import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
const RC2 = lazy(() => import(/* webpackChunkName: "chartjs" */ 'react-chartjs2'));

import cloneDeep from 'lodash/cloneDeep';

function c3ToGraphJs(columns) {
  return {
    labels: columns.find((c) => c[0] === 'x').slice(1),
    datasets: columns
      .filter((c) => c[0] !== 'x')
      .map((c) => ({
        label: c[0],
        data: c.slice(1),
      })),
  };
}

const chartColors = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(231,233,237)',
};

const chartOptions = {
  tooltips: {
    mode: 'point',
  },
  scales: {
    xAxes: [
      {
        type: 'time',
        time: {
          displayFormats: {
            day: 'MMM DD',
          },
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          suggestedMax: 5,
          suggestedMin: 0,
          callback: (value) => {
            // We don't want to display fractional values, which Chart.js does if the max value is small enough.
            // We avoid that by returning undefined for all fractional values.
            if (value % 1 === 0) {
              return value;
            }
            return undefined;
          },
        },
      },
    ],
  },
};

// The latency chart needs its own options, since it deals with (fractions of) seconds, not integer counts.
const latencyChartOptions = cloneDeep(chartOptions);

latencyChartOptions.scales.yAxes = [
  {
    ticks: {
      suggestedMin: 0,
      callback: (value, index, values) => {
        return getHumanizedSeconds(value);
      },
    },
  },
];

latencyChartOptions.tooltips.callbacks = {
  label: (tooltipItem, data) => {
    return `${data.datasets[tooltipItem.datasetIndex].label}: ${getHumanizedSeconds(
      tooltipItem.yLabel
    )}`;
  },
};

function getHumanizedSeconds(value) {
  let fractionalDigits = 2;
  if (value > 0.1) {
    fractionalDigits = 0;
  } else if (value > 0.01) {
    fractionalDigits = 1;
  }
  return `${moment.duration(value, 'second').asMilliseconds().toFixed(fractionalDigits)}ms`;
}

function InsightsCharts({ data }) {
  const entitiesData = c3ToGraphJs(data.entities.columns);
  const errorsData = c3ToGraphJs(data.errors.columns);
  const latenciesData = c3ToGraphJs(data.latencies.columns);
  // style the known datasets
  const cubicInterpolationMode = 'monotonic';
  Object.assign(entitiesData.datasets[0], {
    label: 'Entities processed',
    backgroundColor: chartColors.green,
    borderColor: chartColors.green,
    fill: false,
    cubicInterpolationMode,
  });
  Object.assign(entitiesData.datasets[1], {
    label: 'Entities changed',
    backgroundColor: chartColors.blue,
    borderColor: chartColors.blue,
    fill: false,
    cubicInterpolationMode,
  });
  Object.assign(errorsData.datasets[0], {
    label: 'Write errors',
    backgroundColor: chartColors.orange,
    borderColor: chartColors.orange,
    fill: false,
    cubicInterpolationMode,
  });
  Object.assign(errorsData.datasets[1], {
    label: 'Read errors',
    backgroundColor: chartColors.red,
    borderColor: chartColors.red,
    fill: false,
    cubicInterpolationMode,
  });
  Object.assign(errorsData.datasets[2], {
    label: 'Pump failures',
    backgroundColor: chartColors.purple,
    borderColor: chartColors.purple,
    fill: false,
    cubicInterpolationMode,
  });
  Object.assign(latenciesData.datasets[0], {
    label: 'Source latency',
    backgroundColor: chartColors.green,
    borderColor: chartColors.green,
    fill: false,
    cubicInterpolationMode,
  });
  Object.assign(latenciesData.datasets[1], {
    label: 'Transform latency',
    backgroundColor: chartColors.yellow,
    borderColor: chartColors.yellow,
    fill: false,
    cubicInterpolationMode,
  });
  Object.assign(latenciesData.datasets[2], {
    label: 'Sink latency',
    backgroundColor: chartColors.blue,
    borderColor: chartColors.blue,
    cubicInterpolationMode,
    fill: false,
  });
  return (
    <div className="col gr-primary">
      <RC2 data={entitiesData} options={chartOptions} type="line" />
      <RC2 data={errorsData} options={chartOptions} type="line" />
      <RC2 data={latenciesData} options={latencyChartOptions} type="line" />
    </div>
  );
}

InsightsCharts.propTypeshi = {
  data: PropTypes.shape({}).isRequired,
};

export default InsightsCharts;
