import React from 'react';
import Legend from 'Components/legend/Legend';
import PageHeader, { PageHeaderTitle } from 'Components/page-header';
import Page from 'Components/page';

interface SubscriptionOverviewProps {
  children: React.ReactNode;
}

export const SubscriptionOverview = ({ children }: SubscriptionOverviewProps) => (
  <Page dataSelenium="subscription-overview-pageview">
    <PageHeader>
      <PageHeaderTitle>Overview</PageHeaderTitle>
    </PageHeader>
    <div className="scrollArea overview">
      <div className="overview__legend">
        <Legend
          items={[
            {
              shape: 'block',
              label: 'Deleted entities',
              color: 'rgb(181, 99, 112)',
            },
            {
              shape: 'block',
              label: 'History',
              color: 'rgb(115, 153, 132)',
            },
            {
              shape: 'block',
              label: 'Latest entities',
              color: 'rgb(82, 156, 186)',
            },
            { shape: 'line', label: 'Pipe error', color: '#c93c37' },
            { shape: 'line', label: 'Pipe running', color: 'green' },
            { shape: 'line', label: 'Pipe disabled', color: 'lightgray' },
          ]}
        />
      </div>
      {children}
    </div>
  </Page>
);
