import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import Typography from 'Common/SesamTypography';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import JsonEditor from 'Common/JsonEditor/JsonEditor';
import EditorActions from 'Redux/thunks/editor';
import { compose } from 'redux';
import { withTheme } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';

function mapStateToProps(state) {
  return {
    showSchema: state.editor.visiblePanels.schema,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    validateConfig: (config) => dispatch(EditorActions.validateConfig(config)),
  };
}

export class BareEditorConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSchema: false,
      validationResult: {
        'config-errors': [],
        'json-schema': {},
      },
      expand: true,
    };

    this.onChange = (config) => {
      this.validate(config);
      this.props.onChange(config);
    };

    this.validate = debounce(
      () =>
        this.props
          .validateConfig(this.props.value)
          .then((validationResult) => this.setState({ validationResult })),
      500
    );
  }

  componentDidUpdate(prevProps) {
    const prevPipeRuntime = get(prevProps, 'pipe.runtime');
    const pipeRuntime = get(this.props, 'pipe.runtime');
    if (!isEqual(prevPipeRuntime, pipeRuntime)) {
      this.validate();
    }
  }

  componentDidMount() {
    this.validate();
    if (this.props.registerRefresh) {
      this.props.registerRefresh(this.validate);
    }
  }

  componentWillUnmount() {
    if (this.props.unregisterRefresh) {
      this.props.unregisterRefresh(this.validate);
    }
  }

  render() {
    let validationResult = get(this.state, 'validationResult[config-errors]', []);
    validationResult.sort((a, b) => {
      if (a.level === 'error' && b.level === 'error') {
        return a.msg.localeCompare(b.msg);
      }
      if (a.level === 'error') return -1;
      if (b.level === 'error') return 1;
      return a.msg.localeCompare(b.msg);
    });
    validationResult = uniqBy(validationResult, 'msg');

    let validationErrors = '';
    if (validationResult.length) {
      validationErrors = (
        <React.Fragment>
          <ul>
            {this.state.expand || validationResult.length <= 1 ? (
              validationResult.map((error, idx) => (
                <li
                  key={idx}
                  className={error.level === 'error' ? 'status-error' : 'status-warning'}
                >
                  {error.msg}
                  &nbsp;({error.level})
                </li>
              ))
            ) : (
              <li key={'folded'}>Expand to see the warnings and errors</li>
            )}
          </ul>
          {validationResult.length > 1 && (
            <div
              style={{ textAlign: 'center', width: '40px' }}
              onClick={() => this.setState({ expand: !this.state.expand })}
              className={this.state.expand ? 'expand-button' : 'expand-button rotate-180'}
            >
              <ExpandMoreIcon id="expand-more-icon" />
            </div>
          )}
        </React.Fragment>
      );
    }

    // Creates a panel that shows _updated/_ts/_deleted at the top of the config
    const createConfigPanel = (config, isCurrent = false) => {
      const ts = get(config, '_ts');
      if (ts) {
        const deleted = get(config, '_deleted');

        return (
          <div
            className="configuration__config-panel"
            style={{
              backgroundColor: this.props.theme.palette.background.semilight,
            }}
          >
            <Typography variant="body2">
              {isCurrent && !this.props.saved ? (
                'Unsaved'
              ) : (
                <Tooltip title={moment(ts / 1000).format('DD/MM/YYYY HH:mm')}>
                  <span>
                    Updated {moment(ts / 1000).fromNow()} {deleted && '(Deleted)'}
                  </span>
                </Tooltip>
              )}
            </Typography>
          </div>
        );
      } else return null;
    };

    return (
      <div className="configuration">
        {/* we replace the key to reset the editor */}
        <div className="stretchy-panel editor__panel editor__panel--editor">
          <div className="body" style={{ overflow: 'hidden' }}>
            <div
              className="configuration__config-panels"
              style={{
                backgroundColor: this.props.theme.palette.background.semilight,
              }}
            >
              {this.props.compare && createConfigPanel(this.props.compareValue)}
              {this.props.compare && <span className="configuration__config-panels-gutter" />}
              {createConfigPanel(this.props.currentConfigEntity, true)}
            </div>
            <JsonEditor
              focus
              hasOptions
              isNewPipe={this.props.isNewPipe}
              key={this.props.editorKey}
              onChange={this.onChange}
              onRun={this.props.onRun}
              onSave={this.props.onSave}
              onValidateJson={this.props.onValidateJson}
              rawJson={this.props.value}
              warnings={validationErrors}
              validationResult={this.state.validationResult}
              targetSchema={this.props.targetSchema}
              sourceSchema={this.props.sourceSchema}
              hash={this.props.configHash}
              compare={this.props.compare}
              compareValue={this.props.compareValue}
            />
          </div>
          <div className="footer">{this.props.children}</div>
        </div>
        {this.props.extras}
        {this.props.showSchema && (
          <div className="stretchy-panel editor__panel editor__panel--schema">
            <h3 className="head">Schema</h3>
            <div className="body">
              <pre>{JSON.stringify(this.state.validationResult['json-schema'], null, 2)}</pre>
            </div>
          </div>
        )}
      </div>
    );
  }
}

BareEditorConfiguration.propTypes = {
  children: PropTypes.node,
  editorKey: PropTypes.object,
  extras: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onRun: PropTypes.func,
  onSave: PropTypes.func,
  onValidateJson: PropTypes.func.isRequired,
  showSchema: PropTypes.bool.isRequired,
  validateConfig: PropTypes.func.isRequired,
  value: PropTypes.object,
  isNewPipe: PropTypes.bool,
  targetSchema: PropTypes.array,
  sourceSchema: PropTypes.array,
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
  configHash: PropTypes.string,
  compare: PropTypes.bool,
  compareValue: PropTypes.shape({}),
  currentConfigEntity: PropTypes.shape({}),
  saved: PropTypes.bool,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      background: PropTypes.shape({
        semilight: PropTypes.string,
      }),
    }),
  }).isRequired,
};

BareEditorConfiguration.defaultProps = {
  registerRefresh: () => {},
  unregisterRefresh: () => {},
};

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(BareEditorConfiguration);
