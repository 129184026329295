import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { datasetsSelector } from 'Redux/thunks/datasets';
import { pipesSelector, searchIsActiveSelector, systemsSelector } from 'Redux/selectors';
import { isGlobal } from 'Internals/pipes';
import withLocalStorage from 'Common/withLocalStorage/withLocalStorage';

import DatasetIcon from '../../images/icons/dataset.svg';
import PipeIcon from '../../images/icons/pipe.svg';
import GlobalPipeIcon from '../../images/icons/global-pipe.svg';
import SystemIcon from '../../images/icons/system.svg';
import CommandPalette from '../command-palette';
import { getDatasetLink } from '../common/Links/DatasetLink';

import './style.css';

const MAX_MATCHES = 25;

// Wouldn't need these if React accepted `Symbol`s as keys…
const makeDatasetKey = (dataset) => `dataset/${dataset._id}`;
const makePipeKey = (pipe) => `pipe/${pipe._id}`;
const makeSystemKey = (system) => `system/${system._id}`;

export const DatahubObjectsPaletteId = 'datahubObjects';

const matchSubscriptionObj = (obj, regExp) => regExp.test(obj._id) || regExp.test(obj.name);

const datasetRenderer = (dataset) => (
  <div className="datahub-objects-palette__item" title={`Dataset ${dataset._id}`}>
    <DatasetIcon />
    <span className="visually-hidden">Dataset</span>
    {dataset._id}
  </div>
);

const pipeRenderer = (pipe) => (
  <div className="datahub-objects-palette__item" title={`Pipe ${pipe._id}`}>
    <PipeIcon />
    <span className="visually-hidden">Pipe</span>
    {pipe.name} {pipe.name !== pipe._id && `(ID: ${pipe._id})`}
  </div>
);

const globalPipeRenderer = (pipe) => (
  <div className="datahub-objects-palette__item" title={`Global pipe ${pipe._id}`}>
    <GlobalPipeIcon />
    <span className="visually-hidden">Pipe</span>
    {pipe.name} {pipe.name !== pipe._id && `(ID: ${pipe._id})`}
  </div>
);

const systemRenderer = (system) => (
  <div className="datahub-objects-palette__item" title={`System ${system._id}`}>
    <SystemIcon />
    <span className="visually-hidden">System</span>
    {system.name} {system.name !== system._id && `(ID: ${system._id})`}
  </div>
);

export class BareDatahubObjectsPalette extends React.Component {
  constructor(props) {
    super(props);

    this.saveSearchToLS = (query) => {
      let updatedRecentSearches = this.state.recentSearches.slice();
      const duplicateIndex = this.state.recentSearches.findIndex(
        (s) => s.id === query.id && s.type === query.type
      );
      if (duplicateIndex < 0) {
        updatedRecentSearches.unshift(query);
      } else {
        updatedRecentSearches.splice(duplicateIndex, 1);
        updatedRecentSearches.unshift(query);
      }
      if (updatedRecentSearches.length > MAX_MATCHES) {
        updatedRecentSearches = updatedRecentSearches.slice(MAX_MATCHES);
      }
      this.props.saveToLocalStorage('recentSearches', updatedRecentSearches);
    };

    this.getRecentResults = (recentSearches) => {
      const results = recentSearches.map((s) => {
        switch (s.type) {
          case 'global': {
            const pipe = this.props.pipesDict[s.id];
            if (pipe) return this.makeGlobalResult(pipe);
            else return null;
          }
          case 'pipe': {
            const pipe = this.props.pipesDict[s.id];
            if (pipe) return this.makePipeResult(pipe);
            else return null;
          }
          case 'dataset': {
            const dataset = this.props.datasetsDict[s.id];
            if (dataset) return this.makeDatasetResult(dataset);
            else return null;
          }
          case 'system': {
            const system = this.props.systemsDict[s.id];
            if (system) return this.makeSystemResult(system);
            else return null;
          }
          default: {
            return null;
          }
        }
      });

      return results.filter(Boolean);
    };

    this.onDatasetActivate = (dataset) => {
      this.saveSearchToLS({ type: 'dataset', id: dataset._id });
      this.navigateToDataset(dataset);
    };

    this.navigateToDataset = (dataset) => {
      const path = getDatasetLink(dataset._id, this.props.datasets, this.props.upstreams);
      this.props.router.push(`/subscription/${this.props.subId}/${path}`);
    };

    this.onPipeActivate = (pipe) => {
      this.saveSearchToLS({ type: 'pipe', id: pipe._id });
      this.navigateToPipe(pipe);
    };

    this.onGlobalPipeActivate = (pipe) => {
      this.saveSearchToLS({ type: 'global', id: pipe._id });
      this.navigateToPipe(pipe);
    };

    this.navigateToPipe = (pipe) => {
      this.props.router.push(
        `/subscription/${this.props.subId}/pipes/pipe/${encodeURIComponent(pipe._id)}/`
      );
    };

    this.onSystemActivate = (system) => {
      this.saveSearchToLS({ type: 'system', id: system._id });
      this.navigateToSystem(system);
    };

    this.navigateToSystem = (system) => {
      this.props.router.push(
        `/subscription/${this.props.subId}/systems/system/${encodeURIComponent(system._id)}/`
      );
    };

    this.makeGlobalResult = (pipe) => ({
      item: pipe,
      keyMaker: makePipeKey,
      matcher: matchSubscriptionObj,
      onActivate: this.onGlobalPipeActivate,
      renderer: globalPipeRenderer,
    });

    this.makePipeResult = (pipe) => ({
      item: pipe,
      keyMaker: makePipeKey,
      matcher: matchSubscriptionObj,
      onActivate: this.onPipeActivate,
      renderer: pipeRenderer,
    });

    this.makeDatasetResult = (dataset) => ({
      item: dataset,
      keyMaker: makeDatasetKey,
      matcher: matchSubscriptionObj,
      onActivate: this.onDatasetActivate,
      renderer: datasetRenderer,
    });

    this.makeSystemResult = (system) => ({
      item: system,
      keyMaker: makeSystemKey,
      matcher: matchSubscriptionObj,
      onActivate: this.onSystemActivate,
      renderer: systemRenderer,
    });

    this.makeHaystack = this.makeHaystack.bind(this);
    this.state = { recentSearches: [] };
  }

  *makeHaystack(prefix) {
    if (prefix === 'global' || prefix === '') {
      const globals = this.props.pipes.filter(isGlobal);
      for (let i = 0; i < globals.length; ++i) {
        yield this.makeGlobalResult(globals[i]);
      }
    }

    if (prefix === 'pipe' || prefix === '') {
      const nonGlobals = this.props.pipes.filter((p) => !isGlobal(p));
      for (let i = 0; i < nonGlobals.length; ++i) {
        yield this.makePipeResult(nonGlobals[i]);
      }
    }

    if (prefix === 'system' || prefix === '') {
      for (let i = 0; i < this.props.systems.length; ++i) {
        yield this.makeSystemResult(this.props.systems[i]);
      }
    }
  }

  componentDidMount() {
    const recentSearches = this.props.loadFromLocalStorage('recentSearches');
    this.setState({ recentSearches });
  }

  render() {
    return (
      <CommandPalette
        haystackGenerator={this.makeHaystack}
        subId={this.props.subId}
        hint={
          <span>
            Toggle this object palette with <kbd>Ctrl</kbd> + <kbd>Space</kbd>
          </span>
        }
        onRequestClose={this.props.onRequestClose}
        recentResults={this.getRecentResults(this.state.recentSearches)}
        hasSearch={this.props.hasSearch}
        isItestMode={this.props.isItestMode}
      />
    );
  }
}

BareDatahubObjectsPalette.propTypes = {
  /** All the datasets */
  datasets: PropTypes.arrayOf(PropTypes.object).isRequired,

  /** All datasets as dict */
  datasetsDict: PropTypes.shape({}).isRequired,

  /** All the pipes as array */
  pipes: PropTypes.arrayOf(PropTypes.object).isRequired,

  /** All pipes as dict */
  pipesDict: PropTypes.shape({}),

  /** All the systems */
  systems: PropTypes.arrayOf(PropTypes.object).isRequired,

  /** All systems as dict */
  systemsDict: PropTypes.shape({}),

  upstreams: PropTypes.shape({}),

  /** Handler for when the user requests closing the palette (e.g. by pressing
      `Esc`) — this should update the parent component's state so as to unmount
      this component */
  onRequestClose: PropTypes.func.isRequired,

  /** ID of the subscription */
  subId: PropTypes.string.isRequired,

  /** {Router} */
  router: PropTypes.object.isRequired,

  loadFromLocalStorage: PropTypes.func.isRequired,
  saveToLocalStorage: PropTypes.func.isRequired,
  hasSearch: PropTypes.bool.isRequired,
  isItestMode: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    datasets: datasetsSelector(state),
    datasetsDict: state.datasets,
    pipes: pipesSelector(state),
    pipesDict: state.pipes,
    subId: state.subscription.id,
    systems: systemsSelector(state),
    systemsDict: state.systems,
    hasSearch: searchIsActiveSelector(state),
    upstreams: state.upstreams,
    isItestMode: state.globals.status.is_itest_mode,
  };
}
const mapPropsToLS = {
  recentSearches: {
    path: ['recentSearches'],
    defaultValue: [],
    bySubscription: true,
  },
};

export default compose(
  withLocalStorage('sesam--recent-searches', mapPropsToLS),
  withRouter,
  connect(mapStateToProps)
)(BareDatahubObjectsPalette);
