import React from 'react';
import PropTypes from 'prop-types';

import { getEntitiesPerSecond } from 'Internals/datasets';

const EntitiesPerSecondRenderer = (props) => {
  const runtime = props.runtime;
  const entitiesPerSecond = getEntitiesPerSecond(runtime);
  const entitiesPerSecondRounded = entitiesPerSecond.toFixed(2);

  return <div>{entitiesPerSecondRounded}</div>;
};

EntitiesPerSecondRenderer.propTypes = {
  runtime: PropTypes.shape({}),
};

export default EntitiesPerSecondRenderer;
