import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'Common/SesamTypography';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import SesamAutocomplete from 'Common/SesamAutocomplete/SesamAutocomplete';
import { DEFAULT_CONFIG_GROUP_ID, DEFAULT_CONFIG_GROUP_LABEL } from 'Redux/selectors';

const useStyle = makeStyles(() => ({
  boldedOption: {
    fontWeight: 'bold',
  },
}));

function idToLabelObject(id) {
  const label = id === DEFAULT_CONFIG_GROUP_ID ? DEFAULT_CONFIG_GROUP_LABEL : id;
  return {
    id,
    label,
  };
}

function ConfigGroupSelector(props) {
  // Note to self, defaultProps do not trigger when null is passed in, just undefined

  const classes = useStyle();

  function onSelectConfigGroup(ev, val) {
    if (val && typeof val.id === 'string') {
      props.onSelectConfigGroup(val.id);
    } else if (typeof val === 'string') {
      props.onSelectConfigGroup(val);
    }
  }

  function onChangeConfigGroup(ev, val) {
    props.onChangeConfigGroup(val);
  }

  const configGroupsWithLabels = props.configGroups.map(idToLabelObject);

  return (
    <SesamAutocomplete
      freeSolo
      blurOnSelect
      getOptionLabel={(o) => {
        if (typeof o === 'string') {
          return o;
        } else {
          return o.label;
        }
      }}
      renderOption={(o) => {
        if (typeof o === 'object' && o.id && o.label) {
          const shouldBeBold = o.id === DEFAULT_CONFIG_GROUP_ID;
          return (
            <Typography className={shouldBeBold ? classes.boldedOption : ''} noWrap>
              {o.label}
            </Typography>
          );
        } else {
          return null;
        }
      }}
      renderInput={(params) => (
        <SesamTextField
          {...props.TextFieldProps}
          {...params}
          placeholder="Select/type new to add"
        />
      )}
      onChange={onSelectConfigGroup}
      onInputChange={onChangeConfigGroup}
      value={idToLabelObject(props.selectedConfigGroup)}
      options={configGroupsWithLabels}
      disablePortal
      filterOptions={(options, input) => {
        const { inputValue } = input;

        if (inputValue === props.selectedConfigGroup) return options;

        return options.filter((option) => {
          const { id } = option;
          return id.indexOf(inputValue) !== -1;
        });
      }}
    />
  );
}

ConfigGroupSelector.propTypes = {
  configGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeConfigGroup: PropTypes.func.isRequired,
  onSelectConfigGroup: PropTypes.func.isRequired,
  selectedConfigGroup: PropTypes.string,
  TextFieldProps: PropTypes.shape({}),
};

ConfigGroupSelector.defaultProps = {
  selectedConfigGroup: '$None',
};

export default ConfigGroupSelector;
