/** @module model-props */

import PropTypes from 'prop-types';

/**
 * Model of a subscription
 */
export const subscriptionProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

/*
 * Model of a subscription member
 */
export const subscriptionMemberProps = PropTypes.shape({
  roles: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
  }),
});

/*
 * Model of a subscription role
 */
export const subscriptionAvailableRoleProps = PropTypes.shape({
  'child-roles': PropTypes.arrayOf(PropTypes.string).isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  'is-custom-role': PropTypes.bool.isRequired,
  'is-internal-role': PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
});

/**
 * Model of a "supported operation" for a user within a subscription
 */
export const operationPropType = PropTypes.shape({
  enabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  'reason-code': PropTypes.string,
  'reason-msg': PropTypes.string,
});

/**
 * Model of the "recipients" attribute of a "notification rule"
 */
export const notificationRuleRecipientsProps = PropTypes.arrayOf(
  PropTypes.shape({
    /*
      The id of this recipient. This can be a role-id (in which case every user with this roleid gets
      notified), a user-id, or a plain email address. The 'type' property specifies how the 'id' should
      be interpreted.
      example: "group:Admin"
    */
    id: PropTypes.string.isRequired,

    /* Specifies what the 'id' property refers to. Can be one of ["role", "user_id", "email"] */
    type: PropTypes.string.isRequired,

    /* The delivery-method(s) to use for this recipient */
    methods: PropTypes.arrayOf(PropTypes.string).isRequired,
  })
);

/**
 * Model of a "notification rule"
 */
export const notificationRuleProps = PropTypes.shape({
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  recipients: notificationRuleRecipientsProps,
});

/**
 * Model of a "notification ruletype"
 */
export const notificationRuleTypeProps = PropTypes.shape({
  type: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  schema: PropTypes.object,
});

/**
 * Model of an alert
 */
export const alertProps = {
  event_timestamp: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  notification_id: PropTypes.number.isRequired,
  notification_rule_id: PropTypes.string.isRequired,
  notification_rule_name: PropTypes.string.isRequired,
  notification_rule_type: PropTypes.string.isRequired,
  pipe_id: PropTypes.string,
  subscription_id: PropTypes.string,
  last_event_timestamp: PropTypes.string.isRequired,
  event_count: PropTypes.number.isRequired,
};

// --- Utilities ---------------------------------------------------------------

/**
 * Creates an object suitable to define PropTypes for a list of subscription
 * operations (i.e. user permissions). These PropTypes can be combined with
 * others by using `Object.assign` when defining a component. To populate these
 * props, use
 * {@linkcode module:redux/reducer-subscriptions.valuesFromOperations}.
 *
 * @example
 * const operations = ['delete_subscription', 'modify_subscription'];
 * MyComponent.propTypes = Object.assign(propTypesFromOperations(operations), {
 *   someProp: PropTypes.bool.isRequired,
 *   anotherProp: PropTypes.func.isRequired,
 * }
 *
 * @param {array} ops An array of operation names (strings), like
 *                    'accept_invitation', 'renew_license', etc
 */
export const propTypesFromOperations = (ops) => {
  const propTypes = {};
  ops.forEach((op) => {
    propTypes[`can_${op}`] = operationPropType;
  });
  return propTypes;
};
