import React from 'react';
import Info from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import isArray from 'lodash/isArray';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles({
  icon: {
    verticalAlign: 'middle',
  },
  iconSmall: {
    fontSize: '1rem',
  },
  iconMedium: {
    fontSize: '1.15rem',
  },
});

interface ConfigComponentTooltipProps {
  info: string[] | string;
  className?: string;
  size: 'small' | 'medium';
}

const ConfigComponentTooltip: React.FC<ConfigComponentTooltipProps> = ({
  info,
  className,
  size = 'small',
  ...restProps
}) => {
  const classes = useStyle();

  const i = isArray(info) ? info.join('\n') : info;

  return (
    <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{i}</div>} {...restProps}>
      <Info
        className={clsx([
          classes.icon,
          size === 'small' && classes.iconSmall,
          size === 'medium' && classes.iconMedium,
          className,
        ])}
        color="disabled"
        fontSize="small"
      />
    </Tooltip>
  );
};

export default ConfigComponentTooltip;
