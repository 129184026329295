import { useEffect, useRef } from 'react';
import usePrevious from './usePrevious';

/**
 * Use this hook to trigger a delayed callback when something (trigger) changes
 * @param startCallback run this function immediately when trigger changes
 * @param endCallback run this function after delay after the trigger changes
 * @param delay how long until endCallback gets run
 * @param trigger changing this variable triggers the effect
 */
export default function useDelayedEffect(startCallback: () => void, endCallback: () => void, delay: number, trigger: any) {
  const savedCallback = useRef<()=>void>();

  const previousTrigger = usePrevious(trigger);

  useEffect(() => {
    if (!trigger && previousTrigger) {
      startCallback();
    }
  }, [trigger]);

  useEffect(() => {
    savedCallback.current = endCallback;
  });

  useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current();
    }
    if (delay !== null && !trigger) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, trigger]);
};
