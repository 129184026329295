import React from 'react';
import PropTypes from 'prop-types';
import './PortalFallbackMessageStyle.css';

const titleMessage = `
Features to do with subscription and user management, 
licenses, JWT tokens, pipe notifications and pipe insights 
are unavailable. Datahub features, like creating
or updating pipes, systems or running and stopping pipes are unaffected.
`;

const PortalFallbackMessage = ({ style }) => (
  <span className="portal-message" style={style} title={titleMessage}>
    The Sesam portal is running in a special read-only fallback mode
  </span>
);

PortalFallbackMessage.propTypes = { style: PropTypes.shape() };

export default PortalFallbackMessage;
