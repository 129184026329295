import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import cloneDeep from 'lodash/cloneDeep';

import Button from 'Common/Button/Button';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import FilterTable, { getSortValues } from '../../../components/filter-table/FilterTable';
import GdprDataTypeForm from '../../../components/gdpr-data-type-form/GdprDataTypeForm';
import { LoadingPanel } from 'Common/LoadingPanel';

import GdprActions from 'Redux/thunks/gdpr';
import { uuid } from 'Internals/utils';
import { sortWithNullOrUndefinedValues } from 'Internals/sort';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import './GdprDataTypePageStyle.css';
import SesamLink from 'Common/Links/SesamLink';

const defaultVisibleCols = ['Description', 'Level', 'System id', 'Purposes', 'Contact', 'Edit'];

const defaultVisibleFacets = ['level', 'system-id', 'purpose-id', 'contact'];

/**
 * Filter
 */
const filterLabelMapping = {
  level: 'Level',
  'system-id': 'System id',
  'purpose-id': 'Purposes',
  contact: 'Contact',
  edit: 'Edit',
};

const emptyFilter = Object.freeze({
  level: {
    selected: [],
    search: [],
  },
  'system-id': {
    selected: [],
    search: [],
  },
  'purpose-id': {
    selected: [],
    search: [],
  },
  contact: {
    selected: [],
    search: [],
  },
});

const defaultFilter = cloneDeep(emptyFilter);

/**
 * Sorting
 */
const defaultSortBy = [
  {
    id: 'systemId',
    desc: false,
  },
];

/**
 * Gdpr data type page
 * Table and form for adding, editing and deleting data types
 */
const GdprDataTypePage = ({
  addDatatype,
  datatypes,
  deleteDatatype,
  loadDatatypes,
  loadPurposes,
  purposes,
  updateDatatype,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [editDatatype, setEditDatatype] = useState(null);

  useEffect(() => {
    loadDatatypes();
    loadPurposes();
  }, []);

  useEffect(() => {
    if (editDatatype) {
      setShowDialog(true);
    }
  }, [editDatatype]);

  const columns = [
    {
      id: 'description',
      Header: 'Description',
      fixed: true,
      accessor: 'description',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'level',
      Header: 'Level',
      accessor: 'level',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'systemId',
      Header: 'System id',
      accessor: 'system-id',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'contact',
      Header: 'Contact',
      accessor: 'contact',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Edit',
      id: 'edit',
      fixed: true,
      width: 65,
      Cell: ({ row }) => <SesamLink onClick={() => handleEditClick(row.original)}>Edit</SesamLink>,
      align: 'center',
    },
  ];

  const getDatatypesWithPopulatedPurposes = (datatypes, purposes) =>
    datatypes.map((datatype) => {
      const newDatatype = { ...datatype };

      if (!newDatatype['purpose-id']) return newDatatype;

      const purposeIds = Array.isArray(datatype['purpose-id'])
        ? [...datatype['purpose-id']]
        : [datatype['purpose-id']];

      newDatatype['purpose-id'] = purposeIds.map((purposeId) => {
        const purpose = purposes.find((purpose) => purpose['purpose-id'] === purposeId);
        if (!purpose) return { name: purposeId, value: purposeId };
        return { name: purpose['title'], value: purposeId };
      });

      return newDatatype;
    });

  const handleCancel = () => {
    setShowDialog(false);
  };

  const handleDeleteDatatype = (datatype) => {
    deleteDatatype(datatype);
    setShowDialog(false);
  };

  const handleEditClick = (datatype) => {
    setEditDatatype(datatype);
  };

  const handleNewDatatype = (data) => {
    const datatype = { ...data, ['data-type-id']: uuid() };
    let purposeIds = datatype['purpose-id'];
    if (purposeIds === null) {
      purposeIds = [];
    }
    datatype['purpose-id'] = purposeIds.map((purpose) => purpose.value);

    addDatatype(datatype);
    setShowDialog(false);
  };

  const handleUpdateDatatype = (data) => {
    let purposeIds = data['purpose-id'];
    if (purposeIds === null) {
      purposeIds = [];
    }
    data['purpose-id'] = purposeIds.map((purpose) => purpose.value);

    updateDatatype(data);
    setShowDialog(false);
  };

  if (!datatypes || !purposes) {
    return <LoadingPanel />;
  }

  const purposeIds = purposes.map((purpose) => ({
    name: purpose.title,
    value: purpose['purpose-id'],
  }));

  const datatypesWithPopulatedPurposes = getDatatypesWithPopulatedPurposes(datatypes, purposes);

  const defaultValues = {
    ['data-type-id']: '',
    ['description']: '',
    ['level']: '',
    ['system-id']: '',
    ['purpose-id']: [],
    ['contact']: '',
  };

  return (
    <ErrorBoundary>
      <main className="scrollArea">
        <div className="gdpr-data-type-page">
          <div className="gdpr-data-type-page__table">
            <FilterTable
              defaults={{
                filter: defaultFilter,
                sortBy: defaultSortBy,
                visibleCols: defaultVisibleCols,
                visibleFacets: defaultVisibleFacets,
              }}
              emptyFilter={emptyFilter}
              filterLabelMapping={filterLabelMapping}
              columns={columns}
              itemsToFilter={datatypesWithPopulatedPurposes}
              tableKey="datatypes-2"
              useExpander
            />
          </div>
          <div className="gdpr-data-type-page__new-data-type">
            <Button
              text="Create new data type"
              onClick={() => {
                setShowDialog(true);
              }}
            />
          </div>
        </div>
        <Dialog
          open={showDialog}
          maxWidth="lg"
          onClose={() => {
            setShowDialog(false);
            setEditDatatype(null);
          }}
        >
          <DialogContent>
            {editDatatype ? (
              <GdprDataTypeForm
                defaultValues={defaultValues}
                initialValues={editDatatype}
                inModal
                onCancel={() => {
                  setEditDatatype(null);
                  handleCancel();
                }}
                onUpdate={(datatype) => {
                  handleUpdateDatatype(datatype);
                  setEditDatatype(null);
                }}
                onDelete={(datatype) => {
                  handleDeleteDatatype(datatype);
                  setEditDatatype(null);
                }}
                purposeIds={purposeIds}
              />
            ) : (
              <GdprDataTypeForm
                defaultValues={defaultValues}
                inModal
                newDataType
                onCancel={handleCancel}
                onDelete={handleDeleteDatatype}
                onSubmit={handleNewDatatype}
                onUpdate={() => {}}
                purposeIds={purposeIds}
              />
            )}
          </DialogContent>
        </Dialog>
      </main>
    </ErrorBoundary>
  );
};

GdprDataTypePage.propTypes = {
  addDatatype: PropTypes.func.isRequired,
  datatypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteDatatype: PropTypes.func.isRequired,
  loadDatatypes: PropTypes.func.isRequired,
  loadPurposes: PropTypes.func.isRequired,
  portalUrl: PropTypes.string.isRequired,
  purposes: PropTypes.arrayOf(PropTypes.shape({})),
  router: PropTypes.shape({}).isRequired,
  updateDatatype: PropTypes.func.isRequired,
};

/**
 * React redux mappings
 */
const mapStateToProps = (state) => ({
  datatypes: state.gdpr.datatypes.data,
  portalUrl: state.subscription.url,
  purposes: state.gdpr.purposes.data,
  token: state.subscription.token,
});

const mapDispatchToProps = (dispatch) => ({
  addDatatype: (datatype) => dispatch(GdprActions.addDatatype(datatype)),
  deleteDatatype: (datatype) => dispatch(GdprActions.deleteDatatype(datatype)),
  loadDatatypes: () => dispatch(GdprActions.loadDatatypes()),
  loadPurposes: () => dispatch(GdprActions.loadPurposes()),
  updateDatatype: (datatype) => dispatch(GdprActions.updateDatatype(datatype)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(GdprDataTypePage);
