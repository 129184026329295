import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { RootState } from '../';
import StripeAPI from 'Api/stripe';

/*
Hook for loading the stripe promise to be used in the Elements components.
Docs: https://stripe.com/docs/stripe-js/react#elements-provider
This works because we can initially pass null as the stripePromise (once)
*/

export default function useStripePromise() {
  const portalUrl = useSelector((state: RootState) => state.globals.portalUrl);
  const [stripeKey, setStripeKey] = useState();
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    StripeAPI.loadKey(portalUrl).then((key) => {
      setStripeKey(key);
    });
  }, []);

  useEffect(() => {
    if (stripeKey && !stripePromise) {
      setStripePromise(loadStripe(stripeKey));
    }
  }, [stripeKey, stripePromise]);

  return stripePromise;
}
