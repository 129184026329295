import React from 'react';

import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core';

function _EditorPanelDivider({ children, style, theme }) {
  const styleWithTheme = {
    ...style,
    backgroundColor: theme.palette.background.semilight,
  };
  return (
    <div className="editor__panel-divider" style={styleWithTheme}>
      {children}
    </div>
  );
}

_EditorPanelDivider.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({}),
  theme: PropTypes.shape({}).isRequired,
};
const EditorPanelDivider = withTheme(_EditorPanelDivider);

function Title({ children, title }) {
  if (!title && typeof children === 'string') {
    title = children;
  }
  return (
    <h4 className="editor__panel-divider-title" title={title}>
      {children}
    </h4>
  );
}

Title.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

function Tools({ children }) {
  return <div className="editor__panel-divider-tools">{children}</div>;
}
Tools.propTypes = {
  children: PropTypes.node,
};

EditorPanelDivider.Title = Title;
EditorPanelDivider.Tools = Tools;

export default EditorPanelDivider;
