import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PipeActions from 'Redux/thunks/pipes';
import { Action } from '../../components/action/ActionPanel';
import { getAvailableActions } from 'Internals/pipes';
import PopupAction from '../../components/action/PopupAction';
import PipeProgressRenderer from 'Common/table-list-renderers/pipe-progress';
import { confirmBefore } from 'Common/Confirmation';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

class PipeOperations extends React.Component {
  constructor(props) {
    super(props);

    this.onParameterOperation = (operation, parameter) => {
      const parameters = {};
      if (this.state.operationParameter && this.state.operationParameter !== '') {
        parameters[parameter] = this.state.operationParameter;
      }
      this.onOperation(operation, parameters);
    };

    this.onOperation = (operation, parameters = {}) => {
      return Promise.all(
        this.supportedPipes(operation).map((pipe) =>
          this.props.runPumpOperation(pipe._id, operation, parameters)
        )
      );
    };

    this.onOperationParameterChanged = (event) => {
      this.setState({ operationParameter: event.target.value });
    };

    this.onResetOperationParameter = () => {
      this.setState({ operationParameter: null });
    };

    this.onResetPipes = () => {
      this.onOperation('update-last-seen', { 'last-seen': '' });
    };

    this.supportedPipes = (operation) => {
      return this.props.pipes.filter((p) => p.runtime['supported-operations'].includes(operation));
    };

    this.state = {
      operationParameter: null,
    };
  }

  render() {
    const availableActions = getAvailableActions(this.props.pipes);
    const pipeOrPipes = this.props.pipes.length > 1 ? 'pipes' : 'pipe';
    // TODO: This component returns invalid markup. Must fix!
    return (
      <span>
        {this.props.unaryProgress && this.props.pipes.length === 1 && this.props.pipes[0] && (
          <li>
            <PipeProgressRenderer
              progress={this.props.pipes[0].progress}
              runtime={this.props.pipes[0].runtime}
              lastRunWithDate
            />
          </li>
        )}
        <Action
          name="Enable"
          onActionClick={() => this.onOperation('enable')}
          enabled={availableActions.includes('enable')}
        />
        <PopupAction
          name="Disable"
          onActionClick={() => this.onParameterOperation('disable', 'comment')}
          onClosed={this.onResetOperationParameter}
          enabled={availableActions.includes('disable')}
        >
          <SesamTextField
            id="disable"
            value={this.state.operationParameter}
            onChange={this.onOperationParameterChanged}
            helperText="You can add comment describing the reason for disabling these pipes. Leave blank for no comment."
          />
        </PopupAction>
        <Action
          name="Restart…"
          onActionClick={() => {
            confirmBefore(`Caution. this will restart the ${pipeOrPipes}.`, () =>
              this.onOperation('update-last-seen', {
                'last-seen': '',
              }).then(() => this.onOperation('start'))
            );
          }}
          enabled={
            availableActions.includes('start') && availableActions.includes('update-last-seen')
          }
        />
        <Action
          name="Start"
          onActionClick={() => this.onOperation('start')}
          enabled={availableActions.includes('start')}
        />
        <Action
          name="Stop"
          onActionClick={() => this.onOperation('stop')}
          enabled={availableActions.includes('stop')}
        />
        <Action
          name="Reset…"
          onActionClick={() => {
            confirmBefore(`Danger! This will reset the ${pipeOrPipes}.`, () => this.onResetPipes());
          }}
          enabled={availableActions.includes('update-last-seen')}
        />
        <Action
          name="Reset to end…"
          onActionClick={() => {
            confirmBefore(
              `Danger! This will reset the ${pipeOrPipes} to end of the source dataset(s), which will usually result in inconsistent data!`,
              () => this.onOperation('set-last-seen-to-end')
            );
          }}
          enabled={availableActions.includes('set-last-seen-to-end')}
          tooltip={`Danger! This will reset the ${pipeOrPipes} to end of the source dataset(s), which will usually result in inconsistent data. Only use this for debugging and testing!`}
        />
        <PopupAction
          name="Last seen"
          enabled={availableActions.includes('update-last-seen')}
          onActionClick={() => this.onParameterOperation('update-last-seen', 'last-seen')}
          onClosed={this.onResetOperationParameter}
        >
          <SesamTextField
            label="Last seen (see API doc)"
            id="lastSeen"
            value={this.state.operationParameter}
            onChange={this.onOperationParameterChanged}
          />
        </PopupAction>
        {this.props.extraActions}
      </span>
    );
  }
}

PipeOperations.propTypes = {
  // will be appended to the end of the action list
  extraActions: PropTypes.any,
  pipes: PropTypes.array.isRequired,
  runPumpOperation: PropTypes.func.isRequired,
  unaryProgress: PropTypes.bool,
};

PipeOperations.defaultProps = {
  enableNew: true,
};

function mapDispatchToProps(dispatch) {
  return {
    runPumpOperation: (id, operation, parameters) =>
      dispatch(PipeActions.runPumpOperation(id, operation, parameters)),
  };
}

export default connect(null, mapDispatchToProps)(PipeOperations);
