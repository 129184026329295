import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { apologise } from 'Redux/thunks/apology';
import sfetch from 'Internals/sfetch';

// @see https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
function b64EncodeUnicode(str) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode('0x' + p1);
    })
  );
}

/**
 * A higher order component that passes down functionality to download files from an endpoint.
 */
const withDownload = () => (WrappedComponent) => {
  class WithDownload extends React.Component {
    constructor(props) {
      super(props);

      // Url is supplied in case the child component needs to add parameters
      this.handleDownload = async (url, token, type, fileName, zipped = false) => {
        let file;
        let hasError = false;
        try {
          file = await this.download(url, token, fileName, type, zipped);
        } catch (e) {
          this.props.apologise(e);
          hasError = true;
        }

        if (hasError) {
          return;
        }

        const link = document.createElement('a');
        link.setAttribute('href', file);
        link.setAttribute('download', `${fileName}.${zipped ? 'zip' : type}`);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      this.download = async (url, token, name, type, zipped) => {
        const mimeTypes = {
          csv: 'text/csv',
          json: 'application/json',
          xml: 'application/xml',
          zip: 'application/zip',
          text: 'text/plain',
        };

        const headers = new Headers();
        headers.append('Authorization', `bearer ${token}`);

        if (zipped) {
          headers.append('Accept', 'application/zip');
        }

        let response;
        try {
          response = await sfetch(url, { headers });
        } catch (e) {
          throw new Error(e);
        }

        let data;
        let file;
        let mimeType;

        if (zipped) {
          data = await response.blob();
          mimeType = mimeTypes['zip'];
          file = URL.createObjectURL(data);
        } else {
          // get the encoding from the server response
          const contentType = response.headers.get('content-type');
          const encoding = contentType.match(/charset=([\s\S]+)$/);

          try {
            if (encoding && encoding[1] && encoding[1] !== 'None') {
              data = await response.arrayBuffer().then((buffer) => {
                const decoder = new TextDecoder(encoding[1]);
                return decoder.decode(buffer);
              });
            } else {
              // default to UTF-8 if no encoding info in header
              data = await response.text();
            }
          } catch (e) {
            console.log('Error reading server response', e);
          }
          mimeType = mimeTypes[type];
          file = `data:${mimeType};base64,${b64EncodeUnicode(data)}`;
        }

        return file;
      };
    }

    render() {
      return <WrappedComponent {...this.props} onDownload={this.handleDownload} />;
    }
  }

  WithDownload.displayName = `WithDownload(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  WithDownload.propTypes = {
    apologise: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = (dispatch) => ({
    apologise: (msg) => dispatch(apologise(msg)),
  });

  return connect(null, mapDispatchToProps)(WithDownload);
};

export default withDownload;
