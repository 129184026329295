import { sfetchJson } from 'Internals/sfetch';
import {
  ApiConf,
  SearchAggregationResults,
  SearchFacet,
  SearchResult,
  SearchResults,
  SomeObject,
} from '../types/types';

type GetDatasetAggregationsParams = {
  searchString: string;
  namespaces: string[];
  properties: string[];
  ref: undefined | string;
};

/**
 * Retrieves the names and counts for dataset facets in the Search page
 * @param apiConf token and subUrl
 * @param params
 */
async function getDatasetAggregations(
  apiConf: ApiConf,
  params: GetDatasetAggregationsParams
): Promise<SearchAggregationResults> {
  const { subUrl, token } = apiConf;
  const fetchOptions: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  };

  return await sfetchJson(`${subUrl}/search/dataset_aggregations`, fetchOptions);
}

type GetNamespaceAggregationsParams = {
  searchString: string;
  datasets: string[];
  namespaces: string[];
  properties: string[];
  ref: undefined | string;
};

/**
 * Retrieves the names and counts for namespace facets in the Search page
 * @param apiConf token and subUrl
 * @param params
 */
async function getNamespaceAggregations(
  apiConf: ApiConf,
  params: GetNamespaceAggregationsParams
): Promise<SearchAggregationResults> {
  const { subUrl, token } = apiConf;
  const fetchOptions: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  };

  return await sfetchJson(`${subUrl}/search/namespace_aggregations`, fetchOptions);
}

type GetPropertiesAggregationsParams = {
  searchString: string;
  datasets: string[];
  namespaces: string[];
  ref: undefined | string;
};

/**
 * Retrieves the names and counts for namespace facets in the Search page
 * @param apiConf token and subUrl
 * @param params
 */
async function getPropertiesAggregations(
  apiConf: ApiConf,
  params: GetPropertiesAggregationsParams
): Promise<SearchAggregationResults> {
  const { subUrl, token } = apiConf;
  const fetchOptions: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  };

  return await sfetchJson(`${subUrl}/search/property_aggregations`, fetchOptions);
}

type SearchParams = {
  searchString: string;
  datasets: string[];
  namespaces: string[];
  properties: string[];
  from: number;
  ref: undefined | string;
  size: number;
};

/**
 * Returns search results for entities in the Search page
 * @param apiConf token and subUrl
 * @param searchParams
 */
async function searchText(apiConf: ApiConf, searchParams: SearchParams): Promise<SearchResults> {
  const { subUrl, token } = apiConf;
  const fetchOptions: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(searchParams),
  };

  return await sfetchJson(`${subUrl}/search`, fetchOptions);
}

/**
 * Retrieves information about which ni-s this entity references
 * @param id
 */
async function getResultsById(apiConf: ApiConf, id: string): Promise<SearchResults> {
  const { subUrl, token } = apiConf;
  const fetchOptions: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  let url = `${subUrl}/search/by_id`;
  if (id && id !== '') {
    url = url + `?id=${id}`;
  }
  return await sfetchJson(url, fetchOptions);
}

/**
 * Retrieves information about which entities reference this ni
 * @param ni
 */
async function getResultsByReferencedBy(apiConf: ApiConf, ni: string): Promise<SearchResults> {
  const { subUrl, token } = apiConf;
  const fetchOptions: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  let url = `${subUrl}/search/by_ref`;
  if (ni && ni !== '') {
    url = url + `?ni=${ni}`;
  }

  const response = await sfetchJson(url, fetchOptions);

  if (response.length > 50) {
    return response.length;
  }

  return response;
}

export default {
  getDatasetAggregations,
  getNamespaceAggregations,
  getPropertiesAggregations,
  getResultsByReferencedBy,
  getResultsById,
  searchText,
};
