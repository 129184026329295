import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import HopsWizardFilteredList from './HopsWizardFilteredList';

import DatasetIcon from '../../../../../images/icons/dataset.svg';

const useStyle = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flex: 1,
    },
    description: {
      flex: 1,
      paddingRight: '10px',
    },
    action: {
      flex: 2,
      display: 'flex',
      overflow: 'hidden',
      paddingLeft: '10px',
    },
  };
});

const HopsWizardStep1 = (props) => {
  const classes = useStyle();

  const { bag, state, setValue } = props;

  return (
    <div className={classes.container}>
      <div className={classes.description}>
        <p>First you must tell us where you want to add the data from.</p>
        <p>
          Browse the list on the right, or use the search box to find the dataset you want, then
          click to select.
        </p>
      </div>
      <div className={classes.action}>
        <HopsWizardFilteredList
          placeholderEmptyValue="Pick a dataset from the list"
          placeholderEmptySearch="No datasets found"
          filterInputProps={{
            autoFocus: true,
          }}
          Icon={DatasetIcon}
          placeholderSearch="Search all datasets"
          value={state.dataset}
          items={bag.datasetIds}
          onSelectItem={(val) => {
            setValue('dataset', val);
          }}
        />
      </div>
    </div>
  );
};

HopsWizardStep1.propTypes = {
  bag: PropTypes.shape({ datasetIds: PropTypes.arrayOf(PropTypes.string) }).isRequired,
  state: PropTypes.shape({ dataset: PropTypes.string }).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default HopsWizardStep1;
