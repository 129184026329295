import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import get from 'lodash/get';

import Box from 'Common/Box/Box';
import ButtonLink from 'Common/ButtonLink/ButtonLink';

import DatasetActions from 'Redux/thunks/datasets';
import { leafPipesSelector } from 'Redux/selectors';
import { getSinkDataset } from 'Internals/pipes';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import JsonEditor from 'Common/JsonEditor/JsonEditor';
import ConfigModifiedInfo from 'Common/ConfigModifiedInfo/ConfigModifiedInfo';
import EntityViewer from '../entity-viewer/EntityViewer';
import PipeGraph from '../../subscription/pages/pipes/PipeGraph';
import PipeStatus from '../pipe-status/PipeStatus';

import './PipeDashboardStyle.css';

function PipeDashboard(props) {
  const {
    datasets,
    datasetsLoaded,
    loadDataset,
    isPipeLeaf,
    pipe,
    pipesLoaded,
    systemsLoaded,
    params,
    router,
    registerRefresh,
    unregisterRefresh,
  } = props;

  const sinkDataset = useMemo(() => getSinkDataset(pipe, datasets), [pipe, datasets]);
  const sinkDatasetId = get(sinkDataset, '_id') || null;

  useEffect(() => {
    function loadSinkDataset() {
      if (sinkDatasetId !== null) {
        loadDataset(sinkDatasetId);
      }
    }

    registerRefresh(loadSinkDataset);

    return () => {
      unregisterRefresh(loadSinkDataset);
    };
  });

  useEffect(() => {
    if (sinkDatasetId !== null) {
      loadDataset(sinkDatasetId);
    }
  }, [loadDataset, sinkDatasetId]);

  if (!pipe || !pipesLoaded || !systemsLoaded || !datasetsLoaded) {
    return null;
  }

  return (
    <main className="scrollArea">
      <div className="pipe-dashboard">
        <div className="pipe-dashboard__section pipe-dashboard__flow">
          <Box>
            <Box.Header>
              <Box.Title>Graph</Box.Title>
              <Box.Actions>
                <ButtonLink
                  to={`/subscription/${params.subId}/pipes/pipe/${encodeURIComponent(
                    pipe._id
                  )}/flow`}
                  size="tiny"
                >
                  Go to graph
                </ButtonLink>
              </Box.Actions>
            </Box.Header>
            <Box.Content>
              <ErrorBoundary fallback="An error occured while rendering the graph">
                <PipeGraph
                  params={params}
                  onGotoNode={(link) => {
                    router.push(link.replace(new RegExp('/graph$'), '/dashboard'));
                  }}
                  options={{
                    autoResize: true,
                  }}
                  fit={true}
                  registerRefresh={registerRefresh}
                  unregisterRefresh={unregisterRefresh}
                />
              </ErrorBoundary>
            </Box.Content>
          </Box>
        </div>
        <div className="pipe-dashboard__section pipe-dashboard__execution">
          <Box>
            <Box.Header>
              <Box.Title>Status</Box.Title>
              <Box.Actions>
                <ButtonLink
                  to={`/subscription/${params.subId}/pipes/pipe/${encodeURIComponent(
                    pipe._id
                  )}/execution`}
                  size="tiny"
                >
                  Go to execution log
                </ButtonLink>
              </Box.Actions>
            </Box.Header>
            <Box.Content padding>
              <ErrorBoundary fallback="An error occured while rendering pipe status">
                <PipeStatus
                  pipe={pipe}
                  sinkDataset={sinkDataset}
                  subId={params.subId}
                  isPipeLeaf={isPipeLeaf}
                />
              </ErrorBoundary>
            </Box.Content>
          </Box>
        </div>
        <div className="pipe-dashboard__section pipe-dashboard__config">
          <Box>
            <Box.Header>
              <Box.Title>Config</Box.Title>
              <Box.Actions>
                <ButtonLink
                  to={`/subscription/${params.subId}/pipes/pipe/${encodeURIComponent(
                    pipe._id
                  )}/edit`}
                  size="tiny"
                >
                  Edit config
                </ButtonLink>
              </Box.Actions>
            </Box.Header>
            <Box.Content>
              <ErrorBoundary fallback="An error occured while rendering pipe config">
                <JsonEditor
                  rawJson={get(pipe, 'config.original')}
                  key={get(pipe, '_id')}
                  readOnly={true}
                  hash={get(pipe, 'config_hash')}
                />
                <ConfigModifiedInfo primitive={pipe} />
              </ErrorBoundary>
            </Box.Content>
          </Box>
        </div>
        <div className="pipe-dashboard__section pipe-dashboard__output">
          <Box title="Output">
            <Box.Header>
              <Box.Title>Output</Box.Title>
              <Box.Actions>
                <ButtonLink
                  to={`/subscription/${params.subId}/pipes/pipe/${encodeURIComponent(
                    pipe._id
                  )}/output`}
                  size="tiny"
                >
                  Go to output
                </ButtonLink>
              </Box.Actions>
            </Box.Header>
            <Box.Content>
              <EntityViewer pipe={pipe} stage="sink" />
            </Box.Content>
          </Box>
        </div>
      </div>
    </main>
  );
}

PipeDashboard.propTypes = {
  datasets: PropTypes.shape({}),
  pipe: PropTypes.shape({
    _id: PropTypes.string,
  }),
  isPipeLeaf: PropTypes.bool.isRequired,
  loadDataset: PropTypes.func,
  params: PropTypes.shape({
    pipeID: PropTypes.string,
    subId: PropTypes.string,
  }),
  pipesLoaded: PropTypes.bool,
  systemsLoaded: PropTypes.bool,
  datasetsLoaded: PropTypes.bool,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  registerRefresh: PropTypes.func.isRequired,
  unregisterRefresh: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const leafPipes = leafPipesSelector(state);
  const isPipeLeaf = leafPipes.includes(ownProps.params.pipeID);
  return {
    datasets: state.datasets,
    isPipeLeaf,
    pipe: state.pipes[ownProps.params.pipeID],
    pipesLoaded: state.loadStatus.pipesLoaded,
    systemsLoaded: state.loadStatus.systemsLoaded,
    datasetsLoaded: state.loadStatus.datasetsLoaded,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadDataset: (id) => dispatch(DatasetActions.load(id)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(PipeDashboard);
