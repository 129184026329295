import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import usePipeEntitiesIndependent from '../../hooks/usePipeEntitiesIndependent';

const useStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonGroup: {
    height: '24px',
    marginLeft: '10px',
    '& .MuiButtonGroup-grouped': {
      minWidth: '34px',
    },
    '& .MuiButton-label': {
      height: '22px',
    },
    '& .MuiButton-outlinedSizeSmall': {
      padding: '0px 7px',
    },
    '& svg': {
      height: '16px',
      width: '16px',
    },
  },
  message: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const EntityNavigator = (props) => {
  const classes = useStyle();
  const { pipeId, pipeConfig, limit, sinkDatasetId, onEntityFetched, stage, subUrl, token } = props;

  const { entities, selectedEntity, selectedEntityIndex, selectNextEntity, selectPreviousEntity } =
    usePipeEntitiesIndependent({ pipeId, pipeConfig, limit, sinkDatasetId, stage, subUrl, token });

  useEffect(() => {
    onEntityFetched(selectedEntity);
  }, [selectedEntity, onEntityFetched]);

  return (
    <div className={classes.container}>
      <div className={classes.navigation}>
        <ButtonGroup className={classes.buttonGroup} size="small">
          <Button
            onClick={selectPreviousEntity}
            disabled={selectedEntityIndex === 0 || selectedEntityIndex === -1}
          >
            <ArrowBack />
          </Button>
          <Button
            onClick={selectNextEntity}
            disabled={selectedEntityIndex === entities.length - 1 || entities.length === 0}
          >
            <ArrowForward />
          </Button>
        </ButtonGroup>
        <div className={classes.message}>
          {entities.length === 0
            ? 'No source entities found'
            : `${selectedEntityIndex + 1}/${entities.length}`}
        </div>
      </div>
    </div>
  );
};

EntityNavigator.propTypes = {
  pipeId: PropTypes.string,
  pipeConfig: PropTypes.shape({}),
  sinkDatasetId: PropTypes.string,
  limit: PropTypes.number,
  onEntityFetched: PropTypes.func.isRequired,
  stage: PropTypes.string,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

EntityNavigator.defaultProps = {
  stage: 'source',
  limit: 50,
};

export default EntityNavigator;
