import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';

import { PermissionsEditor } from 'Common/PermissionsEditor';

function SystemPermissions(props) {
  const systemId = props.params.systemID;
  return (
    <div className="scrollArea">
      <p>
        These settings inherit their default values from the{' '}
        <SesamLink to={`/subscription/${props.subId}/systems/prototype-permissions`}>
          system prototype settings
        </SesamLink>
        .
      </p>
      <PermissionsEditor
        permissionPath={['systems', systemId]}
        registerRefresh={props.registerRefresh}
        unregisterRefresh={props.unregisterRefresh}
      />
    </div>
  );
}

SystemPermissions.propTypes = {
  params: PropTypes.shape({
    systemID: PropTypes.string.isRequired,
  }),
  subId: PropTypes.string.isRequired,
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    subId: state.subscription.id,
  };
}

export default connect(mapStateToProps)(SystemPermissions);
