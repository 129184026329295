import React from 'react';
import PropTypes from 'prop-types';

import './EmptyState.css';

const EmptyState = ({ children }) => (
  <div className="empty-state-container">
    <div>{children}</div>
  </div>
);

EmptyState.propTypes = {
  children: PropTypes.node,
};

EmptyState.defaultProps = {
  children: 'Nothing to see here.',
};

export default EmptyState;
