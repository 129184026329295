import { Json } from '../types/json';

type TokenType =
  | 'NEWLINE'
  | 'WHITESPACE'
  | 'OPEN_BRACE'
  | 'CLOSE_BRACE'
  | 'BRACKET'
  | 'COLON'
  | 'COMMA'
  | 'NUMBER_LITERAL'
  | 'STRING_KEY'
  | 'STRING_LITERAL'
  | 'BOOLEAN_LITERAL'
  | 'NULL';

type TokenInfo = {
  regex: RegExp;
  tokenType: TokenType;
};

const tokenInfos: TokenInfo[] = [
  { regex: /^\n/, tokenType: 'NEWLINE' },
  { regex: /^\s+/, tokenType: 'WHITESPACE' },
  { regex: /^{/, tokenType: 'OPEN_BRACE' },
  { regex: /^}/, tokenType: 'CLOSE_BRACE' },
  { regex: /^[\[\]]/, tokenType: 'BRACKET' },
  { regex: /^:/, tokenType: 'COLON' },
  { regex: /^,/, tokenType: 'COMMA' },
  { regex: /^-?\d+(?:\.\d+)?(?:e[+\-]?\d+)?/i, tokenType: 'NUMBER_LITERAL' },
  { regex: /^"(?:\\.|[^"])*"/, tokenType: 'STRING_LITERAL' },
  { regex: /^true|false/, tokenType: 'BOOLEAN_LITERAL' },
  { regex: /^null/, tokenType: 'NULL' },
];

type Token = {
  type: TokenType;
  value: string;
};

function getTokens(json: Json | string) {
  let input = typeof json === 'string' ? json : JSON.stringify(json);

  const tokens: Token[] = [];
  let foundToken = false;

  let match;
  let i;
  let numTokenTypes = tokenInfos.length;

  do {
    for (i = 0; i < numTokenTypes; i++) {
      match = tokenInfos[i].regex.exec(input);
      if (match) {
        let _type = tokenInfos[i].tokenType;
        if (_type === "STRING_LITERAL") {
          // We want to differentiate a string key vs a string value, but doing it
          // in regex has some bad perf edge cases (see IS-12853)
          const nextChar = input[match[0].length];
          if (nextChar === ":") {
            _type = "STRING_KEY";
          }
        }
        tokens.push({
          type: _type,
          value: match[0].replace(/^\"/, '').replace(/\"$/, ''),
        });
        input = input.substring(match[0].length);
        foundToken = true;
        break;
      } else {
        foundToken = false;
      }
    }
  } while (input.length > 0 && foundToken);

  return tokens;
}

function lexer(input: Json | string) {
  return getTokens(input);
}

function getLine(tokens: Token[], pathArray: string[]) {
  let newLineCounter = 0;
  let level = -1;
  let pathLevel = 0;
  for (const token of tokens) {
    let currentProp = pathArray[pathLevel];
    if (token.value && token.value.trim() === currentProp && level === pathLevel) {
      if (pathLevel >= pathArray.length - 1) break;
      pathLevel = pathLevel + 1;
    }
    switch (token.type) {
      case 'OPEN_BRACE': {
        level = level + 1;
        break;
      }
      case 'CLOSE_BRACE': {
        level = level - 1;
        break;
      }
      case 'NEWLINE': {
        newLineCounter = newLineCounter + 1;
        break;
      }
    }
  }
  return newLineCounter;
}

export function pathToLineNumber(input: Json | string, pathArray: string[]) {
  const tokens = lexer(input);
  return getLine(tokens, pathArray);
}
