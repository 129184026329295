import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LoadingPanel } from 'Common/LoadingPanel';
import MonitoringActions from 'Redux/thunks/monitoring';
import PipeActions from 'Redux/thunks/pipes';
import { confirmBefore } from 'Common/Confirmation';
import Box from 'Common/Box/Box';
import Button from 'Common/Button/Button';
import InsightsGraphs from './InsightsCharts';
import InsightsInfo from './InsightsInfo';
import InsightsEmptyState from './InsightsEmptyState';
import PortalFallbackMessage from 'Common/PortalFallbackMessage/PortalFallbackMessage';

class Insights extends React.Component {
  constructor(props) {
    super(props);

    this.checkInsightsEnabled = () => {
      this.setState({ loading: true });
      return this.props.getPipeSettings(this.props.params.pipeID).then((settings) => {
        if (settings['insights_enabled']) {
          this.setState({ enabled: true }, () => this.loadData());
        } else {
          this.setState({ enabled: false, loading: false });
        }
      });
    };

    this.loadData = () =>
      this.props.getData(this.props.params.pipeID).then((data) => {
        this.setState({ data, loading: false });
      });

    this.handleToggleInsight = (booleanValue) => {
      confirmBefore(
        `${
          booleanValue
            ? 'Enabling insights will incur additional costs. Are you sure?'
            : 'This will disable insights for this pipe. Proceed?'
        }`,
        () =>
          this.props
            .getPipeSettings(this.props.params.pipeID)
            .then((response) => {
              const body = { ...response, insights_enabled: booleanValue };
              return body;
            })
            .then((body) =>
              this.props
                .updatePipeSettings(this.props.params.pipeID, body)
                .then(() => this.checkInsightsEnabled())
            )
      );
    };

    this.state = {
      enabled: false,
      data: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.checkInsightsEnabled();
  }

  render() {
    if (this.state.loading) {
      return <LoadingPanel loadingMessage={'Crunching numbers...'} />;
    }
    return (
      <React.Suspense fallback={<LoadingPanel />}>
        <main className="scrollArea insights">
          {this.props.isPortalInFallbackMode && <PortalFallbackMessage />}
          {this.state.enabled ? (
            <div className="row">
              <div className="col gr-secondary">
                <InsightsInfo />
                <Box>
                  <Box.Content padding>
                    <div className="insights__disable">
                      <span>
                        Insights are{' '}
                        <span className="insights__highlight insights__highlight--enabled">
                          enabled
                        </span>
                      </span>
                      <Button text="Disable" onClick={() => this.handleToggleInsight(false)} />
                    </div>
                  </Box.Content>
                </Box>
              </div>
              <InsightsGraphs data={this.state.data} />
            </div>
          ) : (
            <div className="empty-state__container">
              <InsightsEmptyState onEnable={() => this.handleToggleInsight(true)} />
            </div>
          )}
        </main>
      </React.Suspense>
    );
  }
}

Insights.propTypes = {
  params: PropTypes.shape({
    pipeID: PropTypes.string.isRequired,
  }),
  subId: PropTypes.string.isRequired,
  getData: PropTypes.func.isRequired,
  updatePipeSettings: PropTypes.func.isRequired,
  getPipeSettings: PropTypes.func.isRequired,
  isPortalInFallbackMode: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  subId: state.subscription.id,
  isPortalInFallbackMode: state.globals.status.is_fallback_mode,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (pipeId) => dispatch(MonitoringActions.getPipeStats(pipeId)),
  getPipeSettings: (pipeId) => dispatch(PipeActions.getPipeSettings(pipeId)),
  updatePipeSettings: (pipeId, obj) => dispatch(PipeActions.updatePipeSettings(pipeId, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
