import React from 'react';
import PropTypes from 'prop-types';
import SubscriptionLink from './SubscriptionLink';

function PipeLink({ pipeId, children, ...restProps }) {
  if (!children) {
    children = pipeId;
  }
  return (
    <SubscriptionLink title={`Go to ${pipeId}`} to={`/pipes/pipe/${pipeId}`} {...restProps}>
      {children}
    </SubscriptionLink>
  );
}

PipeLink.propTypes = {
  pipeId: PropTypes.string,
  children: PropTypes.node,
};

export default PipeLink;
