import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ClipboardJS from 'clipboard';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';

import get from 'lodash/get';
import moment from 'moment';

import Button from 'Common/Button/Button';

import { apologise } from 'Redux/thunks/apology';
import useResource from '../../hooks/useResource';

import Box from 'Common/Box/Box';

import SesamCheckbox from 'Common/SesamCheckboxField/SesamCheckboxField';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

import './ShareableLinksStyle.css';

const Links = ({ links }) => {
  new ClipboardJS('.links__link-copy');
  return (
    <div className="links">
      {links.map((link, index) => (
        <div className="links__link" key={link}>
          {link.indexOf('entities') !== -1 && (
            <div className="links__link-header">
              <div className="links__link-description">Entities</div>
              <div className="links__link-copy" data-clipboard-target={`#link-${index}`}>
                Copy
              </div>
            </div>
          )}
          {link.indexOf('sdshare-collection') !== -1 && (
            <div className="links__link-header">
              <div className="links__link-description">SDShare collection</div>
              <div className="links__link-copy" data-clipboard-target={`#link-${index}`}>
                Copy
              </div>
            </div>
          )}
          {link.indexOf('sdshare-fragments') !== -1 && (
            <div className="links__link-header">
              <div className="links__link-description">SDShare fragments</div>
              <div className="links__link-copy" data-clipboard-target={`#link-${index}`}>
                Copy
              </div>
            </div>
          )}
          <SesamTextField
            margin="none"
            id={`link-${index}`}
            readOnly={true}
            type="text"
            value={link}
          />
        </div>
      ))}
    </div>
  );
};

Links.propTypes = {
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const ExpRenderer = ({ value }) => {
  if (value || value === 0) {
    const timestamp = moment.unix(value);
    const humanReadable = moment().to(timestamp);

    if (timestamp.isBefore(moment())) {
      return (
        <span
          title={timestamp.toISOString() + ' (this link is expired)'}
          className="shareable-links__exp--expired"
        >
          {humanReadable}
        </span>
      );
    } else {
      return (
        <span
          title={'Expires at ' + timestamp.toISOString()}
          className="shareable-links__exp--active"
        >
          {humanReadable}
        </span>
      );
    }
  } else {
    return <span>{'Indefinite'}</span>;
  }
};

const defaultExp = 24;
const defaultDescription = '';
const defaultIsIndefinite = false;

const ShareableLinks = ({ apologise, pipeId, subUrl, token }) => {
  /* States */
  const [exp, setExp] = useState(defaultExp);
  const [isIndefinite, setIsIndefinite] = useState(defaultIsIndefinite);
  const [description, setDescription] = useState(defaultDescription);
  const [, addingLink, errorAddingLink, addLink] = useResource();
  const [, deletingLink, errorDeletingLink, deleteLink] = useResource();
  const [links, gettingLinks, errorLoadingLinks, getLinks] = useResource(
    `${subUrl}/publishers/${pipeId}/shareable-links`,
    {
      credentials: 'include',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
    true,
    []
  );

  /*Effects */
  useEffect(() => {
    getLinks();
  }, [addingLink, deletingLink]);

  useEffect(() => {
    if (errorAddingLink) apologise(errorAddingLink);
    if (errorDeletingLink) apologise(errorDeletingLink);
    if (errorLoadingLinks) apologise(errorLoadingLinks);
  }, [errorAddingLink, errorDeletingLink, errorLoadingLinks]);

  /* Handlers */
  const handleAddingLink = () => {
    const body = {};
    if (description) body.description = description;
    if (!isIndefinite) {
      const expTs = Math.floor(Date.now() / 1000) + exp * 60 * 60;
      body.exp = expTs;
    }

    const url = `${subUrl}/publishers/${pipeId}/shareable-links`;
    const options = {
      credentials: 'include',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(body),
    };

    addLink(url, options);
    setExp(defaultExp);
    setDescription(defaultDescription);
    setIsIndefinite(defaultIsIndefinite);
  };

  const handleDeletingLink = (id) => {
    const url = `${subUrl}/publishers/${pipeId}/shareable-links/${id}`;
    const options = {
      credentials: 'include',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    };

    if (confirm('Are you sure you want to delete this acces token?')) {
      deleteLink(url, options);
    }
  };

  /* Columns */
  const cols = [
    {
      id: 'desc',
      Header: 'Description',
      accessor: 'description',
      headerClassName: 'column_header--align-left',
      Cell: ({ value }) => <span>{value ? value : '(No description)'}</span>,
    },
    {
      id: 'exp',
      Header: 'Expires in',
      accessor: 'exp',
      headerClassName: 'column_header--align-left',

      Cell: ExpRenderer,
    },

    {
      id: 'expander',
      Header: 'URLs',
      width: 60,

      expander: true,
      sortable: false,
      Expander: ({ isExpanded }) => (
        <div className="shareable-links__column shareable-links__link">
          {isExpanded ? 'Hide' : 'Show'}
        </div>
      ),
    },
    {
      id: 'created',
      Header: 'Created',
      accessor: 'created_ts',
      headerClassName: 'column_header--align-left',
      Cell: (row) => {
        const timestamp = moment.unix(row.value / 1000000000);
        const humanReadable = timestamp.format('YYYY-MM-DD HH:mm:ss');
        return <span title={timestamp.toISOString()}>{humanReadable}</span>;
      },
    },
    {
      id: 'delete',
      Header: 'Delete',
      accessor: 'delete',
      width: 60,

      sortable: false,
      Cell: ({ original: { id } }) => (
        <div
          onClick={() => handleDeletingLink(id)}
          className="shareable-links__column shareable-links__link shareable-links__link--danger"
        >
          Delete
        </div>
      ),
    },
  ];

  return (
    <div className="shareable-links">
      {links.length < 1 ? (
        <span>
          There are no existing shareable links on this pipe. Create a new one with the form below.
        </span>
      ) : (
        <ReactTable
          loading={gettingLinks}
          className="-striped -highlight list-table"
          noDataText="No shareable links found"
          data={links}
          minRows={1}
          defaultSorted={[
            {
              id: 'exp',
              desc: true,
            },
          ]}
          columns={cols}
          pageSize={links.length}
          showPagination={false}
          SubComponent={({ original }) => (
            <div className="shareable-links__links">
              <Links links={get(original, 'urls', [])} />
            </div>
          )}
        />
      )}
      <Box className="shareable-links__create">
        <Box.Header>Create a new shareable link</Box.Header>
        <Box.Content padding>
          <div className="shareable-links__form">
            <div className="shareable-links__exp-input">
              <SesamTextField
                margin="none"
                label="Short description"
                type="text"
                value={description}
                onChange={(ev) => setDescription(get(ev, 'target.value'))}
              />
            </div>
            <div className="shareable-links__exp-input">
              <SesamTextField
                margin="none"
                label="Expires after (hours)"
                type="number"
                disabled={isIndefinite}
                value={exp}
                onChange={(ev) => setExp(Number(get(ev, 'target.value')))}
                min={0}
              />
            </div>
            <SesamCheckbox
              helperText={''}
              CheckboxProps={{
                value: isIndefinite,
                onChange: () => setIsIndefinite((isIndefinite) => !isIndefinite),
                title:
                  'The link will not have an expiration date. You can always delete it manually.',
              }}
              label="Indefinite"
              FormControlProps={{
                margin: 'none',
              }}
            />

            <div className="shareable-links__create-button">
              <Button text="Create" type="button" onClick={handleAddingLink} />
            </div>
          </div>
        </Box.Content>
      </Box>
    </div>
  );
};

ShareableLinks.propTypes = {
  apologise: PropTypes.func.isRequired,
  pipeId: PropTypes.string.isRequired,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  apologise: (msg) => dispatch(apologise(msg)),
});

export default connect(null, mapDispatchToProps)(ShareableLinks);
