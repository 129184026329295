import Numeric from 'Common/renderers/Numeric/Numeric';
import React from 'react';

export const PipeProgressStatus: React.FC<{ processed: number; total: number }> = ({
  processed = 0,
  total = 0,
}) => (
  <>
    <Numeric>{processed}</Numeric> / <Numeric>{total}</Numeric>
  </>
);
