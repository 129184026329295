import { useState, useCallback } from 'react';
import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';

export default function useLocalStorage(
  baseKey: string,
  key: string[] | string,
  defaultValue: any
) {
  const [storedValue, setStoredValue] = useState(() => {
    const value = getFromLocalStorage(baseKey, key, defaultValue);
    return value;
  });

  const setAndSaveValue = useCallback((val) => {
    setStoredValue(val);
    setIntoLocalStorage(baseKey, key, val);
  }, []);

  return [storedValue, setAndSaveValue];
}
