import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import datejs from 'dayjs';
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';
import Typography from 'Common/SesamTypography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Button from 'Common/Button/Button';
import HeaderButton from '../header-button';
import SesamCheckboxField from 'Common/SesamCheckboxField/SesamCheckboxField';
import { changelog, Change } from '../../changelog/changelog';
import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';
import { RootState } from '../../';

const useStyle = makeStyles((theme) => {
  return {
    changeTitle: {
      display: 'flex',
      flexDirection: 'column',
    },
    changeDate: { color: theme.palette.grey[700] },
    changesIcon: {
      width: '32px',
      height: '32px',
    },
    divider: {
      marginBottom: '10px',
    },
    lvl: {
      display: 'flex',
      marginTop: '15px',
      alignItems: 'center',
    },
    lvlCircle: {
      width: '10px',
      height: '10px',
      borderRadius: 50,
      backgroundColor: theme.palette.grey[300],
      marginRight: '3px',
    },
    lvlCircleFilled: {
      backgroundColor: theme.palette.primary.main,
    },
    lvlLabel: {
      color: theme.palette.grey[700],
      marginLeft: '7px',
    },
    okButton: { marginLeft: '15px' },
    tags: {
      marginBottom: '10px',
    },
    tag: {
      marginRight: '5px',
    },
    changeContent: {},
  };
});

const CHANGELOG_BASE_KEY = 'sesam--changelog';

function Changelog(props) {
  function close() {
    setShowUnreadChangesLS(showUnreadChanges);
    setOpen(false);
    setShowAllMode(false);
  }

  function markChangesAsRead() {
    if (unreadChanges.length > 0) {
      const lastSeen = unreadChanges[0].id;
      setLastSeen(lastSeen);
    }
    close();
  }

  function renderChange(change: Change) {
    return (
      <div>
        <div className={classes.changeTitle}>
          <Typography variant="h6">{change.title}</Typography>
          <Typography className={classes.changeDate} variant="subtitle2">
            {datejs(change.id).format('D MMM YYYY')}
          </Typography>
        </div>
        <div className={classes.lvl}>{renderLevel(change.lvl)}</div>
        <div
          className={classes.changeContent}
          dangerouslySetInnerHTML={{ __html: change.content }}
        />
        <div className={classes.tags}>{renderTags(change.tags)}</div>
      </div>
    );
  }

  function renderLevel(progress: string) {
    let lvl;
    switch (progress) {
      case 'Experimental':
        lvl = 0;
        break;
      case 'Feature':
        lvl = 1;
    }

    return (
      <Fragment>
        <div className={clsx([classes.lvlCircle, classes.lvlCircleFilled])} />
        <div
          className={clsx([classes.lvlCircle, progress === 'Feature' && classes.lvlCircleFilled])}
        />
        <Typography className={classes.lvlLabel} variant="subtitle2">
          {progress}
        </Typography>
      </Fragment>
    );
  }

  function renderTags(tags: string[]) {
    return tags.map((tag: string, i: number) => (
      <Chip size="small" key={`${tag}-${i}`} className={classes.tag} label={tag} />
    ));
  }

  const classes = useStyle();

  const [open, setOpen] = useState<boolean>(false);
  const [showAllMode, setShowAllMode] = useState<boolean>(false);
  const [lastSeen, setLastSeen] = useState<number | undefined>(undefined);
  const [showUnreadChanges, setShowUnreadChanges] = useState<boolean | undefined>(undefined);
  const [showUnreadChangesLS, setShowUnreadChangesLS] = useState<boolean | undefined>(undefined);

  const itestMode = useSelector((state: RootState) => state.globals.status.is_itest_mode);

  useEffect(() => {
    if (lastSeen === undefined) {
      const lastSeenFromLS = getFromLocalStorage(CHANGELOG_BASE_KEY, 'lastSeen', 0);
      setLastSeen(lastSeenFromLS);
    } else {
      setIntoLocalStorage(CHANGELOG_BASE_KEY, 'lastSeen', lastSeen);
    }
  }, [lastSeen]);

  useEffect(() => {
    if (showUnreadChangesLS === undefined) {
      const showUnreadChangesFromLS = getFromLocalStorage(
        CHANGELOG_BASE_KEY,
        'showUnreadChanges',
        true
      );
      setShowUnreadChangesLS(showUnreadChangesFromLS);
      setShowUnreadChanges(showUnreadChangesFromLS);
    } else {
      setIntoLocalStorage(CHANGELOG_BASE_KEY, 'showUnreadChanges', showUnreadChangesLS);
    }
  }, [showUnreadChangesLS]);

  const unreadChanges = useMemo(() => {
    if (lastSeen === undefined) return [];

    return changelog.filter((change: Change) => {
      return change.id > lastSeen;
    });
  }, [lastSeen]);

  let changes = [];
  if (unreadChanges.length > 0) {
    changes = [...unreadChanges];
  } else {
    if (showAllMode) changes = [...changelog];
    else {
      if (lastSeen !== changelog[0].id) changes = [...changelog];
    }
  }

  return (
    <Fragment>
      <HeaderButton darkModeActive={props.darkModeActive}>
        <button
          className="btn-link"
          onClick={() => {
            setOpen(true);
            setShowAllMode(true);
          }}
          title="See changelog…"
        >
          <Badge
            overlap="circular"
            color="error"
            variant="dot"
            invisible={unreadChanges.length === 0}
          >
            <NewReleasesOutlinedIcon className={classes.changesIcon} />
          </Badge>
        </button>
      </HeaderButton>
      <Dialog
        open={
          (open || (showUnreadChangesLS === true && unreadChanges.length > 0)) &&
          itestMode === false
        }
        onKeyUp={(e) => {
          if (e.key === 'Escape') {
            markChangesAsRead();
          }
        }}
      >
        <DialogTitle>What's new?</DialogTitle>
        <Divider />
        <DialogContent>
          {changes.map((change: Change, i) => (
            <Fragment key={i}>
              {renderChange(change)}
              {i < changes.length - 1 && <Divider className={classes.divider} />}
            </Fragment>
          ))}
        </DialogContent>
        <Divider />
        <DialogActions>
          <SesamCheckboxField
            label="Do not automatically show new entries"
            CheckboxProps={{
              checked: !showUnreadChanges,
              onChange: (ev) => {
                setShowUnreadChanges(!ev.target.checked);
              },
            }}
            FormControlLabelProps={{ labelPlacement: 'start' }}
          />
          <Button className={classes.okButton} onClick={() => markChangesAsRead()}>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default Changelog;
