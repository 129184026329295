import React from 'react';
import PropTypes from 'prop-types';

import './DetailView.css';

const context = React.createContext({});
const { Provider, Consumer } = context;

function DetailView({ children, initiallySelectedId }) {
  const [selected, setSelected] = React.useState(initiallySelectedId);
  const onSelect = (i) => {
    setSelected(i);
  };
  return (
    <Provider value={{ selected, onSelect }}>
      <div className="DetailView__Container">{children}</div>
    </Provider>
  );
}

DetailView.propTypes = {
  children: (props, propName) => {
    const children = props[propName];
    let error = false;
    if (React.Children.count(children) === 2) {
      if (
        (React.Children.toArray(children)[0].type === List &&
          React.Children.toArray(children)[1].type === Content) ||
        (React.Children.toArray(children)[1].type === List &&
          React.Children.toArray(children)[0].type === Content)
      ) {
        return null;
      } else error = true;
    } else error = true;
    if (error) {
      return new Error(
        'DetailView requires exactly two components, DetailView.List and DetailView.Content. Arbitrary order.'
      );
    }
  },
};

function List({ children }) {
  return <Consumer>{() => <div className="DetailView__List">{children}</div>}</Consumer>;
}

List.propTypes = {
  children: (props, propName) => {
    const children = props[propName];
    React.Children.forEach(children, (child) => {
      if (child.type !== Item) {
        throw new Error('DetailView.List only accepts an array of DetailView.Item components');
      }
    });
  },
};

function Item({ children, id }) {
  return (
    <Consumer>
      {({ selected, onSelect }) => (
        <div onClick={() => onSelect(id)} className="DetailView__Item">
          {children(selected === id, onSelect)}
        </div>
      )}
    </Consumer>
  );
}

Item.propTypes = {
  children: PropTypes.func,
};

function Content({ children }) {
  return (
    <Consumer>
      {({ selected }) => <div className="DetailView__Content">{children(selected)}</div>}
    </Consumer>
  );
}

Content.propTypes = {
  children: PropTypes.func.isRequired,
};

DetailView.Item = Item;
DetailView.List = List;
DetailView.Content = Content;

export default DetailView;
