import React, { PropsWithChildren } from 'react';
import Button from 'Common/Button/Button';
import SesamLink from 'Common/Links/SesamLink';
import './ActionPanel.css';

/*
 * List of actions, grouped to the left and to the right
 */
export function ActionPanel(props: PropsWithChildren<{}>) {
  return <div className="actionpanel">{props.children}</div>;
}

type ActionProps = PropsWithChildren<{
  enabled?: boolean;
  onActionClick: (ev: Event) => void;
  name: string;
  tooltip?: string;
  useListItem?: boolean;
  theme?: string;
}>;

/**
 * Simple action with click listener
 */
export function Action({
  enabled = true,
  onActionClick,
  name,
  tooltip = '',
  useListItem = true,
  theme,
  children,
}: ActionProps) {
  if (useListItem) {
    return (
      <li>
        <Button disabled={!enabled} onClick={onActionClick} title={tooltip} theme={theme}>
          {name}
        </Button>
        {children}
      </li>
    );
  } else {
    return (
      <>
        <Button disabled={!enabled} onClick={onActionClick} title={tooltip} theme={theme}>
          {name}
        </Button>

        {children}
      </>
    );
  }
}

type LinkActionProps = PropsWithChildren<{
  enabled: boolean;
  to: string;
  name: string;
}>;

/**
 * Action that links to another page
 */
export function LinkAction({ enabled, to, name }: LinkActionProps) {
  return (
    <li>
      {enabled ? (
        <SesamLink to={to}>{name}</SesamLink>
      ) : (
        <span className="link-disabled">{name}</span>
      )}
    </li>
  );
}
