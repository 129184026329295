import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { buildClassName, ClassNamePropType } from 'Internals/react-utils';
import Media from '../media';
import UserAvatar from '../user-avatar/UserAvatarContainer';

function UserBanner({ className, email, name, nowrap }) {
  return (
    <Media
      className={buildClassName('user-card', className)}
      img={<UserAvatar email={email} />}
      nowrap={nowrap}
      type="avatar"
    >
      <h3 className="name heading-section">{name}</h3>
      <small className="email">{email}</small>
    </Media>
  );
}

UserBanner.propTypes = {
  className: ClassNamePropType,
  email: PropTypes.string.isRequired,
  nowrap: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

UserBanner.defaultProps = {
  className: undefined,
  nowrap: false,
};

export default UserBanner;

function mapStateToProps(state) {
  return {
    name: state.user.name,
    email: state.user.email,
  };
}

export const CurrentUserBanner = connect(mapStateToProps)(UserBanner);
