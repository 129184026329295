import PropTypes from 'prop-types';
import React from 'react';

import { MenuAction } from '../menu';
import MenuToggler from '../menu-toggler';

export const FieldPropType = PropTypes.shape({
  header: PropTypes.string.isRequired,
  sorter: PropTypes.func,
});

const CardListSortSelector = function ({ fields, onChange, currentSortBy, currentSortAsc }) {
  const anchorPoint = {
    anchor: { hPos: 'right', vPos: 'bottom' },
    popover: { hPos: 'right', vPos: 'top' },
  };
  let sortDir = '';

  if (currentSortBy) {
    sortDir = currentSortAsc ? ' (asc)' : ' (desc)';
  }

  return (
    <div className="card-list__sort-selector">
      <MenuToggler
        anchorPoint={anchorPoint}
        label={`Sort by ${currentSortBy || '(none)'}${sortDir}`}
        labelClassName="btn btn--dropdown btn--small"
      >
        {fields.map((field) => (
          <MenuAction key={field.header} label={field.header} onClick={() => onChange(field)} />
        ))}
      </MenuToggler>
    </div>
  );
};

CardListSortSelector.propTypes = {
  currentSortAsc: PropTypes.bool,
  currentSortBy: PropTypes.string,
  fields: PropTypes.arrayOf(FieldPropType).isRequired,
  onChange: PropTypes.func.isRequired,
};

CardListSortSelector.defaultProps = {
  currentSortAsc: true,
  currentSortBy: undefined,
};

export default CardListSortSelector;
