import React, { useContext, useEffect, useCallback, createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import produce from 'immer';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

import ConfigStateRenderer from 'Common/table-list-renderers/config-state';

import {
  getPipesFromSystem,
  getPipesToSystem,
  getPipesWithSystemTransform,
  systemConfigSorter,
} from 'Internals/systems';
import { sortBooleanWithNullOrUndefinedValues, sortWithNullOrUndefinedValues } from 'Internals/sort';

import SystemsListPage from './systems-list-page';
import SystemActions from 'Redux/thunks/systems';
import { systemsSelector } from 'Redux/selectors';
import SystemLink from 'Common/Links/SystemLink';

import FilterTable, { getSortValues } from '../filter-table/FilterTable';

import Actions from './systems-list-actions';

import './style.css';
import useLocalStorage from '../../hooks/useLocalStorage';
import { bytesToSize, findInternalOriginInFilter, withSeparators } from 'Internals/utils';
import InfoTooltip from 'Common/InfoTooltip/InfoTooltip';
import Ellipsis from 'Common/Ellipsis';
import State from 'Common/renderers/State/State';

const LOCAL_STORAGE_KEY = 'sesam--data-table-3';

const filterLabelMapping = {
  _id: 'System',
  origin: 'Origin',
  tags: 'Tags',
  type: 'Type',
  name: 'Name',
  configGroup: 'Config group',
  systemGroup: 'System group',
  tenant: 'Tenant',
};

const emptyFilter = Object.freeze({
  _id: {
    selected: [],
    search: [],
  },
  name: {
    selected: [],
    search: [],
  },
  type: {
    selected: [],
    search: [],
  },
  origin: {
    selected: [],
    search: [],
  },
  tags: {
    selected: [],
    search: [],
  },
  configGroup: {
    selected: [],
    search: [],
  },
  systemGroup: {
    selected: [],
    search: [],
  },
  tenant: {
    selected: [],
    search: [],
  },
});

const defaultFilter = produce(emptyFilter, (draft) => {
  draft.origin.selected = ['user'];
});

const defaultVisibleCols = ['System', 'Type', 'Config', 'Pipes in', 'Pipes out'];

const defaultVisibleFacets = ['_id', 'type', 'origin', 'tags'];

const defaultSortBy = [
  {
    id: 'systems-link',
    desc: false,
  },
];

export function BytesUsedCell({ cell: { value } }) {
  const { showBytesExact, toggleShowBytesExact } = useContext(SystemsListContext);
  if (isUndefined(value)) {
    return '-';
  }
  return (
    <span
      onClick={() => toggleShowBytesExact()}
      title="Click to switch between representation"
      style={{ cursor: 'pointer' }}
    >
      {showBytesExact ? withSeparators(value) : bytesToSize(value)}
    </span>
  );
}

const SystemsListContext = createContext({
  showBytesExact: false,
  toggleShowBytesExact: () => {},
});

const SystemsList = (props) => {
  const {
    deleteSystem,
    loadAll,
    loadAllForced,
    pipes,
    inbounds,
    outbounds,
    transforms,
    systems,
    systemsDict,
    systemsLoaded,
    token,
    subUrl,
  } = props;

  const [showBytesExact, setShowBytesExact] = useLocalStorage(
    LOCAL_STORAGE_KEY,
    ['systems', 'showBytesExact'],
    false
  );

  const toggleShowBytesExact = () => {
    setShowBytesExact(!showBytesExact);
  };

  const columns = [
    {
      Header: 'System',
      fixed: true,
      id: 'systems-link',
      width: 300,
      accessor: (s) => ({
        id: s._id,
        link: s.link,
        description: get(s, ['config', 'original', 'description']),
      }),
      Cell: ({ cell: { value } }) => (
        <div data-selenium="system-id" style={{ display: 'flex' }}>
          <Ellipsis>
            <SystemLink systemId={value.link}>{value.id}</SystemLink>
          </Ellipsis>
          {value.description && (
            <InfoTooltip info={value.description} style={{ marginLeft: '5px' }} />
          )}
        </div>
      ),
      sortType: getSortValues((a, b) => {
        return sortWithNullOrUndefinedValues(a.id, b.id);
      }),
    },
    {
      Header: 'Name',
      id: 'systems-name',
      accessor: (s) => ({ name: s.name, link: s.link }),
      Cell: ({ cell: { value } }) => <SystemLink systemId={value.link}>{value.name}</SystemLink>,
      sortType: getSortValues((a, b) => {
        return sortWithNullOrUndefinedValues(a.name, b.name);
      }),
    },
    {
      Header: 'Type',
      id: 'systems-type',
      accessor: 'type',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Origin',
      id: 'systems-origin',
      accessor: 'runtime.origin',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Pipes in',
      id: 'systems-inbound',
      accessor: (s) => getPipesToSystem(s._id, pipes, outbounds).length,
      align: 'right',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Pipes out',
      id: 'systems-outbound',
      accessor: (s) => getPipesFromSystem(s._id, pipes, inbounds).length,
      align: 'right',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Config',
      id: 'systems-config',
      accessor: (s) => s,
      align: 'center',
      Cell: ({ cell: { value } }) => <ConfigStateRenderer value={value} type="system" />,
      sortType: getSortValues(systemConfigSorter),
    },
    {
      Header: 'Config group',
      id: 'systems-config-group',
      accessor: 'configGroup',
      Cell: ({ cell: { value } }) => {
        return <span>{value}</span>;
      },
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'System group',
      id: 'systems-system-group',
      accessor: 'systemGroup',
      Cell: ({ cell: { value } }) => {
        return <span>{value}</span>;
      },
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Bytes used',
      id: 'systems-storage',
      accessor: 'bytesUsed',
      align: 'center',
      Cell: BytesUsedCell,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Transforms',
      id: 'systems-transforms',
      accessor: (s) => getPipesWithSystemTransform(s._id, pipes, transforms).length,
      align: 'right',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Tenant',
      id: 'tenant-pipe',
      accessor: 'tenant',
      align: 'center',
      width: 75,
      Cell: ({ cell: { value } }) =>
        value !== 'Not Tenant' ? (
          <State title="Tenant pipe" className="status-green" text={value} />
        ) : (
          <State title="Not Tenant pipe" className="status-red" text="Not Tenant" />
        ),
      sortType: getSortValues((a, b) => {
        const aBool = a !== 'Not Tenant';
        const bBool = b !== 'Not Tenant';
        return sortBooleanWithNullOrUndefinedValues(aBool, bBool);
      }),
    },
  ];

  const renderActions = (selectedIds, clearSelection) => {
    const selectedSystems = getSelectedSystems(selectedIds);
    const deleteSystems = () => {
      onDelete(selectedIds);
      clearSelection();
    };
    return <Actions selectedSystems={selectedSystems} onDelete={deleteSystems} />;
  };

  const [includeInternal, setIncludeInternal] = useState(false);
  const handleFilterChanged = useCallback(
    (filter) => {
      const found = findInternalOriginInFilter(filter);
      if (found) {
        if (!includeInternal) {
          setIncludeInternal(true);
          loadAllForced(true);
        }
      } else {
        if (includeInternal) {
          setIncludeInternal(false);
          loadAllForced();
        }
      }
    },
    [loadAllForced, includeInternal]
  );

  const getSelectedSystems = (selectedIds) => {
    return selectedIds.map((id) => systemsDict[id]).filter(Boolean);
  };

  const onDelete = (selectedIds) => selectedIds.forEach((systemId) => deleteSystem(systemId));

  const curlString = `curl \
    -H "Authorization: bearer ${token}" \
    "${subUrl}/systems`;

  return (
    <SystemsListContext.Provider value={{ showBytesExact, toggleShowBytesExact }}>
      <SystemsListPage includeInternal={includeInternal}>
        <FilterTable
          actionsRenderer={renderActions}
          defaults={{
            filter: defaultFilter,
            sortBy: defaultSortBy,
            visibleCols: defaultVisibleCols,
            visibleFacets: defaultVisibleFacets,
          }}
          emptyFilter={emptyFilter}
          filterLabelMapping={filterLabelMapping}
          columns={columns}
          itemsToFilter={systems}
          tableKey="systems"
          onFilterChanged={handleFilterChanged}
          curl={curlString + `?include-internal-systems=${includeInternal}"`}
        />
      </SystemsListPage>
    </SystemsListContext.Provider>
  );
};

SystemsList.propTypes = {
  deleteSystem: PropTypes.func.isRequired,
  loadAll: PropTypes.func.isRequired,
  loadAllForced: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: PropTypes.object.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  pipes: PropTypes.shape({}).isRequired,
  inbounds: PropTypes.shape({}).isRequired,
  outbounds: PropTypes.shape({}).isRequired,
  transforms: PropTypes.shape({}).isRequired,
  systems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  systemsDict: PropTypes.shape({}).isRequired,
  systemsLoaded: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  subUrl: PropTypes.string,
  token: PropTypes.string,
};

const mapStateToProps = (state) => ({
  systems: systemsSelector(state),
  systemsDict: state.systems,
  systemsLoaded: state.loadStatus.systemsLoaded,
  pipes: state.pipes,
  inbounds: state.inbounds,
  outbounds: state.outbounds,
  transforms: state.transforms,
  subUrl: state.subscription.url,
  token: state.subscription.token,
});

const mapDispatchToProps = (dispatch) => ({
  deleteSystem: (id) => dispatch(SystemActions.delete(id)),
  loadAll: (includeInternal) => dispatch(SystemActions.loadAll(includeInternal)),
  loadAllForced: (includeInternal) => dispatch(SystemActions.loadAllForced(includeInternal)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SystemsList);
