import PropTypes from 'prop-types';
import React from 'react';

import SesamTextField from 'Common/SesamTextField/SesamTextField';
import Card from '../card';
import Identicon from '../identicon/Identicon';
import Media from '../media';

export const RoleProps = PropTypes.shape({
  'is-custom-role': PropTypes.bool.isRequired,
  'is-internal-role': PropTypes.bool.isRequired,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

function getStatus(role) {
  const labels = {};
  if (role['is-internal-role']) {
    labels.builtIn = 'Built-in';
  }
  if (role['is-custom-role']) {
    labels.custom = 'Custom';
  }
  return labels;
}
function RoleCard(props) {
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    props.handleFieldChange({ target: { name, value } });
  };
  const identicon = <Identicon input={props.role.name} />;
  const heading = props.editMode ? (
    <SesamTextField
      margin="normal"
      inputProps={{ autoFocus: true }}
      id="name"
      name="name"
      type="text"
      defaultValue={props.role.name}
      onChange={handleFieldChange}
      className="heading-section"
    />
  ) : (
    <h3 className="heading-section">{props.role.name}</h3>
  );
  const description = props.editMode ? (
    <SesamTextField
      margin="normal"
      label="Description"
      name="description"
      id="description"
      type="text"
      defaultValue={props.role.description}
      onChange={handleFieldChange}
      className="heading-section"
    />
  ) : (
    props.role.description || <em>No description</em>
  );

  return (
    <Card
      badges={getStatus(props.role)}
      header={
        <Media img={identicon} type="icon">
          {heading}
        </Media>
      }
      text={description}
      actions={props.actions}
    />
  );
}

RoleCard.propTypes = {
  role: RoleProps.isRequired,
  actions: PropTypes.node,
  editMode: PropTypes.bool,
  handleFieldChange: PropTypes.func,
};

export default RoleCard;
