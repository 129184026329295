import React from 'react';
import State from 'Common/renderers/State/State';
import { StatusClassName } from './PipeStatus';

export const PipeLeafStatus: React.FC<{ isLeaf: boolean }> = ({ isLeaf }) =>
  isLeaf ? (
    <State title="This pipe has no downstreams" className={StatusClassName.GREEN} text="Yes" />
  ) : (
    <State title="This pipe has downstreams" className={StatusClassName.RED} text="No" />
  );
