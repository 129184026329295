import React, { useEffect, useState } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import {
  getFilterFromQueryString,
  getQueryStringFromFilter,
  makeFilteredObjectsGetter,
  validateFilter,
} from 'Internals/filtering';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import produce from 'immer';
import remove from 'lodash/remove';
import usePrevious from '../../hooks/usePrevious';

function useFilter({
  defaults,
  keys,
  emptyFilter,
  filterLabelMapping,
  location,
  setSelection,
  addToast,
  subId,
  itemsToFilter,
  router,
}) {
  const [filteredItems, setFilteredItems] = useState([]);
  const [savedFiltersToast, setSavedFiltersToast] = useState('');
  const prevSearch = usePrevious(location.search);
  const [savedFilters, setSavedFilters] = useLocalStorage(
    keys.base,
    [keys.table, 'savedFilters'],
    {}
  );

  const [visibleFacets, setVisibleFacets] = useLocalStorage(
    keys.base,
    [keys.table, 'visibleFacets'],
    defaults.visibleFacets
  );

  const [filter, setFilter] = useLocalStorage(
    keys.base,
    [subId, keys.table, keys.facets],
    defaults.filter
  );

  /**
   * Setting initial filter
   *
   * The filter can be set from three different sources.
   * These are (in order of priority) [url, local storage, default filter]
   *
   * The initial filter is only set once when the component mounts,
   * as seen by the empty dependency array
   */
  useEffect(() => {
    let filterFromUrl;
    let filterFromLS;

    let initialFilter = defaults.filter;

    // Get filter from local storage

    filterFromLS = filter;

    // Get filter from url
    filterFromUrl = getFilterFromQueryString(location.search, filterLabelMapping);

    // If filter from local storage is valid, merge with empty filter
    if (validateFilter(filterFromLS) && filterFromLS && !isEmpty(filterFromLS)) {
      initialFilter = merge({}, emptyFilter, filterFromLS);
    }

    // If filter from URL is valid, merge with empty filter
    if (validateFilter(filterFromUrl) && filterFromUrl && !isEmpty(filterFromUrl)) {
      initialFilter = merge({}, emptyFilter, filterFromUrl);
    }

    // Set initial filter
    setFilter(initialFilter);
  }, []); // TODO: Should we add dependencies? Could this be handled a better way?

  /**
   * Add facets that are in the url to the visible facets array,
   * so that all facets with values are visible
   */
  useEffect(() => {
    if (prevSearch !== location.search) {
      const filterFromUrl = getFilterFromQueryString(location.search, filterLabelMapping);
      const vf = Object.keys(filterFromUrl).reduce(
        (acc, key) => {
          if (!acc.includes(key)) {
            acc.push(key);
          }
          return acc;
        },
        [...visibleFacets]
      );
      setVisibleFacets(vf);
    }
  }, [location.search, prevSearch, visibleFacets, filterLabelMapping, setVisibleFacets]);

  /**
   * Handles toggling of visibility of facets
   * @param {string} facetId
   */
  const handleToggleFacetVisibility = (facetId) => {
    const vf = [...visibleFacets];
    const f = { ...filter };

    if (vf.includes(facetId)) {
      vf.splice(vf.indexOf(facetId), 1);
      const facet = { ...f[facetId] };
      facet.selected = [];
      facet.search = [];
      f[facetId] = facet;
    } else {
      const insertIndex = Object.keys(f).indexOf(facetId);
      vf.splice(insertIndex, 0, facetId);
    }

    setVisibleFacets(vf);
    handleFilterUpdate(f);
  };

  /**
   * Set filtered items
   */
  useEffect(() => {
    if (!isEmpty(filter)) {
      setFilteredItems(makeFilteredObjectsGetter(itemsToFilter || [], filter));
    }
  }, [itemsToFilter, filter]);

  /**
   * Show toast message when creating/editing/deleting saved filters
   */
  useEffect(
    () => {
      if (savedFiltersToast) {
        addToast(savedFiltersToast);
        setSavedFiltersToast('');
      }
    },
    [addToast, savedFiltersToast] // FIXME: Remove state functions as deps
  );

  /**
   * Handlers
   */

  /**
   *  Handles updating the filter state, and reflect the
   *  change to the URL
   * @param {obj} filter
   */
  // FIXME: use useEffect
  const handleFilterUpdate = (filter) => {
    const isReset = isEmpty(filter);
    if (isReset) {
      filter = defaults.filter;
    }
    setFilter(filter);
    setSelection([]);

    if (isReset) {
      router.replace({
        pathname: location.pathname,
      });
    } else {
      const search = getQueryStringFromFilter(filter, filterLabelMapping);
      router.replace({
        pathname: location.pathname,
        search,
      });
    }
  };

  /**
   * Handles clicking a saved filter
   * @param {string} filterQueryString
   */
  const handleSavedFilterClick = (filterQueryString) => {
    const filterFromQueryString = getFilterFromQueryString(filterQueryString, filterLabelMapping);

    if (
      validateFilter(filterFromQueryString) &&
      filterFromQueryString &&
      !isEmpty(filterFromQueryString)
    ) {
      const newFilter = merge({}, emptyFilter, filterFromQueryString);
      handleFilterUpdate(newFilter);
    }
  };

  /**
   * Handles deleting a saved filter
   * @param {string} key
   */
  const handleSavedFilterDeleteClick = (key) => {
    const alreadySavedFilters = { ...savedFilters };
    delete alreadySavedFilters[key];
    setSavedFilters(alreadySavedFilters);
    setSavedFiltersToast(`${key} has been removed from saved filters`);
  };

  /**
   * Handles saving a new filter
   * @param {string} name
   */
  const handleSaveFilter = (name) => {
    let toastMessage;

    const alreadySavedFilters = { ...savedFilters };

    const filterQueryString = getQueryStringFromFilter(filter, filterLabelMapping);

    if (!filterQueryString) {
      setSavedFiltersToast('Cannot save. Filter is empty.');
      return;
    }

    const filterExistsUnderKey = Object.keys(alreadySavedFilters).filter((key) => {
      const savedFilter = alreadySavedFilters[key];
      if (savedFilter === filterQueryString) return true;
    })[0];

    if (filterExistsUnderKey) {
      delete alreadySavedFilters[filterExistsUnderKey];
      toastMessage = `Changed name of saved filter from ${filterExistsUnderKey} to ${name}`;
    } else {
      toastMessage = `Added ${name} to saved filters`;
    }

    alreadySavedFilters[name] = filterQueryString;

    setSavedFilters(alreadySavedFilters);
    setSavedFiltersToast(toastMessage);
  };

  /**
   * Handles updating a selected value in the filter
   * based on an id
   * @param {string} id
   * @param {string} value
   */
  const handleUpdateFilterSelected = (id, value) => {
    const updatedFilter = produce(filter, (draft) => {
      const facet = draft[id];
      if (facet.selected.includes(value)) {
        remove(facet.selected, (s) => s === value);
      } else {
        facet.selected.push(value);
      }
    });

    handleFilterUpdate(updatedFilter);
  };

  /**
   * Handles clearing a selected value in the filter
   * based on an id
   * @param {string} id
   */
  const handleClearFilterSelected = (id) => {
    const updatedFilter = produce(filter, (draft) => {
      draft[id].selected = [];
    });

    handleFilterUpdate(updatedFilter);
  };

  /**
   * Handles updating a search value in the filter
   * based on an id
   * @param {string} id
   * @param {string} value
   */
  const handleUpdateFilterSearch = (id, value) => {
    const updatedFilter = produce(filter, (draft) => {
      const facet = draft[id];
      const rxValue = `${value}*`;
      if (!facet.search.includes(rxValue)) {
        facet.search.push(rxValue);
      }
    });

    handleFilterUpdate(updatedFilter);
  };

  /**
   * Handles removing a search value in the filter
   * based on an id
   * @param {string} id
   * @param {string} value
   */
  const handleRemoveFilterSearch = (id, value) => {
    const updatedFilter = produce(filter, (draft) => {
      remove(draft[id].search, (c) => c === value);
    });

    handleFilterUpdate(updatedFilter);
  };

  /**
   * Handle clearing search values in the filter
   * based on an id
   * @param {string} id
   */
  const handleClearFilterSearch = (id) => {
    const updatedFilter = produce(filter, (draft) => {
      draft[id].search = [];
    });

    handleFilterUpdate(updatedFilter);
  };

  /**
   * Handles resetting the filter
   */
  const handleResetFilter = () => {
    handleFilterUpdate({});
  };

  return {
    handleUpdateFilterSearch,
    handleRemoveFilterSearch,
    handleClearFilterSearch,
    handleResetFilter,
    handleClearFilterSelected,
    handleUpdateFilterSelected,
    handleSaveFilter,
    handleSavedFilterClick,
    handleSavedFilterDeleteClick,
    handleToggleFacetVisibility,
    filter,
    filteredItems,
    savedFilters,
    visibleFacets,
  };
}

export default useFilter;
