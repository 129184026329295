import React from 'react';
import PropTypes from 'prop-types';
import { amountToSize, withSeparators } from 'Internals/utils';

import './PieHub.css';
/*
 * Generates an SVG pie chart for the center hub with labels and icons.
 *
 * @see {http://wiki.scribus.net/canvas/Making_a_Pie_Chart}
 */

class PieHub extends React.Component {
  constructor(props) {
    super(props);

    this._renderPaths = () => {
      const radCircumference = Math.PI * 2;
      const center = this.props.size / 2;
      const radius = center; // pad to prevent clipping
      const total = this.props.slices.reduce((totalValue, slice) => totalValue + slice.value, 0);

      let radSegment = 0;
      let lastPos = {
        x: radius,
        y: 0,
      };

      const sliceData = this.props.slices.map((slice, index) => {
        const color = slice.color;
        const value = slice.value;
        const calcPoint = (segment) => ({
          x: 100 + Math.cos(segment) * (radius - 20),
          y: 100 - Math.sin(segment) * (radius - 20),
        });
        const labelOffset = (Math.PI / 180) * 8;
        const valuePercentage = value / total;

        // should the arc go the long way round?
        const longArc = valuePercentage <= 0.5 ? 0 : 1;

        const delta = valuePercentage * radCircumference;
        // center label in middle of the segment
        const labelCenter = radSegment + delta * 0.5;
        radSegment += delta;
        const nextPos = {
          x: Math.cos(radSegment) * radius,
          y: Math.sin(radSegment) * radius,
        };

        let textPos = calcPoint(labelCenter + labelOffset);

        // d is a string that describes the path of the slice.
        // The weirdly placed minus signs [eg, (-(lastPoint.y))] are due to the fact
        // that our calculations are for a graph with positive Y values going up,
        // but on the screen positive Y values go down.
        const d = [
          `M ${center},${center}`,
          `l ${lastPos.x},${-lastPos.y}`,
          `a ${radius},${radius},0,${longArc},0,${nextPos.x - lastPos.x},${-(
            nextPos.y - lastPos.y
          )}`,
          'z',
        ].join(' ');

        lastPos = nextPos;

        return {
          onClick: slice.onClick,
          color: slice.color,
          value: slice.value,
          name: slice.name,
          d,
          textPos,
        };
      });

      const slices = sliceData.map(({ value, onClick, name, color, d }, index) => {
        if (value === total) {
          return (
            <g key={index} onClick={onClick} className="segment">
              <circle r={radius} cx={radius} cy={radius} fill={color} key={index} />
              <title>{`${name} (${withSeparators(value)} entities)`}</title>
            </g>
          );
        }
        if (value === 0) {
          return null;
        }
        return (
          <g key={index} onClick={onClick} className="segment">
            <path d={d} fill={color}>
              <title>{`${name} (${withSeparators(value)} entities)`}</title>
            </path>
          </g>
        );
      });

      const labels = sliceData.map(({ value, textPos, name, color }, index) => {
        if (value === total) {
          <text
            key={index}
            fill="#fff"
            x={textPos.x}
            y={textPos.y}
            dy=".3em"
            textAnchor="middle"
            className="outlined-svg-text"
          >
            <title>{`${name} (${withSeparators(value)} entities)`}</title>
            {amountToSize(value)}
          </text>;
        }
        if (value === 0) return null;

        return (
          <text
            fill="#fff"
            key={index}
            x={textPos.x}
            y={textPos.y}
            dy=".3em"
            textAnchor="middle"
            className="outlined-svg-text"
          >
            <title>{`${name} (${withSeparators(value)} entities)`}</title>
            {amountToSize(value)}
          </text>
        );
      });

      return (
        <>
          {slices}
          <circle r="60" cx={center} cy={center} fill={this.props.overlayColor} />
          {labels}
        </>
      );
    };
  }

  render() {
    const size = this.props.size;
    const center = size / 2;

    return (
      <>
        <svg viewBox={`0 0 ${size} ${size}`} style={{ overflow: 'visible' }}>
          <g transform={`rotate(0 ${center} ${center})`}>{this._renderPaths()}</g>
        </svg>
        <div className="piehub-center">{this.props.children}</div>
      </>
    );
  }
}

PieHub.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  slices: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired, // hex color
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  overlayColor: PropTypes.string,
};

PieHub.defaultProps = {
  size: 200,
  overlayColor: '#eee',
};

export default PieHub;
