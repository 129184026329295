import React from 'react';
import SesamLink from 'Common/Links/SesamLink';
import { Form, FormActions } from 'Common/forms';
import FocusPanel from '../../../components/focus-panel';

const ConnectionFailGuard = () => {
  return (
    <FocusPanel>
      <div>
        <h2 className="heading-page">Not connected</h2>
        <p>Subscription does not exist</p>
      </div>
      <Form component="div">
        <FormActions>
          <SesamLink to={`/dashboard`}>Go to dashboard</SesamLink>
        </FormActions>
      </Form>
    </FocusPanel>
  );
};

export default ConnectionFailGuard;
