import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import SubActions, { findSub, isInvite } from 'Redux/thunks/subscriptions';
import { Form, FormActions } from 'Common/forms';

import FocusPanel from '../../../components/focus-panel';
import PromiseButton from '../../../components/promise-button';

export default function (doneComponent) {
  function MemberGuard(props) {
    if (props.invited) {
      return (
        <FocusPanel>
          <div>
            <h2 className="heading-page">Invitation pending</h2>
            <p>Accept this invitation to access this subscription.</p>
          </div>
          <Form component="div">
            <FormActions>
              <PromiseButton onClick={() => props.onAccept(props.subId)} pending="Accepting…">
                Accept
              </PromiseButton>
              <PromiseButton onClick={() => props.onDecline(props.subId)} pending="Declining…">
                Decline
              </PromiseButton>
            </FormActions>
          </Form>
        </FocusPanel>
      );
    }
    const doneProps = Object.assign({}, props);
    delete doneProps.invited;
    return React.createElement(doneComponent, doneProps);
  }

  MemberGuard.propTypes = {
    invited: PropTypes.bool.isRequired,
    subId: PropTypes.string.isRequired,
    onAccept: PropTypes.func,
    onDecline: PropTypes.func,
  };

  function mapDispatchToProps(dispatch) {
    return {
      onAccept: (subId) => dispatch(SubActions.accept(subId)),
      onDecline: (subId) => dispatch(SubActions.decline(subId)),
    };
  }

  function mapStateToProps(state) {
    const sub = findSub(state.subscriptions)(state.subscription.id);
    return {
      invited: isInvite(sub),
      subId: state.subscription.id,
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(MemberGuard);
}
