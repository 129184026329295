import React from 'react';
import PropTypes from 'prop-types';

import { ActionPanel } from '../action/ActionPanel';
import { DeleteAction } from '../action/DeleteAction';

const DatasetsListActions = (props) => (
  <div className="list-actions">
    <ActionPanel>
      <DeleteAction onDelete={props.onDelete} selection={props.selectedDatasets} title="dataset" />
    </ActionPanel>
  </div>
);

DatasetsListActions.propTypes = {
  onDelete: PropTypes.func.isRequired,
  selectedDatasets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default DatasetsListActions;
