import React from 'react';
import { default as MUIFormControlLabel } from '@material-ui/core/FormControlLabel';
import { FormControlLabelProps as MUIFormControlLabelProps } from '@material-ui/core/FormControlLabel';

export interface FormControlLabelProps extends MUIFormControlLabelProps {}

const FormControlLabel: React.FC<FormControlLabelProps> = (props) => {
  return <MUIFormControlLabel {...props} />;
};

export default FormControlLabel;
