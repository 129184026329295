import React from 'react';
import PropTypes from 'prop-types';

import MenuToggler from '../menu-toggler';
import { MenuAction, MenuActionLink } from '../menu';
import { confirmBefore } from 'Common/Confirmation';
import MenuIcon from '../../images/icons/menu.svg';

import './style.css';

class SystemActionsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { actionParameter: '' };
  }

  render() {
    const menuIcon = <MenuIcon className="system-actions-menu" />;

    return (
      <MenuToggler label={menuIcon} labelClassName="system-actions-menu__toggler">
        <MenuActionLink
          label="Duplicate"
          to={`/subscription/${this.props.subId}/systems/new/${this.props.system._id}`}
        />
        <MenuAction
          label="Delete"
          onClick={() =>
            confirmBefore('Are you sure you want to delete this system?', () =>
              this.props.onDelete(this.props.system._id)
            )
          }
        />
      </MenuToggler>
    );
  }
}

SystemActionsMenu.propTypes = {
  onDelete: PropTypes.func.isRequired,
  system: PropTypes.object.isRequired,
  subId: PropTypes.string.isRequired,
};

export default SystemActionsMenu;
