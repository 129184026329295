import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SystemSecrets from '../../../components/system-secrets';

function SystemSecretsPage(props) {
  return (
    <div className="stack col gr-primary">
      <SystemSecrets
        systemID={props.system._id}
        registerRefresh={props.registerRefresh}
        unregisterRefresh={props.unregisterRefresh}
      />
    </div>
  );
}

SystemSecretsPage.propTypes = {
  system: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {
    system: state.systems[ownProps.params.systemID],
  };
}

export default connect(mapStateToProps)(SystemSecretsPage);
