import React from 'react';
import PropTypes from 'prop-types';
import './Bytes.css';
import Numeric from '../Numeric/Numeric';
import { bytesToSize } from 'Internals/utils';

class Bytes extends React.Component {
  constructor(props) {
    super(props);

    this.toggleShowingExact = () => {
      if (this.props.onToggleShowingExact) {
        this.props.onToggleShowingExact(!this.state.isShowingExact);
      } else {
        this.setState((state) => ({ isShowingExact: !state.isShowingExact }));
      }
    };

    this.state = { isShowingExact: this.props.isShowingExact };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isShowingExact !== prevProps.isShowingExact) {
      this.setState({ isShowingExact: this.props.isShowingExact });
    }
  }

  render() {
    return (
      <div
        className={`number--bytes ${this.props.className}`}
        style={this.props.style}
        onClick={this.toggleShowingExact}
        title={'Click to switch between exact and more readable values'}
      >
        {this.state.isShowingExact ? (
          <Numeric>{this.props.children}</Numeric>
        ) : (
          <span>{bytesToSize(this.props.children)}</span>
        )}
      </div>
    );
  }
}

Bytes.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
  children: PropTypes.any.isRequired,
  withSeparators: PropTypes.bool,
  isShowingExact: PropTypes.bool,
  onToggleShowingExact: PropTypes.func,
  title: PropTypes.string,
};
Bytes.defaultProps = {
  className: '',
  style: {},
  children: 0,
  toggleBytes: false,
  withSeparators: true,
  isShowingExact: true,
};

export default Bytes;
