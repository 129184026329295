import State from 'Common/renderers/State/State';
import React, { useRef } from 'react';
import { StatusClassName } from './PipeStatus';

const isInSync = (outOfSync?: string[]) =>
  Array.isArray(outOfSync) && outOfSync.length >= 1 ? false : true;

export const PipeSyncStatus: React.FC<{ outOfSync: string[] }> = ({ outOfSync }) => {
  const inSync = isInSync(outOfSync);

  if (inSync) {
    return <State title="Pipe is in sync" className={StatusClassName.GREEN} text="Yes" />;
  }

  return (
    <State
      title={`Pipe is out of sync with these datasets:\n${outOfSync.join('\n')}`}
      className={StatusClassName.RED}
      text="No"
    />
  );
};
