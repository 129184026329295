import React from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import SesamLink from 'Common/Links/SesamLink';
import FilterTable from '../filter-table/FilterTable';
import { useSubId } from 'Hooks/sesam';

const DATA_TYPE_PROPERTIES_TABLE_KEY = 'entity-type-properties';

const filterLabelMapping = {
  name: 'Name',
  type: 'Type',
};

const emptyFilter = Object.freeze({
  name: {
    selected: [],
    search: [],
  },
  type: {
    selected: [],
    search: [],
  },
});

const defaultFilter = produce(emptyFilter, (draft) => {});

const defaultVisibleCols = ['Name', 'Type'];

const defaultSortBy = [
  {
    id: 'name',
    desc: false,
  },
];

const defaultVisibleFacets = ['name', 'type'];

/**
 * Renders a list of data types
 * @param {object} props
 */
const EntityTypePropertiesList = (props) => {
  const { properties } = props;
  const subId = useSubId();

  return (
    <FilterTable
      defaults={{
        filter: defaultFilter,
        sortBy: defaultSortBy,
        visibleCols: defaultVisibleCols,
        visibleFacets: defaultVisibleFacets,
      }}
      emptyFilter={emptyFilter}
      columns={[
        {
          Header: 'Name',
          id: 'name',
          accessor: 'name',
          fixed: true,
          Cell: ({ cell: { value } }) => (
            <SesamLink
              to={`/subscription/${subId}/browse/properties/${encodeURIComponent(value)}/overview`}
            >
              {value}
            </SesamLink>
          ),
        },
        {
          Header: 'Type',
          id: 'type',
          accessor: 'type',
        },
      ]}
      itemsToFilter={properties}
      filterLabelMapping={filterLabelMapping}
      tableKey={DATA_TYPE_PROPERTIES_TABLE_KEY}
    />
  );
};

EntityTypePropertiesList.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape({})),
};

export default EntityTypePropertiesList;
