import React from 'react';
import PropTypes from 'prop-types';

import SesamTextField from 'Common/SesamTextField/SesamTextField';
import Button from 'Common/Button/Button';

import './style.css';

const AclLine = (props) => {
  const { address = '', description = '' } = props;

  return (
    <li className="item-list__item acl-item">
      <div className="item-fields">
        <SesamTextField
          name="address"
          onChange={props.onChange}
          placeholder="IP or CIDR range"
          value={address}
          size="small"
          error={address !== '' && !props.isValidIP(address)}
          fullWidth={false}
          style={{ marginRight: '10px', paddingTop: '5px', paddingBottom: '5px' }}
        />
        <SesamTextField
          name="description"
          onChange={props.onChange}
          placeholder="Description"
          value={description}
          size="small"
          fullWidth={false}
          style={{ marginRight: '10px', paddingTop: '5px', paddingBottom: '5px' }}
        />
      </div>
      <div className="item-delete-action">
        <Button onClick={props.onDelete} theme="danger">
          Remove
        </Button>
      </div>
    </li>
  );
};

AclLine.propTypes = {
  address: PropTypes.string,
  description: PropTypes.string,
  isValidIP: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
};

export default AclLine;
