import React from 'react';
import PropTypes from 'prop-types';
import MUIButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import InProgress from '../../in-progress';

import './style.css';

/**
 * Standard button component
 * @param {string} text to display inside the button
 * @param {string} theme primary or secondary
 * @param {string} variant
 * @param {boolean} loading show loading or not
 * @param {boolean} compact true or false
 * @param {node} children if defined, use instead of text
 */

const useStyles = makeStyles((theme) => ({
  label: {
    letterSpacing: 0,
  },
  root: {
    textTransform: 'none',
  },
  containedSecondary: {
    main: theme.palette.warning.main,
  },
  iconSizeSmall: {
    height: '1rem',
    width: '1rem',
    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },
}));

function Button({
  icon,
  text,
  theme,
  variant,
  loading,
  children,
  loadingText,
  active,
  size,
  classesFromProps,
  ...restProps
}) {
  let className = [
    restProps.className ? restProps.className : '',
    'button',
    active ? 'button--active' : '',
  ]
    .join(' ')
    .trim();

  if (icon) {
    restProps.startIcon = icon;
  }
  const classes = useStyles();
  let color = theme;
  if (theme === 'danger') {
    color = 'secondary';
  }

  return (
    <MUIButton
      className={className}
      disableElevation
      variant={variant}
      color={color}
      classes={{
        label: classes.label,
        root: classes.root,
        iconSizeSmall: classes.iconSizeSmall,
        ...classesFromProps,
      }}
      size={size}
      {...restProps}
    >
      {loading ? (
        <>
          <InProgress inverted={true} size="small" />
          <span className="button__text">{loadingText}</span>
        </>
      ) : children ? (
        <span className="button__text">{children}</span>
      ) : (
        <span className="button__text">{text}</span>
      )}
    </MUIButton>
  );
}

Button.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.node,
  text: PropTypes.string,
  loadingText: PropTypes.string,
  variant: PropTypes.string,
  theme: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.string,
  testid: PropTypes.string,
};

Button.defaultProps = {
  active: false,
  text: 'go',
  variant: 'contained',
  theme: 'default',
  loading: false,
  size: 'small',
  style: {},
};

export default Button;
