import PropTypes from 'prop-types';
import React from 'react';
import { useTable } from 'react-table';

import get from 'lodash/get';

import TrashIcon from '../../../images/icons/trash.svg';
import WarningIcon from '../../../images/icons/warning.svg';

import './DataViewTableStyle.css';
import { withTheme } from '@material-ui/core';

function Table(props) {
  const {
    configErrors,
    columns,
    copiedColumns,
    data,
    dataFromPreviousStep,
    dataTypes,
    onColumnCopy,
    onColumnRemove,
    isSourceSelected,
    theme,
  } = props;

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  let currentRowIdx = 0;

  return (
    <div className="table">
      <table {...getTableProps()} className="table__table">
        {configErrors.length > 0 ? (
          <React.Fragment>
            <thead className="table__header">
              <tr colSpan="0" style={{ height: '32px' }}>
                <th className="table__header-col">Config errors</th>
              </tr>
            </thead>
            <tbody>
              {configErrors.map((error, i) => (
                <tr colSpan="0" className="table__row table__row--even" key={i}>
                  <td className="table__data">
                    <div className="data-view-table__error">
                      <WarningIcon />
                      <div className="data-view-table__error-text">{error.msg}</div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <thead className="table__header">
              {headerGroups.map((headerGroup, idx) => (
                <tr key={idx}>
                  {headerGroup.headers.map((col, i) => {
                    return (
                      <th key={i} {...col.getHeaderProps()} className="table__header-col">
                        <div className="data-view-table__header">
                          <div
                            className={`data-view-table__header-name ${
                              isSourceSelected && copiedColumns.includes(col.id)
                                ? 'data-view-table__header--copied'
                                : ''
                            }`}
                          >
                            {col.render('Header')}
                            {col.accessor && (
                              <span className="table__header-type">{`(${dataTypes[col.id]})`}</span>
                            )}
                          </div>
                          {col.accessor && col.id !== '_id' && !isSourceSelected && (
                            <span
                              className="data-view-table__header-delete"
                              onClick={() => {
                                onColumnRemove(col.id);
                              }}
                            >
                              <TrashIcon />
                            </span>
                          )}
                          {isSourceSelected && (
                            <span
                              className="data-view-table__header-copy"
                              onClick={() => {
                                onColumnCopy(col.id);
                              }}
                            >
                              +
                            </span>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {rows.map((row, rowIdx) => {
                prepareRow(row);

                let isNewRow = false;
                const currentRowId = row.values['_id'];
                const previousRow = dataFromPreviousStep[currentRowIdx];

                // In some cases, one source entity can return multiple previewed entities.
                // The following code checks if the current row is new or not, by seeing if the _id's match.
                // If they do not match, the current row is considered new.
                if (previousRow) {
                  const previousRowId = previousRow['_id'];
                  if (previousRowId !== currentRowId) {
                    isNewRow = true;
                  } else {
                    currentRowIdx++;
                  }
                }

                const originalValue = get(row, 'original', {});
                const className = [
                  'table__row',
                  rowIdx % 2 === 0 ? 'table__row--even' : 'table__row--odd',
                  isNewRow
                    ? theme.palette.type === 'dark'
                      ? 'data-view-table__new--dark'
                      : 'data-view-table__new'
                    : '',
                ]
                  .join(' ')
                  .trim();

                if (Object.keys(originalValue).length === 0) {
                  return (
                    <tr
                      key={rowIdx}
                      style={{
                        height: '20px',
                      }}
                    >
                      <td className="table__data">
                        <div className="spinner-rotate spinner-rotate--small">
                          <div />
                        </div>
                      </td>
                    </tr>
                  );
                }

                return (
                  <tr key={rowIdx} className={className}>
                    {row.cells.map((cell, i) => {
                      const columnId = cell.column.id;
                      let currentValue = cell.value;
                      let isEditedCell = false;
                      let isNewCell = false;
                      let previousValue;

                      if (!isNewRow && previousRow) {
                        previousValue = previousRow[columnId];
                        if (
                          !Object.keys(previousRow).includes(columnId) &&
                          currentValue !== undefined
                        ) {
                          isNewCell = true;
                        } else if (Object.keys(previousRow).includes(columnId)) {
                          if (typeof previousValue === 'object') {
                            previousValue = JSON.stringify(previousValue);
                          }
                          if (typeof currentValue === 'object') {
                            currentValue = JSON.stringify(currentValue);
                          }
                          if (currentValue !== previousValue) {
                            isEditedCell = true;
                          }
                        }
                      }

                      const className = [
                        'table__data',
                        isNewCell
                          ? theme.palette.type === 'dark'
                            ? 'data-view-table__new--dark'
                            : 'data-view-table__new'
                          : '',
                        isEditedCell
                          ? theme.palette.type === 'dark'
                            ? 'data-view-table__edited--dark'
                            : 'data-view-table__edited'
                          : '',
                      ]
                        .join(' ')
                        .trim();

                      return (
                        <td key={i} className={className} style={{}}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </React.Fragment>
        )}
      </table>
    </div>
  );
}

Table.propTypes = {
  configErrors: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  copiedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataFromPreviousStep: PropTypes.arrayOf(PropTypes.shape({})),
  dataTypes: PropTypes.shape({}).isRequired,
  onColumnCopy: PropTypes.func.isRequired,
  onColumnRemove: PropTypes.func.isRequired,
  isSourceSelected: PropTypes.bool.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Table);
