import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import ScriptTag from 'react-script-tag';
import { ThemeProvider } from '@material-ui/core/styles';
import { darkTheme, theme } from '../../themes';
import Apologies from '../apologies';
import { Analytics } from '../..';
import { Toaster } from '../toaster/Toaster';
import faviconPath from '../../images/favicon.ico';

function AppRoot({ children, darkModeActive }) {
  const isItestMode = useSelector((state) => state.globals.status.is_itest_mode);

  useEffect(() => {
    if (isItestMode === false) {
      let mode = darkModeActive ? 'dark' : 'light';
      Analytics.event({
        category: 'Theme',
        action: mode,
        nonInteraction: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkModeActive]); // only once

  return (
    <ThemeProvider theme={darkModeActive ? darkTheme : theme}>
      <div className="app-root">
        <Helmet link={[{ rel: 'icon', href: faviconPath }]} />
        {children}
        <Apologies />
        <Toaster />

        <div id="busyIndicator" style={{ display: 'none' }}>
          Loading...
        </div>
        <div id="busyIndicatorShowCounter" style={{ display: 'none' }} />
      </div>

      {isItestMode === false && (
        <ScriptTag
          isHydrating={false}
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/19709964.js"
        />
      )}
    </ThemeProvider>
  );
}

AppRoot.propTypes = {
  children: PropTypes.node.isRequired,
  darkModeActive: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    darkModeActive: state.theme.dark,
  };
}

const Connected = connect(mapStateToProps)(AppRoot);

export { Connected as AppRoot };
