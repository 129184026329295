import PropTypes from 'prop-types';
import React from 'react';
import Button from 'Common/Button/Button';
import SesamModal from 'Common/SesamModal/SesamModal';
import { Form, FormActions } from 'Common/forms';
import PromiseButton from '../../../components/promise-button';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import DangerText from 'Common/Text/DangerText';

class ConfirmDeleteAccount extends React.Component {
  constructor(props) {
    super(props);

    this.onRetype = (event) => {
      this.setState({
        retyped: event.target.value,
      });
    };

    (this.onDelete = () => {
      return this.props.onApprove();
    }),
      (this.state = {
        deleting: false,
        retyped: '',
      });
  }

  render() {
    const isConfirmed =
      this.state.retyped.length >= 8 && this.props.user.user_id.startsWith(this.state.retyped);
    return (
      <SesamModal
        className="simple-dialog"
        onRequestClose={this.props.onCancel}
        isOpen
        contentLabel="Confirmation"
      >
        <h2 className="heading-page">
          <DangerText>Please confirm</DangerText>
        </h2>
        <p>
          Do you want to delete the user &quot;
          {this.props.user.email} (id:
          {this.props.user.user_id}
          )&quot;?
        </p>
        <DangerText>
          This operation is irreversible and all the information about the user account will be
          lost.
        </DangerText>
        <p>
          Please confirm by retyping the first eight characters of the user ID &quot;
          {this.props.user.user_id}
          &quot;
        </p>
        <Form component="div">
          <SesamTextField
            label="First characters"
            name="retype"
            value={this.state.retyped}
            onChange={this.onRetype}
            margin="normal"
          />
        </Form>
        <FormActions>
          <Button
            className="btn btn--default"
            onClick={this.props.onCancel}
            disabled={this.state.deleting}
          >
            Cancel
          </Button>
          <PromiseButton
            onClick={this.onDelete}
            pending="Deleting…"
            disabled={!isConfirmed}
            theme="danger"
          >
            Delete the user account!
          </PromiseButton>
        </FormActions>
      </SesamModal>
    );
  }
}

ConfirmDeleteAccount.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default ConfirmDeleteAccount;
