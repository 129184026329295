import React from 'react';

import Box from 'Common/Box/Box';
import DatahubSettings from '../settings-datahub';
import UploadConfig from './UploadConfig';
import DownloadConfig from './DownloadConfig';

import './style.css';

const SettingsDatahubTools = () => {
  return (
    <DatahubSettings>
      <main className="scrollArea">
        <div className="row">
          <div className="col gr-equal">
            <Box title="Import configuration">
              <Box.Header>
                <Box.Title>Import config</Box.Title>
              </Box.Header>
              <Box.Content padding>
                <UploadConfig forceUpload={false} />
              </Box.Content>
            </Box>
          </div>
          <div className="col gr-equal">
            <Box title="Export configuration">
              <Box.Header>
                <Box.Title>Export config</Box.Title>
              </Box.Header>
              <Box.Content padding>
                <DownloadConfig />
              </Box.Content>
            </Box>
          </div>
        </div>
      </main>
    </DatahubSettings>
  );
};

export default SettingsDatahubTools;
