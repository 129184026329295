import { DatasetInspectorFilters } from 'Types/enums';

export const initialState = {
  datasetIndexes: [],
  entities: [],
  populating: false,
  // the populating counter is only used by the
  // freetext completed/paused top/bottom
  // it's there to only set populating to false
  // once both the up/down searches finished
  populatingCounter: 0,
  loading: true,
  loadingLatest: false,
  loadingMore: false,
  loadingMoreTop: false,
  hasMore: false,
  hasMoreTop: false,
  previousEntity: undefined,
  hasPrevious: false,
  selectedEntity: undefined,
  nextEntities: [],
  since: undefined,
  topSince: undefined,
  showingFreetextResults: false,
  showingElasticResults: false,
  showingIdResults: false,
  deleted: false,
  history: false,
  uncommitted: false,
  disablePost: false,
  entitiesToPost: [],
  entityToPost: {},
  postMultiple: true,
  postTargetDatasetId: null,
  postValidationError: null,
  showPost: false,
  jumpType: 'sequence',
  jumpSequenceValue: undefined,
  jumpUpdatedValue: undefined,
  searchType: 'id',
  searchValue: '',
  isFilterDirty: false,
  elasticIndexFound: false,
  triedLookingForElasticIndex: false,
  datasetEmpty: false,
  freetextProgress: 0,
};

export default function reducer(state, action) {
  let newState;
  let newEntities;
  let nextEntitiesCopy;

  switch (action.type) {
    case 'datasetIsEmpty':
      return {
        ...initialState,
        loading: false,
        deleted: state.deleted,
        history: state.history,
        uncommitted: state.uncommitted,
        jumpType: state.jumpType,
        searchType: state.searchType,
        jumpValue: state.jumpValue,
        searchValue: state.searchValue,
      };
    case 'populateEntitiesStart':
      return {
        ...state,
        populating: true,
        populatingCounter: 2,
        entities: [],
        loading: true,
      };
    case 'loadMoreStart':
      return {
        ...state,
        loadingMore: true,
      };
    case 'loadMoreTopStart':
      return { ...state, loadingMoreTop: true };
    case 'postInvalid':
      return {
        ...state,
        postValidationError: action.payload,
        disablePost: true,
      };
    case 'postReset':
      return {
        ...state,
        disablePost: false,
        postTargetDatasetId: null,
        postValidationError: null,
        showPost: false,
      };
    case 'postMultipleEntities':
      return {
        ...state,
        entitiesToPost: action.payload,
        postMultiple: true,
        showPost: true,
      };
    case 'postSingleEntity':
      return {
        ...state,
        entityToPost: action.payload ? action.payload : {},
        postMultiple: false,
        showPost: true,
      };
    case 'postValid':
      return {
        ...state,
        postValidationError: null,
        disablePost: false,
      };
    case 'reset':
      return {
        ...initialState,
        deleted: state.deleted,
        history: state.history,
        uncommitted: state.uncommitted,
        jumpType: state.jumpType,
        searchType: state.searchType,
        populating: true,
      };
    case 'setPersistedInitialState':
      return action.payload;
    case 'resetJumpValue':
      return {
        ...state,
        jumpUpdatedValue: undefined,
        jumpSequenceValue: undefined,
        isFilterDirty: true,
      };
    case 'resetSearchValue':
      return {
        ...state,
        searchValue: '',
        isFilterDirty: true,
        showingElasticResults: false,
      };
    case 'setDatasetIndexes':
      return {
        ...state,
        datasetIndexes: action.payload,
      };
    case 'setJumpType':
      return {
        ...state,
        jumpType: action.payload,
        isFilterDirty: true,
      };
    case 'setJumpSequenceValue':
      return {
        ...state,
        jumpSequenceValue: action.payload,
        isFilterDirty: true,
      };
    case 'setJumpUpdatedValue':
      return {
        ...state,
        jumpUpdatedValue: action.payload,
        isFilterDirty: true,
      };
    case 'setPostTargetDatasetId':
      return {
        ...state,
        postTargetDatasetId: action.payload,
      };
    case 'setSearchType':
      return {
        ...state,
        searchType: action.payload,
        isFilterDirty: true,
      };
    case 'setSearchValue':
      return { ...state, searchValue: action.payload, isFilterDirty: true };
    case 'toggleTableView':
      return {
        ...state,
      };
    case 'setFilter':
      switch (action.payload.toLowerCase()) {
        case DatasetInspectorFilters.CompleteLog:
          return {
            ...state,
            entities: [],
            showingFreetextResults: false,
            showingElasticResults: false,
            showingIdResults: false,
            history: true,
            deleted: true,
            freetextProgress: 0,
          };
        case DatasetInspectorFilters.LatestWithDeleted:
          return {
            ...state,
            entities: [],
            showingFreetextResults: false,
            showingElasticResults: false,
            showingIdResults: false,
            history: false,
            deleted: true,
            uncommitted: false,
            freetextProgress: 0,
          };
        case DatasetInspectorFilters.LatestWithoutDeleted:
          return {
            ...state,
            entities: [],
            showingFreetextResults: false,
            showingElasticResults: false,
            showingIdResults: false,
            history: false,
            deleted: false,
            uncommitted: false,
            freetextProgress: 0,
          };
        default:
          return { ...state };
      }
    case 'setUncommitted':
      return { ...state, uncommitted: action.payload };

    case 'freetextAddEntityBottom':
      newState = Object.assign({}, state, {
        loading: false,
      });
      newState.entities = [...newState.entities];
      newState.entities.push(action.payload);
      if (!newState.selectedEntity) {
        newState.previousEntity = action.payload;
        newState.selectedEntity = action.payload;
        newState.nextEntities = [];
      }
      return newState;

    case 'freetextAddEntityTop':
      newState = Object.assign({}, state, {
        loading: false,
      });
      newState.entities = [...newState.entities];
      newState.entities.unshift(action.payload);
      if (!newState.selectedEntity) {
        newState.previousEntity = action.payload;
        newState.selectedEntity = action.payload;
        newState.nextEntities = [];
      }

      return newState;

    case 'freetextCompletedBottom':
      newState = Object.assign({}, state, {
        loading: false,
        hasMore: false,
      });
      if (state.loadingMore) {
        newState.loadingMore = false;
      } else {
        if (state.populatingCounter < 2) {
          newState.populating = false;
        }
        newState.populatingCounter = state.populatingCounter - 1;
      }
      return newState;

    case 'freetextCompletedTop':
      newState = Object.assign({}, state, {
        loading: false,
        hasMoreTop: false,
      });
      if (state.loadingMoreTop) {
        newState.loadingMoreTop = false;
      } else {
        if (state.populatingCounter < 2) {
          newState.populating = false;
        }
        newState.populatingCounter = state.populatingCounter - 1;
      }
      return newState;

    case 'freetextPauseBottom':
      newState = Object.assign({}, state);
      if (state.loadingMore) {
        newState.loadingMore = false;
      } else {
        if (state.populatingCounter < 2) {
          newState.populating = false;
        }
        newState.populatingCounter = state.populatingCounter - 1;
      }
      return newState;

    case 'freetextPauseTop':
      newState = Object.assign({}, state);
      if (state.loadingMoreTop) {
        newState.loadingMoreTop = false;
      } else {
        if (state.populatingCounter < 2) {
          newState.populating = false;
        }
        newState.populatingCounter = state.populatingCounter - 1;
      }
      return newState;

    case 'freetextProgressUpdated':
      newState = Object.assign({}, state, { freetextProgress: action.payload });
      return newState;

    case 'searchStart': {
      newState = Object.assign({}, state, {
        isFilterDirty: false,
        freetextProgress: 0,
      });
      newState.entities = [];
      if (newState.elasticIndexFound) {
        newState.showingElasticResults = true;
      } else {
        newState.showingFreetextResults = true;
      }
      newState.hasMore = true;
      newState.hasMoreTop = true;
      newState.selectedEntity = undefined;
      newState.since = action.since;
      newState.topSince = action.since;
      return newState;
    }

    case 'searchStop':
      newState = {
        ...state,
        hasMore: false,
        hasMoreTop: false,
        showingFreetextResults: true,
        populating: false,
        loadingMore: false,
        loadingMoreTop: false,
      };
      return newState;

    case 'selectEntity':
      return Object.assign({}, state, {
        previousEntity: action.entity,
        selectedEntity: action.entity,
        nextEntities: [],
      });

    case 'resetPreviousEntity':
      return Object.assign({}, state, {
        previousEntity: state.selectedEntity,
        nextEntities: [],
      });

    case 'loadPreviousEntity':
      nextEntitiesCopy = [...state.nextEntities];
      nextEntitiesCopy.push({
        id: state.previousEntity._id,
        updated: state.previousEntity._updated,
      });

      return Object.assign({}, state, {
        previousEntity: action.previousEntity,
        nextEntities: nextEntitiesCopy,
      });

    case 'setHasPreviousEntity':
      return Object.assign({}, state, {
        hasPrevious: action.hasPrevious,
      });

    case 'loadNextEntity':
      nextEntitiesCopy = [...state.nextEntities];
      nextEntitiesCopy.pop();

      return Object.assign({}, state, {
        nextEntities: nextEntitiesCopy,
        previousEntity: action.nextEntity,
      });

    case 'loadBySearch':
      newState = Object.assign({}, state, {
        datasetId: state.datasetId,
        nextEntities: [],
        previousEntity: undefined,
        selectedEntity: undefined,
        loading: false,
      });

      newEntities = action.payload.entities;
      newState.entities = newEntities;

      if (newEntities.length > 0) {
        newState.since = newEntities[newEntities.length - 1]._updated;
        if (action.payload.selectedEntity) {
          newState.selectedEntity = action.payload.selectedEntity;
          newState.previousEntity = action.payload.selectedEntity;
        } else {
          newState.selectedEntity = newEntities[0];
          newState.previousEntity = newEntities[0];
        }
      }

      if (newEntities.length < action.payload.howMany) {
        newState.hasMore = false;
      } else {
        newState.hasMore = true;
      }

      newState.hasMoreTop = false;
      newState.showingIdResults = true;
      newState.populating = false;
      newState.isFilterDirty = false;
      return newState;

    case 'loadBySearchMore':
      newState = Object.assign({}, state, {
        datasetId: state.datasetId,
        nextEntities: [],
      });

      newEntities = action.payload.entities;

      if (newEntities.length > 0) {
        newState.entities = newState.entities.concat(newEntities);
        newState.since = newEntities[newEntities.length - 1]._previous;
      }

      if (newEntities.length + 1 < action.payload.howMany) {
        newState.hasMore = false;
      } else {
        newState.hasMore = true;
      }
      newState.loadingMore = false;

      return newState;

    case 'jumpToOffset':
      newState = Object.assign({}, state, {
        datasetId: state.datasetId,
        hasMore: false,
        hasMoreTop: false,
        nextEntities: [],
        previousEntity: undefined,
        selectedEntity: undefined,
        loading: false,
      });

      newEntities = action.payload.entities;
      newState.entities = newEntities;
      if (newEntities.length > 0) {
        newState.since = newEntities[newEntities.length - 1]._updated;
        newState.topSince = newEntities[0]._updated;
        newState.hasMore = action.payload.hasMore;
        newState.hasMoreTop = action.payload.hasMoreTop;
      } else {
        newState.since = undefined;
        newState.topSince = undefined;
        newState.hasMore = false;
        newState.hasMoreTop = false;
      }

      newState.populating = false;
      newState.isFilterDirty = false;
      return newState;

    case 'loadMore':
      newState = Object.assign({}, state);
      newEntities = action.payload.entities;

      if (newEntities.length > 0) {
        newState.entities = [...newState.entities, ...newEntities];
        if (newState.entities.length > 300) {
          const slicedEntitiesArr = newState.entities.slice(
            newState.entities.length - 300,
            newState.entities.length - 1
          );

          newState.entities = slicedEntitiesArr;

          newState.topSince = newState.entities[0]._updated;
          newState.hasMoreTop = true;
        }
        newState.since = newEntities[newEntities.length - 1]._updated; // This might be the cause of "duplicate keys" warning from React

        newState.hasMore = true;

        if (newEntities.length < action.payload.howMany) {
          newState.hasMore = false;
        }
      } else {
        newState.hasMore = false;
      }
      newState.loadingMore = false;
      return newState;

    case 'loadMoreTop':
      newState = Object.assign({}, state);
      newEntities = action.entities;

      if (newEntities.length > 0) {
        newState.entities = [...newEntities, ...newState.entities];

        // We keep a maximum of 300 entities in the list at any time
        if (newState.entities.length > 300) {
          newState.entities = newState.entities.slice(0, 299);
          newState.since = newState.entities[newState.entities.length - 1]._updated;
        }

        newState.topSince = newEntities[0]._updated; // This might be the cause of "duplicate keys" warning from React
        newState.hasMore = true;
      } else {
        newState.hasMoreTop = false;
      }
      newState.loadingMoreTop = false;
      return newState;

    case 'loadEntities':
      newState = Object.assign({}, state, {
        loading: false,
        showingIdResults: false,
        showingElasticResults: false,
        showingFreetextResults: false,
      });

      newState.loadingMore = false;
      newState.loadingMoreTop = false;
      newState.showingIdResults = false;

      newEntities = action.payload.entities;

      newState.populating = false;
      newState.isFilterDirty = false;

      if (newEntities.length > 0) {
        newState.entities = newEntities;

        newState.since = newEntities[newEntities.length - 1]._updated;
        newState.topSince = newEntities[0]._updated;
        newState.hasMore = true;
        newState.hasMoreTop = false;
        newState.selectedEntity = newEntities[0];
        newState.previousEntity = newEntities[0];
      } else {
        newState.entities = [];
        newState.hasMore = false;
        newState.hasMoreTop = false;
        newState.previousEntity = undefined;
        newState.hasPrevious = false;
        newState.selectedEntity = undefined;
        newState.since = undefined;
        newState.topSince = undefined;
        newState.nextEntities = [];
        return newState;
      }

      if (action.payload.entities.length < action.payload.howMany) {
        newState.hasMore = false;
      }

      return newState;

    case 'updateSinceBottom':
      newState = Object.assign({}, state);
      newState.since = action.payload;
      return newState;

    case 'updateSinceTop':
      newState = Object.assign({}, state);
      newState.topSince = action.payload;
      return newState;

    case 'triedLookingForElasticIndex':
      newState = { ...state };
      newState.triedLookingForElasticIndex = true;
      return newState;

    case 'elasticIndexFound':
      newState = { ...state };
      newState.elasticIndexFound = true;
      return newState;

    case 'datasetEmpty':
      newState = { ...state };
      newState.datasetEmpty = true;
      return newState;

    default:
      return { ...state };
  }
}
