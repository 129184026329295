import React, { createContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Form as Formik } from 'formik';

export interface FormProps {
  className?: string;
  component?: string;
  horizontal?: boolean;
  standout?: boolean;
  useFormik?: boolean;
  margin?: boolean;
}

export const FormContext = createContext({});

const useStyle = makeStyles((theme) => {
  return {
    margin: { margin: '1rem 0' },
    formHorizontal: {
      alignItems: 'baseline',
      display: 'flex',
      margin: 0,
    },
    formStandout: {
      backgroundColor: theme.palette.background.semilight,
      border: `1px solid ${theme.palette.background.divider}`,
      padding: '0.7rem',
    },
  };
});

const Form: React.FC<FormProps> = ({
  useFormik = false,
  className,
  children,
  component: componentProp,
  horizontal = false,
  standout = false,
  margin = true,
  ...restProps
}) => {
  const styleClasses = useStyle();

  const Component = componentProp ? componentProp : 'form';

  const classNames = clsx([
    margin && styleClasses.margin,
    className,
    horizontal && styleClasses.formHorizontal,
    standout && styleClasses.formStandout,
  ]);

  return (
    <FormContext.Provider value={{ horizontal }}>
      {useFormik ? (
        <Formik className={classNames} {...restProps}>
          {children}
        </Formik>
      ) : (
        <Component className={classNames} {...restProps}>
          {children}
        </Component>
      )}
    </FormContext.Provider>
  );
};

export default Form;
