import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import SesamCheckboxCompact from 'Common/SesamCheckboxCompact/SesamCheckboxCompact';

function FilterTableHeader({ getToggleAllRowsSelectedProps, headers, rowWidth, theme }) {
  const visibleHeaders = headers.filter((h) => h && h.isVisible);
  return (
    <div
      style={{
        flex: '0 1 auto',
        display: 'flex',
        overflow: 'hidden',
        width: rowWidth,
        position: 'sticky',
        top: 0,
      }}
      className="table__header"
    >
      <div
        className={'table__header-col'}
        style={{
          width: '30px',
          flex: '30 0 auto',
          maxWidth: '30px',
          textAlign: 'center',
          padding: '5px 0',
          borderRight: `1px solid ${theme.palette.divider}`,
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.light,
        }}
      >
        <SesamCheckboxCompact {...getToggleAllRowsSelectedProps()} size="small" disableRipple />
      </div>
      {visibleHeaders.map((column, i) => (
        <FilterTableHeaderCell
          column={column}
          index={i}
          key={i}
          headersLength={visibleHeaders.length}
          theme={theme}
        />
      ))}
    </div>
  );
}

FilterTableHeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getToggleAllRowsSelectedProps: PropTypes.func.isRequired,
  rowWidth: PropTypes.number.isRequired,
};

function FilterTableHeaderCell({ column, index, headersLength, theme }) {
  const props = column.getHeaderProps({
    ...column.getSortByToggleProps(),
  });
  const style = {
    position: 'relative',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.light,
  };
  if (column.width) {
    style.flex = `${column.width} 0 auto`;
    style.width = `${column.width}px`;
  }
  if (column.maxWidth > 0) {
    style.maxWidth = `${column.maxWidth}px`;
  } else {
    style.maxWidth = `${column.width}px`;
  }
  if (column.align) {
    style.textAlign = column.align;
  }

  return (
    <div
      key={index}
      style={style}
      className={`table__header-col ${
        column.isSorted
          ? column.isSortedDesc
            ? 'table__header-col--sorted-desc'
            : 'table__header-col--sorted-asc'
          : ''
      }`}
    >
      <div onClick={props.onClick} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {column.render('Header')}
      </div>
      {index < headersLength - 1 && (
        <div
          {...column.getResizerProps({
            className: 'table__header-resizer',
          })}
        />
      )}
    </div>
  );
}

FilterTableHeaderCell.propTypes = {
  column: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  headersLength: PropTypes.number.isRequired,
};

export default withTheme(FilterTableHeader);
