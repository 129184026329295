import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'Common/Button/Button';
import Page from '../page';
import PageHeader, { PageHeaderGroup, PageHeaderTitle } from '../page-header';
import SystemActions from 'Redux/thunks/systems';
import TabbedNav from '../tabbed-nav';

function SystemsListPage(props) {
  return (
    <Page dataSelenium="subscription-systems-pageview">
      <PageHeader>
        <PageHeaderTitle>Systems</PageHeaderTitle>
        <PageHeaderGroup>
          <Button theme="primary" onClick={() => props.loadAll(props.includeInternal)}>
            Refresh
          </Button>
          <SesamLink to={`/subscription/${props.subId}/systems/new`} buttonLink={true}>
            <Button theme="primary">New system</Button>
          </SesamLink>
        </PageHeaderGroup>
      </PageHeader>
      <TabbedNav
        nav={[
          {
            label: 'List all',
            to: `/subscription/${props.subId}/systems/all`,
          },
          {
            label: 'Permissions',
            to: `/subscription/${props.subId}/systems/permissions`,
          },
          {
            label: 'Prototype permissions',
            to: `/subscription/${props.subId}/systems/prototype-permissions`,
          },
        ]}
      >
        {props.children}
      </TabbedNav>
    </Page>
  );
}

SystemsListPage.propTypes = {
  children: PropTypes.node,
  subId: PropTypes.string.isRequired,
  includeInternal: PropTypes.bool.isRequired,
  loadAll: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    loadAll: PropTypes.func.isRequired,
    subId: state.subscription.id,
    subscriptionInfo: state.subscription.info,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAll: (includeInternal) => dispatch(SystemActions.loadAll(includeInternal)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemsListPage);
