import React, { useContext } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import produce from 'immer';
import memoize from 'memoize-one';
import get from 'lodash/get';

import { withSeparators } from 'Internals/utils';
import {
  sortWithNullOrUndefinedValues,
  sortBooleanWithNullOrUndefinedValues,
} from 'Internals/sort';
import State from 'Common/renderers/State/State';
import { makeUrlParamsFromPath, getPathState, getColorClassNamesFromState } from 'Internals/graph';
import { getPipeQueueSize } from 'Internals/pipes';
import { LoadingPanel } from 'Common/LoadingPanel';
import PipeLink from 'Common/Links/PipeLink';
import SystemLink from 'Common/Links/SystemLink';
import EndpointTypeLink from 'Common/Links/EndpointTypeLink';
import EmptyState from 'Common/EmptyState/EmptyState';
import SesamLink from 'Common/Links/SesamLink';
import FilterTable, { getSortValues } from '../filter-table/FilterTable';
import { SubscriptionContext } from '../../pages/Subscription.page';
import { useFlows } from '../../hooks/useFlows';
import FlowsListPage from './FlowsListPage';
import './style.css';

const HOP_IDENTIFIER = '---';
const IsDirectState = ({ isDirect }) =>
  isDirect === 'Yes' ? (
    <State title="Flow is direct" className="status-green" text="Yes" />
  ) : (
    <State title="Flow is indirect (hops in the path)" className="status-red" text="No" />
  );
IsDirectState.propTypes = { isDirect: PropTypes.string.isRequired };

const LastRunState = ({ lastRun }) => {
  if (lastRun === null) {
    return <State title="Unknown state of last run" className="status-yellow" text="Unknown" />;
  }
  if (lastRun) {
    return (
      <State
        title="All pipes in this flow succeeded in the last run"
        className="status-green"
        text="Success"
      />
    );
  } else {
    return (
      <State
        title="At least one pipe in this flow failed in the last run"
        className="status-red"
        text="Failure"
      />
    );
  }
};
LastRunState.propTypes = {
  lastRun: PropTypes.bool,
};

const PathRenderer = ({ path, subId }) => {
  const string = path.reduce((acc, curr, idx, arr) => {
    if (curr === HOP_IDENTIFIER) return acc;
    if (arr[idx - 1] === HOP_IDENTIFIER) {
      return acc + ' ⤑ ' + curr;
    } else {
      return acc + ' ⭢ ' + curr;
    }
  });
  const link = `/subscription/${subId}/flows/flow?${makeUrlParamsFromPath(path)}`;
  return (
    <SesamLink title={string} to={link} className="primitive-link">
      {string}
    </SesamLink>
  );
};

PathRenderer.propTypes = {
  path: PropTypes.array.isRequired,
  subId: PropTypes.string.isRequired,
};

const getFlowLastRun = (path, pipes) => {
  const pipesRunStatuses = path.map((id) => {
    const pipe = pipes[id];
    if (pipe) {
      return get(pipe, 'runtime.success');
    } else {
      return null;
    }
  });
  if (pipesRunStatuses.includes(null)) return null;
  if (pipesRunStatuses.includes(false)) return false;
  else return true;
};

const getFlowLastRunMemo = memoize(getFlowLastRun);

const filterLabelMapping = {
  from: 'From',
  to: 'To',
  direction: 'Direction',
  direct: 'Direct',
  systemId: 'System',
  endpointType: 'Endpoint type',
  path: 'Includes',
  lastRunStatus: 'Last run',
};

const emptyFilter = Object.freeze({
  from: {
    selected: [],
    search: [],
  },
  to: {
    selected: [],
    search: [],
  },
  direction: {
    selected: [],
    search: [],
  },
  systemId: {
    selected: [],
    search: [],
  },
  endpointType: {
    selected: [],
    search: [],
  },
  direct: {
    selected: [],
    search: [],
  },
  path: {
    selected: [],
    search: [],
  },
  lastRunStatus: {
    selected: [],
    search: [],
  },
});

const defaultFilter = produce(emptyFilter, (draft) => {
  draft.from.selected = [];
});

const defaultVisibleCols = [
  'From',
  'To',
  'Path',
  'System',
  'Endpoint type',
  'Direction',
  'Direct',
  'Queues',
  'Last run',
];

const defaultVisibleFacets = ['from', 'to', 'system', 'direct'];

const defaultSortBy = [
  {
    id: 'flows-from',
    desc: false,
  },
];

const getSumQueues = (path, pipes) => {
  let sum = 0;
  for (const id of path) {
    sum = sum + getPipeQueueSize(pipes[id]);
  }
  return sum;
};

const getSumQueuesMemoized = memoize(getSumQueues);

/**
 * Renders a list of flows
 * @param {object} props
 */
const FlowsList = (props) => {
  const { pipes, pipesLoaded } = props;

  let { flows, finished: flowsComputed, loading: flowsLoading } = useFlows();
  flows = flows.map((f) => {
    const status = getFlowLastRunMemo(f.path, pipes);
    if (status === null) {
      f.lastRunStatus = 'Unknown';
      f.lastRunStatusRaw = null;
      return f;
    }
    if (status) {
      f.lastRunStatus = 'Success';
      f.lastRunStatusRaw = true;
    } else {
      f.lastRunStatus = 'Failure';
      f.lastRunStatusRaw = false;
    }
    return f;
  });

  const columns = [
    {
      Header: 'Path',
      fixed: true,
      id: 'flows-path',
      width: 300,
      accessor: 'path',
      Cell: ({ cell: { value } }) => {
        const subId = useContext(SubscriptionContext);
        return <PathRenderer path={value} subId={subId} />;
      },
      sortType: getSortValues((a, b) => {
        return sortWithNullOrUndefinedValues(a[0], b[0]);
      }),
    },
    {
      Header: 'From',
      id: 'flows-from',
      accessor: 'from',
      Cell: ({ cell: { value } }) => <PipeLink pipeId={value} />,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'To',
      id: 'flows-to',
      accessor: 'to',
      Cell: ({ cell: { value } }) => <PipeLink pipeId={value} />,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'System',
      id: 'flows-system',
      accessor: 'systemId',
      Cell: ({ cell: { value } }) => <SystemLink systemId={value} />,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Endpoint type',
      id: 'flows-endpointtype',
      accessor: (f) => f,
      Cell: ({ cell: { value } }) => (
        <EndpointTypeLink type={value.endpointType} direction={value.direction} />
      ),
      sortType: getSortValues((a, b) => {
        const endpointTypeA = a.endpointType;
        const endpointTypeB = b.endpointType;
        return sortWithNullOrUndefinedValues(endpointTypeA, endpointTypeB);
      }),
    },
    {
      Header: 'Direction',
      id: 'flows-direction',
      accessor: 'direction',
      width: 100,
      Cell: ({ cell: { value } }) => <span title={value}>{value}</span>,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Direct',
      id: 'flows-isdirect',
      accessor: 'direct',
      width: 75,
      Cell: ({ cell: { value } }) => <IsDirectState isDirect={value} />,
      sortType: getSortValues((a, b) => {
        const aBool = a === 'Yes';
        const bBool = b === 'Yes';

        return sortBooleanWithNullOrUndefinedValues(aBool, bBool);
      }),
    },
    {
      Header: 'Queues',
      id: 'flows-queues',
      accessor: (f) => getSumQueuesMemoized(f.path, pipes),
      align: 'right',
      Cell: ({ cell: { value } }) => <div>{withSeparators(value)}</div>,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Last run',
      id: 'flows-lastRun',
      accessor: 'lastRunStatusRaw',
      align: 'center',
      Cell: ({ cell: { value } }) => <LastRunState lastRun={value} />,
      sortType: getSortValues(sortBooleanWithNullOrUndefinedValues),
    },
    {
      Header: 'Status',
      id: 'flows-status',
      accessor: (f) => getPathState(f.path, pipes),
      align: 'center',
      Cell: ({ cell: { value } }) => {
        const state = value ? value : 'Unknown';
        const className = getColorClassNamesFromState(state);
        return (
          <span title={`At least one pipe in flow is in state: ${state}`} className={className}>
            {state}
          </span>
        );
      },
    },
  ];

  // TODO: when we have more tabs here, we should have one FlowsPage, and then FlowsListPage and then FlowsList
  // that will make this neater
  if (props.pipesLoaded && flows.length < 1 && flowsComputed) {
    return (
      <FlowsListPage>
        <EmptyState>
          <p>No flows (paths between global pipes and systems) were found in this subscription.</p>
          <p>
            To start using this feature, add <code>"global": true</code> to the{' '}
            <code>metadata</code> property in your global pipes.
          </p>
        </EmptyState>
      </FlowsListPage>
    );
  }
  return (
    <FlowsListPage>
      {pipesLoaded ? (
        flowsLoading ? (
          <LoadingPanel loadingMessage="Calculating flows" />
        ) : (
          <FilterTable
            defaults={{
              filter: defaultFilter,
              sortBy: defaultSortBy,
              visibleCols: defaultVisibleCols,
              visibleFacets: defaultVisibleFacets,
            }}
            emptyFilter={emptyFilter}
            filterLabelMapping={filterLabelMapping}
            columns={columns}
            itemsToFilter={flows}
            tableKey="flows"
          />
        )
      ) : (
        <LoadingPanel loadingMessage="Fetching pipes..." />
      )}
    </FlowsListPage>
  );
};

FlowsList.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  pipes: PropTypes.shape({}).isRequired,
  upstreams: PropTypes.shape({}).isRequired,
  downstreams: PropTypes.shape({}).isRequired,
  lookups: PropTypes.shape({}).isRequired,
  pipesLoaded: PropTypes.bool.isRequired,
  subId: PropTypes.string.isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
};

const mapStateToProps = (state) => ({
  pipes: state.pipes,
  upstreams: state.upstreams,
  downstreams: state.downstreams,
  lookups: state.lookups,
  subId: state.subscription.id,
  pipesLoaded: state.loadStatus.pipesLoaded,
  flowsComputing: state.loadStatus.flowsComputing,
});

export default compose(withRouter, connect(mapStateToProps))(FlowsList);
