import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { apologise } from 'Redux/thunks/apology';
import DatasetActions from 'Redux/thunks/datasets';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MoreVert from '@material-ui/icons/MoreVert';
import SesamIconButtonPopover from 'Common/SesamIconButtonPopover/SesamIconButtonPopover';
import PostEntityModal from '../dataset-inspector/PostEntityModal';
import { useDatasetInspectorState } from '../../hooks/useDatasetInspector/useDatasetInspectorState';
import DatasetNavigatorFilter from './DatasetNavigatorFilter';

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonGroup: {
    height: '24px',
    marginLeft: '7.5px',
    marginRight: '7.5px',
    '& .MuiButtonGroup-grouped': {
      minWidth: '34px',
    },
    '& .MuiButton-label': {
      height: '22px',
    },
    '& .MuiButton-outlinedSizeSmall': {
      padding: '0px 7px',
    },
    '& svg': {
      height: '16px',
      width: '16px',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
  },
  filter: {
    flex: 1,
    display: 'flex',
    marginLeft: '7.5px',
  },
  menu: {
    marginLeft: '7.5px',
    marginRight: '7.5px',
  },
  spinner: {
    marginRight: '7.5px',
    marginLeft: '7.5px',
    marginTop: '4px',
    width: '20px',
  },
  activeFilter: {
    color: theme.palette.primary.main,
  },
  message: {
    marginLeft: '10px',
    paddingTop: '5px',
    fontSize: '13px',
    color: theme.palette.text.danger,
  },
}));

const DatasetNavigator = (props) => {
  const classes = useStyle();

  const {
    apologise,
    dataset,
    datasetId,
    datasetsLoaded,
    loadDataset,
    onEntityFetched,
    originalDataset,
    params,
    subset,
    pipes,
    router,
    subUrl,
    token,
    upstreams,
  } = props;

  const persistFilter = false;

  const {
    filter,
    isSelectedEntityFirstEntity,
    isSelectedEntityLastEntity,
    navigateNext,
    navigatePrevious,
    postEntities,
    postJson,
    postReset,
    repost,
    reset,
    resetJump,
    resetSearch,
    search,
    setFilter,
    setJumpSequenceValue,
    setJumpType,
    setJumpUpdatedValue,
    setPost,
    setPostValid,
    setSearchType,
    setSearchValue,
    sendPost,
    state,
  } = useDatasetInspectorState({
    apologise,
    dataset,
    datasetID: datasetId,
    datasetsLoaded,
    loadDataset,
    originalDataset,
    params,
    pipes,
    router,
    subset,
    subUrl,
    token,
    upstreams,
    persistFilter,
  });

  useEffect(() => {
    if (state.selectedEntity) {
      onEntityFetched(state.selectedEntity);
    }
  }, [onEntityFetched, state.selectedEntity]);

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.container}>
        <div className={classes.navigation}>
          <ButtonGroup className={classes.buttonGroup} size="small">
            <Button onClick={navigateNext} disabled={isSelectedEntityLastEntity}>
              <ArrowBack />
            </Button>
            <Button onClick={navigatePrevious} disabled={isSelectedEntityFirstEntity}>
              <ArrowForward />
            </Button>
          </ButtonGroup>
        </div>
        {state.entities.length !== 0 && (
          <div className={classes.spinner}>
            {(state.populating || state.loading) && <CircularProgress size={20} />}
          </div>
        )}
        <div className={classes.actions}>
          <DatasetNavigatorFilter
            className={classes.filter}
            dataset={dataset}
            datasetIndexes={state.datasetIndexes}
            entitiesLength={state.entities.length}
            filter={filter}
            isFilterDirty={state.isFilterDirty}
            jumpSequenceValue={state.jumpSequenceValue}
            jumpType={state.jumpType}
            jumpUpdatedValue={state.jumpUpdatedValue}
            loading={state.loading}
            populating={state.populating}
            resetJump={resetJump}
            setJumpSequenceValue={setJumpSequenceValue}
            setJumpUpdatedValue={setJumpUpdatedValue}
            reset={reset}
            resetSearch={resetSearch}
            search={() => {
              search();
            }}
            searchType={state.searchType}
            searchValue={state.searchValue}
            setFilter={setFilter}
            setJumpType={setJumpType}
            setSearchType={setSearchType}
            setSearchValue={setSearchValue}
            subset={subset}
          />
          <SesamIconButtonPopover
            className={classes.menu}
            IconButtonProps={{ size: 'small' }}
            icon={<MoreVert />}
            render={(toggle) => (
              <List>
                <ListItem button onClick={postEntities} disabled={state.nextEntities.length === 0}>
                  Back to latest
                </ListItem>
                <ListItem button onClick={navigatePrevious} disabled={!state.hasPrevious}>
                  Previous
                </ListItem>
                <ListItem button onClick={navigateNext} disabled={state.nextEntities.length === 0}>
                  Next
                </ListItem>
                <Divider />
                <ListItem
                  button
                  onClick={() => {
                    toggle();
                    postEntities();
                  }}
                >
                  Post entities…
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    toggle();
                    repost();
                  }}
                >
                  Repost entity…
                </ListItem>
              </List>
            )}
          />
        </div>
        <PostEntityModal
          onClose={() => postReset()}
          onPostChange={setPost}
          onPostValidation={setPostValid}
          rawJson={postJson}
          sendPost={sendPost}
          showPost={state.showPost}
          postMultiple={state.postMultiple}
          isPostDisabled={state.isPostDisabled}
          postValidationError={state.postValidationError}
        />
      </div>
      {state.entities.length === 0 && (
        <div className={classes.message}>No source entities found</div>
      )}
    </div>
  );
};

DatasetNavigator.propTypes = {
  apologise: PropTypes.func.isRequired,
  dataset: PropTypes.shape({}),
  datasetId: PropTypes.string.isRequired,
  datasetsLoaded: PropTypes.bool.isRequired,
  loadDataset: PropTypes.func.isRequired,
  onEntityFetched: PropTypes.func.isRequired,
  originalDataset: PropTypes.shape({}),
  params: PropTypes.shape({}).isRequired,
  pipes: PropTypes.shape({}).isRequired,
  router: PropTypes.shape({}).isRequired,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  upstreams: PropTypes.shape({}).isRequired,
  subset: PropTypes.shape([]),
};

const mapStateToProps = (state, ownProps) => {
  const dataset = state.datasets[ownProps.datasetId];

  let originalDataset; // only in case of dead letter datasets
  if (dataset && dataset.originalDataset) {
    originalDataset = state.datasets[dataset.originalDataset];
  }

  return {
    dataset,
    datasetsLoaded: state.loadStatus.datasetsLoaded,
    originalDataset,
    pipes: state.pipes,
    subUrl: state.subscription.url,
    token: state.subscription.token,
    upstreams: state.upstreams,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  apologise: (err) => dispatch(apologise(err)),
  loadDataset: () => dispatch(DatasetActions.load(ownProps.datasetId, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatasetNavigator));
