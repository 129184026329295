import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import PropTypes from 'prop-types';
import React from 'react';

import PromiseButton from '../promise-button';
import SubActions, { isInvite } from 'Redux/thunks/subscriptions';

const BareSubscriptionButtons = function ({ onAccept, onDecline, sub }) {
  const actions = [];
  const subIsInvite = isInvite(sub);

  if (subIsInvite) {
    actions.push(
      <PromiseButton
        data-selenium="subscription-decline-invitation-button"
        onClick={onDecline}
        key="decline"
        pending="Declining…"
      >
        Decline
      </PromiseButton>
    );
    actions.push(
      <PromiseButton
        data-selenium="subscription-accept-invitation-button"
        key="accept"
        onClick={onAccept}
        pending="Accepting…"
      >
        Accept
      </PromiseButton>
    );
  }

  if (!subIsInvite) {
    actions.push(
      <SesamLink key="members" to={`/subscription/${sub.id}/settings-subscription/members`}>
        Members
      </SesamLink>
    );
    actions.push(
      <SesamLink key="settings" to={`/subscription/${sub.id}/settings-subscription/basics`}>
        Settings
      </SesamLink>
    );
  }

  // TODO: With React 16 we wouldn't need this wrapper <div>
  return <div>{actions}</div>;
};

BareSubscriptionButtons.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  sub: PropTypes.shape().isRequired,
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onAccept: () => dispatch(SubActions.accept(ownProps.sub.id)),
    onDecline: () => dispatch(SubActions.decline(ownProps.sub.id)),
  };
}

export default connect(null, mapDispatchToProps)(BareSubscriptionButtons);
