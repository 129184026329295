import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import isObject from 'lodash/isObject';

import SubsActions from 'Redux/thunks/subscriptions';
import contentDisposition from 'content-disposition';
import { saveAs } from 'file-saver';
import { portalJwtUpdated } from 'Redux/thunks/global';
import sendAnalyticsInfo from '../api/analytics';

let store: any;

export const sfetchInit = (reduxStore: any) => {
  store = reduxStore;
};

// --- Accounting for number of ongoing requests (for Selenium tests) ----------

let pendingRequestCount = 0;
let completedRequestCount = 0;

export const decrementPendingRequestCount = (passthrough: Response): Response => {
  pendingRequestCount -= 1;
  completedRequestCount += 1;
  return passthrough;
};

export const incrementPendingRequestCount = () => (pendingRequestCount += 1);

export const getPendingRequestCount = () => pendingRequestCount;

export const getCompletedRequestCount = () => completedRequestCount;

// --- Handlers for different types of request failures ------------------------

let fetchNewJwtSingletonPromise: Promise<string> | null;

const getJwtToken = (portalUrl: string, subId: string) =>
  sfetchText(`${portalUrl}/subscriptions/${subId}/jwt`, {
    credentials: 'include',
  });

const handleExpiredJwt = (
  response: Response,
  reject: (reason?: any) => void,
  requestUrl: RequestInfo,
  options: RequestInit
): Promise<Response> => {
  if (!fetchNewJwtSingletonPromise) {
    // No ongoing attempt to fetch a new JWT; start one
    const portalUrl = store.getState().globals.portalUrl;
    const subId = store.getState().subscription.id;
    let newToken: string;

    fetchNewJwtSingletonPromise = getJwtToken(portalUrl, subId)
      .then((token) => {
        newToken = token;
      })
      .then(() => store.dispatch(portalJwtUpdated(newToken)))
      .then(() => store.dispatch(SubsActions.reload(subId)))
      .then(() => {
        fetchNewJwtSingletonPromise = null;
      })
      .then(() => newToken);
  }

  // When new JWT acquired, update the "Authorization"-header and try the original request again
  return fetchNewJwtSingletonPromise.then((token) => {
    const newOptions = set(
      options ? cloneDeep(options) : {},
      'headers.Authorization',
      `bearer ${token}`
    );
    return sfetch(requestUrl, newOptions);
  });
};

class SesamError extends Error {
  public response: Response;
  public responseBody?: any;
  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    this.name = 'SesamError';
    this.response = new Response();
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

const handleNon2xxJson =
  (
    response: Response,
    reject: (reason?: any) => void,
    requestUrl: RequestInfo,
    options?: RequestInit
  ) =>
  (json: any): any | Promise<Response> => {
    if (response.status === 401 && json['jwt-is-expired']) {
      return handleExpiredJwt(response, reject, requestUrl, options as RequestInit);
    }

    const error = new SesamError(json.detail);
    error.response = response;
    error.responseBody = json;
    return reject(error);
  };

const handleNon2xxGeneric = (response: Response, reject: (reason?: any) => void) => () => {
  const error = new SesamError(response.statusText);
  error.response = response;
  return reject(error);
};

const assert2xx =
  (requestUrl: RequestInfo, options?: RequestInit) =>
  (response: Response): Response | Promise<any> => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    return new Promise((resolve, reject) => {
      response
        .json()
        .then(handleNon2xxJson(response, reject, requestUrl, options))
        .then(resolve)
        .catch(handleNon2xxGeneric(response, reject));
    });
  };

// Sending an analytics signal to the portal when ever a service api is called on pipes, systems and datasets.
const analytics =
  (requestUrl: RequestInfo) =>
  (response: Response): Response | Promise<any> => {
    const portalUrl = store.getState().globals.portalUrl;
    const subId = store.getState().subscription.id;
    const urlsToCheck = ['pipes', 'systems', 'datasets'];
    if (
      subId &&
      typeof requestUrl === 'string' &&
      urlsToCheck.some((url: string) => requestUrl.includes(url))
    ) {
      sendAnalyticsInfo(portalUrl, subId, 'api_call');
    }

    return response;
  };

// --- Fetch functions ---------------------------------------------------------

export default function sfetch(requestUrl: RequestInfo, options?: RequestInit): Promise<Response> {
  if (!store) {
    throw new Error('Redux store must be assigned before using sfetch()');
  }

  if (typeof options !== 'undefined' && !isObject(options)) {
    throw new Error('Request options must be an object or undefined');
  }

  pendingRequestCount += 1;
  return fetch(requestUrl, options)
    .then(decrementPendingRequestCount)
    .then(assert2xx(requestUrl, options))
    .then(analytics(requestUrl));
}

export function sfetchText(requestUrl: RequestInfo, options: RequestInit) {
  return sfetch(requestUrl, options).then((response) => response.text());
}

export function sfetchJson(requestUrl: RequestInfo, options?: RequestInit, key?: string) {
  return sfetch(requestUrl, options)
    .then((response) => response.json())
    .then((json) => (key === undefined ? json : json[key]));
}

export function sfetchDownload(requestUrl: RequestInfo, options: RequestInit) {
  return sfetch(requestUrl, options).then((response) => {
    const dispositionHeader = response.headers.get('Content-Disposition') || '';
    const disposition = contentDisposition.parse(dispositionHeader);
    const filename = disposition.parameters.filename;
    return response.blob().then((blob: Blob) => {
      return saveAs(blob, filename);
    });
  });
}
