import State from 'Common/renderers/State/State';
import React from 'react';
import { StatusClassName } from './PipeStatus';

export const PipeRunningStatus: React.FC<{ isRunning: boolean }> = ({ isRunning = true }) =>
  isRunning ? (
    <State title="Pipe is running" className={StatusClassName.GREEN} text="Yes" />
  ) : (
    <State title="Pipe is not running" className={StatusClassName.RED} text="No" />
  );
