import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import PropTypes from 'prop-types';
import React from 'react';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import sortBy from 'lodash/sortBy';

function SelectPayment(props) {
  function handleFieldChange(ev) {
    if (props.onChange) {
      props.onChange(ev.target.value);
    }
  }

  let notOwnedPaymentMethodInfo = null;
  const selectedPaymentMethodId = props.value;
  if (selectedPaymentMethodId) {
    const selectedCurrentUsersPaymentMethod = props.paymentMethods.find(
      (x) => x.id === selectedPaymentMethodId
    );
    if (!selectedCurrentUsersPaymentMethod && props.selectedPaymentMethodInfo) {
      // The current user doesn't own the selected paymentmethod.
      notOwnedPaymentMethodInfo = (
        <div>
          The currently selected paymentmethod &quot;
          {props.selectedPaymentMethodInfo.name}
          &quot; is owned by the user {props.selectedPaymentMethodInfo.owner.name} (
          {props.selectedPaymentMethodInfo.owner.email}
          ).
        </div>
      );
    }
  }

  return (
    <div>
      <h2 className="heading-section">Payment</h2>
      {notOwnedPaymentMethodInfo}
      {props.paymentMethods.length > 0 || (
        <p>
          You have no verified payment methods.{' '}
          <SesamLink to="/user/payment-methods/create">Please add one</SesamLink>
        </p>
      )}
      {props.paymentMethods.length === 0 || (
        <SesamTextField
          label="Payment method"
          margin="normal"
          select
          id="paymentmethod"
          value={props.value}
          onChange={handleFieldChange}
          helperText="Select from your own verified payment methods"
        >
          {sortBy(props.paymentMethods, 'name').map((pm) => {
            let optionText = pm.name;
            if (pm.owner && pm.owner.user_id && pm.owner.user_id !== props.currentUserId) {
              optionText = `${optionText} (owner: '${pm.owner.email}')`;
            }

            return (
              <SesamMenuItem key={pm.id} value={pm.id}>
                {optionText}
              </SesamMenuItem>
            );
          })}
        </SesamTextField>
      )}
    </div>
  );
}

SelectPayment.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedPaymentMethodInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    owner: PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    currentUserId: state.user.user_id,
  };
}

export default connect(mapStateToProps)(SelectPayment);
