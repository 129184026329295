import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';
import { sortWithSortable } from 'Internals/sort';
import CardListSortSelector, { FieldPropType } from './sort-selector';

import './style.css';

const LOCAL_STORAGE_KEY = 'sesam--card-list';
const SORT_BY_KEY = 'sortBy';
const SORT_ASC_KEY = 'sortAsc';

const getFieldData = (obj, field) => {
  if (typeof field.data === 'function') {
    const result = field.data(obj, field);
    return typeof result === 'undefined' ? '' : result;
  }
  return get(obj, field.data, '');
};

const defaultFieldSorter = (field, row1, row2) => {
  const row1Data = getFieldData(row1, field);
  const row2Data = getFieldData(row2, field);

  if (row1Data && !row2Data) return -1;
  if (row2Data && !row1Data) return 1;

  if (typeof row1Data === 'number' && typeof row2Data === 'number') {
    return row1Data < row2Data ? -1 : 1;
  }

  return row1Data.toString().trim().toLowerCase() < row2Data.toString().trim().toLowerCase()
    ? -1
    : 1;
};

class CardList extends React.Component {
  constructor(props) {
    super(props);

    /**
     * Retrieve personalised sort direction from local storage
     */
    this.loadPersonalSortAsc = () =>
      getFromLocalStorage(LOCAL_STORAGE_KEY, [this.props.id, SORT_ASC_KEY], true);

    /**
     * Retrieve personalised sort column from local storage
     */
    this.loadPersonalSortBy = () =>
      getFromLocalStorage(
        LOCAL_STORAGE_KEY,
        [this.props.id, SORT_BY_KEY],
        this.props.defaultSortByKey
      );

    /**
     * Stores the sort field (and direction) for this list in localStorage
     * @param {Field} sortByField The field object to save
     */
    this.setSortBy = (sortByField) => {
      const sortBy = sortByField.header;
      const sortAscending = this.state.sortBy === sortBy ? !this.state.sortAscending : true;

      this.setState({ sortAscending, sortBy });
      setIntoLocalStorage(LOCAL_STORAGE_KEY, [this.props.id, SORT_BY_KEY], sortBy);
      setIntoLocalStorage(LOCAL_STORAGE_KEY, [this.props.id, SORT_ASC_KEY], sortAscending);
    };

    this.getSortedCards = (pinned = false) => {
      const pinnedIds = this.props.pinnedIds;
      const cards = this.props.data.filter((dataRow) => {
        const rowKey = get(dataRow, this.props.dataKey);
        const isPinned = pinnedIds.includes(rowKey);
        return pinned ? isPinned : !isPinned;
      });

      // No sense in sorting pinnned items
      if (pinned) return cards;

      // Find the field object that we are sorting by
      const sortField = this.props.fields.find((field) => field.header === this.state.sortBy);

      if (sortField) {
        // If no sorter defined (and not explicitely set to null), use the default sorter
        Object.assign(sortField, {
          sorter: sortField.sorter === undefined ? defaultFieldSorter : sortField.sorter,
        });
        return sortWithSortable(cards, sortField, this.state.sortAscending);
      }

      return cards;
    };

    this.state = {
      sortAscending: this.loadPersonalSortAsc(),
      sortBy: this.loadPersonalSortBy(),
    };
  }

  render() {
    const hasHeader = this.props.header || this.props.sortable;
    const sortableFields = this.props.fields.filter((field) => field.sorter !== null);

    return (
      <div className="card-list">
        {hasHeader && (
          <div className="card-list__header">
            {this.props.header && (
              <div className="card-list__header-controls">{this.props.header}</div>
            )}
            {sortableFields.length > 0 && (
              <CardListSortSelector
                currentSortBy={this.state.sortBy}
                currentSortAsc={this.state.sortAscending}
                fields={sortableFields}
                onChange={this.setSortBy}
              />
            )}
          </div>
        )}
        <ul className="card-list__list">
          {this.getSortedCards(true).map((card) => (
            <li key={card.id}>
              <card.cardRenderer {...card} />
            </li>
          ))}
          {this.getSortedCards().map((card) => (
            <li key={card.id}>
              <card.cardRenderer {...card} />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

CardList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      cardRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    })
  ).isRequired,

  /** Name of property in `props.data` objects that provides a unique key; accepts lodash `get` selector strings */
  dataKey: PropTypes.string.isRequired,

  /** Fields that the list can be sorted by */
  fields: PropTypes.arrayOf(FieldPropType),

  /** Unique ID for this list */
  id: PropTypes.string.isRequired,

  /** Array of IDs (as per `props.dataKey`) of list items to be pinned at the top */
  pinnedIds: PropTypes.arrayOf(PropTypes.string),

  /** Any elements to use as part of the list header (e.g. filtering controls) */
  header: PropTypes.node,

  /** Whether the list is sortable */
  sortable: PropTypes.bool,

  /** The key to sort by if the user hasn't explicitly selected a key */
  defaultSortByKey: PropTypes.string,
};

CardList.defaultProps = {
  header: undefined,
  fields: [],
  pinnedIds: [],
  sortable: false,
};

export default CardList;
