import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import moment from 'moment';

import { findSub } from 'Redux/thunks/subscriptions';
import { alertProps } from '../../model-props';
import ExpansionPanel from '../expansion-panel';
import KeyValue from '../key-value';

import './style.css';

function Alert(props) {
  let title = '';

  const parsedEventTimestamp = moment(props.event_timestamp);
  const parsedLastEventTimestamp = moment(props.last_event_timestamp);
  const isOngoing = props.is_ongoing;
  let when;

  if (isOngoing) {
    // This is an ongoing notification; I.e if the notification-rule triggers again, no new notification will
    // be created; the current notification will just be updated with new "event_count" and
    // "last_event_timestamp" values.
    title = `Ongoing for ${parsedEventTimestamp.toNow(true)} — ${props.msg}`;
  } else {
    // This is an old notification where whatever triggered it has been resolved. (It may switch back to being
    // ongoing if the notification rule triggers again with one hour of the last triggering).
    title = `${parsedLastEventTimestamp.fromNow()} — ${props.msg}`;
  }

  if (props.event_count <= 1) {
    // This notification represents a single triggering of the notification rule.
    when = (
      <span title={props.event_timestamp}>
        {parsedEventTimestamp.format('YYYY-MM-DD HH:mm:ss')}
      </span>
    );
  } else {
    when = (
      <span>
        First occurrence:{' '}
        <span title={props.event_timestamp}>
          {parsedEventTimestamp.format('YYYY-MM-DD HH:mm:ss')}
        </span>
        . This event has triggered an additional {props.event_count - 1} times after the first
        occurrence. The last time it triggered was{' '}
        <span title={props.last_event_timestamp}>
          {parsedLastEventTimestamp.format('YYYY-MM-DD HH:mm:ss')}.
        </span>
      </span>
    );
  }

  const details = {
    When: when,
  };

  if (props.extra_notification_info && props.extra_notification_info.expand_msg) {
    details.Message = [
      props.msg,
      <ExpansionPanel title="details...">
        <div>{props.extra_notification_info.expand_msg}</div>
      </ExpansionPanel>,
    ];
  } else {
    details.Message = props.msg;
  }

  if (props.subscription_id) {
    const subLink = `/subscription/${props.subscription_id}`;
    details.Origin = (
      <ol className="alert__origin">
        {
          <li>
            <SesamLink to={subLink}>{props.subName}</SesamLink>
          </li>
        }
        {props.pipe_id && (
          <li>
            <SesamLink to={`${subLink}/pipes/pipe/${props.pipe_id}`}>{props.pipe_id}</SesamLink>
          </li>
        )}
        {props.pipe_id && props.notification_rule_id && (
          <li>
            <SesamLink
              to={`${subLink}/pipes/pipe/${props.pipe_id}/notifications?ruleId=${props.notification_rule_id}`}
            >
              {props.notification_rule_name}
            </SesamLink>
          </li>
        )}
      </ol>
    );
  }

  return (
    <ExpansionPanel className="alert" title={title}>
      <KeyValue list={details} />
    </ExpansionPanel>
  );
}

Alert.propTypes = Object.assign(
  {
    subName: PropTypes.string.isRequired,
  },
  alertProps
);

function mapStateToProps(state, ownProps) {
  return {
    subName: ownProps.subscription_id
      ? findSub(state.subscriptions)(ownProps.subscription_id).name
      : '',
  };
}

export default connect(mapStateToProps)(Alert);
