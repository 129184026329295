import type { NodeComponent, SomeObject } from '../types/types';
/**
 * Returns a more human readable representation of bytes
 * Credit, with modification: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 * @param {Number} bytes Bytes to convert
 */
export function bytesToSize(bytes: number) {
  if (isNaN(bytes)) return '-';
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 B';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return '~' + (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

/**
 * Returns a more human readable representation of a general number (using K/M/B/T/...)
 * K - thousand
 * M - million
 * B - billion
 * T - trillion
 * P - quadrillion
 * E - quintillion
 * Z - sextillion
 * Y - septilion
 * @param {number} amount Amount to convert
 */
export function amountToSize(amount: number) {
  if (isNaN(amount)) return amount;
  const sizes = ['', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];
  if (amount === 0) return '0';
  const i = Math.floor(Math.log10(Math.abs(amount)) / Math.log10(1000));
  return (amount / Math.pow(1000, i)).toFixed(2) + sizes[i];
}

export function withSeparators(value: number) {
  if (typeof value !== 'number') {
    return value;
  } else {
    return (+value).toLocaleString();
  }
}

/**
 * Lifts a value to array if not already an array
 * @param {any} value
 */
export function ensureArray(value: any) {
  if (Array.isArray(value)) return value;
  if (value === undefined || value === null) return [];
  else return [value];
}

/**
 * Visits all properties of a an object recursively.
 *
 * @param object to visit
 * @param visitor visitor will be called with 'key' and value when value is not an object
 */
export function visit(object: object, visitor: Function) {
  function _visit(object: any, visitor: Function, key = '__root__') {
    const type = typeof object;
    if (type === 'object') {
      visitor(key, object);
      for (const k in object) {
        _visit(object[k], visitor, k);
      }
    } else {
      visitor(key, object);
    }
  }

  _visit(object, visitor);
}

/**
 * Promisified setTimeout
 * @param {number} delay in millis
 */
export function sleep(delay: number) {
  return new Promise((resolve) => setTimeout(() => resolve(), delay));
}

/**
 * Runs a function with given arguments
 * and times it. It's an async function
 * so that it works with sync and async functions
 * but it means it returns a promise either way.
 * Useful for measuring performance.
 * @param {function} f to time
 * @param  {...any} args f's arguments
 * @returns Promise with the results of the function
 * @effect prints out the time it took to run
 */
export async function timeAsync(f: Function, ...args: any) {
  var t0 = performance.now();
  const results = await f(...args);
  var t1 = performance.now();
  console.log(`Call to ${f.name} took ${t1 - t0} milliseconds.`);
  return results;
}

/**
 * Runs a function and times it.
 * Useful for measuring performance.
 * @param {function} f to time
 * @param  {...any} args f's arguments
 * @returns Promise with the results of the function
 * @effect prints out the time it took to run
 */
export function time(f: Function) {
  const t0 = performance.now();
  f();
  const t1 = performance.now();
  console.log(`Call to ${f.name} took ${t1 - t0} milliseconds.`);
}

/*
 * Returns a random v4 UUID of the form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx,
 * where each x is replaced with a random hexadecimal digit from 0 to f,
 * and y is replaced with a random hexadecimal digit from 8 to b.
 *
 * https://gist.github.com/jed/982883
 */
export function uuid(a?: any) {
  return a // if the placeholder was passed, return
    ? // a random number from 0 to 15
      (
        a ^ // unless b is 8,
        ((Math.random() * // in which case
          16) >> // a random number from
          (a / 4))
      ) // 8 to 11
        .toString(16) // in hexadecimal
    : // or otherwise a concatenated string:
      // @ts-ignore
      (
        [1e7] + // 10000000 +
        -1e3 + // -1000 +
        -4e3 + // -4000 +
        -8e3 + // -80000000 +
        -1e11
      ) // -100000000000,
        .replace(
          // replacing
          /[018]/g, // zeroes, ones, and eights with
          uuid // random hex digits
        );
}

/**
 * Comparator function for sorting by _id
 * @param {NodeComponent} a entity base
 * @param {NodeComponent} b entity base
 */
export function sorterById(a: NodeComponent, b: NodeComponent) {
  const idA = a._id.toUpperCase();
  const idB = b._id.toUpperCase();
  if (idA < idB) return -1;
  if (idA > idB) return 1;
  return 0;
}

/**
 * Comparator function for sorting by _id (reverse)
 * @param {NodeComponent} a entity base
 * @param {NodeComponent} b entity base
 */
export function sorterByIdReverse(a: NodeComponent, b: NodeComponent) {
  return -sorterById(a, b);
}

type ObjectWithName = {
  name: string;
};
export function nameSorter(a: ObjectWithName, b: ObjectWithName) {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}

/*
 * Transforms properties results from the new models API to be the same as we used in the old API (generate schema)
 */
export function propertiesToSchema(properties: any, entityType?: string) {
  let schema = [];
  for (const [k, v] of Object.entries(properties)) {
    let type = '';
    if (v.anyOf) {
      type = v.anyOf.map((x) => x.type).join('/');
    } else {
      type = v.type;
    }
    let propertyType = {
      name: k,
      type: type,
      origin: entityType,
    };
    if (entityType) {
      propertyType.origin = entityType;
    }
    schema.push(propertyType);
  }
  return schema;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function mutableUpdate<T>(arr: T[], el: T, matcher: (el: T) => boolean) {
  const index = arr.findIndex(matcher);
  if (index !== -1) arr[index] = el;
}

export function mutableUpsert<T>(arr: T[], el: T, matcher: (el: T) => boolean) {
  const index = arr.findIndex(matcher);
  if (index === -1) {
    arr.push(el);
  } else {
    arr[index] = el;
  }
}

export function mutableDelete<T>(arr: T[], matcher: (el: T) => boolean) {
  const index = arr.findIndex(matcher);
  if (index !== -1) arr.splice(index, 1);
}

export function findInternalOriginInFilter(filter: {
  origin: { search: string[]; selected: string[] };
}) {
  const searchArray = filter.origin.search;
  const selectArray = filter.origin.selected;
  let found = false;
  if (Array.isArray(searchArray)) {
    for (const str of searchArray) {
      if (typeof str === 'string' && (str.startsWith('system') || str.startsWith('replica'))) {
        found = true;
      }
    }
  }
  found = found || selectArray.includes('system');
  return found;
}

/**
 * Typesafe way to set a default value for something.
 * Just experimenting with this, but it works.
 */
export function setDefault<T>(prop: Optional<T>, defaultValue: T) {
  if (prop === undefined || prop === null) {
    return defaultValue;
  } else {
    return prop;
  }
}

const singleSizes = ['small', 'medium', 'large', 'single'];
const legacySizes = ['small', 'medium', 'large', 'xlarge', 'x-large', 'enterprise'];

// These functions map the parts of the products object into variables that control
// the state of the radio buttons/checkboxes
export function getEnvironment(products: SomeObject) {
  if (legacySizes.includes(products.datahub.size)) {
    return 'production';
  }

  if (products.datahub.size === 'developer') {
    return 'developer';
  } else if (products.datahub.size === 'developer-pro') {
    return 'developer-pro';
  } else {
    return 'production';
  }
}

export function getProductionType(products: SomeObject) {
  if (singleSizes.includes(products.datahub.size)) {
    return 'single';
  }

  return 'multi';
}