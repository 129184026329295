import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';

import PipeOutput from '../../../components/pipe-output/PipeOutput';

const FlowOutputPage = ({ flow }) => {
  const lastPipe = get(flow, `[${flow.length - 1}].data`);
  return <PipeOutput pipe={lastPipe} />;
};

FlowOutputPage.propTypes = {
  flow: PropTypes.arrayOf(PropTypes.shape({})),
};

export default FlowOutputPage;
