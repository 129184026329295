import ace from 'brace';

ace.define(
  'ace/mode/dtl',
  [
    'require',
    'exports',
    'module',
    'ace/lib/oop',
    'ace/mode/json_highlight_rules',
    'ace/mode/matching_brace_outdent',
    'ace/mode/behaviour/cstyle',
    'ace/mode/folding/cstyle',
    'ace/worker/worker_client',
  ],
  (acequire, exports) => {
    const JsonMode = acequire('ace/mode/json').Mode;
    const HighlightRules = acequire('ace/mode/dtl_highlight_rules').DtlHighlightRules;

    class Mode extends JsonMode {
      constructor() {
        super();
        this.HighlightRules = HighlightRules;
      }
    }

    exports.Mode = Mode;
  }
);

ace.define(
  'ace/mode/dtl_highlight_rules',
  ['require', 'exports', 'module', 'ace/mode/json_highlight_rules'],
  (acequire, exports) => {
    const JsonHighlightRules = acequire('ace/mode/json_highlight_rules').JsonHighlightRules;

    const dtlCommentRegex = /\["comment"/;
    const fnRegex =
      /\["(?:\+|-|\/|\*|%|\^|abs|add|and|all|any|apply|apply-hops|apply-ns|base64-decode|base64-encode|boolean|bytes|case|case-eq|ceil|coalesce|combine|concat|copy|count|create|create-child|datetime|datetime-diff|datetime-format|datetime-parse|datetime-plus|datetime-shift|decimal|decrypt|decrypt-pgp|decrypt-pki|default|dict|difference|discard|distinct|divide|encrypt|encrypt-pgp|enkrypt-pki|enumerate|eq|fail!|filter|first|flatten|float|floor|group|group-by|gt|gte|has-key|hash128|hex|hops|if|if-null|in|insert|integer|intersection|intersects|is-boolean|is-bytes|is-changed|is-datetime|is-decimal|is-dict|is-empty|is-float|is-integer|is-list|is-ni|is-not-empty|is-not-null|is-null|is-string|is-uri|is-uuid|items|join|json|json-parse|json-transit|json-transit-parse|key-values|keys|last|length|list|ljust|literal|lookup-entity|lower|lstrip|lt|lte|make-ni|map|map-dict|map-values|matches|max|merge|merge-union|min|minus|mod|multiply|neq|ni|ni-collapse|ni-expand|ni-id|ni-ns|not|now|nth|or|path|phonenumber-parse|phonenumber-format|plus|pow|range|remove|rename|replace|reversed|rjust|round|rstrip|sin|slice|sorted|sorted-descending|split|string|strip|sqrt|substring|sum|tan|tuples|union|upper|uri|url-quote|url-unquote|uuid|values|)*"/;

    const jsonRules = [
      {
        token: 'variable', // single line
        regex: '["](?:(?:\\\\.)|(?:[^"\\\\]))*?["]\\s*(?=:)',
      },
      {
        token: 'string', // single line
        regex: '"',
        push: 'string',
      },
      {
        token: 'constant.numeric', // hex
        regex: '0[xX][0-9a-fA-F]+\\b',
      },
      {
        token: 'constant.numeric', // float
        regex: '[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b',
      },
      {
        token: 'constant.language.boolean',
        regex: '(?:true|false)\\b',
      },
      {
        token: 'text', // single quoted strings are not allowed
        regex: "['](?:(?:\\\\.)|(?:[^'\\\\]))*?[']",
      },
      {
        token: 'comment', // comments are not allowed, but who cares?
        regex: '\\/\\/.*$',
      },
      {
        token: 'comment.start', // comments are not allowed, but who cares?
        regex: '\\/\\*',
        next: 'comment',
      },
      {
        token: 'paren.lparen',
        regex: '[[({]',
      },
      {
        token: 'paren.rparen',
        regex: '[\\])}]',
      },
      {
        token: 'text',
        regex: '\\s+',
      },
    ];

    class DtlHighlightRules extends JsonHighlightRules {
      constructor() {
        super();

        this.$rules = {
          start: [
            {
              token: 'comment.start',
              regex: dtlCommentRegex,
              push: 'dtlComment',
            },
            {
              token: 'entity.name.function',
              regex: fnRegex,
              push: 'func',
            },
            {
              token: 'paren.lparen',
              regex: '[[]',
              push: 'list',
            },
            ...jsonRules,
          ],
          string: [
            {
              token: 'constant.language.escape',
              regex: /\\(?:x[0-9a-fA-F]{2}|u[0-9a-fA-F]{4}|["\\\/bfnrt])/,
            },
            {
              token: 'string',
              regex: '"|$',
              next: 'pop',
            },
            { token: 'variable.language', regex: /_(T|S|P)\./ },
            {
              defaultToken: 'string',
            },
          ],
          comment: [
            {
              token: 'comment.end', // comments are not allowed, but who cares?
              regex: '\\*\\/',
              next: 'pop',
            },
            {
              defaultToken: 'comment',
            },
          ],
          func: [
            {
              token: 'entity.name.function',
              regex: fnRegex,
              push: 'func',
            },
            {
              token: 'paren.lparen',
              regex: '[[]',
              push: 'list',
            },
            {
              token: 'entity.name.function',
              regex: /\]/,
              next: 'pop',
            },
            ...jsonRules,
          ],
          list: [
            {
              token: 'variable', // single line
              regex: '["](?:(?:\\\\.)|(?:[^"\\\\]))*?["]\\s*(?=:)',
            },
            {
              token: 'string', // single line
              regex: '"',
              push: 'string',
            },
            {
              token: 'paren.rparen',
              regex: '[\\]]',
              next: 'pop',
            },
            {
              token: 'entity.name.function',
              regex: fnRegex,
              push: 'func',
            },
            {
              token: 'comment.start',
              regex: dtlCommentRegex,
              push: 'dtlComment',
            },
            ...jsonRules,
          ],
          dtlComment: [
            {
              token: 'comment',
              regex: /\[/,
              push: 'dtlComment',
            },
            {
              token: 'comment.end',
              regex: /]/,
              next: 'pop',
            },
            {
              defaultToken: 'comment',
            },
          ],
        };

        this.normalizeRules();
      }
    }

    exports.DtlHighlightRules = DtlHighlightRules;
  }
);
