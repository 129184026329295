export enum DatasetInspectorFilters {
  /**
   * Filter latest entities without deleted entities.
   * Named `Latest` in the UI.
   */
  LatestWithoutDeleted = 'latestwithoutdeleted',
  /**
   * Filter latest entities with deleted entities.
   * Named `Deleted` in the UI.
   */
  LatestWithDeleted = 'latestwithdeleted',
  /**
   * Get all entities.
   * Named `All` in the UI.
   */
  CompleteLog = 'completelog',
}
