import SesamLink from 'Common/Links/SesamLink';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Warning from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import Identicon from '../identicon/Identicon';
import Media from '../media';
import { datahubInfoSelector } from 'Redux/selectors';
import { getDatahubIsOutdated } from 'Internals/datahub';
import { AvatarConnectionString } from './AvatarConnectionString.view';
import SubActions from 'Redux/thunks/subscriptions';
import { CustomConnectionsThunk } from 'Redux/thunks/customConnectionURL.thunks';

const useStyle = makeStyles((theme) => {
  return {
    datahubOutdatedIcon: {
      backgroundColor: 'transparent !important',
      color: theme.palette.error.main,
      cursor: 'pointer',
      marginTop: '2px',
    },
    headingSection: {
      margin: '0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.primary,
      fontFamily: 'Barlow, sans-serif',
      fontSize: '1.25rem',
      fontWeight: '300',
    },
  };
});

function SubscriptionAvatar({
  connectionUrl,
  customConnectionURL,
  name,
  nowrap,
  subId,
  withLink,
  isDefaultUrl,
  router,
  shouldDisplayRestoreLinkButton,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const datahubInfo = useSelector(datahubInfoSelector);
  const isConnected = useSelector((state) => state.subscription.connected);
  const subscriptionId = useSelector((state) => state.subscription.id);
  const defaultConnection = useSelector((state) => state.subscription.defaultConnectionUrl);
  const datahubOutdated = useMemo(() => {
    return getDatahubIsOutdated(datahubInfo);
  }, [datahubInfo]);
  const datahubOutdatedIcon = (
    <Tooltip title="The service instance is running a version that is too old. This voids the SLA. Please upgrade or contact support.">
      <Warning
        size="small"
        className={classes.datahubOutdatedIcon}
        onClick={() => {
          router.push(`/subscription/${subId}/settings-subscription/basics`);
        }}
      />
    </Tooltip>
  );

  const onRestoreConnectionClick = () => {
    dispatch(SubActions.attemptConnectV2(subscriptionId, defaultConnection)).then(() => {
      dispatch(CustomConnectionsThunk.removeCustomConnection(subscriptionId));
    });
  };

  return (
    <Media
      data-selenium-subscription-name={name}
      img={datahubOutdated ? datahubOutdatedIcon : <Identicon input={name} />}
      nowrap={nowrap}
      type="avatar"
    >
      <h3 className={classes.headingSection} data-selenium="subscription-name-heading">
        {withLink && (
          <SesamLink className="card--clickbox__target" title={name} to={`/subscription/${subId}`}>
            {name}
          </SesamLink>
        )}
        {!withLink && name}
      </h3>
      {isDefaultUrl || customConnectionURL || connectionUrl ? (
        <AvatarConnectionString
          customConnectionURL={customConnectionURL}
          connectionUrl={connectionUrl}
          isConnected={isConnected}
          onRestoreConnectionClick={onRestoreConnectionClick}
          shouldDisplayRestoreLinkButton={shouldDisplayRestoreLinkButton}
        />
      ) : (
        <i>
          <small title={'Connected to non-default url ' + connectionUrl}>{connectionUrl}</small>
        </i>
      )}
    </Media>
  );
}

SubscriptionAvatar.propTypes = {
  connectionUrl: PropTypes.string,
  customConnectionURL: PropTypes.string,
  name: PropTypes.string.isRequired,
  nowrap: PropTypes.bool,
  subId: PropTypes.string.isRequired,
  withLink: PropTypes.bool,
  isDefaultUrl: PropTypes.bool,
  router: PropTypes.shape({}),
  shouldDisplayRestoreLinkButton: PropTypes.bool,
};

SubscriptionAvatar.defaultProps = {
  connectionUrl: '',
  isDefaultUrl: true,
  nowrap: false,
  withLink: false,
  shouldDisplayRestoreLinkButton: false,
};

const Wrapped = withRouter(SubscriptionAvatar);

export { Wrapped as SubscriptionAvatar };
