import React from 'react';
import { connect } from 'react-redux';

import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * HOC to wrap components that want to persist some of their values in local storage
 * @param {string} baseKey base key of the local storage entry
 * @param {object} mapPropsToLS object that describes which properties we want to persist
 * Example: const mapPropsToLS = {
    visibleCols: {
      path: [SYSTEMS_TABLE_KEY, COLS_KEY], (path to value in the local storage object)
      defaultValue: defaultVisibleCols, (what's the default value)
      bySubscription: false, (save by subscription or not)
    },
    sortBy: {
      path: [SYSTEMS_TABLE_KEY, SORT_BY_KEY],
      defaultValue: defaultSortBy,
      bySubscription: false,
    },
    filter: {
      path: [SYSTEMS_TABLE_KEY, FACETS_KEY],
      defaultValue: defaultFilter,
      bySubscription: true,
    },
  }
 * 
 */
const withLocalStorage =
  (baseKey = '', mapPropsToLS = {}) =>
  (WrappedComponent) => {
    class WithLocalStorage extends React.Component {
      constructor(props) {
        super(props);

        /**
         * Internal method to get path to [name] variable (differs
         * if we want to store it by subscription or not)
         */
        this.getPath = (name) =>
          mapPropsToLS[name].bySubscription
            ? [this.props.subId, ...mapPropsToLS[name].path]
            : mapPropsToLS[name].path;

        /**
         * Loads [name] variable from localStorage
         */
        this.load = (name) => {
          return getFromLocalStorage(baseKey, this.getPath(name), mapPropsToLS[name].defaultValue);
        };

        /**
         * Saves [name] variable with [value] to local storage
         */
        this.save = (name, value) => {
          return setIntoLocalStorage(baseKey, this.getPath(name), value);
        };

        /**
         * Unused method for now. If this is called in every render (as {...this.getProps()})
         * in the Wrapped component props, then the WrappedComponent always has access to persisted
         * data as props. The downside is that this is retreived on every render.
         * Because so far in all the components we load from local storage only in the constructor
         * (the variables are always in the component state and only mirrored to localStorage)
         * If we ever want to use localstorage more as a way to store state, then we could add this back in.
         */
        this.getProps = () => {
          return Object.keys(mapPropsToLS).reduce((acc, curr) => {
            acc[curr] = getFromLocalStorage(
              baseKey,
              this.getPath(curr),
              mapPropsToLS[curr].defaultValue
            );
            return acc;
          }, {});
        };
      }
      render() {
        return (
          <WrappedComponent
            saveToLocalStorage={this.save}
            loadFromLocalStorage={this.load}
            {...this.props}
          />
        );
      }
    }

    // Convention
    WithLocalStorage.displayName = `WithLocalStorage(${getDisplayName(WrappedComponent)})`;

    /**
     * We want to connect to redux because we want the sub id
     * TOOD: Maybe this could be done in a better way
     */
    return connect((state) => ({ subId: state.subscription.id }))(WithLocalStorage);
  };

export default withLocalStorage;
