import { sfetchJson } from 'Internals/sfetch';
import { ApiConf, Model } from '../types/types';

/**
 * Retrieve all models
 * @param {{ subUrl: string, token: string }} apiConf Configuration for the API call
 * @param {boolean} effective Whether to get the effective response
 * @param {boolean} verbose Whether to ask for verbose response
 * @returns {Promise<*>}
 */
async function getAll(apiConf: ApiConf, effective = false, verbose = true) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/models?effective=${effective}&verbose=${verbose}`;
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
    },
    method: 'GET',
  };
  return await sfetchJson(url, options);
}

/**
 * Retrieve a single model by its id
 * @param {{ subUrl: string, token: string }} apiConf Configuration for the API call
 * @param {string} modelId Id of the model
 * @param {boolean} effective Whether to get the effective response
 * @param {boolean} verbose Whether to ask for verbose response
 * @returns {Promise<*>}
 */
async function get(apiConf: ApiConf, modelId: string, effective = false, verbose = true) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/models/${modelId}?effective=${effective}&verbose=${verbose}`;
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
    },
    method: 'GET',
  };
  return await sfetchJson(url, options);
}

/**
 * Add a model
 * @param {{ subUrl: string, token: string }} apiConf Configuration for the API call
 * @param {Object[]} models Model objects to be POSTed
 * @param {boolean} force Whether to force POST or not (override validation)
 * @returns {Promise<*>}
 */
async function post(apiConf: ApiConf, models: Model[], force = false) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/models?force=${force}`;
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
    method: 'POST',
    body: JSON.stringify(models),
  };
  return await sfetchJson(url, options);
}

/**
 * Remove a model
 * @param {{ subUrl: string, token: string }} apiConf Configuration for the API call
 * @param {string} modelId Id of the model to remove
 * @returns {Promise<*>}
 */
async function remove(apiConf: ApiConf, modelId: string) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/models/${modelId}`;
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
    method: 'DELETE',
  };
  return await sfetchJson(url, options);
}

export default {
  getAll,
  get,
  post,
  remove,
};
