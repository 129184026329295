import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SecretsThunks from 'Redux/thunks/secrets';
import EnvVarThunks from 'Redux/thunks/vars';
import EnvVariables from '../../components/env-variables';
import Secrets from '../../components/secrets';
import DatahubSettings from '../settings-datahub';

class SubSettingsVariables extends React.Component {
  componentDidMount() {
    this.props.dispatch(EnvVarThunks.envLoadAll());
    this.props.dispatch(SecretsThunks.secretLoadAll());
  }

  render() {
    return (
      <DatahubSettings>
        <main className="scrollArea">
          <div className="row">
            <section className="col col--elastic gr-equal">
              <h3 className="heading-section">Environment variables</h3>
              <EnvVariables />
            </section>
            <section className="col col--elastic gr-equal">
              <h3 className="heading-section">Secrets</h3>
              <Secrets />
            </section>
          </div>
        </main>
      </DatahubSettings>
    );
  }
}

SubSettingsVariables.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(null, (dispatch) => ({ dispatch }))(SubSettingsVariables);
