import React from 'react';

function InsightsInfo() {
  return (
    <>
      <h2 className="heading-section">Pipe statistics</h2>
      <p>
        These graphs show how many entities, errors and latencies for the current pipe during the
        last 30 days.
      </p>
      <ul>
        <li>
          <b>Entities processed:</b> How many entities entered the pipe.
        </li>
        <li>
          <b>Changed entities:</b> How many of these were new or different from the last time they
          were processed.
        </li>
        <li>
          <b>Read errors:</b> Number of times the pipe failed to read from the source.
        </li>
        <li>
          <b>Write errors:</b> Number of times the pipe failed to write to the sink.
        </li>
        <li>
          <b>Pump failures:</b> Number of times the pipe failed to complete a run.
        </li>
        <li>
          <b>Source latency:</b> Time it took the pipe to poll the data from the source.
        </li>
        <li>
          <b>Transform latency:</b> Time it took the pipe to pass the data through the transforms.
        </li>
        <li>
          <b>Sink latency:</b> Time it took the pipe to pass the write the data to the sink.
        </li>
      </ul>
      <p>
        The statistics are collected from your subscription. No entities are collected to protect
        your privacy.
      </p>
      <p>
        This is a feature that is part of the <b>standard</b> subscription, but is available now
        during the <b>trial</b> period.
      </p>
    </>
  );
}

export default InsightsInfo;
