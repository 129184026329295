import { createReducer } from '@reduxjs/toolkit';
import { simpleSettingSet } from '../thunks/simple-settings';

const aWeekAgo = new Date();
aWeekAgo.setHours(0, 0, 0, 0);
aWeekAgo.setDate(-7);

type SimpleSettingsState = {
  alertsGroupBy: string;
  alertsLoaded: boolean;
  alertsShownSince: Date;
  [other: string]: any;
};

const initialState: SimpleSettingsState = {
  alertsGroupBy: 'nothing',
  alertsLoaded: false,
  alertsShownSince: aWeekAgo,
};

export default createReducer(initialState, (builder) =>
  builder.addCase(simpleSettingSet, (state, action) => {
    state[action.payload.key] = action.payload.value;
    // TS complains because there is no guarantee that the key specified in the 'key'
    // property of the action and the type of the value in the action will correspond
    // to what is defined in the State type (the value types are different on all three keys)
    // TODO: structure the action differently (e.g. make three different actions)
  })
);
