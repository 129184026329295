import get from 'lodash/get';
import set from 'lodash/set';

/**
 * Retrieve a value from localStorage, with similar semantics to lodash's `get()`
 * @see https://lodash.com/docs#get
 */
export const getFromLocalStorage = (
  baseKey: string,
  path?: string | string[],
  fallbackValue = {}
) => {
  let base: unknown;

  try {
    const storage = localStorage.getItem(baseKey);

    if (storage) {
      base = JSON.parse(storage);
    }
  } catch (e) {
    console.error('Error occurred while trying to retrieve from the local storage', e);

    return fallbackValue;
  }

  if (base === null) {
    return fallbackValue;
  }

  return path !== undefined ? get(base, path, fallbackValue) : base;
};

/**
 * Set a value in localStorage, with similar semantics to lodash's `set()`
 * @param  {string} baseKey The key in localStorage
 * @see https://lodash.com/docs#set
 */
export const setIntoLocalStorage = (baseKey: string, path: string | string[], value: any) => {
  if (path !== undefined && !Array.isArray(path) && typeof path !== 'string') {
    throw new Error('`path` parameter must be array, string, or undefined');
  }

  let obj = getFromLocalStorage(baseKey) ?? {};

  if (path) {
    set(obj, path, value);
  } else {
    obj = value;
  }

  localStorage.setItem(baseKey, JSON.stringify(obj));

  return obj;
};

/**
 * Removes a deeply nested value in localStorage.
 * @see https://lodash.com/docs#set
 */
export const removeFromLocalStorage = (baseKey: string, path: string | string[]) => {
  let obj;

  if (!path) {
    localStorage.removeItem(baseKey);
  } else {
    obj = getFromLocalStorage(baseKey);

    if (obj) {
      set(obj, path, undefined);
      localStorage.setItem(baseKey, JSON.stringify(obj));
    }
  }

  return obj;
};
