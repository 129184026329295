import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import difference from 'lodash/difference';

import { simpleSettingSet } from 'Redux/thunks/simple-settings';
import { buildClassName } from 'Internals/react-utils';
import HeaderButton from '../header-button';
import WarningIcon from '../../images/icons/warning.svg';

import './style.css';

const AUTO_CLOSE_TIMEOUT = 10000;

function getAnnouncementsFromProps(props) {
  let announcements = [];
  if (props.datahubAnnouncements) {
    announcements = announcements.concat(props.datahubAnnouncements);
  }
  if (props.announcements) {
    announcements = announcements.concat(props.announcements);
  }
  return announcements;
}

/**
 * Super hacky way to detect special portal fallback message
 * @param {string} announcement
 */
function isSpecialFallbackMessage(announcement) {
  return /The Sesam portal is running in a special read-only fallback mode.*/.test(announcement);
}
export class BareAnnouncementBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.shouldBeInitiallyOpen,
    };

    this.handleToggle = (ev) => {
      ev.preventDefault();
      const newIsOpen = !this.state.isOpen;
      if (newIsOpen) {
        // the user opened the announcements, so we should never auto-close them.
        this.props.setPreventAutoClose(true);
      } else {
        // the user explicitly closed the announcements, so we can auto-close the list later.
        this.props.setPreventAutoClose(false);
      }
      this.props.setDisplayAsInitiallyClosed(!newIsOpen);

      this.setState({ isOpen: newIsOpen });
    };
  }

  componentDidMount() {
    this.scheduleAutoClose();
  }

  componentDidUpdate(prevProps) {
    // If the announcements change, we want to open the announcements-list
    const newAnnouncements = getAnnouncementsFromProps(this.props);
    const oldAnnouncements = getAnnouncementsFromProps(prevProps);
    const addedAnnouncements = difference(newAnnouncements, oldAnnouncements);
    if (addedAnnouncements.length > 0) {
      // alt least one new announcement has been added, so display the list of announcements.
      this.setState({ isOpen: true });
      this.props.setDisplayAsInitiallyClosed(false);
      this.scheduleAutoClose();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.autoCloseTimeoutId);
  }

  scheduleAutoClose() {
    if (this.autoCloseTimeoutId) return;
    this.autoCloseTimeoutId = setTimeout(() => {
      if (this.props.shouldAutoClose) {
        this.setState({ isOpen: false });
        this.props.setDisplayAsInitiallyClosed(true);
      }
    }, AUTO_CLOSE_TIMEOUT);
  }

  render() {
    let announcements = getAnnouncementsFromProps(this.props);

    announcements = announcements.filter((a) => {
      if (isSpecialFallbackMessage(a)) return false;
      else return true;
    });

    if (announcements.length === 0) {
      return null;
    }

    const classNames = buildClassName(
      'announcement-banner',
      this.state.isOpen ? 'announcement-banner--open' : null
    );

    return (
      <div className={classNames}>
        <HeaderButton
          className="announcement-banner__toggler"
          isToggled={this.state.isOpen}
          isToggler
          darkModeActive={this.props.darkModeActive}
        >
          <a href="#announcements" onClick={this.handleToggle}>
            <WarningIcon />
          </a>
        </HeaderButton>
        <div className="announcement-banner__announcements">
          <ul id="announcements" onClick={() => this.props.setPreventAutoClose(true)} tabIndex="0">
            {announcements.map((announcement, idx) => (
              <li key={idx}>{announcement}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

BareAnnouncementBanner.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.string),
  datahubAnnouncements: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool,
  shouldAutoClose: PropTypes.bool,
  setPreventAutoClose: PropTypes.func.isRequired,
  setDisplayAsInitiallyClosed: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  subId: PropTypes.string.isRequired,
  shouldBeInitiallyOpen: PropTypes.bool,
  darkModeActive: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const datahubInfo = state.subscription ? state.subscription.info : null;
  const datahubAnnouncements = [];
  if (datahubInfo && datahubInfo.status) {
    const healthStatus = datahubInfo.status.health_status;
    if (healthStatus && healthStatus.status !== 'ok') {
      datahubAnnouncements.push(`The datahub is not healthy: ${healthStatus.message}`);
    }
  }

  return {
    announcements: state.user.announcements,
    route: state.routing.locationBeforeTransitions.pathname,
    datahubAnnouncements: datahubAnnouncements,
    subId: state.subscription.id,
    shouldAutoClose: !state.simpleSettings.announcementsPreventAutoClose,
    shouldBeInitiallyOpen: !state.simpleSettings.announcementsDisplayAsInitiallyClosed,
    darkModeActive: state.theme.dark,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPreventAutoClose: (enabled) =>
      dispatch(
        simpleSettingSet({
          key: 'announcementsPreventAutoClose',
          value: enabled,
        })
      ),
    setDisplayAsInitiallyClosed: (enabled) =>
      dispatch(
        simpleSettingSet({
          key: 'announcementsDisplayAsInitiallyClosed',
          value: enabled,
        })
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BareAnnouncementBanner);
