import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import get from 'lodash/get';

import Box from 'Common/Box/Box';
import DownloadEntities from '../download-entities/DownloadEntities';
import EntityViewer from '../entity-viewer/EntityViewer';
import ShareableLinks from '../shareable-links/ShareableLinks';
import { DatasetInspectorContainer } from '../dataset-inspector/DatasetInspectorContainer';

import PipeOutputSection from './pipe-output-section/PipeOutputSection';

import './PipeOutputStyle.css';

const PipeOutput = (props) => {
  const sinkType = get(props.pipe, 'config.effective.sink.type');
  if (
    sinkType === 'dataset' ||
    sinkType === 'replicated_dataset' ||
    sinkType === 'mirrored_dataset'
  ) {
    const datasetID = get(props.pipe, 'config.effective.sink.dataset');
    if (datasetID) {
      return (
        <DatasetInspectorContainer
          datasetID={datasetID}
          registerRefresh={props.registerRefresh}
          unregisterRefresh={props.unregisterRefresh}
          addCustomActions={props.addCustomActions}
          removeCustomActions={props.removeCustomActions}
          tab="output"
        />
      );
    }
  }

  let downloadEntities = null;
  let shareableLinks = null;
  let entityViewer = null;

  const sinkIsEndpoint = [
    'csv_endpoint',
    'http_endpoint',
    'xml_endpoint',
    'excel_endpoint',
  ].includes(sinkType);

  if (sinkIsEndpoint) {
    let type = sinkType.split('_')[0];
    if (type === 'http') type = 'entities';
    downloadEntities = (
      <PipeOutputSection className="pipe-output__download-entities">
        <PipeOutputSection.Header className={props.darkModeActive && 'white-color'}>
          Download entities
        </PipeOutputSection.Header>
        <PipeOutputSection.Description>
          <p>
            {`This pipe pushes its entities to the `}
            <code>{`/publisher/${type}`}</code>
            {` endpoint. You can use the download form below to download entities to your computer.`}
          </p>
        </PipeOutputSection.Description>
        <PipeOutputSection.Content>
          <Box>
            <Box.Header>
              <Box.Title>Download entities</Box.Title>
            </Box.Header>
            <Box.Content padding>
              <DownloadEntities
                url={`${props.subUrl}/publishers/${encodeURIComponent(props.pipe._id)}/${type}`}
                token={props.token}
                type={type === 'entities' ? 'json' : type}
                fileName={encodeURIComponent(props.pipe._id)}
                pipeName={encodeURIComponent(props.pipe._id)}
                subId={props.subId}
              />
            </Box.Content>
          </Box>
        </PipeOutputSection.Content>
      </PipeOutputSection>
    );

    shareableLinks = (
      <PipeOutputSection className="pipe-output__shareable-links">
        <PipeOutputSection.Header className={props.darkModeActive && 'white-color'}>
          Shareable links
        </PipeOutputSection.Header>
        <PipeOutputSection.Description>
          <p>
            You can give read-only access to this endpoint by generating a url with an access token.
            The access token will work until it expires or is deleted.
          </p>
        </PipeOutputSection.Description>
        <PipeOutputSection.Content>
          <ShareableLinks
            pipeId={get(props.pipe, '_id')}
            subUrl={props.subUrl}
            token={props.token}
          />
        </PipeOutputSection.Content>
      </PipeOutputSection>
    );
  }

  if (!sinkIsEndpoint) {
    entityViewer = (
      <PipeOutputSection className="pipe-output__entites">
        <PipeOutputSection.Header className={props.darkModeActive && 'white-color'}>
          Sink entities
        </PipeOutputSection.Header>
        <PipeOutputSection.Description>
          <p>This is how the entities look as they enter the sink.</p>
        </PipeOutputSection.Description>
        <PipeOutputSection.Content className="pipe-output__entity-viewer">
          <EntityViewer pipe={props.pipe} subUrl={props.subUrl} token={props.token} stage="sink" />
        </PipeOutputSection.Content>
      </PipeOutputSection>
    );
  }

  return (
    <div className="pipe-output">
      {downloadEntities}
      {shareableLinks}
      {entityViewer}
    </div>
  );
};

PipeOutput.propTypes = {
  addCustomActions: PropTypes.func,
  pipe: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
  registerRefresh: PropTypes.func,
  removeCustomActions: PropTypes.func,
  unregisterRefresh: PropTypes.func,
  subId: PropTypes.string.isRequired,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  darkModeActive: PropTypes.bool,
};

PipeOutput.defaultProps = {
  pipe: null,
  addCustomActions: () => {},
  removeCustomActions: () => {},
  registerRefresh: () => {},
  unregisterRefresh: () => {},
};

const mapStateToProps = (state, ownProps) => {
  let pipe;
  if (ownProps.pipe) {
    pipe = ownProps.pipe;
  } else if (get(ownProps, 'params.pipeID')) {
    pipe = state.pipes[ownProps.params.pipeID];
  }

  return {
    pipe,
    subId: state.subscription.id,
    subUrl: state.subscription.url,
    token: state.subscription.token,
    darkModeActive: state.theme.dark,
  };
};

export default connect(mapStateToProps)(PipeOutput);
