import React, { useEffect, useState } from 'react';

export default function useResourceInject<T>(
  getter: () => Promise<T>,
  initialLoad = false,
  initialData: T | null = null
): [T | null, boolean, Error | null, () => void] {
  const [data, setData] = useState<T | null>(initialData);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(initialLoad);

  useEffect(() => {
    let isCanceled = false;
    if (loading) {
      getter()
        .then((data: T) => {
          if (!isCanceled) {
            setData(data);
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => {
      isCanceled = true;
    };
  }, [loading]);

  function refresh() {
    setLoading(true);
    return Promise.resolve();
  }
  return [data, loading, error, refresh];
}
