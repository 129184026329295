import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Actions from 'Redux/thunks/user-profile';
import TermsOfService from '../components/terms-of-service/TermsOfService';

function ApproveTOS(props, context) {
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const isNew3rdPartyAuthUser = props.user.approvedTos < 0;
  const hasApprovedCurrentTos = props.user.approvedTos === props.portalTos;

  const handleCancel = useCallback(
    (ev) => {
      ev.preventDefault();
      context.router.push('/');
    },
    [context.router]
  );
  const handleApprove = useCallback(
    (ev) => {
      ev.preventDefault();
      setLoadingApprove(true);
      const data = {
        approvedTos: props.portalTos,
      };
      props.changeProfile(data).then(() => {
        setLoadingApprove(false);
        context.router.push('/');
      });
    },
    [props.portalTos, context.router, props.changeProfile]
  );

  if (hasApprovedCurrentTos) {
    context.router.push('/');
    return null;
  }

  return (
    <TermsOfService
      loading={{ cancel: loadingCancel, approve: loadingApprove }}
      isNew3rdPartyAuthUser={isNew3rdPartyAuthUser}
      user={props.user}
      onApprove={handleApprove}
      onCancel={handleCancel}
    />
  );
}

ApproveTOS.propTypes = {
  user: PropTypes.shape({
    user_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    approvedTos: PropTypes.number.isRequired,
  }).isRequired,
  portalUrl: PropTypes.string.isRequired,
  changeProfile: PropTypes.func.isRequired,
  portalTos: PropTypes.number.isRequired,
};

ApproveTOS.defaultProps = {};

ApproveTOS.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  portalUrl: state.globals.portalUrl,
  portalTos: state.globals.status.tos_version,
});

const mapDispatchToProps = (dispatch) => ({
  changeProfile: (data) => dispatch(Actions.changeProfile(null, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveTOS);
