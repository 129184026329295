function getNonStandardKeyNameMapping(key: string) {
  switch (key) {
    case 'Down':
      return 'ArrowDown';
    case 'Esc':
      return 'Escape';
    case 'Up':
      return 'ArrowUp';
    default:
      return key;
  }
  // TODO: There are a lot more for IE/Edge…
}

type KeyModifiers = {
  altKey?: boolean;
  ctrlKey?: boolean;
  shiftKey?: boolean;
  metaKey?: boolean;
};

type KeyHandler = {
  key: string;
  modifiers: KeyModifiers;
  callback: Function;
  stopPropagation: boolean;
  triggerOnInputs: boolean;
};

let handlers: KeyHandler[] = [];
const allowPrintableKeys = ['button', 'checkbox', 'file', 'image', 'radio', 'reset', 'submit'];

function matchesModifiers(actual: KeyModifiers, expected: KeyModifiers) {
  return (
    !!actual.altKey === !!expected.altKey &&
    !!actual.ctrlKey === !!expected.ctrlKey &&
    !!actual.shiftKey === !!expected.shiftKey &&
    !!actual.metaKey === !!expected.metaKey
  );
}

function handleKeydown(ev: KeyboardEvent) {
  handlers.forEach((handler) => {
    const key = getNonStandardKeyNameMapping(ev.key);
    if (!handler.triggerOnInputs) {
      if (
        !handler.modifiers.altKey &&
        !handler.modifiers.ctrlKey &&
        !handler.modifiers.shiftKey &&
        !handler.modifiers.metaKey
      ) {
        if (ev.target && (<HTMLTextAreaElement>ev.target).tagName === 'TEXTAREA') {
          return;
        } else if (
          ev.target &&
          (<HTMLElement>ev.target).tagName === 'INPUT' &&
          !allowPrintableKeys.includes((<HTMLInputElement>ev.target).type)
        ) {
          return;
        }
      }
    }

    if (key === handler.key && matchesModifiers(ev, handler.modifiers)) {
      if (handler.stopPropagation) {
        ev.stopPropagation();
      }

      handler.callback(ev);
    }
  });
}

document.addEventListener('keydown', handleKeydown);

export function registerKey(
  key: string,
  callback: Function,
  modifiers = {},
  stopPropagation = true,
  triggerOnInputs = false
) {
  // TODO: reconfigure this to use three parameters: `key`, `callback`, and `options`,
  // whereby `options` will allow for `modifiers`, `stopPropagation`, `triggerOnInputs`,
  // and the all-important `preventDefault` (not currently handled here).
  handlers.push({
    key,
    modifiers,
    callback,
    stopPropagation,
    triggerOnInputs,
  });
}

export function unregisterKey(key: string, modifiers = {}) {
  handlers = handlers.filter(
    (handler) => !(handler.key === key && matchesModifiers(modifiers, handler.modifiers))
  );
}
