import React from 'react';
import { default as MUIFormHelperText } from '@material-ui/core/FormHelperText';
import { FormHelperTextProps as MUIFormHelperTextProps } from '@material-ui/core/FormHelperText';

export interface FormHelperTextProps extends MUIFormHelperTextProps {}

const FormHelperText: React.FC<FormHelperTextProps> = (props) => {
  return <MUIFormHelperText {...props} />;
};

export default FormHelperText;
