import PropTypes from 'prop-types';
import React from 'react';

import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';
import DisplayModeButton from 'Common/DisplayModeButton';
import CardList from '../card-list';
import DataTable from '../data-table';

import './style.css';

const SHOWING_LOCAL_STORAGE_KEY = 'sesam--card-list-data-table';
const SHOWING_KEY = 'showing';

class CardListDataTable extends React.Component {
  constructor(props) {
    super(props);

    this.switchShowing = (showing, value) => {
      setIntoLocalStorage(SHOWING_LOCAL_STORAGE_KEY, [this.props.id, SHOWING_KEY], showing);
      this.setState({ showing, buttonValue: value });
    };

    this.buttonToggled = (_event, newAlignment) => {
      if (newAlignment == 'table') {
        this.switchShowing(CardListDataTable.DATA_TABLE, 'table');
      } else if (newAlignment == 'cards') {
        this.switchShowing(CardListDataTable.CARD_LIST, 'cards');
      }
    };

    const showing = getFromLocalStorage(
      SHOWING_LOCAL_STORAGE_KEY,
      [this.props.id, SHOWING_KEY],
      this.props.defaultShowing
    );

    this.state = {
      showing,
      buttonValue: showing ? 'table' : 'cards',
    };
  }

  render() {
    const header = (
      <div className="card-list-data-table__header">
        {this.props.header}
        <div className="card-list-data-table__selector">
          <DisplayModeButton
            buttonValue={this.state.buttonValue}
            value1="cards"
            value2="table"
            buttonToggled={this.buttonToggled}
          />
        </div>
      </div>
    );

    return (
      <div className="card-list-data-table">
        {this.state.showing === CardListDataTable.CARD_LIST && (
          <CardList
            {...this.props}
            header={header}
            fields={this.props.cols.concat(this.props.fields)}
          />
        )}
        {this.state.showing === CardListDataTable.DATA_TABLE && (
          <DataTable {...this.props} header={header} />
        )}
      </div>
    );
  }
}

CardListDataTable.CARD_LIST = 0;
CardListDataTable.DATA_TABLE = 1;

CardListDataTable.propTypes = {
  defaultShowing: PropTypes.oneOf([CardListDataTable.CARD_LIST, CardListDataTable.DATA_TABLE]),
  ...CardList.propTypes,
  ...DataTable.propTypes,
};

CardListDataTable.defaultProps = {
  defaultShowing: CardListDataTable.CARD_LIST,
  ...CardList.defaultProps,
  ...DataTable.defaultProps,
};

export default CardListDataTable;
