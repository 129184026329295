import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import zxcvbnLoader from 'bundle-loader?lazy&name=zxcvbn!zxcvbn';

import SesamTextField, { SesamTextFieldProps } from '../SesamTextField/SesamTextField';

const useStyle = makeStyles((theme) => {
  return {
    helperTextError: {
      color: `${theme.palette.error.main} !important`,
    },
    helperTextWarning: {
      color: `${theme.palette.warning.main} !important`,
    },
    helperTextOk: {
      color: `${theme.palette.success.main} !important`,
    },
  };
});

type PasswordMeterTextFieldProps = {
  customDict: [];
  restProps: SesamTextFieldProps;
};

const PasswordMeterTextField: React.FC<PasswordMeterTextFieldProps> = ({
  customDict,
  ...restProps
}) => {
  const classes = useStyle();

  const [zxcvbnLoaded, setZxcvbnLoaded] = useState(false);

  const zxcvbn = useRef(null);

  useEffect(() => {
    zxcvbnLoader((z) => {
      zxcvbn.current = z;
      setZxcvbnLoaded(true);
    });
  }, []);

  const getCustomDict = () => {
    // Create a better custom dictionary to test passwords against.
    // If there are two entries, "Doctor Strangelove" and "dc.strange-love@example.com"
    // then the dictionary becomes (in addition to those two):
    // ['Doctor', 'Strangelove', 'dc', 'strange', 'love', 'example', 'com']

    const splitRegEx = /@|\.|-| /;

    return customDict.reduce(
      (dictSoFar, entry) => dictSoFar.concat(entry.split(splitRegEx)),
      customDict
    );
  };

  const getHelperText = (result: { score: number; feedback: { warning: string } }) => {
    if (restProps.value.length < 12) {
      return (
        <span
          className={classes.helperTextError}
        >{`Password length must be at least 12 characters ${
          result.feedback.warning && `- ${result.feedback.warning}`
        }`}</span>
      );
    }
    if (result.score < 2) {
      return (
        <span className={classes.helperTextError}>{`Password strength: ${
          result.score === 0 ? 'Very bad' : 'Bad'
        } ${result.feedback.warning && `- ${result.feedback.warning}`}`}</span>
      );
    } else if (result.score >= 2) {
      if (result.score < 4) {
        return (
          <span className={classes.helperTextWarning}>{`Password strength: ${
            result.score === 2 ? 'OK' : 'Good'
          }`}</span>
        );
      } else {
        return <span className={classes.helperTextOk}>{`Password strength: Very good`}</span>;
      }
    }
  };

  let result = null;

  const useZxcvbn = zxcvbnLoaded && restProps.value.length > 0;
  const cd = getCustomDict();

  let zxcvbnHelperText;
  if (useZxcvbn) {
    result = zxcvbn.current(restProps.value, cd);
    zxcvbnHelperText = getHelperText(result);
  }

  return (
    <SesamTextField
      {...restProps}
      type="password"
      helperText={restProps.helperText || zxcvbnHelperText || ''}
      error={(result && result.score < 2) || restProps.error || restProps.value.length < 12}
    />
  );
};

PasswordMeterTextField.defaultProps = {
  customDict: [],
};

export default PasswordMeterTextField;
