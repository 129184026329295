import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import Ellipsis from 'Common/Ellipsis';

const useStyle = makeStyles((theme) => {
  return {
    pageHeader: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0 auto',
      marginBottom: '0.5rem',
      minHeight: '1.75rem',
    },
    pageHeaderGroup: {
      alignItems: 'baseline',
      display: 'flex',
      flex: '0 0 auto',
      marginRight: '1rem',
      '& > *': {
        marginRight: '0.5rem',
      },
      '& > :last-child': {
        marginRight: '0',
      },
      '&:last-child': {
        marginLeft: 'auto',
        marginRight: '0',
      },
    },
    pageHeaderTitle: {
      color: theme.palette.text.primary,
      fontFamily: theme.fonts.headings,
      fontSize: '1.5rem',
      fontWeight: '300',
      marginRight: '1rem',
      '&:last-of-type': {
        flex: '0 1 auto',
        overflow: 'hidden',
      },
      '& a': {
        display: 'block',
        TextDecoration: 'none',
      },
      '&:not(:first-child) .ellipsis::before': {
        content: '"/"',
        display: 'inline',
        marginRight: '1rem',
      },
    },
  };
});

function PageHeader({ style, children }) {
  const classes = useStyle();
  return (
    <div className={classes.pageHeader} style={style}>
      {children}
    </div>
  );
}

PageHeader.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape(),
};

export default PageHeader;

// -----------------------------------------------------------------------------

export function PageHeaderGroup({ children }) {
  const classes = useStyle();
  return <div className={classes.pageHeaderGroup}>{children}</div>;
}

PageHeaderGroup.propTypes = {
  children: PropTypes.node,
};

// -----------------------------------------------------------------------------

export function PageHeaderTitle({ children, ...restProps }) {
  const classes = useStyle();
  return (
    <h1 className={classes.pageHeaderTitle} {...restProps}>
      <Ellipsis>{children}</Ellipsis>
    </h1>
  );
}

PageHeaderTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};
