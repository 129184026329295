import PropTypes from 'prop-types';
import React from 'react';

import MenuToggler from '../menu-toggler';
import { MenuCheckbox } from '../menu';

function FacetSelector({ filter, filterLabels, onToggleFacetVisibility, visibleFacets }) {
  return (
    <div className="facet-selector">
      <MenuToggler
        label="More"
        labelClassName="btn btn--dropdown"
        closeOnClickOutside={true}
        closeOnEsc
        closeOnInteraction={true}
      >
        {Object.keys(filter).map((c) => {
          return (
            <MenuCheckbox
              key={c}
              label={filterLabels[c]}
              checked={visibleFacets.includes(c)}
              onChange={() => onToggleFacetVisibility(c)}
            />
          );
        })}
      </MenuToggler>
    </div>
  );
}

FacetSelector.propTypes = {
  filter: PropTypes.shape({}).isRequired,
  filterLabels: PropTypes.shape({}).isRequired,
  onToggleFacetVisibility: PropTypes.func.isRequired,
  visibleFacets: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FacetSelector;
