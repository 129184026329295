import { sfetchJson } from 'Internals/sfetch';
import { ApiConf, EntityType } from '../types/types';

export type EntityTypeKind = 'sink' | 'source';

/**
 * Get entity types for a pipe
 * @param {{ subUrl: string, token: string }} apiConf
 * @param {string} id Id of the pipe
 * @param {("sink" | "source")} kind Whether to get the sink or source entity types
 * @returns {Promise<*>}
 */
async function getEntityType(apiConf: ApiConf, id: string, kind: EntityTypeKind) {
  if (kind === 'sink') {
    return await getSinkEntityType(apiConf, id);
  } else if (kind === 'source') {
    return await getSourceEntityType(apiConf, id);
  } else {
    throw new Error("Unknown kind of pipe entity type. Please specify 'sink' or 'source'.");
  }
}

/**
 * Get sink entity types for a pipe
 * @param {{ subUrl: string, token: string }} apiConf
 * @param {string} id Id of the pipe
 * @returns {Promise<*>}
 */
async function getSinkEntityType(apiConf: ApiConf, id: string) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/pipes/${id}/entity-types/sink`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };
  try {
    return await sfetchJson(url, options);
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * Get source entity types for a pipe
 * @param {{ subUrl: string, token: string }} apiConf
 * @param {string} id Id of the pipe
 * @returns {Promise<*>}
 */
async function getSourceEntityType(apiConf: ApiConf, id: string) {
  const { subUrl, token } = apiConf;

  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };

  const entityTypeUrl = `${subUrl}/pipes/${id}/entity-types/source`;
  try {
    return await sfetchJson(entityTypeUrl, options);
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * Use the old generate schema API
 * @param {{ subUrl: string, token: string }} apiConf
 * @param {string} id Id of the pipe
 * @param {number} sampleSize How many entities to use for the schema generation
 * @param {boolean} keysOnly
 * @returns {Promise<*>}
 */
async function getSchemaDefinition(
  apiConf: ApiConf,
  id: string,
  sampleSize = 1000,
  keysOnly?: boolean
) {
  const { subUrl, token } = apiConf;
  let url = `${subUrl}/pipes/${id}/generate-schema-definition?sample_size=${sampleSize}`;
  if (keysOnly) {
    url = `${url}&keys_only=true`;
  }

  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };

  try {
    return await sfetchJson(url, options);
  } catch (e) {
    throw new Error(e);
  }
}

export default {
  getEntityType,
  getSourceEntityType,
  getSinkEntityType,
  getSchemaDefinition,
};
