import SesamLink from 'Common/Links/SesamLink';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => {
  return {
    tabbedNav: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      marginTop: '0.5rem',
      overflow: 'hidden',
      height: '100%',
    },
    tabbedNavTabs: {
      display: 'flex',
      flex: '0 0 auto',
      flexWrap: 'wrap-reverse',
      marginBottom: '-1px',
      zIndex: '20',
    },
    tabbedNavTabActive: {
      borderBottomColor: `${theme.palette.background.light} !important`,
      '&::before': {
        backgroundColor: theme.palette.primary.main,
        content: '""',
        height: '0.25rem',
        left: '-1px',
        position: 'absolute',
        right: '-1px',
        top: '-1px',
        zIndex: '10',
      },
    },
    tabbedNavTab: {
      backgroundColor: theme.palette.background.light,
      border: `1px solid ${theme.palette.divider}`,
      borderRightWidth: '0',
      color: theme.palette.text.primary,
      flex: '0 0 auto',
      flexWrap: 'wrap-reverse',
      padding: '0.5rem 1rem 0.25rem 1rem',
      position: 'relative',
      textDecoration: 'none',
      '&:last-child': {
        borderRightWidth: '1px',
      },
      '&:focus': {
        outline: 'none',
        textDecoration: 'underline',
      },
    },
    tabbedNavContent: {
      backgroundColor: theme.palette.background.light,
      border: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      padding: '1rem 1rem 1rem 1rem',
      flexDirection: 'column',
      flex: ' 1 1 auto',
      overflow: 'auto',
      height: '100%',
      position: 'relative',
    },
  };
});

const TabbedNav = function ({ children, nav }) {
  const classes = useStyle();
  return (
    <div className={classes.tabbedNav}>
      <nav className={classes.tabbedNavTabs}>
        {nav.map((navItem) => (
          <SesamLink
            activeClassName={classes.tabbedNavTabActive}
            className={classes.tabbedNavTab}
            data-selenium="tabbedNavTab"
            key={navItem.label}
            title={navItem.tooltip}
            to={navItem.to}
            buttonLink={true}
          >
            {navItem.label}
          </SesamLink>
        ))}
      </nav>
      <div className={classes.tabbedNavContent}>{children}</div>
    </div>
  );
};

TabbedNav.propTypes = {
  children: PropTypes.node.isRequired,
  nav: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
    })
  ),
};

TabbedNav.defaultProps = {
  nav: [],
};

export default TabbedNav;
