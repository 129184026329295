import React from 'react';

import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';

const SesamKeyboardDatePicker: React.FC<KeyboardDatePickerProps> = (props) => {
  return <KeyboardDatePicker {...props} />;
};

SesamKeyboardDatePicker.defaultProps = {
  variant: 'inline',
  format: 'LLLL',
  orientation: 'landscape',
};

export default SesamKeyboardDatePicker;
