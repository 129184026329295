import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Form, FormActions } from 'Common/forms';

import Actions from 'Redux/thunks/user-profile';
import Feedback from '../../components/feedback';
import PromiseButton from '../../components/promise-button';

const RequestVerifyEmailForm = (props) => {
  return !props.verified ? (
    <Form component="div">
      <Feedback type="warning">Your email address has not been verified yet</Feedback>
      <FormActions>
        <PromiseButton
          onClick={props.requestVerifyEmail}
          pending="Requesting…"
          disabled={!props.enabled}
        >
          Request verification email
        </PromiseButton>
      </FormActions>
    </Form>
  ) : (
    <Feedback>Your email is verified</Feedback>
  );
};

RequestVerifyEmailForm.propTypes = {
  verified: PropTypes.bool,
  enabled: PropTypes.bool.isRequired,
  requestVerifyEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  verified: state.user.emailVerified,
  enabled: state.user.supportedOperations.request_email_verification.enabled,
});

const mapDispatchToProps = (dispatch) => ({
  requestVerifyEmail: () =>
    dispatch(Actions.requestVerifyEmail()).then(() => dispatch(Actions.load())),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestVerifyEmailForm);
