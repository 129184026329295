import React from 'react';
import { withTheme } from '@material-ui/core/styles';

import SesamCheckboxCompact from 'Common/SesamCheckboxCompact/SesamCheckboxCompact';

function FilterTableRow({ index, style, data, rowWidth, theme }) {
  const backgrounds = [theme.palette.background.light, theme.palette.background.semilight];
  const styleCopy = { ...style };
  styleCopy.top = styleCopy.top + 30;
  const row = data.rows[index];
  data.prepareRow(row);
  const bg = backgrounds[(index + 1) % 2];
  return (
    <div
      {...row.getRowProps({
        style: { ...styleCopy, background: bg, width: rowWidth },
        className: 'table__row',
      })}
    >
      <div
        className="table__data"
        style={{
          width: '30px',
          maxWidth: '30px',
          flex: '30 0 auto',
          textAlign: 'center',
          padding: '5px 0',
          borderRight: `1px solid ${theme.palette.divider}`,
        }}
      >
        <SesamCheckboxCompact
          onChange={() => row.toggleRowSelected(!row.isSelected)}
          checked={row.isSelected}
          size="small"
          disableRipple
        />
      </div>
      {row.cells.map((cell) => {
        const style = {
          flex: `${cell.column.width} 0 auto`,
          width: `${cell.column.width}px`,
          // borderRight: `1px solid ${theme.palette.divider}`,
        };
        if (cell.column.maxWidth) {
          style.maxWidth = `${cell.column.maxWidth}px`;
        }
        if (cell.column.align) {
          style.textAlign = cell.column.align;
        }
        const props = cell.getCellProps({
          className: 'table__data',
          style,
          role: 'gridcell',
        });

        return <div {...props}>{cell.render('Cell')}</div>;
      })}
    </div>
  );
}

export default withTheme(FilterTableRow);
