import React from 'react';
import PropTypes from 'prop-types';

import { LoadingPanel } from 'Common/LoadingPanel';
import JsonPanel from 'Common/JsonPanel';
import EditorPanelDivider from '../editor/EditorPanelDivider';

function PipeAnalyseExecution({ analysis, analysisReady, className }) {
  return (
    <div className={className || ''}>
      <EditorPanelDivider>Execution plan</EditorPanelDivider>
      {!analysisReady && <LoadingPanel loadingMessage="Analysing…" size="medium" />}
      {analysisReady && <JsonPanel rawJson={analysis || []} filter={false} alwaysUpdate />}
    </div>
  );
}

PipeAnalyseExecution.propTypes = {
  analysis: PropTypes.any,
  analysisReady: PropTypes.bool,
  className: PropTypes.string,
};

export default PipeAnalyseExecution;
