import { sfetchJson } from 'Internals/sfetch';
import { ApiConf } from '../types/types';

async function get(
  apiConf: ApiConf,
  entityId: string,
  since?: number,
  limit?: number,
  reverse?: boolean
) {
  const { subUrl, token } = apiConf;
  const entityIdUrl = encodeURIComponent(entityId);
  const limitStr = limit?.toString();
  const url = `${subUrl}/config-history/entity?entity_id=${entityIdUrl}${
    since ? `&since=${since}` : ''
  }${limitStr ? `&limit=${limitStr}` : ''}${
    reverse || reverse === false ? `&reverse=${reverse}` : ''
  }`;

  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return await sfetchJson(url, options);
}

export default {
  get,
};
