import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'Common/Button/Button';
import { dataAttrsFromProps } from 'Internals/react-utils';

/**
 * A button that reflects the promise that the onClick handler returns.
 * Displays prop "pending" if promise is pending, otherwise children are displayed.
 * If no pending is supplied and children is string we display children with some decoration
 */
function PromiseButton({ children, disabled, onClick, pending, ...restProps }) {
  const [isPending, setIsPending] = useState(false);

  const handleClick = (ev) => {
    setIsPending(true);
    onClick(ev)
      .then(() => setIsPending(false))
      .catch(() => setIsPending(false));
  };

  const pendingOrChildren = isPending ? pending : children;
  return (
    <Button
      {...restProps}
      disabled={disabled || isPending}
      onClick={handleClick}
      {...dataAttrsFromProps(restProps)}
    >
      {pendingOrChildren}
    </Button>
  );
}

PromiseButton.propTypes = {
  children: PropTypes.any.isRequired,
  pending: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

export default PromiseButton;
