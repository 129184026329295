import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import useClipboard from '../../../hooks/useClipboard';
import { DatasetInspectorContext } from '../DatasetInspectorContext';
import TerminalIcon from '../../../images/icons/terminal.svg';

function DatasetInspectorViewerEntityHeaderCurl(props) {
  const { addToast, token, subUrl } = props;
  const [state, actions, showTableView, datasetID] = useContext(DatasetInspectorContext);
  const deleted = state.deleted;
  const history = state.history;
  const idUrl = encodeURIComponent(datasetID);
  const uncommitted = state.uncommitted;
  const curlString = `curl \
    -H "Authorization: bearer ${token}" \
    "${subUrl}/datasets/${idUrl}/entities?deleted=${deleted}&history=${history}&reverse=true&uncommitted=${uncommitted}&limit=30"`;

  useClipboard('.dataset-inspector-viewer__entity-header-curl-button', () => {
    addToast('Copied to clipboard!');
  });
  return (
    <React.Fragment>
      <button
        className="btn btn--small dataset-inspector-viewer__entity-header-curl-button"
        title="Copy get entities CURL to clipboard"
        data-clipboard-text={curlString}
      >
        <TerminalIcon style={{ width: '20px', height: '20px', marginLeft: 'auto' }} />
      </button>
    </React.Fragment>
  );
}

DatasetInspectorViewerEntityHeaderCurl.propTypes = {
  addToast: PropTypes.func.isRequired,
  token: PropTypes.string,
  subUrl: PropTypes.string,
};

export default withRouter(DatasetInspectorViewerEntityHeaderCurl);
