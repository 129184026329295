import { handlePortalError } from '../utils';
import MonitoringAPI from '../../api/monitoring';
import { AppThunk } from '../..';

// All of these are not really thunks, just API calls with error handling

export default {
  getPipeStats(pipeId: string, handleError = true): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      const subId = getState().subscription.id;
      return MonitoringAPI.getPipeStats(portalUrl, subId, pipeId).catch((error) => {
        if (handleError) return dispatch(handlePortalError(error));
        else throw error;
      });
    };
  },
  getSubscriptionStats(subId: string, handleError = true): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return MonitoringAPI.getSubscriptionStats(portalUrl, subId).catch((error) => {
        if (handleError) return dispatch(handlePortalError(error));
        else throw error;
      });
    };
  },
  getInvoiceData(subId: string, handleError = true): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return MonitoringAPI.getInvoiceData(portalUrl, subId).catch((error) => {
        if (handleError) return dispatch(handlePortalError(error));
        else throw error;
      });
    };
  },
};
