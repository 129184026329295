import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';

import PipeInput from '../../../components/pipe-input/PipeInput';

const FlowInputPage = ({ flow }) => {
  const firstPipe = get(flow, '[0].data');
  return <PipeInput pipe={firstPipe} />;
};

FlowInputPage.propTypes = {
  flow: PropTypes.arrayOf(PropTypes.shape({})),
};

export default FlowInputPage;
