import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useBlockLayout, useTable, useResizeColumns } from 'react-table';

import Skeleton from '@material-ui/lab/Skeleton';
import TackIcon from '../../../../images/icons/tack.svg';
import TackDownIcon from '../../../../images/icons/tack_down.svg';
import { withTheme } from '@material-ui/core';

function EntitySkeleton() {
  return (
    <div
      style={{
        height: '32px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div className="table__data">
        <Skeleton />
      </div>
    </div>
  );
}

function DatasetTable(props) {
  const {
    columns,
    data,
    hasJumpValue,
    loading,
    loadingMore,
    loadingMoreTop,
    onScroll,
    onSelectEntity,
    onSetTableContainerRef,
    populating,
    selectedEntityOffset,
    theme,
  } = props;

  const tableRef = useRef(null);
  const headerRef = useRef(null);

  const initialState = {
    pinnedRow: {
      row: null,
      updatedVal: null,
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    totalColumnsWidth,
  } = useTable(
    {
      columns,
      data,
      tableRef,
      initialState,
    },
    useBlockLayout,
    useResizeColumns,
    (hooks) => {
      function reducer(state, action) {
        if (action.type === 'toggle_pinned') {
          return { ...state, pinnedRow: action.id };
        }
      }
      hooks.stateReducers.push(reducer);

      hooks.visibleColumns.push((columns) => [
        {
          id: 'pin',
          show: true,
          width: 50,
          Cell: (instance) => {
            const isRowPinned = instance.state.pinnedRow === instance.row.id;
            return (
              <span
                className="table__icon"
                onClick={() => {
                  instance.dispatch({
                    type: 'toggle_pinned',
                    id: instance.row.id,
                  });
                }}
              >
                {isRowPinned ? <TackDownIcon /> : <TackIcon />}
              </span>
            );
          },
        },
        ...columns,
      ]);
    }
  );
  const containerWidth = tableRef.current && tableRef.current.clientWidth;
  const width = totalColumnsWidth < containerWidth ? containerWidth : totalColumnsWidth;

  return (
    <div
      className="table"
      ref={(el) => {
        onSetTableContainerRef(el);
      }}
      onScroll={onScroll}
    >
      <div
        {...getTableProps()}
        cellSpacing="0"
        className="table__table"
        ref={(r) => (tableRef.current = r)}
      >
        <div
          className={`table__header table__header--sticky ${
            theme.palette.type === 'dark' ? 'table__header table__header--sticky--dark' : ''
          }`}
          style={{ width }}
          ref={(r) => (headerRef.current = r)}
        >
          {headerGroups.map((headerGroup, i) => (
            <div className="table__row" key={i}>
              {headerGroup.headers.map((column) => {
                return (
                  <div
                    key={column.id}
                    {...column.getHeaderProps({
                      style: { flex: '1 1 auto' },
                    })}
                    className="table__header-col"
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {column.render('Header')}
                      {column.type && <div className="table__header-type">({column.type})</div>}
                    </div>
                    <div {...column.getResizerProps()} className="table__header-resizer" />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div
          {...getTableBodyProps()}
          style={{
            position: 'relative',
          }}
        >
          {((populating && hasJumpValue) || loadingMoreTop) && <EntitySkeleton />}

          <div>
            {rows.map((row, i) => {
              prepareRow(row);

              const updatedVal = row['original']['_updated'];
              const isPinned = state.pinnedRow === row.id;
              const className = [
                'table__row',
                i % 2 === 0 ? 'table__row--even' : 'table__row--odd',
                selectedEntityOffset !== undefined && selectedEntityOffset === updatedVal
                  ? 'table__row--selected'
                  : '',
                isPinned
                  ? `table__row--pinned ${
                      theme.palette.type === 'dark' ? 'table__row--pinned--dark' : ''
                    }`
                  : '',
              ]
                .join(' ')
                .trim();

              const rowStyle = {
                height: '32px',
                width,
              };

              if (isPinned) {
                rowStyle['top'] = headerRef.current.clientHeight;
              }

              return (
                <div
                  {...row.getRowProps()}
                  style={rowStyle}
                  key={i}
                  className={className}
                  onClick={() => {
                    onSelectEntity(row.original);
                  }}
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <div {...cell.getCellProps()} className="table__data" key={i}>
                        <div>{cell.render('Cell')}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="dataset-inspector-table__table-skeleton">
            {(populating || loading) &&
              [...Array(15).keys()].map((c, i) => <EntitySkeleton key={i} />)}
            {loadingMore && <EntitySkeleton />}
          </div>
        </div>
      </div>
    </div>
  );
}

DatasetTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasJumpValue: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  loadingMoreTop: PropTypes.bool.isRequired,
  onScroll: PropTypes.func.isRequired,
  onSelectEntity: PropTypes.func.isRequired,
  onSetTableContainerRef: PropTypes.func.isRequired,
  populating: PropTypes.bool.isRequired,
  selectedEntityOffset: PropTypes.number,
  theme: PropTypes.shape({}).isRequired,
};

DatasetTable.defaultProps = {
  sortable: true,
};

export default withTheme(DatasetTable);
