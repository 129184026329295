import React, { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import findIndex from 'lodash/findIndex';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import SearchFacetList from './SearchFacetList';
import SearchAPI from '../../api/search';
import EntitiesAPI from '../../api/entities';
import RefBox from './RefBox';
import SearchBox from './SearchBox';
import SearchEntityViewer from './SearchEntityViewer';
import SearchResultList from './SearchResultList';
import EmptyState from 'Common/EmptyState/EmptyState';
import { apiConfSelector, searchIsActiveSelector } from 'Redux/selectors';
import { SearchFacet, SearchResult, SomeObject } from '../../types/types';
import { featureLevelSelector } from 'Redux/thunks/datahub';
import SesamLink from 'Common/Links/SesamLink';
import { useSubId } from '../../hooks/sesam';
import SlideRightIcon from '../../images/icons/slide-right.svg';

interface SearchProps {
  router: SomeObject;
  registerRefresh: (refresh: Function) => void;
  unregisterRefresh: (refresh: Function) => void;
}

function sortFacets(a: SearchFacet, b: SearchFacet) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}

const DEFAULT_SEARCH_SIZE = 40;

const useStyle = makeStyles({
  container: { display: 'flex', flexDirection: 'row', minWidth: '90px' },
  browse: {
    marginRight: '10px',
    flexBasis: 'auto',
    flexShrink: 0,
    maxWidth: '320px',
    overflowY: 'auto',
  },
  results: {
    marginRight: '10px',
    flexBasis: 'auto',
    flexShrink: 0,
    flexGrow: 1,
    maxWidth: '500px',
    wordBreak: 'break-all',
    overflowY: 'auto',
  },
  details: {
    flexBasis: 'auto',
    flexShrink: 0,
    minWidth: '400px',
    flexGrow: 1,
    overflowY: 'auto',
  },
  collapsible: {
    width: '50px',
    flexGrow: 0,
    display: 'flex',
    flexShrink: 0,
    background: '#F7F8F8',
    marginRight: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
    '& svg': {
      display: 'block',
      height: '2em',
      margin: 'auto',
      padding: '0.25em 0',
      width: '2em',
      color: '#58585B',
    },
  },
  hide: {
    display: 'none',
  },
});

function getSearchFacetsFromQuery(query: string | string[]): SearchFacet[] {
  const queryArr = Array.isArray(query) ? query : [query];
  return queryArr.map((name: string) => {
    return { name };
  });
}

function getQueryFromSearchFacets(searchFacets: SearchFacet[]) {
  return searchFacets.map((searchFacet: SearchFacet) => {
    return searchFacet.name;
  });
}

const Search: React.FC<SearchProps> = (props) => {
  const { router } = props;
  const subId = useSubId();
  const hasSearch = useSelector(searchIsActiveSelector);

  // redirect if search is not activated
  useEffect(() => {
    if (subId && hasSearch === false) {
      router.replace({
        pathname: `/subscription/${subId}/browse/entity-types`,
      });
    }
  }, [subId, hasSearch]);

  const refresh = () => search();

  useEffect(() => {
    props.registerRefresh(refresh);
    return () => {
      props.unregisterRefresh(refresh);
    };
  });

  const classes = useStyle();

  const apiConf = useSelector(apiConfSelector);

  const initialQuery = router.location.query.q || '';
  const [query, setQuery] = useState<string>(initialQuery);

  const [datasetFacets, setDatasetFacets] = useState<SearchFacet[]>([]);
  const datasetFromURL = router.location.query.ds;
  let initialActiveDatasetFacets: SearchFacet[] = [];
  if (datasetFromURL !== undefined) {
    initialActiveDatasetFacets = getSearchFacetsFromQuery(datasetFromURL);
  }
  const [activeDatasetFacets, setActiveDatasetFacets] = useState<SearchFacet[]>(
    initialActiveDatasetFacets
  );

  const [namespacesFacets, setNamespacesFacets] = useState<SearchFacet[]>([]);
  const namespacesFromURL = router.location.query.ns;
  let initialActiveNamespacesFacets: SearchFacet[] = [];
  if (namespacesFromURL !== undefined) {
    initialActiveNamespacesFacets = getSearchFacetsFromQuery(namespacesFromURL);
  }
  const [activeNamespacesFacets, setActiveNamespacesFacets] = useState<SearchFacet[]>(
    initialActiveNamespacesFacets
  );

  const [propertiesFacets, setPropertiesFacets] = useState<SearchFacet[]>([]);
  const propertiesFromURL = router.location.query.ps;
  let initialActivePropertiesFacets: SearchFacet[] = [];
  if (propertiesFromURL !== undefined) {
    initialActivePropertiesFacets = getSearchFacetsFromQuery(propertiesFromURL);
  }
  const [activePropertiesFacets, setActivePropertiesFacets] = useState<SearchFacet[]>(
    initialActivePropertiesFacets
  );

  useEffect(() => {
    const locationCopy = { ...router.location };
    const queryCopy = { ...router.location.query };
    queryCopy.q = query;
    queryCopy.ds = getQueryFromSearchFacets(activeDatasetFacets);
    queryCopy.ns = getQueryFromSearchFacets(activeNamespacesFacets);
    queryCopy.ps = getQueryFromSearchFacets(activePropertiesFacets);

    locationCopy.query = queryCopy;
    router.replace(locationCopy);
  }, [activeDatasetFacets, activeNamespacesFacets, activePropertiesFacets, router]);

  const [from, setFrom] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [appending, setAppending] = useState<boolean>(false);
  const [totalHits, setTotalHits] = useState<number | null>(null);

  const [selectedEntityInfo, setSelectedEntityInfo] = useState<SearchResult>({
    id: '',
    dataset: '',
    ids: [],
  });
  const [selectedEntity, setSelectedEntity] = useState<SomeObject>();
  useEffect(() => {
    async function getEntity() {
      const response = await EntitiesAPI.lookupEntity(
        { ...apiConf, dataset: selectedEntityInfo.dataset },
        selectedEntityInfo.id
      );

      if (response.length > 0) {
        setSelectedEntity(response[0]);
      }
    }

    if (selectedEntityInfo) {
      getEntity();
    }
  }, [selectedEntityInfo]);

  const ref = router.location.query.ref;
  const clearRef = () => {
    const locationCopy = { ...router.location };
    const queryCopy = { ...router.location.query };

    delete queryCopy.ref;

    locationCopy.query = queryCopy;
    router.push(locationCopy);
  };

  useEffect(() => {
    search();
  }, [activeDatasetFacets, activeNamespacesFacets, activePropertiesFacets, ref]);

  function handleResultClicked(id: string) {
    const encodedId = encodeURIComponent(id);
    router.push(`/subscription/${subId}/browse/ni/${encodedId}`);
  }

  function handleScroll(ev: React.UIEvent) {
    if (appending === false && searching === false && hasMore === true) {
      const target = ev.target as HTMLDivElement;
      if (target.scrollTop + target.clientHeight > target.scrollHeight - 90) {
        search(true);
      }
    }
  }

  function updateLocationRouterOnSearch() {
    const locationCopy = { ...router.location };
    const queryCopy = { ...router.location.query };

    queryCopy.q = query;

    locationCopy.query = queryCopy;
    router.push(locationCopy);
  }

  async function search(append = false) {
    if (append === false) {
      setSearching(true);
    } else {
      setAppending(true);
    }

    updateLocationRouterOnSearch();

    if (append === false) {
      const datasetAggregations = await SearchAPI.getDatasetAggregations(apiConf, {
        searchString: query,
        namespaces: activeNamespacesFacets.map((n) => n.name),
        properties: activePropertiesFacets.map((p) => p.name),
        ref,
      });
      setDatasetFacets(datasetAggregations.results.sort(sortFacets));

      const namespaceAggregations = await SearchAPI.getNamespaceAggregations(apiConf, {
        searchString: query,
        datasets: activeDatasetFacets.map((d) => d.name),
        namespaces: activeNamespacesFacets.map((n) => n.name),
        properties: activePropertiesFacets.map((p) => p.name),
        ref,
      });
      setNamespacesFacets(namespaceAggregations.results.sort(sortFacets));

      const propertiesAggregations = await SearchAPI.getPropertiesAggregations(apiConf, {
        searchString: query,
        datasets: activeDatasetFacets.map((d) => d.name),
        namespaces: activeNamespacesFacets.map((n) => n.name),
        ref,
      });
      setPropertiesFacets(propertiesAggregations.results.sort(sortFacets));
    }
    const newFrom = append ? from + DEFAULT_SEARCH_SIZE : 0;

    const searchResults = await SearchAPI.searchText(apiConf, {
      searchString: query,
      datasets: activeDatasetFacets.map((f) => f.name),
      namespaces: activeNamespacesFacets.map((n) => n.name),
      properties: activePropertiesFacets.map((p) => p.name),
      from: newFrom,
      ref,
      size: DEFAULT_SEARCH_SIZE,
    });

    const hits = searchResults.results;

    if (hits.length === 0) {
      setIsEmptySearch(true);
    } else {
      setIsEmptySearch(false);
    }

    if (hits.length < DEFAULT_SEARCH_SIZE) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }

    if (append === false) {
      setSelectedEntityInfo(hits[0]);
    }

    setResults((oldHits: SearchResult[]) => {
      if (append) {
        return [...oldHits, ...hits];
      } else {
        return [...hits];
      }
    });
    setFrom(newFrom);
    setSearching(false);
    setAppending(false);
    setTotalHits(searchResults.total_hits);
  }

  function toggleFacet(toggledFacet: SearchFacet, activeFacets: SearchFacet[]) {
    const activeFacetsCopy = [...activeFacets];
    const idx = findIndex(activeFacets, (facet) => facet.name === toggledFacet.name);

    if (idx === -1) {
      activeFacetsCopy.push(toggledFacet);
    } else {
      activeFacetsCopy.splice(idx, 1);
    }

    return activeFacetsCopy;
  }

  const nodeFeatureLevel = useSelector(featureLevelSelector);

  if (nodeFeatureLevel && nodeFeatureLevel < 6) {
    return (
      <EmptyState>
        Browsing global data is not supported on this subscription. You can enable this feature in
        the{' '}
        <SesamLink to={`subscription/${subId}/settings-subscription/products`}>
          Product Settings
        </SesamLink>
        .
      </EmptyState>
    );
  }

  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className={clsx(['scrollArea', classes.container])} onScroll={handleScroll}>
      <div
        className={clsx([classes.collapsible, `${collapsed ? '' : classes.hide}`])}
        onClick={() => setCollapsed((prev) => !prev)}
      >
        <SlideRightIcon />
      </div>
      <div className={clsx([classes.browse, `${collapsed ? classes.hide : ''}`])}>
        <div>
          <SearchBox
            onSearch={() => {
              search();
            }}
            onQueryChanged={(query: string) => setQuery(query)}
            query={query}
            onClick={() => setCollapsed((prev) => !prev)}
          />
        </div>
        {ref && <RefBox onClearRef={clearRef} reference={ref} subId={subId} />}
        <div>
          <SearchFacetList
            activeFacets={activeDatasetFacets}
            facets={datasetFacets}
            name="dataset"
            onClearAggregation={() => setActiveDatasetFacets([])}
            onFacetToggle={(toggledFacet: SearchFacet) => {
              setActiveDatasetFacets((activeDatasetFacets: SearchFacet[]) =>
                toggleFacet(toggledFacet, activeDatasetFacets)
              );
            }}
          />
          <SearchFacetList
            activeFacets={activeNamespacesFacets}
            facets={namespacesFacets}
            name="namespaces"
            onClearAggregation={() => setActiveNamespacesFacets([])}
            onFacetToggle={(toggledFacet: SearchFacet) => {
              setActiveNamespacesFacets((activeNamespacesFacets: SearchFacet[]) =>
                toggleFacet(toggledFacet, activeNamespacesFacets)
              );
            }}
          />
          <SearchFacetList
            activeFacets={activePropertiesFacets}
            facets={propertiesFacets}
            name="properties"
            onClearAggregation={() => setActivePropertiesFacets([])}
            onFacetToggle={(toggledFacet: SearchFacet) => {
              setActivePropertiesFacets((activePropertiesFacets: SearchFacet[]) =>
                toggleFacet(toggledFacet, activePropertiesFacets)
              );
            }}
          />
        </div>
      </div>
      <div className={classes.results}>
        <SearchResultList
          hasMore={hasMore}
          isEmpty={isEmptySearch}
          onClickResult={handleResultClicked}
          results={results}
          searching={searching}
          selectedEntityInfo={selectedEntityInfo}
          setSelectedEntityInfo={(entityInfo: SearchResult) => setSelectedEntityInfo(entityInfo)}
          subId={subId}
          totalHits={totalHits}
        />
      </div>
      <div className={classes.details} style={{ position: 'sticky', top: 0 }}>
        {selectedEntity && <SearchEntityViewer selectedEntity={selectedEntity} />}
      </div>
    </div>
  );
};

export default withRouter(Search);
