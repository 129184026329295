/**
 * Header bar at top of application; contains logo and utility navigation
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '@material-ui/icons/NotificationsNoneOutlined';
import Search from '@material-ui/icons/SearchOutlined';
import { makeStyles } from '@material-ui/core/styles';

import { getCurrentSub } from 'Redux/thunks/subscriptions';
import { commandPaletteToggled } from 'Redux/thunks/global';
import SesamLink from 'Common/Links/SesamLink';
import AnnouncementBanner from '../announcement-banner';
import Changelog from '../changelog/Changelog';
import HeaderButton from '../header-button';
import SubscriptionSelector from '../subscription-selector';
import UserFeedback from '../user-feedback';
import UserMenu from '../user-menu';
import { DatahubObjectsPaletteId } from '../datahub-objects-palette';
import { subscriptionProps } from '../../model-props';

import Logo from '../../images/sesamlogo.svg';
import './style.css';

const mainLink = '/dashboard';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.text.brandActive,
      textDecoration: 'none',
    },
  },
}));

function AppHeader(props) {
  const classes = useStyles();
  const logo = props.isLoggedIn ? (
    <SesamLink to={mainLink} className="app-header__logo" data-selenium="dashboard-link">
      <Logo className="app-header__logo-img img-responsive" alt="Logo" />
    </SesamLink>
  ) : (
    <div className="app-header__logo">
      <Logo className="app-header__logo-img img-responsive" alt="Logo" />
    </div>
  );

  return (
    <header>
      <div className={props.darkModeActive ? 'app-header dark' : 'app-header'}>
        {logo}
        <nav className="app-header__utility-nav">
          {props.currentSub && (
            <SubscriptionSelector currentSub={props.currentSub} subs={props.subs} />
          )}
          {props.isLoggedIn && <AnnouncementBanner />}
          {props.isLoggedIn && <UserFeedback />}
          {props.currentSub && (
            <HeaderButton darkModeActive={props.darkModeActive}>
              <button
                className="btn-link"
                onClick={props.showDatahubObjectsPalette}
                title="Search datahub…"
              >
                <Search />
              </button>
            </HeaderButton>
          )}
          {props.isLoggedIn && (
            <HeaderButton darkModeActive={props.darkModeActive}>
              <SesamLink to="/alerts" title="Alerts">
                <Alert className={classes.root} />
              </SesamLink>
            </HeaderButton>
          )}
          {props.isLoggedIn && <Changelog darkModeActive={props.darkModeActive} />}
          {props.isLoggedIn && <UserMenu />}
        </nav>
      </div>
    </header>
  );
}
AppHeader.propTypes = {
  currentSub: subscriptionProps,
  isLoggedIn: PropTypes.bool.isRequired,
  subs: PropTypes.arrayOf(subscriptionProps),
  showDatahubObjectsPalette: PropTypes.func,
  darkModeActive: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    currentSub: getCurrentSub(state),
    isLoggedIn: state.user.loggedIn,
    subs: state.subscriptions,
    darkModeActive: state.theme.dark,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showDatahubObjectsPalette: () => dispatch(commandPaletteToggled(DatahubObjectsPaletteId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
