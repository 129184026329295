import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import produce from 'immer';

import { LoadingPanel } from 'Common/LoadingPanel';
import ErrorPanel from 'Common/ErrorPanel';

import KeyValue from '../key-value';
import useResource from '../../hooks/useResource';
import EntityTypePropertiesList from '../entity-type-properties-list/EntityTypePropertiesList';
import './EntityTypeOverviewStyle.css';

function getDefinitionIdFromRef(refVal) {
  let definitionId;
  if (typeof refVal === 'string') {
    const refValParts = refVal.split('/');
    definitionId = refValParts[refValParts.length - 1];
  }
  return definitionId;
}

function parseJsonSchemaDefinition(val) {
  if (typeof val === 'object') {
    if (val.subtype) {
      return val.subtype;
    }
    if (val.anyOf && Array.isArray(val.anyOf)) {
      const result = val.anyOf.map((x) => {
        if (x.subtype) return x.subtype;
        if (x.items) {
          // It's an array, get the type of items
          let type = x.items.type;
          if (x.items.subtype) {
            type = x.items.subtype;
          }
          return `array<${type}>`;
        } 
        if (x.type) return x.type;
      });

      if (result.length !== 0) return [...new Set(result)].join(' | ');
    }
    if (val.type) {
      return val.type;
    }
  }
  return 'Unknown type';
}

const EntityTypeOverview = (props) => {
  const {
    subUrl,
    token,
    params: { entityTypeId, modelId },
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [entityType, loading, error, _refresh] = useResource(
    `${subUrl}/models/${modelId}/${entityTypeId}?effective=true`,
    {
      credentials: 'include',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
    true,
    null
  );

  let cleanEntityType = {};
  let properties = [];
  if (entityType) {
    cleanEntityType = produce(entityType, (draft) => {
      Object.keys(draft).forEach((k) => {
        if (typeof draft[k] === 'object') {
          delete draft[k];
        }
      });
      delete draft.additionalProperties;
      delete draft.type;
    });
    properties = Object.keys(entityType.properties).reduce((acc, propertyName) => {
      const obj = {};
      obj['name'] = propertyName;
      const propertyInfo = entityType.properties[propertyName];
      let propertyType = {};
      if (propertyInfo['$ref']) {
        const definitionName = getDefinitionIdFromRef(propertyInfo['$ref']);
        propertyType = entityType.definitions[definitionName];
      } else if (propertyInfo.type) {
        propertyType = propertyInfo;
      }

      obj['type'] = parseJsonSchemaDefinition(propertyType);
      acc.push(obj);
      return acc;
    }, []);
  }

  if (!entityTypeId) {
    return <div>No entity type specified.</div>;
  }
  if (loading) {
    return <LoadingPanel loadingMessage={'Loading entity type.'} />;
  }
  if (error) {
    return <ErrorPanel title={'Error loading entity type.'} />;
  }

  return (
    <div className="entity-type-overview">
      <div className="entity-type-overview__key-value">
        <KeyValue list={cleanEntityType} />
      </div>
      <b className="entity-type-overview__properties-label">Properties:</b>
      <div className="entity-type-overview__properties-list">
        <EntityTypePropertiesList properties={properties} />
      </div>
    </div>
  );
};

EntityTypeOverview.propTypes = {
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  params: PropTypes.shape({
    entityTypeId: PropTypes.string.isRequired,
    modelId: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    subUrl: state.subscription.url,
    token: state.subscription.token,
  };
}

export default connect(mapStateToProps)(EntityTypeOverview);
