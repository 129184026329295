import { EntityType } from 'Types/types';

export const filterResolvedEntities = (entities: EntityType[]) =>
  entities.filter((entity: EntityType) => {
    if (entity._deleted === true) {
      return true;
    }

    return (
      (!entity.resolved || entity.resolved === false) &&
      (!entity.retryable || entity.retryable === true)
    );
  });
