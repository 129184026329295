import React from 'react';
import BrowsePage from '../browse-page/BrowsePage';
import ModelsList from './ModelsList';

const ModelsListPage = (props) => {
  return (
    <BrowsePage>
      <ModelsList {...props} />
    </BrowsePage>
  );
};

export default ModelsListPage;
