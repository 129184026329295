import React from 'react';
import SesamLink from 'Common/Links/SesamLink';
import { makeStyles } from '@material-ui/core/styles';

import Box from 'Common/Box/Box';
import Button from 'Common/Button/Button';

interface RefBoxProps {
  onClearRef: () => void;
  reference: string;
  subId: string;
}

const useStyle = makeStyles({
  box: {
    marginBottom: '25px',
    height: 'auto',
  },
});

const RefBox: React.FC<RefBoxProps> = ({ onClearRef, reference, subId }) => {
  const classes = useStyle();
  const encodedReference = encodeURIComponent(reference);

  return (
    <Box className={classes.box}>
      <Box.Header>
        <Box.Title>Reference</Box.Title>
        <Box.Actions>
          <Button size="small" onClick={onClearRef}>
            Clear
          </Button>
        </Box.Actions>
      </Box.Header>
      <Box.Content white padding>
        You are browsing entities that reference{' '}
        <SesamLink to={`/subscription/${subId}/browse/ni/${encodedReference}`}>
          {reference}
        </SesamLink>
      </Box.Content>
    </Box>
  );
};

export default RefBox;
