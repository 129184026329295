import { useCallback, useState } from 'react';

function useInputValue(initialValue = '') {
  const onChange = useCallback((event) => {
    setValue(event.currentTarget.value);
  }, []);
  const [value, setValue] = useState(initialValue);

  return { value, onChange };
}

export default useInputValue;
