import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Ellipsis = ({ children, ...restProps }) => {
  const title = typeof children === 'string' ? children : '';
  return (
    <div className="ellipsis" title={title} {...restProps}>
      {children}
    </div>
  );
};

Ellipsis.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default Ellipsis;
