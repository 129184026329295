import { Optional, SomeObject } from '../../types/types';
import { SupportedOperationsResponse } from '../../api/user';
import { createReducer } from '@reduxjs/toolkit';
import { userProfileLoaded } from '../thunks/user-profile';
import {
  userLoggedIn,
  userLoginFailed,
  signupUserFailed,
  forgotPasswordSent,
  passwordReset,
  passwordResetInvalidToken,
} from '../thunks/auth';
import { zendeskConnected } from '../../components/user-feedback';

import { subCreated, tooManySubscriptionsFound } from '../thunks/subscriptions';

type UserState = {
  name: string;
  email: string;
  user_id: Optional<string>;
  loggedIn: boolean;
  loginFailed: boolean;
  signupFailed?: boolean;
  forgotEmailSent: string;
  forgotEmailAlreadySent?: boolean;
  passwordReset: boolean;
  passwordResetInvalidToken?: boolean;
  createSubFailed: boolean;
  captchaRequired: boolean;
  supportedOperations: SupportedOperationsResponse;
  emailVerified: boolean | undefined;
  announcements: string[];
  providerIds: string[];
  providerWhitelist?: string[]; //TODO check this property
  lastCreateTrialRequest?: SomeObject | null; //TODO check this property
  isPortalAdmin?: boolean; //TODO check this property
  zendeskAvailable: boolean;
  approvedTos: number;
};

const initialState: UserState = {
  name: '',
  email: '',
  user_id: null,
  loggedIn: false,
  loginFailed: false,
  forgotEmailSent: '',
  passwordReset: false,
  createSubFailed: false,
  captchaRequired: false,
  supportedOperations: {
    // TODO add the other supported ops with false as default
    request_email_verification: {
      enabled: false,
      id: '',
      name: '',
    },
    delete_account: {
      enabled: false,
      id: '',
      name: '',
    },
  },
  emailVerified: undefined,
  announcements: [],
  providerIds: [],
  zendeskAvailable: false,
  approvedTos: -1,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(userProfileLoaded, (state, action) => {
      state.name = action.payload.profile.name;
      state.email = action.payload.profile.email;
      state.emailVerified = action.payload.profile.email_verified;
      state.supportedOperations = action.payload.supportedOperations;
      state.announcements = action.payload.announcements;
      state.isPortalAdmin = action.payload.isPortalAdmin;
      state.user_id = action.payload.user_id;
      state.lastCreateTrialRequest = action.payload.lastCreateTrialRequest;
      state.providerIds = action.payload.providerIds;
      state.providerWhitelist = action.payload.providerWhitelist;
      state.approvedTos = action.payload.approvedTos;
    })
    .addCase(userLoginFailed, (state, action) => {
      state.loginFailed = true;
      state.captchaRequired = action.payload;
    })
    .addCase(userLoggedIn, (state) => {
      state.loggedIn = true;
      state.loginFailed = false;
      state.captchaRequired = false;
    })
    .addCase(signupUserFailed, (state) => {
      state.signupFailed = true;
    })
    .addCase(forgotPasswordSent, (state, action) => {
      state.forgotEmailSent = action.payload;
      state.forgotEmailAlreadySent = false;
    })
    .addCase(passwordReset, (state) => {
      state.passwordReset = true;
      state.passwordResetInvalidToken = false;
    })
    .addCase(passwordResetInvalidToken, (state) => {
      // TODO create fsm for the different workflows? reset password, forgot password, signup, etc..
      state.passwordReset = false;
      state.passwordResetInvalidToken = true;
    })
    .addCase(tooManySubscriptionsFound, (state) => {
      state.createSubFailed = true;
    })
    .addCase(subCreated, (state) => {
      state.createSubFailed = false;
    })

    .addCase(zendeskConnected, (state) => {
      state.zendeskAvailable = true;
    })
);
