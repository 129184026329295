import { useEffect } from 'react';

import ClipboardJS from 'clipboard';

function useClipboard(selector: string | Element, onSuccess: (e: ClipboardJS.Event) => void) {
  useEffect(() => {
    const clipboard = new ClipboardJS(selector);
    clipboard.on('success', onSuccess);
    return () => {
      clipboard.destroy();
    };
  });
}

export default useClipboard;
