import get from 'lodash/get';
import { getUpstreamPipeFromDatasetId, isGlobal } from './pipes';
import { Dataset, DatasetID, LookupMap, PipeMap, UpstreamMap } from '../types/types';

/**
 * Retrieves the pipe objects for all the pipes this dataset looks up or hops to
 * @param {string} datasetId object to look through
 * @param {object} allPipes array of all pipe objects
 * @param {object} lookups
 * @returns {array} of pipe objects
 */
export function getLookupPipes(datasetId: DatasetID, allPipes: PipeMap, lookups: LookupMap) {
  const pipeIds = Object.keys(get(lookups, datasetId, {}));
  return pipeIds.map((id) => allPipes[id]).filter(Boolean);
}

/**
 * Checks if the upstream pipe of the dataset is a global pipe
 * @param {string} datasetId
 * @param {object} allPipes
 * @param {object} upstreams
 */
export function upstreamPipeIsGlobal(
  datasetId: DatasetID,
  allPipes: PipeMap,
  upstreams: UpstreamMap
) {
  if (datasetId && datasetId.startsWith('system:')) {
    return false;
  } else {
    const upstreamPipe = getUpstreamPipeFromDatasetId(datasetId, allPipes, upstreams);
    if (!upstreamPipe) {
      return false;
    }
    return isGlobal(upstreamPipe);
  }
}

/**
 * Checks if the dataset has a pipe connected to it (does there
 * exist a pipe that has this dataset as a sink?)
 * @param {string} datasetId
 * @param {object} allPipes all pipe objects
 * @param {object} upstreams
 * @returns {true|false}
 */
export function isMaintained(datasetId: DatasetID, allPipes: PipeMap, upstreams: UpstreamMap) {
  // dead letter datasets should not be considered non-maintained
  if (getType(datasetId) === 'dead_letter_dataset') return true;
  if (datasetId && datasetId.startsWith('system:')) {
    return true;
  } else return !!getUpstreamPipeFromDatasetId(datasetId, allPipes, upstreams);
}

type DatasetType = 'execution_dataset' | 'dead_letter_dataset' | 'dataset';
/**
 * Tells us if the dataset is an execution dataset,
 * dead letter dataset or regular dataset
 * @param {string} datasetId
 * @returns {DatasetType} info about the dataset
 * @throws Error if the datasetId isn't a string
 */
export function getType(datasetId: DatasetID): DatasetType {
  if (!(typeof datasetId === 'string')) {
    throw new Error('getType: The supplied datasetId is invalid');
  }

  if (datasetId.startsWith('system:pump:') && !datasetId.startsWith('system:pump:system')) {
    return 'execution_dataset';
  } else if (datasetId.startsWith('system:dead-letter')) {
    return 'dead_letter_dataset';
  } else {
    return 'dataset';
  }
}

/**
 * If this is a dead letter dataset, retrieves its original
 * dataset's id, else undefined
 * @param {string} datasetId to look at
 * @returns {string|undefined} id of original dataset
 */
export function getDeadLetterDatasetOriginalId(datasetId: DatasetID) {
  if (getType(datasetId) === 'dead_letter_dataset') {
    return datasetId.split('system:dead-letter:')[1];
  }
}

/**
 * Retrieves the number of latest entities without
 * deleted ones for this dataset
 * @param {object} dataset
 * @returns {number} of entities
 */
export function getIndexWithoutDeleted(dataset: Dataset) {
  return (
    get(dataset, 'runtime[count-index-exists]', 0) - get(dataset, 'runtime[count-index-deleted]', 0)
  );
}

/**
 * Retrieves the number of all entities in the log
 * of this dataset
 * @param {object} dataset
 * @returns {number} of entities
 */
export function getCompleteLog(dataset: Dataset) {
  return get(dataset, 'runtime[count-log-exists]', 0);
}

/**
 * Retrieves the number of all latest entities
 * of this dataset
 * @param {object} dataset
 * @returns {number} of entities
 */
export function getIndexWithDeleted(dataset: Dataset) {
  return get(dataset, 'runtime[count-index-exists]', 0);
}

/**
 * Retrieves the number of latest deleted entities
 * of this dataset
 * @param {object} dataset
 * @returns {number} of entities
 */
export function getIndexDeleted(dataset: Dataset) {
  return get(dataset, 'runtime[count-index-deleted]', 0);
}

/**
 * Retrieves the number of deleted entities
 * in the history of this dataset
 * @param {object} dataset
 * @returns {number} of entities
 */
export function getLogDeleted(dataset: Dataset) {
  return get(dataset, 'runtime[count-log-deleted]', 0);
}

/**
 * Retrieves the queue size for dataset
 * @param {object} d dataset
 * @returns {number} queue size
 */
export function getDatasetQueueSize(d: Dataset) {
  if (!d.runtime.queues) {
    // old nodes without queues
    return 0;
  }
  if (Array.isArray(d.runtime.queues)) {
    return 0;
  }
  if (!d.runtime.queues.populated) {
    // some datasets never gets "populated", so we skip them for now
    return 0;
  }
  return d.runtime.queues.size;
}

export function getEntitiesPerSecond(r: any) {
  const isRunning = r['is-running'];
  const lastRun = r['last-run'];
  const lastStarted = r['last-started'];
  const entitiesLastRun = get(r, ['progress', 'last-run']);

  let entitiesPerSecond = 0;

  if (isRunning) {
    entitiesPerSecond = get(r, ['metrics', 'entities', 'entities_per_second']) || 0;
  } else if (lastRun !== '' && lastStarted !== '') {
    const lastRunDate = new Date(lastRun);
    const lastStartedDate = new Date(lastStarted);

    const diffInSeconds = (lastRunDate.getTime() - lastStartedDate.getTime()) / 1000;

    entitiesPerSecond = entitiesLastRun / diffInSeconds;
  }

  return entitiesPerSecond;
}

export function isUserDataset(dataset: Dataset) {
  return get(dataset, 'runtime.origin') === 'user';
}
