import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';

import './style.css';

const viewport = window;

class Popover extends React.Component {
  constructor(props) {
    super(props);
    const anchor = props.anchor;
    this.state = {
      anchor,
      positionStyle: {},
    };

    if (anchor) {
      this.attachListeners();
      this.positionByAnchor();
    }

    this.onRepositioningEvent = () => {
      this.positionByAnchor();
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.anchor && this.props.anchor !== prevProps.anchor) {
      this.setState({ anchor: this.props.anchor }, () => {
        this.attachListeners();
        this.positionByAnchor();
      });
    } else if (this.props.anchor && this.props.isOpen && !prevProps.isOpen) {
      this.positionByAnchor();
    } else if (!this.props.anchor && prevProps.anchor) {
      this.setState({ anchor: null });
      this.detachListeners();
    }
  }

  componentWillUnmount() {
    if (this.props.anchor) {
      this.detachListeners();
    }
  }

  positionByAnchor() {
    const positionStyle = {};
    const anchorRect = this.state.anchor.getBoundingClientRect();
    const anchorAP = this.props.anchorPoint.anchor;
    const popoverAP = this.props.anchorPoint.popover;

    const hPos = anchorAP.hPos === 'left' ? anchorRect.left : anchorRect.left + anchorRect.width;
    const vPos = anchorAP.vPos === 'top' ? anchorRect.top : anchorRect.top + anchorRect.height;

    positionStyle[popoverAP.hPos] = popoverAP.hPos === 'left' ? hPos : window.innerWidth - hPos;
    positionStyle[popoverAP.vPos] = popoverAP.vPos === 'top' ? vPos : window.innerHeight - vPos;

    this.setState({ positionStyle });
  }

  attachListeners() {
    viewport.addEventListener('resize', this.onRepositioningEvent);
    viewport.addEventListener('scroll', this.onRepositioningEvent);
  }

  detachListeners() {
    viewport.removeEventListener('resize', this.onRepositioningEvent);
    viewport.removeEventListener('scroll', this.onRepositioningEvent);
  }

  render() {
    const classNames = ['popover'];

    if (!this.props.isOpen) {
      classNames.push('popover--hidden');
    }

    if (this.props.className) {
      classNames.push(this.props.className);
    }

    return (
      <Portal>
        <div
          className={classNames.join(' ')}
          ref={this.props.refPopover}
          style={this.state.positionStyle}
        >
          {this.props.isOpen && this.props.children}
        </div>
      </Portal>
    );
  }
}

export const PositionPropShape = PropTypes.shape({
  hPos: PropTypes.oneOf(['left', 'right']).isRequired,
  vPos: PropTypes.oneOf(['top', 'bottom']).isRequired,
});

export const AnchorPointPropShape = PropTypes.shape({
  anchor: PositionPropShape.isRequired,
  popover: PositionPropShape.isRequired,
});

Popover.propTypes = {
  anchor: PropTypes.instanceOf(Element),
  anchorPoint: AnchorPointPropShape,
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  refPopover: PropTypes.func,
};

Popover.defaultProps = {
  anchorPoint: {
    anchor: { hPos: 'left', vPos: 'bottom' },
    popover: { hPos: 'left', vPos: 'top' },
  },
};

export default Popover;
