import React from 'react';
import PropTypes from 'prop-types';
import SesamLink from 'Common/Links/SesamLink';
import { Form, FormActions } from 'Common/forms';
import './style.css';

function NotFound(props) {
  return (
    <div className="not-found">
      <h2 className="heading-page">{props.title}</h2>
      <p>{props.message}</p>
      <Form>
        <FormActions>
          {props.link && <SesamLink to={props.link}>{props.linkText}</SesamLink>}
        </FormActions>
      </Form>
    </div>
  );
}

NotFound.propTypes = {
  link: PropTypes.string,
  linkText: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
};

NotFound.defaultProps = {
  link: '/',
  linkText: 'Go back to your dashboard',
  message: 'This is not the webpage you are looking for',
  title: '404 Not found',
};

export default NotFound;
