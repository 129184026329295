import { createReducer } from '@reduxjs/toolkit';
import { Alert } from '../../api/alerts';
import { loadedAll } from '../thunks/alerts';

type AlertsState = Alert[];

const initialState: AlertsState = [];

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
const reducer = createReducer(initialState, (builder) =>
  builder.addCase(loadedAll, (state, action) => {
    return action.payload;
  })
);

export default reducer;
