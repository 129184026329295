import React, { useState } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import MenuToggler from '../menu-toggler';
import { MenuControl, MenuSeparator, MenuCheckbox } from '../menu';
import InputWithButton from 'Common/InputWithButton/InputWithButton';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { createSearchRegex } from 'Internals/filtering';

import './FacetStyle.css';

/**
 * A single row component for the Virtual List for the unchecked values
 */
function UncheckedValueRow({ index, style, data }) {
  const { values, handler } = data;
  const value = values[index];
  return (
    <MenuCheckbox
      style={{ ...style, whiteSpace: 'nowrap' }}
      key={value}
      label={value}
      onChange={(e) => handler(e, value)}
      checked={false}
    />
  );
}

function Facet(props) {
  const [facetSearch, setFacetSearch] = useState('');

  const handleSelectedChange = (e, value) => {
    e.stopPropagation();
    props.onFacetSelectedChange(props.filterId, value);
  };

  const handleSearchChange = (e) => {
    e.stopPropagation();
    props.onFacetSearchChange(props.filterId, facetSearch);
    setFacetSearch('');
  };

  const handleRemoveSearch = (e, value) => {
    e.stopPropagation();
    props.onRemoveSearch(props.filterId, value);
  };

  const handleClearSelected = (e) => {
    e.stopPropagation();
    props.onClearSelectedFilters(props.filterId);
  };

  const handleClearSearch = (e) => {
    e.stopPropagation();
    props.onClearSearchFilters(props.filterId);
  };

  const availableValues = props.values;
  const values = facetSearch
    ? availableValues.filter((c) => {
        const search = createSearchRegex(facetSearch + '*');
        return search.test(c);
      })
    : availableValues;

  // Derive checked/unchecked values from props
  const checkedValues = [...props.filter.selected].sort();
  const uncheckedValues = values.filter((value) => !props.filter.selected.includes(value)).sort();
  const searchValues = props.filter.search;

  const searchValuesHtml = searchValues.map((value) => (
    <MenuCheckbox
      key={value}
      label={value}
      checked
      onChange={(e) => handleRemoveSearch(e, value)}
    />
  ));
  const checkedValuesHtml = checkedValues.map((value) => (
    <MenuCheckbox
      key={value}
      label={value}
      onChange={(e) => handleSelectedChange(e, value)}
      checked
    />
  ));

  const virtualListHeight = Math.min(uncheckedValues.length * 24, 500);
  const uncheckedValuesHtml = (
    <AutoSizer disableHeight={true}>
      {({ width }) => (
        <FixedSizeList
          itemCount={uncheckedValues.length}
          overscanCount={3}
          itemData={{ values: uncheckedValues, handler: handleSelectedChange }}
          itemSize={24}
          height={virtualListHeight}
          width={width}
          style={{
            overflowX: 'hidden',
          }}
        >
          {UncheckedValueRow}
        </FixedSizeList>
      )}
    </AutoSizer>
  );

  const clearSelectedHtml = (
    <li className="facet_clear">
      <span className="facet-clear_link" onClick={(e) => handleClearSelected(e)}>
        ✕ Clear selected items
      </span>
    </li>
  );

  const clearSearch = (
    <li className="facet_clear">
      <span className="facet-clear_link" onClick={(e) => handleClearSearch(e)}>
        ✕ Clear searched items
      </span>
    </li>
  );

  const isActiveFilter = !isEmpty(props.filter.search) || !isEmpty(props.filter.selected);

  let togglerLabel = props.label;
  if (!isEmpty(props.filter.search)) {
    togglerLabel = togglerLabel + ': ' + props.filter.search.join(', ');
  }
  if (!isEmpty(props.filter.selected)) {
    togglerLabel = togglerLabel + ': ' + props.filter.selected.join(', ');
  }

  return (
    <div className={`facet ${isActiveFilter ? 'facet--active' : 'facet--inactive'}`}>
      <MenuToggler
        label={togglerLabel}
        labelClassName="btn btn--dropdown"
        closeOnClickOutside={true}
        closeOnEsc
        closeOnInteraction={false}
        active={isActiveFilter}
      >
        <MenuControl>
          <form onSubmit={(ev) => ev.preventDefault()}>
            <InputWithButton
              textFieldProps={{
                value: facetSearch,
                onChange: (ev) => setFacetSearch(ev.target.value),
                type: 'search',
                autoFocus: true,
              }}
              buttonProps={{
                text: 'Search',
                disabled: facetSearch === '',
                theme: 'primary',
                type: 'submit',
                onClick: (e) => handleSearchChange(e),
              }}
            />
          </form>
        </MenuControl>
        <MenuSeparator />
        {!isEmpty(searchValuesHtml) && clearSearch}
        {searchValuesHtml}
        {!isEmpty(searchValuesHtml) && <MenuSeparator />}
        {!isEmpty(checkedValuesHtml) && clearSelectedHtml}
        {checkedValues && !isEmpty(checkedValues) && checkedValuesHtml}
        {!isEmpty(checkedValuesHtml) && !isEmpty(uncheckedValuesHtml) && <MenuSeparator />}
        {uncheckedValues && !isEmpty(uncheckedValues) && uncheckedValuesHtml}
      </MenuToggler>
    </div>
  );
}

Facet.propTypes = {
  values: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    search: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  filterId: PropTypes.string.isRequired,
  onFacetSelectedChange: PropTypes.func.isRequired,
  onFacetSearchChange: PropTypes.func.isRequired,
  onClearSelectedFilters: PropTypes.func.isRequired,
  onClearSearchFilters: PropTypes.func.isRequired,
  onRemoveSearch: PropTypes.func.isRequired,
};

export default Facet;
