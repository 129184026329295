import React from 'react';

import { PermissionsEditor } from 'Common/PermissionsEditor';
import PipesParent from './PipesParent';

function PipesPermissions() {
  return (
    <PipesParent>
      <div className="scrollArea">
        <PermissionsEditor permission="pipes-collection" />
      </div>
    </PipesParent>
  );
}

export default PipesPermissions;
