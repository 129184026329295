import { PipeID, SystemID } from '../types/types';
import { Path } from './graph';

export function getAngle(idx: number, total: number) {
  const separationAngle = total > 10 ? 10 : 16;
  const baseAngle = -((total - 1) * separationAngle) / 2;

  const degrees = idx * separationAngle;
  return baseAngle + degrees + 0; // get rid of negative zero
}

export type FlowsMessageData = {
  flows: BaseFlow[];
};
export type FlowDirection = 'inbound' | 'internal' | 'outbound';

// an endpoint kind means a flow between a global and an endpoint
// a system kind means a flow between a global and a system
// a global kind means a flow between two globals
type FlowKind = 'endpoint' | 'system' | 'global';

export interface BaseFlow {
  from: PipeID;
  to: PipeID;
  direction: FlowDirection;
  direct: 'Yes' | 'No';
  _id: string;
  path: Path;
}

export interface GlobalFlow extends BaseFlow {
  kind: 'global';
}

// a flow with a pipe at one end that
// is connected to a system
export interface SystemFlow extends BaseFlow {
  kind: 'system';
  systemId: SystemID;
  system: string;
}

// A flow with a pipe at one end that
// is connected to an endpoint (http/csv...)
export interface EndpointFlow extends BaseFlow {
  kind: 'endpoint';
  endpointType: string;
}

export type Flow = SystemFlow | EndpointFlow | GlobalFlow;

export type LocalAggregatedFlows = {
  [id: string]: {
    kind: FlowKind;
    direction: LocalNodeDirection;
    systemsInGroup: string[];
    doesNotExist: boolean;
    flows: Flow[];
  };
};

export type OverviewNodeFlowInfo = {
  pipeName: string;
  link: string;
  path: Path;
  localDirection: FlowDirection;
};
type LocalNodeDirection = 'incoming' | 'outgoing' | 'both';

export interface LocalOverviewNode {
  name: string;
  direction: LocalNodeDirection;
  direct: boolean;
  flows: OverviewNodeFlowInfo[];
  kind: FlowKind;
  link: string;
  color: string;
  doesNotExist: boolean;
  pipesFromLink?: string;
  pipesToLink?: string;
}

export function getDirectionCounts(nodes: LocalOverviewNode[]) {
  return nodes.reduce(
    (acc, curr) => {
      const side = curr.direction === 'outgoing' ? 'right' : 'left';
      acc[side] = acc[side] + 1;
      return acc;
    },
    { left: 0, right: 0 }
  );
}
