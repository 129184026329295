import { createReducer } from '@reduxjs/toolkit';
import {
  pipeLoading,
  pipeLoaded,
  pipesLoadAllFinished,
  pipesLoadAllStarted,
  pipesLoadAllFailed,
  pipeLoadingFailed,
} from '../thunks/pipes';
import {
  systemLoadAllStarted,
  systemLoadAllFinished,
  systemLoadAllFailed,
} from '../thunks/systems';
import {
  datasetsLoadAllStarted,
  datasetsLoadAllFailed,
  datasetsLoadAllFinished,
} from '../thunks/datasets';
import { disconnected, subSwitched } from '../thunks/subscriptions';

type LoadStatusState = {
  pipeLoading: boolean;
  pipeLoadingFailed: boolean;
  pipesLoaded: boolean;
  systemsLoaded: boolean;
  datasetsLoaded: boolean;
  pipesLoading: boolean;
  datasetsLoading: boolean;
  systemsLoading: boolean;
};

const initialState: LoadStatusState = {
  pipeLoading: false,
  pipeLoadingFailed: false,
  pipesLoaded: false,
  systemsLoaded: false,
  datasetsLoaded: false,
  pipesLoading: false,
  datasetsLoading: false,
  systemsLoading: false,
};

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
export default createReducer(initialState, (builder) =>
  builder
    .addCase(pipeLoading, (state) => {
      state.pipeLoading = true;
    })
    .addCase(pipeLoaded, (state) => {
      state.pipeLoading = false;
    })
    .addCase(pipeLoadingFailed, (state) => {
      state.pipeLoading = false;
    })
    .addCase(pipesLoadAllFinished, (state) => {
      state.pipesLoaded = true;
      state.pipesLoading = false;
    })
    .addCase(pipesLoadAllStarted, (state) => {
      state.pipesLoading = true;
    })
    .addCase(pipesLoadAllFailed, (state) => {
      state.pipesLoading = false;
    })
    .addCase(systemLoadAllFinished, (state) => {
      state.systemsLoaded = true;
      state.systemsLoading = false;
    })
    .addCase(systemLoadAllStarted, (state) => {
      state.systemsLoading = true;
    })
    .addCase(systemLoadAllFailed, (state) => {
      state.datasetsLoading = false;
    })
    .addCase(datasetsLoadAllFinished, (state) => {
      state.datasetsLoaded = true;
      state.datasetsLoading = false;
    })
    .addCase(datasetsLoadAllStarted, (state) => {
      state.datasetsLoading = true;
    })
    .addCase(datasetsLoadAllFailed, (state) => {
      state.datasetsLoading = false;
    })
    .addCase(subSwitched, () => {
      return initialState;
    })
    .addCase(disconnected, () => {
      return initialState;
    })
);
