import PropTypes from 'prop-types';
import React from 'react';

import Box from 'Common/Box/Box';

import './LegendStyle.css';

const itemProps = {
  shape: PropTypes.oneOf(['line', 'circle', 'block']).isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

const LegendItem = ({ item }) => {
  return (
    <div className="legend-item">
      <div
        className={`legend-item__shape legend-item__shape--${item.shape}`}
        style={{ backgroundColor: item.color }}
      />
      <div className="legend-item__label">{item.label}</div>
    </div>
  );
};

LegendItem.propTypes = {
  item: PropTypes.shape(itemProps),
};

const Legend = (props) => {
  const { items } = props;

  return (
    <div className="legend">
      <Box>
        <Box.Header>
          <Box.Title>Legend</Box.Title>
        </Box.Header>
        <Box.Content padding white>
          <div className="legend__list">
            {items.map((item) => {
              return <LegendItem item={item} key={item.label} />;
            })}
          </div>
        </Box.Content>
      </Box>
    </div>
  );
};

Legend.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(itemProps)),
};

export default Legend;
