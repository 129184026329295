import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import values from 'lodash/values';
import moment from 'moment';
import ClipboardJS from 'clipboard';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { Typography, withTheme } from '@material-ui/core';
import { unixTimestampToDate } from 'Internals/datetime';

import JwtActions from 'Redux/thunks/jwt';
import Button from 'Common/Button/Button';
import { toastAdded } from 'Redux/thunks/global';
import SesamModal from 'Common/SesamModal/SesamModal';
import DangerText from 'Common/Text/DangerText';
import ExpansionPanel from '../../../components/expansion-panel';
import AddJwtForm from '../../../components/add-jwt';
import PromiseButton from '../../../components/promise-button';
import KeyValue from '../../../components/key-value';
import CodeViewer from '../../../components/code-viewer';

import './style.css';

const isExpired = (expiryDate) => {
  const now = new Date();
  if (expiryDate < now) return 'expired';
  const future = now.setDate(now.getDate() + 14);
  if (expiryDate < future) return 'expiring';
  return false;
};

function JwtItem({ jwt, onDelete, theme }) {
  const expiryDate = unixTimestampToDate(jwt.payload.exp);
  const expiryFormatted = moment(expiryDate).format('MMMM Do YYYY');
  const expiryStatus = isExpired(expiryDate);
  let color;
  if (expiryStatus === 'expired') color = theme.palette.text.danger;
  if (expiryStatus === 'expiring') color = theme.palette.warning.yellow;
  const createdBy = get(jwt, 'audit.created_by.name');
  const createdByEmail = get(jwt, 'audit.created_by.email');
  const lastModifiedBy = get(jwt, 'audit.last_modified_by.name');
  const lastModifiedByEmail = get(jwt, 'audit.last_modified_by.email');
  const details = {
    Description: jwt.description || '(none)',
    Roles: values(jwt.payload.principals).join(', '),
    Expiry: <Typography style={{ color }}>{expiryFormatted}</Typography>,
    Id: jwt.id,
  };
  if (jwt.client_id) details['Client Id'] = jwt.client_id;
  if (jwt.refresh_interval) details['Refresh interval'] = jwt.refresh_interval;
  if (jwt.refresh_interval) details['Refresh interval'] = jwt.refresh_interval;
  if (createdBy && createdByEmail) details['Created by'] = `${createdBy} (${createdByEmail})`;
  if (lastModifiedBy && lastModifiedByEmail)
    details['Last modified by'] = `${lastModifiedBy} (${lastModifiedByEmail})`;

  let tooltip = `Id: ${jwt.id}`;
  if (expiryStatus === 'expired') {
    tooltip += ' (expired)';
  } else if (expiryStatus === 'expiring') {
    tooltip += ' (expiring soon)';
  }
  return (
    <ExpansionPanel title={jwt.name} tooltip={tooltip} warning={expiryStatus}>
      <KeyValue list={details} />
      <div className="toolbar toolbar--right">
        <PromiseButton onClick={() => onDelete(jwt)} pending="Deleting…" theme="danger">
          Delete…
        </PromiseButton>
      </div>
    </ExpansionPanel>
  );
}

JwtItem.propTypes = {
  jwt: PropTypes.shape(jwtProps).isRequired,
  onDelete: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

const style = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function NewJwtModal({ isOpen, jwtString, clientSecret, onClose }) {
  return (
    <SesamModal isOpen={isOpen} onClose={onClose} style={style}>
      <div className="jwt-modal">
        <h2 className="heading-component">New JWT created</h2>
        <p style={{ marginTop: 0 }}>This is the string of the newly created token:</p>
        <CodeViewer code={jwtString} forceWrap style={{ maxWidth: '20rem' }} />
        <div className="jwt-modal__copy-box">
          <DangerText className="jwt-modal__copy-text">
            This is the only time this string is provided. <b>Make sure you save it.</b>
          </DangerText>
          <Button
            text="Copy to clipboard"
            data-clipboard-text={jwtString}
            className="jwt-modal__copy-button"
          />
        </div>
        {clientSecret && (
          <React.Fragment>
            <p style={{ marginTop: 0 }}>This is the client secret:</p>
            <CodeViewer code={clientSecret} forceWrap style={{ maxWidth: '20rem' }} />
            <div className="jwt-modal__copy-box">
              <DangerText className="jwt-modal__copy-text">
                This is the only time this secret is provided. <b>Make sure you save it.</b>
              </DangerText>
              <Button
                text="Copy to clipboard"
                data-clipboard-text={clientSecret}
                className="jwt-modal__copy-button"
              />
            </div>
          </React.Fragment>
        )}
        <hr style={{ width: '100%' }} />
        <Button
          text="Dismiss"
          onClick={onClose}
          style={{ alignSelf: 'flex-end', marginTop: '1rem' }}
        />
      </div>
    </SesamModal>
  );
}

NewJwtModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  jwtString: PropTypes.string.isRequired,
  clientSecret: PropTypes.string,
  onClose: PropTypes.func,
};

NewJwtModal.defaultProps = {
  jwt_string: "JWT string doesn't exist",
  clientSecret: '',
  onClose: () => {
    return;
  },
};

class SettingsSubscriptionJWT extends React.Component {
  constructor(props) {
    super(props);

    this.handleAdd = (jwt) => {
      return this.props.addJwt(this.props.subId, jwt).then(({ jwtString, clientSecret }) => {
        return this.openModal(jwtString, clientSecret);
      });
    };

    this.openModal = (jwtString, clientSecret) => {
      this.setState({
        modalOpened: true,
        tempJwtString: jwtString,
        tempClientSecret: clientSecret,
      });
    };

    this.closeModal = () => {
      if (confirm('Are you sure you want to continue?')) {
        this.setState({ modalOpened: false });
      }
    };

    this.handleDelete = (jwt) => {
      if (confirm(`Are you sure you want to delete the "${jwt.name}" token?`)) {
        return this.props.deleteJwt(this.props.subId, jwt.id);
      }
      return Promise.reject();
    };

    this.props.loadAllJwts(this.props.subId);

    this.clipboard = new ClipboardJS('.jwt-modal__copy-button');
    this.clipboard.on('success', () => {
      this.props.addToast('Copied to clipboard');
    });

    this.sortJWTs = () => {
      const jwts = cloneDeep(this.props.allJwts);
      return jwts.sort((a, b) => {
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        if (x > y) return 1;
        else if (x < y) return -1;
        return 0;
      });
    };

    this.state = {
      tempJwtString: '',
      tempClientSecret: '',
      modalOpened: false,
    };
  }

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  render() {
    return (
      <main className="scrollArea">
        <h1 className="heading-page">JSON Web Tokens</h1>
        <div className="row" id="jwt-row">
          <div className="col gr-secondary">
            <AddJwtForm handleAdd={this.handleAdd} />
          </div>
          <div className="col gr-primary">
            <h2 className="heading-section">Existing JWTs</h2>
            {this.props.allJwts.length > 0 && (
              <ul className="expansion-panel-list">
                {this.sortJWTs().map((jwt) => (
                  <li key={jwt.id}>
                    <JwtItem jwt={jwt} onDelete={this.handleDelete} theme={this.props.theme} />
                  </li>
                ))}
              </ul>
            )}
            {this.props.allJwts.length === 0 && (
              <p>There are no JWTs associated with this subscription.</p>
            )}
          </div>
        </div>
        <NewJwtModal
          isOpen={this.state.modalOpened}
          onClose={this.closeModal}
          jwtString={this.state.tempJwtString}
          clientSecret={this.state.tempClientSecret}
        />
      </main>
    );
  }
}

const jwtProps = {
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  payload: PropTypes.shape({
    exp: PropTypes.number.isRequired,
  }),
  audit: PropTypes.shape({
    created_by: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }),
    last_modified_by: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
};

SettingsSubscriptionJWT.propTypes = {
  addJwt: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  allJwts: PropTypes.arrayOf(PropTypes.shape(jwtProps)),
  deleteJwt: PropTypes.func.isRequired,
  loadAllJwts: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    allJwts: state.jwt,
    subId: state.subscription.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addJwt: (subId, jwt) => dispatch(JwtActions.add(subId, jwt)),
    deleteJwt: (subId, jwtId) => dispatch(JwtActions.delete(subId, jwtId)),
    loadAllJwts: (subId) => dispatch(JwtActions.loadAll(subId)),
    addToast: (message) => dispatch(toastAdded({ message, type: 'success' })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(SettingsSubscriptionJWT));
