import { SomeObject } from '../../types/types';
import { createReducer } from '@reduxjs/toolkit';
import {
  apologyDismissed,
  versionLoaded,
  statusLoaded,
  apologise,
  Apology,
} from '../thunks/apology';
import { layoutChanged, commandPaletteToggled } from '../thunks/global';
import { paymentMethodsLoaded } from '../thunks/payment-methods';

type GlobalsState = {
  apologies: Apology[];
  baseUrl: string;
  commandPaletteShown: 'datahubObjects' | undefined;
  layoutUpdates: number;
  paymentMethodsLoaded: boolean;
  portalUrl: string;
  status: SomeObject;
  version: string | null;
};

const initialState: GlobalsState = {
  apologies: [],
  baseUrl: window.sesamBasename ? window.sesamBasename : '/',
  commandPaletteShown: undefined,
  layoutUpdates: 0,
  paymentMethodsLoaded: false,
  portalUrl: '/api',
  status: {},
  version: null,
};

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(apologyDismissed, (state, action) => {
      state.apologies = state.apologies.filter((a) => a.id !== action.payload);
    })
    .addCase(versionLoaded, (state, action) => {
      state.version = action.payload;
    })
    .addCase(statusLoaded, (state, action) => {
      state.status = action.payload;
    })
    .addCase(layoutChanged, (state, action) => {
      state.layoutUpdates += 1;
    })
    .addCase(apologise, (state, action) => {
      state.apologies.push(action.payload);
    })
    .addCase(paymentMethodsLoaded, (state, action) => {
      state.paymentMethodsLoaded = true;
    })
    .addCase(commandPaletteToggled, (state, action) => {
      if (state.commandPaletteShown === action.payload) {
        state.commandPaletteShown = undefined;
      } else {
        state.commandPaletteShown = action.payload;
      }
    })
);

export default reducer;
