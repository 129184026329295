import React from 'react';
import { push } from 'react-router-redux';
import { useSelector, useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';

import { toastAdded } from 'Redux/thunks/global';
import PaymentMethodActions from 'Redux/thunks/payment-methods';
import Feedback from '../../../components/feedback';
import Editor from './editor';
import { RootState } from '../../../';
import { NewPaymentMethod } from 'Api/payment-methods';
import useStripePromise from 'Hooks/useStripePromise';

const defaultPm = {
  name: '',
  description: '',
  type: 'invoice',
  billing_info: {
    address1: '',
    address2: '',
    address3: '',
    name: '',
    orgnr: '',
    phone: '',
  },
};

const Create = () => {
  const emailVerified = useSelector((state: RootState) => state.user.emailVerified);

  const dispatch = useDispatch();
  const create = (pm: NewPaymentMethod) =>
    dispatch(PaymentMethodActions.create(pm))
      .then(dispatch(toastAdded({ message: 'Payment method added!', type: 'success' })))
      .then(() => dispatch(push('/user/payment-methods')));
  const showError = (msg: string | null) =>
    dispatch(toastAdded({ message: msg ? msg : 'Payment method rejected', type: 'warning' }));

  const stripePromise = useStripePromise();

  return (
    <main className="scrollArea">
      <h2 className="heading-section">Create new payment method</h2>
      {!emailVerified && (
        <div>
          <Feedback type="warning">
            In order to create a payment method, your email must be verified.
            <br />
            We have sent a verification email to your email address. <br />
            To resend the verification email, go to your user profile.
          </Feedback>
        </div>
      )}
      <div className="row">
        <div className="col gr-primary">
          <Elements stripe={stripePromise}>
            <Editor
              value={defaultPm}
              buttonText="Add"
              onSave={create}
              update={false}
              showError={showError}
            />
          </Elements>
        </div>
      </div>
    </main>
  );
};

export default Create;
