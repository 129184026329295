import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Button from 'Common/Button/Button';
import { toastAdded } from 'Redux/thunks/global';
import ButtonGroup from 'Common/ButtonGroup/ButtonGroup';

import useClipboard from '../../hooks/useClipboard';
import ResetIcon from '../../images/icons/reset.svg';
import SaveIcon from '../../images/icons/save.svg';
import ShareIcon from '../../images/icons/share.svg';
import TerminalIcon from '../../images/icons/terminal.svg';

import Facet from './Facet';
import FacetSelector from './FacetSelector';
import FilterSelector from './FilterSelector';
import './FacetListStyle.css';

function FacetList(props) {
  useClipboard('.facet-list--share-button', () => {
    props.addToast('URL copied to clipboard');
  });
  useClipboard('.facet-list--curl-button', () => {
    props.addToast('URL copied to clipboard');
  });

  const availableValuesMap = useMemo(() => {
    let value = undefined;
    const valueMap = {};
    for (const key of props.visibleFacets) {
      valueMap[key] = {};
    }
    for (let i = 0, n = props.itemsToFilter.length; i < n; ++i) {
      for (const key of props.visibleFacets) {
        value = get(props.itemsToFilter[i], key);
        if (Array.isArray(value)) {
          for (const val of value) {
            if (typeof val === 'object') {
              valueMap[key][val.name] = true;
            } else {
              valueMap[key][val] = true;
            }
          }
        } else {
          if (value) {
            valueMap[key][value] = true;
          }
        }
      }
    }
    for (const key of Object.keys(valueMap)) {
      valueMap[key] = Object.keys(valueMap[key]);
    }
    return valueMap;
  }, [props.itemsToFilter, props.visibleFacets]);

  // Remove visible facets that are invalid
  // This might happen when the locally stored filter keys
  // don't match with the stored facets
  const validVisibleFacets = props.visibleFacets.filter((f) => props.filter[f]);

  return (
    <nav className="facet-list">
      {validVisibleFacets.map((key) => (
        <Facet
          key={props.filterLabels[key]}
          values={availableValuesMap[key]}
          filter={props.filter[key]}
          label={props.filterLabels[key]}
          filterId={key}
          onFacetSelectedChange={props.onFacetSelectedChange}
          onFacetSearchChange={props.onFacetSearchChange}
          onClearSelectedFilters={props.onClearSelectedFilters}
          onClearSearchFilters={props.onClearSearchFilters}
          onRemoveSearch={props.onRemoveSearch}
        />
      ))}
      <div className="facet-list__facet-selector">
        {props.visibleFacets && (
          <FacetSelector
            filter={props.filter}
            filterLabels={props.filterLabels}
            onToggleFacetVisibility={props.onToggleFacetVisibility}
            visibleFacets={props.visibleFacets}
          />
        )}
      </div>
      <div className="facet-list__separator" />
      <ButtonGroup>
        <Button
          className="facet-list--reset-button"
          icon={<ResetIcon />}
          onClick={props.onResetFilters}
          text="Reset"
        />
        <Button
          className="facet-list--share-button"
          data-clipboard-text={window.location.href}
          icon={<ShareIcon />}
          style={{ marginLeft: '0.5rem' }}
        >
          Share
        </Button>
        <Button
          className="facet-list--save-button"
          data-clipboard-text={window.location.href}
          icon={<SaveIcon />}
          style={{ marginLeft: '0.5rem' }}
          onClick={() => {
            const name = prompt('Give your saved filter a name');
            if (name === null) {
              return;
            } else if (!name) {
              alert('You must give your saved filter a name');
            } else {
              props.onSaveFilter(name);
            }
          }}
        >
          Save…
        </Button>
        {props.curl && (
          <Button
            className="facet-list--curl-button"
            data-clipboard-text={props.curl}
            icon={<TerminalIcon />}
            style={{ marginLeft: '0.5rem' }}
          >
            CURL
          </Button>
        )}
      </ButtonGroup>
      <div className="facet-list__filter-selector">
        <FilterSelector
          filters={props.savedFilters}
          onFilterClick={props.onSavedFilterClick}
          onFilterDeleteClick={props.onSavedFilterDeleteClick}
        />
      </div>
    </nav>
  );
}

FacetList.propTypes = {
  addToast: PropTypes.func.isRequired,
  filter: PropTypes.shape({}).isRequired,
  filterLabels: PropTypes.shape({}).isRequired,
  itemsToFilter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onFacetSelectedChange: PropTypes.func.isRequired,
  onFacetSearchChange: PropTypes.func.isRequired,
  onClearSelectedFilters: PropTypes.func.isRequired,
  onClearSearchFilters: PropTypes.func.isRequired,
  onSavedFilterClick: PropTypes.func.isRequired,
  onSavedFilterDeleteClick: PropTypes.func.isRequired,
  onRemoveSearch: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onSaveFilter: PropTypes.func.isRequired,
  onToggleFacetVisibility: PropTypes.func,
  savedFilters: PropTypes.shape({}).isRequired,
  visibleFacets: PropTypes.arrayOf(PropTypes.string),
  curl: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  addToast: (message) => dispatch(toastAdded({ message, type: 'success' })),
});

export default connect(null, mapDispatchToProps)(FacetList);
