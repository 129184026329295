import { createReducer } from '@reduxjs/toolkit';
import { disconnected } from '../thunks/subscriptions';
import { secretAdded, secretLoadedAll, secretRemoved } from '../thunks/secrets';

type SecretsState = string[];

const initialState: SecretsState = [];

export default createReducer(initialState, (builder) =>
  builder
    .addCase(secretAdded, (state, action) => {
      state.push(action.payload.name);
    })

    .addCase(secretRemoved, (state, action) => {
      state.splice(state.indexOf(action.payload), 1);
    })

    .addCase(secretLoadedAll, (state, action) => {
      return action.payload; // Just return the full new state
    })

    .addCase(disconnected, () => initialState)
);
