import React, { ReactNode } from 'react';
import SesamLink from 'Common/Links/SesamLink';

import './PipeLastRunStatus.css';

const stripErrorPrefixRegEx = new RegExp(/\n([\s\S]*)/);
const stripCompletedPrefixRegEx = new RegExp(/Completed OK ([\s\S]*)/);

export const PipeLastRunStatus: React.FC<{
  shortMessage: ReactNode | string;
  longMessage: string;
  icon: ReactNode | null;
  hasNoExecutionDataset: boolean;
  subId: string;
  id: string;
  useLink?: boolean;
}> = ({ shortMessage, longMessage, icon, hasNoExecutionDataset, subId, id, useLink = true }) => (
  <div className="last-run-container">
    {useLink && !hasNoExecutionDataset ? (
      <SesamLink to={`subscription/${subId}/pipes/pipe/${id}/execution`}>
        <span className="last-run" title={longMessage}>
          {icon}
          {shortMessage}
        </span>
      </SesamLink>
    ) : (
      <span className="last-run" title={longMessage}>
        {icon}
        {shortMessage}
      </span>
    )}
  </div>
);
