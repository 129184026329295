import React from 'react';
import { FormikProps } from 'formik';

import RecipientsEditor from '../../recipients-editor/RecipientsEditor';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import { Recipient } from '../../recipients-editor/RecipientsEditor';
import { RoleInfo, SubscriptionMemberInfo } from '../../../types/types';

interface FormValues {
  description: string;
  name: string;
  recipients: Recipient[];
}

interface ValueTooHighFormProps {
  availableRoles: RoleInfo[];
  members: SubscriptionMemberInfo[];
}

const parameterData = [
  { name: 'Entities processed', id: 'entities_last_run' },
  { name: 'Changed entities', id: 'changes_last_run' },
  { name: 'Failed retries', id: 'number_of_failed_retries_last_run' },
  { name: 'Dead entities', id: 'number_of_dead_entities_last_run' },
  { name: 'Failed retried entities', id: 'retry_errors_last_run' },
  { name: 'Successfully retried entities', id: 'retries_succeeded_last_run' },
  { name: 'Read errors', id: 'read_errors_last_run' },
  { name: 'Sink time', id: 'sink_time' },
  { name: 'Source time', id: 'source_time' },
  { name: 'Transform time', id: 'transform_time' },
  { name: 'Total time', id: 'total_time' },
  { name: 'Write errors', id: 'write_errors_last_run' },
];

const parameterDescriptions = {
  changes_last_run:
    'This is the number or entities that was actually changed. When writing an entity to a dataset, the pipe will compare the new entity to the version of entity that is already in the dataset (if any). If the entity is already in the dataset and is identical to the new entity, the new entity is skipped.',
  entities_last_run: "This is the total number of entities that was read from the pipe's source.",
  number_of_dead_entities_last_run:
    ' This is the number of entities that the pipe unsuccessfully retried writing to the sink, and where the pipe has retried so many times that the pipe has permanently given up on the entities.',
  number_of_failed_retries_last_run:
    'This is the number of entities that was unsuccessfully retried. The pipe will retry these entities again the next time the pipe runs.',
  read_errors_last_run:
    'This is the number of time the pipe failed to read entities from the source.',
  retries_succeeded_last_run:
    'This is the number of entities that was retried, and where the retry attempt succeeded.',
  retry_errors_last_run:
    'This is the total number of failed retry attempts. This number is the sum of the "Failed retries" and "Dead entities" properties. ',
  sink_time: 'This is the number of seconds the pipe spent writing to the sink.',
  source_time: 'This is the number of seconds the pipe spent reading from the source.',
  total_time: 'This is the total number of seconds the pipe spent.',
  transform_time:
    'This is the number of seconds the pipe spent doing DTL and other types for transforms.',
  write_errors_last_run:
    'This is the number of times the pipe failed to write entities to the sink.',
};

const ValueTooHighForm: React.FC<ValueTooHighFormProps & FormikProps<FormValues>> = ({
  availableRoles,
  handleChange,
  handleBlur,
  errors,
  members,
  setValues,
  values,
}) => {
  return (
    <React.Fragment>
      <SesamTextField
        autoFocus
        error={errors.extra_rule_info && errors.extra_rule_info.parameter}
        helperText={
          (errors.extra_rule_info && errors.extra_rule_info.parameter) ||
          (values.extra_rule_info &&
            values.extra_rule_info.parameter &&
            parameterDescriptions[values.extra_rule_info.parameter]) ||
          'The name of the parameter to check.'
        }
        label="Parameter"
        margin="normal"
        name="extra_rule_info.parameter"
        onBlur={handleBlur}
        onChange={handleChange}
        select
        value={values.extra_rule_info.parameter || ''}
      >
        {parameterData.map((data) => {
          return (
            <SesamMenuItem key={data.id} value={data.id}>
              {data.name}
            </SesamMenuItem>
          );
        })}
      </SesamTextField>
      <SesamTextField
        error={errors.extra_rule_info && errors.extra_rule_info.limit}
        helperText={
          (errors.extra_rule_info && errors.extra_rule_info.limit) ||
          'The maximum value of the parameter.'
        }
        label="Limit"
        margin="normal"
        name="extra_rule_info.limit"
        onBlur={handleBlur}
        onChange={handleChange}
        type="number"
        value={values.extra_rule_info.limit || ''}
      />
      <SesamTextField
        helperText="A human readable name for this rule"
        label="Name"
        margin="normal"
        name="name"
        onChange={handleChange}
        value={values.name}
      />
      <SesamTextField
        helperText="A description of the business logic behind this rule"
        label="Description"
        margin="normal"
        multiline
        name="description"
        onChange={handleChange}
        rows={4}
        value={values.description}
      />
      <RecipientsEditor
        availableRoles={availableRoles}
        members={members}
        onChange={(recipients) => {
          setValues({ ...values, recipients });
        }}
        recipients={values.recipients}
        style={{ marginTop: '30px' }}
        TextFieldProps={{
          helperText:
            (errors.recipients && errors.recipients[0] && errors.recipients[0].methods) || ' ',
          margin: 'normal',
          error: Boolean(errors.recipients && errors.recipients[0] && errors.recipients[0].methods),
        }}
      />
    </React.Fragment>
  );
};

export default ValueTooHighForm;
