import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Elements } from '@stripe/react-stripe-js';

import { LoadingPanel } from 'Common/LoadingPanel';
import PaymentMethodActions from 'Redux/thunks/payment-methods';
import { toastAdded } from 'Redux/thunks/global';
import useStripePromise from 'Hooks/useStripePromise';
import Editor from './editor';
import './edit.css';

function EditPaymentMethod(props) {
  function update(pm) {
    pm.id = props.pm.id;
    return props.update(pm);
  }

  useEffect(() => {
    if (!props.loaded) {
      props.reload();
    }
  }, [props.loaded]);

  const stripePromise = useStripePromise();

  if (!props.loaded) {
    return <LoadingPanel />;
  }
  if (!props.pm) {
    return <p>Not found</p>;
  }
  const pm = props.pm;
  let otherOwnerInfo = null;
  if (pm.owner && pm.owner.user_id && pm.owner.user_id !== props.currentUserId) {
    otherOwnerInfo = `NOTE: This payment method is owned by the user '${pm.owner.email}')`;
  }
  return (
    <main className="scrollArea">
      <h2 className="heading-section">Edit payment method</h2>
      <div className="row">
        <div className="col gr-primary">
          {stripePromise && (
            <Elements stripe={stripePromise}>
              <Editor
                value={props.pm}
                buttonText="Update"
                onSave={update}
                type={props.pm.type}
                update
              />
            </Elements>
          )}
        </div>
        <div className="col gr-secondary">
          {otherOwnerInfo && <p className="other-owner--warning">{otherOwnerInfo}</p>}
          <p>
            You can edit a payment method as long as it is not used to pay for a subscription yet.
          </p>
          <p>The payment method might need to be verified again.</p>
        </div>
      </div>
    </main>
  );
}

EditPaymentMethod.propTypes = {
  reload: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  showError: PropTypes.func,
  pm: PropTypes.object,
  loaded: PropTypes.bool.isRequired,
  currentUserId: PropTypes.string,  
};

function mapStateToProps(state, ownProps) {
  return {
    pm: state.paymentMethods.find((pm) => pm.id === ownProps.params.pmID),
    loaded: state.globals.paymentMethodsLoaded,
    currentUserId: state.user.user_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reload: () => dispatch(PaymentMethodActions.loadAll()),
    update: (pm) =>
      dispatch(PaymentMethodActions.update(pm))
        .then(dispatch(toastAdded({ message: 'Payment method updated', type: 'success' })))
        .then(dispatch(push('/user/payment-methods'))),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentMethod);
