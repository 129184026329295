import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import find from 'lodash/find';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';
import validationSchema from './forms/validationSchema';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import Button from 'Common/Button/Button';
import PipeNotificationActions from 'Redux/thunks/pipe-notifications';
import renderRuleForm from './forms/renderRuleForm';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import { Form, FormActions } from 'Common/forms';
import { PipeNotificationRuleTypeInfo, NewPipeNotificationRule } from '../../types/notifications';
import { toastAdded } from 'Redux/thunks/global';

interface NewPipeNotificationRuleFormProps {
  availableRuleTypes: PipeNotificationRuleTypeInfo[];
  pipeId: string;
  subId: string;
}

const NewPipeNotificationRuleForm: React.FC<NewPipeNotificationRuleFormProps> = (props) => {
  const { availableRuleTypes, pipeId, subId } = props;

  const [isDefaultDialogOpen, setIsDefaultDialogOpen] = useState<boolean>(false);
  const [isAddingDefaults, setIsAddingDefaults] = useState<boolean>(false);

  const dispatch = useDispatch();
  const addNotificationRule = (rule: NewPipeNotificationRule) =>
    dispatch(PipeNotificationActions.add(subId, pipeId, rule));
  const addToast = (message: string) => dispatch(toastAdded({ type: 'success', message }));

  function addDefaults() {
    setIsAddingDefaults(true);

    const pumpFailedDefault = {
      description: 'This rule was created as a default.',
      extra_rule_info: {},
      name: 'Default - Pump failed',
      recipients: [{ id: 'group:Admin', methods: ['portal', 'email'], type: 'role' }],
      type: 'pump_failed',
    };

    const pumpFinishedOverdueDefault = {
      description: 'This rule was created as a default.',
      extra_rule_info: { limit: 86400 },
      name: 'Default - Pump running for 24 hours',
      recipients: [{ id: 'group:Admin', methods: ['portal', 'email'], type: 'role' }],
      type: 'pump_finished_overdue',
    };

    Promise.all([
      addNotificationRule(pumpFailedDefault),
      addNotificationRule(pumpFinishedOverdueDefault),
    ]).then(() => {
      setIsDefaultDialogOpen(false);
      setIsAddingDefaults(false);
      addToast('Default notification rules added!');
    });
  }

  return (
    <div>
      <Formik
        initialValues={{
          type: '',
          name: '',
          description: '',
          extra_rule_info: {
            limit: null,
            extra_grace_period: null,
            interval: null,
            parameter: null,
            event_type: null,
            pattern: null,
          },
          recipients: [],
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          /**
           * Becayse Yup's .when() won't work with Formik, we have to specify
           * conditional validation here.
           */
          const errors = {};
          if (
            values.type === 'pump_completed_value_too_high_over_time' ||
            values.type === 'pump_completed_value_too_low_over_time' ||
            values.type === 'pump_completed_value_too_high' ||
            values.type === 'pump_completed_value_too_low'
          ) {
            if (!values.extra_rule_info.parameter) {
              set(errors, ['extra_rule_info', 'parameter'], 'This field is required');
            }
            if (!values.extra_rule_info.limit) {
              set(errors, ['extra_rule_info', 'limit'], 'This field is required');
            }
          }
          if (
            values.type === 'pump_completed_value_too_high_over_time' ||
            values.type === 'pump_completed_value_too_low_over_time'
          ) {
            if (!values.extra_rule_info.interval) {
              set(errors, ['extra_rule_info', 'interval'], 'This field is required');
            }
          }
          if (values.type === 'pattern_match') {
            if (!values.extra_rule_info.event_type) {
              set(errors, ['extra_rule_info', 'event_type'], 'This field is required');
            }
            if (!values.extra_rule_info.parameter) {
              set(errors, ['extra_rule_info', 'parameter'], 'This field is required');
            }
            if (!values.extra_rule_info.pattern) {
              set(errors, ['extra_rule_info', 'pattern'], 'This field is required');
            }
          }

          return errors;
        }}
        onSubmit={(rule: NewPipeNotificationRule, bag) => {
          const extraRuleInfoWithouNullValues = omitBy(rule.extra_rule_info, isNull);
          rule.extra_rule_info = extraRuleInfoWithouNullValues;
          return addNotificationRule(rule).then((addedRule: NewPipeNotificationRule) => {
            addToast('Notification rule added!');
            bag.resetForm();
          });
        }}
      >
        {(props) => {
          const currentRuleType = find(availableRuleTypes, (rt) => rt.type === props.values.type);
          return (
            <div>
              <Form margin={false} useFormik>
                <Autocomplete
                  value={currentRuleType || ''}
                  options={availableRuleTypes}
                  getOptionLabel={(ruleType) => ruleType.name || ''}
                  getOptionSelected={(ruleType, name) => {
                    return ruleType.type === name;
                  }}
                  renderInput={(params) => (
                    <SesamTextField
                      {...params}
                      label="Rule type"
                      margin="normal"
                      helperText={
                        (currentRuleType && currentRuleType.description) ||
                        'Required - You must select a rule type'
                      }
                    />
                  )}
                  onChange={(ev: React.ChangeEvent, ruleType) => {
                    if (ruleType !== null) {
                      props.setValues({
                        ...props.values,
                        type: ruleType.type,
                      });
                    }
                  }}
                />
                {props.values.type && renderRuleForm(props.values.type, props)}
                <FormActions>
                  <Button
                    type="button"
                    onClick={() => {
                      setIsDefaultDialogOpen(true);
                    }}
                  >
                    Add defaults…
                  </Button>
                  <Button
                    type="button"
                    disabled={!props.values.type}
                    onClick={() => {
                      props.resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    theme="primary"
                    loading={props.isSubmitting}
                    loadingText="Adding"
                    disabled={!props.dirty || !props.isValid || props.isSubmitting}
                  >
                    Add
                  </Button>
                </FormActions>
              </Form>
            </div>
          );
        }}
      </Formik>
      <Dialog open={isDefaultDialogOpen}>
        <DialogTitle>Add default rules</DialogTitle>
        <DialogContent>
          You are about to add the following default rules:
          <ul>
            <li>Pump failed</li>
            <li>Pump finished overdue (24 hours)</li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDefaultDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            theme="primary"
            onClick={() => {
              addDefaults();
            }}
            loading={isAddingDefaults}
            loadingText="Adding"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewPipeNotificationRuleForm;
