import React from 'react';
import { ColumnInstance } from 'react-table';
import MenuToggler from '../menu-toggler';
import ColumnsIcon from '../../images/icons/columns.svg';
import { MenuAction, MenuCheckbox, MenuSeparator } from '../menu';

const anchorPoint = {
  anchor: { hPos: 'right', vPos: 'bottom' },
  popover: { hPos: 'right', vPos: 'top' },
};

type TableColSelectorProps = {
  cols: ColumnInstance<any>[];
  resetHiddenColumns: () => void;
};

const defaults = {
  cols: [],
  resetHiddenColumns: () => {},
};

const TableColSelector: React.FC<TableColSelectorProps> = (props = defaults) => {
  return (
    <div className="col-selector">
      <MenuToggler
        anchorPoint={anchorPoint}
        closeOnInteraction={false}
        label={<ColumnsIcon />}
        labelClassName="btn btn--dropdown btn--small btn--icon"
      >
        {props.cols.map((col, i) => (
          <MenuCheckbox
            key={i}
            label={col.Header}
            readOnly={col.fixed}
            {...col.getToggleHiddenProps()}
          />
        ))}
        <MenuSeparator />
        <MenuAction label="Reset to default" onClick={() => props.resetHiddenColumns()} />
      </MenuToggler>
    </div>
  );
};

export default TableColSelector;
