import React from 'react';
import AuthThunks from 'Redux/thunks/auth';
import CenteredLayout from '../layout/centered';

import Button from 'Common/Button/Button';

function Logout() {
  const dispatch = useDispatch();
  function handleLogout() {
    dispatch(AuthThunks.logout());
  }
  return (
    <CenteredLayout>
      <div className="logout">
        <h2>Logout?</h2>
        <Button onClick={handleLogout}>Logout</Button>
      </div>
    </CenteredLayout>
  );
}

export default Logout;
