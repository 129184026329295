import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import PropTypes from 'prop-types';
import React from 'react';

import Page from '../page';
import PageHeader, { PageHeaderTitle } from '../page-header';
import TabbedNav from '../tabbed-nav';

const EntityTypePropertyPage = (props) => {
  const {
    children,
    subId,
    params: { propertyId },
  } = props;

  return (
    <Page dataSelenium="subscription-browse-pageview">
      <PageHeader>
        <PageHeaderTitle>
          <SesamLink to={`subscription/${subId}/browse`}>Browse</SesamLink>
        </PageHeaderTitle>
        <PageHeaderTitle>{`Properties`}</PageHeaderTitle>
        <PageHeaderTitle>{propertyId}</PageHeaderTitle>
      </PageHeader>
      <TabbedNav
        nav={[
          {
            label: 'Overview',
            to: `/subscription/${subId}/browse/properties/${encodeURIComponent(
              propertyId
            )}/overview`,
          },
        ]}
      >
        {children}
      </TabbedNav>
    </Page>
  );
};

EntityTypePropertyPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  params: PropTypes.shape({
    entityTypeId: PropTypes.string,
    modelId: PropTypes.string,
    propertyId: PropTypes.string,
  }),
  subId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  subId: state.subscription.id,
});

export default connect(mapStateToProps)(EntityTypePropertyPage);