import { makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyle = makeStyles((theme) => {
  return {
    link: {
      color: theme.palette.text.brand,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.text.brandActive,
      },
    },
  };
});

function ExternalLink({ className, ...props }) {
  const classes = useStyle();
  let fullClassName = classes.link;
  if (className) {
    fullClassName = fullClassName + ' ' + className;
  }
  return <a className={fullClassName} {...props} />;
}

ExternalLink.propTypes = {
  className: PropTypes.string,
};

export default ExternalLink;
