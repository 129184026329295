import { createTheme } from '@material-ui/core/styles';
import colors from './colors';
import commonColors from './commonColors';

// TODO: Standardize the themes and migrate them to Typescript

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.brand,
      active: colors.brandActive,
    },
    secondary: {
      main: colors.light.danger,
    },
    warning: {
      main: colors.light.warning,
      yellow: commonColors.amber.amberDark,
    },
    overlay: colors.light.overlay,
    inverted: colors.light.inverted,
    background: colors.light.background,
    text: {
      brand: colors.light.text.brand,
      brandActive: colors.light.text.brandActive,
      danger: colors.light.danger,
    },
    divider: colors.light.divider,
  },
  fonts: {
    headings: 'Barlow, sans-serif',
  },
});

// Secondary dark theme
export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: colors.brand,
      active: colors.brandActive,
    },
    secondary: {
      main: colors.dark.danger,
    },
    warning: {
      main: colors.dark.warning,
      yellow: commonColors.amber.amberDark,
    },
    overlay: colors.dark.overlay,
    inverted: colors.dark.inverted,
    background: colors.dark.background,
    text: {
      primary: '#d0d0d0',
      brand: colors.dark.text.brand,
      brandActive: colors.dark.text.brandActive,
      danger: colors.dark.danger,
    },
    divider: colors.dark.divider,
  },
  fonts: {
    headings: 'Barlow, sans-serif',
  },
});
