import React from 'react';
import get from 'lodash/get';
import produce, { Draft } from 'immer';
import { connect } from 'react-redux';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import { getPipesFromSystem, getPipesToSystem } from 'Internals/systems';
import { getPathColor, makeUrlParamsFromPath } from 'Internals/graph';
import { getSinkDataset } from 'Internals/pipes';

import Hub from 'Common/Hub/Hub';
import EmptyState from 'Common/EmptyState/EmptyState';
import SystemIcon from '../../../images/icons/system.svg';
import EndpointNode from 'Common/Hub/EndpointNode';
import PieHub from '../../components/hub/PieHub';
import { getIndexDeleted, getIndexWithoutDeleted, getLogDeleted } from 'Internals/datasets';
import { LoadingPanel } from 'Common/LoadingPanel';
import { useFlows } from '../../../hooks/useFlows';
import { nameSorter } from 'Internals/utils';
import {
  LocalAggregatedFlows,
  getAngle,
  getDirectionCounts,
  Flow,
  LocalOverviewNode,
} from 'Internals/overview-utils';
import {
  DatasetMap,
  DownstreamMap,
  InboundMap,
  LookupMap,
  OutboundMap,
  PipeID,
  PipeMap,
  System,
  SystemID,
  UpstreamMap,
} from '../../../types/types';
import useLoadingState from '../../../hooks/useLoadingState';

function createNodes(
  aggregateFlows: LocalAggregatedFlows,
  systemId: string,
  pipes: PipeMap,
  subId: string
): LocalOverviewNode[] {
  return Object.keys(aggregateFlows).map((identifier) => {
    return produce({}, (node: Draft<LocalOverviewNode>) => {
      const { flows, kind, direction } = aggregateFlows[identifier];
      node.name = identifier;
      node.flows = flows.map((flow) => {
        return {
          pipeName: identifier,
          link: `/subscription/${subId}/flows/flow?${makeUrlParamsFromPath(flow.path)}`,
          path: flow.path,
          localDirection: flow.direction === 'inbound' ? 'outbound' : 'inbound',
        };
      });
      node.kind = 'global';
      // locally this is a global node, even though globally, these
      // are all system flows (global flow is between two globals)
      // the kind=global is for the icon in the node component
      node.direction = direction;
      node.direct = flows.reduce((acc: boolean, f) => acc && f.direct === 'Yes', true);

      node.link = `/subscription/${subId}/pipes/pipe/${node.name}/overview`;

      node.color = getPathColor(
        [...new Set<PipeID>(node.flows.map(({ path }) => path).flat())],
        pipes
      );
      node.pipesFromLink = `/subscription/${subId}/flows/all?To.selected=${node.name}&System.selected=${systemId}`;
      node.pipesToLink = `/subscription/${subId}/flows/all?From.selected=${node.name}&System.selected=${systemId}`;
      return node;
    });
  });
}

function getAggregateFlows(systemId: string, flows: Flow[]) {
  let aggregateFlows: LocalAggregatedFlows = {};

  for (const f of flows) {
    if (f.kind === 'system' && f.direction !== 'internal' && f.systemId === systemId) {
      // inbound direction of flow means from an edge system to a global
      // so the identifier we care about is the global pipe, the "to" property
      // in case of inbound and "from" property in case of outbound
      const direction = f.direction === 'inbound' ? 'outgoing' : 'incoming';
      const identifier = f.direction === 'inbound' ? f.to : f.from;
      if (aggregateFlows[identifier]) {
        aggregateFlows[identifier].flows.push(f);
        if (aggregateFlows[identifier].direction !== direction) {
          aggregateFlows[identifier].direction = 'both';
        }
      } else {
        aggregateFlows[identifier] = {
          kind: f.kind, // always "system" in this case
          direction: direction,
          doesNotExist: false, // global pipe will always exist
          systemsInGroup: [], // no system/groups, just global pipes
          flows: [f],
        };
      }
    }
  }

  return aggregateFlows;
}

// TODO these functions can by more DRY
// the whole System/Pipe/General overview can have more reuse of functions
// EndpointNode component would be good to turn into Compound Component like I did with Hub
// TODO make the component show a loading panel while calculating

type SystemOverviewProps = {
  params: {
    systemID: SystemID;
  };
  system: System;
  pipes: PipeMap;
  inbounds: InboundMap;
  outbounds: OutboundMap;
  upstreams: UpstreamMap;
  downstreams: DownstreamMap;
  lookups: LookupMap;
  datasets: DatasetMap;
  subId: string;
  flowsComputing: boolean;
};

function SystemOverview(props: SystemOverviewProps) {
  let { flows, finished: flowsComputed, loading: flowsLoading } = useFlows();
  const loadingState = useLoadingState();
  const systemId = get(props, 'system._id');

  const aggregateFlows = getAggregateFlows(systemId, flows);

  const inPipes = getPipesToSystem(props.params.systemID, props.pipes, props.inbounds);
  const outPipes = getPipesFromSystem(props.params.systemID, props.pipes, props.outbounds);

  const nodes = createNodes(aggregateFlows, systemId, props.pipes, props.subId);

  if (flowsLoading) {
    return <LoadingPanel loadingMessage={'Loading flows...'} />;
  }

  if (nodes.length < 1 && flowsComputed) {
    return <EmptyState>This system has no flows to global pipes</EmptyState>;
  }

  const directionCounts = getDirectionCounts(nodes);
  const sizes = [...inPipes, ...outPipes].reduce(
    (sums, pipe) => {
      const sinkDataset = getSinkDataset(pipe, props.datasets);
      return {
        latest: sums.latest + getIndexWithoutDeleted(sinkDataset),
        deleted: sums.deleted + getIndexDeleted(sinkDataset),
        history: sums.history + getLogDeleted(sinkDataset),
      };
    },
    {
      latest: 0,
      deleted: 0,
      history: 0,
    }
  );
  const slices = [
    {
      color: '#529cba', //blue
      name: 'Latest',
      onClick: () => {},
      value: sizes.latest,
    },
    {
      color: '#c93c37', //red
      name: 'Deleted',
      onClick: () => {},
      value: sizes.deleted,
    },
    {
      color: '#739984', //green
      name: 'History',
      onClick: () => {},
      value: sizes.history,
    },
  ];

  return (
    <ErrorBoundary>
      <div className="scrollArea">
        {loadingState.pipesLoaded ? (
          props.flowsComputing ? (
            <LoadingPanel loadingMessage="Calculating flows" />
          ) : (
            <Hub>
              <Hub.Nexus>
                <PieHub slices={slices} overlayColor="white">
                  <SystemIcon width="50" height="50" />
                  <span>{props.system._id}</span>
                </PieHub>
              </Hub.Nexus>
              <Hub.Wheel>
                {nodes
                  .filter((n) => n.direction !== 'outgoing')
                  .sort(nameSorter)
                  .reverse()
                  .map((node: LocalOverviewNode, i: number) => (
                    <Hub.Spoke
                      color={node.color}
                      angle={getAngle(i, directionCounts.left)}
                      direction={node.direction}
                      key={i}
                      dashed={!node.direct}
                    >
                      <EndpointNode {...node} />
                    </Hub.Spoke>
                  ))}
                {nodes
                  .filter((n) => n.direction === 'outgoing')
                  .sort(nameSorter)
                  .map((node: LocalOverviewNode, i: number) => (
                    <Hub.Spoke
                      color={node.color}
                      angle={getAngle(i, directionCounts.right)}
                      direction={node.direction}
                      key={i}
                      dashed={!node.direct}
                    >
                      <EndpointNode {...node} />
                    </Hub.Spoke>
                  ))}
              </Hub.Wheel>
            </Hub>
          )
        ) : (
          <LoadingPanel loadingMessage="Fetching data" />
        )}
      </div>
    </ErrorBoundary>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    datasets: state.datasets,
    system: state.systems[ownProps.params.systemID],
    pipes: state.pipes,
    inbounds: state.inbounds,
    outbounds: state.outbounds,
    upstreams: state.upstreams,
    downstreams: state.downstreams,
    lookups: state.lookups,
    subId: state.subscription.id,
    flowsComputing: state.loadStatus.flowsComputing,
  };
}

export default connect(mapStateToProps)(SystemOverview);
