import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import Feedback from '../../../components/feedback';

class UpdateBasicsForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleFieldChange = (ev) => {
      this.setState({
        [ev.target.id]: ev.target.value,
        showSaveConfirmation: false,
        notValid: false,
      });
    };

    this.hasChanges = () => {
      return (
        this.props.name !== this.state.name ||
        this.props.description !== this.state.description ||
        this.props.url !== this.state.url
      );
    };

    this.update = (e) => {
      e.preventDefault();
      const notValid = !this.state.name.trim();
      this.setState({ notValid });
      if (!notValid) {
        this.setState({ pleaseWait: true });
        this.props
          .onUpdate(this.state.name, this.state.description, this.state.url)
          .then(() => {
            this.setState({
              pleaseWait: false,
              editing: false,
              showSaveConfirmation: true,
            });
          })
          .catch(() => {
            this.setState({ pleaseWait: false });
          });
      }
    };

    this.state = {
      name: this.props.name,
      description: this.props.description,
      showSaveConfirmation: false,
      url: this.props.url,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.name !== this.props.name ||
      prevProps.description !== this.props.description ||
      prevProps.showSaveConfirmation !== this.props.showSaveConfirmation ||
      prevProps.url !== this.props.url
    ) {
      this.setState({
        name: this.props.name,
        description: this.props.description,
        showSaveConfirmation: this.props.showSaveConfirmation,
        url: this.props.url,
      });
    }
  }

  render() {
    return (
      <Form onSubmit={this.update} standout>
        <SesamTextField
          margin="normal"
          id="name"
          onChange={this.handleFieldChange}
          value={this.state.name}
          label="Name"
          type="text"
          error={this.state.notValid}
          helperText={this.state.notValid && 'Name is required'}
        />
        <SesamTextField
          multiline
          margin="normal"
          id="description"
          onChange={this.handleFieldChange}
          value={this.state.description}
          label="Description"
          type="textarea"
          placeholder="Describe your subscription"
          rows="6"
        />
        <SesamTextField
          margin="normal"
          id="url"
          type="url"
          value={this.state.url}
          onChange={this.handleFieldChange}
          label="Documentation URL"
          placeholder="URL to a page that documents this subscription"
        />
        {this.props.canChange && (
          <FormActions>
            <Button
              text={this.state.pleaseWait ? 'Updating' : 'Update'}
              disabled={this.state.pleaseWait || !this.hasChanges()}
              onClick={this.update}
            />
          </FormActions>
        )}
        {!this.props.canChange && (
          <Feedback>You don&apos;t have permission to change these details</Feedback>
        )}
        {this.state.showSaveConfirmation && <Feedback>Changes saved</Feedback>}
      </Form>
    );
  }
}

UpdateBasicsForm.propTypes = {
  canChange: PropTypes.bool,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  showSaveConfirmation: PropTypes.bool,
};

export default UpdateBasicsForm;
