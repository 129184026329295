import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';

const useStyle = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const SesamAutocomplete: React.FC<AutocompleteProps<string>> = (props) => {
  const classes = useStyle();
  return <Autocomplete classes={{ root: classes.root }} {...props} />;
};

export default SesamAutocomplete;
