import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SesamLink from 'Common/Links/SesamLink';
import Layout from '../layout/full-sidebar';
import Page from '../components/page';
import PageHeader, { PageHeaderTitle } from '../components/page-header';
import TabbedNav from '../components/tabbed-nav';

const useStyle = makeStyles(() => {
  return {
    table: {
      width: '100%',
      padding: '0 1em',
      textAlign: 'left',
    },
    header: {
      marginBottom: '20px',
      fontSize: '25px',
    },
    subHeader: {
      marginTop: '20px',
      fontSize: '20px',
    },
  };
});

const PricesPage = () => {
  const classes = useStyle();

  return (
    <Layout>
      <Page>
        <PageHeader>
          <PageHeaderTitle>
            <SesamLink to={'/'}>Dashboard</SesamLink>
          </PageHeaderTitle>
          <PageHeaderTitle>Prices</PageHeaderTitle>
        </PageHeader>

        <TabbedNav
          nav={[
            {
              label: 'Subscription fees',
              to: '/prices',
            },
          ]}
        >
          <main className="scrollArea" style={{ marginTop: '1em' }}>
            <div>
              <div style={{ marginBottom: '50px' }}>
                <h1 className={classes.header}>Prices</h1>
                <div>
                  <h2 className={classes.subHeader}>Developer environment</h2>
                  <p style={{ width: '70%' }}>
                    A developer environment has a fixed price and serves a single developer. 
                    These do not have support for backup, VPN, or SLA and can not host test, 
                    staging or production environments. The development environment is
                    cloud-only and restricted. We strongly encourage following{' '}
                    <a
                      href="https://en.wikipedia.org/wiki/Test-driven_development"
                      target="_blank"
                      rel="noreferrer"
                    >
                      test-driven development practices
                    </a>{' '}
                    by using the{' '}
                    <a
                      href="https://docs.sesam.io/hub/sesam-client.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sesam Client
                    </a>
                    .
                  </p>
                  <table className={classes.table}>
                    <colgroup>
                      <col style={{ width: '70%' }} />
                      <col style={{ width: '30%' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <p>Compute</p>
                        </th>
                        <th>
                          <p>Pr environment</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>Fixed price Developer - 1 Engine &lt; 20 GB Data</p>
                        </td>
                        <td>
                          <p>$75.00</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Fixed price Developer Pro - 2 Engines &lt; 20 GB Data</p>
                        </td>
                        <td>
                          <p>$320.00</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <h2 className={classes.subHeader}>Production and test environment</h2>
                  <table className={classes.table}>
                    <colgroup>
                      <col style={{ width: '70%' }} />
                      <col style={{ width: '30%' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <p>
                            Compute <sup>[1]</sup>
                          </p>
                        </th>
                        <th>
                          <p>Pr environment</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>Single compute - 4 Engines &lt; 350 GB Data</p>
                        </td>
                        <td>
                          <p>$1,300.00</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Multi compute - 16 Engines &lt; 1 TB Data</p>
                        </td>
                        <td>
                          <p>$4,200.00</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className={classes.table}>
                    <colgroup>
                      <col style={{ width: '70%' }} />
                      <col style={{ width: '30%' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <p>Data</p>
                        </th>
                        <th>
                          <p>Pr GB</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>Storage</p>
                        </td>
                        <td>
                          <p>$22.00</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>VPN</p>
                        </td>
                        <td>
                          <p>$3.00</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className={classes.table}>
                    <colgroup>
                      <col style={{ width: '70%' }} />
                      <col style={{ width: '30%' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <p>
                            SLA - Response time <sup>[2]</sup>
                          </p>
                        </th>
                        <th>
                          <p>Pr GB</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>Standard - 8h</p>
                        </td>
                        <td>
                          <p>$22.00</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Enterprise - 1h</p>
                        </td>
                        <td>
                          <p>$42.00</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Premium 0,5h x 24/7 <sup>[3]</sup>
                          </p>
                        </td>
                        <td>
                          <p>$125.00</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <p>
                    <sup>[1]</sup> Geo-replicated backup is included in cloud environments.
                  </p>
                  <p>
                    <sup>[2]</sup> SLA is billed for minimum 50GB data and a maximum of 600GB.
                  </p>
                  <p>
                    <sup>[3]</sup> Contact sales for Premium SLA.
                  </p>
                </div>
              </div>
              <h1 className={classes.header}>Legacy prices</h1>
              <p>The following items are no longer available for new subscriptions:</p>
              <table className={classes.table}>
                <colgroup>
                  <col style={{ width: '70%' }} />
                  <col style={{ width: '30%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <p>Compute</p>
                    </th>
                    <th>
                      <p>Pr environment</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>Large compute - 8 Engines &lt; 750 GB Data</p>
                    </td>
                    <td>
                      <p>$2,500.00</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className={classes.table}>
                <colgroup>
                  <col style={{ width: '70%' }} />
                  <col style={{ width: '30%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <p>
                        <a>GDPR Data Access Portal</a>
                      </p>
                    </th>
                    <th>
                      <p>Pr GB</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>Basic &lt; 1 request per second</p>
                    </td>
                    <td>
                      <p>$50.00</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Standard &lt; 5 requests per second</p>
                    </td>
                    <td>
                      <p>$100.00</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Enterprise &lt; 10 requests per second</p>
                    </td>
                    <td>
                      <p>$200.00</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </main>
        </TabbedNav>
      </Page>
    </Layout>
  );
};

export default PricesPage;
