import { configureStore } from '@reduxjs/toolkit';
import RootReducer from 'Redux/reducers';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { save, load } from 'redux-localstorage-simple';
import { browserHistory } from './browserHistory';

export const store = configureStore({
  reducer: RootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => [
    thunk,
    routerMiddleware(browserHistory),
    save({
      states: ['navigation', 'gdpr', 'theme'],
      namespace: 'sesam',
    }),
  ],
  preloadedState: load({
    states: ['navigation', 'gdpr', 'theme'],
    namespace: 'sesam',
  }),
});
