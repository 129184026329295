/**
 * A horizontal bar that provides actions and status (e.g. error message),
 * usually to be used as a whole-page submission UI.
 */

import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import InProgress from '../in-progress';
import './style.css';

export const ActionBarStatus = {
  default: 'default',
  error: 'error',
  waiting: 'waiting',
};

const statusLabels = {
  error: 'Error',
  waiting: 'In progress…',
};

class ActionBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggleMessage = () => {
      this.setState({ messageCollapsed: !this.state.messageCollapsed });
    };

    this.state = {
      messageCollapsed: true,
    };
  }

  render() {
    let className = `action-bar action-bar--status-${this.props.status}`;
    if (this.props.darkMode) className += '-dark';

    if (!this.state.messageCollapsed) {
      className += ' action-bar--show-message';
    }

    return (
      <nav className={className}>
        {this.props.status !== ActionBarStatus.default && (
          <div className="action-bar__status-icon">
            {this.props.status === ActionBarStatus.waiting && <InProgress />}
            <div className="visually-hidden">{statusLabels[this.props.status]}</div>
          </div>
        )}
        {this.props.message && (
          <div className="action-bar__message">
            <button className="action-bar__message-toggler" onClick={this.toggleMessage}>
              <span className="visually-hidden">Toggle</span>
            </button>
            <div className="action-bar__message-content">{this.props.message}</div>
          </div>
        )}
        {this.props.shortMessage && (
          <div className="action-bar__short-message">{this.props.shortMessage}</div>
        )}
        <div className="action-bar__actions">{this.props.children}</div>
      </nav>
    );
  }
}

ActionBar.propTypes = {
  children: PropTypes.node,
  message: PropTypes.node,
  shortMessage: PropTypes.string,
  status: PropTypes.string,
  darkMode: PropTypes.bool,
};

ActionBar.defaultProps = {
  message: '',
  shortMessage: '',
  status: ActionBarStatus.default,
};

const mapStateToProps = (state) => ({
  darkMode: state.theme.dark,
});

export default connect(mapStateToProps)(ActionBar);
