/**
 * @see http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
 */

import React from 'react';
import PropTypes from 'prop-types';

import { buildClassName, dataAttrsFromProps, ClassNamePropType } from 'Internals/react-utils';

import './style.css';

const Media = function ({ children, className, img, nowrap, type, ...rest }) {
  const classes = buildClassName(
    'media',
    type && `media--${type}`,
    nowrap && 'media--nowrap',
    className
  );

  return (
    <div className={classes} {...dataAttrsFromProps(rest)}>
      {img !== undefined && <div className="media__img">{img}</div>}
      <div className="media__bd">{children}</div>
    </div>
  );
};

Media.propTypes = {
  children: PropTypes.node,
  className: ClassNamePropType,
  img: PropTypes.node,
  nowrap: PropTypes.bool,
  type: PropTypes.oneOf(['avatar', 'banner', 'icon', 'stats']),
};

Media.defaultProps = {
  children: undefined,
  className: undefined,
  img: undefined,
  nowrap: false,
  type: undefined,
};

export default Media;
