import React from 'react';

const State: React.FC<{ title: string; text: string; className: string }> = ({
  title,
  text,
  className,
}) => (
  <span title={title} className={`state ${className}`}>
    &#x25cf; {text}
  </span>
);

export default State;
