import VarsAPI, { EnvVarsDict } from '../../api/vars';
import { apologise } from './apology';
import { getApiConf } from './subscriptions';
import { AppThunk } from '../..';
import { createAction } from '@reduxjs/toolkit';
import { Json } from '../../types/json';

export const envVarRemoved = createAction<string>('envVars/removed');
export const envVarReplaced = createAction<{ name: string; value: Json }>('envVars/replaced');
export const envVarLoadedAll = createAction<EnvVarsDict>('envVars/loadedAll');
export const envVarReplacedAll = createAction<EnvVarsDict>('envVars/replacedAll');

export default {
  envSaveAll(env: EnvVarsDict): AppThunk {
    return function (dispatch, getState) {
      return VarsAPI.put(getApiConf(getState), env)
        .then(() => dispatch(envVarReplacedAll(env)))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  envLoadAll(): AppThunk {
    return function (dispatch, getState) {
      return VarsAPI.getAll(getApiConf(getState))
        .then((envVariables) => dispatch(envVarLoadedAll(envVariables)))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  envReplace(name: string, value: Json): AppThunk {
    return function (dispatch, getState) {
      return VarsAPI.post(getApiConf(getState), name, value)
        .then(() => dispatch(envVarReplaced({ name, value })))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  envRemove(name: string): AppThunk {
    return function (dispatch, getState) {
      return VarsAPI.remove(getApiConf(getState), name)
        .then(() => dispatch(envVarRemoved(name)))
        .catch((error) => dispatch(apologise(error)));
    };
  },
};
