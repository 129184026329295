import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast as ToastType, toastDismissed } from 'Redux/thunks/global';
import { RootState } from '../../';
import { Toast } from './Toast';

const TICK_DURATION = 20;
const TRANSITION_DURATION = 333; /* matches .33s in CSS file, var(--duration-major) */
const TOAST_DURATION = 3000;

export enum TransitionState {
  APPEAR = 'appear',
  DISMISS = 'dismiss',
}

export const Toaster = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state: RootState) => state.toasts);
  const [activeToast, setActiveToast] = useState<ToastType>();
  const [transitionState, setTransitionState] = useState(TransitionState.DISMISS);

  useEffect(() => {
    if (toasts && toasts.length > 0 && transitionState !== TransitionState.APPEAR) {
      setTimeout(() => {
        const toastObject = {
          message: toasts[0].message,
          type: toasts[0].type,
        };
        setActiveToast(toastObject);
        setTransitionState(TransitionState.APPEAR);

        // dismisses the toast after it has been shown for a certain duration
        setTimeout(() => {
          dismiss();
        }, TOAST_DURATION);
      }, TICK_DURATION);
    }
  }, [toasts]);

  const dismiss = () => {
    setTransitionState(TransitionState.DISMISS);
    setTimeout(() => {
      dispatch(toastDismissed());
    }, TRANSITION_DURATION);
  };

  if (!activeToast) {
    return null;
  }

  return (
    <aside className="toaster">
      <Toast
        message={activeToast.message}
        type={activeToast.type}
        transitionState={transitionState}
        dismiss={dismiss}
      />
    </aside>
  );
};
