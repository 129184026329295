import React from 'react';
import PropTypes from 'prop-types';
import ClipboardJS from 'clipboard';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import Button from '../Button/Button';
import JsonEditor from '../JsonEditor/JsonEditor';
import { LoadingPanel } from '../LoadingPanel';

// Icons downloaded from here: https://www.iconfinder.com/search/?q=file
import FileIcon from '../../../images/icons/file-generic.svg';
import UploadIcon from '../../../images/icons/upload.svg';
import UploadOkIcon from '../../../images/icons/uploadOk.svg';

import './UploadStyle.css';
import { toastAdded } from 'Redux/thunks/global';
import ExternalLink from '../Links/ExternalLink';

/**
 * Presentational component that renders an upload form.
 * Uses functionality from the "withUpload" HOC.
 */
class Upload extends React.Component {
  constructor(props) {
    super(props);

    this.clipboard = new ClipboardJS('.upload__curl-copy');
    this.clipboard.on('success', () => {
      this.props.addToast('Copied to clipboard');
    });
  }

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  render() {
    let curl = null;
    let loader = null;
    let uploadSuccess = null;
    let editor = null;
    let dropZone = null;
    let file = null;
    let errors = null;

    if (this.props.uploading) {
      loader = (
        <div className="upload__loader">
          <LoadingPanel />
        </div>
      );
    }

    if (this.props.curl && this.props.contentType === 'application/json') {
      let command;

      if (this.props.contentType === 'application/json') {
        command = `curl -H "Content-Type: ${this.props.contentType}" -H "Authorization: bearer ${
          this.props.token
        }" -X POST -d '${JSON.stringify(this.props.json)}' ${this.props.url}`;
      }
      curl = (
        <React.Fragment>
          <ExternalLink className="upload__curl-copy" data-clipboard-text={command}>
            Copy cURL
          </ExternalLink>
        </React.Fragment>
      );
    }

    if (this.props.uploadSuccess) {
      uploadSuccess = (
        <div className="upload__success">
          <div className="upload__icon upload__icon--success">
            <UploadOkIcon />
          </div>
          <div className="upload__drop-msg upload__drop-msg--bold">SUCCESS!</div>
          <div className="upload__drop-msg upload__drop-msg">your file was uploaded</div>
        </div>
      );
    }

    if (!this.props.file && !this.props.uploadSuccess && this.props.editor) {
      editor = (
        <div className="upload__editor">
          <div className="upload__editor-info">
            Use the editor below, or <b>drag & drop</b> a file to it.
          </div>
          <JsonEditor
            key={this.props.editorKey}
            errors={this.props.errors}
            onChange={(json) => this.props.onJsonChange(json)}
            onValidateJson={(valid) => this.props.onValidateJson(valid)}
            rawJson={this.props.json}
          />
        </div>
      );
    }

    if (!this.props.file && !this.props.uploadSuccess && !this.props.editor) {
      dropZone = (
        <div className="upload__drop-zone">
          <div className="upload__icon upload__icon--drop">
            <UploadIcon />
          </div>
          <div className="upload__drop-msg upload__drop-msg--bold">DRAG & DROP</div>
          <div className="upload__drop-msg upload__drop-msg">to upload</div>
        </div>
      );
    }

    if (this.props.file && !this.props.uploading && !this.props.uploadSuccess) {
      file = (
        <div
          className={`upload__file upload__file--${
            isEmpty(this.props.errors) ? 'success' : 'error'
          }`}
        >
          <div className="upload__icon">
            <FileIcon />
          </div>
          <div className="upload__drop-msg upload__drop-msg--bold">
            <p>{this.props.file.name}</p>
          </div>
        </div>
      );
    }

    if (
      !isEmpty(this.props.errors) &&
      ((this.props.editor && this.props.file) || !this.props.editor)
    ) {
      errors = <div className="upload__errors">{this.props.errors}</div>;
    }

    return (
      <form
        className="upload"
        onDrop={this.props.onDrop}
        onDragOver={(ev) => ev.preventDefault()}
        onSubmit={this.props.onUpload}
      >
        {loader}
        {uploadSuccess}
        {editor}
        {dropZone}
        {file}
        {errors}
        <div className="upload__actions">
          {curl}
          <Button
            className="upload__action"
            style={{ marginLeft: '0.5rem' }}
            theme="primary"
            type="submit"
            disabled={
              this.props.uploadSuccess ||
              (!this.props.force && !isEmpty(this.props.errors)) ||
              this.props.uploading ||
              (!this.props.file && isEmpty(this.props.json))
            }
          >
            Upload
          </Button>
          <Button
            className="upload__action"
            theme="danger"
            style={{ marginLeft: '0.5rem' }}
            onClick={this.props.onReset}
          >
            Clear
          </Button>
        </div>
      </form>
    );
  }
}

Upload.propTypes = {
  addToast: PropTypes.func.isRequired,
  allowed: PropTypes.arrayOf(PropTypes.string),
  contentType: PropTypes.string,
  editor: PropTypes.bool,
  curl: (props, propName, componentName) => {
    if (props['allowed'].length !== 1 || props['allowed'][0] !== 'json') {
      return new Error(`${componentName}: The curl property is only available for type: JSON`);
    }
  },
  editorKey: PropTypes.number.isRequired,
  errors: PropTypes.string.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  force: PropTypes.bool.isRequired,
  json: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  token: PropTypes.string,
  uploading: PropTypes.bool.isRequired,
  uploadSuccess: PropTypes.bool.isRequired,
  url: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  onJsonChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onValidateJson: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

Upload.defaultProps = {
  editor: false,
  curl: false,
};

const mapDispatchToProps = (dispatch) => ({
  addToast: (message) => dispatch(toastAdded({ type: 'success', message })),
});

export default connect(null, mapDispatchToProps)(Upload);
