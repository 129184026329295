/*
 Copyright (C) Sesam.io - All Rights Reserved.
 Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';

import { PermissionsEditor } from 'Common/PermissionsEditor';
import DatahubSettings from '../settings-datahub';

function SettingsDatahubPermissions() {
  return (
    <DatahubSettings>
      <main className="scrollArea">
        <PermissionsEditor permission="node" />
      </main>
    </DatahubSettings>
  );
}

export default SettingsDatahubPermissions;
