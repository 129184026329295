import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import get from 'lodash/get';

import Box from 'Common/Box/Box';
import Button from 'Common/Button/Button';
import DatasetActions from 'Redux/thunks/datasets';
import JsonPanel from 'Common/JsonPanel';
import SystemActions from 'Redux/thunks/systems';
import SystemGraph from '../../subscription/pages/systems/SystemGraph';
import PipeActions from 'Redux/thunks/pipes';

import './SystemDashboardStyle.css';
import ConfigModifiedInfo from 'Common/ConfigModifiedInfo/ConfigModifiedInfo';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import ButtonLink from '../common/ButtonLink/ButtonLink';

class SystemDashboard extends React.Component {
  componentDidMount() {
    this.props.loadSystem(this.props.params.systemID);
    this.props.loadPipes();
    this.props.loadDatasets();
    this.props.loadSystems();
  }

  componentDidUpdate(prevProps) {
    if (this.props.system !== prevProps.system) {
      this.props.loadSystem(this.props.params.systemID);
    }
  }

  render() {
    const { system, datasetsLoaded, pipesLoaded, systemsLoaded, params, router } = this.props;

    if (!system || !pipesLoaded || !systemsLoaded || !datasetsLoaded) {
      return null;
    }

    return (
      <div className="system-dashboard">
        <div className="system-dashboard__section">
          <Box>
            <Box.Header>
              <Box.Title>Graph</Box.Title>
              <Box.Actions>
                <ButtonLink
                  onClick={() =>
                    router.push(
                      `/subscription/${params.subId}/systems/system/${encodeURIComponent(
                        system._id
                      )}/graph`
                    )
                  }
                  size="tiny"
                >
                  Go to graph
                </ButtonLink>
              </Box.Actions>
            </Box.Header>
            <Box.Content>
              <ErrorBoundary fallback="An error occured while rendering the graph">
                <SystemGraph
                  params={params}
                  onGotoNode={(link) => {
                    router.push(link.replace('/graph', '/dashboard'));
                  }}
                  options={{
                    autoResize: true,
                  }}
                  fit={true}
                  registerRefresh={this.props.registerRefresh}
                  unregisterRefresh={this.props.unregisterRefresh}
                />
              </ErrorBoundary>
            </Box.Content>
          </Box>
        </div>
        <div className="system-dashboard__section">
          <Box>
            <Box.Header>
              <Box.Title>Config</Box.Title>
              <Box.Actions>
                <ErrorBoundary fallback="An error occured while rendering the system config">
                  <ButtonLink
                    onClick={() =>
                      router.push(
                        `/subscription/${params.subId}/systems/system/${encodeURIComponent(
                          system._id
                        )}/edit`
                      )
                    }
                    size="tiny"
                  >
                    Edit config
                  </ButtonLink>
                </ErrorBoundary>
              </Box.Actions>
            </Box.Header>
            <Box.Content>
              <JsonPanel rawJson={get(system, 'config.original')} />
              <ConfigModifiedInfo primitive={system} />
            </Box.Content>
          </Box>
        </div>
      </div>
    );
  }
}

SystemDashboard.propTypes = {
  system: PropTypes.shape({}),
  datasetsLoaded: PropTypes.bool,
  pipesLoaded: PropTypes.bool,
  systemsLoaded: PropTypes.bool,
  params: PropTypes.shape({
    systemID: PropTypes.string,
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  loadSystem: PropTypes.func,
  loadPipes: PropTypes.func,
  loadDatasets: PropTypes.func,
  loadSystems: PropTypes.func,
  registerRefresh: PropTypes.func.isRequired,
  unregisterRefresh: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  system: state.systems[ownProps.params.systemID],
  pipesLoaded: state.loadStatus.pipesLoaded,
  systemsLoaded: state.loadStatus.systemsLoaded,
  datasetsLoaded: state.loadStatus.datasetsLoaded,
});

const mapDispatchToProps = (dispatch) => ({
  loadDatasets: () => dispatch(DatasetActions.loadAll()),
  loadSystem: (id) => dispatch(SystemActions.load(id)),
  loadSystems: () => dispatch(SystemActions.loadAll()),
  loadPipes: () => dispatch(PipeActions.loadAll()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(SystemDashboard);
