import React from 'react';

import { PermissionsEditor } from 'Common/PermissionsEditor';
import PipesParent from './PipesParent';

function PipesPrototypePermissions() {
  return (
    <PipesParent>
      <div className="scrollArea">
        <p>These settings will be inherited as default settings by all pipes.</p>
        <PermissionsEditor permission="pipe-prototype" />
      </div>
    </PipesParent>
  );
}

export default PipesPrototypePermissions;
