import React from 'react';

import { DateTimePicker, DateTimePickerProps } from '@material-ui/pickers';

const SesamDateTimePicker: React.FC<DateTimePickerProps> = (props) => <DateTimePicker {...props} />;

SesamDateTimePicker.defaultProps = {
  ampm: false,
};

export default SesamDateTimePicker;
