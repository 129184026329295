import { ApiConf } from '../types/types';
import { sfetchJson } from 'Internals/sfetch';

async function getAll(apiConf: ApiConf): Promise<string[]> {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    headers: { Authorization: `bearer ${token}` },
  };
  return await sfetchJson(subUrl + '/secrets', options);
}

export type SecretPostResponse = {
  [secretName: string]: '<created>' | '<updated>';
};

async function post(apiConf: ApiConf, name: string, value: string): Promise<SecretPostResponse> {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify({ [name]: value }),
  };
  return await sfetchJson(`${subUrl}/secrets`, options);
}

export type SecretDeleteResponse = {
  [secretName: string]: '<deleted>';
};

async function remove(apiConf: ApiConf, name: string): Promise<SecretDeleteResponse> {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    method: 'DELETE',
    headers: {
      Authorization: `bearer ${token}`,
    },
    mode: 'cors',
  };
  return await sfetchJson(`${subUrl}/secrets/${name}`, options);
}

export default {
  post,
  remove,
  getAll,
};
