import PropTypes from 'prop-types';
import React from 'react';

import './ButtonGroupStyle.css';

function ButtonGroup({ children, className, ...restProps }) {
  return (
    <div className={`${[className, 'button-group'].join(' ')}`} {...restProps}>
      {children}
    </div>
  );
}

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};

export default ButtonGroup;
