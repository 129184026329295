import PropTypes from 'prop-types';
import React from 'react';
import Recaptcha from 'react-google-recaptcha';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import Feedback from '../components/feedback';
import FocusPanel from '../components/focus-panel';
import Actions from 'Redux/thunks/auth';
import CenteredLayout from '../layout/centered';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.handleFieldChange = (ev) => {
      this.setState({ [ev.target.id]: ev.target.value });
    };

    this.resetPassword = (e) => {
      e.preventDefault();
      const notValid = !this.state.email;
      const possibleRobot = !this.state.captchaResponse;
      this.setState({
        notValid,
        possibleRobot,
      });
      if (!(notValid || possibleRobot)) {
        this.setState({
          pleaseWait: true,
        });
        this.props
          .forgotPassword(this.state.email, this.state.captchaResponse)
          .then(() => {
            this.setState({
              email: '',
              pleaseWait: false,
            });
          })
          .catch(() => {
            this.captchaComponent.reset();
            this.setState({
              pleaseWait: false,
              possibleRobot: true,
            });
          });
      }
    };

    this.captchaDone = (captchaResponse) => {
      this.setState({
        captchaResponse,
        possibleRobot: false,
      });
    };

    this.state = {
      email: '',
      captchaResponse: null,
    };
  }

  render() {
    return (
      <CenteredLayout>
        <FocusPanel>
          <h2 className="heading-page">Reset your password</h2>
          {!this.props.emailSent && (
            <Form onSubmit={this.resetPassword}>
              <SesamTextField
                margin="normal"
                label="Email"
                inputProps={{ autoFocus: true }}
                id="email"
                type="email"
                value={this.state.email}
                onChange={this.handleFieldChange}
              />
              <div>
                <Recaptcha
                  id="captcha"
                  sitekey="6Lcsr8IZAAAAAJQoK5c_BTn0VTf10d4ur6LLuD7g"
                  ref={(el) => (this.captchaComponent = el)}
                  onChange={this.captchaDone}
                />
              </div>
              {this.state.notValid && <Feedback type="warning">Email is required</Feedback>}
              {this.state.possibleRobot && (
                <Feedback type="warning">Solve the CAPTCHA to show you are not a robot</Feedback>
              )}
              <FormActions>
                <SesamLink to="/auth/login">Back to login</SesamLink>
                <Button theme="primary" type="submit" disabled={this.state.pleaseWait}>
                  {this.state.pleaseWait ? 'Sending…' : 'Send email'}
                </Button>
              </FormActions>
            </Form>
          )}
          {this.props.emailSent && (
            <div>
              <p>{`An email has been sent to '${this.props.emailSent}', if it exists in our system. If you haven't received an email, please check your spam folder.`}</p>
              <p>
                <SesamLink to="/auth/login">Back to login</SesamLink>
              </p>
            </div>
          )}
        </FocusPanel>
      </CenteredLayout>
    );
  }
}

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  emailSent: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    emailSent: state.user.forgotEmailSent,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    forgotPassword: (email, captchaResponse) =>
      dispatch(Actions.forgotPassword(email, captchaResponse)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
