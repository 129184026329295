import '@babel/polyfill';
import 'whatwg-fetch';

import smoothscroll from 'smoothscroll-polyfill';
import { Action } from '@reduxjs/toolkit';
import { syncHistoryWithStore } from 'react-router-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThunkAction } from 'redux-thunk';

import 'normalize.css';
import './style/global.css';

import { useDispatch } from 'react-redux';
import wireUpInternals from './internals';

import zendesk from 'Internals/zendesk';
import App from './App';
import ReactGA from 'react-ga4';
import { sendHibernationAnalyticsTrigger } from './utils/Analytics';
import { store } from 'Internals/store';
import { browserHistory } from 'Internals/browserHistory';

// For TypeScript
declare global {
  interface Window {
    sesamBasename: string;
  }
}

export type GetStateFn = typeof store.getState;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<any, RootState, unknown, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Wire up all non-React components
// Kind of a hacky way to use Redux, but it works
wireUpInternals(store);

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store);

// Google Analytics
ReactGA.initialize('G-BGYX13CMXB', {
  gaOptions: {
    cookieDomain: 'auto',
  },
});

// Wire up Google Analytics and Zendesk to the browser history
history.listen((location: Location) => {
  const pathname = location.pathname;
  ReactGA.set({ page: pathname });
  ReactGA.send({ hitType: 'pageview', page: pathname });
  zendesk.updatePath(pathname);

  // Sending an analytics signal to the portal on a subscription page view.
  const portalUrl = store.getState().globals.portalUrl;
  sendHibernationAnalyticsTrigger(portalUrl);
});

export const Analytics = ReactGA;

// Whatever Zendesk and Smoothscroll packages need
zendesk.polyfill();
smoothscroll.polyfill();

ReactDOM.render(<App store={store} history={history} />, document.getElementById('app'));

if (module.hot) {
  // this is necessary for HMR, the only thing we need for the whole app which is nice
  module.hot.accept('./App.tsx', () => {
    const NextRootContainer = require('./App.tsx').default;
    ReactDOM.render(<App store={store} history={history} />, document.getElementById('app'));
  });
}
