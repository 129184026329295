import React from 'react';

import './ErrorPanel.css';

function ErrorPanel(props) {
  if (!props.errors) {
    return <div />;
  }

  if (!props.errors.xhr) {
    return (
      <div className="errorpanel">
        <h1>Dummy failure!</h1>
      </div>
    );
  }
  /*
         xhr: jqXHR,
         textStatus: textStatus,
         errorThrown: errorThrown

         */
  let xhr = props.errors.xhr;
  let title = props.title ? <h1>{props.title}</h1> : <span />;
  return (
    <div className="errorpanel">
      {title}
      <pre>
        {`XHR status: ${xhr.status} (${xhr.statusText})\n` +
          `Text-status: ${props.errors.textStatus}\n` +
          `Error thrown:${props.errors.errorThrown}\n` +
          `XHR response text:\n\n${xhr.responseText}`}
      </pre>
    </div>
  );
}

export default ErrorPanel;
