import React from 'react';
import PropTypes from 'prop-types';
import SubscriptionLink from './SubscriptionLink';

function EndpointTypeLink({ type, direction }) {
  if (!type) return null;
  return (
    <SubscriptionLink
      title={`Show pipes going ${direction === 'inbound' ? 'from' : 'to'} ${type}`}
      to={`pipes/all?${direction === 'inbound' ? 'Source type' : 'Sink type'}.selected=${type}`}
    >
      {type}
    </SubscriptionLink>
  );
}

EndpointTypeLink.propTypes = {
  type: PropTypes.string,
  direction: PropTypes.string.isRequired,
};

export default EndpointTypeLink;
