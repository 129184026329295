import escapeRegExp from 'lodash/escapeRegExp';
import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import 'react-table-6/react-table.css';
import ReactTooltip from 'react-tooltip';
import Button from 'Common/Button/Button';
import '../../../components/common/ErrorPanel.css';
import { FormActions } from 'Common/forms';
import { LoadingPanel } from 'Common/LoadingPanel';
import ExternalLink from 'Common/Links/ExternalLink';
import MaterialDialog from '../../../components/material-dialog';
import sfetch from 'Internals/sfetch';

import PipeActions from 'Redux/thunks/pipes';
import { apologise } from 'Redux/thunks/apology';
import { getGdprMsUrl } from '../gdpr/util';
import './style.css';

const ReactTable = lazy(() => import(/* webpackChunkName: "react-table" */ 'react-table-6'));
import 'react-table-6/react-table.css';

import '../../../components/common/ErrorPanel.css';
import './style.css';
import { toastAdded } from 'Redux/thunks/global';

class GdprTranslations extends React.Component {
  constructor(props) {
    super(props);

    // Bind some methods that are used as gui eventhandlers and callbacks
    this.getTdProps = this.getTdProps.bind(this);
    this.getTdPropsForLanguageColumn = this.getTdPropsForLanguageColumn.bind(this);
    this.getTheadThProps = this.getTheadThProps.bind(this);
    this.closeEditDialog = this.closeEditDialog.bind(this);
    this.revertEditDialog = this.revertEditDialog.bind(this);

    this.state = {
      downloadError: null,
      selectedLanguageString: null,
      editDialogValue: null,
      originalEditDialogValue: null,
      tableData: null,
      tableColumns: null,
    };

    this.getDatabrowserLocalizationStrings = async () => {
      const options = {
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'GET',
      };
      const url = `${this.props.databrowserURL}/configuration_localization_strings`;
      try {
        const stream = await sfetch(url, options);
        const databrowserStrings = await stream.json();
        return databrowserStrings;
      } catch (err) {
        throw `Failed to load the DAP localization strings from "${url}": ${err.message || err}`;
      }
    };

    this.getDatahubPipeConfig = async (pipeId) => {
      const options = {
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'GET',
      };

      const url = `${this.props.datahubURL}/pipes/${pipeId}/config`;
      try {
        const stream = await sfetch(url, options);
        const datahubStrings = await stream.json();
        return datahubStrings;
      } catch (err) {
        throw `Failed to parse the datahub localization strings from "${url}": ${
          err.message || err
        }`;
      }
    };

    this.putDatahubPipeConfig = async (pipeConfig) => {
      const options = {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.props.token}`,
        },
        body: JSON.stringify(pipeConfig),
        method: 'PUT',
      };
      const pipeId = pipeConfig._id;
      const url = `${this.props.datahubURL}/pipes/${pipeId}/config`;
      try {
        const stream = await sfetch(url, options);
        await stream.json();
      } catch (err) {
        throw `Failed to store the datahub pipe at "${url}": ${err.message || err}`;
      }

      let pumpStartAttempt = 1;
      while (true) {
        try {
          await this.props.runPumpOperationWithoutErrorHandler(pipeId, 'start', {});
          console.log(`Successfully started the pipe "${pipeId}".`);
          break;
        } catch (err) {
          if (pumpStartAttempt < 3) {
            console.log(
              `Failed to start the pipe "${pipeId}": ${
                err.message || err
              }. I'll try again in a second...`
            );
            await new Promise((resolve) => setTimeout(resolve, 1000));
          } else {
            console.info(
              `Failed to start the pipe "${pipeId}": ${err.message || err}. I'm giving up.`
            );
            break;
          }
        }
        pumpStartAttempt++;
      }
    };

    this.deleteDatabrowserOverride = async (languageStringID, language) => {
      const options = {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'DELETE',
      };

      const escapedLanguage = encodeURIComponent(language);
      const escapedLanguageStringID = encodeURIComponent(languageStringID);

      const url = `${this.props.databrowserURL}/configuration_localization_strings/overrides/${escapedLanguage}/${escapedLanguageStringID}`;
      try {
        const stream = await sfetch(url, options);
        const response = await stream.json();
        return response;
      } catch (err) {
        throw `Failed to delete the DAP localization string at "${url}": ${err.message || err}`;
      }
    };

    this.setDatabrowserOverride = async (languageStringID, language, value) => {
      const options = {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.props.token}`,
        },
        body: value,
        method: 'PUT',
      };

      const escapedLanguage = encodeURIComponent(language);
      const escapedLanguageStringID = encodeURIComponent(languageStringID);

      const url = `${this.props.databrowserURL}/configuration_localization_strings/overrides/${escapedLanguage}/${escapedLanguageStringID}`;
      try {
        const stream = await sfetch(url, options);
        const response = await stream.json();
        return response;
      } catch (err) {
        throw `Failed to store the DAP localization string at "${url}": ${err.message || err}`;
      }
    };

    this.loadLocalizationStrings = async () => {
      console.log('loadLocalizationStrings() starting.');
      let databrowserStrings;
      let datahubGDPRTemplateTranslationsPipeConfig;
      let datahubCustomTranslationsPipeConfig;

      try {
        databrowserStrings = await this.getDatabrowserLocalizationStrings();
        datahubGDPRTemplateTranslationsPipeConfig = await this.getDatahubPipeConfig(
          'gdpr-template-translations'
        );
        datahubCustomTranslationsPipeConfig = await this.getDatahubPipeConfig(
          'custom-translations'
        );
      } catch (err) {
        console.log('loadLocalizationStrings() failed:' + err.message || err);
        this.setState({ downloadError: err.message || err });
        this.props.apologise(err.message || err);
        return;
      }

      //################################################################################################
      // Create the react-table structures
      //################################################################################################
      const availableLanguages = new Set();
      availableLanguages.add('en');
      availableLanguages.add('no');
      if (this.props.default_language) {
        availableLanguages.add(this.props.default_language);
      }

      const tableData = [];

      for (const databrowserString of databrowserStrings) {
        // The databrowserString is on this form:
        //
        //  {
        //      "id": "gdpr_not_logged_in_page",
        //      "description": "Heading text that is displayed when the user is not logged in.",
        //      "defaults": {
        //        "no": "\n<div class=\"header\">\n    <h1>Logg inn til Datatilgang Portal</h1>\n</div>",
        //        "en": "\n<div class=\"header\">\n    <h1>Sign in to Data Access Portal</h1>\n</div>"
        //      },
        //      "overrides": {
        //        "no": "\n<div class=\"header\"><h1>Lågg inn </h1></div>"
        //      }
        //    },
        //
        // We will just use this as-is.
        tableData.push(databrowserString);

        for (const language of Object.keys(databrowserString.defaults)) {
          availableLanguages.add(language);
        }
        for (const language of Object.keys(databrowserString.overrides)) {
          availableLanguages.add(language);
        }
      }

      let datahubOverrideStrings = null;
      if (
        datahubCustomTranslationsPipeConfig.source &&
        datahubCustomTranslationsPipeConfig.source.entities
      ) {
        datahubOverrideStrings = datahubCustomTranslationsPipeConfig.source.entities;
      }

      let datahubDefaultStrings = null;
      if (
        datahubGDPRTemplateTranslationsPipeConfig.source &&
        datahubGDPRTemplateTranslationsPipeConfig.source.entities
      ) {
        datahubDefaultStrings = datahubGDPRTemplateTranslationsPipeConfig.source.entities;
      }

      const combinedDatahubStrings = {}; // StringId => stringinfo on the databrowser format
      if (datahubDefaultStrings) {
        for (const datahubString of datahubDefaultStrings) {
          // The datahubString is on this form:
          //  {
          //    "_id": "subject_data_available_mail",
          //    "translations": {
          //      "en": {
          //        "body_template": "Your requested data is now available in the GDPR portal. <br><br>Please log in at <a href=\"$ENV(portal-url)\">GDPR portal</a> to see it.",
          //        "subject_template": "GDPR data access request results available",
          //        "text_body_template": ""
          //      },
          //      "no": {
          //        "body_template": "Data du har forespurt er tilgjengelig i GDPR portalen.<br><br>Logg inn i <a href=\"$ENV(portal-url)\">GDPR portalen</a> for å se dem.",
          //        "subject_template": "GDPR data tilgjengelig",
          //        "text_body_template": ""
          //      },
          //      "description": {
          //        "body_template": "This is the description for the body_template",
          //        "subject_template": "This is the description for the subject_template",
          //        "text_body_template": "This is the description for the text_body_template"
          //      }
          //    }
          //  },
          //
          // Each entity can contain any number of language-strings; the example above contains three:
          // "body_template", "subject_template" and "text_body_template".
          const entityID = datahubString._id;
          if (datahubString.translations) {
            for (const [language, languageStrings] of Object.entries(datahubString.translations)) {
              if (language !== 'description') {
                availableLanguages.add(language);
              }
              for (const [attributeID, languageString] of Object.entries(languageStrings)) {
                const languageStringID = `${entityID}[${attributeID}]`;
                let languageStringInfo = combinedDatahubStrings[languageStringID];
                if (languageStringInfo === undefined) {
                  languageStringInfo = {
                    id: languageStringID,
                    entityID,
                    attributeID,
                    defaults: {},
                    overrides: {},
                  };
                  if (datahubString.translations.description) {
                    const description = datahubString.translations.description[attributeID];
                    if (description) {
                      languageStringInfo.description = description;
                    }
                  }
                  combinedDatahubStrings[languageStringID] = languageStringInfo;
                }
                if (language !== 'description') {
                  languageStringInfo.defaults[language] = languageString;
                }
              }
            }
          }
        }
      }

      if (datahubOverrideStrings) {
        for (const datahubString of datahubOverrideStrings) {
          // The datahubString is on this form:
          //  {
          //    "_id": "subject_data_available_mail",
          //    "translations": {
          //      "en": {
          //        "body_template": "Your requested data is now available in the GDPR portal. <br><br>Please log in at <a href=\"$ENV(portal-url)\">GDPR portal</a> to see it.",
          //        "subject_template": "GDPR data access request results available",
          //        "text_body_template": ""
          //      },
          //      "no": {
          //        "body_template": "Data du har forespurt er tilgjengelig i GDPR portalen.<br><br>Logg inn i <a href=\"$ENV(portal-url)\">GDPR portalen</a> for å se dem.",
          //        "subject_template": "GDPR data tilgjengelig",
          //        "text_body_template": ""
          //      },
          //      "description": {
          //        "body_template": "This is the description for the body_template",
          //        "subject_template": "This is the description for the subject_template",
          //        "text_body_template": "This is the description for the text_body_template"
          //      }
          //    }
          //  },
          //
          // Each entity can contain any number of language-strings; the example above contains three:
          // "body_template", "subject_template" and "text_body_template".
          const entityID = datahubString._id;
          if (datahubString.translations) {
            for (const [language, languageStrings] of Object.entries(datahubString.translations)) {
              if (language !== 'description') {
                availableLanguages.add(language);
              }
              for (const [attributeID, languageString] of Object.entries(languageStrings)) {
                const languageStringID = `${entityID}[${attributeID}]`;
                let languageStringInfo = combinedDatahubStrings[languageStringID];
                if (languageStringInfo === undefined) {
                  languageStringInfo = {
                    id: languageStringID,
                    entityID,
                    attributeID,
                    defaults: {},
                    overrides: {},
                  };
                  if (datahubString.translations.description) {
                    const description = datahubString.translations.description[attributeID];
                    if (description) {
                      languageStringInfo.description = description;
                    }
                  }
                  combinedDatahubStrings[languageStringID] = languageStringInfo;
                }
                if (language !== 'description') {
                  languageStringInfo.overrides[language] = languageString;
                }
              }
            }
          }
        }
      }

      for (const datahubString of Object.values(combinedDatahubStrings)) {
        tableData.push(datahubString);
      }

      const makePlaceholderFilter = function (placeholder, tooltip) {
        return ({ filter, onChange }) => (
          <input
            type="text"
            data-tip={tooltip}
            placeholder={placeholder}
            style={{
              width: '100%',
            }}
            value={filter ? filter.value : ''}
            onChange={(event) => onChange(event.target.value)}
          />
        );
      };

      const tableColumns = [
        {
          id: 'description', // Required because our accessor is not a string
          Header: 'Description',
          className: 'description',
          filterMethod: this.descriptionColumnfilterMethod,
          accessor: (item) => {
            let description = item.id;
            if (item.description) {
              description += `: ${item.description}`;
            }
            return <div dangerouslySetInnerHTML={{ __html: description }} />;
          },
          Filter: makePlaceholderFilter(
            'Filter on description',
            'Type in a word here to find localization strings that has that word in their description.'
          ),
        },
      ];

      const availableLanguagesArray = Array.from(availableLanguages);
      availableLanguagesArray.sort();
      for (const language of availableLanguagesArray) {
        tableColumns.push({
          id: language, // Required because our accessor is not a string
          Header: language,
          className: 'language',
          maxWidth: 200,
          filterMethod: this.languageColumnfilterMethod,
          Cell: (row) => {
            return (
              <ExternalLink
                href={`/subscription/${
                  this.props.subscriptionId
                }/gdpr/translations?id=${encodeURIComponent(row.original.id)}&language=${language}`}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                {row.value}
              </ExternalLink>
            );
          },
          Filter: makePlaceholderFilter(
            `Filter on '${language}' translation`,
            `Type in a word here to find localization strings that has that word in their '${language}' translation.`
          ),

          accessor: (item) => {
            let value = item.overrides[language];
            let className;
            if (value === undefined) {
              // fallback to the default value
              value = item.defaults[language];
            }

            if (value === undefined) {
              value = '**missing**';
            }
            return value;
          },
        });
      }

      this.setState({
        tableData: tableData,
        tableColumns: tableColumns,
      });
      console.log('loadLocalizationStrings() finished ok');
    };

    this.saveEditDialog = async () => {
      this.setState({ savingInProgress: true });

      const selectedLanguageString = this.state.selectedLanguageString.original;
      const language = this.state.selectedLanguageString.language;
      const defaultValue = selectedLanguageString.defaults[language] || '';
      const value = this.state.editDialogValue;
      const languageStringID = selectedLanguageString.id;

      const entityID = selectedLanguageString.entityID;
      const attributeID = selectedLanguageString.attributeID;
      try {
        if (entityID === undefined) {
          // This is a databrowser value that should be stored in the "/sesam/data/databrowser.ini" file.

          if (value === defaultValue) {
            // delete any custom value
            await this.deleteDatabrowserOverride(languageStringID, language);
          } else {
            // save a new custom value
            await this.setDatabrowserOverride(languageStringID, language, value);
          }
        } else {
          // This is a datahub value that should be stored in the "custom-translations" pipe.
          const pipeConfig = await this.getDatahubPipeConfig('custom-translations');
          const filteredEntities = pipeConfig.source.entities.filter(
            (entity) => entity._id === entityID
          );
          let customEntity = filteredEntities.length > 0 ? filteredEntities[0] : undefined;

          if (value === defaultValue) {
            // delete any custom value
            if (customEntity !== undefined) {
              if (customEntity.translations && customEntity.translations[language] !== undefined) {
                delete customEntity.translations[language][attributeID];
                if (Object.keys(customEntity.translations[language]).length === 0) {
                  delete customEntity.translations[language];
                }
              }

              if (
                !customEntity.translations ||
                Object.keys(customEntity.translations).length === 0
              ) {
                // There are no more custom strings in this entity, so we can delete it.
                pipeConfig.source.entities = pipeConfig.source.entities.filter(
                  (entity) => entity._id !== entityID
                );
              }

              await this.putDatahubPipeConfig(pipeConfig);
            }
          } else {
            // save a new custom value
            if (customEntity === undefined) {
              customEntity = {
                _id: entityID,
                translations: {},
              };
              pipeConfig.source.entities.push(customEntity);
            }

            if (customEntity.translations[language] === undefined) {
              customEntity.translations[language] = {};
            }

            customEntity.translations[language][attributeID] = value;

            await this.putDatahubPipeConfig(pipeConfig);
          }
        }
      } catch (err) {
        this.setState({ savingInProgress: false });
        this.props.apologise(err.message || err);
        return;
      }

      // Load all the strings, so that the gui gets updated with the new values.
      await this.loadLocalizationStrings();

      this.setState({ savingInProgress: false });
      this.closeEditDialog();
      this.props.addToast(
        `Updated the value of the '${languageStringID}' string for the language '${language}'.`
      );
    };
  }

  componentDidMount() {
    this.loadLocalizationStrings();
  }

  /*
   * This method is called for each td-cell in the table. It returns any extra props to
   * set on the Cell object that renders the cell.
   */
  getTdProps(state, rowInfo, column, instance) {
    if (rowInfo === undefined || rowInfo.row === undefined) {
      return {};
    }
    if (column.className === 'language') {
      return this.getTdPropsForLanguageColumn(state, rowInfo, column, instance);
    }

    if (column.id === 'description') {
      let tooltip;

      const item = rowInfo.row._original;
      const description = item.description;
      const stringId = item.id;

      tooltip = `StringID: '${stringId}'`;
      if (description) {
        tooltip += `\n${description}`;
        tooltip = description;
      }
      const props = {
        'data-tip': tooltip,
      };

      const language = this.props.default_language || 'no';
      let value = item.overrides[language];
      let className;
      if (value !== undefined) {
        className = 'override';
      } else {
        // fallback to the default value
        className = 'default';
        value = item.defaults[language];
      }
      if (value === undefined) {
        className = 'missing';
      }

      value = value || '';
      value = value.trim();

      props.onClick = this.createOpenEditDialogHandler(language, rowInfo, className, value);
      return props;
    }
    return {};
  }

  /*
   * This method is called for each th-cell in the table head.
   */
  getTheadThProps(state, rowInfo, column, instance) {
    if (column.className === 'language') {
      return {
        'data-tip': `Click to sort the strings based on their '${column.id}' translations.`,
      };
    }

    if (column.id === 'description') {
      return {
        'data-tip': 'Click to sort the strings based on their descriptions.',
      };
    }
    return {};
  }

  /*
   * utility-method used by getTdProps() for the language-columns.
   */
  getTdPropsForLanguageColumn(state, rowInfo, column, instance) {
    const item = rowInfo.row._original;
    const language = column.id;
    let value = item.overrides[language];
    let className;
    if (value !== undefined) {
      className = 'override';
    } else {
      // fallback to the default value
      className = 'default';
      value = item.defaults[language];
    }
    if (value === undefined) {
      className = 'missing';
    }

    value = value || '';
    value = value.trim();

    let tooltip;
    if (className === 'missing') {
      tooltip = '<div>This value is missing! You should click it and enter a value.</div>';
    } else {
      if (className === 'default') {
        tooltip =
          '<div>The GDPR portal is using the default value of this string. Click on the string to enter your own custom value for the string.</div>';
      } else {
        tooltip =
          '<div>The GDPR portal is using a custom (user-defined) value of this string. Click on the string to edit it.</div>';
      }
      tooltip += `<br><div>The current value of the string looks like this:</div><div class="localization-string-value">${value}</div>`;
    }

    return {
      className,
      'data-tip': tooltip,
      onClick: this.createOpenEditDialogHandler(language, rowInfo, className, value),
    };
  }

  descriptionColumnfilterMethod(filter, row, column) {
    const filterValue = filter.value;
    if (!filterValue) {
      return true;
    }
    if (!row) {
      return true;
    }
    if (filterValue === '*') {
      return true;
    }

    const regExp = new RegExp(escapeRegExp(filterValue));

    const originalStringInfo = row._original;
    if (originalStringInfo) {
      // Search in the raw description string
      if (originalStringInfo.description) {
        if (originalStringInfo.description.search(regExp, 'i') !== -1) {
          return true;
        }
      }

      // Search in the localization_string_id, too.
      if (originalStringInfo.id) {
        if (originalStringInfo.id.search(regExp, 'i') !== -1) {
          return true;
        }
      }
    }

    return false;
  }

  languageColumnfilterMethod(filter, row, column) {
    const filterValue = filter.value;
    if (!filterValue) {
      return true;
    }
    if (!row) {
      return true;
    }
    if (filterValue === '*') {
      return true;
    }

    const rowValue = row[column.id];
    if (!rowValue) {
      return false;
    }
    const regExp = new RegExp(escapeRegExp(filterValue), 'i');
    if (rowValue.search(regExp) !== -1) {
      return true;
    }

    // Enable searching in the default text, too.
    const originalStringInfo = row._original;
    if (originalStringInfo && originalStringInfo.defaults) {
      const language = column.id;
      const defaultValue = originalStringInfo.defaults[language];
      if (defaultValue && defaultValue !== rowValue) {
        if (defaultValue.search(regExp) !== -1) {
          return true;
        }
      }
    }

    return false;
  }

  createOpenEditDialogHandler(language, rowInfo, className, value) {
    const openEditDialogHandlerForLanguage = (e, handleOriginal) => {
      // Display the edit-dialog
      const item = rowInfo.row;
      if (item) {
        this.setState({
          selectedLanguageString: {
            language,
            className,
            original: item._original,
          },
          editDialogValue: value,
          originalEditDialogValue: value,
        });
      }
      // IMPORTANT! React-Table uses onClick internally to trigger
      // events like expanding SubComponents and pivots.
      // By default a custom 'onClick' handler will override this functionality.
      // If you want to fire the original onClick handler, call the
      // 'handleOriginal' function.
      if (handleOriginal) {
        handleOriginal();
      }
    };
    return openEditDialogHandlerForLanguage;
  }

  closeEditDialog() {
    this.setState({ selectedLanguageString: null, editDialogValue: null });
  }

  revertEditDialog() {
    //this.setState({selectedLanguageString: null, editDialogValue: null});
    const selectedLanguageString = this.state.selectedLanguageString.original;
    const language = this.state.selectedLanguageString.language;
    const defaultValue = selectedLanguageString.defaults[language] || '';
    this.setState({ editDialogValue: defaultValue });
  }

  getEditDialogIfNeeded() {
    if (this.state.selectedLanguageString) {
      const selectedLanguageString = this.state.selectedLanguageString.original;
      const language = this.state.selectedLanguageString.language;
      const value = this.state.editDialogValue;
      const className = this.state.selectedLanguageString.className;
      const defaultValue = selectedLanguageString.defaults[language];

      let descriptionElement = null;
      if (selectedLanguageString.description) {
        descriptionElement = (
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: selectedLanguageString.description,
            }}
          />
        );
      }

      let currentStateElement = null;
      if (className === 'missing') {
        currentStateElement = (
          <span className={className}>
            This string is currently missing a translation for this language.
          </span>
        );
      } else if (className === 'default') {
        currentStateElement = (
          <span className={className}>
            This string is currently using the default translation for this language.
          </span>
        );
      } else if (className === 'override') {
        currentStateElement = (
          <span className={className}>
            This string is currently using a custom (user-defined) translation for this language.
          </span>
        );
      }

      return (
        <MaterialDialog
          className="edit-translation-dialog"
          header="Edit translation"
          onOverlayClick={this.closeEditDialog}
        >
          <div>
            You are editing the string '{selectedLanguageString.id}' for the language ''
            {language}
            '. {currentStateElement}
          </div>

          {descriptionElement}

          <textarea
            value={value}
            onChange={(event) => {
              event.preventDefault();
              this.setState({ editDialogValue: event.target.value });
            }}
          />

          <FormActions>
            <Button
              disabled={
                this.state.savingInProgress ||
                this.state.editDialogValue === this.state.originalEditDialogValue
              }
              onClick={this.saveEditDialog}
              title="Click to save the updated string value"
            >
              {this.state.savingInProgress ? 'Saving...' : 'Save'}
            </Button>
            <Button
              disabled={this.state.savingInProgress}
              onClick={this.closeEditDialog}
              title="Click to cancel the editing operation"
            >
              Cancel
            </Button>

            {className !== 'default' && (
              <Button
                disabled={this.state.savingInProgress}
                title="Click to revert back to the default value of this string."
                onClick={this.revertEditDialog}
                theme="danger"
              >
                Revert to default
              </Button>
            )}
          </FormActions>
        </MaterialDialog>
      );
    }
    // No localization string is selected, so don't display the edit-dialog.
    return null;
  }

  render() {
    if (this.state.downloadError) {
      return (
        <div>
          <div className="errorpanel">
            <h1>An error occurred</h1>
            <pre>{this.state.downloadError}</pre>
          </div>
        </div>
      );
    }

    if (!this.state.tableData) {
      return <LoadingPanel loadingMessage="Loading..." />;
    }

    let editDialog = this.getEditDialogIfNeeded();

    return (
      <Suspense fallback={<LoadingPanel />}>
        <main className="scrollArea">
          <h1 className="heading-section">GDPR Translations</h1>
          <p>
            This page lets you customize the various texts used in the GDPR portal. Use the
            filter-boxes to find the text you want to modify and click on the text to edit it.
          </p>

          <ReactTable
            className="localization-strings"
            data={this.state.tableData}
            columns={this.state.tableColumns}
            showPagination={false}
            defaultPageSize={this.state.tableData.length}
            getTdProps={this.getTdProps}
            getTheadThProps={this.getTheadThProps}
            filterable={true}
          />

          {editDialog}
          <ReactTooltip html={true} className="tooltip" />
        </main>
      </Suspense>
    );
  }
}

GdprTranslations.propTypes = {
  token: PropTypes.string.isRequired,
  databrowserURL: PropTypes.string.isRequired,
  datahubURL: PropTypes.string.isRequired,
  addToast: PropTypes.func.isRequired,
  default_language: PropTypes.string,
};

function mapStateToProps(state) {
  const subData = state.subscriptions.find((s) => s.id === state.subscription.id);
  const databrowser = find(subData.connections, (i) => i.type === 'databrowser');

  return {
    gdprMicroserviceURL: getGdprMsUrl(state),
    databrowserURL: get(databrowser, 'url'),
    token: state.subscription.token,
    datahubURL: state.subscription.url,
    subscriptionId: state.subscription.id,
    default_language: state.env['default-language'] || '',
  };
}

const mapDispatchToProps = (dispatch) => ({
  addToast: (message) => dispatch(toastAdded({ message, type: 'success' })),
  apologise: (message) => dispatch(apologise(message)),
  runPumpOperationWithoutErrorHandler: (id, operation, parameters) =>
    dispatch(PipeActions.runPumpOperationWithoutErrorHandler(id, operation, parameters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GdprTranslations);
