import React from 'react';
import moment from 'moment';
import { useTheme } from '@material-ui/styles';
import { PipeRuntime } from '../../types/types';
import WarningIcon from '../../images/icons/warning.svg';
import { PipeLastRunStatus } from './PipeLastRunStatus';

const stripErrorPrefixRegEx = new RegExp(/\n([\s\S]*)/);
const stripCompletedPrefixRegEx = new RegExp(/Completed OK ([\s\S]*)/);

export enum RuntimeStatus {
  NULL = 'null',
  TRUE = 'true',
  FALSE = 'false',
}

export const getRuntimeStatuses = (
  lastMessage: string | null,
  runtime: PipeRuntime,
  theme: any
) => {
  const successMatches = lastMessage && stripCompletedPrefixRegEx.exec(lastMessage);
  const failureMatches = lastMessage && stripErrorPrefixRegEx.exec(lastMessage);

  const successMessage = `${moment(runtime['last-run']).format('YYYY-MM-DD HH:mm')} - OK ${
    successMatches ? successMatches[1] : lastMessage
  }`;

  const failureShortMessage = `${moment(runtime['last-run']).format('YYYY-MM-DD HH:mm')} - ${
    failureMatches ? failureMatches[1] : lastMessage
  }`;

  const lastOriginalErrorMessage = runtime['last-original-error-message'];
  const failureLongMessage = lastOriginalErrorMessage
    ? `ERROR: ${lastMessage} Original error: ${lastOriginalErrorMessage}`
    : failureShortMessage;

  return {
    [RuntimeStatus.NULL]: {
      shortMessage: <span>&mdash;</span>,
      longMessage: 'No last run data found',
      icon: null,
    },
    [RuntimeStatus.TRUE]: {
      shortMessage: successMessage,
      longMessage: successMessage,
      icon: <span style={{ color: '#008580' }}>&#x25cf;&nbsp;</span>,
    },
    [RuntimeStatus.FALSE]: {
      shortMessage: failureShortMessage,
      longMessage: failureLongMessage,
      icon: (
        <WarningIcon className="last-run__error" style={{ color: theme.palette.warning.main }} />
      ),
    },
  };
};

const getRuntimeSuccess = (runtimeSuccess: null | true | false) => {
  if (runtimeSuccess === null) return RuntimeStatus.NULL;
  else if (runtimeSuccess) return RuntimeStatus.TRUE;
  return RuntimeStatus.FALSE;
};

export const PipeLastRunStatusContainer: React.FC<{
  type: string;
  runtime: PipeRuntime;
  subId: string;
  id: string;
  useLink: boolean;
}> = ({ type, runtime, subId, id, useLink = true }) => {
  const theme = useTheme();
  const lastMessage = runtime['last-message'];
  const hasNoExecutionDataset = runtime.success === null;
  const runtimeSuccess = getRuntimeSuccess(runtime.success);

  // noop pipes (http_endpoint, etc) doesn't have a schedule
  if (type === 'noop') {
    return null;
  }

  const runtimeStatuses = getRuntimeStatuses(lastMessage, runtime, theme);

  return (
    <PipeLastRunStatus
      shortMessage={runtimeStatuses[runtimeSuccess]['shortMessage']}
      longMessage={runtimeStatuses[runtimeSuccess]['longMessage']}
      icon={runtimeStatuses[runtimeSuccess]['icon']}
      hasNoExecutionDataset={hasNoExecutionDataset}
      subId={subId}
      id={id}
      useLink={useLink}
    />
  );
};
