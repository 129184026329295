import { Entity } from 'Types/entities.types';

type EntityNamespaceArgs = {
  entity: Entity;
  removeNamespaces: boolean;
};

export const getNamespaceFromEntity = (args: EntityNamespaceArgs) => {
  const { entity: entities, removeNamespaces } = args;

  const splitNamespace = entities?._id.split(':');
  const noNamespace = removeNamespaces && splitNamespace.length === 1;

  return noNamespace ? entities._id : splitNamespace[0];
};
