import React from 'react';
import List from '@material-ui/core/List';

import SearchResultSkeleton from './SearchResultSkeleton';

const SearchResultSkeletonList: React.FC = () => {
  return (
    <List disablePadding>
      {Array.from({ length: 10 }, () => (
        <SearchResultSkeleton />
      ))}
    </List>
  );
};

export default SearchResultSkeletonList;
