import React, { useEffect, useState, createContext, useContext, useCallback } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import produce from 'immer';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import get from 'lodash/get';

import ConfigStateRenderer from 'Common/table-list-renderers/config-state';
import { getEntitiesPerSecond } from 'Internals/datasets';
import { getPipeQueueSize, pipeConfigSorter, getSinkDataset } from 'Internals/pipes';
import {
  sortWithNullOrUndefinedValues,
  sortBooleanWithNullOrUndefinedValues,
} from 'Internals/sort';
import { findInternalOriginInFilter } from 'Internals/utils';
import PipeProgressRenderer from 'Common/table-list-renderers/pipe-progress';
import PipeStateRenderer from 'Common/table-list-renderers/pipe-state';
import PipeEntitiesPerSecondRenderer from 'Common/table-list-renderers/pipe-entities-per-second';
import InProgress from '../in-progress';
import PipeActions from 'Redux/thunks/pipes';
import { pipesSelector, leafPipesSelector } from 'Redux/selectors';
import InfoTooltip from 'Common/InfoTooltip/InfoTooltip';

import DatasetActions from 'Redux/thunks/datasets';
import PipesListPage from './pipes-list-page';
import SystemActions from 'Redux/thunks/systems';

import FilterTable, { getSortValues } from '../filter-table/FilterTable';
import State from 'Common/renderers/State/State';
import PipeLink from 'Common/Links/PipeLink';
import SesamLink from 'Common/Links/SesamLink';
import useLocalStorage from '../../hooks/useLocalStorage';

import { bytesToSize, withSeparators } from 'Internals/utils';

import Actions from './pipes-list-actions';
import Ellipsis from 'Common/Ellipsis';

import './style.css';
import { SubscriptionContext } from '../../pages/Subscription.page';
import { PipeNextRunStatus } from '../pipe-status/PipeNextRunStatus';
import { PipeLastRunStatusContainer } from '../pipe-status/PipeLastRunStatus.container';

// FIXME: Centralize constants
const LOCAL_STORAGE_KEY = 'sesam--data-table-3';

const filterLabelMapping = {
  _id: 'Pipe',
  name: 'Name',
  'config.effective.source.system': 'From',
  'config.effective.source.type': 'Source type',
  'config.effective.sink.system': 'To',
  'config.effective.sink.type': 'Sink type',
  direction: 'Direction',
  origin: 'Origin',
  'runtime.state': 'State',
  tags: 'Tags',
  pumpMode: 'Pump mode',
  global: 'Global',
  lastRunStatus: 'Last run status',
  configGroup: 'Config group',
  durable: 'Durable',
  tenant: 'Tenant',
  transforms: 'Transforms',
};

const emptyFilter = Object.freeze({
  _id: {
    selected: [],
    search: [],
  },
  name: {
    selected: [],
    search: [],
  },
  'config.effective.source.system': {
    selected: [],
    search: [],
  },
  'config.effective.source.type': {
    selected: [],
    search: [],
  },
  'config.effective.sink.system': {
    selected: [],
    search: [],
  },
  'config.effective.sink.type': {
    selected: [],
    search: [],
  },
  direction: {
    selected: [],
    search: [],
  },
  origin: {
    selected: [],
    search: [],
  },
  'runtime.state': {
    selected: [],
    search: [],
  },
  tags: {
    selected: [],
    search: [],
  },
  pumpMode: {
    selected: [],
    search: [],
  },
  global: {
    selected: [],
    search: [],
  },
  lastRunStatus: {
    selected: [],
    search: [],
  },
  configGroup: {
    selected: [],
    search: [],
  },
  durable: {
    selected: [],
    search: [],
  },
  tenant: {
    selected: [],
    search: [],
  },
  transforms: {
    selected: [],
    search: [],
  },
});

const defaultFilter = produce(emptyFilter, (draft) => {
  draft.origin.selected = ['user'];
});

const defaultVisibleCols = [
  'Pipe',
  'Enabled',
  'Progress',
  'Next run',
  'Last run',
  'Bytes used',
  'Config',
];

const defaultVisibleFacets = [
  '_id',
  'config.effective.source.system',
  'config.effective.sink.system',
  'origin',
];

const defaultSortBy = [
  {
    id: 'pipes-link',
    desc: false,
  },
];

function getObjectName(obj, system) {
  // The e value is the human-assigned "name" attribute in the source and sink
  // config. If this is set, we will use it verbatim.
  if (!obj) {
    return 'N/A';
  }

  if (obj.name) {
    return obj.name;
  }

  if (system) {
    const systemName = system.name || `${system._id} (${system.config.type})`;
    const systemSubComponent = obj.dataset || obj['system-subcomponent'] || obj.table || obj.url;
    if (systemSubComponent) {
      return `${systemSubComponent}@${systemName}`;
    }
  }

  // check if the sink/source is connected to a dataset
  const dataset = obj.dataset;
  if (dataset) {
    return `dataset:${dataset}`;
  }

  // Finally, fallback to raw sink and source ids if nothing else has been found.
  return obj._id;
}

function getFromName(source = {}, systems) {
  return getObjectName(source, systems[source.system]);
}

function getToName(sink = {}, systems) {
  return getObjectName(sink, systems[sink.system]);
}

function PipeFromCell({ cell: { value: sink } }) {
  const { systems } = useContext(PipesListContext);
  return getFromName(sink, systems) || '';
}

function PipeToCell({ cell: { value: sink } }) {
  const { systems } = useContext(PipesListContext);
  return getToName(sink, systems) || '';
}

function BytesUsedCell({ cell: { value } }) {
  const { showBytesExact, toggleShowBytesExact } = useContext(PipesListContext);
  return (
    <span
      onClick={() => toggleShowBytesExact()}
      title="Click to switch between representation"
      style={{ cursor: 'pointer' }}
    >
      {showBytesExact ? withSeparators(value) : bytesToSize(value)}
    </span>
  );
}

const PipesListContext = createContext({ datasets: {}, systems: {} });

const PipesList = (props) => {
  const {
    datasets,
    deletePipe,
    loadPipesForced,
    loadSystems,
    loadDatasets,
    leafPipes,
    pipes,
    pipesLoaded,
    systems,
    token,
    subUrl,
  } = props;

  const [showBytesExact, setShowBytesExact] = useLocalStorage(
    LOCAL_STORAGE_KEY,
    ['pipes', 'showBytesExact'],
    true
  );

  const [shouldLoadDatasets, setShouldLoadDataset] = useState(false);

  const toggleShowBytesExact = () => {
    setShowBytesExact(!showBytesExact);
  };

  const renderActions = (selectedIds, clearSelection) => {
    const selectedPipes = getSelectedPipes(selectedIds);
    const deletePipes = () => {
      onDelete(selectedIds);
      clearSelection();
    };
    return <Actions selectedPipes={selectedPipes} onDelete={deletePipes} />;
  };

  const getSelectedPipes = (selectedIds) => {
    return selectedIds.map((id) => props.pipesDict[id]).filter(Boolean);
  };

  const handleVisibleColsChanged = useCallback(
    (newCols) => {
      // in case that systems or datasets are not loaded already, unlikely
      if ((newCols.includes('From') || newCols.includes('To')) && !systems) {
        loadSystems();
      }
      if (
        newCols.includes('Populated') ||
        newCols.includes('Index w/o deleted') ||
        newCols.includes('Complete index') ||
        newCols.includes('Complete log')
      ) {
        if (!datasets) loadDatasets();
        setShouldLoadDataset(true);
      } else {
        setShouldLoadDataset(false);
      }
    },
    [loadSystems, loadDatasets, datasets, systems]
  );

  const [includeInternal, setIncludeInternal] = useState(false);
  const handleFilterChanged = useCallback(
    (filter) => {
      const found = findInternalOriginInFilter(filter);
      if (found) {
        if (!includeInternal) {
          setIncludeInternal(true);
          loadPipesForced(true);
          loadSystems(true);
        }
      } else {
        if (includeInternal) {
          setIncludeInternal(false);
          loadPipesForced();
          loadSystems();
        }
      }
    },
    [loadPipesForced, loadSystems, includeInternal]
  );

  const onDelete = (selectedIds) => selectedIds.forEach((pipeId) => deletePipe(pipeId));

  const curlString = `curl \
    -H "Authorization: bearer ${token}" \
    "${subUrl}/pipes`;

  const columns = [
    {
      Header: 'Pipe',
      id: 'pipes-link',
      fixed: true,
      width: 300,
      accessor: (d) => ({
        id: d._id,
        link: d.link,
        description: get(d, ['config', 'original', 'description']),
      }),
      Cell: ({ cell: { value } }) => (
        <div data-selenium="pipe-id" style={{ display: 'flex' }}>
          <Ellipsis>
            <PipeLink pipeId={value.link}>{value.id}</PipeLink>
          </Ellipsis>
          {value.description && (
            <InfoTooltip info={value.description} style={{ marginLeft: '5px' }} />
          )}
        </div>
      ),
      sortType: getSortValues((a, b) => sortWithNullOrUndefinedValues(a.id, b.id)),
    },
    {
      Header: 'Name',
      id: 'pipes-name',
      accessor: (d) => ({ name: d.name, link: d.link }),
      Cell: ({ cell: { value } }) => <PipeLink pipeId={value.link}>{value.name}</PipeLink>,
      sortType: getSortValues((a, b) => sortWithNullOrUndefinedValues(a.name, b.name)),
    },
    {
      Header: 'From',
      id: 'pipes-from',
      accessor: 'config.effective.source',
      Cell: PipeFromCell,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'To',
      id: 'pipes-to',
      accessor: 'config.effective.sink',
      Cell: PipeToCell,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Transforms',
      id: 'transforms',
      accessor: 'transforms',
      Cell: ({ cell: { value } }) => <span>{value}</span>,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Enabled',
      id: 'pipes-enabled',
      accessor: 'runtime[is-disabled]',
      align: 'center',
      width: 75,
      Cell: ({ cell: { value } }) =>
        !value ? (
          <State title="Pipe is enabled" className="status-green" text="Yes" />
        ) : (
          <State title="Pipe is disabled" className="status-red" text="No" />
        ),
      sortType: getSortValues((a, b) => sortBooleanWithNullOrUndefinedValues(!a, !b)),
    },
    {
      Header: 'State',
      id: 'pipes-state',
      accessor: 'runtime.state',
      Cell: ({ cell: { value } }) => <PipeStateRenderer state={value} />,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Global',
      id: 'pipes-global',
      accessor: 'global',
      align: 'center',
      width: 75,
      Cell: ({ cell: { value } }) =>
        value === 'Yes' ? (
          <State title="Pipe is a global" className="status-green" text="Yes" />
        ) : (
          <State title="Pipe is not a global" className="status-red" text="No" />
        ),
      sortType: getSortValues((a, b) => {
        const aBool = a === 'Yes';
        const bBool = b === 'Yes';
        return sortBooleanWithNullOrUndefinedValues(aBool, bBool);
      }),
    },
    {
      Header: 'Progress',
      id: 'pipes-progress',
      accessor: (d) => ({
        runtime: d.runtime,
        progress: get(d, 'runtime.progress'),
      }),
      Cell: ({ cell: { value } }) => (
        <PipeProgressRenderer runtime={value.runtime} progress={value.progress} />
      ),
      sortType: getSortValues((a, b) => {
        const aLastRun = get(a, 'progress["last-run"]');
        const bLastRun = get(b, 'progress["last-run"]');
        return sortWithNullOrUndefinedValues(aLastRun, bLastRun);
      }),
    },
    {
      Header: 'Entities/second',
      id: 'pipes-entities-per-second',
      accessor: 'runtime',
      Cell: ({ cell: { value } }) => <PipeEntitiesPerSecondRenderer runtime={value} />,
      sortType: getSortValues((a, b) => {
        const aEps = getEntitiesPerSecond(a);
        const bEps = getEntitiesPerSecond(b);

        return sortWithNullOrUndefinedValues(aEps, bEps);
      }),
    },
    {
      Header: 'Next run',
      id: 'pipes-next-run',
      accessor: 'runtime[next-run]',
      Cell: ({ cell: { value, row } }) => (
        <PipeNextRunStatus nextRun={value} type={get(row, 'original.config.effective.type')} />
      ),
      sortType: getSortValues((a, b) => {
        return sortWithNullOrUndefinedValues(new Date(a), new Date(b));
      }),
    },
    {
      Header: 'Last run',
      id: 'pipes-last-run',
      accessor: 'runtime',
      Cell: ({ cell: { value, row } }) => {
        const subId = useContext(SubscriptionContext);
        return (
          <PipeLastRunStatusContainer
            subId={subId}
            runtime={value}
            showMore={false}
            id={get(row, 'original.config.effective._id')}
            type={get(row, 'original.config.effective.type')}
          />
        );
      },
      sortType: getSortValues((a, b) => {
        const aLastRun = a['last-run'];
        const bLastRun = b['last-run'];
        return sortWithNullOrUndefinedValues(new Date(aLastRun), new Date(bLastRun));
      }),
    },
    {
      Header: 'Last seen',
      id: 'pipes-last-seen',
      accessor: 'runtime[last-seen]',
      Cell: ({ cell: { value } }) => <div>{isNil(value) ? '' : value}</div>,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Bytes used',
      id: 'pipes-bytes-used',
      accessor: (d) => (isObject(d.storage) ? d.storage.total : d.storage),
      align: 'right',
      Cell: BytesUsedCell,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Queues',
      id: 'pipes-queues',
      accessor: (p) => p,
      Cell: ({ cell: { value } }) => <div>{withSeparators(getPipeQueueSize(value))}</div>,
      sortType: getSortValues((a, b) => {
        const aQ = getPipeQueueSize(a);
        const bQ = getPipeQueueSize(b);

        return sortWithNullOrUndefinedValues(aQ, bQ);
      }),
    },
    {
      Header: 'Origin',
      id: 'pipes-origin',
      accessor: 'runtime.origin',
      Cell: ({ cell: { value } }) => <span>{value}</span>,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Config',
      id: 'pipes-config',
      accessor: (p) => p,
      align: 'center',
      Cell: ({ cell: { value } }) => <ConfigStateRenderer value={value} type="pipe" />,
      sortType: getSortValues(pipeConfigSorter),
    },
    {
      Header: 'Config group',
      id: 'pipes-config-group',
      accessor: 'configGroup',
      Cell: ({ cell: { value } }) => {
        return <span>{value}</span>;
      },
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Pump mode',
      id: 'pipes-mode',
      accessor: 'pumpMode',
      style: { textAlign: 'center' },
    },
    {
      Header: 'Populated',
      id: 'pipes-populated',
      align: 'center',
      accessor: (p) => getSinkDataset(p, datasets),
      width: 75,
      Cell: ({ cell: { value: sinkDataset } }) => {
        if (sinkDataset) {
          return get(sinkDataset, 'runtime.populated') ? (
            <State title="Pipe's sink dataset is populated" className="status-green" text="Yes" />
          ) : (
            <State title="Pipes's sink dataset is not populated" className="status-red" text="No" />
          );
        } else {
          return '-';
        }
      },
      sortType: getSortValues((a, b) => {
        const aSinkPopulated = get(a, 'runtime.populated');
        const bSinkPopulated = get(b, 'runtime.populated');

        return sortBooleanWithNullOrUndefinedValues(aSinkPopulated, bSinkPopulated);
      }),
    },
    {
      Header: 'Index w/o deleted',
      id: 'pipes-index-without-deleted',
      accessor: (p) => getSinkDataset(p, datasets),
      Cell: ({ cell: { value: sinkDataset } }) => {
        if (sinkDataset) {
          const count =
            sinkDataset.runtime['count-index-exists'] - sinkDataset.runtime['count-index-deleted'];
          return <span>{withSeparators(count)}</span>;
        } else {
          return '-';
        }
      },
      sortType: getSortValues((a, b) => {
        let aCount = a ? a.runtime['count-index-exists'] - a.runtime['count-index-deleted'] : null;
        let bCount = b ? b.runtime['count-index-exists'] - b.runtime['count-index-deleted'] : null;

        return sortWithNullOrUndefinedValues(aCount, bCount);
      }),
    },
    {
      Header: 'Complete index',
      id: 'pipes-complete-index',
      accessor: (p) => getSinkDataset(p, datasets),
      Cell: ({ cell: { value: sinkDataset } }) => {
        if (sinkDataset) {
          const count = sinkDataset.runtime['count-index-exists'];
          return <span>{withSeparators(count)}</span>;
        } else {
          return '-';
        }
      },
      sortType: getSortValues((a, b) => {
        let aCount = a ? a.runtime['count-index-exists'] : null;
        let bCount = b ? b.runtime['count-index-exists'] : null;

        return sortWithNullOrUndefinedValues(aCount, bCount);
      }),
    },
    {
      Header: 'Complete log',
      id: 'pipes-complete-log',
      accessor: (p) => getSinkDataset(p, datasets),
      Cell: ({ cell: { value: sinkDataset } }) => {
        if (sinkDataset) {
          const count = sinkDataset.runtime['count-log-exists'];
          return <span>{withSeparators(count)}</span>;
        } else {
          return '-';
        }
      },
      sortType: getSortValues((a, b) => {
        let aCount = a ? a.runtime['count-log-exists'] : null;
        let bCount = b ? b.runtime['count-log-exists'] : null;
        return sortWithNullOrUndefinedValues(aCount, bCount);
      }),
    },
    {
      Header: 'Leaf',
      id: 'pipes-leaf',
      align: 'center',
      accessor: (p) => p._id,
      width: 95,
      Cell: ({ cell: { value: pipeId } }) => {
        if (pipeId) {
          return leafPipes.includes(pipeId) ? (
            <State title="This pipe has no downstream pipes" className="status-red" text="Yes" />
          ) : (
            <State title="This pipe has downstream pipes" className="status-green" text="No" />
          );
        }
      },
      sortType: getSortValues((a, b) => {
        const aLeaf = leafPipes.includes(a);
        const bLeaf = leafPipes.includes(b);

        return sortBooleanWithNullOrUndefinedValues(aLeaf, bLeaf);
      }),
    },
    {
      Header: 'Completeness',
      id: 'pipes-completeness',
      accessor: (p) => ({
        sinkDataset: getSinkDataset(p, datasets),
        pipeId: p._id,
      }),
      Cell: ({ cell: { value } }) => {
        const subId = useContext(SubscriptionContext);
        if (value.sinkDataset) {
          const completeness = get(value.sinkDataset, ['runtime', 'completeness']);

          if (typeof completeness === 'number') {
            const completenessRelative = moment(completeness / 1000).fromNow();
            const completenessTotal = moment(completeness / 1000).format('do MMM YYYY - HH:mm:ss');
            return (
              <Tooltip title={completenessTotal}>
                <SesamLink
                  to={`/subscription/${subId}/pipes/pipe/${
                    value.pipeId
                  }/output?jtype=updated&jval=${completeness / 1000}`}
                >
                  {completenessRelative}
                </SesamLink>
              </Tooltip>
            );
          }
        }
        return '-';
      },
      sortType: getSortValues((a, b) => {
        let aCount = a.sinkDataset ? a.sinkDataset.runtime['completeness'] : null;
        let bCount = b.sinkDataset ? b.sinkDataset.runtime['completeness'] : null;
        return sortWithNullOrUndefinedValues(aCount, bCount);
      }),
    },
    {
      Header: 'Durable',
      id: 'durable-pipe',
      accessor: 'durable',
      align: 'center',
      width: 75,
      Cell: ({ cell: { value } }) =>
        value === 'Yes' ? (
          <State title="Pipe is durable" className="status-green" text="Yes" />
        ) : (
          <State title="Pipe is not durable" className="status-red" text="No" />
        ),
      sortType: getSortValues((a, b) => {
        const aBool = a === 'Yes';
        const bBool = b === 'Yes';
        return sortBooleanWithNullOrUndefinedValues(aBool, bBool);
      }),
    },
    {
      Header: 'Tenant',
      id: 'tenant-pipe',
      accessor: 'tenant',
      align: 'center',
      width: 75,
      Cell: ({ cell: { value } }) =>
        value !== 'Not Tenant' ? (
          <State title="Tenant pipe" className="status-green" text={value} />
        ) : (
          <State title="Not Tenant pipe" className="status-red" text="Not Tenant" />
        ),
      sortType: getSortValues((a, b) => {
        const aBool = a !== 'Not Tenant';
        const bBool = b !== 'Not Tenant';
        return sortBooleanWithNullOrUndefinedValues(aBool, bBool);
      }),
    },
  ];

  if (!pipesLoaded) {
    return <InProgress />;
  }

  return (
    <PipesListContext.Provider
      value={{
        datasets,
        systems,
        showBytesExact,
        toggleShowBytesExact,
      }}
    >
      <PipesListPage includeInternal={includeInternal} shouldLoadDatasets={shouldLoadDatasets}>
        <FilterTable
          actionsRenderer={renderActions}
          defaults={{
            filter: defaultFilter,
            sortBy: defaultSortBy,
            visibleCols: defaultVisibleCols,
            visibleFacets: defaultVisibleFacets,
          }}
          emptyFilter={emptyFilter}
          filterLabelMapping={filterLabelMapping}
          columns={columns}
          itemsToFilter={pipes}
          tableKey="pipes"
          onVisibleColsChanged={handleVisibleColsChanged}
          onFilterChanged={handleFilterChanged}
          curl={curlString + `?include-internal-pipes=${includeInternal}"`}
        />
      </PipesListPage>
    </PipesListContext.Provider>
  );
};

PipesList.propTypes = {
  deletePipe: PropTypes.func.isRequired,
  loadPipes: PropTypes.func.isRequired,
  loadPipesForced: PropTypes.func.isRequired,
  loadSystems: PropTypes.func.isRequired,
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  leafPipes: PropTypes.arrayOf(PropTypes.string).isRequired,
  pipes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pipesDict: PropTypes.shape({}),
  pipesLoaded: PropTypes.bool.isRequired,
  systems: PropTypes.shape({}).isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,

  loadDatasets: PropTypes.func.isRequired,
  datasets: PropTypes.shape({}).isRequired,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  pipes: pipesSelector(state),
  leafPipes: leafPipesSelector(state),
  pipesDict: state.pipes,
  datasets: state.datasets,
  pipesLoaded: state.loadStatus.pipesLoaded,
  systemsLoaded: state.loadStatus.systemsLoaded,
  datasetsLoaded: state.loadStatus.datasetsLoaded,
  systems: state.systems,
  subUrl: state.subscription.url,
  token: state.subscription.token,
});

const mapDispatchToProps = (dispatch) => ({
  deletePipe: (id) => dispatch(PipeActions.delete(id)),
  loadPipes: (loadInternal) => {
    dispatch(PipeActions.loadAll(loadInternal));
  },
  loadPipesForced: (loadInternal) => {
    dispatch(PipeActions.loadAllForced(loadInternal));
  },
  loadSystems: (loadInternal) => {
    dispatch(SystemActions.loadAll(loadInternal));
  },
  loadDatasets: (loadInternal) => {
    dispatch(DatasetActions.loadAll(loadInternal));
  },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PipesList);
