import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import sortBy from 'lodash/sortBy';

import DatasetsAPI from '../../../api/datasets';

import DatasetActions from 'Redux/thunks/datasets';
import DataTable from '../../../components/data-table';
import IndexPreview from '../../../components/index-preview';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';

const getIndexedPercentage = (idx) => {
  if (idx && idx.progress) {
    return `${Math.round((100 * idx.progress.indexed) / idx.progress.total)}%`;
  } else {
    return `-`;
  }
};

const BareDatasetIndexes = ({
  getDatasetIndexes,
  params,
  registerRefresh,
  subUrl,
  token,
  unregisterRefresh,
}) => {
  const [indexes, setIndexes] = useState([]);
  const [rowData, setRowData] = useState({});
  const [toDelete, setToDelete] = useState(null);

  const ref = useRef();
  const deleteIndex = (dataset, id) => {
    return DatasetsAPI.removeIndexes({ subUrl, token }, dataset, id);
  };

  const refreshIndexes = () => {
    getDatasetIndexes(params.datasetID).then((indexes) => {
      setIndexes(indexes);
      setRowData({});
    });
  };

  const handleDeleteClick = (dataset, id, status) => {
    if (
      confirm(
        `Are you sure you want to ${status === 'orphaned' ? 'delete' : 'rebuild'} this index?`
      )
    ) {
      setToDelete({ dataset, id });
    }
  };

  useEffect(() => {
    ref.current = refreshIndexes;
    refreshIndexes();
    registerRefresh(ref.current);
    return () => {
      unregisterRefresh(ref.current);
    };
  }, [params.datasetID]);

  useEffect(() => {
    if (toDelete) {
      deleteIndex(toDelete.dataset, toDelete.id).then(() => {
        refreshIndexes();
        setToDelete(null);
      });
    }
  }, [toDelete]);

  return (
    <ErrorBoundary>
      <main className="scrollArea">
        <div className="row">
          <div className="col gr-equal">
            <p>
              DTL require indexes when pipes join against this dataset with hops. The datasets are
              indexed asynchronously. This page lists all the indexes for this dataset and the
              status for each index. The expression column is the expression that is used in the DTL
              to compute the index value.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col col--elastic gr-primary">
            <DataTable
              id="dataset-indexed"
              data={sortBy(indexes, 'expression')}
              dataKey="expression"
              cols={[
                { header: 'Expression', data: 'expression', fixed: true },
                { header: 'Status', type: 'short-text', data: 'status' },
                {
                  header: 'Remaining',
                  type: 'number',
                  data: 'progress.remaining',
                },
                {
                  header: 'Indexed',
                  type: 'number',
                  data: getIndexedPercentage,
                },
                {
                  header: 'Version',
                  type: 'number',
                  data: 'version',
                },
                { header: 'Used in pipes', data: 'used-by-pipes' },
              ]}
              selectableCols
              title="Indexes"
              onRowClick={(data) => setRowData(data)}
            />
          </div>
          <div className="col gr-secondary">
            <IndexPreview data={rowData} onDeleteClick={handleDeleteClick} />
          </div>
        </div>
      </main>
    </ErrorBoundary>
  );
};

BareDatasetIndexes.propTypes = {
  getDatasetIndexes: PropTypes.func.isRequired,
  params: PropTypes.shape({
    datasetID: PropTypes.string.isRequired,
  }),
  registerRefresh: PropTypes.func,
  subId: PropTypes.string.isRequired,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  unregisterRefresh: PropTypes.func,
};

const mapStateToProps = (state) => ({
  subId: state.subscription.id,
  subUrl: state.subscription.url,
  token: state.subscription.token,
});

const mapDispatchToProps = (dispatch) => ({
  getDatasetIndexes: (datasetId) => dispatch(DatasetActions.getDatasetIndexes(datasetId)),
});

export const DatasetIndexes = connect(mapStateToProps, mapDispatchToProps)(BareDatasetIndexes);
