import PropTypes from 'prop-types';
import React from 'react';

import MenuToggler from '../menu-toggler';
import { MenuAction } from '../menu';

import TrashIcon from '../../images/icons/trash.svg';

import './FilterSelectorStyle.css';

const FilterSelector = ({ filters, onFilterClick, onFilterDeleteClick }) => {
  return (
    <div className="filter-selector">
      <MenuToggler
        label="Saved filters"
        labelClassName="btn btn--dropdown"
        closeOnClickOutside={true}
        closeOnEsc={true}
        closeOnInteraction={true}
      >
        {Object.keys(filters).length < 1 && (
          <span className="filter-selector__empty">No filters saved</span>
        )}
        {Object.keys(filters).map((key) => {
          return (
            <MenuAction key={key} label={key} onClick={() => onFilterClick(filters[key])}>
              <div
                className="filter-selector__delete-filter"
                onClick={(e) => {
                  e.stopPropagation();
                  onFilterDeleteClick(key);
                }}
              >
                <TrashIcon />
              </div>
            </MenuAction>
          );
        })}
      </MenuToggler>
    </div>
  );
};

FilterSelector.propTypes = {
  filters: PropTypes.shape({}),
  onFilterClick: PropTypes.func,
  onFilterDeleteClick: PropTypes.func,
};

export default FilterSelector;
