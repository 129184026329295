import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import JsonPanel from './JsonPanel';
import JsonCompare from './JsonCompare';

import SesamCheckboxField from './SesamCheckboxField/SesamCheckboxField';

import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';

import { withSeparators } from 'Internals/utils';

import './ComparePanel.css';

const STORAGE_KEY = 'sesam--hide-system-attrs';
const STORAGE_KEY_WORD_WRAP = 'sesam--word-wrap';
const STORAGE_KEY_FORMAT_TRACEBACK = 'sesam--format-traceback';

/*
 * Panel that compares two entities from a dataset.
 */
class ComparePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: getFromLocalStorage(STORAGE_KEY, undefined, true),
      wordWrap: getFromLocalStorage(STORAGE_KEY_WORD_WRAP, undefined, true),
      formatTraceback: getFromLocalStorage(STORAGE_KEY_FORMAT_TRACEBACK, undefined, false),
    };

    this.onToggleFilter = () => {
      setIntoLocalStorage(STORAGE_KEY, undefined, !this.state.filter);
      this.setState({
        filter: !this.state.filter,
      });
    };

    this.onToggleWordWrap = () => {
      setIntoLocalStorage(STORAGE_KEY_WORD_WRAP, undefined, !this.state.wordWrap);
      this.setState({
        wordWrap: !this.state.wordWrap,
      });
    };

    this.onToggleFormatTraceback = () => {
      setIntoLocalStorage(STORAGE_KEY_FORMAT_TRACEBACK, undefined, !this.state.formatTraceback);
      this.setState({
        formatTraceback: !this.state.formatTraceback,
      });
    };
  }

  render() {
    if (!this.props.original) {
      return null;
    }

    const toMoment = (unixtime) => moment(unixtime / 1000);

    const ago = (ts) => `${moment.duration(moment().diff(ts)).humanize()}`;
    const timestamp = (ts) => ts.format('DD.MM.YYYY HH:mm:ss');

    const stat = (e) => {
      const selectedTs = toMoment(e._ts);
      return (
        <span>
          Sequence #{withSeparators(e._updated)}
          &nbsp;
          {e._deleted ? 'deleted' : 'updated'}
          &nbsp;
          <span title={timestamp(selectedTs)}>
            {ago(selectedTs)} ago{' '}
            <span className="comparePanel__timestamp">{timestamp(selectedTs)}</span>
          </span>
        </span>
      );
    };

    return (
      <div className="comparePanel stretchy-panel">
        <div className="header">
          {this.props.compare ? (
            <span>
              <span className="left">{stat(this.props.value, 'right')}</span>
              <span className="right">{stat(this.props.original, 'left')}</span>
            </span>
          ) : (
            <span className="left single">{stat(this.props.value, 'left')}</span>
          )}
          <div className="clearer" />
        </div>
        <div className="body">
          {this.props.compare ? (
            <JsonCompare
              original={{
                data: this.props.original,
                key: this.props.original['_updated'],
              }}
              value={{
                data: this.props.value,
                key: this.props.value['_updated'],
              }}
              filter={this.state.filter}
            />
          ) : (
            <JsonPanel
              rawJson={this.props.value}
              filter={this.state.filter}
              wordWrap={this.state.wordWrap}
              formatTraceback={this.state.formatTraceback}
            />
          )}
        </div>
        <div className="footer">
          <div>
            <SesamCheckboxField
              label="Show system attributes"
              CheckboxProps={{
                checked: !this.state.filter,
                onChange: this.onToggleFilter,
                size: 'small',
              }}
            />
            {this.props.original['traceback'] && (
              <SesamCheckboxField
                label="Format traceback"
                CheckboxProps={{
                  checked: this.state.formatTraceback,
                  onChange: this.onToggleFormatTraceback,
                  size: 'small',
                }}
              />
            )}
          </div>
          <SesamCheckboxField
            label="Toggle word wrap"
            CheckboxProps={{
              checked: this.state.wordWrap,
              onChange: this.onToggleWordWrap,
              size: 'small',
            }}
          />
        </div>
      </div>
    );
  }
}

ComparePanel.propTypes = {
  original: PropTypes.shape({
    _updated: PropTypes.number.isRequired,
    _deleted: PropTypes.bool,
  }),
  value: PropTypes.shape({
    _updated: PropTypes.number.isRequired,
    _deleted: PropTypes.bool,
  }),
  compare: PropTypes.bool.isRequired,
};

export default ComparePanel;
