import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';

import Button from 'Common/Button/Button';
import { LoadingPanel } from 'Common/LoadingPanel';
import SystemActions from 'Redux/thunks/systems';
import { getApiConf } from 'Redux/thunks/subscriptions';
import InfoTooltip from 'Common/InfoTooltip/InfoTooltip';

import NotFound from '../../../components/not-found';
import Page from '../../../components/page';
import PageHeader, { PageHeaderGroup, PageHeaderTitle } from '../../../components/page-header';
import SystemActionsMenu from '../../../components/system-actions-menu';
import TabbedNav from '../../../components/tabbed-nav';
import Feedback from '../../../components/feedback';
import SystemsAPI from '../../../api/systems';

class System extends React.Component {
  constructor(props) {
    super(props);
    this.registeredRefreshes = [];

    this.onDelete = (systemId) => {
      this.props
        .delete(systemId)
        .then(() => this.context.router.push(`/subscription/${this.props.subId}/systems`));
    };

    this.refresh = () => {
      this.setState({
        restartFeedback: null,
      });

      this.registeredRefreshes.forEach((cb) => cb());
    };

    this.triggerRestart = () => {
      this.setState({
        restarting: true,
        restartFeedback: null,
      });

      SystemsAPI.reload(this.props.apiConf, this.props.system._id)
        .then(() =>
          this.setState({
            restarting: false,
            restartFeedback: 'Restarting, check status',
            restartFeedbackType: 'warning',
          })
        )
        .catch(() =>
          this.setState({
            restarting: false,
            restartFeedback: 'Failed to restart, check status',
            restartFeedbackType: 'error',
          })
        );
    };

    this.registerRefresh = (func) => this.registeredRefreshes.push(func);
    this.unregisterRefresh = (func) => {
      this.registeredRefreshes = this.registeredRefreshes.filter((c) => c !== func);
    };

    this.state = {
      cannotFindObject: false,
      restarting: false,
      restartFeedback: null,
    };
  }

  componentDidMount() {
    this.refresh(this.props.params.systemID);
  }

  render() {
    const system = this.props.system;
    if (!system) return <LoadingPanel />;
    const title = (system && system.name) || this.props.params.systemID;
    let nav = [];

    if (system) {
      nav = [
        {
          label: 'Dashboard',
          to: `/subscription/${this.props.subId}/systems/system/${system.link}/dashboard`,
        },
        {
          label: 'Config',
          to: `/subscription/${this.props.subId}/systems/system/${system.link}/edit`,
        },
        {
          label: 'Permissions',
          to: `/subscription/${this.props.subId}/systems/system/${system.link}/permissions`,
        },
        {
          label: 'Status',
          to: `/subscription/${this.props.subId}/systems/system/${system.link}/status`,
        },
        {
          label: 'Graph',
          to: `/subscription/${this.props.subId}/systems/system/${system.link}/graph`,
        },
        {
          label: 'Overview',
          to: `/subscription/${this.props.subId}/systems/system/${system.link}/overview`,
        },
      ];

      if (this.props.featureLevel && this.props.featureLevel >= 2) {
        nav.splice(2, 0, {
          label: 'Secrets',
          to: `/subscription/${this.props.subId}/systems/system/${system.link}/secrets`,
        });
      }
    }

    const description = get(system, ['config', 'original', 'description']);

    return (
      <Page>
        <PageHeader>
          <PageHeaderTitle>
            <SesamLink to={`/subscription/${this.props.subId}/systems`}>Systems</SesamLink>
          </PageHeaderTitle>
          <PageHeaderTitle style={{ marginRight: '5px' }}>{title}</PageHeaderTitle>
          {description && <InfoTooltip info={description} style={{ marginRight: '5px' }} />}
          {system && (
            <PageHeaderGroup>
              <SystemActionsMenu
                onDelete={this.onDelete}
                subId={this.props.subId}
                system={system}
              />
            </PageHeaderGroup>
          )}
          <PageHeaderGroup>
            {system && system.type == 'system:microservice' && (
              <span>
                {this.state.restartFeedback && (
                  <Feedback type={this.state.restartFeedbackType}>
                    {this.state.restartFeedback}
                  </Feedback>
                )}
                <Button disabled={this.state.restarting} onClick={this.triggerRestart}>
                  Pull and restart
                </Button>
              </span>
            )}
            <Button theme="primary" onClick={() => this.refresh(this.props.params.systemID)}>
              Refresh
            </Button>
          </PageHeaderGroup>
        </PageHeader>
        <TabbedNav nav={nav}>
          {!this.state.cannotFindObject && !system && <LoadingPanel />}
          {this.state.cannotFindObject && (
            <NotFound
              link={`/subscription/${this.props.subId}/systems`}
              linkText="View systems"
              message="Maybe it hasn't been created yet, or you misspelled an ID?"
              title="System not found"
            />
          )}
          {!this.state.cannotFindObject &&
            system &&
            React.cloneElement(this.props.children, {
              registerRefresh: this.registerRefresh,
              unregisterRefresh: this.unregisterRefresh,
            })}
        </TabbedNav>
      </Page>
    );
  }
}

System.propTypes = {
  children: PropTypes.node,
  delete: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,
  params: PropTypes.shape({
    systemID: PropTypes.string.isRequired,
  }),
  subId: PropTypes.string.isRequired,
  system: PropTypes.object,
  apiConf: PropTypes.shape({}).isRequired,
  featureLevel: PropTypes.number,
};

System.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  featureLevel: get(state, 'subscription.info.status.feature-level'),
  subId: state.subscription.id,
  system: state.systems[ownProps.params.systemID],
  apiConf: getApiConf(state),
});

const mapDispatchToProps = (dispatch) => ({
  delete: (id) => dispatch(SystemActions.delete(id)),
  load: (id) => dispatch(SystemActions.load(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(System);
