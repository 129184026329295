import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withTheme } from '@material-ui/core';
import SesamToggleSwitch from '../SesamToggleSwitch';
import './style.css';

/*
 * Panel with title that has a switch
 */
function PanelWithSwitch(props) {
  const [collapsed, setCollapsed] = useState(props.startCollapsed);
  const className = collapsed ? 'panel-with-switch' : 'panel-with-switch show';
  return (
    <div
      className={className}
      style={{
        backgroundColor: props.theme.palette.background.light,
        border: `1px solid ${props.theme.palette.divider}`,
      }}
    >
      <h2
        className="header"
        // onClick={() => setCollapsed((_collapsed) => !_collapsed)}
      >
        {props.title}
        <SesamToggleSwitch
          leftLabel={props.isOn ? 'Enabled' : 'Disabled'}
          rightLabel=""
          isOn={props.isOn}
          onToggle={(e) => {
            const checked = e.target.checked;
            props.handleChange({ target: { name: props.name, value: checked } });
          }}
          style={{ float: 'right' }}
        />
      </h2>
      <div className="panel" style={{ backgroundColor: props.theme.palette.background.semilight }}>
        {props.children}
      </div>
    </div>
  );
}

PanelWithSwitch.defaultProps = {
  startCollapsed: true,
  title: 'Title of collapsible panel',
};

PanelWithSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  startCollapsed: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isOn: PropTypes.bool.isRequired,
  children: PropTypes.node,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      background: PropTypes.shape({
        light: PropTypes.string,
        blueish: PropTypes.string,
        semilight: PropTypes.string,
      }),
      divider: PropTypes.string,
      primary: PropTypes.shape({ main: PropTypes.string }),
      text: PropTypes.shape({ primary: PropTypes.string }),
    }),
  }),
};

export default withTheme(PanelWithSwitch);
