import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import PasswordMeterTextField from 'Common/PasswordMeterTextField/PasswordMeterTextField';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import Feedback from '../components/feedback';
import FocusPanel from '../components/focus-panel';
import Actions from 'Redux/thunks/auth';
import CenteredLayout from '../layout/centered';

const minPasswordLength = 12;

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.handleFieldChange = (ev) => {
      this.setState({ [ev.target.id]: ev.target.value });
    };

    this.handlePasswordChange = (ev) => {
      this.handleFieldChange(ev);
      this.setState({ passwordChanged: true });
    };

    this.resetPassword = (e) => {
      e.preventDefault();
      const notValid =
        !this.state.password ||
        this.state.password.length < minPasswordLength ||
        this.state.password !== this.state.confirmPassword;

      this.setState({
        notValid,
      });
      if (!notValid) {
        this.setState({
          pleaseWait: true,
        });
        this.props
          .resetPassword(this.props.location.query.token, this.state.password)
          .then(() => {
            this.setState({
              password: '',
              confirmPassword: '',
              pleaseWait: false,
            });
          })
          .catch(() => {
            this.setState({
              pleaseWait: false,
            });
          });
      }
    };

    this.state = {
      confirmPassword: '',
      notValid: false,
      password: '',
      passwordChanged: false,
      pleaseWait: false,
    };
  }

  render() {
    return (
      <CenteredLayout>
        <FocusPanel>
          <h2 className="heading-page">Reset password</h2>
          {!this.props.location.query.token && (
            <div>
              <p>You can only access this page by using a password reset link.</p>
              <SesamLink to="/auth/login">Back to login</SesamLink>
            </div>
          )}
          {this.props.location.query.token && !this.props.passwordReset && (
            <Form onSubmit={this.resetPassword}>
              <PasswordMeterTextField
                margin="normal"
                id="password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
                label="New password"
                minLength={minPasswordLength}
              />

              <SesamTextField
                id="confirmPassword"
                margin="normal"
                type="password"
                label="Confirm new password"
                value={this.state.confirmPassword}
                onChange={this.handleFieldChange}
              />

              <FormActions>
                <SesamLink to="/auth/login">Back to login</SesamLink>
                <Button type="submit" disabled={this.state.pleaseWait}>
                  {this.state.pleaseWait ? 'Sending email...' : 'Reset password'}
                </Button>
              </FormActions>
              {this.state.notValid && (
                <Feedback type="warning">
                  Password is required and it must be repeated correctly. The minimum length of the
                  password must be at least 12 characters.
                </Feedback>
              )}
              {this.props.invalidToken && (
                <Feedback type="warning">Password reset token is invalid</Feedback>
              )}
            </Form>
          )}
          {this.props.location.query.token && this.props.passwordReset && (
            <div>
              <p>Your password has been reset.</p>
              <SesamLink to="/auth/login">Back to login</SesamLink>
            </div>
          )}
        </FocusPanel>
      </CenteredLayout>
    );
  }
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  passwordReset: PropTypes.bool,
  location: PropTypes.shape({
    query: PropTypes.shape({
      token: PropTypes.string,
    }).isRequired,
  }).isRequired,
  invalidToken: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    passwordReset: state.user.passwordReset,
    invalidToken: state.user.passwordResetInvalidToken,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (token, password) => dispatch(Actions.resetPassword(token, password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
