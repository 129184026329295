import { sfetchText } from 'Internals/sfetch';

async function loadKey(portalUrl: string) {
  const requestOptions: RequestInit = {
    credentials: 'include',
  };
  return await sfetchText(`${portalUrl}/payment/stripe_publishable_api_key`, requestOptions);
}

export default {
  loadKey,
};
