import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import startsWith from 'lodash/startsWith';
import endsWith from 'lodash/endsWith';
import qs from 'qs';
import clamp from 'lodash/clamp';
import { useSelector } from 'react-redux';

import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';
import { getSourceDatasetIds, getUpstreamPipeFromDatasetId } from 'Internals/pipes';
import sfetch from 'Internals/sfetch';
import { apiConfSelector } from 'Redux/selectors';
import { getType } from 'Internals/datasets';
import DatasetAPI from '../../api/datasets';
import EntityAPI from '../../api/entities';
import PipesAPI from '../../api/pipes';

import reducer, { initialState } from './reducer';
import SearchWorker from './search.worker';
import { PostEntitiesModalThunk } from 'Redux/thunks/postEntitiesModal.thunks';
import { store } from 'Internals/store';
import { getNamespaceFromEntity } from 'Internals/repostDeadEntities.utils';
import { DatasetInspectorFilters } from 'Types/enums';

const LOCAL_STORAGE_KEY = 'sesam--entityview-filter';
const SEARCH_TYPE_KEY = 'searchType';
const JUMP_TYPE_KEY = 'jumpType';
const FILTER_KEY = 'filter';

const LOAD_MORE_TOP_BUFFER = 10;
const DEFAULT_NUM_ITEMS = 30;
let searchWorker;

function terminateSearchWorker(sw) {
  if (sw) {
    sw.terminate();
  }
}

/**
 * Helper function to find an entity to select (jump to) in an array of entities
 * Jump to offset or timestamp
 * @param entities
 * @param offsetOrTimestamp
 * @param useOffset
 * @returns {null|object} entity to select
 */
function findEntity(entities, offsetOrTimestamp, useOffset) {
  function createFindEntityByProperty(property) {
    return function _findEntity(entities, start, end, value, iterations = 100) {
      if (iterations < 1) {
        return entities[0];
      }
      if (end - start === 0) {
        // leaves only one entity
        return entities[end];
      }
      if (end - start === 1) {
        // two entities left
        if (entities[end][property] === value) {
          return entities[end];
        } else {
          return entities[start];
        }
      }
      const middle = Math.floor((end + start) / 2);
      const currentEntity = entities[middle];
      if (currentEntity[property] === value) {
        return currentEntity;
      }
      if (currentEntity[property] < value) {
        return _findEntity(entities, start, middle, value, iterations - 1);
      } else if (currentEntity[property] > value) {
        return _findEntity(entities, middle + 1, end, value, iterations - 1);
      }
    };
  }

  const L = entities.length;
  if (L < 1) {
    return null;
  }
  if (L === 1) {
    return entities[0];
  }
  if (useOffset) {
    let offset = offsetOrTimestamp;
    // clamp offset to min/max offset values in entity array
    // remember array is sorted descending on _updated
    offset = clamp(offset, entities[L - 1]._updated, entities[0]._updated);
    const findByOffset = createFindEntityByProperty('_updated');
    return findByOffset(entities, 0, L - 1, offset);
  } else {
    let timestamp = parseInt(offsetOrTimestamp * 1000);
    timestamp = clamp(timestamp, entities[L - 1]._ts, entities[0]._ts);
    const findByTs = createFindEntityByProperty('_ts');
    return findByTs(entities, 0, L - 1, timestamp);
  }
}

export const useDatasetInspectorState = ({
  router,
  datasetID,
  originalDataset,
  dataset,
  loadDataset,
  params,
  pipes,
  subUrl,
  token,
  upstreams,
  datasetsLoaded,
  apologise,
  subset,
  targetDatasetId,
  persistFilter = true,
}) => {
  const datasetId = params.datasetID || datasetID;

  const datasetIdRef = useRef(datasetId);

  const apiConfBase = useSelector(apiConfSelector);

  const apiConf = useMemo(() => {
    return {
      ...apiConfBase,
      dataset: encodeURIComponent(datasetId),
    };
  }, [apiConfBase, datasetId]);

  const elasticSubsetFilter = useMemo(() => {
    /* 
      Turn a simple subset eq expr into an ES filter.
      ["eq", "_S.prop", 100] => prop=100
      ["eq", "_S.'prop.sub'", 100] => 'prop.sub'=100
      ["eq", "_S.prop.sub", 100] => null
      ["eq", "_S.'prop.sub'.sub", 100] => null
    */
    let prop;
    let val;

    if (Array.isArray(subset) && typeof subset[1] === 'string') {
      prop = subset[1];
      val = subset[2];
      if (startsWith(prop, '_S.')) {
        prop = prop.replace('_S.', '');
        if (prop.split('.').length === 1 || (startsWith(prop, "'") && endsWith(prop, "'"))) {
          return { term: { [prop]: val } };
        }
      }
    }
    return null;
  }, [subset]);

  const isDatasetEmpty = useMemo(() => {
    const logCount = get(dataset, ['runtime', 'count-log-exists']);
    return logCount === undefined ? undefined : logCount === 0;
  }, [dataset]);

  const upstreamPipeId = useMemo(() => {
    const upstreamPipe = getUpstreamPipeFromDatasetId(datasetId, pipes, upstreams);
    let sourceDatasetId = getSourceDatasetIds(upstreamPipe);
    if (sourceDatasetId && sourceDatasetId.length === 1) {
      sourceDatasetId = sourceDatasetId[0];
      const upUpstreamPipe = getUpstreamPipeFromDatasetId(sourceDatasetId, pipes, upstreams);
      if (upUpstreamPipe) return upUpstreamPipe._id;
    }
    return null;
  }, [datasetId, pipes, upstreams]);

  // TODO: Should use hook
  let persistedSearchType = getFromLocalStorage(LOCAL_STORAGE_KEY, SEARCH_TYPE_KEY, 'id');

  let persistedJumpType = getFromLocalStorage(LOCAL_STORAGE_KEY, JUMP_TYPE_KEY, 'sequence');

  let persistedFilter = !persistFilter
    ? DatasetInspectorFilters.LatestWithoutDeleted
    : getFromLocalStorage(
        LOCAL_STORAGE_KEY,
        FILTER_KEY,
        DatasetInspectorFilters.LatestWithoutDeleted
      );

  const query = router.location.query;
  const queryRef = useRef(query);

  let jumpTypeFromQuery;
  let jumpSequenceValueFromQuery = initialState.jumpSequenceValue;
  let jumpUpdatedValueFromQuery = initialState.jumpUpdatedValue;
  let searchTypeFromQuery;
  let searchValueFromQuery = initialState.searchValue;
  let deletedFromQuery = initialState.deleted;
  let historyFromQuery = initialState.history;

  const showingFilteredResults =
    (query.sval !== undefined && query.sval.trim() !== '') ||
    (query.jval !== undefined && query.jval.trim() !== '');

  /**
   * Parse the search and jump part of the URL query string,
   * and overwrite the persisted values from localStorage
   */
  if (Object.keys(query).length > 0) {
    jumpTypeFromQuery = query['jtype'];
    const jumpValue = parseInt(query['jval']);
    if (!isNaN(jumpValue)) {
      if (query['jtype'] === 'sequence') {
        jumpSequenceValueFromQuery = jumpValue;
      }
      if (query['jtype'] === 'updated') {
        jumpUpdatedValueFromQuery = jumpValue;
      }
    }
    searchTypeFromQuery = query['stype'];
    searchValueFromQuery = query['sval'];
    persistedFilter = query['filter'];
  }
  if (jumpTypeFromQuery) {
    persistedJumpType = jumpTypeFromQuery;
  }
  if (searchTypeFromQuery) {
    persistedSearchType = searchTypeFromQuery;
  }

  if (persistedFilter) {
    if (persistedFilter === DatasetInspectorFilters.CompleteLog) {
      deletedFromQuery = true;
      historyFromQuery = true;
    } else if (persistedFilter === DatasetInspectorFilters.LatestWithDeleted) {
      deletedFromQuery = true;
      historyFromQuery = false;
    } else if (persistedFilter === DatasetInspectorFilters.LatestWithoutDeleted) {
      deletedFromQuery = false;
      historyFromQuery = false;
    } else {
      deletedFromQuery = false;
      historyFromQuery = false;
    }
  }

  const uncommittedFromQuery = query['uncommitted'] === 'true' ? true : false;

  const persistedInitialState = {
    ...initialState,
    jumpType: persistedJumpType,
    jumpSequenceValue: jumpSequenceValueFromQuery,
    jumpUpdatedValue: jumpUpdatedValueFromQuery,
    searchType: persistedSearchType,
    searchValue: searchValueFromQuery || '',
    deleted: deletedFromQuery,
    history: historyFromQuery,
    uncommitted: uncommittedFromQuery,
  };

  const [state, dispatch] = useReducer(reducer, persistedInitialState);

  const filter = useMemo(() => {
    if (state.history && state.deleted) {
      return DatasetInspectorFilters.CompleteLog;
    } else if (!state.history && state.deleted) {
      return DatasetInspectorFilters.LatestWithDeleted;
    } else if (!state.history && !state.deleted) {
      return DatasetInspectorFilters.LatestWithoutDeleted;
    }
  }, [state.history, state.deleted]);

  const isFilterActive = useMemo(() => {
    const result =
      !isUndefined(state.jumpUpdatedValue) ||
      !isUndefined(state.jumpSequenceValue) ||
      state.searchValue.length > 0;

    return result;
  }, [state.jumpUpdatedValue, state.jumpSequenceValue, state.searchValue]);

  const postJson = useMemo(
    () => (state.postMultiple ? state.entitiesToPost : state.entityToPost),
    [state.postMultiple, state.entitiesToPost, state.entityToPost]
  );

  const selectedEntityIndex = useMemo(() => {
    if (state.entities && state.selectedEntity) {
      return state.entities.map((e) => e._updated).indexOf(state.selectedEntity._updated);
    } else {
      return -1;
    }
  }, [state.entities, state.selectedEntity]);

  const isSelectedEntityFirstEntity = useMemo(() => {
    return (selectedEntityIndex === 0 || selectedEntityIndex === -1) && !state.hasMoreTop;
  }, [selectedEntityIndex, state.hasMoreTop]);

  const isSelectedEntityLastEntity = useMemo(() => {
    return selectedEntityIndex === state.entities.length - 1 && !state.hasMore;
  }, [selectedEntityIndex, state.entities, state.hasMore]);

  const sourceEntityPath = useMemo(() => {
    if (state.selectedEntity) {
      if (upstreamPipeId) {
        return `/subscription/${params.subId}/pipes/pipe/${upstreamPipeId}/output?stype=id&sval=${state.selectedEntity._id}`;
      }
    }
    return null;
  }, [upstreamPipeId, params.subId, state.selectedEntity]);

  useEffect(() => {
    /**
     * Keep {state.jumpType} in local storage
     */
    setIntoLocalStorage(LOCAL_STORAGE_KEY, JUMP_TYPE_KEY, state.jumpType);
  }, [state.jumpType]);

  useEffect(() => {
    /**
     * Keep {state.searchType} in local storage
     */
    setIntoLocalStorage(LOCAL_STORAGE_KEY, SEARCH_TYPE_KEY, state.searchType);
  }, [state.searchType]);

  useEffect(() => {
    setIntoLocalStorage(LOCAL_STORAGE_KEY, FILTER_KEY, filter);
  }, [filter]);

  /**
   * Stop search worker when navigating away
   */
  useEffect(() => {
    const leaveHook = router.setRouteLeaveHook(router.routes[4], () =>
      terminateSearchWorker(searchWorker)
    );

    return () => {
      leaveHook();
    };
  });

  /**
   * This is to make sure we are working with the latest version of the dataset.
   */
  useEffect(() => {
    if (datasetsLoaded) {
      loadDataset();
    }
  }, [datasetsLoaded, loadDataset]);

  useEffect(() => {
    async function getDatasetIndexes() {
      const indexes = await DatasetAPI.getIndexes(apiConf, datasetId);
      if (Array.isArray(indexes) === true && indexes.length > 0) {
        dispatch({ type: 'setDatasetIndexes', payload: indexes });
      }
    }
    if (datasetId !== undefined && apiConf !== undefined) {
      getDatasetIndexes();
    }
  }, [apiConf, datasetId]);

  /**
   * After the dataset has been loaded, and initialized, we do an initial search.
   */
  const lastOffset = useMemo(() => {
    return get(dataset, ['runtime', 'last-offset']);
  }, [dataset]);

  useEffect(() => {
    async function lookForIndex(datasetId) {
      let index;
      try {
        index = await EntityAPI.lookupDatasetIndexInElasticSearch(
          apiConf,
          encodeURIComponent(datasetId)
        );
      } catch (e) {
        // No need to throw any error.
      } finally {
        dispatch({ type: 'triedLookingForElasticIndex' });
      }

      if (index) {
        dispatch({ type: 'elasticIndexFound' });
      }
    }

    if (dataset && !state.triedLookingForElasticIndex) {
      lookForIndex(dataset._id);
    }
  }, [dataset, state.triedLookingForElasticIndex]);

  const jumpValue = useMemo(() => {
    if (state.jumpType === 'sequence') {
      if (state.jumpSequenceValue === undefined || state.jumpSequenceValue > lastOffset) {
        return lastOffset;
      } else {
        return state.jumpSequenceValue;
      }
    } else {
      if (state.jumpUpdatedValue === undefined) {
        return Date.now();
      } else {
        return state.jumpUpdatedValue;
      }
    }
  }, [lastOffset, state.jumpSequenceValue, state.jumpType, state.jumpUpdatedValue]);

  const jump = useCallback(
    async (jumpValue) => {
      let response;
      if (state.jumpType === 'sequence') {
        response = await EntityAPI.jumpToOffset(
          apiConf,
          jumpValue,
          state.history,
          state.deleted,
          state.uncommitted,
          JSON.stringify(subset)
        );
      } else if (state.jumpType === 'updated') {
        const offset = await EntityAPI.jumpToTimestamp(
          apiConf,
          parseInt(jumpValue * 1000),
          state.deleted,
          state.history,
          state.uncommitted,
          JSON.stringify(subset)
        );
        response = await EntityAPI.jumpToOffset(
          apiConf,
          offset,
          state.deleted,
          state.history,
          state.uncommitted,
          JSON.stringify(subset)
        );
      }
      dispatch({
        type: 'jumpToOffset',
        payload: {
          entities: response.entities,
          pageSize: 50,
          hasMore: response.hasMore,
          hasMoreTop: response.hasMoreTop,
        },
      });
      if (!isEmpty(response.selectedEntity)) {
        selectEntity(response.selectedEntity);
      }
    },
    [apiConf, state.deleted, state.history, state.jumpType, state.uncommitted, subset]
  );

  const loadLatest = useCallback(
    async (args) => {
      const options = {
        deleted: args?.deleted ? args.deleted : state.deleted,
        history: args?.history ? args.history : state.history,
        reverse: true,
        uncommitted: state.uncommitted,
        limit: args?.limit ? args.limit : DEFAULT_NUM_ITEMS,
        subset: JSON.stringify(subset),
      };

      const finalOptions = args?.dead ? { ...options, dead: args.dead } : options;

      const entities = await EntityAPI.fetchEntities(apiConf, finalOptions);

      dispatch({
        type: 'loadEntities',
        payload: {
          entities,
          howMany: args?.limit ? args.limit : DEFAULT_NUM_ITEMS,
        },
      });
    },
    [apiConf, state.deleted, state.history, state.uncommitted, subset]
  );

  const searchElastic = useCallback(
    async (query, since) => {
      dispatch({
        type: 'searchStart',
        payload: since,
      });

      const options = {
        query,
        limit: DEFAULT_NUM_ITEMS,
        reverse: true,
        deleted: state.deleted,
        history: state.history,
      };

      if (since !== undefined && since !== null) {
        const response = await EntityAPI.jumpToOffset(
          apiConf,
          since,
          state.history,
          state.deleted,
          state.uncommitted,
          elasticSubsetFilter,
          query,
          'elasticsearch',
          encodeURIComponent(datasetId)
        );

        dispatch({
          type: 'jumpToOffset',
          payload: {
            entities: response.entities,
            pageSize: 50,
            hasMore: response.hasMore,
            hasMoreTop: response.hasMoreTop,
          },
        });
        if (!isEmpty(response.selectedEntity)) {
          selectEntity(response.selectedEntity);
        }
      } else {
        const entities = await EntityAPI.fetchEntitiesFromElasticSearch(
          apiConf,
          encodeURIComponent(datasetId),
          options
        );
        dispatch({
          type: 'loadEntities',
          payload: {
            entities,
            howMany: DEFAULT_NUM_ITEMS,
            since: since,
          },
        });
      }
    },
    [apiConf, datasetId, elasticSubsetFilter, state.deleted, state.history, state.uncommitted]
  );

  const searchFreetext = useCallback(
    async (query, since) => {
      if (searchWorker) {
        searchWorker.terminate();
      }
      searchWorker = new SearchWorker();
      searchWorker.onmessage = (e) => {
        const msgType = e.data.msgType;

        switch (msgType) {
          case 'completedBottom':
            dispatch({
              type: 'freetextCompletedBottom',
            });
            break;
          case 'completedTop':
            dispatch({
              type: 'freetextCompletedTop',
            });
            break;
          case 'entityAddBottom':
            dispatch({
              type: 'freetextAddEntityBottom',
              payload: e.data.entity,
            });
            break;
          case 'entityAddTop':
            dispatch({
              type: 'freetextAddEntityTop',
              payload: e.data.entity,
            });
            break;
          case 'pausedBottom':
            dispatch({
              type: 'freetextPauseBottom',
            });
            break;
          case 'pausedTop':
            dispatch({
              type: 'freetextPauseTop',
            });
            break;
          case 'progressUpdated':
            dispatch({
              type: 'freetextProgressUpdated',
              payload: e.data.progress,
            });
        }
      };

      /**
       * When searching for "key": "value" in free text, we need to
       * remove the whitespace after ":" to make it adhere to the
       * stringified json format
       */
      let strippedQuery;
      const match = /[^\\](": )/.exec(query);
      strippedQuery = match ? query.replace('": ', '":') : query;

      const initMessage = {
        backendUrl: apiConf.subUrl,
        datasetID: datasetId,
        deleted: state.deleted,
        history: state.history,
        msgType: 'init',
        pageSize: DEFAULT_NUM_ITEMS,
        search: strippedQuery,
        token: apiConf.token,
        uncommitted: state.uncommitted,
        since: since,
        subset,
      };

      dispatch({
        type: 'searchStart',
        payload: since,
      });

      searchWorker.postMessage(initMessage);
    },
    [apiConf, state.deleted, state.history, datasetId, state.uncommitted, subset]
  );

  const makeSearch = useCallback(
    async (jumpValue) => {
      if (state.searchType === 'freetext') {
        if (state.jumpType === 'sequence') {
          if (
            (state.elasticIndexFound && !subset) ||
            (state.elasticIndexFound && subset && elasticSubsetFilter)
          ) {
            searchElastic(state.searchValue, jumpValue);
          } else {
            searchFreetext(state.searchValue, jumpValue);
          }
        } else if (state.jumpType === 'updated') {
          let offset;

          if (
            (state.elasticIndexFound && !subset) ||
            (state.elasticIndexFound && subset && elasticSubsetFilter)
          ) {
            offset = await EntityAPI.getOffestByTimestampFromElastic(
              apiConf,
              encodeURIComponent(datasetId),
              state.searchValue,
              parseInt(jumpValue * 1000)
            );

            if (!offset) {
              const firstEntity = await EntityAPI.fetchEntitiesFromElasticSearch(
                apiConf,
                encodeURIComponent(datasetId),
                {
                  query: state.searchValue,
                  reverse: false,
                  limit: 1,
                  deleted: state.deleted,
                  history: state.history,
                }
              );

              offset = firstEntity[0]._updated;
            }

            searchElastic(state.searchValue, offset);
          } else {
            offset = await EntityAPI.jumpToTimestamp(
              apiConf,
              parseInt(jumpValue * 1000),
              state.deleted,
              state.history,
              state.uncommitted,
              JSON.stringify(subset)
            );

            searchFreetext(state.searchValue, offset);
          }
        }
      } else if (state.searchType === 'id') {
        const entities = await EntityAPI.loadEntitiesBySearch(
          apiConf,
          state.searchValue,
          DEFAULT_NUM_ITEMS,
          state.deleted,
          state.history
        );
        let entityToSelect;
        if (state.jumpUpdatedValue || state.jumpSequenceValue) {
          entityToSelect = findEntity(entities, jumpValue, state.jumpType === 'sequence');
        }

        dispatch({
          type: 'loadBySearch',
          payload: {
            entities,
            howMany: DEFAULT_NUM_ITEMS,
            selectedEntity: entityToSelect,
          },
        });
      }
    },
    [
      apiConf,
      datasetId,
      elasticSubsetFilter,
      searchElastic,
      searchFreetext,
      state.deleted,
      state.elasticIndexFound,
      state.history,
      state.jumpSequenceValue,
      state.jumpType,
      state.jumpUpdatedValue,
      state.searchType,
      state.searchValue,
      state.uncommitted,
      subset,
    ]
  );

  const populate = useCallback(() => {
    dispatch({ type: 'populateEntitiesStart' });
    if (isFilterActive) {
      terminateSearchWorker(searchWorker);

      if (jumpValue !== undefined) {
        if (state.searchValue.length > 0) {
          makeSearch(jumpValue);
        } else {
          jump(jumpValue);
        }
      }
    } else {
      loadLatest();
    }
  }, [isFilterActive, jump, jumpValue, loadLatest, makeSearch, state.searchValue]);

  const showingResults =
    state.showingFreetextResults || state.showingElasticResults || state.showingIdResults;

  useEffect(() => {
    if (datasetId !== datasetIdRef.current) {
      dispatch({ type: 'reset' });
    }
  }, [datasetId]);

  useEffect(() => {
    if (query !== queryRef.current) {
      dispatch({ type: 'setPersistedInitialState', payload: persistedInitialState });
      queryRef.current = query;
    }
  }, [query]);

  useEffect(() => {
    if (
      typeof datasetId === 'string' &&
      datasetId.length > 0 &&
      isDatasetEmpty === false &&
      state.entities.length === 0 &&
      !showingResults
    ) {
      populate();
    }
  }, [datasetId, isDatasetEmpty, populate, showingResults, state.entities.length]);

  useEffect(() => {
    if (isDatasetEmpty === true) {
      if (state.loading === true) {
        dispatch({ type: 'datasetIsEmpty' });
      }
    }
  });

  const loadMore = useCallback(async () => {
    dispatch({ type: 'loadMoreStart' });
    if (state.loadingMore === false) {
      if (state.showingFreetextResults) {
        if (searchWorker) {
          searchWorker.postMessage({
            msgType: 'next',
            // pageSize: DEFAULT_NUM_ITEMS,
            // since: state.since,
          });
        }
      } else if (state.showingElasticResults) {
        const options = {
          query: state.searchValue,
          limit: DEFAULT_NUM_ITEMS,
          since: state.since,
          reverse: true,
          deleted: state.deleted,
          history: state.history,
        };

        const entities = await EntityAPI.fetchEntitiesFromElasticSearch(
          apiConf,
          encodeURIComponent(datasetId),
          options
        );

        dispatch({
          type: 'loadMore',
          payload: {
            entities,
            howMany: DEFAULT_NUM_ITEMS,
          },
        });
      } else {
        try {
          const entities = await EntityAPI.fetchEntities(apiConf, {
            deleted: state.deleted,
            history: state.history,
            limit: DEFAULT_NUM_ITEMS,
            reverse: true,
            since: state.since,
            uncommitted: state.uncommitted,
            subset: JSON.stringify(subset),
          });

          dispatch({
            type: 'loadMore',
            payload: {
              entities,
              howMany: DEFAULT_NUM_ITEMS,
            },
          });
        } catch (err) {
          apologise(err);
        }
      }
    }
  }, [
    apiConf,
    apologise,
    datasetId,
    state.deleted,
    state.history,
    state.loadingMore,
    state.searchValue,
    state.showingElasticResults,
    state.showingFreetextResults,
    state.since,
    state.uncommitted,
    subset,
  ]);

  const loadMoreTop = useCallback(async () => {
    dispatch({ type: 'loadMoreTopStart' });
    if (state.loadingMoreTop === false) {
      if (state.showingFreetextResults) {
        if (searchWorker) {
          searchWorker.postMessage({
            msgType: 'nextTop',
            // pageSize: LOAD_MORE_TOP_BUFFER,
            // since: state.topSince,
          });
        }
      } else if (state.showingElasticResults) {
        const options = {
          query: state.searchValue,
          limit: DEFAULT_NUM_ITEMS,
          since: state.topSince,
          reverse: false,
          deleted: state.deleted,
          history: state.history,
        };

        const entities = await EntityAPI.fetchEntitiesFromElasticSearch(
          apiConf,
          encodeURIComponent(datasetId),
          options
        );

        dispatch({
          type: 'loadMoreTop',
          entities: entities.reverse(),
        });
      } else {
        const entities = await EntityAPI.fetchEntities(apiConf, {
          deleted: state.deleted,
          history: state.history,
          limit: LOAD_MORE_TOP_BUFFER,
          reverse: false,
          since: state.topSince,
          uncommitted: state.uncommitted,
          subset: JSON.stringify(subset),
        });

        dispatch({
          type: 'loadMoreTop',
          entities: entities.reverse(),
        });
      }
    }
  }, [
    apiConf,
    datasetId,
    state.deleted,
    state.history,
    state.loadingMoreTop,
    state.searchValue,
    state.showingElasticResults,
    state.showingFreetextResults,
    state.topSince,
    state.uncommitted,
    subset,
  ]);

  // TODO move this to useEffect
  const loadNextEntity = useCallback(() => {
    if (state.nextEntities.length === 0) return;
    const nextEntities = [...state.nextEntities];
    const next = Object.assign({}, nextEntities.pop());
    return EntityAPI.getEntity(apiConf, next.id, next.updated)
      .then((nextEntity) => {
        dispatch({
          type: 'loadNextEntity',
          nextEntity,
        });
      })
      .then(() => testPreviousEntity())
      .catch(apologise);
  }, [apiConf, apologise, state.nextEntities, testPreviousEntity]);

  // TODO move this to useEffect
  const loadPreviousEntity = useCallback(() => {
    return EntityAPI.getEntity(apiConf, state.previousEntity._id, state.previousEntity._previous)
      .then((previousEntity) =>
        dispatch({
          type: 'loadPreviousEntity',
          previousEntity,
        })
      )
      .catch(apologise);
  }, [apiConf, apologise, state.previousEntity]);

  const navigateNext = useCallback(() => {
    if (state.entities.length < 2) return;
    if (selectedEntityIndex < state.entities.length - 1) {
      selectEntity(state.entities[selectedEntityIndex + 1]);
    } else {
      dispatch({ type: 'loadMoreStart' });
    }
  }, [selectedEntityIndex, state.entities]);

  const navigatePrevious = useCallback(() => {
    if (state.entities.length < 2) return;
    if (selectedEntityIndex > 0) {
      selectEntity(state.entities[selectedEntityIndex - 1]);
    } else {
      dispatch({ type: 'loadMoreTopStart' });
    }
  }, [selectedEntityIndex, state.entities]);

  const postDeadEntity = useCallback(() => {
    let datasetType;
    if (dataset) {
      datasetType = getType(dataset._id);
    }

    if (datasetType === 'dead_letter_dataset' && originalDataset) {
      const deadEntity = state.selectedEntity.entity;

      dispatch({
        type: 'setPostTargetDatasetId',
        payload: originalDataset._id,
      });
      dispatch({ type: 'postSingleEntity', payload: deadEntity });
    }
  }, [dataset, originalDataset, state.selectedEntity]);

  const repostDeadEntities = useCallback(async () => {
    // 1. GET pipe configuration

    const latestEntities = await EntityAPI.fetchEntities(apiConf, {
      deleted: false,
      history: false,
    });

    let upstreamDatasetNamespace;
    let removeNamespaces = false;

    if (latestEntities?.length) {
      const pipe = await PipesAPI.get(apiConf, latestEntities[0]?.pipe);
      upstreamDatasetNamespace = pipe?.config?.effective?.source?.dataset;
      removeNamespaces = pipe?.config?.effective?.remove_namespaces;
    }

    const lastEntity = await EntityAPI.fetchEntities(
      {
        ...apiConfBase,
        dataset: encodeURIComponent(upstreamDatasetNamespace),
      },
      {
        reverse: true,
        limit: 1,
        deleted: false,
        history: false,
      }
    );

    let currentNamespace = '';

    if (lastEntity?.length && lastEntity.length > 0) {
      currentNamespace = getNamespaceFromEntity({
        entity: lastEntity[0],
        removeNamespaces,
      });
    }

    if (latestEntities?.length) {
      const originalEntities = latestEntities.map((entity) => {
        const id = entity.entity._id;
        const namespace = pipes[entity?.pipe]?.config?.effective?.source?.dataset;
        const sourceCompleteId = `${pipes[entity?.pipe]?.config?.effective?.source?.dataset}:${
          entity?.entity?._id
        }`;

        return {
          _id: entity._id,
          pipe: pipes[entity?.pipe],
          id,
          namespace,
          completeId: currentNamespace
            ? `${currentNamespace}:${entity?.entity?._id}`
            : sourceCompleteId,
        };
      });

      // 2. load original entities

      const promises = [];
      const errors = [];

      originalEntities?.forEach(async (entity) => {
        promises.push(
          EntityAPI.getEntity(
            {
              ...apiConfBase,
              dataset: encodeURIComponent(entity.namespace),
            },
            entity.completeId
          ).catch((err) => {
            const errorMessage =
              typeof err === 'string'
                ? err
                : `Failed to fetch ${entity.namespace} entity id: ${entity.completeId}`;
            errors.push(errorMessage);
            console.error(errorMessage, err);
          })
        );
      });

      if (originalEntities?.length > 0 && targetDatasetId) {
        dispatch({
          type: 'setPostTargetDatasetId',
          payload: targetDatasetId,
        });

        const enhancedEntities = [];

        Promise.all(promises).then((entities) => {
          entities.forEach((entity) => {
            if (entity?._id) {
              enhancedEntities.push(entity);
            }
          });

          if (errors.length) {
            store?.dispatch(PostEntitiesModalThunk.setErrorMessages(errors));
          }

          if (enhancedEntities && enhancedEntities.length > 0) {
            dispatch({ type: 'postMultipleEntities', payload: enhancedEntities });
          } else {
            apologise('No source entities found!');
          }
        });
      }
    }

    store?.dispatch(
      PostEntitiesModalThunk.setModalInfo(
        'Repost multiple entities upstream',
        `Reposting following entities to ${currentNamespace}`
      )
    );
  }, [dataset, originalDataset]);

  const postEntities = () => {
    dispatch({ type: 'postMultipleEntities', payload: [] });
  };

  const postReset = () => {
    dispatch({ type: 'postReset' });
  };

  const repost = () => {
    dispatch({ type: 'postSingleEntity', payload: state.selectedEntity });
  };

  const reset = async () => {
    dispatch({ type: 'reset' });
    terminateSearchWorker(searchWorker);
    resetQueryParameters();
  };

  const resetJump = () => {
    dispatch({ type: 'resetJumpValue' });
  };

  const resetPreviousEntity = () => {
    dispatch({ type: 'resetPreviousEntity' });
  };

  const resetQueryParameters = useCallback(() => {
    const query = {};
    if (router.location.query && router.location.query.path) {
      /**
       * In case there was already a path query in the original url
       * This is right now only for when we use the DatasetInspectorContainer in the FlowPage output
       * Without this the path of the flow gets removed
       */
      query.path = router.location.query.path;
    }

    router.replace({
      search: `?${qs.stringify(query)}`,
      pathname: location.pathname
        .split('/')
        .filter((c) => c !== 'unified')
        .join('/'),
    });
  }, [router]);

  const resetSearch = () => {
    dispatch({ type: 'resetSearchValue' });
  };

  const search = useCallback(() => {
    const query = Object.assign({}, qs.parse(router.location.query), {
      stype: state.searchType,
      sval: state.searchValue,
      jtype: state.jumpType,
      jval: state.jumpType === 'sequence' ? state.jumpSequenceValue : state.jumpUpdatedValue,
      uncommitted: state.uncommitted,
      filter,
    });

    if (router.location.query && router.location.query.path) {
      /**
       * In case there was already a path query in the original url
       * This is right now only for when we use the DatasetInspectorContainer in the FlowPage output
       * Without this the path of the flow gets removed
       */
      query.path = router.location.query.path;
    }

    router.replace({
      search: `?${qs.stringify(query)}`,
      pathname: location.pathname
        .split('/')
        .filter((c) => c !== 'unified')
        .join('/'),
    });
    populate();
  }, [
    populate,
    router,
    state.jumpSequenceValue,
    state.jumpType,
    state.jumpUpdatedValue,
    state.searchType,
    state.searchValue,
    state.uncommitted,
    filter,
  ]);

  const stopSearch = () => {
    searchWorker.terminate();
    dispatch({ type: 'searchStop' });
  };

  const selectEntity = (entity) => {
    dispatch({
      type: 'selectEntity',
      entity,
    });
  };

  function isFloat(value) {
    try {
      if (parseFloat(value) && parseFloat(value) % parseInt(value) !== 0)
        if (parseFloat(value).toString().length === value.toString().length) return true;
      return false;
    } catch (e) {
      return false;
    }
  }

  function traverseAndCheckAllValues(rawJson) {
    if (rawJson) {
      Object.keys(rawJson).forEach((key) => {
        const type = typeof rawJson[key];
        if (type === 'object') traverseAndCheckAllValues(rawJson[key]);
        if (type === 'number') {
          if (isFloat(rawJson[key])) rawJson[key] = `~d${rawJson[key]}`;
        }
      });
    }
  }

  // TODO put this into useEffect!
  const sendPost = useCallback(
    ({ force, preserveFloatTypes }) => {
      const targetDatasetID = state.postTargetDatasetId || params.datasetID || datasetID;
      const url = `${subUrl}/datasets/${targetDatasetID}/entities?force=${
        force ? 'true' : 'false'
      }`;
      const json = postJson;
      if (preserveFloatTypes) traverseAndCheckAllValues(json);
      const fullJson = state.postMultiple ? json : [json];
      const requestOptions = {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
        body: JSON.stringify(fullJson),
      };

      sfetch(url, requestOptions)
        .then(() => {
          dispatch({ type: 'postReset' });
        })
        .then(() => {
          loadDataset();
          populate();
        })
        .catch((e) => {
          dispatch({
            type: 'postInvalid',
            payload:
              e.responseBody && e.responseBody.validation_errors
                ? 'Validation errors'
                : (e.responseBody && e.responseBody.detail) || 'Unexpected error',
          });
        });
    },
    [
      datasetID,
      loadDataset,
      params.datasetID,
      populate,
      postJson,
      state.postMultiple,
      state.postTargetDatasetId,
      subUrl,
      token,
    ]
  );

  const setFilter = (val) => {
    const query = Object.assign({}, qs.parse(router.location.query), {
      filter: val,
    });

    router.replace({
      search: `?${qs.stringify(query)}`,
      pathname: location.pathname
        .split('/')
        .filter((c) => c !== 'unified')
        .join('/'),
    });
    dispatch({ type: 'setFilter', payload: val });
  };

  const setJumpSequenceValue = (val) => {
    if (isNaN(val)) {
      val = undefined;
    } else {
      if (val && val < 0) val = 0;
    }

    dispatch({ type: 'setJumpSequenceValue', payload: val });
  };

  const setJumpType = (val) => {
    dispatch({ type: 'setJumpType', payload: val });
  };

  const setJumpUpdatedValue = (val) => {
    dispatch({ type: 'setJumpUpdatedValue', payload: val });
  };

  const setPost = useCallback(
    (json) => {
      if (state.postMultiple) {
        dispatch({ type: 'postMultipleEntities', payload: json });
      } else {
        dispatch({ type: 'postSingleEntity', payload: json });
      }
    },
    [state.postMultiple]
  );

  const setPostValid = (isValid) => {
    if (!isValid) {
      dispatch({ type: 'postInvalid', payload: 'Parse error' });
    } else {
      dispatch({ type: 'postValid' });
    }
  };

  const setSearchType = (val) => {
    dispatch({ type: 'searchStop' });
    dispatch({ type: 'setSearchType', payload: val });
  };

  const setSearchValue = (val) => {
    if (val === '') {
      dispatch({ type: 'resetSearchValue' });
    }
    dispatch({ type: 'setSearchValue', payload: val });
  };

  const setUncommitted = (uncommitted) => {
    dispatch({ type: 'setUncommitted', payload: uncommitted });
  };

  // TODO: move this to useEffect
  const testPreviousEntity = useCallback(async () => {
    try {
      if (isNull(state.previousEntity._previous)) {
        throw new Error('Is first entity');
      }
      const previousEntity = await EntityAPI.getEntity(
        apiConf,
        state.previousEntity._id,
        state.previousEntity._previous
      );
      return dispatch({
        type: 'setHasPreviousEntity',
        hasPrevious: true,
        previousEntity,
      });
    } catch (e) {
      return dispatch({
        type: 'setHasPreviousEntity',
        hasPrevious: false,
        e,
      });
    }
  }, [apiConf, state.previousEntity]);

  return {
    filter,
    isDatasetEmpty,
    isFilterActive,
    isSelectedEntityFirstEntity,
    isSelectedEntityLastEntity,
    loadMore,
    loadMoreTop,
    loadNextEntity,
    loadPreviousEntity,
    navigateNext,
    navigatePrevious,
    populate,
    postDeadEntity,
    repostDeadEntities,
    postEntities,
    postJson,
    postReset,
    repost,
    reset,
    resetJump,
    resetPreviousEntity,
    resetSearch,
    search,
    selectEntity,
    selectedEntityIndex,
    sendPost,
    setFilter,
    setJumpSequenceValue,
    setJumpType,
    setJumpUpdatedValue,
    setPost,
    setPostValid,
    setSearchType,
    setSearchValue,
    setUncommitted,
    showingFilteredResults,
    sourceEntityPath,
    state,
    stopSearch,
    testPreviousEntity,
  };
};
