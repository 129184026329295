import { createAction } from '@reduxjs/toolkit';

export type NavId =
  | 'browse'
  | 'dataset'
  | 'datasets'
  | 'gdpr'
  | 'pipe'
  | 'pipes'
  | 'settings-databrowser'
  | 'settings-datahub'
  | 'settings-subscription'
  | 'system'
  | 'systems'
  | 'flows'
  | 'data-type'
  | 'model';

export const lastViewedTabUpdated = createAction<{
  navId: NavId;
  value: string;
}>('navigation/updateLastViewedTab');
