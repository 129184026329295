import React from 'react';

import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

const SesamCheckbox: React.FC<CheckboxProps> = (props) => <Checkbox {...props} />;

SesamCheckbox.defaultProps = {
  color: 'primary',
};

export default SesamCheckbox;
