import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { getCurrentSub, makeRoleCheckerForCurrentSub } from 'Redux/thunks/subscriptions';
import Page from '../../../components/page';
import PageHeader, { PageHeaderTitle } from '../../../components/page-header';
import TabbedNav from '../../../components/tabbed-nav';

export const getDatabrowserURL = (subscription, databrowserId) => {
  if (subscription) {
    if (subscription.connections) {
      for (const connection of subscription.connections) {
        if (connection.id === databrowserId) {
          return connection.url;
        }
      }
    }
  }
  return null;
};

export const getDatabrowserURLFromCustomFQDN = (subscription, databrowserId) => {
  if (subscription) {
    if (subscription.provisioning_result && subscription.provisioning_result.services) {
      const databrowserListFromProvisioningResult =
        subscription.provisioning_result.services.databrowsers;
      if (databrowserListFromProvisioningResult) {
        for (let i = 0; i < databrowserListFromProvisioningResult.length; i += 1) {
          const databrowser = databrowserListFromProvisioningResult[i];
          if (databrowser.id === databrowserId) {
            let customFQDN = databrowser.custom_fqdn;
            if (customFQDN && customFQDN.length > 0) {
              customFQDN = customFQDN[0];
              if (customFQDN.indexOf(':') !== -1) {
                // This is an internal test-portal, so use http.
                return 'http://' + customFQDN;
              } else {
                return 'https://' + customFQDN;
              }
            }
            break;
          }
        }
      }
    }
  }
  return null;
};

const SettingsDatabrowser = (props) => {
  const nav = [];
  const databrowserId = props.params.databrowserId;
  if (!databrowserId) return null;
  const databrowserURL = getDatabrowserURL(props.sub, databrowserId);
  let isConnected = false;
  if (databrowserURL) {
    isConnected = true;
  }

  if (props.roleChecker('group:Admin') && isConnected) {
    nav.push({
      label: 'Configuration file',
      to: `/subscription/${props.subId}/settings-databrowser/${databrowserId}/configfile`,
    });
  }

  if (isConnected) {
    nav.push({
      label: 'Data Access Portal',
      to: `/subscription/${props.subId}/settings-databrowser/${databrowserId}/databrowser`,
    });
  }

  if (props.roleChecker('group:Admin') && isConnected) {
    nav.push({
      label: 'Network',
      to: `/subscription/${props.subId}/settings-databrowser/${databrowserId}/network`,
    });
  }

  return (
    <Page dataSelenium="databrowser-settings-pageview">
      <PageHeader>
        <PageHeaderTitle>
          {`Settings for the Data Access Portal "${databrowserId}"`}
        </PageHeaderTitle>
      </PageHeader>
      <TabbedNav nav={nav}>{props.children}</TabbedNav>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  connected: state.subscription.connected,
  sub: getCurrentSub(state),
  subId: state.subscription.id,
  roleChecker: makeRoleCheckerForCurrentSub(state),
});

SettingsDatabrowser.propTypes = {
  connected: PropTypes.bool.isRequired,
  children: PropTypes.node,
  params: PropTypes.object.isRequired,
  sub: PropTypes.object.isRequired,
  subId: PropTypes.string.isRequired,
  roleChecker: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(SettingsDatabrowser);
