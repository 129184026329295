import React from 'react';
import PropTypes from 'prop-types';

import Box from 'Common/Box/Box';
import Button from 'Common/Button/Button';

import './Insights.css';

class InsightsEmptyState extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = () => {
      this.props.onEnable();
    };
  }

  render() {
    return (
      <div style={{ flex: '0 0 auto' }}>
        <Box>
          <Box.Content padding>
            <div className="insights__empty-state">
              <p>
                Insights for this pipe are{' '}
                <span className="insights__highlight insights__highlight--disabled">disabled</span>
              </p>
              <p>
                When enabled, charts on this tab show how many entities, errors and latencies there
                have been for the current pipe during the last 30 days.
              </p>
              <Button text="Enable insights…" onClick={this.handleClick} />

              <p>
                <small>
                  Enabling Insights will incur additional costs of $5 per pipe per month. Data
                  collection starts only after you enable Insights.
                </small>
              </p>
            </div>
          </Box.Content>
        </Box>
      </div>
    );
  }
}

InsightsEmptyState.propTypes = {
  onEnable: PropTypes.func.isRequired,
};

InsightsEmptyState.defaultProps = {
  onEnable: () => {},
};

export default InsightsEmptyState;
