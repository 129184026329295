import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Box from 'Common/Box/Box';
import DatahubAPI from '../../api/datahub';
import LogList from '../log-list/LogList';
import DatahubSettings from '../../subscription/pages/settings-datahub';

import './SettingsDatahubLogsStyle.css';

const setLogs = (logs) => () => ({
  logs,
});

class SettingsDatahubLogs extends React.Component {
  constructor() {
    super();

    // Methods
    this.getLogs = async () => {
      const logs = await DatahubAPI.getLogs({
        subUrl: this.props.subUrl,
        token: this.props.token,
      });
      this.setState(setLogs(logs));
    };

    // State
    this.state = {
      logs: [],
    };
  }

  componentDidMount() {
    this.getLogs();
  }

  render() {
    return (
      <DatahubSettings>
        <main className="scrollArea">
          <div className="settings-datahub-logs">
            <div className="settings-datahub-logs__list">
              <Box>
                <Box.Header>
                  <Box.Title>Download logs</Box.Title>
                </Box.Header>
                <Box.Content>
                  <LogList
                    logs={this.state.logs}
                    subUrl={this.props.subUrl}
                    token={this.props.token}
                  />
                </Box.Content>
              </Box>
            </div>
          </div>
        </main>
      </DatahubSettings>
    );
  }
}

SettingsDatahubLogs.propTypes = {
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  subUrl: state.subscription.url,
  token: state.subscription.token,
});

export default connect(mapStateToProps)(SettingsDatahubLogs);
