import React from 'react';
import PropTypes from 'prop-types';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { connect } from 'react-redux';

import GenerateSchemaPanel from './GenerateSchemaPanel';
import PipesActions from 'Redux/thunks/pipes';

import { apologise } from 'Redux/thunks/apology';
import { sfetchJson } from 'Internals/sfetch';

class GenerateSchemaPanelContainer extends React.Component {
  constructor(props) {
    super(props);

    this.generateSchema = async () => {
      const { pipe, subUrl, token } = this.props;
      const requestOptions = {
        credentials: 'include',
        headers: { Authorization: `bearer ${token}` },
      };
      let useOldApi = this.state.usingOldApi;

      const entityTypeUrl = `${subUrl}/pipes/${pipe._id}/entity-types/sink`;
      let schema = [];
      try {
        let response = await sfetchJson(entityTypeUrl, requestOptions);
        if (response.properties && !isEmpty(response.properties)) {
          schema = response.properties;
        } else {
          useOldApi = true;
        }
      } catch (e) {
        useOldApi = true;
      }
      if (useOldApi) {
        const url = `${subUrl}/pipes/${pipe._id}/generate-schema-definition?sample_size=${this.state.sampleSize}`;
        try {
          schema = await sfetchJson(url, requestOptions);
        } catch (e) {
          this.setState({
            loading: false,
          });
          this.props.apologise(e);
          return;
        }
      }

      this.setState({
        loading: false,
        schema,
        usingOldApi: useOldApi,
      });
    };

    this.updateSampleSize = (ev) => {
      const value = ev.target.value;
      this.setState({
        sampleSize: value,
      });
    };

    this.state = {
      loading: true,
      generatedSchema: [],
      sampleSize: 1000,
      usingOldApi: false,
    };
  }

  componentDidMount() {
    this.props.loadPipe(this.props.pipeId);
    this.generateSchema();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.pipe.config.original, prevProps.pipe.config.original)) {
      this.setState({
        loading: true,
      });
      this.generateSchema();
    }
  }

  render() {
    return (
      <GenerateSchemaPanel
        loading={this.state.loading}
        schema={this.state.schema}
        isDirty={this.props.isDirty}
        sampleSize={this.state.sampleSize}
        onChangeSampleSize={this.updateSampleSize}
        onSubmit={this.generateSchema}
        usingOldApi={this.state.usingOldApi}
      />
    );
  }
}

GenerateSchemaPanelContainer.propTypes = {
  apologise: PropTypes.func.isRequired,
  pipeId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  pipe: PropTypes.shape({}).isRequired,
  loadPipe: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  subUrl: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  apologise: (msg) => dispatch(apologise(msg)),
  loadPipe: (id) => dispatch(PipesActions.load(id)),
});

const mapStateToProps = (state, ownProps) => ({
  pipe: state.pipes[ownProps.pipeId],
  token: state.subscription.token,
  subUrl: state.subscription.url,
  isDirty: state.editor.isDirty,
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateSchemaPanelContainer);
