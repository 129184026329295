import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { LoadingPanel } from 'Common/LoadingPanel';
import DetailView from 'Common/DetailView/DetailView';
import Box from 'Common/Box/Box';
import { getEdgeColor } from 'Internals/graph';
import { isGlobal } from 'Internals/pipes';
import EditPipe from '../../../subscription/pages/pipes/EditPipe';
import PipeIcon from '../../../images/icons/pipe_circle.svg';
import EditSystem from '../../../subscription/pages/systems/EditSystem';
import GlobalIcon from '../../../images/icons/global_circle.svg';
import SystemIcon from '../../../images/icons/system_circle.svg';
import { PipeLastRunStatusContainer } from '../../../components/pipe-status/PipeLastRunStatus.container';

import './style.css';

function getEditPage(id, flow) {
  const found = flow.find((f) => f.data._id === id);
  if (found) {
    if (get(found, 'data.config.effective.type') === 'pipe') {
      return <EditPipe params={{ pipeID: id }} />;
    } else {
      return <EditSystem params={{ systemID: id }} />;
    }
  } else return null;
}

function ConfigListItem({ isFirst, isLast, item, selected }) {
  const isPipe = get(item, 'data.config.effective.type') === 'pipe';
  const isGlobalPipe = isGlobal(item.data);
  const icon = isPipe ? (
    isGlobalPipe ? (
      <GlobalIcon
        width="32"
        height="32"
        color={getEdgeColor(item.data)}
        className="flow-config__item__icon"
      />
    ) : (
      <PipeIcon
        width="32"
        height="32"
        color={getEdgeColor(item.data)}
        className="flow-config__item__icon"
      />
    )
  ) : (
    <SystemIcon
      width="32"
      height="32"
      color={getEdgeColor(item.data)}
      className="flow-config__item__icon"
    />
  );
  return (
    <div className={'flow-config__item ' + (selected ? 'flow-config__item--selected' : '')}>
      <div className="flow-config__item__graph">
        <div
          className={
            'flow-config__item__line-top ' + (isFirst ? 'flow-config__item__line-top--first' : '')
          }
        >
          &nbsp;
        </div>
        {icon}
        <div
          className={
            'flow-config__item__line-bottom ' +
            (isLast ? 'flow-config__item__line-bottom--last' : '')
          }
        >
          &nbsp;
        </div>
      </div>
      <div className="flow-config__item__box-container">
        <Box className="flow-config__item__box">
          <span>{isPipe ? 'Pipe' : 'System'}</span>
          <span>{item.data._id}</span>
          <PipeLastRunStatusContainer
            runtime={item.data.runtime}
            useLink={false}
            id={get(item, 'data._id')}
            type={get(item, 'data.config.effective.type')}
          />
        </Box>
      </div>
    </div>
  );
}

ConfigListItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
};

function FlowConfigPage({ flow }) {
  return !isEmpty(flow) ? (
    <DetailView initiallySelectedId={flow[0].data._id}>
      <DetailView.List>
        <Box className="flow-config__side">
          <Box.Header>Pipes and systems in flow</Box.Header>
          <Box.Content padding>
            {flow.map((item, i) => (
              <DetailView.Item key={item.data._id} id={item.data._id}>
                {(selected) => (
                  <ConfigListItem
                    isFirst={i === 0}
                    isLast={i === flow.length - 1}
                    item={item}
                    selected={selected}
                  />
                )}
              </DetailView.Item>
            ))}
          </Box.Content>
        </Box>
      </DetailView.List>
      <DetailView.Content>{(id) => getEditPage(id, flow)}</DetailView.Content>
    </DetailView>
  ) : (
    <LoadingPanel />
  );
}

FlowConfigPage.propTypes = {
  flow: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    })
  ),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  subId: PropTypes.string,
};

export default withRouter(FlowConfigPage);
