import React from 'react';
import PropTypes from 'prop-types';

import SesamTextField from '../SesamTextField/SesamTextField';

import CodeStyleOption from './CodeStyleOption';

const CodeStyleOptions = (props) => {
  const name = props.name;
  let value = props.option.value;
  if (value === undefined || value === null) {
    value = props.option['default-value'];
  }
  const type = typeof value;
  const handler = (v) => props.onChange(name, v);
  if (type === 'boolean') {
    return (
      <CodeStyleOption
        helperText={props.option.description}
        label={props.option.label}
        onChange={(e) => handler(e.target.checked)}
        checked={value}
        name={name}
      />
    );
  } else if (type === 'number') {
    return (
      <SesamTextField
        margin="normal"
        label={props.option.label}
        id={name}
        type="number"
        value={value}
        onChange={(e) => handler(parseInt(e.target.value, 10))}
        helperText={props.option.description}
      />
    );
  }
  console.warn(`Assertion warning: No renderer for option of type: ${type}`);
  return null;
};

CodeStyleOptions.propTypes = {
  name: PropTypes.string.isRequired,
  option: PropTypes.shape({
    value: PropTypes.any,
    'default-value': PropTypes.any.isRequired,
    description: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};

export default CodeStyleOptions;
