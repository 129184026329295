import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import Actions from 'Redux/thunks/subscriptions';
import { canEditVPN } from 'Redux/selectors';
import get from 'lodash/get';

import VpnEditor from '../../../components/vpn-editor';

const getSubType = (subscriptions, id) => {
  const sub = subscriptions.find((s) => s.id === id);
  if (sub) {
    return sub.type;
  }
  return '';
};

function SettingsSubscriptionVpn(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [key, setKey] = useState(0);

  function onSave(vpn) {
    const products = JSON.parse(JSON.stringify(props.subData.products));
    products.vpn = vpn;
    setIsSubmitting(true);
    props
      .updateVpn(props.subId, products)
      .then(() => setIsSubmitting(false))
      .catch(() => setIsSubmitting(false))
      .finally(() => setKey(key + 1));
  }

  if (!props.canEditVPN) {
    props.router.push(`/subscription/${props.subId}/settings-subscription/basics`);
    return null;
  }

  const sesamGateway = get(props, 'subData.provisioning_result.vpn.public_ip_vpn', '(not set)');
  const sesamAddressSpaces = get(
    props,
    'subData.provisioning_result.vpn.subscription_vnet_range',
    '(not set)'
  );

  return (
    <main className="scrollArea">
      <div className="row" style={{ margin: 0 }}>
        <div className="col gr-secondary vpn-tab-primary">
          <h2 className="heading-section">VPN settings</h2>
          <p>You can edit the VPN settings using the forms below.</p>
        </div>
        <div className="col gr-primary vpn-tab-secondary">
          <h2 className="heading-section">&nbsp;</h2>
          <p>Sesam Peer VPN Gateway: {sesamGateway}</p>
          <p>Sesam address spaces: {sesamAddressSpaces}</p>
        </div>
      </div>
      <br />
      <div className="row" style={{ marginLeft: 16 }}>
        <VpnEditor
          key={key}
          vpn={props.subData.products.vpn}
          isSubmitting={isSubmitting}
          onChange={onSave}
        />
      </div>
    </main>
  );
}

SettingsSubscriptionVpn.propTypes = {
  type: PropTypes.string.isRequired,
  subData: PropTypes.object.isRequired,
  subId: PropTypes.string.isRequired,
  updateVpn: PropTypes.func.isRequired,
  canEditVPN: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    subId: state.subscription.id,
    type: getSubType(state.subscriptions, state.subscription.id),
    subData: state.subscriptions.find((s) => s.id === state.subscription.id),
    canEditVPN: canEditVPN(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateVpn: (subId, products) => dispatch(Actions.updateVpn(subId, products)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(SettingsSubscriptionVpn);
