/* eslint-env node */
module.exports = {
  cyan: {
    cyan100: '#009fdc',
    cyan90: '#19a8df',
    cyan80: '#32b2e3',
    cyan70: '#4cbbe6',
    cyan60: '#66c5ea',
    cyan50: '#7fcfed',
    cyan40: '#99d8f1',
    cyan30: '#b2e2f4',
    cyan20: '#ccebf8',
    cyan10: '#e5f5fb',
  },
  warmGrey: {
    warmGrey100: '#40363c',
    warmGrey90: '#534a4f',
    warmGrey80: '#665e62',
    warmGrey70: '#797276',
    warmGrey60: '#8c868a',
    warmGrey50: '#9f9a9d',
    warmGrey40: '#b2aeb1',
    warmGrey30: '#c5c2c4',
    warmGrey20: '#d8d6d8',
    warmGrey10: '#ebeaeb',
  },
  white: '#ffffff',
  amber: {
    amberDark: '#fab20e',
    amber: '#fcd16e',
    amberLight: '#fde8b7',
    amberLighter: '#fff7e7',
  },
  flame: {
    flameDark: '#f2571e',
    flame: '#f79a78',
    flameLight: '#fbcdbb',
    flameLighter: '#feeee9',
  },
  pine: {
    pineDark: '#008580',
    pine: '#66b6b3',
    pineLight: '#b3dad9',
    pineLighter: '#e6f3f2',
  },
  midnight: {
    midnightDark: '#023258',
    midnight: '#67849b',
    midnightLight: '#b3c2cd',
    midnightLighter: '#e6ebee',
  },
  black: '#000000',
};
