import { createReducer } from '@reduxjs/toolkit';
import { showZendeskWindow, hideZendeskWindow } from '../thunks/global';

type ZendeskWindowState = {
  show: boolean;
};

const initialState: ZendeskWindowState = { show: false };

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
export default createReducer(initialState, (builder) =>
  builder
    .addCase(showZendeskWindow, (state, action) => {
      state.show = true;
    })
    .addCase(hideZendeskWindow, (state, action) => {
      state.show = false;
    })
);
