import PropTypes from 'prop-types';
import React from 'react';

import { MenuActionLink } from '../menu';

import MenuIcon from '../../images/icons/menu.svg';
import MenuToggler from '../menu-toggler';

import './style.css';

class DatasetActionsMenu extends React.Component {
  render() {
    const menuIcon = <MenuIcon className="dataset-actions-menu" />;

    return (
      <MenuToggler label={menuIcon} labelClassName="dataset-actions-menu__toggler">
        <MenuActionLink
          label="Create downstream pipe"
          to={`/subscription/${this.props.subId}/pipes/new?source=${this.props.datasetID}`}
        />

        {this.props.upstreamPipeID && (
          <MenuActionLink
            label="Go to pipe"
            to={`/subscription/${this.props.subId}/pipes/pipe/${this.props.upstreamPipeID}`}
          />
        )}
      </MenuToggler>
    );
  }
}

DatasetActionsMenu.propTypes = {
  upstreamPipeID: PropTypes.string.isRequired,
  datasetID: PropTypes.string.isRequired,
  subId: PropTypes.string.isRequired,
};

export default DatasetActionsMenu;
