import React from 'react';
import PropTypes from 'prop-types';

import Arrow from './arrow.svg';

import './Hub.css';

function getBackgroundImage(color, dashed) {
  return `repeating-linear-gradient(
    to right,
    ${color} 0%,
    ${color} ${dashed ? '33.3%' : '100%'},
    rgba(255, 255, 255, 0) 33.3%,
    rgba(255, 255, 255, 0) 100%
  )`;
}

const Hub = ({ children }) => {
  return <div className="hub">{children}</div>;
};

Hub.propTypes = {
  children: PropTypes.node.isRequired,
};

Hub.defaultProps = {
  nodes: [],
};

const Nexus = ({ children }) => <div className="hub__nexus">{children}</div>;
Nexus.propTypes = {
  children: PropTypes.node.isRequired,
};

const Wheel = ({ children }) => <ul className="hub__wheel">{children}</ul>;
Wheel.propTypes = {
  children: PropTypes.node.isRequired,
};

const Spoke = ({ color, angle, dashed, direction, children }) => {
  let className = 'spoke';
  let arrowClass = 'arrow';
  const style = {
    backgroundImage: getBackgroundImage(color, dashed),
    transform: `rotate(${angle}deg) translateX(${direction === 'outgoing' ? 14 : -14}rem)`,
  };

  switch (direction) {
    case 'incoming':
      className += ' spoke--inbound';
      arrowClass += ' arrow--inbound';
      break;
    case 'outgoing':
      className += ' spoke--outbound';
      arrowClass += ' arrow--outbound';
      break;
    case 'both':
      className += ' spoke--inbound';
      arrowClass += ' arrow--inbound';
      break;
    case 'none':
      break;
    default:
      throw new Error(`unknown direction '${direction}'`);
  }

  return (
    <li className={className} style={style}>
      {direction !== 'outgoing' && <Arrow className={arrowClass} style={{ color }} />}
      {children}
      {direction === 'outgoing' && <Arrow className={arrowClass} style={{ color }} />}
    </li>
  );
};

Spoke.propTypes = {
  color: PropTypes.string.isRequired,
  angle: PropTypes.number.isRequired,
  direction: PropTypes.string.isRequired,
  dashed: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

Hub.Nexus = Nexus;
Hub.Wheel = Wheel;
Hub.Spoke = Spoke;

export { Nexus, Wheel, Spoke };
export default Hub;
