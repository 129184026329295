import sfetch from 'Internals/sfetch';

const sendAnalyticsInfo = async (
  portalUrl: string,
  subId: string,
  action: 'page_view' | 'api_call'
) => {
  const url = `${portalUrl}/analytics`;

  const options: RequestInit = {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      'subscription_id': subId,
      action: action,
    }),
  };

  return await sfetch(url, options);
};

export default sendAnalyticsInfo;
