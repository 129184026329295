import React, { useState, useCallback } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import memoize from 'memoize-one';
import moment from 'moment';
import isObject from 'lodash/isObject';
import produce from 'immer';

import { withSeparators, bytesToSize, findInternalOriginInFilter } from 'Internals/utils';
import {
  sortWithNullOrUndefinedValues,
  sortBooleanWithNullOrUndefinedValues,
} from 'Internals/sort';
import { upstreamPipeIsGlobal, isMaintained, getDatasetQueueSize } from 'Internals/datasets';
import DatasetActions, { datasetsSelector } from 'Redux/thunks/datasets';
import DatasetLink from 'Common/Links/DatasetLink';
import State from 'Common/renderers/State/State';
import Actions from '../../../components/datasets-list/datasets-list-actions';
import FilterTable, { getSortValues } from '../../../components/filter-table/FilterTable';
import useLocalStorage from '../../../hooks/useLocalStorage';
import DatahubSettings from '../settings-datahub';

import '../../../components/datasets-list/style.css';

// FIXME: Centralize constants
const LOCAL_STORAGE_KEY = 'sesam--data-table-3';

const getDatasetsWithGlobal = memoize((datasets, pipes, upstreams) => {
  return datasets.map((dataset) => {
    const datasetCopy = { ...dataset };
    datasetCopy['_global'] = upstreamPipeIsGlobal(datasetCopy._id, pipes, upstreams) ? 'Yes' : 'No';
    return datasetCopy;
  });
});

const filterLabelMapping = {
  _id: 'Dataset',
  origin: 'Origin',
  state: 'State',
  _global: 'Global',
};

const emptyFilter = Object.freeze({
  _id: {
    selected: [],
    search: [],
  },
  origin: {
    selected: [],
    search: [],
  },
  state: {
    selected: [],
    search: [],
  },
  _global: {
    selected: [],
    search: [],
  },
});

const defaultFilter = produce(emptyFilter, (draft) => {
  draft.origin.selected = ['system'];
});

const defaultVisibleCols = ['Dataset', 'Last modified', 'Complete index'];

const defaultVisibleFacets = ['_id', 'origin', '_global'];

const defaultSortBy = [
  {
    id: 'datasets-link',
    desc: false,
  },
];

const InternalDatasets = (props) => {
  const { datasets, deleteDataset, loadAllForced, pipes, upstreams } = props;

  const [showBytesExact, setShowBytesExact] = useLocalStorage(
    LOCAL_STORAGE_KEY,
    ['datasets', 'showBytesExact'],
    true
  );

  const columns = [
    {
      Header: 'Dataset',
      id: 'datasets-link',
      fixed: true,
      width: 300,
      accessor: (d) => ({ id: d._id, link: d.link }),
      Cell: ({ cell: { value } }) => (
        <div data-selenium="dataset-id">
          <DatasetLink datasetId={value.link}>{value.id}</DatasetLink>
        </div>
      ),
      sortType: getSortValues((a, b) => {
        return sortWithNullOrUndefinedValues(a.id, b.id);
      }),
    },
    {
      Header: 'Last modified',
      id: 'datasets-last-modified',
      accessor: 'runtime[last-modified]',
      Cell: ({ cell: { value } }) => getLastModified(value),
      sortType: getSortValues((a, b) => {
        return sortWithNullOrUndefinedValues(new Date(a), new Date(b));
      }),
    },
    {
      Header: 'Index w/o deleted',
      id: 'datasets-index-without-deleted',
      accessor: (d) => getIndexCountNoDeleted(d),
      align: 'right',
      Cell: ({ cell: { value } }) => <span>{withSeparators(value)}</span>,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Complete index',
      id: 'datasets-complete-index',
      accessor: 'runtime[count-index-exists]',
      align: 'right',
      Cell: ({ cell: { value } }) => <span>{withSeparators(value)}</span>,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Complete log',
      id: 'datasets-complete-log',
      accessor: 'runtime[count-log-exists]',
      align: 'right',
      Cell: ({ cell: { value } }) => <span>{withSeparators(value)}</span>,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Bytes used',
      id: 'pipes-bytes-used',
      accessor: (d) => (isObject(d.storage) ? d.storage.total : d.storage),
      align: 'right',
      Cell: ({ cell: { value } }) => (
        <span
          onClick={() => toggleShowBytesExact()}
          title="Click to switch between representation"
          style={{ cursor: 'pointer' }}
        >
          {showBytesExact ? withSeparators(value) : bytesToSize(value)}
        </span>
      ),
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Origin',
      id: 'datasets-origin',
      accessor: 'runtime.origin',
      Cell: ({ cell: { value } }) => <span>{value}</span>,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Queues',
      id: 'datasets-queues',
      accessor: (d) => d,
      align: 'right',
      Cell: ({ cell: { value } }) => <div>{withSeparators(getDatasetQueueSize(value))}</div>,
      sortType: getSortValues((a, b) => {
        const aQ = getDatasetQueueSize(a);
        const bQ = getDatasetQueueSize(b);

        return sortWithNullOrUndefinedValues(aQ, bQ);
      }),
    },
    {
      Header: 'Populated',
      id: 'datasets-populated',
      accessor: 'runtime.populated',
      align: 'center',
      width: 75,
      Cell: ({ cell: { value } }) =>
        value ? (
          <State title="Dataset is populated" className="status-green" text="Yes" />
        ) : (
          <State title="Dataset is not populated" className="status-red" text="No" />
        ),
      sortType: getSortValues(sortBooleanWithNullOrUndefinedValues),
    },
    {
      Header: 'Maintained',
      id: 'datasets-maintained',
      width: 75,
      accessor: (d) => isMaintained(d._id, pipes, upstreams),
      align: 'center',
      Cell: ({ cell: { value } }) =>
        value ? (
          <State
            title="Dataset is maintained (has upstream pipe)"
            className="status-green"
            text="Yes"
          />
        ) : (
          <State
            title="Dataset is not maintained (has no upstream pipe)"
            className="status-red"
            text="No"
          />
        ),
      sortType: getSortValues(sortBooleanWithNullOrUndefinedValues),
    },
    {
      Header: 'Global',
      id: 'datasets-global',
      width: 75,
      accessor: (d) => upstreamPipeIsGlobal(d._id, pipes, upstreams),
      align: 'center',
      Cell: ({ cell: { value } }) =>
        value ? (
          <State title="Upstream pipe is global" className="status-green" text="Yes" />
        ) : (
          <State title="Upstream pipe is not global" className="status-red" text="No" />
        ),
      sortType: getSortValues(sortBooleanWithNullOrUndefinedValues),
    },
  ];

  const toggleShowBytesExact = () => {
    setShowBytesExact(!showBytesExact);
  };

  const renderActions = (selectedIds, clearSelection) => {
    const selectedDatasets = getSelectedDatasets(selectedIds);
    const deleteDatasets = () => {
      onDelete(selectedIds);
      clearSelection();
    };
    return <Actions selectedDatasets={selectedDatasets} onDelete={deleteDatasets} />;
  };

  const onDelete = (selectedIds) => selectedIds.forEach((datasetId) => deleteDataset(datasetId));

  const getSelectedDatasets = (selectedIds) =>
    datasets.filter((dataset) => selectedIds.includes(dataset._id));

  const getLastModified = (lastModified) => {
    if (lastModified === null) {
      return 'never';
    }
    return `${moment.duration(moment().diff(lastModified)).humanize()} ago`;
  };
  const [includeInternal, setIncludeInternal] = useState(false);
  const handleFilterChanged = useCallback(
    (filter) => {
      const found = findInternalOriginInFilter(filter);
      if (found) {
        if (!includeInternal) {
          setIncludeInternal(true);
          loadAllForced(true);
        }
      } else {
        if (includeInternal) {
          setIncludeInternal(false);
          loadAllForced();
        }
      }
    },
    [loadAllForced, includeInternal]
  );

  const getIndexCountNoDeleted = (dataset) =>
    dataset.runtime['count-index-exists'] - dataset.runtime['count-index-deleted'];

  return (
    <DatahubSettings>
      <FilterTable
        actionsRenderer={renderActions}
        defaults={{
          filter: defaultFilter,
          sortBy: defaultSortBy,
          visibleCols: defaultVisibleCols,
          visibleFacets: defaultVisibleFacets,
        }}
        emptyFilter={emptyFilter}
        filterLabelMapping={filterLabelMapping}
        columns={columns}
        itemsToFilter={datasets}
        tableKey="internal-datasets"
        onFilterChanged={handleFilterChanged}
      />
    </DatahubSettings>
  );
};

InternalDatasets.propTypes = {
  datasets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pipes: PropTypes.shape({}).isRequired,
  upstreams: PropTypes.shape({}).isRequired,
  deleteDataset: PropTypes.func.isRequired,
  loadAll: PropTypes.func.isRequired,
  loadAllForced: PropTypes.func.isRequired,
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
    search: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  subId: PropTypes.string.isRequired,
  datasetsLoaded: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  cell: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    datasets: getDatasetsWithGlobal(datasetsSelector(state), state.pipes, state.upstreams),
    pipes: state.pipes,
    upstreams: state.upstreams,
    datasetsLoaded: state.loadStatus.datasetsLoaded,
    subId: state.subscription.id,
  };
}

const mapDispatchToProps = (dispatch) => ({
  deleteDataset: (id) => dispatch(DatasetActions.delete(id)),
  loadAll: (includeInternal) => dispatch(DatasetActions.loadAll(includeInternal)),
  loadAllForced: (includeInternal) => dispatch(DatasetActions.loadAllForced(includeInternal)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(InternalDatasets);
