import PropTypes from 'prop-types';
import React from 'react';

import './PipeOutputSectionStyle.css';

const Header = ({ children, className, ...props }) => (
  <div className={`pipe-output-section__header ${className ? className : ''}`} {...props}>
    {children}
  </div>
);

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};

const Description = ({ children, className, ...props }) => (
  <div className={`pipe-output-section__description ${className ? className : ''}`} {...props}>
    {children}
  </div>
);

Description.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};

const Content = ({ children, className, ...props }) => (
  <div className={`pipe-output-section__content ${className ? className : ''}`} {...props}>
    {children}
  </div>
);

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};

const PipeOutputSection = ({ children, className, ...props }) => (
  <div className={`pipe-output-section ${className ? className : ''}`} {...props}>
    {children}
  </div>
);

PipeOutputSection.Header = Header;
PipeOutputSection.Description = Description;
PipeOutputSection.Content = Content;

PipeOutputSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};

export default PipeOutputSection;
