import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import SubscriptionsActions from 'Redux/thunks/subscriptions';

const withGdprRedirect = (WrappedComponent, source) => {
  class Redirect extends React.Component {
    constructor(props) {
      super(props);

      this.getSub = (subs) => head(subs.filter((c) => c.id === this.props.params.subId));

      this.getRoles = (sub) => get(sub, ['current-users-member', 'roles']);

      this.getAdminSubs = (subs) =>
        subs.filter((c) => {
          const role = this.getRoles(c);
          return role && role.includes('group:Admin');
        });

      this.getPath = (sub) => {
        let path;
        const roles = this.getRoles(sub);
        const basePath = `/subscription/${sub.id}/gdpr`;

        if (roles && roles.includes('group:Admin')) {
          path = `${basePath}/data-types`;
        } else if (roles && roles.includes('group:GDPR_operations')) {
          path = `${basePath}/access-requests`;
        }

        return path;
      };

      this.isGdprSub = (sub) =>
        sub.name.toLowerCase().indexOf('gdpr') !== -1 || sub.products.gdpr_platform;

      this.redirectFromDashboard = (subs) => {
        if (!subs.length) return;

        const adminSubs = this.getAdminSubs(subs);
        if (adminSubs.length == 1) {
          const sub = adminSubs[0];
          if (this.isGdprSub(sub)) {
            this.props.router.replace({
              pathname: this.getPath(sub),
              search: '?collapsed=true',
            });
          }
        }
      };

      this.redirectFromSubscription = (sub) => {
        if (isEmpty(sub) || isUndefined(sub)) return;

        if (this.isGdprSub(sub)) {
          if (/^\/?subscription\/.*\/overview\/?$/.test(this.props.location.pathname)) {
            this.props.router.replace({
              pathname: this.getPath(sub),
              search: '?collapsed=true',
            });
          }
        }
      };

      this.redirectFromGdprPage = (sub) => {
        if (isEmpty(sub) || isUndefined(sub)) return;
        if (/^\/?subscription\/.*\/gdpr\/?$/.test(this.props.location.pathname)) {
          this.props.router.replace({
            pathname: this.getPath(sub),
            search: '?collapsed=true',
          });
        }
      };

      if (!this.props.subs.length) {
        this.props.loadAllSubs();
      } else {
        const sub = this.getSub(this.props.subs);

        if (source === 'dashboard') {
          this.redirectFromDashboard(this.props.subs);
        } else if (source === 'subscription') {
          this.redirectFromSubscription(sub);
        } else {
          this.redirectFromGdprPage(sub);
        }
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.subs.length && this.props.subs.length !== prevProps.subs.length) {
        const sub = this.getSub(this.props.subs);
        if (source === 'dashboard') {
          this.redirectFromDashboard(this.props.subs);
        } else if (source === 'subscription') {
          this.redirectFromSubscription(sub);
        } else {
          this.redirectFromGdprPage(sub);
        }
      } else if (
        this.props.location.pathname !== prevProps.location.pathname &&
        /^\/?subscription\/.*\/gdpr\/?$/.test(this.props.location.pathname)
      ) {
        const sub = this.getSub(this.props.subs);
        this.redirectFromGdprPage(sub);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  Redirect.propTypes = {
    loadAllSubs: PropTypes.func.isRequired,
    subs: PropTypes.arrayOf(PropTypes.shape()),
  };

  Redirect.defaultProps = { subs: [] };

  const mapStateToProps = (state) => ({
    subs: state.subscriptions,
  });

  const mapDispatchToProps = (dispatch) => ({
    loadAllSubs: () => dispatch(SubscriptionsActions.loadAll()),
  });

  return connect(mapStateToProps, mapDispatchToProps)(withRouter(Redirect));
};

export default withGdprRedirect;
