import { endsWith } from 'lodash/string';

export function getGdprMsUrl(state) {
  let gdprMicroserviceURL = null;
  if (state.subscription.url) {
    gdprMicroserviceURL = state.subscription.url;
    if (!endsWith(gdprMicroserviceURL, '/')) {
      gdprMicroserviceURL += '/';
    }
    gdprMicroserviceURL += 'systems/gdpr-template-gdpr-ms/proxy';
  }
  return gdprMicroserviceURL;
}
