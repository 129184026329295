import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import CollapsePanel from 'Common/CollapsePanel/CollapsePanel';
import VpnConnection from '../vpn-connection';
import './style.css';

function VpnEditor(props) {
  const vpnConnections = get(props, 'vpn.connections', []);
  const [guiConnections, setGuiConnections] = useState(vpnConnections);
  const [disabledEditButtons, setDisabledEditButtons] = useState(
    vpnConnections.length !== 0
      ? vpnConnections.map(() => {
          return [true];
        })
      : []
  );

  function vpnObjectCreator(connections) {
    const vpn = { ...props.vpn };
    vpn.connections = connections.map((con) => {
      const obj = {};
      obj._id = con._id;
      if (con.gateway_address) obj.gateway_address = con.gateway_address;
      if (con.address_spaces.length !== 0) obj.address_spaces = [...con.address_spaces];
      if (con.shared_key) obj.shared_key = con.shared_key;
      if (con.asn) obj.asn = con.asn;
      if (con.bgp_peering_address) obj.bgp_peering_address = con.bgp_peering_address;
      if (con.ipsec_policy) obj.ipsec_policy = { ...con.ipsec_policy };
      return obj;
    });
    return vpn;
  }

  function editConnectionHandler(index) {
    if (
      guiConnections[index].gateway_address === '' ||
      guiConnections[index].address_spaces.length === 0 ||
      guiConnections[index].shared_key === ''
    )
      return;

    setDisabledEditButtons(
      disabledEditButtons.map((d, i) => {
        if (i === index) return [true];
        return d;
      })
    );

    const editedCons = vpnConnections.map((con, i) => {
      if (i === index) {
        return {
          ...con,
          _id: vpnConnections[i]._id,
          gateway_address: guiConnections[i].gateway_address,
          address_spaces: [...guiConnections[i].address_spaces],
          shared_key: guiConnections[i].shared_key,
        };
      }
      return con;
    });

    const vpn = vpnObjectCreator(editedCons);
    props.onChange(vpn);
  }

  function deleteConnectionHandler(index) {
    if (confirm('Are you sure you want to delete this connection?')) {
      setDisabledEditButtons(
        disabledEditButtons.filter((d, i) => {
          if (i !== index) return d;
        })
      );
      const remainingCons = guiConnections.filter((_, i) => {
        return index !== i;
      });
      setGuiConnections(remainingCons);

      const vpn = vpnObjectCreator(remainingCons);
      props.onChange(vpn);
    }
  }

  function editFormHandleChange(e, index, con) {
    const { name, value } = e.target;
    const newState = [...guiConnections];
    const newDisabledEditButtons = [...disabledEditButtons];
    const oldAddressSpaces = vpnConnections[index].address_spaces
      ? vpnConnections[index].address_spaces
      : [];

    switch (name) {
      case 'gateway_address':
        newState[index] = {
          gateway_address: value,
          address_spaces: con.address_spaces ? [...con.address_spaces] : [],
          shared_key: con.shared_key,
        };
        if (vpnConnections[index].gateway_address !== value)
          newDisabledEditButtons[index][0] = false;
        else newDisabledEditButtons[index][0] = true;
        break;
      case 'address_spaces':
        newState[index] = {
          gateway_address: con.gateway_address,
          address_spaces: value ? [...value] : [],
          shared_key: con.shared_key,
        };
        if (value.length !== oldAddressSpaces.length) newDisabledEditButtons[index][1] = false;
        else if (
          value.some((v) => {
            if (!oldAddressSpaces.includes(v)) return true;
          })
        )
          newDisabledEditButtons[index][1] = false;
        else newDisabledEditButtons[index][1] = true;
        break;
      case 'shared_key':
        newState[index] = {
          gateway_address: con.gateway_address,
          address_spaces: con.address_spaces ? [...con.address_spaces] : [],
          shared_key: value,
        };
        if (vpnConnections[index].shared_key !== value) newDisabledEditButtons[index][2] = false;
        else newDisabledEditButtons[index][2] = true;
        break;
      default:
        console.log('Error handling edit connection form in vpn editor...!');
        break;
    }

    setGuiConnections([...newState]);
    setDisabledEditButtons([...newDisabledEditButtons]);
  }

  function setGuiConnectionsWrapper(gatewayAddress, addressSpaces, sharedKey) {
    setGuiConnections([
      ...guiConnections,
      {
        gateway_address: gatewayAddress,
        address_spaces: [...addressSpaces],
        shared_key: sharedKey,
      },
    ]);
  }

  function setDisabledEditButtonsWrapper(disabled) {
    setDisabledEditButtons([...disabledEditButtons, [disabled]]);
  }

  return (
    <main className="scrollArea">
      <div className="row">
        <div className="col gr-secondary vpn-tab-primary">
          <h2 className="heading-section">Add or modify VPN details</h2>
          <VpnConnection
            onChange={props.onChange}
            parentSetGuiConnections={setGuiConnectionsWrapper}
            parentSetDisabledEditButtons={setDisabledEditButtonsWrapper}
            mode={'new'}
            disabledEditButtons={disabledEditButtons}
            vpn={props.vpn}
          />
        </div>

        <div className="col gr-primary vpn-tab-secondary">
          <h2 className="heading-section">Existing VPN connections</h2>
          {guiConnections.map((con, index) => {
            return (
              <CollapsePanel title={`Connection ${index + 1}`} startCollapsed={false} key={index}>
                <VpnConnection
                  onChange={props.onChange}
                  parentSetGuiConnections={setGuiConnectionsWrapper}
                  parentSetDisabledEditButtons={setDisabledEditButtonsWrapper}
                  mode={'edit'}
                  editFormHandleChange={editFormHandleChange}
                  editConnectionHandler={editConnectionHandler}
                  deleteConnectionHandler={deleteConnectionHandler}
                  disabledEditButtons={disabledEditButtons}
                  index={index}
                  con={con}
                  vpn={props.vpn}
                />
              </CollapsePanel>
            );
          })}
        </div>
      </div>
    </main>
  );
}

VpnEditor.propTypes = {
  vpn: PropTypes.shape({
    enable_snat: PropTypes.bool,
    sesam_gateway: PropTypes.string,
    sesam_address_spaces: PropTypes.arrayOf(PropTypes.string),
    connections: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.number,
        gateway_address: PropTypes.string,
        address_spaces: PropTypes.arrayOf(PropTypes.string),
        shared_key: PropTypes.string,
      })
    ),
    tier: PropTypes.number,
  }),
  onChange: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default VpnEditor;
