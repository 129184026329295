import { sfetchJson } from 'Internals/sfetch';
import { ApiConf  } from '../types/types';

/**
 * Retrieve all properties in subscription
 * @param {{ subUrl: string, token: string }} apiConf Configuration for the API call
 * @returns {Promise<*>}
 */
async function getProperties(apiConf: ApiConf) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/lineage/properties`;
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  };
  return await sfetchJson(url, options);
}

/**
 * Get a property graph for a specific property
 * @param {{ subUrl: string, token: string }} apiConf Configuration for the API call
 * @param {string} property
 * @returns {Promise<*>}
 */
async function getPropertyGraph(apiConf: ApiConf, property: string) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/lineage/properties/${property}`;
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
    },
    method: 'GET',
  };
  return await sfetchJson(url, options);
}

/**
 * Get the root response of the lineage service
 * @param {{ subUrl: string, token: string }} apiConf Configuration for the API call
 * @returns {Promise<*>}
 */
async function getRoot(apiConf: ApiConf) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/lineage`;
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
    },
    method: 'GET',
  };
  return await sfetchJson(url, options);
}

export default {
  getProperties,
  getPropertyGraph,
  getRoot
};

