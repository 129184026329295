import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

import Box from 'Common/Box/Box';
import SearchResult from './SearchResult';
import SearchResultSkeleton from './SearchResultSkeleton';
import SearchResultSkeletonList from './SearchResultSkeletonList';
import { SearchResult as SearchResultType, SomeObject } from '../../types/types';

interface SearchResultListProps {
  hasMore: boolean;
  isEmpty: boolean;
  onClickResult: (id: string) => void;
  results: SomeObject[];
  searching: boolean;
  selectedEntityInfo: SearchResultType;
  setSelectedEntityInfo: (entityInfo: SearchResultType) => void;
  subId: string;
  totalHits: number | null;
}

const useStyle = makeStyles({
  loadingArea: {
    height: '73px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  result: {
    marginBottom: '10px',
  },
});

const SearchResultList: React.FC<SearchResultListProps> = ({
  hasMore,
  isEmpty,
  onClickResult,
  results,
  searching,
  selectedEntityInfo,
  setSelectedEntityInfo,
  subId,
  totalHits,
}) => {
  const classes = useStyle();
  return (
    <Box noScroll>
      <Box.Header>
        <Box.Title>Results{totalHits !== null && ` ${totalHits}`}</Box.Title>
      </Box.Header>
      <Box.Content white>
        {searching === true && <SearchResultSkeletonList />}
        {searching === false && isEmpty === true && (
          <ListItem style={{ height: '73px' }}>
            <ListItemText>No entities found!</ListItemText>
          </ListItem>
        )}
        {searching === false && isEmpty === false && (
          <React.Fragment>
            <List disablePadding>
              {results.map((result: SomeObject) => (
                <SearchResult
                  key={result._id}
                  className={classes.result}
                  onClickResult={onClickResult}
                  result={result}
                  selectedEntityInfo={selectedEntityInfo}
                  setSelectedEntityInfo={setSelectedEntityInfo}
                  subId={subId}
                />
              ))}
            </List>
            {hasMore && (
              <div className={classes.loadingArea}>
                <SearchResultSkeleton />
              </div>
            )}
          </React.Fragment>
        )}
      </Box.Content>
    </Box>
  );
};

export default SearchResultList;
