import React from 'react';
import PropTypes from 'prop-types';

import PipeAnalyseExecution from '../pipe-analyse-execution';
import PipeAnalyseRawData from '../pipe-analyse-raw-data';

const PipeAnalysePanel = function ({ analysis, analysisReady, pipe, show }) {
  if (!show) {
    return null;
  }
  return (
    <div className="stretchy-panel editor__panel editor__panel--extra">
      <h3 className="head">Analyse</h3>
      <div className="body">
        <div className="stack">
          <PipeAnalyseExecution
            className="stack__item stack__item--1x"
            analysis={analysis}
            analysisReady={analysisReady}
          />
          <PipeAnalyseRawData className="stack__item stack__item--1x" pipe={pipe} />
        </div>
      </div>
    </div>
  );
};

PipeAnalysePanel.propTypes = {
  analysisReady: PropTypes.bool,
  analysis: PropTypes.any,
  pipe: PropTypes.object,
  show: PropTypes.bool,
};

export default PipeAnalysePanel;
