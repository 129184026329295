import { sfetchJson, sfetchText } from 'Internals/sfetch';
import { SubscriptionJWT } from '../types/types';
import { Json } from '../types/json';

async function post(subId: string, jwt: string): Promise<SubscriptionJWT> {
  const options: RequestInit = {
    body: JSON.stringify(jwt),
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  };
  return await sfetchJson(`/api/subscriptions/${subId}/api_json_web_tokens`, options);
}

async function remove(subId: string, id: string): Promise<Json> {
  const url = `/api/subscriptions/${subId}/api_json_web_tokens/${id}`;
  const options: RequestInit = {
    credentials: 'include',
    method: 'DELETE',
  };
  return await sfetchJson(url, options);
}

async function getAll(subId: string): Promise<SubscriptionJWT[]> {
  const url = `/api/subscriptions/${subId}/api_json_web_tokens`;
  return await sfetchJson(url, { credentials: 'include' });
}

async function get(portalUrl: string, subId: string) {
  return await sfetchText(`${portalUrl}/subscriptions/${subId}/jwt`, {
    credentials: 'include',
  });
}

export default {
  post,
  remove,
  getAll,
  get,
};
