import React from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import SesamDateTimePicker from 'Common/SesamDateTimePicker/SesamDateTimePicker';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

const DatasetInspectorFilterJump = (props) => {
  const {
    jumpUpdatedValue,
    jumpType,
    jumpSequenceValue,
    onJumpReset,
    onJumpSequenceValueChanged,
    onJumpUpdatedValueChanged,
  } = props;

  return (
    <div className="dataset-inspector-filter__jump-field">
      {jumpType === 'sequence' ? (
        <SesamTextField
          margin="none"
          className="dataset-inspector-filter__jump-field-input"
          value={!isUndefined(jumpSequenceValue) ? jumpSequenceValue : ''}
          onChange={(ev) => onJumpSequenceValueChanged(parseInt(ev.target.value))}
          inputProps={{ type: 'number' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" size="small" onClick={onJumpReset}>
                  <Clear fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <div className="dataset-inspector-filter__jump-field-timestamp">
          <div className="dataset-inspector-filter__jump-field-timestamp-input">
            <SesamDateTimePicker
              autoOk
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onJumpReset();
                      }}
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              TextFieldComponent={(props) => <SesamTextField {...props} margin="none" />}
              value={!isUndefined(jumpUpdatedValue) ? new Date(jumpUpdatedValue) : null}
              onChange={(dateTime) => onJumpUpdatedValueChanged(dateTime.valueOf())}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DatasetInspectorFilterJump.propTypes = {
  jumpSequenceValue: PropTypes.number,
  jumpType: PropTypes.string.isRequired,
  jumpUpdatedValue: PropTypes.number,
  onJumpReset: PropTypes.func.isRequired,
  onJumpSequenceValueChanged: PropTypes.func.isRequired,
  onJumpUpdatedValueChanged: PropTypes.func.isRequired,
};

export default DatasetInspectorFilterJump;
