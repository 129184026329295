import { useMemo } from 'react';
import { Flow } from 'Internals/overview-utils';
import { InboundMap, OutboundMap, Pipe, PipeMap, System, TransformMap } from 'Types/types';

import uniqBy from 'lodash/uniqBy';

import {
  getPipesFromSystem,
  getPipesToSystem,
  getPipesWithSystemTransform,
} from 'Internals/systems';
import { isValidConfig, isRunError } from 'Internals/pipes';
import { getBranchColor } from 'Internals/graph';
import {
  coalescePipesIntoEndpointNodes,
  Group,
  isNotInternal,
} from '../subscription/pages/overview/overviewUtils';

type SystemNodesProps = {
  systemNodes: Group[];
  pipes: PipeMap;
  inbounds: InboundMap;
  outbounds: OutboundMap;
  transforms: TransformMap;
  darkModeActive: boolean;
  flows?: Flow[];
  subId: string;
};

const truncate = (str: string, n: number) => (str.length > n ? `${str.substr(0, n - 4)} ...` : str);

const getPipeDirections = (
  systems: System[],
  pipes: PipeMap,
  inbounds: InboundMap,
  outbounds: OutboundMap,
  transforms: TransformMap
) => {
  let pipesFrom: Pipe[] = [];
  let pipesTo: Pipe[] = [];
  let hasRestTransforms = false;

  for (const system of systems) {
    let _pipesFrom = getPipesFromSystem(system._id, pipes, inbounds);
    let _pipesTo = getPipesToSystem(system._id, pipes, outbounds);

    pipesFrom = pipesFrom.concat(_pipesFrom);
    pipesTo = pipesTo.concat(_pipesTo);

    // to show pipes with rest transform in the chart
    const pipesWithSystemTransform = getPipesWithSystemTransform(system._id, pipes, transforms);
    hasRestTransforms = !!pipesWithSystemTransform.length;
    pipesFrom = uniqBy(pipesFrom.concat(pipesWithSystemTransform), (pipe) => pipe._id);
    pipesTo = uniqBy(pipesTo.concat(pipesWithSystemTransform), (pipe) => pipe._id);
  }

  return { pipesFrom, pipesTo, hasRestTransforms };
};

const getCurrentBranchColor = (
  flows: Flow[],
  pipes: PipeMap,
  groupSystemIds: string[],
  pipesFrom: Pipe[],
  pipesTo: Pipe[],
  darkModeActive: boolean
) => {
  let branchColor = darkModeActive ? 'white' : 'black';
  if (flows) {
    const flowsFromToSystems = flows
      .filter((flow) => {
        if (flow.kind === 'system') {
          return groupSystemIds.includes(flow.system);
        } else return false;
      })
      .map((flow) => flow.path);

    const allPipesInFlows = flowsFromToSystems.reduce((acc, flow) => {
      flow.forEach((node) => {
        if (!acc.includes(node)) {
          acc.push(node);
        }
      });
      return acc;
    }, []);

    const allPipesOutsideFlows = [...pipesFrom, ...pipesTo]
      .filter((pipe) => !allPipesInFlows.includes(pipe['_id']))
      .map((p) => p['_id']);

    branchColor = getBranchColor(flowsFromToSystems, allPipesOutsideFlows, pipes, darkModeActive);
  }

  return branchColor;
};

const getDirection = (pipesFrom: Pipe[], pipesTo: Pipe[]) => {
  let direction;
  if (pipesFrom.length > 0 && pipesTo.length > 0) {
    direction = 'both';
  } else if (pipesTo.length > 0) {
    direction = 'outbound';
  } else if (pipesFrom.length > 0) {
    direction = 'inbound';
  } else {
    direction = 'none';
  }
  return direction;
};

const getIsOutboundError = (pipesFrom: Pipe[]) =>
  pipesFrom.reduce((acc, pipe) => {
    const isConfigError = !isValidConfig(pipe);
    const isRunErr = isRunError(pipe);
    return acc && isConfigError && isRunErr;
  }, false);

const getPipeLinks = (
  groupSystemIds: string[],
  groupSystemLinks: string[],
  hasRestTransforms: boolean,
  subId: string
) => {
  let pipesFromQueryParams;
  if (hasRestTransforms)
    pipesFromQueryParams = groupSystemLinks
      .map((link: string) => `Transforms.selected=${link}`)
      .join('&');
  else
    pipesFromQueryParams = groupSystemLinks
      .map((link: string) => `From.selected=${link}`)
      .join('&');

  let pipesFromLink = `/subscription/${subId}/pipes/all?${pipesFromQueryParams}`;

  let pipesToQueryParams;
  if (hasRestTransforms)
    pipesToQueryParams = groupSystemLinks
      .map((link: string) => `Transforms.selected=${link}`)
      .join('&');
  else pipesToQueryParams = groupSystemLinks.map((link: string) => `To.selected=${link}`).join('&');

  const pipesToLink = `/subscription/${subId}/pipes/all?${pipesToQueryParams}`;

  let link = '';
  if (groupSystemIds.length === 1) {
    link = `/subscription/${subId}/systems/system/${groupSystemLinks[0]}/overview`;
  } else {
    const queryParams = groupSystemLinks.map((link: string) => `System.selected=${link}`).join('&');

    link = `/subscription/${subId}/systems/all?${queryParams}`;
  }

  return { pipesFromLink, pipesToLink, link };
};

const getEndpointNodes = (pipes: PipeMap, subId: string, darkModeActive: boolean) => {
  const endpointsMap = Object.values(pipes)
    .filter(isNotInternal)
    .reduce(coalescePipesIntoEndpointNodes, {});

  const endpointNodes = Object.entries(endpointsMap).map(([k, v]) => {
    const branchColor = getBranchColor([], v.pipes, pipes, darkModeActive);
    return {
      id: k,
      name: k,
      direction: v.direction,
      pipesFrom: v.pipesOut,
      pipesTo: v.pipesIn,
      link: '',
      pipesFromLink: `/subscription/${subId}/pipes/all?Source type.selected=${k}&Origin.selected=user`,
      pipesToLink: `/subscription/${subId}/pipes/all?Sink type.selected=${k}&Origin.selected=user`,
      isInboundError: v.isInboundError,
      isOutboundError: v.isOutboundError,
      branchColor,
    };
  });

  return endpointNodes;
};

export const useSystemNodes = (props: SystemNodesProps) => {
  const { systemNodes, pipes, inbounds, outbounds, transforms, darkModeActive, flows, subId } =
    props;

  const results = useMemo(() => {
    const calculatedSystemNodes = systemNodes.map((group) => {
      const groupSystemIds = group.systems.map((system: System) => system._id);
      const groupSystemLinks = group.systems.map((system: System) => system.link);

      const { pipesFrom, pipesTo, hasRestTransforms } = getPipeDirections(
        group.systems,
        pipes,
        inbounds,
        outbounds,
        transforms
      );

      const branchColor = getCurrentBranchColor(
        flows,
        pipes,
        groupSystemIds,
        pipesFrom,
        pipesTo,
        darkModeActive
      );

      const isOutboundError = getIsOutboundError(pipesFrom);

      const isInboundError = pipesTo.reduce((acc, pipe) => {
        const isConfigError = !isValidConfig(pipe);
        const isRunErr = isRunError(pipe);
        return acc && isConfigError && isRunErr;
      }, false);

      const direction = getDirection(pipesFrom, pipesTo);

      const { pipesFromLink, pipesToLink, link } = getPipeLinks(
        groupSystemIds,
        groupSystemLinks,
        hasRestTransforms,
        subId
      );

      return {
        id: group.id,
        name: truncate(group.id, 18),
        pipesFrom: pipesFrom.length,
        pipesTo: pipesTo.length,
        direction,
        pipesFromLink,
        pipesToLink,
        link,
        isInboundError,
        isOutboundError,
        branchColor,
      };
    });

    const endpointNodes = getEndpointNodes(pipes, subId, darkModeActive);

    const allSystemNodes = [...calculatedSystemNodes, ...endpointNodes];

    return allSystemNodes;
  }, [systemNodes, pipes, inbounds, outbounds, transforms, darkModeActive, flows, subId]);

  return results;
};
