import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import get from 'lodash/get';

import { ActionBarStatus } from '../../../components/action-bar';
import { clone } from 'Redux/utils';
import { LoadingPanel } from 'Common/LoadingPanel';
import Page from '../../../components/page';
import PageHeader, { PageHeaderTitle } from '../../../components/page-header';
import PipeActions from 'Redux/thunks/pipes';
import PipeEditor from '../../../components/pipe-editor';
import SystemActions from 'Redux/thunks/systems';
import { systemsSelector } from 'Redux/selectors';
import TabbedNav from '../../../components/tabbed-nav';
import { getFromLocalStorage } from 'Internals/local-storage';
import { DEFAULT_CONFIG_GROUP_ID } from 'Redux/selectors';

const CONFIG_EDITOR_LS_KEY = 'sesam--config-editor';

class NewPipe extends React.Component {
  constructor(props) {
    super(props);

    this.loadConfigGroupFromLS = () => {
      const configGroupFromLS = getFromLocalStorage(
        CONFIG_EDITOR_LS_KEY,
        ['configGroup'],
        DEFAULT_CONFIG_GROUP_ID
      );
      return configGroupFromLS;
    };

    this.save = (pipe) => {
      this.setState({
        status: ActionBarStatus.waiting,
        shortMessage: 'Saving…',
        message: '',
      });

      return this.props.save(pipe).catch((err) => {
        this.setState({
          status: ActionBarStatus.error,
          shortMessage: 'Could not save pipe',
          message: err.toString(),
        });

        throw new Error(err);
      });
    };

    this.afterSave = (pipe) => {
      const link = encodeURIComponent(pipe._id);
      this.context.router.push(`/subscription/${this.props.subId}/pipes/pipe/${link}/edit`);
    };

    this.state = {
      loadingSourcePipe: false,
      loadedSourcePipe: false,
      message: '',
      shortMessage: '',
      status: ActionBarStatus.default,
    };
  }

  componentDidMount() {
    this.props.loadSystems();

    if (this.props.params.pipeID) {
      this.setState({ loadingSourcePipe: true });
      this.props
        .loadPipe(this.props.params.pipeID)
        .then(() => this.setState({ loadingSourcePipe: false, loadedSourcePipe: true }));
    }
  }

  render() {
    let defaultPipe = {};
    if (this.props.params.pipeID) {
      // This means we are duplicating a pipe
      if (this.state.loadingSourcePipe) {
        return <LoadingPanel />;
      }
      if (this.state.loadedSourcePipe) {
        defaultPipe = clone(this.props.sourcePipe.config.original);
        defaultPipe._id = `${this.props.params.pipeID}-copy`;
      }
    } else {
      defaultPipe = {
        _id: null,
        type: 'pipe',
      };
      const sourceDataset = get(this, 'props.location.query.source');
      if (sourceDataset) {
        defaultPipe.source = { type: 'dataset', dataset: sourceDataset };
      }
      const newId = get(this, 'props.location.query.id');
      if (newId) defaultPipe._id = newId;
    }

    const savedConfigGroup = this.loadConfigGroupFromLS();

    if (savedConfigGroup !== DEFAULT_CONFIG_GROUP_ID) {
      defaultPipe.metadata = {
        '$config-group': savedConfigGroup,
      };
    }

    return (
      <Page>
        <PageHeader>
          <PageHeaderTitle>
            <SesamLink to={`/subscription/${this.props.subId}/pipes`}>Pipes</SesamLink>
          </PageHeaderTitle>
          <PageHeaderTitle>New pipe</PageHeaderTitle>
        </PageHeader>
        <TabbedNav
          nav={[
            {
              label: 'Config',
              to: this.props.location.pathname,
            },
          ]}
        >
          <PipeEditor
            initialValue={defaultPipe}
            ready={false}
            initialSaved={false}
            message={this.state.message}
            onSave={this.save}
            onAfterSave={this.afterSave}
            shortMessage={this.state.shortMessage}
            status={this.state.status}
            systems={this.props.systems}
            isDuplicatedPipe={Boolean(this.props.params.pipeID)}
            isNewPipe={true}
          />
        </TabbedNav>
      </Page>
    );
  }
}

NewPipe.propTypes = {
  loadPipe: PropTypes.func.isRequired,
  loadSystems: PropTypes.func.isRequired,
  params: PropTypes.shape({
    pipeID: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape(),
  save: PropTypes.func.isRequired,
  sourcePipe: PropTypes.object,
  subId: PropTypes.string.isRequired,
  systems: PropTypes.array.isRequired,
};

NewPipe.contextTypes = {
  // https://github.com/reactjs/react-router/issues/975
  router: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  return {
    sourcePipe: state.pipes[ownProps.params.pipeID],
    subId: state.subscription.id,
    systems: systemsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadPipe: (id) => dispatch(PipeActions.load(id)),
  loadSystems: () => dispatch(SystemActions.loadAll(true)),
  save: (pipe) => dispatch(PipeActions.add(pipe)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPipe);
