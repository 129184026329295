import React from 'react';
import PropTypes from 'prop-types';

import { buildClassName, ClassNamePropType } from 'Internals/react-utils';

import './style.css';

const Page = function ({ children, className, dataSelenium }) {
  const attrs = {};

  if (dataSelenium) {
    attrs['data-selenium'] = dataSelenium;
  }

  return (
    <article className={buildClassName('page', className)} {...attrs}>
      {children}
    </article>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  className: ClassNamePropType,
  dataSelenium: PropTypes.string,
};

export default Page;
