import React from 'react';
import { StatusClassName } from './PipeStatus';
import State from 'Common/renderers/State/State';

export const PipePopulationStatus: React.FC<{ isPopulated: boolean }> = ({ isPopulated = true }) =>
  isPopulated ? (
    <State title="Dataset is populated" className={StatusClassName.GREEN} text="Yes" />
  ) : (
    <State title="Dataset is not populated" className={StatusClassName.RED} text="No" />
  );
