/* eslint no-underscore-dangle:
  ["error", { "allow": ["_id", "_deleted", "_previous", "_updated", "_ts"] }]
*/
import './DatasetInspectorStyle.css';

import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { apologise } from 'Redux/thunks/apology';
import DatasetActions from 'Redux/thunks/datasets';
import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';
import { getType } from 'Internals/datasets';
import { getUpstreamPipeFromDatasetId } from 'Internals/pipes';
import { registerKey, unregisterKey } from 'Internals/global-shortcuts';
import { LoadingPanel } from 'Common/LoadingPanel';
import Button from 'Common/Button/Button';
import { confirmBefore } from 'Common/Confirmation';

import { useDatasetInspectorState } from '../../hooks/useDatasetInspector/useDatasetInspectorState';
import { DatasetInspectorBrowser } from './dataset-inspector-browser/DatasetInspectorBrowser';
import { DatasetInspectorFilter } from './dataset-inspector-filter/DatasetInspectorFilter';
import DatasetInspectorTable from './dataset-inspector-table/DatasetInspectorTable';
import DatasetInspectorViewer from './dataset-inspector-viewer/DatasetInspectorViewer';
import PostEntityModal from './PostEntityModal';
import { DatasetInspectorContext } from './DatasetInspectorContext';
import { apiConfSelector } from 'Redux/selectors';

const LOCAL_STORAGE_KEY = 'sesam--entityview-filter';
const TABLE_VIEW_KEY = 'showTableView';

const DatasetInspectorContainer = (props) => {
  const {
    addCustomActions,
    datasetID,
    originalDataset,
    dataset,
    loadDataset,
    params,
    pipes,
    registerRefresh,
    removeCustomActions,
    router,
    subUrl,
    token,
    unregisterRefresh,
    upstreams,
    datasetsLoaded,
    apologise,
    subset,
    displayRepostAll,
    targetDatasetId,
  } = props;

  const {
    filter,
    loadMore,
    loadMoreTop,
    loadNextEntity,
    loadPreviousEntity,
    navigateNext,
    navigatePrevious,
    populate,
    postDeadEntity,
    repostDeadEntities,
    postEntities,
    postJson,
    postReset,
    repost,
    reset,
    resetJump,
    resetPreviousEntity,
    resetSearch,
    search,
    selectEntity,
    setFilter,
    setJumpSequenceValue,
    setJumpType,
    setJumpUpdatedValue,
    setPost,
    setPostValid,
    setSearchType,
    setSearchValue,
    setUncommitted,
    sendPost,
    sourceEntityPath,
    state,
    stopSearch,
    testPreviousEntity,
  } = useDatasetInspectorState({
    apologise,
    dataset,
    datasetID,
    datasetsLoaded,
    loadDataset,
    originalDataset,
    params,
    pipes,
    router,
    subUrl,
    token,
    upstreams,
    subset,
    targetDatasetId,
  });

  const persistedShowTableView = getFromLocalStorage(LOCAL_STORAGE_KEY, TABLE_VIEW_KEY, false);

  const [showTableView, setShowTableView] = useState(persistedShowTableView);

  function toggleTableView() {
    setShowTableView((tw) => !tw);
  }

  useEffect(() => {
    populate();
  }, [props.params.datasetID]);

  useEffect(() => {
    /**
     * Keep {state.showTableView} in local storage
     */
    setIntoLocalStorage(LOCAL_STORAGE_KEY, TABLE_VIEW_KEY, showTableView);
  }, [showTableView]);

  const currentEl = useRef(null);

  const itemElement = useRef(null);

  const listElement = useRef(null);

  const prevScrollTop = useRef(0);

  const tableContainerRef = useRef(null);

  /**
   * Register refresh handlers
   */
  useEffect(() => {
    function refresh() {
      populate();
      loadDataset();
    }
    registerRefresh(refresh);

    return () => {
      unregisterRefresh(refresh);
    };
  });

  /**
   * The loading of new entities is done by scrolling down or up in the enities list.
   * {props.hasMore} & {props.hasMoreTop} lets us know whether the dataset has more entities
   * to append or prepend to the current list.
   */
  function handleScroll() {
    if (!state.populating) {
      const container = showTableView ? tableContainerRef.current : listElement.current;
      const itemHeight = showTableView ? 32 : 69;

      prevScrollTop.current = container.scrollTop;

      /**
       * If {scrollTop} is within 2 * {itemHeight} from the bottom of the {container} and the dataset
       * has more entities to append, then load more entitieds and append them to the current list of entitites
       */
      if (container.clientHeight + container.scrollTop >= container.scrollHeight - itemHeight * 2) {
        if (state.hasMore && !state.loadingMore) {
          // only load if we are not already loading
          loadMore();
        }
      }

      /**
       * If {scrollTop} is within 2 * {itemHeight} from the top of the {container} and the dataset
       * has more entitites to prepend, then load more entities and prepend to the current list of entities
       */
      if (container.scrollTop < itemHeight * 2) {
        if (state.hasMoreTop && !state.loadingMoreTop) {
          // only load if we are not already loading
          loadMoreTop();
        }
      }
    }
  }

  const upstreamPipeId = useMemo(() => {
    if (!dataset) {
      return undefined;
    }

    const upstreamPipe = getUpstreamPipeFromDatasetId(dataset._id, pipes, upstreams);
    return upstreamPipe ? upstreamPipe['_id'] : undefined;
  }, [dataset, pipes, upstreams]);

  useLayoutEffect(() => {
    if (currentEl && currentEl.current && !state.populating) {
      currentEl.current.scrollIntoView({
        block: 'nearest',
      });
    }
  }, [state.selectedEntity, state.populating]);

  /**
   * Register key handlers for moving up and down the list of entities
   */
  useEffect(() => {
    function next(ev) {
      ev.preventDefault();
      navigateNext();
    }

    function prev(ev) {
      ev.preventDefault();
      navigatePrevious();
    }

    registerKey('j', next);
    registerKey('ArrowDown', next);
    registerKey('k', prev);
    registerKey('ArrowUp', prev);

    return () => {
      unregisterKey('j');
      unregisterKey('ArrowDown');
      unregisterKey('k');
      unregisterKey('ArrowUp');
    };
  });

  /**
   * Scroll from top to make sure the selected entity from
   * a jump always stays inside the list client window
   */
  useEffect(() => {
    let containerEl = showTableView ? tableContainerRef.current : listElement.current;
    let itemHeight = showTableView ? 30 : 70;

    if (state.hasMoreTop) {
      if (containerEl.clientHeight < containerEl.scrollHeight) {
        if (containerEl.scrollTop < itemHeight) {
          containerEl.scrollTop = prevScrollTop.current + (containerEl.clientHeight - itemHeight);
        }
      }
    }
  }, [state.entities, state.selectedEntity, state.hasMoreTop, showTableView]);

  const rollbackDataset = () => {
    props.rollback(props.datasetID);
  };

  const commitDataset = () => {
    props.commit(props.datasetID);
  };

  const circuitBreakerHasTripped = dataset && dataset.runtime['circuit-breaker-tripped'];

  const circuitBreakerButtons = (
    <React.Fragment>
      {circuitBreakerHasTripped && (
        <Button
          key="roll-back-dataset"
          onClick={() =>
            confirmBefore('Are you sure you want to roll back this dataset?', rollbackDataset)
          }
        >
          Roll back dataset
        </Button>
      )}
      {circuitBreakerHasTripped && (
        <Button
          key="commit-dataset"
          onClick={() =>
            confirmBefore('Are you sure you want to commit this dataset?', commitDataset)
          }
        >
          Commit dataset
        </Button>
      )}
    </React.Fragment>
  );

  /**
   * Add custom actions to the actions in the top right corner
   */
  useEffect(() => {
    let actions = [
      <Button theme="primary" key="postEntity" onClick={() => postEntities()}>
        Post entity…
      </Button>,
    ];

    if (circuitBreakerHasTripped) {
      actions.push(circuitBreakerButtons);
    }
    addCustomActions(actions);

    return () => {
      removeCustomActions();
    };
  }, [addCustomActions, removeCustomActions, circuitBreakerHasTripped]);

  const apiConfBase = useSelector(apiConfSelector);

  const apiConf = useMemo(() => {
    return {
      ...apiConfBase,
      dataset: encodeURIComponent(dataset?._id),
    };
  }, [apiConfBase, dataset?._id]);

  const handleEntityRef = useCallback(
    (el, entity, idx) => {
      if (state.selectedEntity === entity) {
        currentEl.current = el;
      }
      if (idx === 0) {
        itemElement.current = el;
      }
    },
    [state.selectedEntity]
  );

  const actions = {
    loadNextEntity,
    loadPreviousEntity,
    postDeadEntity,
    repost,
    resetJump,
    reset,
    resetPreviousEntity,
    resetSearch,
    search,
    selectEntity,
    sendPost,
    setFilter,
    setJumpSequenceValue,
    setJumpType,
    setJumpUpdatedValue,
    setSearchType,
    setSearchValue,
    setUncommitted,
    testPreviousEntity,
    toggleTableView,
  };

  if (!dataset) {
    return <LoadingPanel />;
  }

  return (
    <DatasetInspectorContext.Provider value={[state, actions, showTableView, datasetID]}>
      <div className="dataset-inspector">
        <div className="dataset-inspector__filter">
          <DatasetInspectorFilter
            dataset={dataset}
            filter={filter}
            subset={subset}
            entities={state.entities}
            pipes={pipes}
          />
        </div>
        {showTableView ? (
          <DatasetInspectorTable
            onScroll={handleScroll}
            onSetTableContainerRef={(el) => {
              tableContainerRef.current = el;
            }}
            showingSearchResults={state.showingFreetextResults || state.showingIdResults}
            upstreamPipeId={upstreamPipeId}
            dataset={dataset}
            filter={filter}
          />
        ) : (
          <div className="dataset-inspector__browser">
            <DatasetInspectorBrowser
              onScroll={handleScroll}
              onSetRef={(el) => {
                listElement.current = el;
              }}
              onEntityRef={handleEntityRef}
              showingSearchResults={state.showingFreetextResults || state.showingIdResults}
              dataset={dataset}
              filter={filter}
              stopSearch={stopSearch}
              displayRepostAll={displayRepostAll}
              repostDeadEntities={repostDeadEntities}
            />
            <DatasetInspectorViewer
              sourceEntityPath={sourceEntityPath}
              isDeadLetterDataset={dataset && getType(dataset._id) === 'dead_letter_dataset'}
              filter={filter}
              isPipeExecutionLog={props.isPipeExecutionLog}
              tab={props.tab}
            />
          </div>
        )}
        <PostEntityModal
          onClose={() => postReset()}
          onPostChange={setPost}
          onPostValidation={setPostValid}
          rawJson={postJson}
          sendPost={sendPost}
          showPost={state.showPost}
          postMultiple={state.postMultiple}
          isPostDisabled={state.isPostDisabled}
          postValidationError={state.postValidationError}
          shouldForceIfDuplicate={displayRepostAll ? true : false}
        />
      </div>
    </DatasetInspectorContext.Provider>
  );
};

DatasetInspectorContainer.propTypes = {
  apologise: PropTypes.func,
  addCustomActions: PropTypes.func,
  dataset: PropTypes.shape(),
  datasets: PropTypes.shape({}).isRequired,
  datasetID: PropTypes.string,
  originalDataset: PropTypes.shape({}),
  loadDataset: PropTypes.func.isRequired,
  params: PropTypes.shape({
    datasetID: PropTypes.string,
    entityID: PropTypes.string,
    subId: PropTypes.string,
  }),
  pipes: PropTypes.shape({}).isRequired,
  registerRefresh: PropTypes.func,
  removeCustomActions: PropTypes.func,
  router: PropTypes.shape().isRequired,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  unregisterRefresh: PropTypes.func,
  upstreams: PropTypes.shape({}).isRequired,
  datasetsLoaded: PropTypes.bool.isRequired,
  subset: PropTypes.shape([]),
  rollback: PropTypes.func,
  commit: PropTypes.func,
  isPipeExecutionLog: PropTypes.bool,
  tab: PropTypes.string,
};

DatasetInspectorContainer.defaultProps = {
  addCustomActions: () => {},
  removeCustomActions: () => {},
  registerRefresh: () => {},
  unregisterRefresh: () => {},
  isPipeExecutionLog: false,
  tab: '',
};

function mapStateToProps(state, ownProps) {
  let dataset = state.datasets[ownProps.params.datasetID] || state.datasets[ownProps.datasetID];

  let originalDataset; // only in case of dead letter datasets
  if (dataset && dataset.originalDataset) {
    originalDataset = state.datasets[dataset.originalDataset];
  }

  return {
    dataset,
    originalDataset,
    datasets: state.datasets,
    pipes: state.pipes,
    subUrl: state.subscription.url,
    token: state.subscription.token,
    upstreams: state.upstreams,
    datasetsLoaded: state.loadStatus.datasetsLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const id = ownProps.params.datasetID || ownProps.datasetID;
  return {
    loadDataset: () => dispatch(DatasetActions.load(id, true)),
    apologise: (err) => dispatch(apologise(err)),
    rollback: (id) => dispatch(DatasetActions.rollback(id)),
    commit: (id) => dispatch(DatasetActions.commit(id)),
  };
}

const WrappedDatasetInspectorContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DatasetInspectorContainer)
);

export { WrappedDatasetInspectorContainer as DatasetInspectorContainer };
