import React from 'react';
import PropTypes from 'prop-types';

function AuthPage(props) {
  return <div style={{ height: '100%', width: '100%' }}>{props.children}</div>;
}

AuthPage.propTypes = {
  children: PropTypes.any,
};

export default AuthPage;
