import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bytesToSize } from 'Internals/utils';

import Button from 'Common/Button/Button';

import './LogListStyle.css';
import useClipboard from '../../hooks/useClipboard';
import { toastAdded } from 'Redux/thunks/global';

function LogList({ addToast, logs, subUrl, token }) {
  useClipboard('.curl-button', () => {
    addToast('Copied to clipboard!');
  });

  return (
    <div className="log-list">
      {logs.map((c) => (
        <div className="log-list__item" key={c._id}>
          <div className="log-list__item-info">
            <div className="log-list__item-id">
              <p>{c._id}</p>
            </div>
            <div className="log-list__item-size">
              <p>{bytesToSize(c.size)}</p>
            </div>
          </div>
          <div className="log-list__item-actions">
            <div className="log-list__item-action">
              <Button
                type="button"
                className="curl-button"
                key={c._id}
                text="Copy cURL to clipboard"
                data-clipboard-text={`curl -H "Authorization: bearer ${token}" ${subUrl}/logs/${c._id} --output ${c._id}.txt`}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

LogList.propTypes = {
  addToast: PropTypes.func.isRequired,
  logs: PropTypes.arrayOf(PropTypes.shape({})),
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

LogList.defaultProps = {
  logs: [],
};

const mapDispatchToProps = (dispatch) => ({
  addToast: (message) => dispatch(toastAdded({ message, type: 'success' })),
});

export default connect(null, mapDispatchToProps)(LogList);
