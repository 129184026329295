import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

import { FormControl, FormControlProps, FormControlLabel, FormHelperText } from '../forms';

import RadioGroup from '@material-ui/core/RadioGroup';
import SesamRadio from '../SesamRadio/SesamRadio';

export const useStyles = makeStyles({
  root: {},
  noMarginRight: {
    marginRight: 0,
  },
  helperText: {
    marginTop: '-3px',
  },
  fullWidthControl: {
    width: '100%',
  },
  fullWidthRadioGroup: {
    justifyContent: 'space-between',
  },
  font: {
    fontFamily: 'Barlow, sans-serif',
  },
});

interface RadioWithLabel {
  noMarginRight?: boolean;
  value: string;
  helperText?: string;
  label: string;
  placement?: 'bottom' | 'end' | 'start' | 'top';
  size?: 'medium' | 'small';
  disabled?: boolean;
  seleniumText?: string;
  testid?: string;
}

type SesamRadioGroupFieldProps = {
  classes?: {
    root: {};
    radioGroup: {};
  };
  className?: string;
  FormControlProps?: FormControlProps;
  name: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  radios: RadioWithLabel[];
  row: boolean;
  value: string | number | boolean;
  fullWidth?: boolean;
};

const SesamRadioGroupField: React.FC<SesamRadioGroupFieldProps> = (props) => {
  const {
    classes,
    className,
    name,
    onChange,
    radios,
    row,
    value,
    fullWidth = false,
    ...restProps
  } = props;

  const styles = { ...useStyles(), ...classes };

  return (
    <FormControl
      className={clsx(styles.root, className, fullWidth && styles.fullWidthControl)}
      {...restProps}
    >
      <RadioGroup
        className={clsx(styles.radioGroup, fullWidth && styles.fullWidthRadioGroup)}
        name={name}
        value={value}
        onChange={onChange}
        row={row}
      >
        {radios.map((radio: RadioWithLabel) => (
          <FormControlLabel
            disabled={radio.disabled || false}
            key={radio.value}
            value={radio.value}
            data-selenium={radio.seleniumText ? radio.seleniumText : null}
            className={clsx({ [styles.noMarginRight]: radio.noMarginRight })}
            control={<SesamRadio color="primary" size={radio.size || 'medium'} />}
            label={
              <div>
                <div className={styles.font}>{radio.label}</div>
                {radio.helperText && (
                  <FormHelperText className={clsx(styles.font, styles.helperText)}>
                    {radio.helperText}
                  </FormHelperText>
                )}
              </div>
            }
            labelPlacement={radio.placement || 'end'}
            data-testid={radio.testid}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default SesamRadioGroupField;
