import React from 'react';
import PropTypes from 'prop-types';

import UserAvatar from './UserAvatar';
import { ClassNamePropType } from 'Internals/react-utils';

class UserAvatarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDefault: false,
    };
    this.handleNoGravatar = () => {
      this.setState({ showDefault: true });
    };
  }

  render() {
    return (
      <UserAvatar
        {...this.props}
        handleError={this.handleNoGravatar}
        showDefault={this.state.showDefault}
      />
    );
  }
}

UserAvatarContainer.propTypes = {
  email: PropTypes.string,
  title: PropTypes.string,
  className: ClassNamePropType,
};

export default UserAvatarContainer;
