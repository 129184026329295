import React from 'react';
import PropTypes from 'prop-types';

import Ellipsis from 'Common/Ellipsis';
import { MenuActionLink, MenuSeparator } from '../menu';
import MenuIcon from '../../images/icons/menu.svg';
import { subscriptionProps } from '../../model-props';
import MenuToggler from '../menu-toggler';

import './style.css';

function SubscriptionSelector({ currentSub, subs }) {
  const label = <MenuIcon className="subscriptions-selector__icon" />;
  const sortedSubs = [...subs].sort((sub1, sub2) =>
    sub1.name.toLowerCase() < sub2.name.toLowerCase() ? -1 : 1
  );
  // Note, this was mutating the store before!

  return (
    <div className="subscriptions-selector">
      <div className="subscriptions-selector__current-sub">
        <Ellipsis>{currentSub.name}</Ellipsis>
      </div>
      <MenuToggler
        label={label}
        labelClassName="subscriptions-selector__toggler"
        isDashboardIcon={true}
      >
        <MenuActionLink label="Dashboard" to="/dashboard" />
        <MenuSeparator />
        {sortedSubs.map((sub) => (
          <MenuActionLink key={sub.id} label={sub.name} to={`/subscription/${sub.id}`} />
        ))}
        <MenuSeparator />
        <MenuActionLink label="New subscription" to="/create-subscription" />
      </MenuToggler>
    </div>
  );
}

SubscriptionSelector.propTypes = {
  currentSub: subscriptionProps.isRequired,
  subs: PropTypes.arrayOf(subscriptionProps).isRequired,
};

export default SubscriptionSelector;
