import { useCallback, useMemo, useReducer } from 'react';
import sfetch from 'Internals/sfetch';
import { Entity } from '../types/entities.types';

type State = {
  disablePost: boolean;
  entitiesToPost: Entity[];
  entityToPost: Entity | {};
  postMultiple: boolean;
  postValidationError: Error | null;
  showPost: boolean;
};

type Action = {
  type:
    | 'postInvalid'
    | 'post'
    | 'postReset'
    | 'postMultipleEntities'
    | 'postSingleEntity'
    | 'postValid';
  payload?: any;
};

const initialState: State = {
  disablePost: false,
  entitiesToPost: [],
  entityToPost: {},
  postMultiple: true,
  postValidationError: null,
  showPost: false,
};

function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case 'postInvalid':
      return {
        ...state,
        postValidationError: action.payload,
        disablePost: true,
      };
    case 'post': {
      const newState = { ...state };
      if (Array.isArray(action.payload)) {
        newState.entitiesToPost = action.payload;
        newState.postMultiple = true;
        newState.showPost = true;
      } else if (typeof action.payload === 'object') {
        newState.entityToPost = action.payload;
        newState.postMultiple = false;
        newState.showPost = true;
      }

      return newState;
    }
    case 'postReset':
      return {
        ...state,
        disablePost: false,
        postValidationError: null,
        showPost: false,
      };
    case 'postMultipleEntities':
      return {
        ...state,
        entitiesToPost: action.payload,
        postMultiple: true,
        showPost: true,
      };
    case 'postSingleEntity':
      return {
        ...state,
        entityToPost: action.payload,
        postMultiple: false,
        showPost: true,
      };
    case 'postValid':
      return {
        ...state,
        postValidationError: null,
        disablePost: false,
      };
    default:
      return state;
  }
}

export default function usePostEntities(
  datasetId: string,
  subUrl: string,
  token: string,
  onPostSuccess = () => {}
) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const postJson = useMemo(
    () => (state.postMultiple ? state.entitiesToPost : state.entityToPost),
    [state.postMultiple, state.entitiesToPost, state.entityToPost]
  );

  const postEntities = () => {
    dispatch({ type: 'postMultipleEntities', payload: [] });
  };

  const postReset = () => {
    dispatch({ type: 'postReset' });
  };

  const post = (payload: Entity | Entity[]) => {
    dispatch({ type: 'post', payload });
  };
  const setPost = useCallback(
    (json) => {
      if (state.postMultiple) {
        dispatch({ type: 'postMultipleEntities', payload: json });
      } else {
        dispatch({ type: 'postSingleEntity', payload: json });
      }
    },
    [state.postMultiple]
  );

  const setPostValid = (isValid: boolean) => {
    if (!isValid) {
      dispatch({ type: 'postInvalid', payload: 'Parse error' });
    } else {
      dispatch({ type: 'postValid' });
    }
  };

  // TODO put this into useEffect!
  const sendPost = useCallback(
    ({ force }) => {
      if (!datasetId) {
        return;
      }
      const url = `${subUrl}/datasets/${datasetId}/entities?force=${force ? 'true' : 'false'}`;
      const json = postJson;
      const fullJson = state.postMultiple ? json : [json];
      const requestOptions: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
        body: JSON.stringify(fullJson),
      };

      sfetch(url, requestOptions)
        .then(() => {
          dispatch({ type: 'postReset' });
        })
        .then(() => {
          onPostSuccess();
        })
        .catch((e) => {
          dispatch({
            type: 'postInvalid',
            payload:
              e.responseBody && e.responseBody.validation_errors
                ? 'Validation errors'
                : (e.responseBody && e.responseBody.detail) || 'Unexpected error',
          });
        });
    },
    [datasetId, postJson, state.postMultiple, subUrl, token]
  );

  return {
    sendPost,
    postReset,
    postEntities,
    setPost,
    setPostValid,
    postJson,
    post,
    state,
  };
}
