import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import Box from 'Common/Box/Box';
import ButtonLink from 'Common/ButtonLink/ButtonLink';
import JsonEditor from 'Common/JsonEditor/JsonEditor';
import JsonPanel from 'Common/JsonPanel';

import './ConfigListStyle.css';

const Entity = ({ entity }) => {
  return <JsonPanel rawJson={entity} fitToContent />;
};

Entity.propTypes = {
  entity: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
};
Entity.defaultProps = { entity: 'No entity data' };

const ConfigListItem = forwardRef(({ primitive, subId, entity }, ref) => {
  const type = get(primitive, 'config.effective.type') === 'pipe' ? 'pipe' : 'system';
  return (
    <div className="config-list-item">
      <div className="config-list-item__config" ref={ref}>
        <Box>
          <Box.Header>
            <Box.Title>{primitive._id} - Config</Box.Title>
            <Box.Actions>
              <ButtonLink
                to={`/subscription/${subId}/${type}s/${type}/${primitive._id}`}
                size="tiny"
              >
                Go to {type}
              </ButtonLink>
            </Box.Actions>
          </Box.Header>
          <Box.Content>
            <JsonEditor
              rawJson={get(primitive, 'config.original')}
              key={get(primitive, '_id')}
              adjustHeightToContent
              readOnly
              hash={get(primitive, 'config_hash')}
            />
          </Box.Content>
        </Box>
      </div>
      {type === 'pipe' && (
        <div className="config-list-item__output">
          <Box title="Output">
            <Box.Header>
              <Box.Title>{primitive._id} - Output</Box.Title>
              <Box.Actions>
                <ButtonLink
                  to={`/subscription/${subId}/pipes/pipe/${encodeURIComponent(
                    primitive._id
                  )}/output`}
                  size="tiny"
                >
                  Go to pipe's output
                </ButtonLink>
              </Box.Actions>
            </Box.Header>
            <Box.Content>
              <Entity entity={entity} />
            </Box.Content>
          </Box>
        </div>
      )}
    </div>
  );
});

ConfigListItem.displayName = 'ConfigListItemForwardRef';

ConfigListItem.propTypes = {
  arrow: PropTypes.bool,
  primitive: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  subId: PropTypes.string.isRequired,
  entity: PropTypes.shape({}),
};

ConfigListItem.defaultProps = {
  arrow: false,
};

const ConfigList = ({ objectList, subId, scrollToSelected, selectedIdx, entities }) => {
  return (
    <div className="config-list">
      {objectList.map((obj, idx) => (
        <ConfigListItem
          primitive={obj}
          entity={entities[idx]}
          subId={subId}
          key={`${get(obj, '_id')}${idx}`}
          ref={(el) =>
            scrollToSelected &&
            idx === selectedIdx &&
            el &&
            el.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
        />
      ))}
    </div>
  );
};

ConfigList.propTypes = {
  objectList: PropTypes.arrayOf(PropTypes.shape({})),
  subId: PropTypes.string.isRequired,
  scrollToSelected: PropTypes.bool,
  selectedIdx: PropTypes.number,
  entities: PropTypes.array,
};

ConfigList.defaultProps = {
  list: [],
  scrollToSelected: false,
  selectedIdx: 0,
};

export default ConfigList;
