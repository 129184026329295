import React from 'react';
import PropTypes from 'prop-types';

import Button from 'Common/Button/Button';
import JsonPanel from 'Common/JsonPanel';
import { LoadingPanel } from 'Common/LoadingPanel';

import './style.css';

function GenerateSchemaPanel({
  loading,
  schema,
  isDirty,
  sampleSize,
  onChangeSampleSize,
  onSubmit,
  usingOldApi,
}) {
  return (
    <div className="stretchy-panel editor__panel editor__panel--extra generate-schema-panel">
      <h3 className="head">Generate schema</h3>
      {isDirty && (
        <div className="generate-schema-panel__caution-message">
          CAUTION. This schema is generated from the last saved config.
        </div>
      )}
      {usingOldApi && (
        <div className="generate-schema-panel__sample-size">
          <form
            className="generate-schema-panel__form"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(e);
            }}
          >
            <input
              id="sampleSizeInput"
              type="number"
              onChange={onChangeSampleSize}
              value={sampleSize}
            />
            <Button type="submit" onSubmit={onSubmit}>
              Set sample size
            </Button>
          </form>
        </div>
      )}
      <div className="body">
        {loading && <LoadingPanel />}
        {!loading && <JsonPanel alwaysUpdate filter={false} rawJson={schema} />}
      </div>
    </div>
  );
}

GenerateSchemaPanel.propTypes = {
  loading: PropTypes.bool.isRequired,
  schema: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDirty: PropTypes.bool,
  usingOldApi: PropTypes.bool,
};

GenerateSchemaPanel.defaultProps = {
  isDirty: false,
};

export default GenerateSchemaPanel;
