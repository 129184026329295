import { CheckboxProps } from '@material-ui/core/Checkbox';
import { FormControlProps } from '@material-ui/core/FormControl';
import React, { ReactNode } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
} from '../forms';

import SesamCheckbox from '../SesamCheckbox/SesamCheckbox';

type SesamCheckboxFieldProps = {
  CheckboxProps: CheckboxProps;
  FormControlProps?: FormControlProps;
  FormControlLabelProps?: FormControlLabelProps;
  helperText?: string;
  label: string | React.ReactNode;
};

const SesamCheckboxField: React.FC<SesamCheckboxFieldProps> = (props) => {
  const {
    CheckboxProps,
    FormControlProps,
    FormControlLabelProps,
    helperText,
    label,
    ...restProps
  } = props;

  return (
    <FormControl {...FormControlProps} {...restProps}>
      <FormGroup>
        <FormControlLabel
          {...FormControlLabelProps}
          control={<SesamCheckbox {...CheckboxProps} />}
          label={label}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormGroup>
    </FormControl>
  );
};

export default SesamCheckboxField;
