import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';

import { buildClassName, ClassNamePropType } from 'Internals/react-utils';
import { sortWithSortable } from 'Internals/sort';
import {
  getFromLocalStorage,
  setIntoLocalStorage,
  removeFromLocalStorage,
} from 'Internals/local-storage';
import {
  ColPropType,
  normaliseCol,
  TableColSelector,
  TableHeaderRow,
  TableRow,
  TableSpanRow,
} from './table-bits';

import './style.css';

const LOCAL_STORAGE_KEY = 'sesam--data-table-2'; // We did a oopsie in the first version
const COLS_KEY = 'cols';
const SORT_BY_KEY = 'sortBy';
const SORT_ASC_KEY = 'sortAsc';

const styles = (theme) => ({
  table: {
    backgroundColor: theme.palette.background.light,
  },
  row: {
    backgroundColor: theme.palette.background.light,
    '&:hover, &:active': {
      backgroundColor: '#ECEBEC',
      cursor: 'pointer',
    },
  },
  rowDark: {
    backgroundColor: theme.palette.background.light,
    '&:hover, &:active': {
      backgroundColor: '#717171',
      cursor: 'pointer',
    },
  },
});

/**
 * A table for displaying data. Supports user-selected columns and rows
 */
class DataTable extends React.Component {
  constructor(props) {
    super(props);

    /**
     * React doesn't apply defaults to nested props, so we normalise the cols
     * and use `this.normalisedCols` as the version of `this.props.cols` with
     * defaults applied.
     */
    this.normalisedCols = props.cols.map((col) => normaliseCol(col));

    /**
     * Retrieve personalised sort direction from local storage
     */
    this.loadPersonalSortAsc = () =>
      getFromLocalStorage(LOCAL_STORAGE_KEY, [this.props.id, SORT_ASC_KEY], true);

    /**
     * Retrieve personalised sort column from local storage
     */
    this.loadPersonalSortBy = () => {
      const defaultSortByKey = getFromLocalStorage(
        LOCAL_STORAGE_KEY,
        [this.props.id, SORT_BY_KEY],
        this.props.defaultSortByKey
      );
      if (defaultSortByKey !== null && typeof defaultSortByKey === 'object') {
        return '';
      }
      return defaultSortByKey;
    };

    /**
     * Retrieve personalised column visibility settings
     * @return {array} A list of visible columns (header names)
     */
    this.loadPersonalCols = () =>
      getFromLocalStorage(
        LOCAL_STORAGE_KEY,
        [this.props.id, COLS_KEY],
        this.getDefaultVisibleCols()
      );

    /**
     * Save personalised column visibility settings
     */
    this.savePersonalCols = () => {
      setIntoLocalStorage(LOCAL_STORAGE_KEY, [this.props.id, COLS_KEY], this.state.visibleCols);
    };

    /**
     * Retrieve defaults column visibility settings
     * @return {array} A list of visible columns (header names)
     */
    this.getDefaultVisibleCols = () =>
      this.normalisedCols.filter((col) => !col.defaultHidden).map((col) => col.header);

    /**
     * Determine if a column is supposed to be visible
     * @param  {object}  col The column object
     * @return {boolean}
     */
    this.isColVisible = (col) => col.fixed || this.state.visibleCols.indexOf(col.header) !== -1;

    /**
     * Set (and store) the personal preference for visibility of a column
     * @param {object}  col     The column object
     * @param {boolean} visible Whether the column is visible or not
     */
    this.setColVisibility = (changedCol, visible) => {
      if (changedCol.fixed) return;
      const visibleCols = this.normalisedCols
        .filter((col) => {
          if (col === changedCol) {
            return visible;
          }
          return this.isColVisible(col);
        })
        .map((col) => col.header);

      this.setState({ visibleCols }, this.savePersonalCols);
    };

    /**
     * Reset the visibility state of columns
     */
    this.resetColVisibility = () => {
      this.setState({ visibleCols: this.getDefaultVisibleCols() });
      removeFromLocalStorage(LOCAL_STORAGE_KEY, [this.props.id, COLS_KEY]);
    };

    /**
     * Check whether all rows are currently selected
     * @return {boolean}
     */
    this.areAllSelected = () =>
      this.state.selectedRows.length && this.state.selectedRows.length === this.props.data.length;

    /**
     * Callback for toggling row selection
     * @param {object} row The row data object
     */
    this.onRowSelectToggle = (row) => {
      const previousSelectedRows = this.state.selectedRows;
      const toggledRowId = get(row, this.props.dataKey);
      let selectedRows;

      if (previousSelectedRows.includes(toggledRowId)) {
        selectedRows = previousSelectedRows.filter((rowId) => rowId !== toggledRowId);
      } else {
        selectedRows = previousSelectedRows.concat(toggledRowId);
      }

      this.setState({ selectedRows }, this.props.onSelectionChanged(selectedRows));
    };

    /**
     * Handle clicking on the "select all" checkbox
     * @param {Event} ev The onChange event of the checkbox
     */
    this.onToggleSelectAll = (ev) => {
      let selectedRows = [];

      if (ev.target.checked) {
        selectedRows = this.props.data.map((row) => get(row, this.props.dataKey));
      }

      this.setState({ selectedRows }, this.props.onSelectionChanged(selectedRows));
    };

    /**
     * Stores the sort column (and direction) for this table in localStorage
     * @param {Column} sortByCol The column object to save
     */
    this.setSortBy = (sortByCol) => {
      const sortBy = sortByCol.header;
      const sortAscending = this.state.sortBy === sortBy ? !this.state.sortAscending : true;

      this.setState({ sortAscending, sortBy });
      setIntoLocalStorage(LOCAL_STORAGE_KEY, [this.props.id, SORT_BY_KEY], sortBy);
      setIntoLocalStorage(LOCAL_STORAGE_KEY, [this.props.id, SORT_ASC_KEY], sortAscending);
    };

    /**
     * Provides a list of rows for output, sorted according to the current
     * sort settings
     * @param {bool} [pinned] Whether to return only pinned rows, or only
     *    non-pinned rows
     * @returns {array} The sorted rows
     */
    this.getSortedRows = (pinned = false) => {
      const sortByCol = this.normalisedCols.find((col) => col.header === this.state.sortBy);
      const pinnedIds = this.props.pinnedIds;
      const rows = this.props.data.filter((dataRow) => {
        const rowKey = get(dataRow, this.props.dataKey);
        const isPinned = pinnedIds.includes(rowKey);
        return pinned ? isPinned : !isPinned;
      });

      // No sense in sorting pinnned items
      if (pinned) return rows;

      return sortWithSortable(rows, sortByCol, this.state.sortAscending);
    };

    this.state = {
      selectedRows: this.props.initiallySelected.slice(0),
      clickedRow: '',
      sortAscending: this.loadPersonalSortAsc(),
      sortBy: this.loadPersonalSortBy(),
      visibleCols: this.loadPersonalCols(),
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props))
      this.normalisedCols = this.props.cols.map((col) => normaliseCol(col));
  }

  render() {
    const colsToShow = this.normalisedCols.filter(this.isColVisible);
    const selectedRows = this.state.selectedRows;
    const clickedRow = this.state.clickedRow;
    const pinnedRows = this.getSortedRows(true);
    const rows = this.getSortedRows();
    const hasHeader = this.props.title || this.props.header || this.props.selectableCols;
    const hasFooter = this.props.selectableRows || this.props.footer;
    const darkModeActive = this.props.darkModeActive;

    return (
      <div
        aria-label={this.props.title || 'Table'}
        className={buildClassName('data-table', this.props.className)}
        role="region"
      >
        {hasHeader && (
          <div className="data-table__header">
            {this.props.title && (
              <h3 className={buildClassName(this.props.headerTitleClass, 'data-table__title')}>
                {this.props.title}
              </h3>
            )}
            {this.props.header && (
              <div className="data-table__header-controls">{this.props.header}</div>
            )}
            {this.props.selectableCols && (
              <TableColSelector
                cols={this.normalisedCols}
                onChange={this.setColVisibility}
                onReset={this.resetColVisibility}
                visibleCols={colsToShow}
              />
            )}
          </div>
        )}
        <table className={clsx(['data-table__table', this.props.classes.table])}>
          <TableHeaderRow
            areAllSelected={this.areAllSelected}
            colsToShow={colsToShow}
            onToggleSelectAll={this.onToggleSelectAll}
            selectableRows={this.props.selectableRows}
            setSortBy={this.setSortBy}
            sortable={this.props.sortable}
            sortedBy={this.state.sortBy}
          />
          <tbody>
            {pinnedRows.length === 0 && rows.length === 0 && this.props.emptyMessage && (
              <TableSpanRow
                content={this.props.emptyMessage}
                span={colsToShow.length + this.props.selectableRows ? 1 : 0}
                className={darkModeActive ? this.props.classes.rowDark : this.props.classes.row}
              />
            )}
            {pinnedRows.length > 0 &&
              pinnedRows.map((row) => (
                <TableRow
                  className={buildClassName(
                    this.props.itemClassNameGetter(row),
                    darkModeActive ? this.props.classes.rowDark : this.props.classes.row
                  )}
                  data={row}
                  key={get(row, this.props.dataKey)}
                  onSelectToggle={this.onRowSelectToggle}
                  selectable={this.props.selectableRows}
                  selected={selectedRows.includes(get(row, this.props.dataKey))}
                  clicked={get(row, this.props.dataKey) === clickedRow}
                  visibleCols={colsToShow}
                  pinned
                />
              ))}
            {rows.length > 0 &&
              rows.map((row) => (
                <TableRow
                  className={buildClassName(
                    this.props.itemClassNameGetter(row),
                    darkModeActive ? this.props.classes.rowDark : this.props.classes.row
                  )}
                  data={row}
                  key={get(row, this.props.dataKey)}
                  onSelectToggle={this.onRowSelectToggle}
                  selectable={this.props.selectableRows}
                  selected={selectedRows.includes(get(row, this.props.dataKey))}
                  clicked={get(row, this.props.dataKey) === this.state.clickedRow}
                  visibleCols={colsToShow}
                  onRowClick={(data) => {
                    this.setState({
                      clickedRow: get(data, this.props.dataKey),
                    });
                    this.props.onRowClick(data);
                  }}
                />
              ))}
          </tbody>
        </table>
        {hasFooter && (
          <div className="data-table__footer">
            {this.props.selectableRows && (
              <div className="data-table__selected-count">
                {selectedRows.length} of {rows.length} {rows.length === 1 ? 'row' : 'rows'} selected
              </div>
            )}
            {this.props.footer && (
              <div className="data-table__footer-controls">{this.props.footer}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

DataTable.propTypes = {
  /** {string|Array<string>} Additional class names for the table */
  className: ClassNamePropType,

  /** {Array<DataTable~ColPropType>} Array of column definitions for the table */
  cols: PropTypes.arrayOf(ColPropType).isRequired,

  /** Data to populate the table; should be an array of objects */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,

  /** Name of property in `props.data` objects that provides a unique key; accepts lodash `get` selector strings */
  dataKey: PropTypes.string.isRequired,

  /** Message to display if there are no rows in the table */
  emptyMessage: PropTypes.node,

  /** Any elements to use as part of the table footer */
  footer: PropTypes.node,

  /** Any elements to use as part of the table header (e.g. filtering controls) */
  header: PropTypes.node,

  /** Classname for the `title` heading (if present) */
  headerTitleClass: PropTypes.oneOf(['heading-component', 'heading-section', 'heading-page']),

  /** Unique ID for this table */
  id: PropTypes.string.isRequired,

  /** Array of IDs (as per `props.dataKey`) of initially-selected rows */
  initiallySelected: PropTypes.arrayOf(PropTypes.string),

  /** {DataTable~onSelectionChangedCallback} Callback for changes in row selection */
  onSelectionChanged: PropTypes.func,

  /** Array of IDs (as per `props.dataKey`) of rows to be pinned at the top */
  pinnedIds: PropTypes.arrayOf(PropTypes.string),

  /** {DataTable~itemClassNameGetterCallback} Callback to get a class to apply to the row */
  itemClassNameGetter: PropTypes.func,

  /** Whether or not the user can select which columns to display */
  selectableCols: PropTypes.bool,

  /** Make rows selectable. Define `props.onSelectionChanged` to respond to selection changes */
  selectableRows: PropTypes.bool,

  /** Whether the table is sortable */
  sortable: PropTypes.bool,

  /** The key to sort by if the user hasn't explicitly selected a key */
  defaultSortByKey: PropTypes.string,

  /** Title for the table, placed in the header above */
  title: PropTypes.string,

  /** Callback when clicking a table row. Has the table row data as its parameter */
  onRowClick: PropTypes.func,

  classes: PropTypes.shape({
    table: PropTypes.string,
    row: PropTypes.string,
    rowDark: PropTypes.string,
  }),

  darkModeActive: PropTypes.bool,
};

DataTable.defaultProps = {
  className: undefined,
  emptyMessage: undefined,
  footer: undefined,
  header: undefined,
  headerTitleClass: 'heading-component',
  initiallySelected: [],
  onSelectionChanged: () => {
    //empty
  },
  pinnedIds: [],
  itemClassNameGetter: () => {
    //empty
  },
  selectableCols: false,
  selectableRows: false,
  sortable: false,
  title: undefined,
  onRowClick: () => {
    //empty
  },
};

const mapStateToProps = (state) => ({
  darkModeActive: state.theme.dark,
});

export default compose(connect(mapStateToProps), withStyles(styles))(DataTable);

/**
 * @callback DataTable~onSelectionChangedCallback
 * @param {array} selection Array of IDs (as per `props.dataKey`) of selected rows
 */

/**
 * @callback DataTable~itemClassNameGetterCallback
 * @param {object} row The row data object (as per `props.data`)
 */
