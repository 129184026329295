import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import stringify from 'json-stable-stringify';
import { withTheme } from '@material-ui/core/styles';

import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import EditorActions from 'Redux/thunks/editor';

import { pathToLineNumber } from 'Internals/json-newline-parser';
import schemaCompleter from './completers/schemaCompleter';

import '../../../style/global.css';
import './JsonEditor.css';
import EditorOptionsModal from './EditorOptionsModal';
import withLazyImport from '../withLazyImport/withLazyImport';
import { pipesSelector, systemsSelector } from 'Redux/selectors';
import { datasetsSelector } from 'Redux/thunks/datasets';
import { isMirorrOrReplica, getUpstreamPipeFromDatasetId } from 'Internals/pipes';
import { isUserDataset } from 'Internals/datasets';

import './DTLMode';
import { TestID } from '../../../testID';

const KEYMAP_KEY = 'sesam-editor-keymap';
const REFORMAT_KEY = 'sesam-editor-reformat';
const CODESTYLE_KEY = 'sesam-editor-codestyle';
const AUTOCOMPLETE_KEY = 'sesam-editor-autocomplete';
const FOLDS_KEY = 'sesam-editor-folds';

const default_codestyle_font_size = {
  'default-value': 12,
  description: 'Editor font size',
  label: 'Font size',
  value: 12,
};

const getFontSize = () => {
  const codeStyleOverrides = JSON.parse(localStorage?.getItem(CODESTYLE_KEY) || '{}');
  return codeStyleOverrides?.font_size ?? 12;
};

export function getStrippedName(str) {
  // ACE retrieves the token is with the "" around it as well, so we strip it
  const arr = str.split(' ');
  let stripped;
  if (arr.length > 1) {
    // was a string with a space (i.e. alias)
    stripped = arr[0].slice(1, arr[0].length);
  } else {
    stripped = arr[0].slice(1, arr[0].length - 1);
  }
  return stripped;
}

class JsonEditor extends React.Component {
  constructor(props) {
    super(props);
    this.getValidationAnnotations = () => {
      if (get(this.props, ['validationResult', 'config-errors'], []).length > 0) {
        const annotations = this.props.validationResult['config-errors'].map((e) => {
          const path = e.elements.slice(1, e.elements.length);
          const pathArray = path
            .slice(1, path.length - 1)
            .split('][')
            .map((x) => x.replace(/^'/, '').replace(/'$/, ''));
          const lineNumber = pathToLineNumber(this.state.content, pathArray);
          return {
            row: lineNumber,
            column: 0,
            text: e.msg,
            type: 'warning',
          };
        });
        return annotations;
      } else {
        return [];
      }
    };

    this.onCodeStyleOptionChanged = (name, value) => {
      const overrides = { ...this.state.overridenCodeStyle };
      overrides[name] = value;
      if (this.hasLocalStorage()) {
        localStorage.setItem(CODESTYLE_KEY, JSON.stringify(overrides));
      }
      const codeStyle = { ...this.state.codeStyle };
      const updatedCodeStyle = { ...this.state.codeStyle[name] };
      updatedCodeStyle.value = value;
      codeStyle[name] = updatedCodeStyle;

      this.setState({
        codeStyle,
        overridenCodeStyle: overrides,
      });
    };

    this.setDefaultCodestyle = () => {
      const overrides = { ...this.state.overridenCodeStyle };
      const codeStyle = {
        ...this.props.reformatConfig,
        font_size: { ...default_codestyle_font_size },
      };

      Object.keys(overrides).forEach((key) => {
        const updatedCodeStyle = { ...codeStyle[key] };
        updatedCodeStyle.value = overrides[key];
        codeStyle[key] = updatedCodeStyle;
      });

      this.setState({
        codeStyle,
      });
      this.reformatWithoutEditor(this.updateValue);
    };

    this.getHash = () => {
      if (this.props.hash) {
        return this.props.hash;
      }
      const entityHash = get(this.state.content, '_hash');
      if (entityHash) {
        return entityHash;
      } else {
        return undefined;
      }
    };

    this.getCurrentAceTheme = () => {
      if (this.props.theme.palette.type === 'dark') {
        return 'ace/theme/dracula';
      } else {
        return undefined;
      }
    };

    this.updateFoldsFromLocalStorage = () => {
      let Range = this.props.imports.brace.acequire('ace/range').Range;
      const hash = this.getHash();
      if (!this.didAddFolds && this.state.reformatted && hash) {
        if (this.hasLocalStorage()) {
          const allFolds = JSON.parse(localStorage.getItem(FOLDS_KEY) || '{}');
          const session = this.ace.getSession();
          const currentFolds = allFolds[hash];
          if (currentFolds) {
            for (const r of currentFolds) {
              try {
                session.addFold(
                  '...',
                  new Range(r.start.row, r.start.column, r.end.row, r.end.column)
                );
                this.didAddFolds = true;
              } catch (e) {
                // don't set didAddFolds if the addFold operation failed
              }
            }
          }
        }
      }
    };

    this.updateValue = () => {
      if (!this.state.reformatted) {
        this.silent = true;
        this.ace.setValue('"Please wait. Content is being reformatted to your preferences..."', -1);
        this.silent = false;
        return;
      }
      if (!isEqual(this.ace.getValue(), this.state.content)) {
        // Set current value as oldest change
        // UndoManager.reset() needs to be set asynchronously since changes are added to the UM after a time out
        setTimeout(() => {
          this.ace.getSession().getUndoManager().reset();
        }, 700);

        // preserve cursor position
        const cursorPosition = this.ace.getCursorPosition();
        // preserve selected range
        const selection = this.ace.getSelection().getRange();
        this.silent = true;
        this.ace.setValue(this.state.content, -1);
        this.silent = false;
        this.ace.gotoLine(cursorPosition.row + 1, cursorPosition.column, false);
        this.ace.getSelection().setSelectionRange(selection);
        this.updateFoldsFromLocalStorage();
      }
    };

    this.createDiffEditor = () => {
      this.aceDiff = new this.props.imports.braceDiff({
        mode: 'ace/mode/dtl',
        theme: this.getCurrentAceTheme(),
        left: {
          element: this.compareWindow,
          editable: false,
          copyLinkEnabled: true,
        },
        right: {
          element: this.editor,
          editable: true,
          copyLinkEnabled: false,
        },
        gutterEl: this.compareGutter,
      });

      this.aceDiff.editors.left.ace.setOptions({
        showPrintMargin: false,
      });
      this.aceDiff.editors.right.ace = this.ace;
      this.aceDiff.editors.right.ace.setOptions({
        showPrintMargin: false,
      });
      this.aceCompare = this.aceDiff.editors.left.ace;
      this.aceCompare.setValue('Loading...');
    };

    this.createEditor = () => {
      this.ace = this.props.imports.brace.edit(this.editor);

      this.ace.getSession().setMode('ace/mode/dtl');

      this.ace.$blockScrolling = Infinity;
      this.ace.$blockScrolling = Infinity;

      this.ace.setOptions({
        scrollPastEnd: 0.75,
        showPrintMargin: false,
      });

      if (this.props.adjustHeightToContent) {
        this.ace.setOptions({
          maxLines: Infinity,
        });
      }

      this.ace.getSession().on('changeFold', (ev, session) => {
        function pruneExistingFolds(folds) {
          // this just prunes the existing folds so that they dont keep growing forever
          // we assume that keys in the object are in insertion order, which is the case
          // generally in browers, if the keys are strings
          if (Object.keys(folds).length > 100) {
            delete folds[Object.keys(folds)[0]];
          }
          return folds;
        }
        const folds = session.$foldData.map((f) => f.range);
        const existingFolds = JSON.parse(localStorage.getItem(FOLDS_KEY) || '{}');

        const hash = this.getHash();
        if (!hash) {
          // no hash for current json file
          // so we can't identify it if we saved it
          return;
        } else {
          existingFolds[hash] = folds;
          const prunedFolds = pruneExistingFolds(existingFolds);
          if (this.hasLocalStorage()) {
            localStorage.setItem(FOLDS_KEY, JSON.stringify(prunedFolds));
          }
        }
      });

      if (this.props.readOnly) {
        this.ace.setReadOnly(true);
        this.ace.setOptions({
          highlightActiveLine: false,
          highlightGutterLine: false,
        });
        this.ace.setTheme(this.getCurrentAceTheme());
        this.ace.renderer.$cursorLayer.element.style.display = 'none';
      } else {
        this.ace.setOptions({
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: this.state.autoCompletion,
          enableLinking: true,
        });
        // create and add our autocompleter
        const subCompleter = schemaCompleter(
          this.props.pipeCompletions,
          this.props.datasetCompletions,
          this.props.systemCompletions,
          this.props.targetSchema,
          this.props.sourceSchema,
          this.props.upstreams,
          this.props.apiConf
        );
        this.langTools.setCompleters([subCompleter]);

        this.ace.schema = this.props.validationResult['json-schema'];
        this.ace.on('change', () => {
          if (!this.silent) {
            this.handleChange(this.ace.getValue());
          }
        });

        this.ace.setTheme(this.getCurrentAceTheme());

        this.ace.on('linkHover', (data, { session }) => {
          if (this.ace.markerId) session.removeMarker(this.ace.markerId);
          if (this.ace.markerLink) this.ace.markerLink = undefined;
          const strippedName = getStrippedName(get(data, 'token.value', ''));

          let found = [
            ...this.props.pipeCompletions,
            ...this.props.datasetCompletions,
            ...this.props.systemCompletions,
          ].find((x) => x.name === strippedName);

          if (found) {
            if (found.type === 'Dataset') {
              const upstreamPipeId = this.props.upstreams[found.name];
              if (upstreamPipeId) {
                const upstreamPipeCompletion = this.props.pipeCompletions.find(
                  (x) => x.name === upstreamPipeId
                );
                if (upstreamPipeCompletion) {
                  found = upstreamPipeCompletion;
                }
              }
            }

            const range = new Range(
              data.position.row,
              data.token.start + 1,
              data.position.row,
              data.token.start + data.token.value.length - 1
            );
            const markerId = session.addMarker(range, 'linkHighlight', 'text', true);
            this.ace.markerId = markerId;
            this.ace.markerLink = found.link;
          }
        });
        this.ace.on('linkClick', () => {
          if (this.ace.markerLink) {
            this.context.router.push(this.ace.markerLink);
          }
        });
        this.ace.on('blur', this.handleBlur);
        this.ace.commands.addCommand({
          name: 'reformat',
          bindKey: {
            win: 'Alt-.',
            mac: 'Alt-.',
          },
          exec: this.reformat,
        });
        this.ace.commands.addCommand({
          name: 'run',
          bindKey: {
            win: 'Ctrl-Enter',
            mac: 'Ctrl-Enter',
          },
          exec: this.run,
        });
        this.ace.commands.addCommand({
          name: 'save',
          bindKey: {
            win: 'Ctrl-S',
            mac: 'Ctrl-S',
          },
          exec: this.save,
        });
        if (this.props.focus) {
          this.ace.focus();
        }
        this.updateValue();

        if (!this.ace.completer) {
          this.ace.execCommand('startAutocomplete');
          this.ace.completer.detach();
        }
        this.ace.getSession().on('changeAnnotation', (_, session) => {
          let sessionAnnotations = session.$annotations || [];
          let areWarningAnnotations = sessionAnnotations.reduce((p, c) => {
            return c.type === 'warning' ? p || true : p;
          }, false);
          if (!areWarningAnnotations) {
            const annotations = this.getValidationAnnotations();
            if (annotations.length > 0) {
              this.ace.getSession().setAnnotations([...sessionAnnotations, ...annotations]);
            }
          }
        });
      }
    };

    this.initialKeymap = () => {
      if (!this.hasLocalStorage()) {
        return 'default';
      }
      return localStorage.getItem(KEYMAP_KEY) || 'default';
    };

    this.initialOverridenCodeStyle = () => {
      if (!this.hasLocalStorage()) {
        return {};
      }
      const value = localStorage.getItem(CODESTYLE_KEY) || '{}';
      return JSON.parse(value);
    };

    this.initialReformat = () => {
      if (!this.hasLocalStorage()) {
        return true;
      }
      return localStorage.getItem(REFORMAT_KEY) === '1';
    };

    this.initialAutoCompletion = () => {
      if (!this.hasLocalStorage()) {
        return true;
      }
      const autoComplete = localStorage.getItem(AUTOCOMPLETE_KEY);

      if (!autoComplete) {
        return true;
      }

      return autoComplete === '1';
    };

    this.hasLocalStorage = () => {
      try {
        return 'localStorage' in window && window.localStorage !== null;
      } catch (e) {
        return false;
      }
    };

    this.handleChange = (content) => {
      this.setState({ content });
      try {
        const validContent = JSON.parse(content);
        if (typeof validContent !== 'object') {
          throw new Error(`JSON has to be an object, this is a ${typeof validContent}.`);
        }
        // no need to notify others if the parsed content is the same
        if (!isEqual(validContent, this.state.validContent)) {
          this.props.onChange(validContent);
        }
        if (this.props.onValidateJson) {
          this.props.onValidateJson(true);
        }

        this.ace.schema = this.props.validationResult['json-schema'];
        this.setState({
          content,
          errors: '',
          validContent,
          wanings: '',
        });
      } catch (err) {
        if (this.props.onValidateJson) {
          this.props.onValidateJson(false);
        }
        this.setState({ errors: err.toString() });
      }
    };

    this.handleBlur = () => {
      if (this.state.autoFormat) {
        this.reformat();
      }
    };

    this.toggleAutoformat = () => {
      // reformat if enabled
      const autoFormat = !this.state.autoFormat;
      if (autoFormat) {
        this.reformat();
      }
      this.setState({ autoFormat });
      if (this.hasLocalStorage()) {
        localStorage.setItem(REFORMAT_KEY, autoFormat ? 1 : 0);
      }
    };

    this.toggleAutoCompletion = () => {
      const autoCompletion = !this.state.autoCompletion;
      this.setState({ autoCompletion });
      if (this.hasLocalStorage()) {
        localStorage.setItem(AUTOCOMPLETE_KEY, autoCompletion ? 1 : 0);
      }
    };

    this.reformatWithoutEditor = (callback = () => {}) => {
      try {
        // try to parse in order to avoid bad server requests
        const parsed = JSON.parse(this.state.content);
        if (this.props.simpleFormat) {
          const formatted = stringify(parsed, {
            space: '  ',
          });
          this.setState(
            {
              content: formatted,
              reformatted: true,
            },
            callback
          );
        } else {
          const style = {};
          // TODO: rewrite with map/filter (thank you eslint!)
          for (const option in this.state.codeStyle) {
            if (this.state.codeStyle[option].value !== undefined) {
              style[option] = this.state.codeStyle[option].value;
            }
          }
          delete style['font_size'];
          this.props.reformatCode(this.state.content, style).then((result) =>
            this.setState(
              {
                content: result,
                reformatted: true,
              },
              callback
            )
          );
        }
      } catch (err) {
        // TODO: should apologize
        console.error('Warning: Failed to reformat', err);
        this.setState({ reformatted: true });
      }
    };

    this.reformat = () => {
      this.reformatWithoutEditor(this.updateValue);
    };

    this.run = () => {
      if (this.props.onRun) {
        this.props.onRun();
      }
    };

    this.save = () => {
      this.props.onSave();
    };

    this.editorCreated = (e) => {
      this.editor = e;
    };

    this.compareGutterCreated = (e) => {
      this.compareGutter = e;
    };

    this.compareWindowCreated = (e) => {
      this.compareWindow = e;
    };

    const rawJson =
      typeof this.props.rawJson === 'object' ? this.props.rawJson : JSON.parse(this.props.rawJson);

    this.langTools = this.props.imports.brace.acequire('ace/ext/language_tools');
    this.props.imports.brace.acequire('ace/ext/linking');
    var Range = this.props.imports.brace.acequire('ace/range').Range;
    this.langTools.completers = [];

    this.state = {
      autoFormat: this.initialReformat(),
      autoCompletion: this.initialAutoCompletion(),
      codeStyle: null,
      content: JSON.stringify(rawJson, null, 2),
      compareContent: {},
      errors: this.props.errors,
      keyMap: this.initialKeymap(),
      overridenCodeStyle: this.initialOverridenCodeStyle(),
      reformatted: false,
      validContent: rawJson,
      warnings: this.props.warnings,
    };
  }

  UNSAFE_componentWillMount() {
    // TODO: should get this as props with connect from the redux store when we move reformat to portal
    if (!this.props.simpleFormat) {
      // TODO: should avoid drawing the unformatted content first, but we need to wait for initial reformat
      // and dom elements to make that work, but if we display a loader we don't get the dom elements
      this.props.getReformatConfig().then(() => this.setDefaultCodestyle());
    } else {
      // TODO: not easy to read, this doesn't update the editor because by the time the editor is created
      // we have already formatted the content
      this.reformatWithoutEditor();
    }
  }

  componentDidMount() {
    this.createEditor();

    if (this.props.compare) {
      this.createDiffEditor();
    }

    this.ace.setOptions({ fontSize: getFontSize() });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.compare && this.props.compare !== prevProps.compare) {
      this.createDiffEditor();
    } else if (!this.props.compare && this.props.compare !== prevProps.compare) {
      this.aceDiff.editors.right.ace.destroy();
      this.createEditor();
    }

    if (this.props.compare) {
      const style = {};
      // TODO: rewrite with map/filter (thank you eslint!)
      for (const option in this.state.codeStyle) {
        if (this.state.codeStyle[option].value !== undefined) {
          style[option] = this.state.codeStyle[option].value;
        }
      }
      delete style['font_size'];
      this.props
        .reformatCode(JSON.stringify(this.props.compareValue), style)
        .then((formattedConfig) => {
          this.aceCompare.setValue(formattedConfig, -1);
        });
    }

    if (!isEqual(this.props, prevProps)) {
      this.updateValue();
      const currentPipeId = get(this.props, 'rawJson._id', '');
      // IS-9056 To prevent duplication of pipes jumps down to the first null that it finds. E.g. is-null function.
      if (this.props.isNewPipe && (!currentPipeId || !currentPipeId.includes('copy'))) {
        if (!this.didSelectNull) {
          const range = this.ace.find('null');
          if (range) this.didSelectNull = true;
        }
      }

      this.setState({
        errors: this.props.errors,
        warnings: this.props.warnings,
      });
    }

    if (
      !(this.state.errors === prevState.errors) ||
      !(this.props.validationResult === prevProps.validationResult)
    ) {
      // Force a resize since error messages appearing/disappearing/changing
      // might change the height of the editor
      this.ace.resize();
    }
    if (this.props.layoutUpdates !== prevProps.layoutUpdates) {
      // Force a resize when anything that might have caused a layout change is
      // triggered.
      this.ace.resize();
    }
    // Ensure tab settings get used within Ace
    if (this.state.codeStyle && this.state.codeStyle.spaces_for_indent) {
      this.ace.getSession().setTabSize(this.state.codeStyle.spaces_for_indent.value);
    }
    if (this.state.codeStyle && this.state.codeStyle.use_tab_for_indent) {
      this.ace.getSession().setUseSoftTabs(!!this.state.codeStyle.use_tab_for_indent.value);
    }
    if (this.state.autoCompletion !== prevState.autoCompletion) {
      this.ace.setOptions({
        enableLiveAutocompletion: this.state.autoCompletion,
      });
    }
    if (this.props.validationResult !== prevProps.validationResult) {
      this.ace.getSession().setAnnotations(this.getValidationAnnotations());
      this.ace.schema = this.props.validationResult['json-schema'];
    }
    this.ace.setOptions({ fontSize: getFontSize() });

    if (
      this.props.targetSchema !== prevProps.targetSchema ||
      this.props.sourceSchema !== prevProps.sourceSchema
    ) {
      const subCompleter = schemaCompleter(
        this.props.pipeCompletions,
        this.props.datasetCompletions,
        this.props.systemCompletions,
        this.props.targetSchema,
        this.props.sourceSchema,
        this.props.upstreams,
        this.props.apiConf
      );
      this.langTools.setCompleters([subCompleter]);
    }
    if (this.props.theme.palette.type !== prevProps.theme.palette.type) {
      this.ace.setTheme(this.getCurrentAceTheme());
      if (this.aceDiff) {
        this.aceDiff.editors.right.ace.setTheme(this.getCurrentAceTheme());
        this.aceDiff.editors.left.ace.setTheme(this.getCurrentAceTheme());
      }
    }
    if (this.aceDiff) {
      this.aceDiff.editors.right.ace.setOptions({ fontSize: getFontSize() });
      this.aceDiff.editors.left.ace.setOptions({ fontSize: getFontSize() });
    }
  }

  componentWillUnmount() {
    this.ace.destroy();
  }

  render() {
    const classNames = ['jsoneditor'];

    if (this.state.errors) {
      classNames.push('jsoneditor--error');
    } else if (this.state.warnings) {
      classNames.push('jsoneditor--warning');
    }
    if (this.props.readOnly) classNames.push('jsoneditor--readonly');

    return (
      <div className={classNames.join(' ')}>
        <div className="jsoneditor__content">
          <div className={`ace-box ${this.props.compare ? 'ace-box--compare' : ''}`}>
            {this.props.compare && (
              <React.Fragment>
                <div ref={this.compareWindowCreated} />
                <div
                  className="jsoneditor__gutter"
                  ref={this.compareGutterCreated}
                  style={{
                    flexGrow: 0,
                    backgroundColor: this.props.theme.palette.background.semilight,
                  }}
                />
              </React.Fragment>
            )}
            <div ref={this.editorCreated} />
          </div>
          {!this.props.compare && this.state.errors && (
            <div className="jsoneditor__status">{this.state.errors}</div>
          )}
          {!this.props.compare && !this.state.errors && this.state.warnings && (
            <div className="jsoneditor__status">{this.state.warnings}</div>
          )}
        </div>
        {this.props.hasOptions && (
          <EditorOptionsModal
            showOptions={this.props.showOptions}
            isAutoFormat={this.state.autoFormat}
            isAutoCompletion={this.state.autoCompletion}
            onCloseOptions={this.props.onCloseOptions}
            onToggleAutoformat={this.toggleAutoformat}
            onToggleAutoCompletion={this.toggleAutoCompletion}
            onCodeStyleOptionChanged={this.onCodeStyleOptionChanged}
            codeStyle={this.state.codeStyle}
            canRun={!!this.props.onRun}
            canSave={!!this.props.onSave}
          />
        )}
      </div>
    );
  }
}

JsonEditor.propTypes = {
  adjustHeightToContent: PropTypes.bool,
  errors: PropTypes.string,
  focus: PropTypes.bool,
  getReformatConfig: PropTypes.func.isRequired,
  hasOptions: PropTypes.bool,
  layoutUpdates: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onCloseOptions: PropTypes.func.isRequired,
  onRun: PropTypes.func,
  onSave: PropTypes.func,
  onValidateJson: PropTypes.func,
  rawJson: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  reformatConfig: PropTypes.object.isRequired,
  reformatCode: PropTypes.func.isRequired,
  showOptions: PropTypes.bool.isRequired,
  simpleFormat: PropTypes.bool,
  warnings: PropTypes.node,
  pipeCompletions: PropTypes.array.isRequired,
  datasetCompletions: PropTypes.array.isRequired,
  systemCompletions: PropTypes.array.isRequired,
  validationResult: PropTypes.shape(),
  isNewPipe: PropTypes.bool,
  readOnly: PropTypes.bool,
  targetSchema: PropTypes.array,
  sourceSchema: PropTypes.array,
  // hash of the value in the editor, used for saving fold data
  // if given, for example the config hash of a pipe config, then it is used,
  // otherwise we try to use a _hash property if it exists in the actual raw json
  // this is in case it's an entity
  hash: PropTypes.string,
  upstreams: PropTypes.shape({}),
  apiConf: PropTypes.shape({
    subUrl: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  }),
};

JsonEditor.defaultProps = {
  adjustHeightToContent: false,
  errors: '',
  focus: false,
  simpleFormat: false,
  warnings: '',
  validationResult: {},
  readOnly: false,
  targetSchema: [],
  sourceSchema: [],
  onSave: () => {},
};

JsonEditor.contextTypes = {
  router: PropTypes.object,
};

function excludeSystemPipesAndReplicas(pipe) {
  if (pipe._id.startsWith('system:')) {
    return false;
  }

  if (isMirorrOrReplica(pipe)) {
    return false;
  }

  return true;
}

function excludeNonUserDatasets(dataset) {
  return isUserDataset(dataset);
}

function mapStateToProps(state) {
  return {
    layoutUpdates: state.globals.layoutUpdates,
    reformatConfig: state.editor.reformatConfig,
    showOptions: state.editor.visiblePanels.options,
    upstreams: state.upstreams,
    apiConf: {
      subUrl: state.subscription.url,
      token: state.subscription.token,
    },
    pipeCompletions: pipesSelector(state)
      .filter(excludeSystemPipesAndReplicas)
      .map((p) => ({
        name: p._id,
        type: 'Pipe',
        link: `/subscription/${state.subscription.id}/pipes/pipe/${encodeURIComponent(p._id)}/`,
      })),
    datasetCompletions: datasetsSelector(state)
      .filter(excludeNonUserDatasets)
      .map((d) => ({
        name: d._id,
        type: 'Dataset',
        link: `/subscription/${state.subscription.id}/pipes/pipe/${encodeURIComponent(
          getUpstreamPipeFromDatasetId(d._id, state.pipes, state.upstreams)
        )}/output`,
      })),
    systemCompletions: systemsSelector(state)
      .filter((s) => !s._id.startsWith('system:'))
      .map((s) => ({
        name: s._id,
        type: 'System',
        link: `/subscription/${state.subscription.id}/systems/system/${encodeURIComponent(s._id)}/`,
      })),
  };
}

const mapDispatchToProps = (dispatch) => ({
  getReformatConfig: () => dispatch(EditorActions.getReformatConfig()),
  onCloseOptions: () => dispatch(EditorActions.togglePanel('options')),
  reformatCode: (code, styleOptions) => dispatch(EditorActions.reformatCode(code, styleOptions)),
});

const lazyImports = {
  brace: () => import(/* webpackChunkName: "brace" */ /* webpackPrefetch: true */ 'brace'),
  braceModeJson: () =>
    import(/* webpackChunkName: "brace-mode-json" */ /* webpackPrefetch: true */ 'brace/mode/json'),
  braceThemeDracula: () =>
    import(
      /* webpackChunkName: "braceThemeDracula" */ /* webpackPrefetch: true */ 'brace/theme/dracula'
    ),
  braceExtSearchbox: () =>
    import(
      /* webpackChunkName: "brace-ext-searchbox" */ /* webpackPrefetch: true */ 'brace/ext/searchbox'
    ),
  braceExtLanguageTools: () =>
    import(
      /* webpackChunkName: "brace-ext-language_tools" */ /* webpackPrefetch: true */ 'brace/ext/language_tools'
    ),
  braceExtLinking: () =>
    import(
      /* webpackChunkName: "brace-ext-linking" */ /* webpackPrefetch: true */ 'brace/ext/linking'
    ),
  braceDiff: () =>
    import(/* webpackChunkName: "braceDiff" */ /* webpackPrefetch: true */ 'brace-diff'),
};

export default compose(
  withTheme,
  withLazyImport(lazyImports, { sequential: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(JsonEditor);
