// @ts-ignore
import debounceAction from 'debounce-action';
import get from 'lodash/get';

import { apologise } from './apology';
import { getApiConf } from './subscriptions';
import DatahubAPI from '../../api/datahub';
import { RootState, AppThunk } from '../..';
import { createAction } from '@reduxjs/toolkit';
import { SomeObject } from '../../types/types';

export function hasFeature(getState: () => RootState) {
  return function (n: number) {
    return get(getState().subscription.info.status, 'feature-level', 0) >= n;
  };
}

export function featureLevelSelector(state: RootState) {
  return get(state.subscription.info.status, 'feature-level', 0);
}

export const datahubUpdated = createAction<any>('datahub/updated');
export const datahubCleared = createAction('datahub/cleared');
export const datahubLicenseLoaded = createAction<any>('datahub/licenseLoaded');

export default {
  getInfo: debounceAction(
    (): AppThunk => (dispatch, getState) => {
      return DatahubAPI.getInfo(getApiConf(getState))
        .then((info) => dispatch(datahubUpdated(info)))
        .catch((error) => dispatch(apologise(error)));
    },
    1000,
    { leading: true, trailing: false }
  ),

  clearInfo(): AppThunk {
    return function (dispatch, getState) {
      const emptyInfo = {};
      dispatch(datahubCleared());
    };
  },

  getLicense: debounceAction(
    (): AppThunk => (dispatch, getState) => {
      return DatahubAPI.getLicense(getApiConf(getState))
        .then((license) => {
          return dispatch(datahubLicenseLoaded(license));
        })
        .catch((error) => dispatch(apologise(error)));
    },
    1000,
    { leading: true, trailing: false }
  ),

  uploadLicense(license: any): AppThunk {
    return function (dispatch, getState) {
      return DatahubAPI.putLicense(getApiConf(getState), license).then(() =>
        dispatch(datahubLicenseLoaded(license))
      );
    };
  },

  // TODO: IS-6787 This is not really a Redux action; it's dispatched as one so it can
  // access `getState()`. Should rethink this.
  getPermission(permissionPathArray: any): AppThunk {
    return function (dispatch, getState) {
      return DatahubAPI.getPermission(getApiConf(getState), permissionPathArray).catch((error) =>
        dispatch(apologise(error))
      );
    };
  },

  // TODO: IS-6787 This is not really a Redux action; it's dispatched as one so it can
  // access `getState()`. Should rethink this.
  putPermission(permissionPathArray: any, permissionData: SomeObject): AppThunk {
    return function (dispatch, getState) {
      return DatahubAPI.putPermission(
        getApiConf(getState),
        permissionPathArray,
        permissionData
      ).catch((error) => dispatch(apologise(error)));
    };
  },
};
