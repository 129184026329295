import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

const useStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    marginBottom: '1.125rem',
    alignItems: 'center',
  },
  buttonGroup: {
    height: '2.5rem',
    marginRight: '-0.0625rem',
  },
  buttonGroupEdge: {
    '&:not(:first-child)': { borderRadius: 0 },
  },
  form: {
    width: '100%',
  },
  inputRoot: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  formHelperTextRoot: {
    position: 'absolute',
    top: '2.1875rem',
  },
}));

function FormulaBar(props) {
  const classes = useStyle();

  const { error, onReset, onSave, onUpdate, value } = props;

  return (
    <div className={classes.container}>
      <ButtonGroup
        className={classes.buttonGroup}
        classes={{ groupedHorizontal: classes.buttonGroupEdge }}
        disabled={error !== ''}
      >
        <Button onClick={onReset}>
          <Clear color={error ? 'disabled' : 'error'} />
        </Button>

        <Button onClick={onSave}>
          <Check color={error ? 'disabled' : 'primary'} />
        </Button>
      </ButtonGroup>
      <form
        className={classes.form}
        onSubmit={(ev) => {
          ev.preventDefault();
          if (!error) {
            onSave();
          }
        }}
      >
        <SesamTextField
          InputProps={{ className: classes.inputRoot }}
          value={value}
          onChange={(ev) => onUpdate(ev.target.value)}
          margin="none"
          helperText={error}
          FormHelperTextProps={{
            className: classes.formHelperTextRoot,
          }}
          error={error !== ''}
        />
      </form>
    </div>
  );
}

FormulaBar.propTypes = {
  error: PropTypes.string.isRequired,
  onReset: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default FormulaBar;
