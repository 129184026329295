import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { sorterById } from 'Internals/utils';
import DatasetActions from 'Redux/thunks/datasets';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import PipeActions from 'Redux/thunks/pipes';
import SystemActions from 'Redux/thunks/systems';

import {
  getPipesFromSystem,
  getPipesToSystem,
  getPipesWithSystemTransform,
} from 'Internals/systems';
import { getSinkDatasetId } from 'Internals/pipes';
import { getComponentType, getIcon, getNodeLabel, getEdgeColor } from 'Internals/graph';
import StaticGraph from '../../components/graph/StaticGraph';
import GraphEdgeInfoPanel from '../../../components/graph-edge-info-panel/GraphEdgeInfoPanel';

// step in pixels for nodes in a row/col
const STEP_X = 150;
const STEP_Y = 100;

const COMPLETENESS_THRESHOLD = 48;

function isCompletenessBelowThreshold(sinkDataset, hours) {
  let completeness;
  const completenessThreshold = (Date.now() - 1000 * 60 * 60 * hours) * 1000;
  if (sinkDataset !== undefined) {
    completeness = get(sinkDataset, ['runtime', 'completeness']);
  }

  if (typeof completeness === 'number' && completeness < completenessThreshold) {
    return true;
  }

  return false;
}

function transformComponentsToGraph(
  main,
  directUpstream,
  directDownstream,
  indirectUpstream,
  indirectDownstream,
  allDatasets,
  darkMode = false
) {
  const nodes = [];
  const edges = [];
  const getNodeId = (o) => `${getComponentType(o)}:${o._id}`;
  const mainNodeId = getNodeId(main);
  // Add the center node
  nodes.push({
    id: mainNodeId,
    meta: {
      type: getComponentType(main),
      datasetId: getSinkDatasetId(main),
      ownId: main._id,
    },
    label: getNodeLabel(main),
    image: getIcon(main),
    size: 40,
    x: 0,
    y: 0,
    fixed: { x: true, y: true },
  });

  directUpstream
    .filter(getComponentType)
    .slice()
    .sort(sorterById)
    .forEach((obj, i) => {
      const id = getNodeId(obj) + ':' + 'DU:' + i;

      const sinkDatasetId = getSinkDatasetId(obj);
      const sinkDataset = allDatasets[sinkDatasetId];

      const completenessBelowThreshold = isCompletenessBelowThreshold(
        sinkDataset,
        COMPLETENESS_THRESHOLD
      );

      nodes.push({
        id,
        label: getNodeLabel(obj),
        meta: {
          type: getComponentType(obj),
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
        color: {
          color: getEdgeColor(obj, false, darkMode),
          highlight: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
          hover: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
        },
        image: getIcon(obj),
        x: -400,
        y: -((directUpstream.length - 1) * STEP_Y) / 2 + STEP_Y * i,
        fixed: { x: true },
      });

      edges.push({
        from: id,
        to: mainNodeId,
        color: {
          color: getEdgeColor(obj, completenessBelowThreshold, darkMode),
          highlight: getEdgeColor(obj, completenessBelowThreshold, darkMode),
          hover: getEdgeColor(obj, completenessBelowThreshold, darkMode),
        },
        id: `dataset:${obj._id}` + ':' + 'DU:' + i,
        meta: {
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
      });
    });

  directDownstream
    .filter(getComponentType)
    .slice()
    .sort(sorterById)
    .forEach((obj, i) => {
      const id = getNodeId(obj) + ':' + 'DD:' + i;

      const sinkDatasetId = getSinkDatasetId(obj);
      const sinkDataset = allDatasets[sinkDatasetId];

      const completenessBelowThreshold = isCompletenessBelowThreshold(
        sinkDataset,
        COMPLETENESS_THRESHOLD
      );

      nodes.push({
        id,
        label: getNodeLabel(obj),
        meta: {
          type: getComponentType(obj),
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
        color: {
          color: getEdgeColor(obj, false, darkMode),
          highlight: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
          hover: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
        },
        image: getIcon(obj),
        x: 400,
        y: -((directDownstream.length - 1) * STEP_Y) / 2 + STEP_Y * i,
        fixed: { x: true },
      });

      edges.push({
        from: mainNodeId,
        to: id,
        color: {
          color: getEdgeColor(obj, completenessBelowThreshold, darkMode),
          highlight: getEdgeColor(obj, completenessBelowThreshold, darkMode),
          hover: getEdgeColor(obj, completenessBelowThreshold, darkMode),
        },
        id: `dataset:${obj._id}` + ':' + 'DD:' + i,
        meta: {
          datasetId: getSinkDatasetId(main),
          ownId: obj._id,
        },
      });
    });

  indirectUpstream
    .filter(getComponentType)
    .slice()
    .sort(sorterById)
    .forEach((obj, i) => {
      const id = getNodeId(obj) + ':' + 'IU:' + i;
      const isPipe = getComponentType(obj) === 'pipe';

      const sinkDatasetId = getSinkDatasetId(obj);
      const sinkDataset = allDatasets[sinkDatasetId];

      const completenessBelowThreshold = isCompletenessBelowThreshold(
        sinkDataset,
        COMPLETENESS_THRESHOLD
      );

      nodes.push({
        id,
        label: getNodeLabel(obj),
        meta: {
          type: getComponentType(obj),
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
        color: {
          color: getEdgeColor(obj, false, darkMode),
          highlight: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
          hover: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
        },
        image: getIcon(obj),
        x: -((indirectUpstream.length - 1) * STEP_X) / 2 + STEP_X * i,
        y: -200,
        fixed: { y: true },
      });
      edges.push({
        from: id,
        to: mainNodeId,
        dashes: true,
        color: {
          color: getEdgeColor(obj, completenessBelowThreshold, darkMode),
          highlight: getEdgeColor(obj, completenessBelowThreshold, darkMode),
          hover: getEdgeColor(obj, completenessBelowThreshold, darkMode),
        },
        id: `dataset:${obj._id}` + ':' + 'IU:' + i,
        smooth: {
          forceDirection: 'vertical',
        },
        arrows: {
          to: { enabled: !isPipe },
          from: { enabled: !isPipe },
        },
        meta: {
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
      });
    });

  indirectDownstream
    .filter(getComponentType)
    .slice()
    .sort(sorterById)
    .forEach((obj, i) => {
      const id = getNodeId(obj) + ':' + 'ID:' + i;

      const sinkDatasetId = getSinkDatasetId(obj);
      const sinkDataset = allDatasets[sinkDatasetId];

      const completenessBelowThreshold = isCompletenessBelowThreshold(
        sinkDataset,
        COMPLETENESS_THRESHOLD
      );

      nodes.push({
        id,
        label: getNodeLabel(obj),
        meta: {
          type: getComponentType(obj),
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
        color: {
          color: getEdgeColor(obj, false, darkMode),
          highlight: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
          hover: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
        },
        image: getIcon(obj),
        x: -((indirectDownstream.length - 1) * STEP_X) / 2 + STEP_X * i,
        y: 200,
        fixed: { y: true },
      });
      edges.push({
        from: mainNodeId,
        to: id,
        dashes: true,
        color: {
          color: getEdgeColor(obj, completenessBelowThreshold, darkMode),
          highlight: getEdgeColor(obj, completenessBelowThreshold, darkMode),
          hover: getEdgeColor(obj, completenessBelowThreshold, darkMode),
        },
        id: `dataset:${obj._id}` + ':' + 'ID:' + i,
        smooth: {
          forceDirection: 'vertical',
        },
        arrows: {
          to: {
            enabled: true,
          },
          from: {
            enabled: getComponentType(main) === 'system',
          },
        },
        meta: {
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
      });
    });

  const edgesWithIds = edges.map((edge) => {
    if (!edge.id) {
      edge.id = `${edge.from}-${edge.to}`;
    }
    return edge;
  });

  const graph = {
    nodes,
    edges: edgesWithIds,
  };

  return {
    graph,
    mainNodeId,
  };
}

function SystemGraph(props, context) {
  function onGotoNode(type, id) {
    const url = `/subscription/${props.subId}/${type}s/${type}/${encodeURIComponent(id)}/graph`;

    if (props.onGotoNode) {
      props.onGotoNode(url);
    } else {
      context.router.push(url);
    }
  }

  const [selectedNode, setSelectedNode] = useState('');
  const [selectedEdge, setSelectedEdge] = useState('');
  const [pinnedEdge, setPinnedEdge] = useState('');
  const [lastChange, setLastChange] = useState('');
  const [lastChangedItem, setLastChangedItem] = useState('');
  const [hidden, setHidden] = useState(false);

  const isEdge = () => {
    return selectedEdge.length > 0 && lastChange === 'edge';
  };
  const isNode = () => {
    return selectedNode !== '' && lastChange === 'node';
  };

  useEffect(() => {
    props.loadSystem(props.params.systemID);
    props.loadDatasets();
    props.loadPipes();
  }, []);

  const refresh = useCallback(() => {
    const pipeIdsToRefresh = [
      ...props.outputPipes,
      ...props.inputPipes,
      ...props.outLookupPipes,
    ].map((p) => p._id);
    const systemIdsToRefresh = [props.params.systemID];
    const pipeRefreshPromises = pipeIdsToRefresh.map((id) => props.loadPipe(id));
    const systemRefreshPromises = systemIdsToRefresh.map((id) => props.loadSystem(id));
    Promise.all(pipeRefreshPromises);
    Promise.all(systemRefreshPromises);
  }, [props.outputPipes, props.inputPipes, props.outLookupPipes, props.params.systemID]);

  useEffect(() => {
    props.registerRefresh(refresh);
    return () => props.unregisterRefresh(refresh);
  });

  const { graph, mainNodeId } = useMemo(
    () =>
      transformComponentsToGraph(
        props.system,
        props.inputPipes,
        props.outputPipes,
        [],
        props.outLookupPipes,
        props.datasets,
        props.darkMode
      ),
    [props.system, props.inputPipes, props.outputPipes, props.outLookupPipes, props.dataset]
  );

  const isGraphPage = props.router.routes[props.router.routes.length - 1].path === 'graph';

  if (!props.system) {
    return null;
  }

  return (
    <ErrorBoundary fallback={<div>An error occured while rendering the flow</div>}>
      <StaticGraph
        graph={graph}
        onGotoNode={onGotoNode}
        mainNodeId={mainNodeId}
        autoSelectMainNode={false}
        onHoverEdge={(params) => {
          setSelectedEdge(params.edge);
          setLastChange('edge');
          const name = params.edge.split(':').slice(0, -1).join('');
          setLastChangedItem(name);
          setHidden(lastChangedItem !== name ? false : hidden);
        }}
        onHoverNode={(params) => {
          setSelectedNode(params.node);
          setLastChange('node');
          const name = params.node.split(':').slice(0, -1).join('');
          setLastChangedItem(name);
          setHidden(lastChangedItem !== name ? false : hidden);
        }}
        onBlurNode={() => {
          setSelectedNode('');
        }}
        onBlurEdge={() => {
          if (pinnedEdge === '') {
            setSelectedEdge('');
          } else {
            setSelectedEdge(pinnedEdge);
          }
        }}
        onSelectEdge={(params) => {
          // Only set selected edge when an edge is selected, not a node.
          if (Array.isArray(params.nodes) && params.nodes.length === 0) {
            const edge = params.edges.length > 0 ? params.edges[0] : '';
            setSelectedEdge(edge);
            setPinnedEdge(edge);
          }
          setTimeout(() => {
            setSelectedEdge('');
            setPinnedEdge('');
          }, 10000);
        }}
        onDeselectEdge={() => {
          setSelectedEdge('');
          setPinnedEdge('');
        }}
        options={props.options}
        fit={props.fit}
      />
      {isGraphPage && (isEdge() || isNode()) && (
        <GraphEdgeInfoPanel
          edge={isEdge() ? selectedEdge : selectedNode}
          main={props.system}
          nodeMode={isNode()}
          hidden={hidden}
          setHidden={setHidden}
        />
      )}
    </ErrorBoundary>
  );
}

SystemGraph.propTypes = {
  loadDatasets: PropTypes.func.isRequired,
  options: PropTypes.shape({}),
  loadPipes: PropTypes.func.isRequired,
  loadSystem: PropTypes.func.isRequired,
  params: PropTypes.shape({
    systemID: PropTypes.string.isRequired,
  }),
  system: PropTypes.object,
  pipes: PropTypes.shape({}).isRequired,
  dataset: PropTypes.object,
  datasets: PropTypes.shape({}).isRequired,
  outputPipes: PropTypes.array.isRequired,
  inputPipes: PropTypes.array.isRequired,
  outLookupPipes: PropTypes.array.isRequired,
  onGotoNode: PropTypes.func,
  subId: PropTypes.string.isRequired,
  fit: PropTypes.bool,
  router: PropTypes.shape({
    routes: PropTypes.arrayOf(PropTypes.object),
  }),
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
  loadPipe: PropTypes.func,
  darkMode: PropTypes.bool,
};

SystemGraph.contextTypes = {
  // https://github.com/reactjs/react-router/issues/975
  router: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  let outputPipes = getPipesFromSystem(ownProps.params.systemID, state.pipes, state.inbounds);
  let inputPipes = getPipesToSystem(ownProps.params.systemID, state.pipes, state.outbounds);
  const outLookupPipes = getPipesWithSystemTransform(
    ownProps.params.systemID,
    state.pipes,
    state.transforms
  );

  return {
    datasets: state.datasets,
    pipes: state.pipes,
    subId: state.subscription.id,
    system: state.systems[ownProps.params.systemID],
    outputPipes,
    inputPipes,
    outLookupPipes,
    darkMode: state.theme.dark,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDatasets: () => dispatch(DatasetActions.loadAll()),
    loadPipes: () => dispatch(PipeActions.loadAll()),
    loadSystem: (id) => dispatch(SystemActions.loadForced(id)),
    loadPipe: (id) => dispatch(PipeActions.loadForced(id)),
  };
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SystemGraph);
