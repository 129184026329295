import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import DatasetThunks from 'Redux/thunks/datasets';
import PipeThunks from 'Redux/thunks/pipes';
import SystemThunks from 'Redux/thunks/systems';
import {
  Dataset,
  DatasetMap,
  Optional,
  Pipe,
  PipeMap,
  PipeResponse,
  System,
  SystemMap,
} from '../types/types';
import useResourceInject from './useResourceInject';
import SystemsAPI from '../api/systems';
import PipesAPI from '../api/pipes';
import DatasetsAPI from '../api/datasets';
import { decoratePipe } from 'Redux/reducers/pipes';
import { decorateSystem } from 'Redux/reducers/systems';
import { decorateDataset } from 'Redux/reducers/datasets';
import { useCallback } from 'react';
import { apiConfSelector } from 'Redux/selectors';

export function useSubId() {
  const subId = useSelector((state: RootState) => state.subscription.id);
  return subId;
}

export function useDatasets(): [DatasetMap, boolean, boolean, (includeInternal: boolean) => void] {
  const dispatch = useDispatch();
  const datasets = useSelector((state: RootState) => state.datasets);
  const datasetsLoading = useSelector((state: RootState) => state.loadStatus.datasetsLoading);
  const datasetsLoaded = useSelector((state: RootState) => state.loadStatus.datasetsLoaded);
  function loadDatasets(includeInternal = false) {
    dispatch(DatasetThunks.loadAll(includeInternal));
  }
  if (!datasetsLoading && !datasetsLoaded) {
    loadDatasets();
  }
  return [datasets, datasetsLoading, datasetsLoaded, loadDatasets];
}

export function usePipes(): [PipeMap, boolean, boolean, (includeInternal: boolean) => void] {
  const dispatch = useDispatch();
  const pipes = useSelector((state: RootState) => state.pipes);
  const pipesLoading = useSelector((state: RootState) => state.loadStatus.pipesLoading);
  const pipesLoaded = useSelector((state: RootState) => state.loadStatus.pipesLoaded);
  function loadPipes(includeInternal = false) {
    dispatch(PipeThunks.loadAll(includeInternal));
  }
  if (!pipesLoading && !pipesLoaded) {
    loadPipes();
  }
  return [pipes, pipesLoading, pipesLoaded, loadPipes];
}

export function useSystems(): [SystemMap, boolean, boolean, (includeInternal?: boolean) => void] {
  const dispatch = useDispatch();
  const systems = useSelector((state: RootState) => state.systems);
  const systemsLoading = useSelector((state: RootState) => state.loadStatus.systemsLoading);
  const systemsLoaded = useSelector((state: RootState) => state.loadStatus.systemsLoaded);
  function loadSystems(includeInternal = false) {
    dispatch(SystemThunks.loadAll(includeInternal));
  }
  if (!systemsLoading && !systemsLoaded) {
    loadSystems();
  }
  return [systems, systemsLoading, systemsLoaded, loadSystems];
}

export function useSystem(id?: string) {
  const apiConf = useSelector(apiConfSelector);
  const _get = useCallback(() => {
    console.log('Getting system');
    return SystemsAPI.get(apiConf, id);
  }, [id]);
  const _delete = useCallback(() => {
    return SystemsAPI.remove(apiConf, id);
  }, [id]);
  const [system, loading, error, refresh] = useResourceInject<System>(() => _get(), true);
  console.log(system, loading, error, refresh);
  return [system ? decorateSystem(system) : null, loading, error, refresh, _delete];
}

export function usePipe(id?: string) {
  const apiConf = useSelector(apiConfSelector);
  const _get = useCallback(() => {
    return PipesAPI.get(apiConf, id);
  }, [id]);
  const [pipe, loading, error, refresh] = useResourceInject<PipeResponse>(() => _get());
  return [pipe ? decoratePipe(pipe) : null, loading, error, refresh];
}

export function useDataset(id?: string) {
  const apiConf = useSelector(apiConfSelector);
  const _get = useCallback(() => {
    return DatasetsAPI.get(apiConf, id);
  }, [id]);
  const [dataset, loading, error, refresh] = useResourceInject<Dataset>(() => _get());
  return [dataset ? decorateDataset(dataset) : null, loading, error, refresh];
}
