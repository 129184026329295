import React, { forwardRef } from 'react';

import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';

const SesamMenuItem: React.FC<MenuItemProps> = forwardRef(function SesamMenuItem(props, ref) {
  const { children, ...restProps } = props;

  return (
    <MenuItem ref={ref} {...restProps}>
      {children}
    </MenuItem>
  );
});

export default SesamMenuItem;
