import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import orderBy from 'lodash/orderBy';
import DatahubSettings from '../settings-datahub';

import Button from 'Common/Button/Button';
import DatasetActions, { datasetsSelector } from 'Redux/thunks/datasets';
import PipeActions from 'Redux/thunks/pipes';
import { pipesSelector } from 'Redux/selectors';
import { withSeparators, amountToSize } from 'Internals/utils';
import { getPipeQueueSize } from 'Internals/pipes';
import './style.css';

const ReactTable = lazy(() =>
  import(/* webpackChunkName: "react-table", webpackPrefetch: true */ 'react-table-6')
);

import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';
import { getDatasetQueueSize } from 'Internals/datasets';
import { LoadingPanel } from 'Common/LoadingPanel';
import PipeLink from 'Common/Links/PipeLink';
import State from 'Common/renderers/State/State';
import DatasetLink from 'Common/Links/DatasetLink';

const LOCAL_STORAGE_KEY = 'sesam--queues-table';
const SHOW_EXACT_KEY = 'showExact';

const IsDisabledRenderer = ({ isDisabled }) =>
  isDisabled ? (
    <State title="Pipe is disabled" className="status-red" text="Yes" />
  ) : (
    <State title="Pipe is enabled" className="status-green" text="No" />
  );
IsDisabledRenderer.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

const IsRunningRenderer = ({ isRunning }) =>
  isRunning ? (
    <State title="Pipe is running" className="status-green" text="Yes" />
  ) : (
    <State title="Pipe is not running" className="status-red" text="No" />
  );
IsRunningRenderer.propTypes = {
  isRunning: PropTypes.bool.isRequired,
};

const IsIndexingRenderer = ({ isIndexing }) =>
  isIndexing ? (
    <State title="Dataset is being indexed" className="status-green" text="Yes" />
  ) : (
    <State title="Dataset is not being indexed" className="status-red" text="No" />
  );
IsIndexingRenderer.propTypes = {
  isIndexing: PropTypes.bool.isRequired,
};

class Queues extends React.Component {
  constructor(props) {
    super(props);

    const defaultShowExact = true;

    this.loadPersonalShowExact = () => {
      const personalShowExact = getFromLocalStorage(
        LOCAL_STORAGE_KEY,
        [SHOW_EXACT_KEY],
        defaultShowExact
      );
      return personalShowExact;
    };

    this.savePersonalShowExact = (showExact) => {
      this.setState({ showExact });
      setIntoLocalStorage(LOCAL_STORAGE_KEY, [SHOW_EXACT_KEY], showExact);
    };

    this.toggleShowExact = () => {
      const currentShowExact = this.state.showExact;
      this.setState({ showExact: !this.state.showExact });
      this.savePersonalShowExact(!currentShowExact);
    };

    this.getPipeCols = () => [
      {
        Header: 'Pipe',
        id: 'pipes-link',
        accessor: 'original.link',
        headerClassName: 'column_header--align-left',
        Cell: (row) => <PipeLink pipeId={row.value} />,
      },
      {
        Header: 'Queues',
        id: 'pipes-queues',
        accessor: 'size',
        maxWidth: 100,
        headerClassName: 'column_header--align-right',
        style: { textAlign: 'right' },
        Cell: (row) => (
          <span onClick={this.toggleShowExact} title="Click to switch representation">
            {this.state.showExact ? withSeparators(row.value) : amountToSize(row.value)}
          </span>
        ),
      },
      {
        Header: 'Running',
        id: 'pipes-running',
        headerClassName: 'column_header--align-right',
        maxWidth: 80,
        style: { textAlign: 'right' },
        accessor: 'original.runtime[is-running]',
        Cell: (row) => <IsRunningRenderer isRunning={row.value} />,
      },
      {
        Header: 'Disabled',
        id: 'pipes-disabled',
        headerClassName: 'column_header--align-right',
        maxWidth: 80,
        style: { textAlign: 'right' },
        accessor: 'original.runtime[is-disabled]',
        Cell: (row) => <IsDisabledRenderer isDisabled={row.value} />,
      },
    ];

    this.getDatasetCols = () => [
      {
        Header: 'Dataset',
        id: 'datasets-link',
        accessor: 'original.link',
        headerClassName: 'column_header--align-left',
        Cell: (row) => <DatasetLink datasetId={row.value} />,
        sortMethod: (a, b) => {
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
          return 0;
        },
      },
      {
        Header: 'Queue size',
        id: 'datasets-queues',
        accessor: 'size',
        maxWidth: 100,
        Cell: (row) => (
          <span onClick={this.toggleShowExact} title="Click to switch representation">
            {this.state.showExact ? withSeparators(row.value) : amountToSize(row.value)}
          </span>
        ),
      },
      {
        Header: 'Indexing',
        id: 'datasets-indexing',
        accessor: 'indexing',
        maxWidth: 80,

        Cell: (row) => <IsIndexingRenderer isIndexing={row.value} />,
      },
    ];

    this.state = {
      showExact: this.loadPersonalShowExact(),
    };
  }

  componentDidMount() {
    this.props.loadAll();
  }

  render() {
    const pipes = this.props.pipes
      .map((pipe) => ({
        original: pipe,
        size: getPipeQueueSize(pipe),
      }))
      .filter((pipe) => pipe.size > 0);
    const datasets = this.props.datasets
      .map((dataset) => ({
        indexing: !!dataset.runtime.indexing,
        original: dataset,
        size: getDatasetQueueSize(dataset),
      }))
      .filter((dataset) => dataset.size > 0 || dataset.indexing);

    return (
      <DatahubSettings>
        <main className="scrollArea">
          <div className="row">
            <Suspense fallback={<LoadingPanel loadingMessage="Loading library" />}>
              <div className="col col--elastic gr-primary queues">
                <h1 className="heading-section">Pipes</h1>
                {pipes.length > 0 ? (
                  <ReactTable
                    className="-striped"
                    data={pipes}
                    columns={this.getPipeCols()}
                    showPagination={false}
                    pageSize={pipes.length}
                    defaultSorted={[
                      {
                        id: 'pipes-queues',
                        desc: true,
                      },
                    ]}
                  />
                ) : (
                  <p>All {this.props.pipes.length} pipe(s) are up-to-date</p>
                )}
                <h1 className="heading-section">Datasets</h1>
                {datasets.length > 0 ? (
                  <ReactTable
                    className="-striped"
                    data={datasets}
                    columns={this.getDatasetCols()}
                    showPagination={false}
                    pageSize={datasets.length}
                    defaultSorted={[{ id: 'datasets-queues', desc: true }]}
                  />
                ) : (
                  <p>All {this.props.datasets.length} dataset(s) are up-to-date</p>
                )}
                <div className="toolbar" style={{ marginTop: '1rem' }}>
                  <Button theme="primary" onClick={this.props.loadAll}>
                    Refresh
                  </Button>
                </div>
              </div>
            </Suspense>
            <div className="col gr-secondary">
              <p>
                Pipes that read from datasets may contain entities that are not yet processed by
                downstream pipes. Datasets are indexed asynchronously and may contain entities that
                are not yet indexed.
              </p>
              <p>This page lists all pipes and datasets that have such entities.</p>
            </div>
          </div>
        </main>
      </DatahubSettings>
    );
  }
}

Queues.propTypes = {
  subId: PropTypes.string.isRequired,
  loadAll: PropTypes.func.isRequired,
  datasets: PropTypes.array.isRequired,
  pipes: PropTypes.array.isRequired,
};

Queues.contextTypes = {
  // https://github.com/reactjs/react-router/issues/975
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    datasets: datasetsSelector(state),
    pipes: pipesSelector(state),
    subId: state.subscription.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAll: () => {
      dispatch(DatasetActions.loadAll());
      dispatch(PipeActions.loadAll());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Queues);
