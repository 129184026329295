import PropTypes from 'prop-types';
import React from 'react';
import SesamLink from 'Common/Links/SesamLink';
import Button from 'Common/Button/Button';
import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
const useStyle = makeStyles((theme) => {
  return {
    buttonLink: {
      padding: '4px 8px',
      fontSize: '13px',
      height: '26px',
    },
  };
});

function ButtonLink(props) {
  const { children, className, to, size, ...restProps } = props;
  const classes = useStyle();

  let fullClassName = '';
  if (size === 'tiny') {
    fullClassName = classes.buttonLink;
  } else {
    restProps.size = size;
  }
  if (className) {
    fullClassName = fullClassName + ' ' + className;
  }

  return (
    <SesamLink to={to} buttonLink={true}>
      <Button className={fullClassName} {...restProps}>
        {children}
      </Button>
    </SesamLink>
  );
}

ButtonLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
};

ButtonLink.defaultProps = {
  theme: 'primary',
  size: 'tiny',
};

export default ButtonLink;
