import isUndefined from 'lodash/isUndefined';
import mapObject from 'lodash/map';
import omitBy from 'lodash/omitBy';

import { sfetchJson } from 'Internals/sfetch';
import debounce from 'lodash/debounce';
import mapKeys from 'lodash/mapKeys';
import { ApiConf, Entity, SomeObject } from '../types/types';

export interface EntitiesApiConf extends ApiConf {
  dataset: string;
}

// -----------------------------------------------------------------------------

/**
 * Looks for an index, with the same name as the dataset, in Elastic Search
 * @param {object} apiConf config of the call, contains sub, dataset id and token
 */
function lookupDatasetIndexInElasticSearch(apiConf: EntitiesApiConf) {
  const requestOptions: RequestInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `bearer ${apiConf.token}`,
      'Content-Type': 'application/json',
    },
  };

  // First just look for the elasticsearch-freetext system
  return sfetchJson(`${apiConf.subUrl}/systems/elasticsearch-freetext`, requestOptions)
    .then(() => {
      // and only if this succeeds look for the index behind the proxy
      return sfetchJson(
        `${apiConf.subUrl}/systems/elasticsearch-freetext/proxy/${apiConf.dataset}`,
        requestOptions
      );
    })
    .catch((err) => {
      return null;
    });
}

/**
 * Looks up a single entity based on its ID using the search API
 * @param {object} apiConf config of the call, contains sub, dataset id and token
 * @param {string} entityId the entity ID which to lookup
 * @param {boolean} history whether to use history=true as parameter
 * @returns {Promise} When resolved, an array of entities of length 1, or empty array
 */
function lookupEntity(
  apiConf: EntitiesApiConf,
  entityId: string,
  history: boolean = false
): Promise<Entity[]> {
  const entityIdUrl = encodeURIComponent(entityId);
  let url = `${apiConf.subUrl}/datasets/${apiConf.dataset}/search?id=${entityIdUrl}`;

  if (history) {
    url = `${url}&history=true`;
  }

  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${apiConf.token}` },
  };
  return sfetchJson(url, options);
}

/**
 * Gets entity by ID and offset
 * If no offset is specified, it gets the latest version of the entity (almost the same as lookupEntity())
 * If there is not an entity with this ID at provided offset, promise rejects
 * @param {object} apiConf config of the call, contains sub, dataset id and token
 * @param {string} entityId the entity ID which to retrieve
 * @param {number} offset sequence number at which to get the entity
 * @returns {Promise} When resolved, the matched entity. Rejects if there is not an entity with this ID at provided offset
 */
function getEntity(apiConf: EntitiesApiConf, entityId: string, offset?: number) {
  const entityIdUrl = encodeURIComponent(entityId);
  let url;
  if (!offset && offset !== 0) {
    url = `${apiConf.subUrl}/datasets/${apiConf.dataset}/entity?entity_id=${entityIdUrl}`;
  } else {
    const offsetUrl = encodeURIComponent(offset);
    url = `${apiConf.subUrl}/datasets/${apiConf.dataset}/entity?entity_id=${entityIdUrl}&offset=${offsetUrl}`;
  }
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${apiConf.token}` },
  };
  return sfetchJson(url, options);
}

/**
 * Fetches entities from the datahub
 * @param {object} apiConf config of the call, contains sub, dataset id and token
 * @param {object} searchParams Key/values to be used as search in the URL
 * @return {Promise} When resolved, an array of entities
 */
function fetchEntities(apiConf: EntitiesApiConf, searchParams: SomeObject): Promise<Entity[]> {
  const searchParamsClean = omitBy(searchParams, isUndefined);
  const urlSearch = mapObject(
    searchParamsClean,
    (value, key) => `${key}=${encodeURIComponent(value)}`
  ).join('&');
  const url = `${apiConf.subUrl}/datasets/${apiConf.dataset}/entities?${urlSearch}`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${apiConf.token}` },
  };
  return sfetchJson(url, options);
}

/**
 * Fetches entities from ElasticSearch
 * @param {object} apiConf -> config of the call, contains sub, datasetId and token
 * @param {object} queryOptions -> key/values to be used in the query
 * @return {Promise} When resolved, an array of entities
 */
async function fetchEntitiesFromElasticSearch(
  apiConf: EntitiesApiConf,
  indexName: string,
  options: SomeObject
) {
  const query: SomeObject = {
    query: {
      bool: {
        must: [
          {
            query_string: {
              query: options.query,
            },
          },
        ],
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    size: options.limit,
    sort: { __updated: { order: options.reverse ? 'desc' : 'asc' } },
  };

  if (options.since !== undefined && options.since !== null) {
    query.query.bool.must.push({
      range: {
        __updated: options.reverse ? { lt: options.since } : { gt: options.since },
      },
    });
  }

  if (!options.deleted) {
    query.query.bool.filter.bool.must.push({ term: { __deleted: false } });
  }

  if (!options.history) {
    query.collapse = {
      field: '__id',
    };
  }

  if (options.subset) {
    query.query.bool.filter.bool.must.push(options.subset);
  }

  const requestOptions: RequestInit = {
    credentials: 'include',
    method: 'POST',
    headers: {
      Authorization: `bearer ${apiConf.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  };

  return sfetchJson(
    `${apiConf.subUrl}/systems/elasticsearch-freetext/proxy/${indexName}/_search`,
    requestOptions
  ).then((result) => {
    const hits = result.hits.hits;
    const entities = hits.map((hit: SomeObject) => {
      return mapKeys({ ...hit._source }, (val, key) => {
        return key.replace('__', '_'); // Will only replace the first instance of '__'
      });
    });
    return entities;
  });
}

const jumpToTimestamp = debounce(
  (
    apiConf: EntitiesApiConf,
    timestamp: number,
    deleted: boolean,
    history: boolean,
    uncommitted: boolean,
    subset: string
  ) => {
    async function recursiveFindTimestamp(
      topEntity: Entity,
      bottomEntity: Entity,
      lookUpwards: boolean,
      lastUnder: number | null = null
    ): Promise<number | undefined> {
      if (!topEntity || !bottomEntity) {
        return 0;
      }

      const bottomTimestamp = bottomEntity['_ts'];
      const bottomUpdated = bottomEntity['_updated'];
      const topTimestamp = topEntity['_ts'];
      const topUpdated = topEntity['_updated'];

      if (timestamp > topTimestamp) {
        return topUpdated;
      }

      if (timestamp <= bottomTimestamp) {
        return bottomUpdated;
      }

      const since = Math.round(
        lookUpwards
          ? bottomUpdated + (topUpdated - bottomUpdated) / 2 - 1
          : topUpdated - (topUpdated - bottomUpdated) / 2 + 1
      );

      const middleArr = await fetchEntities(apiConf, {
        limit: 1,
        deleted: deleted,
        history: history,
        uncommitted: uncommitted,
        reverse: !lookUpwards,
        since: since,
        subset,
      });

      const middleEntity = middleArr[0];

      const middleUpdated = middleEntity['_updated'];
      const middleTimestamp = middleEntity['_ts'];

      if (bottomUpdated === since && lastUnder === null) {
        return bottomUpdated;
      } else if (middleTimestamp >= timestamp && middleUpdated !== topUpdated) {
        return recursiveFindTimestamp(middleEntity, bottomEntity, false, lastUnder);
      } else if (middleTimestamp >= timestamp && middleUpdated === topUpdated) {
        if (lastUnder) {
          return lastUnder;
        } else {
          return recursiveFindTimestamp(middleEntity, bottomEntity, true, lastUnder);
        }
      } else if (middleTimestamp < timestamp && middleUpdated !== bottomUpdated) {
        return recursiveFindTimestamp(topEntity, middleEntity, false, middleUpdated);
      } else if (middleTimestamp < timestamp && middleUpdated === bottomUpdated) {
        return recursiveFindTimestamp(topEntity, middleEntity, true, middleUpdated);
      }
    }

    return fetchEntities(apiConf, {
      limit: 1,
      deleted: deleted,
      history: history,
      uncommitted: uncommitted,
      reverse: true,
      subset,
    }).then((entities) => {
      const lastEntity = entities[0];
      return fetchEntities(apiConf, {
        limit: 1,
        deleted: deleted,
        history: history,
        uncommitted: uncommitted,
        reverse: false,
        subset,
      }).then((entities) => {
        const firstEntity = entities[0];
        return recursiveFindTimestamp(lastEntity, firstEntity, true);
      });
    });
  },
  500,
  { leading: true, trailing: false }
);

async function jumpToOffset(
  apiConf: EntitiesApiConf,
  offset: string,
  history: boolean,
  deleted: boolean,
  uncommitted: boolean,
  subset: string,
  query: string,
  source = 'node',
  indexName: string
) {
  const prevQuery = {
    since: parseInt(offset) - 1,
    limit: 50,
    deleted: deleted,
    history: history,
    uncommitted: uncommitted,
    reverse: false,
    query,
    subset,
  };

  const nextQuery = {
    since: offset,
    limit: 50,
    reverse: true,
    deleted: deleted,
    history: history,
    uncommitted: uncommitted,
    query,
    subset,
  };

  let prevEntities;
  let nextEntities;
  if (source === 'node') {
    prevEntities = await fetchEntities(apiConf, prevQuery);
    nextEntities = await fetchEntities(apiConf, nextQuery);
  } else if (source === 'elasticsearch') {
    prevEntities = await fetchEntitiesFromElasticSearch(apiConf, indexName, prevQuery);
    nextEntities = await fetchEntitiesFromElasticSearch(apiConf, indexName, nextQuery);
  }
  const entities = prevEntities.reverse().concat(nextEntities);
  const selectedEntity = prevEntities[prevEntities.length - 1] || nextEntities[0];
  const hasMoreTop = prevEntities.length === 50;
  const hasMore = nextEntities.length === 50;

  const prev = prevEntities.length > 0 ? prevEntities.length - 1 : 0;
  return { entities, selectedEntity, hasMoreTop, hasMore, prev };
}

async function loadEntitiesBySearch(
  apiConf: EntitiesApiConf,
  entityId: string,
  howMany: number,
  includeDeleted: boolean,
  allHistory: boolean
) {
  let entities: Entity[] = [];
  try {
    entities = await lookupEntity(apiConf, entityId, allHistory);
    if (!includeDeleted) {
      entities = entities.filter((e) => !e._deleted);
    }
  } catch (err) {
    console.log(err);
  }
  return entities;
}

interface BaseGetEntitiesParams {
  datasetId: string;
  limit?: number;
  since?: number;
  history?: boolean;
  reverse?: boolean;
  deleted?: boolean;
  subset?: string;
}

interface WebhookGetEntitiesParams extends BaseGetEntitiesParams {
  webhook: string;
  token: string;
}

export type GetEntitiesParams = BaseGetEntitiesParams | WebhookGetEntitiesParams;

export async function getEntities(apiConf: ApiConf, params: GetEntitiesParams) {
  // the fetchEntities function wants the datasetId in the apiConf
  const entitiesApiConf = { ...apiConf, dataset: params.datasetId };
  delete params.datasetId;
  return await fetchEntities(entitiesApiConf, params);
}

async function getOffestByTimestampFromElastic(
  apiConf: EntitiesApiConf,
  indexName: string,
  query: string,
  timestamp: number
) {
  const q = {
    query: {
      bool: {
        must: [
          {
            query_string: {
              query: query,
            },
          },
          {
            range: {
              __ts: { lte: timestamp },
            },
          },
        ],
      },
    },
    size: 1,
    sort: { __ts: { order: 'desc' } },
  };

  const fetchOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Authorization: `bearer ${apiConf.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(q),
  };

  return sfetchJson(
    `${apiConf.subUrl}/systems/elasticsearch-freetext/proxy/${indexName}/_search`,
    fetchOptions
  ).then((result) => {
    const hits = result.hits.hits;
    if (hits.length > 0) {
      return hits[0]._source.__updated;
    }
    return null;
  });
}

export default {
  fetchEntities,
  lookupDatasetIndexInElasticSearch,
  lookupEntity,
  getEntity,
  jumpToTimestamp,
  jumpToOffset,
  loadEntitiesBySearch,
  fetchEntitiesFromElasticSearch,
  getOffestByTimestampFromElastic,
};
