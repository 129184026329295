import React from 'react';
import PropTypes from 'prop-types';

import { ActionPanel } from '../action/ActionPanel';
import { DeleteAction } from '../action/DeleteAction';

const SystemsListActions = (props) => (
  <div className="list-actions">
    <ActionPanel>
      <DeleteAction onDelete={props.onDelete} selection={props.selectedSystems} title="system" />
    </ActionPanel>
  </div>
);

SystemsListActions.propTypes = {
  onDelete: PropTypes.func.isRequired,
  selectedSystems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SystemsListActions;
