import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SystemActions from 'Redux/thunks/systems';
import { ActionBarStatus } from '../../../components/action-bar';
import SystemEditor from '../../../components/system-editor';

class EditSystem extends React.Component {
  constructor(props) {
    super(props);

    this.save = (system) => {
      this.setState({
        status: ActionBarStatus.waiting,
        shortMessage: 'Saving…',
        message: '',
      });

      return this.props
        .update(this.props.params.systemID, system)
        .then(() =>
          this.setState({
            status: ActionBarStatus.default,
            shortMessage: 'System saved',
          })
        )
        .catch((err) =>
          this.setState({
            status: ActionBarStatus.error,
            shortMessage: 'Could not save system',
            message: err,
          })
        );
    };

    this.state = {
      message: '',
      shortMessage: '',
      status: ActionBarStatus.default,
    };
  }

  componentDidMount() {
    this.props.load(this.props.params.systemID);
  }

  render() {
    const system = this.props.system;
    if (!system) {
      return null;
    }
    return (
      <SystemEditor
        onSave={this.save}
        initialDirty
        initialSaved
        initialValue={system.config.original}
        message={this.state.message}
        shortMessage={this.state.shortMessage}
        status={this.state.status}
      />
    );
  }
}

EditSystem.contextTypes = {
  // https://github.com/reactjs/react-router/issues/975
  router: PropTypes.object,
};

EditSystem.propTypes = {
  params: PropTypes.shape({
    systemID: PropTypes.string.isRequired,
  }),
  subId: PropTypes.string.isRequired,
  system: PropTypes.object,
  load: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    system: state.systems[ownProps.params.systemID],
    subId: state.subscription.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    load: (id) => dispatch(SystemActions.load(id)),
    update: (id, updatedSystem) => dispatch(SystemActions.update(id, updatedSystem)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSystem);
