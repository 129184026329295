import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ComparePanel from 'Common/ComparePanel';
import { DatasetInspectorContext } from '../DatasetInspectorContext';

function DatasetInspectorViewerEntityBody(props) {
  const [state] = useContext(DatasetInspectorContext);
  const selectedEntityKey = state.selectedEntity && JSON.stringify(state.selectedEntity);

  return (
    <div className="dataset-inspector-viewer__entity-body">
      <ComparePanel
        value={state.previousEntity}
        key={selectedEntityKey}
        original={state.selectedEntity}
        compare={props.compare}
      />
    </div>
  );
}

DatasetInspectorViewerEntityBody.propTypes = {
  compare: PropTypes.bool.isRequired,
};

export default DatasetInspectorViewerEntityBody;
