import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import get from 'lodash/get';

import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';
import PipeActions from 'Redux/thunks/pipes';
import SystemActions from 'Redux/thunks/systems';
import { systemsSelector } from 'Redux/selectors';
import PipeEditor from '../../../components/pipe-editor';
import { ActionBarStatus } from '../../../components/action-bar';

const LOCAL_STORAGE_KEY = 'sesam--config-editor';
const DATA_VIEW_KEY = 'showDataView';

class EditPipe extends React.Component {
  constructor(props) {
    super(props);
    this.handleToggleView = () => {
      const showDataViewTogggled = !this.state.showDataView;

      setIntoLocalStorage(LOCAL_STORAGE_KEY, DATA_VIEW_KEY, showDataViewTogggled);

      this.setState({
        showDataView: showDataViewTogggled,
      });
    };

    this.save = (pipe) => {
      this.setState({
        status: ActionBarStatus.waiting,
        shortMessage: 'Saving…',
        message: '',
      });

      return this.props
        .update(this.props.params.pipeID, pipe)
        .then(() => {
          this.setState({
            status: ActionBarStatus.default,
            shortMessage: 'Pipe saved',
          });
        })
        .catch((err) => {
          this.setState({
            status: ActionBarStatus.error,
            shortMessage: 'Could not save pipe',
            message: err,
          });

          throw new Error(err);
        });
    };

    this.shouldShowToggle = () => {
      let showToggle = false;

      const config = get(this.props.pipe, 'config.original');
      const sourceType = get(config, 'source.type');
      const transform = config.transform;
      if (sourceType === 'dataset') {
        if (transform) {
          if (
            Array.isArray(transform) &&
            transform.length > 0 &&
            typeof transform[0] === 'object' &&
            transform[0].type === 'dtl'
          ) {
            showToggle = true;
          } else if (typeof transform === 'object' && transform.type === 'dtl') {
            showToggle = true;
          }
        } else {
          showToggle = true;
        }
      }

      return showToggle;
    };

    const persistedShowDataView = getFromLocalStorage(LOCAL_STORAGE_KEY, DATA_VIEW_KEY, false);

    this.state = {
      message: '',
      shortMessage: '',
      status: ActionBarStatus.default,
      showDataView: persistedShowDataView,
    };
  }

  componentDidMount() {
    this.props.loadSystems();
  }

  render() {
    const pipe = this.props.pipe;
    if (!pipe) {
      return null;
    }
    return (
      <ErrorBoundary>
        <PipeEditor
          initialValue={pipe.config.original}
          pipe={pipe}
          // TODO: should not be ready until pipe is provisioned in multinode
          ready
          initialSaved
          message={this.state.message}
          onSave={this.save}
          onToggleView={this.handleToggleView}
          shortMessage={this.state.shortMessage}
          status={this.state.status}
          systems={this.props.systems}
          registerRefresh={this.props.registerRefresh}
          unregisterRefresh={this.props.unregisterRefresh}
          shouldShowToggle={this.shouldShowToggle()}
          showDataView={this.state.showDataView}
        />
      </ErrorBoundary>
    );
  }
}

EditPipe.propTypes = {
  pipe: PropTypes.object,
  subId: PropTypes.string.isRequired,
  systems: PropTypes.array.isRequired,
  params: PropTypes.shape({ pipeID: PropTypes.string }),
  loadSystems: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  registerRefresh: PropTypes.func.isRequired,
  unregisterRefresh: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  pipe: state.pipes[ownProps.params.pipeID],
  subId: state.subscription.id,
  systems: systemsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadSystems: () => dispatch(SystemActions.loadAll(true)),
  update: (id, updatedPipe) => dispatch(PipeActions.update(id, updatedPipe)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPipe);
