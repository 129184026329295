import React from 'react';
import CancelIcon from '../../images/icons/cancel.svg';
import { TransitionState } from './Toaster';
import { ToastType } from 'Redux/thunks/global';
import './style.css';

export const Toast: React.FC<{
  message: string;
  type: ToastType;
  transitionState: TransitionState;
  dismiss: () => void;
}> = ({ message, type = 'notification', transitionState, dismiss }) => {
  const classNames = ['toast', `toast--${type}`, `toast--${transitionState}`];

  return (
    <div className={classNames.join(' ')}>
      <div className="toast__message">{message}</div>
      <button className="toast__button" onClick={dismiss}>
        <span className="visually-hidden">Dismiss</span>
        <CancelIcon />
      </button>
    </div>
  );
};
