import React from 'react';
import PropTypes from 'prop-types';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

import './style.css';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    height: '25px',
    width: '150px',
  },
  toggleButton: {
    fontFamily: 'Barlow, sans-serif',
    height: '25px',
    width: '75px',
    textTransform: 'capitalize',
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    '&.MuiToggleButton-root.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.active,
      color: '#fff',
    },
  },
}));

function DisplayModeButton(props) {
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      value={props.buttonValue}
      exclusive
      onChange={props.buttonToggled}
      aria-label="Toggle button"
      className={classes.buttonContainer}
    >
      <ToggleButton value={props.value1} className={classes.toggleButton}>
        {props.value1}
      </ToggleButton>
      <ToggleButton value={props.value2} className={classes.toggleButton}>
        {props.value2}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

DisplayModeButton.defaultProps = {
  buttonValue: 'option1',
  value1: 'option1',
  value2: 'option2',
  buttonToggled: null,
};

DisplayModeButton.propTypes = {
  buttonValue: PropTypes.string,
  value1: PropTypes.string,
  value2: PropTypes.string,
  buttonToggled: PropTypes.func,
};

export default DisplayModeButton;
