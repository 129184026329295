import React from 'react';
import PropTypes from 'prop-types';
import SubscriptionLink from './SubscriptionLink';

function SystemLink({ systemId, children }) {
  if (!children) {
    children = systemId;
  }
  return (
    <SubscriptionLink title={systemId} to={`systems/system/${systemId}`}>
      {children}
    </SubscriptionLink>
  );
}

SystemLink.propTypes = {
  systemId: PropTypes.string,
  children: PropTypes.node,
};

export default SystemLink;
