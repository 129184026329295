import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Button from 'Common/Button/Button';
import CollapsePanel from 'Common/CollapsePanel/CollapsePanel';
import { Form, FormActions } from 'Common/forms';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

import { apologise } from 'Redux/thunks/apology';
import DatahubActions from 'Redux/thunks/datahub';
import SubActions from 'Redux/thunks/subscriptions';
import DangerText from 'Common/Text/DangerText';

function Unlicensed() {
  return <p>Unlicensed</p>;
}

function duration(expiryDate) {
  const diff = moment().diff(expiryDate);
  return (
    `${moment(expiryDate).format('MMMM Do YYYY, h:mm:ss a')}` +
    ` (${diff < 0 ? 'in about' : 'did expire'} ${moment.duration(diff).humanize()}${
      diff < 0 ? '' : ' ago'
    })`
  );
}
function Licensed(props) {
  const license = props.license;
  const expires = license.expires ? duration(license.expires) : 'N/A';
  const licensedTo =
    license['licensed-to'] && license['licensed-to'].email ? license['licensed-to'].email : 'N/A';

  return (
    <dl className="key-value">
      <dt className="key-value__key">ID</dt>
      <dd className="key-value__val" data-selenium-license-id>
        {license._id}
      </dd>
      <dt className="key-value__key">Expires</dt>
      <dd className="key-value__val">{expires}</dd>
      {license['has-expired'] && (
        <dd className="key-value__val">
          <DangerText>License has expired</DangerText>
        </dd>
      )}
      <dt className="key-value__key">Licensed to</dt>
      <dd className="key-value__val">{licensedTo}</dd>
    </dl>
  );
}

Licensed.propTypes = {
  license: PropTypes.any.isRequired,
};

class SettingsSubscriptionLicense extends React.Component {
  constructor(props) {
    super(props);

    this.onUpload = (ev) => {
      ev.preventDefault();
      this.props
        .uploadLicense(this.state.license)
        .then(() => location.reload())
        .catch((response) =>
          this.props.onUploadFail(response.responseBody.detail || response.title)
        );
    };

    this.handleLicenseChanged = (ev) => {
      this.setState({
        license: ev.target.value,
      });
    };

    this.handleOnRenewLicense = () => {
      return this.props.renewLicense(this.props.subId);
    };

    this.refresh = () => {
      this.props.refreshLicense();
    };

    this.state = {
      license: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.connected && this.props.connected) {
      this.props.refreshLicense();
    }
  }

  componentDidMount() {
    this.license = React.createRef();
    if (this.props.connected) {
      this.props.refreshLicense();
    }
    this.props.registerRefresh(this.refresh);
  }

  componentWillUnmount() {
    this.props.unregisterRefresh(this.refresh);
  }

  render() {
    const canRenewLicense = get(
      this.props.sub,
      'supported-operations.renew_license.enabled',
      false
    );

    let currentLicense;
    if (this.props.license) {
      currentLicense =
        this.props.license._id === null ? (
          <Unlicensed />
        ) : (
          <Licensed license={this.props.license} />
        );
    } else {
      currentLicense = (
        <p>
          <em>Not connected</em>
        </p>
      );
    }

    return (
      <main className="scrollArea">
        <div className="row">
          <div className="col gr-equal">
            <h2 className="heading-section">Current license</h2>
            {currentLicense}
            <h2 className="heading-section">License bundles</h2>
            {this.props.sub.licenses &&
              this.props.sub.licenses.map((license) => (
                <dl
                  className={`key-value license ${
                    (license['is-active-license'] && ' active-license') || ' inactive-license'
                  }`}
                  key={`${license.original.created}_${license.original.expires}`}
                >
                  {license.signed && (
                    <div>
                      <dt className="key-value__key">Bundle</dt>
                      <dd className="key-value__val">
                        <CollapsePanel title={license.original.email}>
                          <pre data-selenium-license-bundle>{license.signed}</pre>
                        </CollapsePanel>
                      </dd>
                    </div>
                  )}
                  <dt className="key-value__key">Created</dt>
                  <dd className="key-value__val">{license.original.created}</dd>
                  <dt className="key-value__key">Expires</dt>
                  <dd className="key-value__val">{license.original.expires}</dd>
                </dl>
              ))}
            {canRenewLicense && <Button onClick={this.handleOnRenewLicense}>Renew license</Button>}
          </div>
          <div className="col gr-equal">
            <h2 className="heading-section">Upload new license</h2>
            <Form onSubmit={this.onUpload}>
              <SesamTextField
                label="License key"
                multiline
                rows="15"
                id="license"
                ref={this.license}
                inputProps={{
                  placeholder: 'Paste license key here',
                  style: { resize: 'none', fontFamily: 'Barlow, sans-serif' },
                }}
                value={this.state.license}
                onChange={this.handleLicenseChanged}
                margin="normal"
              />
              <FormActions>
                <Button type="submit">Upload</Button>
              </FormActions>
            </Form>
          </div>
        </div>
      </main>
    );
  }
}

SettingsSubscriptionLicense.propTypes = {
  connected: PropTypes.bool.isRequired,
  onUploadFail: PropTypes.func.isRequired,
  refreshLicense: PropTypes.func.isRequired,
  renewLicense: PropTypes.func.isRequired,
  sub: PropTypes.object.isRequired,
  subId: PropTypes.string.isRequired,
  uploadLicense: PropTypes.func.isRequired,
  registerRefresh: PropTypes.func.isRequired,
  unregisterRefresh: PropTypes.func.isRequired,
  license: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    connected: state.subscription.connected,
    license: state.subscription.license,
    sub: state.subscriptions.find((s) => s.id === state.subscription.id),
    subId: state.subscription.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onUploadFail: (error) => dispatch(apologise(`Failed to upload license (${error})`)),
    refreshLicense: () => dispatch(DatahubActions.getLicense()),
    renewLicense: (id) => dispatch(SubActions.renewLicense(id)),
    uploadLicense: (license) => dispatch(DatahubActions.uploadLicense(license)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsSubscriptionLicense);
