import React from 'react';
import PropTypes from 'prop-types';

import './ThirdPartyLogin.css';
import Button from 'Common/Button/Button';
import { makeStyles, withTheme } from '@material-ui/core';

function capitalize(str) {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return str;
  }
}

function ThirdPartyLogin({ providers, location }) {
  if (providers.length < 1) return null;
  return (
    <div className="third-party-login">
      {providers.map((providerInfo) => {
        return (
          <ThemedProviderButton
            key={providerInfo.id + 'login'}
            providerInfo={providerInfo}
            location={location}
          />
        );
      })}
    </div>
  );
}

ThirdPartyLogin.propTypes = {
  providers: PropTypes.array,
  location: PropTypes.shape({}).isRequired,
};

ThirdPartyLogin.defaultProps = {
  providers: [],
};

// Keys in this object correspond to Button API in Material UI
const useStyles = makeStyles((theme) => ({
  label: {
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
  },
}));

function ProviderButton({ providerInfo, location }) {
  const providerId = capitalize(providerInfo.id);
  const buttonText = providerInfo.login_button_text || providerId;
  const tooltip = providerInfo.login_button_tooltip || providerId;
  const classes = useStyles();
  let url;
  if (location?.query?.redirect) {
    url = providerInfo.login_url + '?redirect=' + location.query.redirect;
  } else {
    url = providerInfo.login_url;
  }
  return (
    <form action={url} method="post" className="third-party-login__form">
      <Button
        type="submit"
        title={tooltip}
        size="large"
        fullWidth={true}
        variant="outlined"
        classes={classes}
        icon={
          providerInfo.login_button_icon && (
            <img
              src={providerInfo.login_button_icon}
              width="20px"
              height="20px"
              className="third-party-login__img"
              alt={tooltip}
            />
          )
        }
      >
        <span className="third-party-login__title">{buttonText}</span>
      </Button>
    </form>
  );
}
const ThemedProviderButton = withTheme(ProviderButton);

ProviderButton.propTypes = {
  providerInfo: PropTypes.shape({
    id: PropTypes.string,
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    login_button_text: PropTypes.string,
    login_url: PropTypes.string,
    login_button_tooltip: PropTypes.string,
    login_button_icon: PropTypes.string,
  }),
  location: PropTypes.shape({
    query: PropTypes.shape({
      redirect: PropTypes.string,
    }).isRequired,
  }),
};

export default ThirdPartyLogin;
