import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import SubActions, {
  findDefaultUrl,
  getCurrentSub,
  valuesFromOperations,
} from 'Redux/thunks/subscriptions';
import Button from 'Common/Button/Button';
import SesamModal from 'Common/SesamModal/SesamModal';
import CollapsePanel from 'Common/CollapsePanel/CollapsePanel';
import { Form, FormActions } from 'Common/forms';
import { LinkButton } from 'Common/LinkButton/LinkButton';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

import Feedback from '../../../components/feedback';
import CodeViewer from '../../../components/code-viewer';
import KeyValue from '../../../components/key-value';
import NetworkAcl from '../../../components/network-acl';
import ConnectHelp from '../../components/connect-help';
import { PersistConnectionStringURL } from './PersistConnectionStringURL.view';
import { canConnect } from 'Internals/connectionString.utils';
import { CustomConnectionsThunk } from 'Redux/thunks/customConnectionURL.thunks';
import { TestID } from '../../../testID';
import { CustomURLConnectionStatus } from 'Types/types';

const operations = ['modify_subscription'];

// TODO doesn't work with trailing slash api/ -> api//license
// TODO can't set default if no connections exists...
// TODO just forcing build
class SettingsSubscriptionNetwork extends React.Component {
  constructor(props) {
    super(props);

    this.handleOpenFailedModal = (ev) => {
      ev.preventDefault();
      this.setState({ showFailedModal: true });
    };

    this.connectDefault = () => {
      this.setState({ pleaseWait: true, couldNotConnectDefault: false });
      this.props
        .attemptConnect(this.props.defaultUrl)
        .then(() => this.setState({ pleaseWait: false }))
        .catch(() => this.setState({ pleaseWait: false, couldNotConnectDefault: true }));
    };

    this.connect = (e) => {
      e?.preventDefault();

      const notValid = !this.state.url;
      this.setState({ notValid });

      if (!notValid) {
        this.setState({
          couldNotConnect: false,
          couldNotConnectDefault: false,
          pleaseWait: true,
          stored: null,
        });
        this.props
          .attemptConnect(this.state.persistedURL ?? this.state.url)
          .then(() => {
            this.setState({
              pleaseWait: false,
              couldNotConnectDefault: false,
              customURLConnectionStatus: CustomURLConnectionStatus.Connected,
            });

            if (this.state.persistedURL && this.state.shouldPersistURL) {
              this.props.setCustomConnectionURL(this.state.subscriptionId, this.state.persistedURL);
            }
          })
          .catch((e) => {
            this.setState({
              pleaseWait: false,
              couldNotConnect: true,
              customURLConnectionStatus: CustomURLConnectionStatus.Failed,
            });

            this.props.restoreDefaultConnectionString(
              this.state.subscriptionId,
              this.props.defaultConnectionUrl
            );
          });
      }
    };

    this.storeUrl = (e) => {
      e.preventDefault();

      let confirmed = true;
      if (!this.props.isItestMode) {
        confirmed = confirm(`Are you sure you want to set "${this.state.url}" as default?`);
      }

      if (confirmed) {
        const url = this.state.url;
        this.setState({ stored: null, storing: true, couldNotConnect: false });

        this.props
          .storeUrl(this.props.subId, url)
          .then(() => this.setState({ stored: url, storing: false }))
          .catch(() => this.setState({ storing: false }));
      }
    };

    this.canConnect = () =>
      canConnect({
        pleaseWait: this.state?.pleaseWait,
        previousURL: this.props.url,
        nextURL: this.state?.url,
        persistedURL: this.state.persistedURL,
      });

    this.canSetDefault = () => {
      return !this.state.storing && this.state.url && this.props.defaultUrl !== this.state.url;
    };

    this.handleFieldChange = (ev) => {
      this.setState({
        [ev.target.id]: ev.target.value,
        persistedURL: ev.target.value,
      });
    };

    this.persistedURLCheckbox = (event) => {
      const isChecked = event.target.checked;

      if (!isChecked) {
        this.props.removeCustomConnectionURL(this.state.subscriptionId);
        this.setState({
          persistedURL: undefined,
          shouldPersistURL: false,
          customURLConnectionStatus: CustomURLConnectionStatus.NotConnected,
        });
      } else {
        this.setState({ shouldPersistURL: true });
      }
    };

    this.attemptConnectPersistedURL = () => {
      this.setState({
        couldNotConnect: false,
        couldNotConnectDefault: false,
        pleaseWait: true,
        stored: null,
      });

      this.props
        .attemptConnect(this.props.persistedURL)
        .then(() => {
          this.setState({
            pleaseWait: false,
            couldNotConnectDefault: false,
            customURLConnectionStatus: CustomURLConnectionStatus.Connected,
            shouldPersistURL: true,
            persistedURL: this.props.persistedURL,
          });

          if (this.state.shouldPersistURL) {
            this.props.setCustomConnectionURL(this.state.subscriptionId, this.props.persistedURL);
          }
        })
        .catch((e) => {
          this.setState({
            pleaseWait: false,
            couldNotConnect: true,
            customURLConnectionStatus: CustomURLConnectionStatus.Failed,
          });

          this.props.restoreDefaultConnectionString(
            this.state.subscriptionId,
            this.props.defaultConnectionUrl
          );
        });
    };

    this.state = {
      subscriptionId: this.props.subId,
      couldNotConnect: false,
      couldNotConnectDefault: false,
      url: this.props.url,
      shouldPersistURL: false,
      persistedURL: undefined,
      customURLConnectionStatus: CustomURLConnectionStatus.NotConnected,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.setState({ url: this.props.url });
    }
  }

  componentDidMount() {
    const asQueryParam = this.props?.location?.query?.connection_url;

    if (!this.props.persistedURL && !asQueryParam) {
      this.setState({ shouldPersistURL: false, persistedURL: undefined });
    } else {
      if (asQueryParam) {
        this.setState({
          shouldPersistURL: true,
          persistedURL: asQueryParam,
        });
      } else {
        this.attemptConnectPersistedURL();
      }
    }
  }

  render() {
    const publicIp = get(this.props.subscription, 'provisioning_result.datahub.public_ip');
    const privateIp = get(this.props.subscription, 'provisioning_result.datahub.private-ip');
    const details = {
      'Default URL': this.props.defaultUrl ? this.props.defaultUrl : 'Not set',
    };

    if (this.props.connected) {
      details.Status = (
        <div>
          Connected to {this.props.url}{' '}
          <LinkButton
            data-selenium="network-connection-disconnect"
            data-testid={TestID.DisconnectNetworkLinkButton}
            onClick={this.props.disconnect}
          >
            Disconnect
          </LinkButton>
        </div>
      );
    } else {
      details.Status = (
        <div>
          {this.state.couldNotConnectDefault ? 'Connection failed. ' : 'Disconnected. '}
          {this.state.couldNotConnectDefault && (
            <LinkButton onClick={this.handleOpenFailedModal}>Details</LinkButton>
          )}{' '}
          <LinkButton
            disabled={this.state.pleaseWait}
            onClick={this.connectDefault}
            data-testid={
              this.state.pleaseWait
                ? TestID.ConnectingNetworkLinkButton
                : TestID.ConnectNetworkLinkButton
            }
          >
            {this.state.pleaseWait ? 'Connecting' : 'Connect'}
          </LinkButton>
        </div>
      );
    }

    if (publicIp) {
      details['Public IP'] = publicIp;
    }

    if (privateIp) {
      details['Private IP'] = privateIp;
    }

    return (
      <main className="scrollArea">
        <div className="row">
          <div className="col gr-equal">
            <h2 className="heading-section">Connection</h2>
            <KeyValue list={details} />
            <Form onSubmit={this.connect} standout>
              <SesamTextField
                margin="normal"
                InputProps={{
                  inputProps: {
                    'data-selenium': 'network-connection-url',
                  },
                }}
                id="url"
                onChange={this.handleFieldChange}
                type="url"
                value={this.state.persistedURL || this.state.url}
                label="Override default URL"
                helperText={
                  this.state.notValid ? (
                    'URL is required'
                  ) : (
                    <span>
                      Full URL, starting with <code>https://</code> and ending in <code>/api</code>
                    </span>
                  )
                }
                inputTestid={TestID.CustomConnectionStringInput}
              />
              <FormActions>
                <PersistConnectionStringURL
                  connectionString={this.state.url}
                  canConnect={this.canConnect()}
                  shouldPersistURL={this.state.shouldPersistURL}
                  persistedURLHandler={this.persistedURLCheckbox}
                />
                {this.props.subscription.service === 'on-premise' && (
                  <Button
                    data-selenium="network-connection-set-default"
                    disabled={!this.canSetDefault()}
                    onClick={this.storeUrl}
                    type="button"
                  >
                    {this.state.storing ? 'Storing' : 'Set as default…'}
                  </Button>
                )}
                <Button
                  data-selenium="network-connection-connect"
                  disabled={!this.canConnect()}
                  type="submit"
                  data-testid={TestID.ConnectButton}
                >
                  {this.state.pleaseWait ? 'Connecting' : 'Connect'}
                </Button>
              </FormActions>
              {this.state.stored && (
                <Feedback>Will connect via {this.state.stored} from now on.</Feedback>
              )}
              {this.state.couldNotConnect && (
                <Feedback type="error" data-testid={TestID.ConnectionFailedMessage}>
                  Connection failed.{' '}
                  <LinkButton
                    onClick={this.handleOpenFailedModal}
                    data-testid={TestID.ConnectionFailedMessageDetailsLinkButton}
                  >
                    Details
                  </LinkButton>
                </Feedback>
              )}
            </Form>

            <CollapsePanel title="Connection log">
              <CodeViewer code={JSON.stringify(this.props.connectLog, null, 2)} />
            </CollapsePanel>
          </div>

          {this.props.subscription.provisioner_version !== 'snowflake' &&
            this.props.can_modify_subscription.enabled && (
              <div className="col gr-equal">
                <NetworkAcl />
              </div>
            )}
        </div>

        <SesamModal
          className="simple-dialog"
          isOpen={this.state.showFailedModal}
          onRequestClose={() => this.setState({ showFailedModal: false })}
          contentLabel="Connection failed"
          testid={TestID.ConnectionFailedModal}
        >
          <ConnectHelp
            failed={this.props.lastConnectFailed}
            lastUrl={this.props.lastConnectUrl}
            subId={this.props.subId}
          />
        </SesamModal>
      </main>
    );
  }
}

SettingsSubscriptionNetwork.propTypes = {
  attemptConnect: PropTypes.func.isRequired,
  connected: PropTypes.bool.isRequired,
  connectLog: PropTypes.array.isRequired,
  defaultUrl: PropTypes.string,
  disconnect: PropTypes.func.isRequired,
  setCustomConnectionURL: PropTypes.func.isRequired,
  removeCustomConnectionURL: PropTypes.func.isRequired,
  isItestMode: PropTypes.bool.isRequired,
  lastConnectFailed: PropTypes.string,
  lastConnectUrl: PropTypes.string,
  storeUrl: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
  subscription: PropTypes.object.isRequired,
  url: PropTypes.string,
  can_modify_subscription: PropTypes.shape({
    enabled: PropTypes.bool,
  }),
};

function mapStateToProps(state) {
  const subscription = getCurrentSub(state);

  const enhancedSubscription = Object.assign(valuesFromOperations(subscription, operations), {
    connected: state.subscription.connected,
    connectLog: state.subscription.connectLog,
    defaultUrl: findDefaultUrl(subscription),
    isItestMode: state.globals.status.is_itest_mode,
    lastConnectFailed: state.subscription.lastConnectFailed,
    lastConnectUrl: state.subscription.lastConnectUrl,
    subId: state.subscription.id,
    subscription,
    url: state.subscription.url,
    persistedURL: state.customConnectionURLs[state.subscription.id],
    defaultConnectionUrl: state.subscription.defaultConnectionUrl,
  });

  return enhancedSubscription;
}

function mapDispatchToProps(dispatch) {
  return {
    attemptConnect: (url) => dispatch(SubActions.attemptConnect(url)),
    storeUrl: (id, url) => dispatch(SubActions.storeUrl(id, url)),
    disconnect: () => dispatch(SubActions.disconnect()),
    setCustomConnectionURL: (subscriptionId, persistedURL) =>
      dispatch(CustomConnectionsThunk.setCustomConnection(subscriptionId, persistedURL)),
    removeCustomConnectionURL: (subscriptionId) =>
      dispatch(CustomConnectionsThunk.removeCustomConnection(subscriptionId)),
    restoreDefaultConnectionString: (subscriptionId, defaultConnection) =>
      dispatch(SubActions.attemptConnectV2(subscriptionId, defaultConnection)).then(() => {
        dispatch(CustomConnectionsThunk.removeCustomConnection(subscriptionId));
      }),
  };
}

const connected = connect(mapStateToProps, mapDispatchToProps)(SettingsSubscriptionNetwork);

export { connected as SettingsSubscriptionNetwork };
