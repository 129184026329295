import { handlePortalError } from '../utils';
import PipeNotificationRulesAPI from '../../api/pipe-notification-rules';
import {
  PipeNotificationRule,
  NewPipeNotificationRule,
  UpdatedPipeNotificationRule,
  PipeNotificationRuleTypeInfo,
} from '../../types/notifications';
import PipeActions from './pipes';
import { createAction } from '@reduxjs/toolkit';
import { PipeID } from '../../types/types';
import { AppThunk } from '../..';
import { apologise } from './apology';

export const ruleAdded = createAction<{
  pipeId: PipeID;
  rule: PipeNotificationRule;
  subId: string;
}>('notificationRules/add');

export const ruleRemoved = createAction<{
  pipeId: PipeID;
  ruleId: string;
}>('notificationRules/delete');

export const ruleUpdated = createAction<{
  pipeId: PipeID;
  rule: PipeNotificationRule;
}>('notificationRules/update');

export const rulesLoaded = createAction<{
  pipeId: PipeID;
  rules: PipeNotificationRule[];
}>('notificationRules/loaded');

export const allRulesLoaded = createAction<{
  rules: PipeNotificationRule[];
}>('notificationRules/allLoaded');

const forceUpdateNotifications = () => {
  return PipeActions.runPumpOperation('system:download-pipe-settings', 'start', {});
};

export default {
  add(subId: string, pipeId: PipeID, newRule: NewPipeNotificationRule): AppThunk {
    return function (dispatch) {
      return PipeNotificationRulesAPI.add(subId, pipeId, newRule)
        .then((rule) => dispatch(ruleAdded({ pipeId, rule, subId })))
        .then(() => dispatch(forceUpdateNotifications()))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  delete(subId: string, pipeId: PipeID, ruleId: string): AppThunk {
    return function (dispatch) {
      return PipeNotificationRulesAPI.remove(subId, pipeId, ruleId)
        .then(() => dispatch(ruleRemoved({ pipeId, ruleId })))
        .then(() => dispatch(forceUpdateNotifications()))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  edit(
    subId: string,
    pipeId: PipeID,
    ruleId: string,
    updatedRule: UpdatedPipeNotificationRule
  ): AppThunk {
    return function (dispatch) {
      return PipeNotificationRulesAPI.update(subId, pipeId, ruleId, updatedRule)
        .then((rule) => dispatch(ruleUpdated({ pipeId, rule })))
        .then(() => dispatch(forceUpdateNotifications()))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  load(subId: string, pipeId: string): AppThunk {
    return function (dispatch) {
      return PipeNotificationRulesAPI.get(subId, pipeId)
        .then((rules) => dispatch(rulesLoaded({ pipeId, rules })))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  loadAll(subId: string): AppThunk {
    return function (dispatch) {
      return PipeNotificationRulesAPI.getAll(subId)
        .then((rules) => dispatch(allRulesLoaded({ rules })))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  // another case of not a thunk, just an API call with an error handler
  loadAvailableNotificationRuleTypes(subId: string, pipeId: string): AppThunk {
    return function (dispatch, getState) {
      return PipeNotificationRulesAPI.getAvailableNotificationRuleTypes(
        getState().globals.portalUrl,
        subId,
        pipeId
      ).catch((error) => dispatch(handlePortalError(error)));
    };
  },
};
