import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import JsonEditor from 'Common/JsonEditor/JsonEditor';
import EnvVarThunks from 'Redux/thunks/vars';
import Button from 'Common/Button/Button';
import ActionBar from '../../../components/action-bar';

import './style.css';

class EnvVariables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      env: this.props.envVariables,
      key: 1,
      preventSave: false,
      shortMessage: '',
    };

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.onEnvChanged = this.onEnvChanged.bind(this);
    this.onSaveEnv = this.onSaveEnv.bind(this);
    this.onShortcutSave = this.onShortcutSave.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.envVariables, prevProps.envVariables)) {
      // just swap out now, could alert the user if dirty
      this.setState({
        env: this.props.envVariables,
        key: this.state.key + 1,
      });
    }
  }

  onEnvChanged(env) {
    this.setState({
      env,
      preventSave: false,
    });
  }

  onSaveEnv() {
    this.props.save(this.state.env);
    this.setMessage('Saved');
  }

  onShortcutSave() {
    if (this.state.preventSave) {
      this.setMessage('Cannot save. Are there any errors?');
    } else {
      this.onSaveEnv();
    }
  }

  setMessage(msg) {
    this.setState({
      shortMessage: msg,
    });
  }

  render() {
    return (
      <div className="env-variables form form--standout">
        <JsonEditor
          key={this.state.key}
          onChange={this.onEnvChanged}
          onValidateJson={(valid) => this.setState({ preventSave: !valid })}
          onSave={this.onShortcutSave}
          rawJson={this.state.env}
          simpleFormat
        />
        <ActionBar shortMessage={this.state.shortMessage}>
          <Button disabled={this.state.preventSave} onClick={this.onSaveEnv}>
            Save
          </Button>
        </ActionBar>
      </div>
    );
  }
}

EnvVariables.propTypes = {
  envVariables: PropTypes.shape({}),
  save: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  envVariables: state.env,
});

const mapDispatchToProps = (dispatch) => ({
  save: (env) => dispatch(EnvVarThunks.envSaveAll(env)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnvVariables);
