import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';

import Button from 'Common/Button/Button';
import ExternalLink from 'Common/Links/ExternalLink';
import { LoadingPanel } from 'Common/LoadingPanel';
import KeyValue from '../key-value';
import EditorPanelDivider from '../editor/EditorPanelDivider';

class SystemHealth extends React.Component {
  constructor(props) {
    super(props);

    const oauth2 = get(props, 'oauth2');
    let expires, lastRefresh, status;
    if (oauth2) {
      if (oauth2['expires-ts']) {
        expires = oauth2['expires-ts'];
        if (startsWith(expires, '~f')) expires = expires.slice(2);
        expires = new Date(expires * 1000);
      }
      if (oauth2['last-refresh-ts']) {
        lastRefresh = new Date(oauth2['last-refresh-ts'] / 1000);
      }
      if (oauth2['status']) {
        status = oauth2['status'];
      }
    }

    this.state = {
      configErrors: [],
      expires,
      lastRefresh,
      status,
    };

    this.getDisplayState = () => {
      const result = this.props.result;
      const displayState = {};
      const systemType = get(this.props.system, 'type');

      if (result.status) {
        if (result.status === 'not-created')
          displayState.Status =
            '"Not-created" - No instances of this microservice are running. This could mean there\'s a problem with startup or that the eager_load/eager_load_microservices options are disabled and there are no pipes connected to it.';
        else displayState.Status = result.status;

        if (result.paused) {
          if (result.status === 'not-created')
            displayState.Status =
              '"Not-created" (paused) - No instances of this microservice are running. This could mean there\'s a problem with startup or that the eager_load/eager_load_microservices options are disabled and there are no pipes connected to it.';
          else displayState.Status = `${displayState.Status} (paused)`;
        }

        if (result.dead) {
          displayState.Status = `${displayState.Status} (dead)`;
        }
      }

      // placing the message exactly below the status KeyValue
      if (result.message) {
        displayState.Message = result.message;
      }

      if (result.status) {
        if (typeof result.status === 'string' && result.status.toLowerCase() === 'running') {
          displayState['Started at'] = (
            <time title={result.started_at} dateTime={result.started_at}>
              {moment(result.started_at).format('DD.MM.YYYY HH:mm:ss')}
            </time>
          );
        } else {
          displayState['Finished at'] = (
            <time title={result.finished_at} dateTime={result.finished_at}>
              {moment(result.finished_at).format('DD.MM.YYYY HH:mm:ss')}
            </time>
          );
        }

        if (result.out_of_memory_killed) {
          displayState['Killed due to'] = 'Out of memory';
        }
      }

      if (result.image) {
        displayState['Image hash'] = result.image;
      }

      if (result.build_number) {
        displayState['Build number'] = result.build_number;
      }

      if (result.repo_slug && result.commit) {
        displayState['Github commit'] = (
          <ExternalLink href={`https://github.com/${result.repo_slug}/commit/${result.commit}`}>
            {result.commit}
          </ExternalLink>
        );
      }

      if ('ping' in result && result.ping != null) {
        const state = result.ping ? 'success' : 'FAILURE';
        if (this.state.configErrors.length && systemType === 'system:microservice')
          displayState[
            'Connectivity Error'
          ] = `The microservice has not been deployed because of configuration error(s).`;
        else {
          if (result.port && result.host)
            displayState['Connectivity'] = `TCP port ${result.port} on '${result.host}' (${state})`;
          else displayState['Connectivity Error'] = 'No TCP port or host is available.';
        }
      }

      if (this.state.status) {
        displayState['Oauth2 status'] = this.state.status;
      }
      if (this.state.expires) {
        displayState['Access token expires'] = moment(this.state.expires).from(new Date());
      }
      if (this.state.lastRefresh) {
        displayState['Last refresh'] = moment(this.state.lastRefresh).from(new Date());
      }

      return displayState;
    };
  }

  componentDidMount() {
    const systemConfig = get(this.props.system, 'config.original');
    this.props.validateConfig(systemConfig).then((validationResult) => {
      const errorsAndWarnings = get(validationResult, 'config-errors');
      const configErrors = errorsAndWarnings.filter((res) => res.level === 'error');
      this.setState({ configErrors });
    });
  }

  render() {
    if (!this.props.result) {
      return <LoadingPanel size="medium" />;
    }
    return (
      <div className={this.props.className || ''}>
        <EditorPanelDivider>
          <EditorPanelDivider.Title title="Status">System health</EditorPanelDivider.Title>
          <EditorPanelDivider.Tools>
            <div className="toolbar toolbar--right">
              <Button onClick={this.props.onFetchStatus}>Check health</Button>
            </div>
          </EditorPanelDivider.Tools>
        </EditorPanelDivider>
        <div className="editor__panel-content">
          <KeyValue list={this.getDisplayState()} />
        </div>
      </div>
    );
  }
}

SystemHealth.propTypes = {
  className: PropTypes.string,
  result: PropTypes.object,
  system: PropTypes.object,
  onFetchStatus: PropTypes.func,
  validateConfig: PropTypes.func,
};

export default SystemHealth;
