import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import EntityAPI from '../../api/entities';
import PipeActions from 'Redux/thunks/pipes';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import Page from '../../components/page';
import PageHeader, { PageHeaderTitle } from '../../components/page-header';
import TabbedNav from '../../components/tabbed-nav';

import {
  isInputEndpointPipe,
  getSourceSystemId,
  isOutputEndpointPipe,
  getSinkSystemId,
  getSinkDatasetId,
} from 'Internals/pipes';
import { makeUrlParamsFromPath } from 'Internals/graph';
const HOP_IDENTIFIER = '---';

function getFlow(path, pipes, systems) {
  if (!path) return null;
  if (isEmpty(pipes)) return [];
  let isHop = false;
  if (!Array.isArray(path)) path = [path];
  const flow = path
    .map((id) => {
      if (id === HOP_IDENTIFIER) {
        isHop = true;
        return HOP_IDENTIFIER;
      } else {
        const pipe = pipes[id];
        if (!pipe) {
          return undefined;
        }
        const result = { data: pipe, isHop };
        isHop = false;
        return result;
      }
    })
    .filter((c) => c !== HOP_IDENTIFIER);

  if (isInputEndpointPipe(flow[0].data)) {
    const system = systems[getSourceSystemId(flow[0].data)];
    if (system) flow.unshift({ data: system });
  }

  if (isOutputEndpointPipe(flow[flow.length - 1].data)) {
    const system = systems[getSinkSystemId(flow[flow.length - 1].data)];
    if (system) flow.push({ data: system });
  }
  if (flow.includes(undefined)) {
    return null;
  } else {
    return flow;
  }
}

const searchEntity = (subUrl, token, datasetId, entityId) => {
  return EntityAPI.lookupEntity(
    {
      subUrl,
      token,
      dataset: datasetId,
    },
    entityId
  );
};

const getLatestEntity = (subUrl, token, datasetId) => {
  const params = {
    deleted: false,
    history: false,
    limit: 1,
    reverse: true,
  };
  const conf = {
    subUrl,
    token,
    dataset: datasetId,
  };
  return EntityAPI.fetchEntities(conf, params);
};

/**
 * Takes the path from the url and turns it into a Flow(an array of pipes),
 * then passes it down to its children.
 */
const FlowPage = ({ children, pipes, systems, subUrl, subId, location, token, previewPipe }) => {
  let errorMsg;
  const [flow, setFlow] = useState([]);
  const [entities, setEntities] = useState([]);
  const pathFromUrl = get(location, 'query.path');
  useEffect(() => {
    let flow = getFlow(pathFromUrl, pipes, systems);
    setFlow(flow);
  }, [pipes, systems, pathFromUrl]);

  useEffect(() => {
    const getFlowEntities = async (objectList, subUrl, token) => {
      let entities = [];
      let firstPipeEncountered = false;
      for (const obj of objectList) {
        // if object is system, just push empty entity
        if (get(obj, 'config.effective.type') !== 'pipe') {
          entities = entities.concat(null);
          continue;
        }
        // object is pipe
        const pipe = obj;
        const sinkDatasetId = getSinkDatasetId(pipe);
        let lastEntity = entities[entities.length - 1];
        if (sinkDatasetId) {
          if (!firstPipeEncountered) {
            // this should only run once
            // for the first pipe we find
            const firstEntity = (await getLatestEntity(subUrl, token, sinkDatasetId))[0];
            entities = entities.concat(firstEntity);
            firstPipeEncountered = true;
            continue;
          }
          let currentResult;
          try {
            // stop flow if no entity
            if (!lastEntity || isEmpty(lastEntity)) break;
            const previewResponse = await previewPipe(pipe.config.original, lastEntity);
            currentResult = get(previewResponse, 'transformed[0][0]');
            if (!currentResult) currentResult = get(previewResponse, 'sink[0]');
          } catch (e) {
            const searchResult = await searchEntity(subUrl, token, sinkDatasetId, lastEntity._id);
            currentResult = searchResult[0];
          }
          entities = entities.concat(currentResult);
        } else {
          let previewResponse;
          try {
            previewResponse = await previewPipe(pipe.config.original, lastEntity);
            let entity = previewResponse.sink[0];
            if (!entity) entity = previewResponse.source;

            entities = entities.concat(entity);
          } catch (e) {
            console.log(e);
          }
        }
      }
      setEntities(entities);
    };
    const objectList = flow.map((c) => c.data);
    getFlowEntities(objectList, subUrl, token);
  }, [flow, subUrl, token, previewPipe]);

  if (!flow) {
    errorMsg =
      "Error: Either you have not supplied a path in the url, or one of the id's does not belong to a pipe";
  }

  const nav = [
    {
      label: 'Dashboard',
      to: `/subscription/${subId}/flows/flow/dashboard?${
        pathFromUrl ? makeUrlParamsFromPath(pathFromUrl) : ''
      }`,
    },
    {
      label: 'Config',
      to: `/subscription/${subId}/flows/flow/config?${
        pathFromUrl ? makeUrlParamsFromPath(pathFromUrl) : ''
      }`,
    },
    {
      label: 'Input',
      to: `/subscription/${subId}/flows/flow/input?${
        pathFromUrl ? makeUrlParamsFromPath(pathFromUrl) : ''
      }`,
      tooltip: 'The input of the first pipe of the flow',
    },
    {
      label: 'Output',
      to: `/subscription/${subId}/flows/flow/output?${
        pathFromUrl ? makeUrlParamsFromPath(pathFromUrl) : ''
      }`,
      tooltip: 'The output of the last pipe of the flow',
    },
  ];

  // add props to all children
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { flow, subId, entities })
  );

  return (
    <ErrorBoundary>
      <Page dataSelenium="flow-page" className="page--flow">
        <PageHeader>
          <PageHeaderTitle>
            <SesamLink to={`/subscription/${subId}/flows`}>Flows</SesamLink>
          </PageHeaderTitle>
          {flow && flow.length > 0 && (
            <PageHeaderTitle>{`${get(flow[0], 'data._id')} -> ${get(
              flow[flow.length - 1],
              'data._id'
            )}`}</PageHeaderTitle>
          )}
        </PageHeader>
        <TabbedNav nav={nav}>{flow ? childrenWithProps : errorMsg}</TabbedNav>
      </Page>
    </ErrorBoundary>
  );
};

FlowPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({
    query: PropTypes.shape({
      path: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
  }),
  pipes: PropTypes.shape({}).isRequired,
  systems: PropTypes.shape({}).isRequired,
  subId: PropTypes.string,
  subUrl: PropTypes.string,
  token: PropTypes.string,
  previewPipe: PropTypes.func,
};

const mapStateToProps = (state) => ({
  pipes: state.pipes,
  token: state.subscription.token,
  systems: state.systems,
  subUrl: state.subscription.url,
  subId: state.subscription.id,
});

const mapDispatchToProps = (dispatch) => ({
  previewPipe: (pipe, entity) => dispatch(PipeActions.previewPipe(pipe, entity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlowPage);
