import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { confirmBefore } from '../../Confirmation';

import sfetch from 'Internals/sfetch';

const zipMimeTypes = [
  'application/zip',
  'application/x-zip',
  'application/octet-stream',
  'application/x-zip-compressed',
];

/**
 * A higher order component that passes down functionality for uploading files to an endpoint.
 */
const withUpload = () => (WrappedComponent) => {
  class WithUpload extends React.Component {
    constructor(props) {
      super(props);

      this.handleDrop = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();

        let fileType;

        const file = ev.dataTransfer.files[0];

        this.setState({ uploadSuccess: false, errors: '' });

        if (!file) {
          this.setState({ errors: 'Could not detect a file' });
        }

        // Figure out what type of file we are dealing with
        if (file.type === 'application/json' || file.name.match(/\.json$/i)) {
          fileType = 'json';
        } else if (zipMimeTypes.includes(file.type) || file.name.match(/\.zip$/i)) {
          fileType = 'zip';
        }

        // Check if type is allowed
        if (!this.props.allowed.includes(fileType)) {
          this.setState({ errors: 'This file type is not allowed' });
        }

        if (fileType === 'json') {
          // Json can be edited on the spot, if allowed
          if (this.props.editor) {
            const reader = new FileReader();

            this.setState({
              file: null,
            });

            // Read the contents of the file and show in editor
            reader.onload = () => {
              try {
                this.setState({
                  json: JSON.parse(reader.result),
                  editorKey: this.state.editorKey + 1,
                  fileType: 'json',
                  contentType: 'application/json',
                });
              } catch (e) {
                this.setState({ errors: `Cannot load file: ${e.message}` });
              }
            };
            reader.readAsText(file);
          } else {
            this.setState({
              file,
              fileType: 'json',
              contentType: 'application/json',
            });
          }
        } else if (fileType === 'zip') {
          // Other files are just kept in state until uploaded
          this.setState({
            file,
            fileType: 'zip',
            contentType: 'application/zip',
          });
        } else {
          this.setState({
            file,
          });
        }
      };

      this.handleJsonChange = (json) => this.setState({ json });

      this.handleValidateJson = (valid) => this.setState({ errors: valid ? '' : 'Parse errors' });

      this.handleUpload = (ev) => {
        ev.preventDefault();
        if (!isEmpty(this.props.confirm)) {
          confirmBefore(this.props.confirm, this.upload);
        } else {
          this.upload();
        }
      };

      this.upload = () => {
        this.setState({
          uploading: true,
        });

        const url = this.props.url;
        const requestOptions = {
          body: this.state.file ? this.state.file : JSON.stringify(this.state.json),
          credentials: 'include',
          headers: {
            'Content-Type': this.state.contentType,
            Authorization: `bearer ${this.props.token}`,
          },
          method: this.props.method,
        };

        sfetch(url, requestOptions)
          .then(() => {
            this.setState({
              errors: '',
              uploading: false,
              uploadSuccess: true,
            });

            this.props.onUploadComplete();
          })
          .catch((e) => {
            const { responseBody } = e;
            let errors;

            if (get(responseBody, 'validation_errors')) {
              errors = 'Validation errors';
            } else if (get(responseBody, 'detail')) {
              errors = responseBody.detail;
            } else if (get(responseBody, 'existing_entity')) {
              errors = `Entity (${get(responseBody, 'existing_entity._id')}) already exist`;
            } else {
              errors = 'Unknown error';
            }

            this.setState({
              errors,
              uploading: false,
              uploadSuccess: false,
            });
          });
      };

      this.handleReset = () => {
        this.setState({
          file: null,
          fileType: null,
          contentType: null,
          editorKey: this.state.editorKey + 1,
          errors: '',
          json: [],
          uploading: false,
          uploadSuccess: false,
          isDraggedOver: false,
        });
      };

      this.state = {
        file: null,
        fileType: null,
        contentType: null,
        json: [],
        editorKey: 0,
        errors: '',
        uploading: false,
        uploadSuccess: false,
      };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      // If fileType & contentType is not set AND the editor is turned on, then set them to json values
      const nextState = {};
      if (
        !prevState.file &&
        !prevState.fileType &&
        !prevState.contentType &&
        nextProps.editor &&
        nextProps.allowed.includes('json')
      ) {
        nextState.fileType = 'json';
        nextState.contentType = 'application/json';
      }
      return nextState;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          allowed={this.props.allowed}
          contentType={this.state.contentType}
          editorKey={this.state.editorKey}
          editor={this.props.editor}
          errors={this.state.errors}
          file={this.state.file}
          fileType={this.state.fileType}
          force={this.props.force}
          json={this.state.json}
          token={this.props.token}
          url={this.props.url}
          uploading={this.state.uploading}
          uploadSuccess={this.state.uploadSuccess}
          onDrop={this.handleDrop}
          onJsonChange={this.handleJsonChange}
          onValidateJson={this.handleValidateJson}
          onReset={this.handleReset}
          onUpload={this.handleUpload}
          onUploadComplete={this.props.onUploadComplete}
        />
      );
    }
  }

  WithUpload.displayName = `WithUpload(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  WithUpload.propTypes = {
    allowed: PropTypes.arrayOf(PropTypes.string),
    comfirm: PropTypes.string,
    force: PropTypes.bool,
    url: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    onUploadcomplete: PropTypes.string,
  };

  WithUpload.defaultProps = {
    confirm: '',
    force: false,
    method: 'POST',
    onUploadComplete: () => {},
  };

  return WithUpload;
};

export default withUpload;
