import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyle = makeStyles((theme) => ({
  linkButton: {
    background: 'transparent',
    border: 0,
    color: theme.palette.text.brand,
    fontSize: '1rem',
    textDecoration: 'underline',
    outline: 0,
    '&:hover, &:active': {
      color: theme.palette.text.brandActive,
    },
  },
}));

export const LinkButton = ({ children, className, ...restProps }) => {
  const classes = useStyle();
  let fullClassName = classes.linkButton;

  if (className) {
    fullClassName = fullClassName + ' ' + className;
  }

  return (
    <button className={fullClassName} {...restProps}>
      {children}
    </button>
  );
};

LinkButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};
