import sfetch, { sfetchJson } from 'Internals/sfetch';
import {
  NewPipeNotificationRule,
  PipeNotificationRule,
  NotificationRule,
  PipeNotificationRuleTypeInfo,
  UpdatedPipeNotificationRule,
} from '../types/notifications';

async function getAll(subId: string): Promise<NotificationRule[]> {
  const url = `/api/subscriptions/${subId}/notification-rules`;
  const options: RequestInit = { credentials: 'include' };
  return await sfetchJson(url, options);
}

async function get(subId: string, pipeId: string): Promise<PipeNotificationRule[]> {
  const url = `/api/subscriptions/${subId}/pipes/${pipeId}/notification-rules`;
  const options: RequestInit = { credentials: 'include' };
  return await sfetchJson(url, options);
}

async function add(
  subId: string,
  pipeId: string,
  newRule: NewPipeNotificationRule
): Promise<PipeNotificationRule> {
  const url = `/api/subscriptions/${subId}/pipes/${pipeId}/notification-rules`;
  const options: RequestInit = {
    body: JSON.stringify(newRule),
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  };
  return await sfetchJson(url, options);
}

async function remove(subId: string, pipeId: string, ruleId: string) {
  const url = `/api/subscriptions/${subId}/pipes/${pipeId}/notification-rules/${ruleId}`;
  const options: RequestInit = {
    credentials: 'include',
    method: 'DELETE',
  };
  return await sfetch(url, options);
}

async function update(
  subId: string,
  pipeId: string,
  ruleId: string,
  updatedRule: UpdatedPipeNotificationRule
): Promise<PipeNotificationRule> {
  const url = `/api/subscriptions/${subId}/pipes/${pipeId}/notification-rules/${ruleId}`;
  const options: RequestInit = {
    body: JSON.stringify(updatedRule),
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
  };
  return await sfetchJson(url, options);
}

async function getAvailableNotificationRuleTypes(
  portalUrl: string,
  subId: string,
  pipeId: string
): Promise<PipeNotificationRuleTypeInfo> {
  const url = `${portalUrl}/subscriptions/${subId}/pipes/${pipeId}/available-notification-ruletypes`;
  const options: RequestInit = { credentials: 'include' };
  return await sfetchJson(url, options);
}

export default {
  getAll,
  get,
  add,
  remove,
  update,
  getAvailableNotificationRuleTypes,
};
