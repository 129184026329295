import React, { useEffect, useCallback, useState } from 'react';
import Typography from 'Common/SesamTypography';
import { portalUrlSelector, subIdSelector } from 'Redux/selectors';
import { RootState } from '../../..';
import { useDispatch, useSelector } from 'react-redux';

import EditPipeNotificationRuleForm from '../../../components/pipe-notifications/EditPipeNotificationRuleForm';
import NotificationRulesAPI from '../../../api/pipe-notification-rules';
import PipeNotificationActions from 'Redux/thunks/pipe-notifications';
import PortalFallbackMessage from 'Common/PortalFallbackMessage/PortalFallbackMessage';
import SubThunks from 'Redux/thunks/subscriptions';
import useResourceInject from '../../../hooks/useResourceInject';
import { LoadingPanel } from 'Common/LoadingPanel';

type SettingsSubscriptionNotificationsProps = {};

function SettingsSubscriptionNotifications(props: SettingsSubscriptionNotificationsProps) {
  const [rulesLoaded, setRulesLoaded] = useState<boolean>(false);

  const subId = useSelector(subIdSelector);
  const portalUrl = useSelector(portalUrlSelector);
  const isFallbackMode = useSelector((state: RootState) => state.globals.status.is_fallback_mode);
  const notificationRules = useSelector((state: RootState) => state.pipeNotifications);

  const dispatch = useDispatch();
  const loadRules = (subId: string) => dispatch(PipeNotificationActions.loadAll(subId));

  function loadMembers() {
    dispatch(SubThunks.loadMembers(subId));
  }

  const refresh = useCallback(() => {
    loadRules(subId).then(() => {
      setRulesLoaded(true);
    });
    loadMembers();
  }, [loadRules, loadMembers]);

  useEffect(() => {
    loadRules(subId).then(() => {
      setRulesLoaded(true);
    });
  }, [subId]);

  useEffect(() => {
    // TODO: make this into hook
    if (subId) {
      loadMembers();
    }
  }, [subId]);

  useEffect(() => {
    props.registerRefresh(refresh);
    return () => props.unregisterRefresh(refresh);
  });

  const getAvailableRuleTypes = useCallback(() => {
    return NotificationRulesAPI.getAvailableNotificationRuleTypes(portalUrl, subId, 'foobar');
  }, [portalUrl, subId]);
  const [availableRuleTypes, loadingRuleTypes, errorLoadingRuleTypes] = useResourceInject(
    getAvailableRuleTypes,
    true,
    []
  );

  if (isFallbackMode) {
    return <PortalFallbackMessage style={{ marginBottom: '0.5rem' }} />;
  }

  return (
    <main className="scrollArea pipe-notifications-page">
      <div className="row">
        <div className="col gr-primary">
          <h2 className="heading-section">Notification rules</h2>
          {!rulesLoaded && <LoadingPanel size="small" />}
          {Object.keys(notificationRules).length === 0 && rulesLoaded && (
            <p>There are no notification rules on any pipes in this subscription.</p>
          )}
          {Object.keys(notificationRules).map((pipeId) => {
            return (
              <div style={{ marginTop: '30px' }}>
                <Typography variant="subtitle2" gutterBottom>
                  {pipeId}
                </Typography>
                {notificationRules[pipeId].map((rule) => {
                  return (
                    <div key={rule.id}>
                      <EditPipeNotificationRuleForm rule={rule} pipeId={pipeId} subId={subId} />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
}

export default SettingsSubscriptionNotifications;
