import { sfetchJson } from 'Internals/sfetch';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PipeTypesAPI, { EntityTypeKind } from '../api/pipe-types';
import { RootState } from '..';

const usePipeEntityTypes = (pipeId: string, stage: EntityTypeKind) => {
  const [entityTypes, setEntityTypes] = useState(undefined);
  const token = useSelector((state: RootState) => state.subscription.token);
  const url = useSelector((state: RootState) => state.subscription.url);

  useEffect(() => {
    if (pipeId) {
      PipeTypesAPI.getEntityType({ subUrl: url, token }, pipeId, stage)
        .then((et) => setEntityTypes(et))
        .catch(() => setEntityTypes(undefined));
    }
  }, [pipeId, token, url]);

  return entityTypes;
};

usePipeEntityTypes.defaultProps = {
  stage: 'sink',
};

export default usePipeEntityTypes;
