import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { sfetchJson } from 'Internals/sfetch';

export default function useResource(
  initialUrl = '',
  initialOptions = {},
  initialLoad = false,
  initialData = null
) {
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(initialLoad);
  const [options, setOptions] = useState(initialOptions);
  const [url, setUrl] = useState(initialUrl);

  const execute = (url: string, options: RequestInit) => {
    if (url) {
      setUrl(url);
    }
    if (options && !isEmpty(options)) {
      setOptions(options);
    }
    setLoading(true);
  };

  useEffect(() => {
    let isCanceled = false;
    if (loading) {
      sfetchJson(url, options)
        .then((data) => {
          if (!isCanceled) {
          }
          setData(data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading, url]);
  return [data, loading, error, execute];
}
