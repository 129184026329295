import React from 'react';
import PropTypes from 'prop-types';
import SesamLink from 'Common/Links/SesamLink';

import Layout from '../layout/full-sidebar';
import Page from '../components/page';
import PageHeader, { PageHeaderTitle } from '../components/page-header';
import TabbedNav from '../components/tabbed-nav';

function User(props) {
  return (
    <Layout>
      <Page>
        <PageHeader>
          <PageHeaderTitle>
            <SesamLink to={'/'}>Dashboard</SesamLink>
          </PageHeaderTitle>
          <PageHeaderTitle>User</PageHeaderTitle>
        </PageHeader>
        <TabbedNav
          nav={[
            {
              label: 'Profile',
              to: '/user/profile',
            },
            {
              label: 'Payment methods',
              to: '/user/payment-methods',
            },
          ]}
        >
          {props.children}
        </TabbedNav>
      </Page>
    </Layout>
  );
}
User.propTypes = {
  children: PropTypes.node,
};

export default User;
