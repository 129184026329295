import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';

import produce from 'immer';

import Button from '../Button/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  stepper: {
    padding: 0,
    flex: '1 1 auto',
    '& .MuiStep-root': {
      padding: 0,
    },
    '& .MuiStepConnector-root': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  actions: {
    marginLeft: '20px',
    '& button:nth-child(1)': {
      marginRight: '10px',
    },
    minWidth: '183px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  step: {
    display: 'flex',
  },
  finishButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover:enabled': {
      backgroundColor: theme.palette.success.light,
    },
  },
}));

const SesamWizard = (props) => {
  const [step, setStep] = useState(0);
  const [state, setState] = useState(props.initialValues);

  const validate = () => {
    let err = {};

    const validateWithSchema = () => {
      try {
        props.validationSchema.validateSync(state, {
          abortEarly: false,
        });
      } catch (e) {
        err = e.inner.reduce((acc, curr) => {
          acc[curr.params.path] = curr.message;
          return acc;
        }, {});
        return err;
      }
      return {};
    };

    if (!isEmpty(props.validationSchema)) {
      err = validateWithSchema();
    }

    return err;
  };

  // This needs to be a callback in order for it not to always update its
  // childrens useEffects
  const handleStateUpdate = useCallback((path, value) => {
    setState((currentState) =>
      produce(currentState, (draft) => {
        set(draft, path, value);
      })
    );
  }, []);

  const classes = useStyle();

  const CurrentStep = props.steps[step].render;

  const errors = validate();

  return (
    <Dialog open={props.open} maxWidth="xl" onClose={props.onClose}>
      <DialogTitle className={classes.dialogTitle}>{props.title}</DialogTitle>
      <DialogContent dividers>
        <div className={classes.container}>
          <div className={classes.step}>
            <CurrentStep bag={props.bag} state={state} setValue={handleStateUpdate} />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Stepper className={classes.stepper} activeStep={step}>
          {props.steps.map((step) => (
            <Step key={step.label}>
              <StepLabel error={step.isError && step.isError(errors)}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.actions}>
          <Button onClick={() => setStep((s) => s - 1)} disabled={step === 0}>
            Previous
          </Button>
          {step < props.steps.length - 1 && (
            <Button
              onClick={() => setStep((s) => s + 1)}
              disabled={step === props.steps.length - 1}
            >
              Next
            </Button>
          )}
          {step === props.steps.length - 1 && (
            <Button
              className={classes.finishButton}
              disabled={!isEmpty(errors)}
              onClick={() => props.onFinish(state)}
            >
              Finish
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

SesamWizard.propTypes = {
  bag: PropTypes.shape({}),
  initialValues: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      render: PropTypes.node.isRequired,
      isError: PropTypes.func,
    })
  ).isRequried,
  title: PropTypes.string.isRequired,
  validationSchema: PropTypes.shape({}),
};

export default SesamWizard;
