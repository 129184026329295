import React from 'react';
import PropTypes from 'prop-types';

import { PermissionsEditor } from 'Common/PermissionsEditor';

import '../../../components/dataset-inspector/DatasetInspectorStyle.css';

function DatasetPermissions(props) {
  const datasetId = props.params.datasetID;

  return (
    <div className="scrollArea">
      <PermissionsEditor permissionPath={['datasets', datasetId]} />
    </div>
  );
}

DatasetPermissions.propTypes = {
  params: PropTypes.shape({
    datasetID: PropTypes.string.isRequired,
  }),
};

export default DatasetPermissions;
