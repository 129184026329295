import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import './style.css';

function FocusPanel({ children, theme }) {
  return (
    <main className="focus-panel" style={{ backgroundColor: theme.palette.background.light }}>
      {children}
    </main>
  );
}

FocusPanel.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      background: PropTypes.shape({ light: PropTypes.string }),
    }),
  }),
};

export default withTheme(FocusPanel);
