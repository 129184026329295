import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import { push } from 'react-router-redux';

import Actions from 'Redux/thunks/subscriptions';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import Page from '../../components/page';
import PageHeader, { PageHeaderTitle } from '../../components/page-header';
import Layout from '../../layout/full-sidebar';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

class CreateTrial extends React.Component {
  constructor(props) {
    super(props);

    this.onCreate = (ev) => {
      ev.preventDefault();
      const notValid = !this.state.name;
      this.setState({
        notValid,
      });
      if (!notValid) {
        this.setState({
          pleaseWait: true,
        });
        this.props
          .createSubscription(this.state)
          .then((id) => {
            this.props.redirectToSubId(id);
          })
          .catch(() => {
            this.setState({
              pleaseWait: false,
            });
          });
      }
    };

    this.handleFieldChange = (ev) => {
      this.setState({ [ev.target.id]: ev.target.value });
    };

    this.state = {
      name: 'Private trial',
      description: 'A free 1-month trial subscription',
      url: '',
      service: 'in-cloud',
      type: 'trial',
    };
  }

  render() {
    return (
      <Layout>
        <Page>
          <PageHeader>
            <PageHeaderTitle>
              <SesamLink to={'/'}>Dashboard</SesamLink>
            </PageHeaderTitle>
            <PageHeaderTitle>Start private trial</PageHeaderTitle>
          </PageHeader>
          <main className="scrollArea">
            <div className="row">
              <div className="col gr-primary">
                <h2 className="heading-section">Setup</h2>
                <Form onSubmit={this.onCreate}>
                  <SesamTextField
                    label="Name"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleFieldChange}
                  />
                  <FormActions>
                    <Button
                      data-selenium="create-subscription"
                      disabled={this.state.pleaseWait}
                      type="submit"
                    >
                      {this.state.pleaseWait ? 'Creating…' : 'Create'}
                    </Button>
                  </FormActions>
                  {this.state.notValid && (
                    <div className="error-panel">Subscription must have a name</div>
                  )}
                  {this.props.createSubFailed && (
                    <div className="error-panel">Subscription limit reached</div>
                  )}
                </Form>
              </div>
              <div className="col gr-secondary">
                <h2 className="heading-section">Price</h2>
                <p>
                  This is a free 1 month trial. You can upgrade it to an ongoing paid subscription
                  at any time.
                </p>
              </div>
            </div>
          </main>
        </Page>
      </Layout>
    );
  }
}

CreateTrial.propTypes = {
  createSubFailed: PropTypes.bool,
  createSubscription: PropTypes.func.isRequired,
  redirectToSubId: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    createSubFailed: state.user.createSubFailed,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    createSubscription: (data) => dispatch(Actions.createSubscription(data)),
    redirectToSubId: (subId) => dispatch(push(`/subscription/${subId}`)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTrial);
