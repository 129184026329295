import React from 'react';
import PropTypes from 'prop-types';

import dropRight from 'lodash/dropRight';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import last from 'lodash/last';
import uniqueId from 'lodash/uniqueId';

import Button from 'Common/Button/Button';
import JsonPanel from 'Common/JsonPanel';
import MockInput from 'Common/MockInput/MockInput';

import SesamTextField from 'Common/SesamTextField/SesamTextField';

import JsonCompare from 'Common/JsonCompare';
import { LoadingPanel } from 'Common/LoadingPanel';

import './style.css';
import EditorPanelDivider from '../editor/EditorPanelDivider';

class DTLDebuggerPanel extends React.Component {
  constructor() {
    super();

    this.state = {
      steps: [],
    };

    this.buildPath = (steps) =>
      steps.reduce((acc, currentStep, index) => {
        if (index !== 0) {
          acc.push('trace');
        }
        if (currentStep.type === 'arr') {
          acc.push(currentStep.step);
        } else if (currentStep.type === 'obj') {
          acc.push(currentStep.name);
        }
        return acc;
      }, []);

    this.getType = (trace) => {
      if (isArray(trace)) {
        return 'arr';
      } else if (isObject(trace)) {
        return 'obj';
      }
    };

    this.alterSteps = (steps) => {
      this.setState(
        {
          steps,
        },
        () => {
          const path = this.buildPath(this.state.steps);
          const currentTrace = get(this.props.trace, path);
          this.props.onChangeStep(currentTrace.expr);
        }
      );
    };

    this.goNext = () => {
      const steps = this.state.steps.slice();
      const lastStep = last(steps);
      lastStep.step = lastStep.step + 1;
      this.alterSteps(steps);
    };

    this.goPrevious = () => {
      const steps = this.state.steps.slice();
      const lastStep = last(steps);
      lastStep.step = lastStep.step - 1;
      this.alterSteps(steps);
    };

    this.stepInto = (trace, name = null) => {
      const steps = this.state.steps.slice();
      const step = {
        type: this.getType(trace),
      };

      if (step.type === 'obj') {
        step.name = trace[name].expr;
      } else if (step.type === 'arr') {
        step.step = 0;
        step.totalSteps = trace.length - 1;
      }
      steps.push(step);
      this.alterSteps(steps);
    };

    this.stepOut = () => {
      const steps = dropRight(this.state.steps);
      this.alterSteps(steps);
    };

    this.exit = () => {
      this.props.onExit();
    };
  }

  componentDidMount() {
    this.stepInto(this.props.trace);
  }

  render() {
    if (this.state.steps.length === 0) {
      return <LoadingPanel />;
    }

    const path = this.buildPath(this.state.steps);
    const lastStep = last(this.state.steps);
    const currentStepValue = get(this.props.trace, path);

    const before = get(currentStepValue, ['before_variables']);
    const after = get(currentStepValue, ['after_variables']);

    const trace = get(currentStepValue, 'trace');
    let traceList;
    const traceParams = [];

    if (!isUndefined(trace)) {
      const traceType = this.getType(trace);
      if (traceType === 'obj') {
        traceList = React.createElement(
          'ul',
          { className: 'dtl-debugger__arguments-list' },
          Object.keys(trace).map((c) => (
            <li className="dtl-debugger__arguments-list-item" key={c}>
              <span
                className="dtl-debugger__arguments-list-links "
                onClick={() => this.stepInto(trace, c)}
              >
                {c}
              </span>
            </li>
          ))
        );
      } else if (traceType === 'arr') {
        traceParams.push(trace);
      }
    }

    return (
      <div className="editor__panel editor__panel--extra editor__panel--extra-large">
        <h3 className="head">DTL debugger{this.props.id && ' (' + this.props.id + ')'}</h3>
        <div className="body">
          <div className="dtl-debugger">
            <div className="dtl-debugger--top">
              <div className="dtl-debugger__info">
                <SesamTextField
                  margin="none"
                  inputProps={{ readOnly: true }}
                  className="dtl-debugger__expression"
                  value={get(currentStepValue, 'expr', '')}
                  label="Expression"
                />
                <MockInput className="dtl-debugger__arguments" value={traceList} />
                <div className="dtl-debugger__actions">
                  <Button
                    onClick={this.goPrevious}
                    text="Previous"
                    disabled={isUndefined(lastStep.step) || lastStep.step === 0}
                  />
                  <Button
                    onClick={this.goNext}
                    text="Next"
                    disabled={lastStep.step === lastStep.totalSteps}
                  />
                  <Button
                    onClick={() => this.stepInto(trace)}
                    text="Step into"
                    disabled={isUndefined(trace) || !isUndefined(traceList)}
                  />
                  <Button
                    onClick={this.stepOut}
                    text="Step out"
                    disabled={this.state.steps.length === 1}
                  />
                  <Button onClick={this.exit} text="Exit" theme="danger" />
                </div>
              </div>
            </div>
            <EditorPanelDivider />
            <div className="dtl-debugger--bottom">
              <div className="dtl-debugger__compare">
                {!isUndefined(before) && !isUndefined(after) && (
                  <JsonCompare
                    original={{ data: after, key: parseInt(uniqueId()) }}
                    value={{ data: before, key: parseInt(uniqueId()) }}
                    filter={false}
                  />
                )}
                {isUndefined(before) && isUndefined(after) && (
                  <JsonPanel rawJson={currentStepValue.result} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DTLDebuggerPanel.propTypes = {
  trace: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeStep: PropTypes.func,
  id: PropTypes.string,
};

DTLDebuggerPanel.defaultProps = {
  onChangeStep: () => {},
};

export default DTLDebuggerPanel;
