import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PipeActions from 'Redux/thunks/pipes';
import Button from 'Common/Button/Button';
import Page from '../page';
import PageHeader, { PageHeaderTitle, PageHeaderGroup } from '../page-header';
import TabbedNav from '../tabbed-nav';

const FlowsListPage = ({ children, subId, loadAll }) => (
  <Page dataSelenium="subscription-flows-pageview">
    <PageHeader>
      <PageHeaderTitle>Flows</PageHeaderTitle>
      <PageHeaderGroup>
        <Button theme="primary" onClick={loadAll}>
          Refresh
        </Button>
      </PageHeaderGroup>
    </PageHeader>
    <TabbedNav
      nav={[
        {
          label: 'List all',
          to: `/subscription/${subId}/flows/all`,
        },
      ]}
    >
      {children}
    </TabbedNav>
  </Page>
);

FlowsListPage.propTypes = {
  children: PropTypes.node,
  subId: PropTypes.string.isRequired,
  loadAll: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  subId: state.subscription.id,
});

const mapDispatchToProps = (dispatch) => ({
  loadAll: () => {
    dispatch(PipeActions.loadAll());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FlowsListPage);
