import { createReducer } from '@reduxjs/toolkit';
import { darkModeActivated, darkModeDeactivated } from '../thunks/global';

type ThemeState = {
  dark: boolean;
};

const initialState: ThemeState = { dark: false };

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
export default createReducer(initialState, (builder) =>
  builder
    .addCase(darkModeActivated, (state, action) => {
      state.dark = true;
    })
    .addCase(darkModeDeactivated, (state, action) => {
      state.dark = false;
    })
);
