import { sfetchJson } from 'Internals/sfetch';
import { SomeObject, SupportedOperation, AuditInfo } from '../types/types';
import { Json } from '../types/json';

type PaymentMethodType = 'invoice' | 'card';
type PaymentMethodOwner = {
  user_id: string;
  name: string;
  email: string;
  picture?: string;
};

export interface NewPaymentMethod {
  name?: string;
  type?: PaymentMethodType;
  description?: string;
  billing_info?: SomeObject;
  card_token?: string;
}

export interface UpdatedPaymentMethod {
  _updated?: SomeObject;
  name?: string;
  description?: string;
  billing_info?: SomeObject;
  [key: string]: any;
}

export interface PaymentMethod {
  id: string;
  type: PaymentMethodType;
  _updated: SomeObject;
  name: string;
  description: string;
  billing_info: SomeObject;
  is_verified: boolean;
  audit?: AuditInfo;
  owner: PaymentMethodOwner;
  'supported-operations': {
    modify_name_and_description: SupportedOperation & {
      'reason-code'?:
        | 'not_logged_in'
        | 'user_doesnt_own_the_paymentmethod'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
    modify_billing_info: SupportedOperation & {
      'reason-code'?:
        | 'not_logged_in'
        | 'user_doesnt_own_the_paymentmethod'
        | 'paymentmethod_is_in_use'
        | 'paymentmethod_is_credit_card'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
    delete_paymentmethod: SupportedOperation & {
      'reason-code'?:
        | 'not_logged_in'
        | 'user_doesnt_own_the_paymentmethod'
        | 'paymentmethod_is_in_use'
        | 'email_not_verified'
        | 'portal_is_in_readonly_mode';
    };
  };
  subscriptions?: {
    id: string;
    name: string;
  }[];
}

async function getAll(portalUrl: string): Promise<PaymentMethod[]> {
  const requestOptions: RequestInit = { credentials: 'include' };
  return await sfetchJson(`${portalUrl}/paymentmethods`, requestOptions);
}

async function remove(portalUrl: string, id: string): Promise<Json> {
  const requestOptions: RequestInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };
  return await sfetchJson(`${portalUrl}/paymentmethods/${id}`, requestOptions);
}

async function post(portalUrl: string, paymentMethod: NewPaymentMethod): Promise<PaymentMethod> {
  const requestOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(paymentMethod),
  };
  return await sfetchJson(`${portalUrl}/paymentmethods`, requestOptions);
}

async function put(portalUrl: string, paymentMethod: PaymentMethod): Promise<UpdatedPaymentMethod> {
  const requestOptions: RequestInit = {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(paymentMethod),
  };
  return await sfetchJson(`${portalUrl}/paymentmethods/${paymentMethod.id}`, requestOptions);
}

export default {
  getAll,
  remove,
  post,
  put,
};
