import PropTypes from 'prop-types';
import React from 'react';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

const AddSecretForm = (props) =>
  props.show ? (
    <Form onSubmit={props.onSubmit} className="add-secret-form">
      <h3 className="heading-component">Add secret</h3>
      <SesamTextField
        margin="normal"
        label="Name"
        inputProps={{ autoFocus: true }}
        id="secretsAddName"
        onChange={props.onFieldChange}
        value={props.name}
      />
      <SesamTextField
        label="Value"
        margin="normal"
        multiline
        rows="5"
        id="secretsAddValue"
        value={props.value}
        onChange={props.onFieldChange}
        autoComplete="off"
      />
      <FormActions>
        <Button
          onClick={(ev) => {
            ev.preventDefault();
            props.onHide();
          }}
          type="button"
        >
          Cancel
        </Button>
        <Button type="submit" disabled={!props.name}>
          Add secret
        </Button>
      </FormActions>
    </Form>
  ) : (
    <div className="toolbar toolbar--right">
      <Button onClick={props.onShow}>Add secret</Button>
    </div>
  );

AddSecretForm.propTypes = {
  show: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onShow: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default AddSecretForm;
