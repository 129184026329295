import Numeric from 'Common/renderers/Numeric/Numeric';
import React from 'react';

export const PipeAverageProcessTimeStatus: React.FC<{ processTime: number }> = ({
  processTime,
}) => (
  <>
    <Numeric>{processTime}</Numeric>
    {' ms'}
  </>
);
