import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import SesamTextField from '../SesamTextField/SesamTextField';

import './style.css';

function InputWithButton({ inline, textFieldProps, buttonProps }) {
  return (
    <div className={`input-with-button input-with-button${inline ? '--inline' : '--block'}`}>
      <div className="input-with-button__input">
        <SesamTextField {...Object.assign({ margin: 'none' }, textFieldProps)} />
      </div>
      <div className="input-with-button__button">
        <Button {...buttonProps} />
      </div>
    </div>
  );
}

InputWithButton.propTypes = {
  inline: PropTypes.bool,
  textFieldProps: PropTypes.shape({}).isRequired,
  buttonProps: PropTypes.shape({}).isRequired,
};

InputWithButton.defaultProps = {
  inline: true,
};

export default InputWithButton;
