import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import cidrOverlap from 'cidr-overlap';

import SesamTextField from 'Common/SesamTextField/SesamTextField';
import { Form, FormActions } from 'Common/forms';
import Button from 'Common/Button/Button';
import MultiSelector from '../multi-selector';
import './style.css';

const ipv4RegEx =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
const ipv6RegEx =
  /^(((([0-9a-fA-F]){1,4}):){7}((([0-9a-fA-F]){1,4})){1}|((([0-9a-fA-F]){1,4}):){3}((([0-9a-fA-F]){1,4})){1}(\/[0-9]{1,2}))$/;
const addressSpaceRegEx =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([89]|1[0-9]|2[0-9]|3[0-2]))$/;
let reservedAddressSpaces = ['169.254.0.0/16', '172.30.0.0/16', '172.31.0.0/16', '192.0.2.0/24'];

function isValidIP(ip) {
  if (ipv4RegEx.test(ip)) return true;
  if (ipv6RegEx.test(ip)) return true;
  return false;
}

function isValidAddressSpace(addressSpace) {
  if (addressSpaceRegEx.test(addressSpace)) return true;
  return false;
}

const useStyles = makeStyles({
  warning: {
    color: '#f00',
    fontSize: 14,
    position: 'relative',
    top: 10,
    margin: 0,
  },
});

function VpnConnection(props) {
  const vpnConnections = get(props, 'vpn.connections', []);
  const [gatewayAddress, setGatewayAddress] = useState('');
  const [addressSpaces, setAddressSpaces] = useState([]);
  const [sharedKey, setSharedKey] = useState('');
  const [showIncompleteError, setShowIncompleteError] = useState(false);
  const [conFormEdited, setConFormEdited] = useState(false);

  const styles = useStyles();

  function handleChange(e) {
    const { name, value } = e.target;

    switch (name) {
      case 'gateway_address':
        setGatewayAddress(value);
        break;
      case 'address_spaces':
        setAddressSpaces(value);
        break;
      case 'shared_key':
        setSharedKey(value);
        break;
      default:
        console.log('Error handling the changes in vpn editor...!');
        break;
    }
  }

  useEffect(() => {
    gatewayAddress !== '' || addressSpaces.length !== 0 || sharedKey !== ''
      ? setConFormEdited(true)
      : setConFormEdited(false);
  }, [gatewayAddress, addressSpaces, sharedKey]);

  function ipError(value) {
    if (!value) return false;
    return !isValidIP(value);
  }

  let addressSpacesError = false;

  let addressSpacesList = addressSpaces;
  if (props.mode !== 'new') {
    addressSpacesList = props.con.address_spaces;
  }

  let addressSpacesErrorText = '';

  addressSpacesError = addressSpacesList.some((v) => {
    if (!isValidAddressSpace(v)) {
      addressSpacesErrorText = 'Error: Invalid address space.';
      return true;
    }
    if (reservedAddressSpaces.includes(v)) {
      addressSpacesErrorText = 'Error: Reserved address space.';
      return true;
    }
    return false;
  });

  if (!addressSpacesError && cidrOverlap([...addressSpacesList]).length > 0) {
    addressSpacesError = true;
    addressSpacesErrorText = 'Error: Overlapping address spaces.';
  }

  function vpnObjectCreator(connections) {
    const vpn = { ...props.vpn };
    vpn.connections = connections.map((con) => {
      const obj = {};
      obj._id = con._id;
      if (con.gateway_address) obj.gateway_address = con.gateway_address;
      if (con.address_spaces.length !== 0) obj.address_spaces = [...con.address_spaces];
      if (con.shared_key) obj.shared_key = con.shared_key;
      if (con.asn) obj.asn = con.asn;
      if (con.bgp_peering_address) obj.bgp_peering_address = con.bgp_peering_address;
      if (con.ipsec_policy) obj.ipsec_policy = { ...con.ipsec_policy };
      return obj;
    });
    return vpn;
  }

  function addConnectionHandler() {
    if (vpnConnections.length === 10) {
      alert('Number of vpn connections cannot exceed 10!');
      return;
    }

    if (gatewayAddress === '' || addressSpaces.length === 0 || sharedKey === '') {
      setShowIncompleteError(true);
      return;
    }

    let newId = vpnConnections.reduce((acc, con) => {
      if (con._id > acc) acc = con._id;
      return acc;
    }, 0);
    newId++;

    props.parentSetDisabledEditButtons(true);
    const cons = [
      ...vpnConnections,
      {
        _id: newId,
        gateway_address: gatewayAddress,
        address_spaces: [...addressSpaces],
        shared_key: sharedKey,
      },
    ];
    props.parentSetGuiConnections(gatewayAddress, addressSpaces, sharedKey);

    setGatewayAddress('');
    setAddressSpaces([]);
    setSharedKey('');
    setConFormEdited(false);
    setShowIncompleteError(false);

    const vpn = vpnObjectCreator(cons);
    props.onChange(vpn);
  }

  return (
    <div className="material-form">
      <Form
        onSubmit={(ev) => {
          ev.preventDefault();
        }}
      >
        <SesamTextField
          margin="normal"
          name="gateway_address"
          type="text"
          InputProps={{ placeholder: 'Enter gateway address' }}
          value={props.mode == 'new' ? gatewayAddress : props.con.gateway_address}
          onChange={(e) =>
            props.mode == 'new'
              ? handleChange(e)
              : props.editFormHandleChange(e, props.index, props.con)
          }
          label="Gateway Address"
          autoComplete="off"
          error={props.mode == 'new' ? ipError(gatewayAddress) : ipError(props.con.gateway_address)}
          required={true}
        />

        <MultiSelector
          name="address_spaces"
          label="Address Spaces"
          placeholder="Enter address spaces"
          backgroundColor="white"
          onChange={(e) =>
            props.mode == 'new'
              ? handleChange(e)
              : props.editFormHandleChange(e, props.index, props.con)
          }
          items={props.mode == 'new' ? addressSpaces : props.con.address_spaces}
          disabledOptions={true}
          error={addressSpacesError}
          required={false}
        />

        <SesamTextField
          margin="normal"
          name="shared_key"
          type="text"
          InputProps={{ placeholder: 'Enter pre-shared key' }}
          value={props.mode == 'new' ? sharedKey : props.con.shared_key}
          onChange={(e) =>
            props.mode == 'new'
              ? handleChange(e)
              : props.editFormHandleChange(e, props.index, props.con)
          }
          label="Pre-shared Key"
          autoComplete="off"
          required={true}
        />

        {props.mode == 'new' ? (
          <FormActions>
            <div>
              {showIncompleteError && (
                <p className={styles.warning}>All fields marked with * should be filled!</p>
              )}
              {addressSpacesError && <p className={styles.warning}>{addressSpacesErrorText}</p>}
              {ipError(gatewayAddress) && <p className={styles.warning}>Invalid IP address</p>}
            </div>
            <Button
              id="add-vpn-connection"
              className="add-vpn-connection"
              data-selenium="add-vpn-connection-button"
              disabled={!conFormEdited || addressSpacesError || ipError(gatewayAddress)}
              color="primary"
              text={props.isSubmitting ? 'Loading...' : 'Add connection'}
              onClick={addConnectionHandler}
            />
          </FormActions>
        ) : (
          <FormActions>
            <div>
              {addressSpacesError && <p className={styles.warning}>{addressSpacesErrorText}</p>}
              {ipError(props.con.gateway_address) && (
                <p className={styles.warning}>Invalid IP address</p>
              )}
            </div>
            <Button
              name="edit-vpn-connection"
              data-selenium="edit-vpn-connection-button"
              disabled={
                (props.disabledEditButtons[props.index].includes(false) ? false : true) ||
                addressSpacesError ||
                ipError(props.con.gateway_address)
              }
              type="submit"
              color="primary"
              text={props.isSubmitting ? 'Loading...' : 'Save'}
              onClick={() => props.editConnectionHandler(props.index)}
            />

            <Button
              className="delete-vpn-connection"
              name="delete-vpn-connection"
              data-selenium="delete-vpn-connection-button"
              type="submit"
              theme="danger"
              text={props.isSubmitting ? 'Loading...' : 'Delete'}
              onClick={() => props.deleteConnectionHandler(props.index)}
            />
          </FormActions>
        )}
      </Form>
    </div>
  );
}

VpnConnection.defaultProps = {
  index: 0,
  con: null,
};

VpnConnection.propTypes = {
  vpn: PropTypes.shape({
    enable_snat: PropTypes.bool,
    sesam_gateway: PropTypes.string,
    sesam_address_spaces: PropTypes.arrayOf(PropTypes.string),
    connections: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.number,
        gateway_address: PropTypes.string,
        address_spaces: PropTypes.arrayOf(PropTypes.string),
        shared_key: PropTypes.string,
      })
    ),
    tier: PropTypes.number,
  }),
  onChange: PropTypes.func,
  parentSetGuiConnections: PropTypes.func,
  parentSetDisabledEditButtons: PropTypes.func,
  mode: PropTypes.string,
  editFormHandleChange: PropTypes.func,
  disabledEditButtons: PropTypes.arrayOf(Boolean),
  editConnectionHandler: PropTypes.func,
  deleteConnectionHandler: PropTypes.func,
  con: PropTypes.object,
  index: PropTypes.number,
};

export default VpnConnection;
