import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import throttle from 'lodash/throttle';
import isEqual from 'lodash/isEqual';

import Button from 'Common/Button/Button';
import { getCurrentSub } from 'Redux/thunks/subscriptions';
import { LoadingPanel } from 'Common/LoadingPanel';
import { sfetchText, sfetchJson } from 'Internals/sfetch';
import { getDatabrowserURL } from '../settings-databrowser/index';
import './style.css';

class SettingsDatabrowserConfigfile extends React.Component {
  constructor(props) {
    super(props);

    this.getEffectiveConfigurationUrl = (propsToUse) => {
      return this.getDatabrowserBaseUrl(propsToUse) + '/configuration?content_only=true';
    };

    this.getDatabrowserConfigFileUrl = (propsToUse) => {
      return this.getDatabrowserBaseUrl(propsToUse) + '/configuration/databrowser.ini';
    };

    this.getDatabrowserBaseUrl = (propsToUse) => {
      if (propsToUse) return getDatabrowserURL(propsToUse.sub, propsToUse.params.databrowserId);
      return getDatabrowserURL(this.props.sub, this.props.params.databrowserId);
    };

    this.downloadConfigFile = (propsToUse) => {
      const databrowserConfigFileUrl = this.getDatabrowserConfigFileUrl(propsToUse);
      if (databrowserConfigFileUrl) {
        const options = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        };

        sfetchText(databrowserConfigFileUrl, options)
          .then((data) => {
            console.log('databrowser.ini download succeeded.');
            this.setState({
              hasLoadedConfigFile: true,
              configFileContent: data,
            });
            setTimeout(this.validate, 100);
          })
          // eslint-disable-next-line no-unused-vars
          .catch((err) => {
            console.log('databrowser.ini download failed.');
            this.setState({
              configFileLoadFailed: true,
            });
          });
      }

      this.downloadEffectiveConfiguration(propsToUse);
    };

    this.downloadEffectiveConfiguration = (propsToUse) => {
      const effectiveConfigurationUrl = this.getEffectiveConfigurationUrl(propsToUse);
      if (effectiveConfigurationUrl) {
        this.setState({
          effectiveConfiguration: 'Loading...',
        });
        const options = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        };

        sfetchText(effectiveConfigurationUrl, options)
          .then((data) => {
            console.log('effectiveConfiguration download succeeded.');
            this.setState({
              effectiveConfiguration: data,
            });
          })
          // eslint-disable-next-line no-unused-vars
          .catch((err) => {
            console.log('effectiveConfiguration download failed.');
            this.setState({
              effectiveConfiguration: null,
            });
          });
      }
    };

    this.handleChange = (event) => {
      this.setState(
        {
          configFileContent: event.target.value,
          hasUnsavedChanges: true,
        },
        this.validate
      );
    };

    this.validate = () => {
      const databrowserConfigFileUrl = this.getDatabrowserConfigFileUrl();
      if (databrowserConfigFileUrl) {
        const validateUrl = `${databrowserConfigFileUrl}?validate_only=true`;

        const options = {
          method: 'PUT',
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.props.token}`,
          },
          bpdy: this.state.configFileContent,
        };
        sfetchJson(validateUrl, options)
          .then((data) => {
            console.log('databrowser.ini validate succeeded. data:', data);
            this.setState({
              configErrors: data['config-errors'],
            });
          })
          // eslint-disable-next-line no-unused-vars
          .catch((err) => {
            console.log('databrowser.ini validate failed.');
          });
      }
    };

    this.save = () => {
      const databrowserConfigFileUrl = this.getDatabrowserConfigFileUrl();
      this.setState({ saveInProgress: true });
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'text/plain',
          Authorization: `Bearer ${this.props.token}`,
        },
        body: this.state.configFileContent,
      };

      sfetchJson(databrowserConfigFileUrl, options)
        .then((data) => {
          console.log('databrowser.ini upload succeeded! data:', data);
          this.setState({
            configErrors: data['config-errors'],
            uploadError: null,
            hasUnsavedChanges: false,
          });
          this.downloadEffectiveConfiguration();
        })
        .catch((err) => {
          console.log('databrowser.ini upload failed.');
          this.setState({
            uploadError: err,
          });
        })
        .finally(() => {
          this.setState({ saveInProgress: false });
        });
    };

    this.state = {
      hasLoadedConfigFile: false,
      configFileContent: null,
      configFileLoadFailed: false,
      loadConfigFileError: null,
    };
  }

  componentDidMount() {
    this.validate = throttle(this.validate, 1000, {
      leading: true,
      trailing: true,
    });
    this.downloadConfigFile(this.props);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) this.downloadConfigFile(this.props);
  }

  render() {
    let configFileWidget;
    if (this.state.configFileLoadFailed) {
      configFileWidget = (
        <div>
          <div className="error">Failed to download the configuration file!</div>
          {this.state.loadConfigFileError && (
            <div>
              Got this error:
              {this.state.loadConfigFileError}
            </div>
          )}
        </div>
      );
    } else if (this.state.hasLoadedConfigFile) {
      configFileWidget = (
        <textarea value={this.state.configFileContent} onChange={this.handleChange} />
      );
    } else {
      configFileWidget = <LoadingPanel loadingMessage="Downloading config-file…" />;
    }

    let configErrors = null;
    if (this.state.configErrors && this.state.configErrors.length > 0) {
      const configErrorMessages = [];

      let hasJustWarnings = true;
      for (let i = 0; i < this.state.configErrors.length; i += 1) {
        const configError = this.state.configErrors[i];
        if (configError.level !== 'warning') {
          hasJustWarnings = false;
        }
        configErrorMessages.push(
          <li
            className={'databrowser-config--error databrowser-config--error-' + configError.level}
            key={i}
          >
            <div className="error-message">{configError.msg}</div>
          </li>
        );
      }

      if (hasJustWarnings) {
        configErrors = (
          <div className="databrowser-config--errors-list databrowser-config--errors-list-warning">
            <h2>Warnings</h2>
            <p>
              The configuration file triggered one or more warnings. The configuration file will
              still take effect if it is saved, though.
            </p>
            <ul>{configErrorMessages}</ul>
          </div>
        );
      } else {
        configErrors = (
          <div className="databrowser-config--errors-list databrowser-config--errors-list-error">
            <h2>Errors</h2>
            <p>
              The configuration file contains one or more errors. These errors must be fixed before
              the configuration file will take effect.
            </p>
            <ul>{configErrorMessages}</ul>
          </div>
        );
      }
    }

    return (
      <main className="scrollArea">
        <h1 className="heading-section">Configuration file</h1>
        <div className="row">
          <div className="col col--elastic gr-primary">
            {configFileWidget}
            <div className="toolbar">
              <Button
                title="Save the new Data Access Portal configuration"
                onClick={this.save}
                disabled={
                  !this.state.hasLoadedConfigFile ||
                  this.state.saveInProgress ||
                  !this.state.hasUnsavedChanges
                }
              >
                Save
              </Button>
            </div>
            {this.state.uploadError && (
              <div>
                <div>Failed to upload the configuration file:</div>
              </div>
            )}
            {configErrors && <div>{configErrors}</div>}
          </div>
          <div className="col gr-secondary">
            <p>
              This page displays the custom configurationfile of the Data Access Portal. Note that
              the configurationfile is initially empty, which means that the Data Access Portal is
              running with the default settings.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col col--elastic">
            {this.state.effectiveConfiguration && (
              <div>
                <h2 className="heading-section">Currently active configuration</h2>
                <p>
                  Below is the configuration that is currently being used by the Data Access Portal.
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.effectiveConfiguration,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </main>
    );
  }
}

SettingsDatabrowserConfigfile.propTypes = {
  isItestMode: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  sub: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

SettingsDatabrowserConfigfile.contextTypes = {
  // https://github.com/reactjs/react-router/issues/975
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isItestMode: state.globals.status.is_itest_mode,
    sub: getCurrentSub(state),
    subId: state.subscription.id,
    token: state.subscription.token,
  };
}

export default connect(mapStateToProps)(SettingsDatabrowserConfigfile);
