import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import mapValues from 'lodash/mapValues';
import isEqual from 'lodash/isEqual';

import Button from 'Common/Button/Button';
import { sfetchJson } from 'Internals/sfetch';
import { LoadingPanel } from 'Common/LoadingPanel';
import JsonPanel from 'Common/JsonPanel';
import NotAvailable from '../not-available';
import EditorPanelDivider from '../editor/EditorPanelDivider';

// const allowedDataTypes = ['csv', 'json', 'xml'];

const rawDataDefs = {
  sourceEntities: {
    id: 'sourceEntities',
    name: 'Source',
    getDataType: () => 'json',
    isEnabled: (pipe) => !!pipe.source,
    urlBuilder: (pipe) => `/pipes/${pipe._id}/entities?stage=source&limit=10`,
  },
  outgoingEntities: {
    id: 'outgoingEntities',
    name: 'Outgoing',
    getDataType: () => 'json',
    isEnabled: () => true,
    urlBuilder: (pipe) => `/pipes/${pipe._id}/entities?stage=after-transforms&limit=10`,
  },
  targetEntities: {
    id: 'targetEntities',
    name: 'Target dataset',
    getDataType: () => 'json',
    isEnabled: (pipe) => !!(pipe.sink && pipe.sink.dataset),
    urlBuilder: (pipe) => `/datasets/${pipe.sink.dataset}/entities?limit=10`,
  },
  // urlSource: {
  //   id: 'urlSource',
  //   name: 'From URL',
  //   getDataType: pipe => pipe.source.type,
  //   isEnabled: pipe => pipe.source && pipe.source.url && allowedDataTypes.includes(pipe.source.type),
  //   urlBuilder: pipe => pipe.source.url,
  // },
};

const rawDataGroups = [
  {
    label: 'Entities',
    types: [rawDataDefs.sourceEntities, rawDataDefs.outgoingEntities, rawDataDefs.targetEntities],
  },
  // {
  //   label: 'Sources',
  //   types: [
  //     rawDataDefs.urlSource,
  //   ],
  // },
];

class PipeAnalyseRawData extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeRawData = (ev) => {
      this.setState({ selectedRawData: ev.target.value }, this.refreshData);
    };

    this.getAllowedRawDataState = (pipe) => {
      return mapValues(rawDataDefs, (def) => def.isEnabled(pipe));
    };

    this.getSelectedRawDataDef = () => {
      return rawDataDefs[this.state.selectedRawData];
    };

    this.getSelectedRawDataUrl = () => {
      const url = this.getSelectedRawDataDef().urlBuilder(this.props.pipe);
      return `${this.props.subUrl}${url}`;
    };

    this.fetchData = (url, dataType) => {
      const requestOptions = {
        credentials: 'include',
        headers: { Authorization: `bearer ${this.props.token}` },
      };

      // TODO handle non-JSON data

      if (dataType !== 'json') {
        throw new Error('Non-JSON raw data handling not yet implemented');
      }

      sfetchJson(url, requestOptions)
        .then((data) => this.setState({ data, dataLoaded: true, dataType }))
        .catch((e) => this.setState({ dataError: true, errorMessage: e.message }));
    };

    this.refreshData = () => {
      this.setState({
        dataError: false,
        dataLoaded: false,
      });

      this.fetchData(
        this.getSelectedRawDataUrl(),
        this.getSelectedRawDataDef().getDataType(this.props.pipe)
      );
    };

    this.openRawDataInPopup = () => {
      window.open(this.getSelectedRawDataUrl(), 'raw-data', 'height=400,width=600');
    };

    this.state = {
      allow: this.getAllowedRawDataState(this.props.pipe),
      data: null,
      dataError: false,
      dataLoaded: false,
      dataType: null,
      errorMessage: null,
      selectedRawData: 'outgoingEntities',
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props))
      this.setState({ allow: this.getAllowedRawDataState(this.props.pipe) });
  }

  render() {
    const errorMessage = (
      <div>
        {this.state.errorMessage}
        <Button onClick={this.openRawDataInPopup}>Try in a new window</Button>
      </div>
    );

    return (
      <div className={this.props.className || ''}>
        <EditorPanelDivider title="Raw data">
          <EditorPanelDivider.Title>Raw data</EditorPanelDivider.Title>
          <EditorPanelDivider.Tools>
            <div className="toolbar">
              <select value={this.state.selectedRawData} onChange={this.onChangeRawData}>
                {rawDataGroups.map((group) => (
                  <optgroup key={group.label} label={group.label}>
                    {group.types.map((type) => (
                      <option key={type.id} value={type.id} disabled={!this.state.allow[type.id]}>
                        {type.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
              <Button
                disabled={!this.getSelectedRawDataDef().isEnabled(this.props.pipe)}
                onClick={this.refreshData}
              >
                Refresh
              </Button>
            </div>
          </EditorPanelDivider.Tools>
        </EditorPanelDivider>

        {this.state.dataError && (
          <NotAvailable title="Could not load data" message={errorMessage} />
        )}
        {!this.state.dataError && !this.state.dataLoaded && <LoadingPanel size="medium" />}
        {!this.state.dataError && this.state.dataLoaded && this.state.dataType === 'json' && (
          <JsonPanel rawJson={this.state.data} />
        )}
        {!this.state.dataError && this.state.dataLoaded && this.state.dataType !== 'json' && (
          <JsonPanel rawJson={this.state.data} type="xml" />
        )}
      </div>
    );
  }
}

PipeAnalyseRawData.propTypes = {
  className: PropTypes.string,
  pipe: PropTypes.object,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    subUrl: state.subscription.url,
    token: state.subscription.token,
  };
}

export default connect(mapStateToProps)(PipeAnalyseRawData);
