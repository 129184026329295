import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LoadingPanel } from 'Common/LoadingPanel';
import { findSub, getCurrentSub, isProvisioning } from 'Redux/thunks/subscriptions';

import { getDatabrowserURL } from '../../pages/settings-databrowser/index';

export default function (doneComponent) {
  function DatabrowserConnectedGuard(props) {
    const databrowserURL = getDatabrowserURL(props.sub, props.params.databrowserId);

    if (!databrowserURL) {
      return <LoadingPanel loadingMessage="Provisioning…" />;
    }

    // TODO: implement a databrowser connection check
    if (!props.connected) {
      return <LoadingPanel loadingMessage="Connecting to instance…" />;
    }
    const doneProps = Object.assign({}, props);
    delete doneProps.provisioning;
    delete doneProps.connected;
    delete doneProps.subId;
    delete doneProps.lastUrl;
    delete doneProps.failed;
    return React.createElement(doneComponent, doneProps);
  }

  DatabrowserConnectedGuard.propTypes = {
    provisioning: PropTypes.bool.isRequired,
    connected: PropTypes.bool.isRequired,
    failed: PropTypes.string.isRequired,
    subId: PropTypes.string.isRequired,
    lastUrl: PropTypes.string,
    sub: PropTypes.object,
    params: PropTypes.shape({
      databrowserId: PropTypes.string,
    }),
  };

  function mapStateToProps(state) {
    return {
      provisioning: isProvisioning(findSub(state.subscriptions)(state.subscription.id)),
      connected: state.subscription.connected,
      failed: state.subscription.lastConnectFailed,
      subId: state.subscription.id,
      lastUrl: state.subscription.lastConnectUrl,
      sub: getCurrentSub(state),
    };
  }

  return connect(mapStateToProps, null)(DatabrowserConnectedGuard);
}
