import React from 'react';
import PropTypes from 'prop-types';
import Box from 'Common/Box/Box';
import DatasetInspectorMessage from '../dataset-inspector-message/DatasetInspectorMessage';
import { EntityBrowserList } from './DatasetInspectorBrowserList';

import './DatasetInspectorBrowserStyle.css';

export const DatasetInspectorBrowser = (props) => (
  <div className="dataset-inspector-browser">
    <Box>
      <Box.Header>
        <DatasetInspectorMessage
          dataset={props.dataset}
          filter={props.filter}
          stopSearch={props.stopSearch}
        />
      </Box.Header>
      <Box.Content
        white
        style={{ maxHeight: props.displayRepostAll ? 'calc(100% - 90px)' : '100%' }}
      >
        <EntityBrowserList
          onScroll={props.onScroll}
          onSetRef={props.onSetRef}
          onEntityRef={props.onEntityRef}
          showingSearchResults={props.showingSearchResults}
          displayRepostAll={props.displayRepostAll}
          repostDeadEntities={props.repostDeadEntities}
          filter={props.filter}
        />
      </Box.Content>
    </Box>
  </div>
);

DatasetInspectorBrowser.propTypes = {
  dataset: PropTypes.shape({}).isRequired,
  filter: PropTypes.string.isRequired,
  onEntityRef: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  onSetRef: PropTypes.func.isRequired,
  showingSearchResults: PropTypes.bool.isRequired,
  stopSearch: PropTypes.func.isRequired,
  displayRepostAll: PropTypes.bool,
  repostDeadEntities: PropTypes.func,
};
