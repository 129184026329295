import sfetch from './sfetch';

async function fetchZendeskJwt(portalUrl: string) {
  try {
    const response = await sfetch(`${portalUrl}/jwt/zendesk`);
    const jwt = await response.text();
    return jwt;
  } catch (e) {
    console.log('Could not load zendesk JWT', e);
    return null;
  }
}

async function fetchZendeskChatJwt(portalUrl: string) {
  try {
    const response = await sfetch(`${portalUrl}/jwt/zendesk-chat`);
    const jwt = await response.text();
    return jwt;
  } catch (e) {
    console.log('Could not load zendesk JWT', e);
    return null;
  }
}

declare global {
  interface Window {
    zE: any;
    zESettings: any;
  }
}

function updatePath(url: string) {
  if (window.zE) {
    window.zE('webWidget', 'updatePath', {
      url,
    });
  }
}

// This polyfill is needed for IE11
function polyfill() {
  // Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md
  (function (arr) {
    arr.forEach(function (item) {
      if (item.hasOwnProperty('append')) {
        return;
      }
      Object.defineProperty(item, 'append', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function append() {
          var argArr = Array.prototype.slice.call(arguments),
            docFrag = document.createDocumentFragment();

          argArr.forEach(function (argItem) {
            var isNode = argItem instanceof Node;
            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
          });

          this.appendChild(docFrag);
        },
      });
    });
  })([Element.prototype, Document.prototype, DocumentFragment.prototype]);
}

function init(portalUrl: string, setZendeskAvailable: Function) {
  const existingZendeskScript = document.getElementById('ze-snippet');
  if (!existingZendeskScript) {
    window.zESettings = {
      webWidget: {
        position: {
          vertical: 'top',
          horizontal: 'right',
        },
        offset: {
          vertical: '56px',
        },
        zIndex: -1,
        contactOptions: {
          enabled: true,
          contactFormLabel: {
            '*': 'Create a ticket',
          },
        },

        authenticate: {
          jwtFn: function (jwtHandler: Function) {
            fetchZendeskJwt(portalUrl).then((baseJwt) => {
              if (baseJwt) {
                jwtHandler(baseJwt);
                setZendeskAvailable();
              }
            });
          },
          chat: {
            jwtFn: function (jwtHandler: Function) {
              fetchZendeskChatJwt(portalUrl).then((chatJwt) => {
                if (chatJwt) {
                  jwtHandler(chatJwt);
                  setZendeskAvailable();
                }
              });
            },
          },
        },
      },
    };
    const zendeskScriptTag = document.createElement('script');
    zendeskScriptTag.id = 'ze-snippet';
    zendeskScriptTag.src =
      'https://static.zdassets.com/ekr/snippet.js?key=52a3e758-c864-45a7-93b7-b805a1967cd1';
    document.body.append(zendeskScriptTag);
  }
}

function logout() {
  if (window.zE) {
    window.zE('webWidget', 'logout');
  }
}

export default {
  logout,
  init,
  updatePath,
  polyfill,
};
