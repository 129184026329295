import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamModal from '../SesamModal/SesamModal';
import KeyValue from '../../key-value';
import Button from '../Button/Button';
import { Form, FormActions } from '../forms';
import CodeStyleOption from './CodeStyleOption';
import CodeStyleOptions from './CodeStyleOptions';

const optionsStyle = {
  // need this to be on top of editor
  overlay: {
    zIndex: 3,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%',
  },
};
class EditorOptionsModal extends React.Component {
  constructor(props) {
    super(props);
    this.sortCodeStyleOptions = (key1, key2) => {
      return this.props.codeStyle[key1].label > this.props.codeStyle[key2].label ? 1 : -1;
    };
  }
  render() {
    const keyboardMappings = {
      Reformat: [
        <span key={'reformat-win'}>
          <kbd>Alt</kbd> + <kbd>.</kbd> (Windows/Linux)
        </span>,
        <span key={'reformat-mac'}>
          <kbd>Opt</kbd> + <kbd>.</kbd> (macOS)
        </span>,
      ],
    };

    if (this.props.canRun) {
      keyboardMappings.Run = [
        <span key={'run-win'}>
          <kbd>Ctrl</kbd> + <kbd>Enter</kbd>
        </span>,
      ];
    }

    if (this.props.canSave) {
      keyboardMappings.Save = (
        <span key={'save'}>
          <kbd>Ctrl</kbd> + <kbd>S</kbd>
        </span>
      );
    }

    keyboardMappings['Search'] = [
      <span key={'search-win'}>
        <kbd>Ctrl</kbd> + <kbd>F</kbd> (Windows/Linux)
      </span>,
      <span key={'search-mac'}>
        <kbd>Cmd</kbd> + <kbd>F</kbd> (macOS)
      </span>,
    ];

    keyboardMappings['Search and Replace'] = [
      <span key={'search-win'}>
        <kbd>Ctrl</kbd> + <kbd>H</kbd> (Windows/Linux)
      </span>,
      <span key={'search-mac'}>
        <kbd>Cmd</kbd> + <kbd>Opt</kbd> + <kbd>F</kbd> (macOS)
      </span>,
    ];

    keyboardMappings['Autocomplete dropdown'] = [
      <span key={'autocomplete-win'}>
        <kbd>Ctrl</kbd> + <kbd>Space</kbd> (Windows/Linux)
      </span>,
      <span key={'autocomplete-mac'}>
        <kbd>Opt</kbd> + <kbd>Space</kbd> (macOS)
      </span>,
      <span key={'autocomplete-tab'}>
        (<kbd>Tab</kbd> to complete)
      </span>,
    ];
    return (
      <SesamModal
        style={optionsStyle}
        contentLabel="Editor preferences"
        isOpen={this.props.showOptions}
        onRequestClose={this.props.onCloseOptions}
        darkModeActive={this.props.darkModeActive}
      >
        <h2 className="heading-page">Editor preferences</h2>
        <Form component="div">
          <div className="row">
            <div className="col gr-equal">
              <h3 className="heading-section">Code style options</h3>
              <CodeStyleOption
                name="autoFormat"
                onChange={this.props.onToggleAutoformat}
                checked={this.props.isAutoFormat}
                label="Autoformat on save"
                helperText="Automatically reformat code when leaving editor"
              />
              <CodeStyleOption
                name="enableCompletion"
                onChange={this.props.onToggleAutoCompletion}
                checked={this.props.isAutoCompletion}
                label="Automatic auto completion"
                helperText="Enable automatic suggestions as you type"
              />
              {this.props.codeStyle &&
                Object.keys(this.props.codeStyle)
                  .sort(this.sortCodeStyleOptions)
                  .map((key) => (
                    <CodeStyleOptions
                      key={key}
                      option={this.props.codeStyle[key]}
                      name={key}
                      onChange={this.props.onCodeStyleOptionChanged}
                    />
                  ))}
            </div>
            <div className="col gr-equal">
              <h3 className="heading-section">Keyboard shortcuts</h3>
              <KeyValue list={keyboardMappings} />
            </div>
          </div>
          <FormActions>
            <Button onClick={this.props.onCloseOptions}>Close</Button>
          </FormActions>
        </Form>
      </SesamModal>
    );
  }
}

EditorOptionsModal.propTypes = {
  showOptions: PropTypes.bool.isRequired,
  isAutoFormat: PropTypes.bool.isRequired,
  canRun: PropTypes.bool.isRequired,
  canSave: PropTypes.bool.isRequired,
  isAutoCompletion: PropTypes.bool.isRequired,
  onCloseOptions: PropTypes.func.isRequired,
  onToggleAutoformat: PropTypes.func.isRequired,
  onToggleAutoCompletion: PropTypes.func.isRequired,
  onCodeStyleOptionChanged: PropTypes.func.isRequired,
  codeStyle: PropTypes.shape(),
  darkModeActive: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  darkModeActive: state.theme.dark,
});

export default connect(mapStateToProps)(EditorOptionsModal);
