import { ApiConf, SomeObject } from '../types/types';
import { sfetchJson } from 'Internals/sfetch';
import { Json } from '../types/json';

async function addDatatype(apiConf: ApiConf, datatype: SomeObject): Promise<Json> {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/receivers/gdpr-data-type-in/entities`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'POST',
    body: JSON.stringify(datatype),
  };
  return await sfetchJson(url, options);
}

async function addPolicy(apiConf: ApiConf, policy: SomeObject): Promise<Json> {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/receivers/gdpr-policy-in/entities`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'POST',
    body: JSON.stringify(policy),
  };

  return await sfetchJson(url, options);
}

async function addPurpose(apiConf: ApiConf, purpose: SomeObject): Promise<Json> {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/receivers/gdpr-purpose-in/entities`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'POST',
    body: JSON.stringify(purpose),
  };
  return await sfetchJson(url, options);
}

async function updateDatatype(apiConf: ApiConf, datatype: SomeObject): Promise<Json> {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/receivers/gdpr-data-type-in/entities`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'POST',
    body: JSON.stringify(datatype),
  };
  return await sfetchJson(url, options);
}

async function updatePolicy(apiConf: ApiConf, policy: SomeObject): Promise<Json> {
  const { subUrl, token } = apiConf;

  const url = `${subUrl}/receivers/gdpr-policy-in/entities`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'POST',
    body: JSON.stringify(policy),
  };
  return await sfetchJson(url, options);
}
async function updatePurpose(apiConf: ApiConf, purpose: SomeObject): Promise<Json> {
  const { subUrl, token } = apiConf;

  const url = `${subUrl}/receivers/gdpr-purpose-in/entities`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'POST',
    body: JSON.stringify(purpose),
  };
  return await sfetchJson(url, options);
}

async function deleteDatatype(apiConf: ApiConf, datatype: SomeObject) {
  datatype['_deleted'] = true;
  return await updateDatatype(apiConf, datatype);
}

async function deletePolicy(apiConf: ApiConf, policy: SomeObject) {
  policy['_deleted'] = true;
  return await updatePolicy(apiConf, policy);
}

async function deletePurpose(apiConf: ApiConf, purpose: SomeObject) {
  purpose['_deleted'] = true;
  return await updatePurpose(apiConf, purpose);
}

async function getDatatypes(apiConf: ApiConf, since?: number): Promise<SomeObject[]> {
  const { subUrl, token } = apiConf;
  let url = `${subUrl}/publishers/gdpr-data-type-out/entities`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };

  if (since !== undefined && since !== null) {
    url = url + `?since=${since}`;
  }

  return await sfetchJson(url, options);
}

async function getPolicies(apiConf: ApiConf, since?: number): Promise<SomeObject[]> {
  const { subUrl, token } = apiConf;
  let url = `${subUrl}/publishers/gdpr-policy-out/entities`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };

  if (since !== undefined && since !== null) {
    url = url + `?since=${since}`;
  }
  return await sfetchJson(url, options);
}

async function getPurposes(apiConf: ApiConf, since?: number): Promise<SomeObject[]> {
  const { subUrl, token } = apiConf;
  let url = `${subUrl}/publishers/gdpr-purpose-out/entities`;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };

  if (since !== undefined && since !== null) {
    url = url + `?since=${since}`;
  }

  return await sfetchJson(url, options);
}

export default {
  addDatatype,
  addPolicy,
  addPurpose,
  deleteDatatype,
  deletePolicy,
  deletePurpose,
  getDatatypes,
  getPolicies,
  getPurposes,
  updateDatatype,
  updatePolicy,
  updatePurpose,
};
