import PropTypes from 'prop-types';
import React from 'react';
import SesamModal from 'Common/SesamModal/SesamModal';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import PromiseButton from '../../../components/promise-button';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import DangerText from 'Common/Text/DangerText';
import { connect } from 'react-redux';

class ConfirmDelete extends React.Component {
  constructor(props) {
    super(props);

    this.onRetype = (event) => {
      this.setState({
        retyped: event.target.value,
      });
    };

    this.onDelete = () => {
      return this.props.onApprove();
    };

    this.state = {
      deleting: false,
      retyped: '',
    };
  }

  render() {
    const isConfirmed =
      this.state.retyped.length >= 8 && this.props.subscription.id.startsWith(this.state.retyped);
    return (
      <SesamModal
        className="simple-dialog"
        onRequestClose={this.props.onCancel}
        isOpen
        contentLabel="Confirmation"
        darkModeActive={this.props.darkModeActive}
      >
        <h2 className="heading-page">
          <DangerText>Please confirm</DangerText>
        </h2>
        <p>
          Do you want to delete the subscription &quot;
          {this.props.subscription && this.props.subscription.name}
          &quot;?
        </p>
        <DangerText>
          This operation is irreversible and all the data and configuration stored inside the
          subscription will be lost. This includes the datahub with its pipes and datasets, backups,
          roles and other settings.
        </DangerText>
        <p>
          Please confirm by retyping the first eight characters of the subscription ID &quot;
          {this.props.subscription.id}
          &quot;
        </p>
        <Form component="div">
          <SesamTextField
            label="Retype first characters"
            name="retype"
            value={this.state.retyped}
            onChange={this.onRetype}
            type="text"
          />
        </Form>
        <FormActions>
          <Button onClick={this.props.onCancel} disabled={this.state.deleting}>
            Cancel
          </Button>
          <PromiseButton
            onClick={this.onDelete}
            pending="Deleting…"
            disabled={!isConfirmed}
            theme="danger"
          >
            Delete this subscription!
          </PromiseButton>
        </FormActions>
      </SesamModal>
    );
  }
}

ConfirmDelete.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
  darkModeActive: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  darkModeActive: state.theme.dark,
});

export default connect(mapStateToProps)(ConfirmDelete);
