import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import PropTypes from 'prop-types';
import React from 'react';

import Page from '../page';
import PageHeader, { PageHeaderTitle } from '../page-header';
import TabbedNav from '../tabbed-nav';

const ModelPage = (props) => {
  const {
    children,
    subId,
    params: { modelId },
  } = props;

  return (
    <Page dataSelenium="subscription-browse-pageview">
      <PageHeader>
        <PageHeaderTitle>
          <SesamLink to={`subscription/${subId}/browse`}>Browse</SesamLink>
        </PageHeaderTitle>
        <PageHeaderTitle>{`Model (${modelId})`}</PageHeaderTitle>
      </PageHeader>
      <TabbedNav
        nav={[
          {
            label: 'Overview',
            to: `/subscription/${subId}/browse/models/${modelId}/overview`,
          },
        ]}
      >
        {children}
      </TabbedNav>
    </Page>
  );
};

ModelPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  params: PropTypes.shape({ modelId: PropTypes.string.isRequired }).isRequired,
  subId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  subId: state.subscription.id,
});

export default connect(mapStateToProps)(ModelPage);
