import React from 'react';
import PropTypes from 'prop-types';

import Box from 'Common/Box/Box';
import SesamCheckboxField from 'Common/SesamCheckboxField/SesamCheckboxField';

function GraphToggler({ onToggle, value, label }) {
  return (
    <Box>
      <Box.Content padding>
        <SesamCheckboxField
          label={label}
          CheckboxProps={{
            value: value,
            onChange: () => onToggle(),
          }}
        />
      </Box.Content>
    </Box>
  );
}

GraphToggler.propTypes = {
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

export default GraphToggler;
