import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import {
  makePermissionCheckerForCurrentSub,
  makeRoleCheckerForCurrentSub,
} from 'Redux/thunks/subscriptions';
import DatahubActions from 'Redux/thunks/datahub';
import Page from '../../../components/page';
import PageHeader, { PageHeaderTitle } from '../../../components/page-header';
import TabbedNav from '../../../components/tabbed-nav';

class SettingsDatahub extends React.Component {
  constructor(props) {
    super(props);

    this.props.refreshDatahubInfo();
  }
  render() {
    const nav = [];

    if (this.props.connected) {
      nav.push({
        label: 'Queues',
        to: `/subscription/${this.props.subId}/settings-datahub/queues`,
      });
    }

    if (this.props.connected) {
      nav.push({
        label: 'Logs',
        to: `/subscription/${this.props.subId}/settings-datahub/logs`,
      });
    }

    if (this.props.roleChecker('group:Admin') && this.props.connected) {
      nav.push({
        label: 'Variables',
        to: `/subscription/${this.props.subId}/settings-datahub/variables`,
      });
    }

    if (this.props.permissionChecker('modify_subscription') && this.props.connected) {
      nav.push({
        label: 'Tools',
        to: `/subscription/${this.props.subId}/settings-datahub/tools`,
      });
    }

    if (this.props.roleChecker('group:Admin') && this.props.connected) {
      nav.push({
        label: 'Metadata',
        to: `/subscription/${this.props.subId}/settings-datahub/metadata`,
      });
    }

    if (
      this.props.datahubInfo.status &&
      this.props.datahubInfo.status['authentication-is-required'] &&
      this.props.permissionChecker('modify_subscription') &&
      this.props.connected
    ) {
      nav.push({
        label: 'Permissions',
        to: `/subscription/${this.props.subId}/settings-datahub/permissions`,
      });
    }

    if (this.props.roleChecker('group:Admin')) {
      nav.push({
        label: 'Stacktrace',
        to: `/subscription/${this.props.subId}/settings-datahub/stacktrace`,
      });
    }

    if (this.props.connected) {
      nav.push({
        label: 'Internal datasets',
        to: `/subscription/${this.props.subId}/settings-datahub/internal-datasets`,
      });
    }

    return (
      <Page dataSelenium="subscription-settings-pageview">
        <PageHeader>
          <PageHeaderTitle>Datahub settings</PageHeaderTitle>
        </PageHeader>
        <TabbedNav nav={nav}>{this.props.children}</TabbedNav>
      </Page>
    );
  }
}

SettingsDatahub.propTypes = {
  children: PropTypes.node,
  connected: PropTypes.bool.isRequired,
  datahubInfo: PropTypes.object.isRequired,
  permissionChecker: PropTypes.func.isRequired,
  refreshDatahubInfo: PropTypes.func.isRequired,
  roleChecker: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  connected: state.subscription.connected,
  datahubInfo: state.subscription.info,
  subId: state.subscription.id,
  permissionChecker: makePermissionCheckerForCurrentSub(state),
  roleChecker: makeRoleCheckerForCurrentSub(state),
});

const mapDispatchToProps = (dispatch) => ({
  refreshDatahubInfo: () => dispatch(DatahubActions.getInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDatahub);
