import PropTypes from 'prop-types';
import React from 'react';

import './ProgressBarStyle.css';

const ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <div className="progress-bar__progress" style={{ width: `${props.progress}%` }} />
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
