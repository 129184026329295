import {
  panelHidden,
  panelToggled,
  dirtyStateUpdated,
  reformatConfigLoaded,
  panelShown,
  Panel,
} from '../thunks/editor';
import { createReducer } from '@reduxjs/toolkit';

type EditorState = {
  reformatConfig: any;
  visiblePanels: {
    [key in Panel]: boolean;
  };
  isDirty: boolean;
};
const initialState: EditorState = {
  reformatConfig: {}, // TODO: this should have a default
  visiblePanels: {
    options: false,
    schema: false,
    templates: false,
  },
  isDirty: false,
};

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(panelToggled, (state, action) => {
      state.visiblePanels[action.payload] = !state.visiblePanels[action.payload];
    })
    .addCase(panelShown, (state, action) => {
      state.visiblePanels[action.payload] = true;
    })
    .addCase(panelHidden, (state, action) => {
      state.visiblePanels[action.payload] = false;
    })
    .addCase(reformatConfigLoaded, (state, action) => {
      state.reformatConfig = action.payload;
    })
    .addCase(dirtyStateUpdated, (state, action) => {
      state.isDirty = action.payload;
    })
);

export default reducer;
