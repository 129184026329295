import get from 'lodash/get';

/**
 * Based on given type of value provided, it returns a snippet that we want to put
 * as a placeholder for this type of value
 * @param {string|undefined} type possible types that values in AST can be
 */
export const getValueSnippet = (type) => {
  switch (type) {
    case 'string':
      return '"${1}"';
    case 'integer':
      return '${1:1}';
    case 'object':
      return '{${1}}';
    case 'array':
      return '[${1}]';
    case 'boolean':
      return '${1:true}';
    case undefined:
      return '"${1}"';
    default:
      return `"\$\{1:${type}\}"`;
  }
};

/**
 * Calculates the offset from row/col position and lines array
 * @param {Object} position object with row and column integer properties
 * @param {array} lines array of strings
 */
export const getOffset = (position, lines) => {
  let offset = 0;
  for (let i = 0; i < position.row; i++) {
    offset = offset + lines[i].length + 1;
  }
  return offset + position.column;
};

/**
 * Gets the previous and next tokens based on offset
 * @param {Array} tokens array of tokens from the tokenizer
 * @param {number} offset int offset
 */
export const getNeighbourTokens = (tokens, offset) => {
  let nextToken, previousToken, nextIdx, prevIdx, currentToken;
  for (let i = 0; i < tokens.length; i++) {
    if (
      tokens[i + 1] &&
      offset >= tokens[i].loc.start.offset &&
      offset <= tokens[i + 1].loc.start.offset
    ) {
      nextToken = tokens[i + 1];
      nextIdx = i + 1;
    }
    if (
      tokens[i - 1] &&
      offset >= tokens[i - 1].loc.end.offset &&
      offset <= tokens[i].loc.end.offset
    ) {
      previousToken = tokens[i - 1];
      prevIdx = i - 1;
    }
    if (prevIdx + 1 !== nextIdx) {
      // if we are _in_ a token
      currentToken = tokens[prevIdx + 1];
    }
  }
  return { nextToken, previousToken, nextIdx, prevIdx, currentToken };
};

export const isWithinOffset = (node, offset) => {
  return node.loc.start.offset < offset && node.loc.end.offset > offset;
};

/**
 * Traverses the AST and finds which node the cursor is located at
 * and what the path to it is
 * @param {number} offset which position the cursor is in
 * @param {object} ast object from the json-to-ast parsing of our input
 */
export const findInAst = (offset, ast) => {
  const visit = (node, offset, path) => {
    switch (node.type) {
      case 'Object': {
        const foundInChildren = node.children.reduce(
          (prev, curr) => prev || visit(curr, offset, path),
          undefined
        );
        if (!foundInChildren) {
          if (isWithinOffset(node, offset)) {
            return { node, path };
          }
        } else {
          return {
            node: foundInChildren.node,
            path: foundInChildren.path,
          };
        }
        break;
      }
      case 'Array': {
        const foundInChildren = node.children.reduce(
          (prev, curr, idx) => prev || visit(curr, offset, [...path, idx]),
          undefined
        );
        if (!foundInChildren) {
          if (isWithinOffset(node, offset)) {
            return { node, path };
          }
        } else {
          return {
            node: foundInChildren.node,
            path: foundInChildren.path,
          };
        }
        break;
      }
      case 'Property': {
        const foundInValue = visit(node.value, offset, [...path, node.key.value]);
        const foundInKey = visit(node.key, offset, path);
        if (foundInValue) {
          return {
            node: foundInValue.node,
            path: foundInValue.path,
          };
        } else if (foundInKey) {
          return {
            node: foundInKey.node,
            path: foundInKey.path,
          };
        } else {
          if (isWithinOffset(node, offset)) {
            return { node, path };
          }
        }
        break;
      }
      case 'Literal': {
        if (isWithinOffset(node, offset)) {
          return { node, path };
        }
        break;
      }
      case 'Identifier': {
        if (isWithinOffset(node, offset)) {
          return { node, path };
        }
        break;
      }
      default: {
      }
    }
  };
  return visit(ast, offset, []);
};

/**
 * Traverses the schema we received from the server with our path we
 * got from the AST parsing and retrieves which properties should be suggestedd
 * @param {Object} schema the schema object from the server
 * @param {string} path string path from the findInAst function
 */
export const findInSchema = (schema, path) => {
  const visit = (node, pathArray) => {
    if (node.type === 'object') {
      if (pathArray.length > 0) {
        const property = pathArray.shift();
        const nextNode = get(node, `properties[${property}]`);
        if (nextNode) return visit(nextNode, pathArray);
        else return [];
      } else {
        if (node.properties) return Object.entries(node.properties);
        else return [];
      }
    } else {
      if (node.properties) return Object.entries(node.properties);
      if (node.enum) return node.enum.map((e) => [e, { type: 'string' }]);
      else return [];
    }
  };

  return visit(schema, [...path]);
};
