import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import EditorActions from 'Redux/thunks/editor';
import { apologise } from 'Redux/thunks/apology';
import SystemsAPI from '../../../api/systems';
import SystemMicroserviceLogs from '../../../components/system-microservice-logs';
import SystemHealth from '../../../components/system-health';

class SystemStatus extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleTail = () => {
      const newVal = !this.state.tail;
      this.setState(
        {
          tail: newVal,
        },
        () => {
          if (this.state.tail) {
            this.fetchLogs();
          }
        }
      );
    };

    this.fetchStatus = () => {
      const apiConf = { subUrl: this.props.subUrl, token: this.props.token };
      SystemsAPI.getStatus(apiConf, this.props.system._id).then((status) =>
        this.setState({
          status,
        })
      );
    };

    this.fetchLogs = () => {
      const apiConf = { subUrl: this.props.subUrl, token: this.props.token };
      if (this.state.tail) {
        let since;
        const split = this.state.logs.split('\n');
        if (isEmpty(split)) {
          this.props.apologise('Could not find any logs');
          return;
        }

        const lastLine = split[split.length - 2];

        // Find timestamp using regex
        const regex = /([0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{9}Z)/gm;

        try {
          since = regex.exec(lastLine)[0];
        } catch (e) {
          this.props.apologise('Could not tail log.');
          this.setState({
            tail: false,
          });
          return;
        }

        SystemsAPI.getLogs(apiConf, this.props.system._id, since).then((data) => {
          const logs = data.split('\n').slice(1).join('\n');
          const newLogs = this.state.logs + logs;

          this.setState({ logs: newLogs });
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.fetchLogs(true);
          }, 3000);
        });
      } else {
        SystemsAPI.getLogs(apiConf, this.props.system._id).then((logs) => this.setState({ logs }));
      }
    };
    this.state = { tail: false, logs: null, status: null };
  }
  componentDidMount() {
    if (this.props.registerRefresh) {
      this.props.registerRefresh(this.fetchStatus);
      this.props.registerRefresh(this.fetchLogs);
    }
    this.fetchStatus();
    this.fetchLogs();
  }

  componentWillUnmount() {
    if (this.props.unregisterRefresh) {
      this.props.unregisterRefresh(this.fetchStatus);
      this.props.unregisterRefresh(this.fetchLogs);
    }
    clearTimeout(this.timeout);
  }
  render() {
    const system = this.props.system;

    if (!system) {
      return null;
    }

    return (
      <div className="stack">
        <SystemHealth
          system={system}
          className="stack__item stack__item--1x"
          result={this.state.status}
          onFetchStatus={this.fetchStatus}
          validateConfig={this.props.validateConfig}
        />
        {system.type === 'system:microservice' && (
          <SystemMicroserviceLogs
            system={system}
            className="stack__item stack__item--1x"
            logs={this.state.logs}
            tail={this.state.tail}
            onFetchLogs={this.fetchLogs}
            onTailToggle={this.handleToggleTail}
          />
        )}
      </div>
    );
  }
}

SystemStatus.propTypes = {
  apologise: PropTypes.func.isRequired,
  params: PropTypes.shape({
    systemID: PropTypes.string.isRequired,
  }),
  system: PropTypes.object,
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
  subUrl: PropTypes.string,
  token: PropTypes.string,
  validateConfig: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  system: state.systems[ownProps.params.systemID],
  subUrl: state.subscription.url,
  token: state.subscription.token,
});

const mapDispatchToProps = (dispatch) => ({
  apologise: (msg) => dispatch(apologise(msg)),
  validateConfig: (config) => dispatch(EditorActions.validateConfig(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemStatus);
