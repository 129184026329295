import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppHeader from '../../components/app-header';

const useStyle = makeStyles((theme) => {
  return {
    layoutCentered: {
      backgroundColor: theme.palette.background.semilight,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    layoutContent: {
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      color: theme.palette.text.primary,
    },
    '& .focus-panel': {
      margin: '0',
    },
  };
});

function CenteredLayout({ children, renderHeader }) {
  const classes = useStyle();

  return (
    <div className={classes.layoutCentered}>
      {renderHeader && <AppHeader />}
      <div className={classes.layoutContent}>{children}</div>
    </div>
  );
}

CenteredLayout.propTypes = {
  children: PropTypes.node.isRequired,
  renderHeader: PropTypes.bool,
};

CenteredLayout.defaultProps = {
  renderHeader: true,
};

export default CenteredLayout;
