import React from 'react';
import PropTypes from 'prop-types';

import JsonPanel from 'Common/JsonPanel';

import './style.css';

function JsonPreviewPanel(props) {
  return (
    <div className="editor__panel editor__panel--extra">
      <h3 className="head">Effective config</h3>
      <div className="body">
        <JsonPanel rawJson={props.json} />
      </div>
    </div>
  );
}

JsonPreviewPanel.propTypes = {
  json: PropTypes.shape().isRequired,
};

export default JsonPreviewPanel;
