import React from 'react';
import PropTypes from 'prop-types';
import AppHeader from '../../components/app-header';
import Headline from '../../components/headline';

function RegularLayout(props) {
  return (
    <div className="layout--regular">
      <AppHeader />
      <Headline text={props.text} color={props.color} />
      <div className="layout__content">{props.children}</div>
    </div>
  );
}

RegularLayout.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default RegularLayout;
