import { tokenTypes, parseTokens, tokenize } from 'json-to-ast';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PipeTypesAPI from '../../../../api/pipe-types';
import {
  findInAst,
  findInSchema,
  getNeighbourTokens,
  getOffset,
  getValueSnippet,
} from './parseUtils';
import { dtlCompletions } from './dtlCompletions';
import { propertiesToSchema } from 'Internals/utils';

/**
 * Checks if the cursor is within the DTL rules part of the AST
 * @param {array} path array representing path in AST
 * @returns {boolean}
 */
function isDtlRules(path) {
  if (path.length < 2) return false;
  return path[0] === 'transform' && (path[1] === 'rules' || path[2] === 'rules');
}

function isHopWhereProperty(path) {
  const L = path.length;
  if (L < 2) return false;
  return path[L - 2] === 'where' || path[L - 3] === 'where';
}

function isHopDatasetsProperty(path) {
  if (path.length < 2) return false;
  const L = path.length;
  return path[L - 2] === 'datasets';
}

function shouldAutocompleteSourceProperties(token, sourceSchema) {
  return token && token.value === '"_S."' && sourceSchema.length > 0;
}

function shouldAutocompleteTargetProperties(token, targetSchema) {
  return token && token.value === '"_T."' && targetSchema.length > 0;
}

function shouldAutocompleteDatasetProperties(token, alias) {
  return token && token.value === `"${alias}."`;
}

function getCurrentDatasetAliases(path, json) {
  const L = path.length;
  if (isHopWhereProperty(path)) {
    let datasetsPropertyPath = [...path];
    const wherePosition = datasetsPropertyPath.indexOf('where');
    datasetsPropertyPath[wherePosition] = 'datasets';
    datasetsPropertyPath = datasetsPropertyPath.slice(0, wherePosition + 1);
    return get(json, datasetsPropertyPath, []);
  }
  return [];
}

function autocompleteDatasetProperties(apiConf, id, callback) {
  PipeTypesAPI.getSinkEntityType(apiConf, id)
    .then((response) => {
      if (response && !isEmpty(response.properties)) {
        const schema = propertiesToSchema(response.properties, id);
        const datasetPropertiesCompletions = schema.map((t) => ({
          caption: t.name,
          value: t.name,
          meta: `${t.type} (${t.origin})`,
        }));
        callback(null, datasetPropertiesCompletions);
      }
    })
    .catch((err) => {
      callback(null, []);
    });
}

/**
 * Checks if we are in a node on the AST
 * where we would want to trigger the target
 * entity schema autocompletion
 * @param {array} path array representing path in AST
 * @param {object} jsoninput the json value of the editor
 * @returns {boolean}
 */
function isTargetField(path, jsoninput) {
  const names = ['add', 'rename'];
  const len = path.length;

  if (len < 2) return false;

  const fieldIndexInDtlSignature = path[len - 1];
  if (fieldIndexInDtlSignature !== 1) return false;

  const pathToFunctionName = [...path];
  pathToFunctionName[len - 1] = pathToFunctionName[len - 1] - 1;
  const functionName = get(jsoninput, pathToFunctionName);

  return names.includes(functionName);
}

/**
 * Returns an object that is then called by the ACE autocompleter engine
 * @param {array} pipeCompletions completions for pipe names
 * @param {array} datasetCompletions completions for dataset names
 * @param {array} systemCompletions completions for system names
 * @param {array} targetSchema completions for target entity schema properties
 * @param {array} sourceSchema completiosn for the source entity schema properties
 * @param {object} upstreams the upstreams object from Redux
 * @param {object} apiConf object with subUrl and token
 */
function schemaCompleter(
  pipeCompletions,
  datasetCompletions,
  systemCompletions,
  targetSchema,
  sourceSchema,
  upstreams,
  apiConf
) {
  return {
    getCompletions: function (editor, session, pos, prefix, callback) {
      // this is the stringified config
      let input = session.getValue();

      // get tokens
      let tokens;
      try {
        tokens = tokenize(input);
      } catch (err) {
        return;
      }

      // calculate the offset in the file from cursor position and lines
      const offset = getOffset(pos, session.doc.$lines);

      // get the previous and next token
      const { nextToken, previousToken, nextIdx, prevIdx, currentToken } = getNeighbourTokens(
        tokens,
        offset
      );

      if (
        previousToken &&
        previousToken.type === tokenTypes.COMMA &&
        nextToken &&
        (nextToken.type === tokenTypes.RIGHT_BRACE ||
          nextToken.type === tokenTypes.RIGHT_BRACKET) &&
        !currentToken
      ) {
        // we are in invalid JSON, trailling comma
        tokens.splice(prevIdx, 1);
        input =
          input.substring(0, previousToken.loc.start.offset) +
          input.substring(previousToken.loc.end.offset, input.length - 1);
      }

      let targetSchemaCompletions = [];
      let sourceSchemaCompletions = [];

      if (shouldAutocompleteTargetProperties(currentToken, targetSchema)) {
        targetSchemaCompletions = targetSchema.map((t) => ({
          caption: t.name,
          value: t.name,
          meta: `${t.type} (${t.origin})`,
        }));

        callback(null, targetSchemaCompletions);
        return;
      }

      if (shouldAutocompleteSourceProperties(currentToken, sourceSchema)) {
        sourceSchemaCompletions = sourceSchema.map((t) => ({
          caption: t.name,
          value: t.name,
          meta: `${t.type} (${t.origin})`,
        }));
        callback(null, sourceSchemaCompletions);
        return;
      }

      // get the AST
      let ast;
      try {
        ast = parseTokens(input, tokens);
      } catch (err) {
        return;
      }

      // find in AST and get autocompletions from schema
      let foundEntries = [];
      let found;
      if (editor.schema) {
        found = findInAst(offset, ast);
        if (found) {
          foundEntries = findInSchema(editor.schema, found.path);
        }
      }

      const preceedingComma =
        previousToken &&
        [
          tokenTypes.LEFT_BRACE,
          tokenTypes.LEFT_BRACKET,
          tokenTypes.COLON,
          tokenTypes.COMMA,
        ].includes(previousToken.type)
          ? ``
          : `,`;

      const trailingComma =
        nextToken &&
        [tokenTypes.RIGHT_BRACE, tokenTypes.RIGHT_BRACKET, tokenTypes.COMMA].includes(
          nextToken.type
        )
          ? ``
          : `,`;

      // filter already used properties
      let usedProperties = [];
      let dtlEntries = [];
      let subCompletions = [];

      if (found) {
        const path = found.path;
        const jsoninput = JSON.parse(input);
        if (path === []) usedProperties = Object.keys(jsoninput);
        else usedProperties = Object.keys(get(jsoninput, path, {}));

        if (isHopWhereProperty(path)) {
          const datasetAliases = getCurrentDatasetAliases(path, jsoninput);
          for (const nameAlias of datasetAliases) {
            const [name, alias] = nameAlias.split(' ');
            if (shouldAutocompleteDatasetProperties(currentToken, alias)) {
              const upstreamPipeId = upstreams[name];
              if (upstreamPipeId) {
                autocompleteDatasetProperties(apiConf, upstreamPipeId, callback);
              }
              return;
            }
          }
        }

        if (isDtlRules(path)) {
          if (isHopDatasetsProperty(path) && found.node.type === 'Literal') {
            const sortedDatasetCompletions = [...datasetCompletions]
              .map(function (completion) {
                return {
                  caption: completion.name,
                  value: completion.name,
                  meta: completion.type,
                };
              })
              .sort((a, b) => a.caption.localeCompare(b.caption));
            callback(null, [...sortedDatasetCompletions]);
            return;
          }
          dtlEntries = dtlCompletions.map((func) => {
            return {
              caption: func.name + ': ' + func.caption,
              value: func.name,
              snippet: `${preceedingComma}${func.snippet}${trailingComma}`,
              meta: 'DTL',
            };
          });

          // only add target schema autocompletions if
          // not already present (i.e. the prefix is T.)
          if (targetSchemaCompletions.length < 1 && isTargetField(path, jsoninput)) {
            targetSchemaCompletions = targetSchema.map((t) => ({
              caption: t.name,
              value: t.name,
              meta: `${t.type} (Class ${t.classId})`,
            }));
          }

          foundEntries = [];
        }

        if (found.node.type === 'Literal') {
          subCompletions = [...pipeCompletions, ...datasetCompletions, ...systemCompletions]
            .map(function (completion) {
              return {
                caption: completion.name,
                value: completion.name,
                meta: completion.type,
              };
            })
            .sort((a, b) => a.caption.localeCompare(b.caption));
          foundEntries = [];
        }
      }

      foundEntries = foundEntries.filter((e) => !usedProperties.includes(e[0]));
      const isIdentifier = get(found, 'node.type') === 'Identifier';

      foundEntries = foundEntries.map((entry) => {
        const snippet = isIdentifier
          ? `${entry[0]}`
          : `${preceedingComma}"${entry[0]}": ${getValueSnippet(entry[1].type)}${trailingComma}`;
        return {
          name: entry[0],
          caption: entry[1].type ? `: ${entry[1].type}` : '',
          snippet,
        };
      });
      const schemaCompletions = foundEntries.map((e) => ({
        caption: e.name + e.caption,
        value: e.name,
        snippet: e.snippet,
        meta: 'Schema',
      }));

      callback(null, [
        ...targetSchemaCompletions,
        ...sourceSchemaCompletions,
        ...schemaCompletions,
        ...subCompletions,
        ...dtlEntries,
      ]);
    },
  };
}

export default schemaCompleter;
