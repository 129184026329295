import React, { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import PipeIcon from '../images/icons/pipe.svg';
import GlobalPipeIcon from '../images/icons/global-pipe.svg';
import SystemIcon from '../images/icons/system.svg';

const xmlnsString = 'http://www.w3.org/2000/svg';
const commonStyle = { transform: 'scale(0.7)' };
const renderer = (component: ReactElement) => ReactDOMServer.renderToStaticMarkup(component);

export const pipeIcon = (color: string) =>
  `data:image/svg+xml;utf8,${renderer(
    <PipeIcon style={{ ...commonStyle, color }} xmlns={xmlnsString} />
  )}`;

export const globalPipeIcon = (color: string) =>
  `data:image/svg+xml;utf8,${renderer(
    <GlobalPipeIcon style={{ ...commonStyle, color }} xmlns={xmlnsString} />
  )}`;

export const systemIcon = (color: string) =>
  `data:image/svg+xml;utf8,${renderer(
    <SystemIcon style={{ ...commonStyle, color }} xmlns={xmlnsString} />
  )}`;

export const systemSelectedIcon = `data:image/svg+xml;utf8,${renderer(
  <SystemIcon style={{ ...commonStyle, color: 'rgb(0, 129, 193)' }} xmlns={xmlnsString} />
)}`;
