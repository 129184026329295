import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Button from 'Common/Button/Button';

import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';

import './style.css';
import { withTheme } from '@material-ui/core';

function _IndexPreviewGroup({ children, theme }) {
  return (
    <div
      className="index-preview__group"
      style={{ borderBottom: `0.0625rem solid ${theme.palette.divider}` }}
    >
      {children}
    </div>
  );
}
const IndexPreviewGroup = withTheme(_IndexPreviewGroup);

function IndexPreview(props) {
  return (
    <div className="index-preview">
      <h3 className="heading-component index-preview__title">Details</h3>
      {!isEmpty(props.data) && (
        <div>
          <IndexPreviewGroup>
            <p>Expression:</p>
            <p>{props.data.expression}</p>
          </IndexPreviewGroup>
          <IndexPreviewGroup>
            <p>Status:</p>
            <p>{props.data.status}</p>
          </IndexPreviewGroup>
          <IndexPreviewGroup>
            <p>Progress:</p>
            <p>
              {props.data.progress.indexed}/{props.data.progress.total}
            </p>
          </IndexPreviewGroup>
          <IndexPreviewGroup>
            <Button
              type="button"
              text={props.data.status === 'orphaned' ? 'Delete…' : 'Rebuild'}
              theme="danger"
              onClick={() =>
                props.onDeleteClick(
                  props.data['dataset'],
                  props.data['internal_id'],
                  props.data.status
                )
              }
            />
          </IndexPreviewGroup>
          {props.data['used-by-pipes'].length > 0 && (
            <IndexPreviewGroup>
              <p>Used in pipes:</p>
              {props.data['used-by-pipes'].map((pipe) => (
                <SesamLink to={`/subscription/${props.subId}/pipes/pipe/${pipe}`} key={pipe}>
                  <p>{pipe}</p>
                </SesamLink>
              ))}
            </IndexPreviewGroup>
          )}
          <IndexPreviewGroup>
            <p>Version:</p>
            <p>{props.data.version}</p>
          </IndexPreviewGroup>
        </div>
      )}
      {isEmpty(props.data) && <p>Click on a row to show details.</p>}
    </div>
  );
}

IndexPreview.propTypes = {
  data: PropTypes.shape({
    dataset: PropTypes.string,
    expression: PropTypes.string,
    internal_id: PropTypes.number,
    progress: PropTypes.shape({
      indexed: PropTypes.number,
      total: PropTypes.number,
    }),
    'used-by-pipes': PropTypes.array,
    status: PropTypes.string,
    version: PropTypes.number,
  }),
  onDeleteClick: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
};

IndexPreview.defaultProps = {
  data: {
    expression: '',
    progress: {
      indexed: 0,
      total: 0,
    },
    'used-by-pipes': [],
    status: '',
  },
};

function mapStateToProps(state) {
  return {
    subId: state.subscription.id,
  };
}

export default connect(mapStateToProps)(IndexPreview);
