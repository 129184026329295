import React, { useMemo } from 'react';
import findIndex from 'lodash/findIndex';
import { makeStyles } from '@material-ui/core/styles';

import SesamCheckboxField from 'Common/SesamCheckboxField/SesamCheckboxField';
import { SearchFacet as SearchFacetType } from '../../types/types';

interface SearchFacetProps {
  activeFacets: SearchFacetType[];
  facet: SearchFacetType;
  onFacetToggle: (facet: SearchFacetType) => void;
}

const useStyle = makeStyles({ root: { padding: '4px' } });

const SearchFacet: React.FC<SearchFacetProps> = ({ activeFacets, facet, onFacetToggle }) => {
  const classes = useStyle();
  const isActive = useMemo(() => {
    const idx = findIndex(activeFacets, (activeFacet) => activeFacet.name === facet.name);
    return idx !== -1;
  }, [activeFacets, facet]);

  return (
    <SesamCheckboxField
      CheckboxProps={{
        checked: isActive,
        classes: classes,
        onChange: (ev: React.ChangeEvent) => {
          onFacetToggle({
            name: facet.name,
          });
        },
      }}
      label={`${facet.name} (${facet.count})`}
    />
  );
};

export default SearchFacet;
