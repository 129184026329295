import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import Avatar from '@material-ui/core/Avatar';

const colors = [
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#009688',
  '#0097a7',
  '#43a047',
  '#0097a7',
  '#3f51b5',
  '#795548',
  '#ff5722',
];

const useStyle = makeStyles((theme) => ({
  avatar: (props) => {
    let charCodeTotal = 0;
    Array.from(props.input).forEach((char) => (charCodeTotal += char.charCodeAt(0)));
    const idx = parseInt(charCodeTotal.toString().split('').pop());

    return {
      width: props.size,
      height: props.size,
      fontSize: `${Math.round(props.size / 2)}px`,
      backgroundColor: colors[idx],
      color: 'white',
    };
  },
}));

function Identicon(props) {
  const { input } = props;
  const classes = useStyle(props);

  const regex = /\b[A-Z|a-z]{1}/g;
  const match = input.match(regex);

  let letters = '';
  if (match && match.length > 0) {
    letters = match
      .slice(0, 2)
      .map((c) => c.toUpperCase())
      .join('');
  }

  return <Avatar className={classes.avatar}>{letters}</Avatar>;
}

Identicon.propTypes = {
  input: PropTypes.string.isRequired,
  size: PropTypes.number,
};

Identicon.defaultProps = {
  size: 40,
};

export default Identicon;
