import { sfetchJson } from 'Internals/sfetch';

async function getPipeStats(portalUrl: string, subId: string, pipeId: string) {
  const requestOptions: RequestInit = { credentials: 'include' };
  return await sfetchJson(
    `${portalUrl}/monitoring/standard/${subId}/pipes/${pipeId}`,
    requestOptions
  );
}

async function getSubscriptionStats(portalUrl: string, subId: string) {
  const requestOptions: RequestInit = { credentials: 'include' };
  return await sfetchJson(`${portalUrl}/monitoring/standard/${subId}`, requestOptions);
}

async function getInvoiceData(portalUrl: string, subId: string) {
  const requestOptions: RequestInit = { credentials: 'include' };
  return await sfetchJson(`${portalUrl}/monitoring/invoicing/${subId}`, requestOptions);
}

export default {
  getPipeStats,
  getSubscriptionStats,
  getInvoiceData,
};
