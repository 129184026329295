import PaymentMethodsAPI, { PaymentMethod, NewPaymentMethod } from '../../api/payment-methods';
import { handlePortalError } from '../utils';
import { AppThunk } from '../..';
import { createAction } from '@reduxjs/toolkit';

export const paymentMethodCreated = createAction<PaymentMethod>('paymentMethods/created');
export const paymentMethodsLoaded = createAction<PaymentMethod[]>('paymentMethods/loaded');
export const paymentMethodRemoved = createAction<string>('paymentMethods/removed');
export const paymentMethodUpdated = createAction<PaymentMethod>('paymentMethods/updated');

export default {
  loadAll(): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return PaymentMethodsAPI.getAll(portalUrl)
        .then((paymentMethods) => dispatch(paymentMethodsLoaded(paymentMethods)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  remove(id: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return PaymentMethodsAPI.remove(portalUrl, id)
        .then(() => dispatch(paymentMethodRemoved(id)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  create(pm: NewPaymentMethod): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return PaymentMethodsAPI.post(portalUrl, pm)
        .then((paymentMethod) => dispatch(paymentMethodCreated(paymentMethod)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },

  update(pm: PaymentMethod): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      return PaymentMethodsAPI.put(portalUrl, pm)
        .then((response) => dispatch(paymentMethodUpdated(response)))
        .catch((error) => dispatch(handlePortalError(error)));
    };
  },
};
