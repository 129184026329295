import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import produce from 'immer';

import PipeActions from 'Redux/thunks/pipes';
import ModelActions from 'Redux/thunks/models';
import SesamLink from 'Common/Links/SesamLink';

import BrowsePage from '../browse-page/BrowsePage';
import FilterTable from '../filter-table/FilterTable';
import { SubscriptionContext } from '../../pages/Subscription.page';
import { getModelName } from '../models-list/ModelsList';
import { useAppDispatch } from '../..';

const filterLabelMapping = {
  _id: 'Name',
  modelName: 'Model',
  dataset: 'Dataset',
};

const emptyFilter = Object.freeze({
  _id: {
    selected: [],
    search: [],
  },
  modelName: {
    selected: [],
    search: [],
  },
  dataset: {
    selected: [],
    search: [],
  },
});

const defaultFilter = produce(emptyFilter, (draft) => {});

const defaultVisibleCols = ['Name', 'Model', 'Dataset'];

const defaultVisibleFacets = ['_id', 'modelName', 'dataset'];

const defaultSortBy = [
  {
    id: '_id',
    desc: false,
  },
];

const columns = [
  {
    Header: 'Name',
    id: '_id',
    accessor: (e) => ({ entityTypeId: e['_id'], modelId: e['modelId'] }),
    fixed: true,
    width: 300,
    Cell: ({
      cell: {
        value: { entityTypeId, modelId },
      },
    }) => {
      const subId = useContext(SubscriptionContext);
      return (
        <SesamLink to={`subscription/${subId}/browse/entity-types/${modelId}/${entityTypeId}`}>
          {entityTypeId}
        </SesamLink>
      );
    },
  },
  {
    Header: 'Model',
    id: 'modelName',
    accessor: 'modelName',
    Cell: ({ cell: { value } }) => {
      const subId = useContext(SubscriptionContext);
      return (
        <SesamLink to={`subscription/${subId}/browse/models?Name.selected=${value}`}>
          {value}
        </SesamLink>
      );
    },
  },
  {
    Header: 'Pipe',
    id: 'dataset',
    accessor: 'dataset',
    Cell: ({ cell: { value } }) => {
      const subId = useContext(SubscriptionContext);
      return value ? (
        <SesamLink to={`subscription/${subId}/pipes/pipe/${value}`}>{value}</SesamLink>
      ) : (
        'None'
      );
    },
  },
];

/**
 * Renders a list of data types
 * @param {object} props
 */
const EntityTypesList = (props) => {
  const { loadAllPipes, loadAllModels, pipes, models } = props;
  const [entityTypes, setEntityTypes] = useState([]);
  const refresh = () => loadAllModels();

  useEffect(() => {
    loadAllPipes();
  }, []);

  useEffect(() => {
    if (!isEmpty(pipes)) {
      loadAllModels();
    }
  }, [pipes, loadAllModels]);

  useEffect(() => {
    props.registerRefresh(refresh);
    return () => {
      props.unregisterRefresh(refresh);
    };
  });

  useEffect(() => {
    if (!isEmpty(models)) {
      setEntityTypes(
        models.reduce((acc, curr) => {
          const model = curr['_id'];
          curr['entity_types'] &&
            Object.keys(curr['entity_types']).forEach((_id) => {
              const entityTypeObj = {};
              entityTypeObj['dataset'] = _id;
              entityTypeObj['_id'] = _id;
              entityTypeObj['modelName'] = getModelName(model);
              entityTypeObj['modelId'] = model;
              acc.push(entityTypeObj);
            });
          return acc;
        }, [])
      );
    }
  }, [models]);

  return (
    <FilterTable
      defaults={{
        filter: defaultFilter,
        sortBy: defaultSortBy,
        visibleCols: defaultVisibleCols,
        visibleFacets: defaultVisibleFacets,
      }}
      emptyFilter={emptyFilter}
      filterLabelMapping={filterLabelMapping}
      columns={columns}
      itemsToFilter={entityTypes}
      tableKey="entity-types"
    />
  );
};

EntityTypesList.propTypes = {
  pipes: PropTypes.shape({}),
  models: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadAllPipes: PropTypes.func.isRequired,
  loadAllModels: PropTypes.func.isRequired,
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
};

const mapStateToProps = (state) => ({
  pipes: state.pipes,
  models: state.models,
});

const mapDispatchToProps = (dispatch) => ({
  loadAllPipes: () => dispatch(PipeActions.loadAll()),
  loadAllModels: () => dispatch(ModelActions.loadAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityTypesList);
