import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import get from 'lodash/get';
import { DatasetInspectorContainer } from 'Components/dataset-inspector/DatasetInspectorContainer';
import '../../components/pipe-output/PipeOutputStyle.css';

const PipeDeadLetters = (props) => {
  const { pipe } = props;

  const targetDatasetId = pipe?.config?.effective?.source?.dataset;

  if (!props.params.pipeID) {
    return null;
  }

  if (!get(props, 'pipe.config.effective.pump.dead_letter_dataset')) {
    props.router.push(`/subscription/${props.subId}/pipes/pipe/${props.pipe.link}/dashboard`);
    return null;
  }

  const datasetID = get(pipe, 'config.effective.pump.dead_letter_dataset');

  if (datasetID) {
    return (
      <DatasetInspectorContainer
        datasetID={datasetID}
        targetDatasetId={targetDatasetId}
        registerRefresh={props.registerRefresh}
        unregisterRefresh={props.unregisterRefresh}
        addCustomActions={props.addCustomActions}
        removeCustomActions={props.removeCustomActions}
        tab="dead-letters"
        displayRepostAll={true}
      />
    );
  }
};

PipeDeadLetters.propTypes = {
  subId: PropTypes.string.isRequired,
  params: PropTypes.shape({
    pipeID: PropTypes.string.isRequired,
  }),
  pipe: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
  addCustomActions: PropTypes.func,
  removeCustomActions: PropTypes.func,
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
};

PipeDeadLetters.defaultProps = {
  pipe: null,
  addCustomActions: () => {},
  removeCustomActions: () => {},
  registerRefresh: () => {},
  unregisterRefresh: () => {},
};

const mapStateToProps = (state, ownProps) => {
  let pipe;
  if (ownProps.pipe) {
    pipe = ownProps.pipe;
  } else if (get(ownProps, 'params.pipeID')) {
    pipe = state.pipes[ownProps.params.pipeID];
  }

  return { subId: state.subscription.id, pipe };
};

const ComposedPipeDeadLetters = compose(connect(mapStateToProps), withRouter)(PipeDeadLetters);

export { ComposedPipeDeadLetters as PipeDeadLetters };
