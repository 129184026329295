import escapeRegExp from 'lodash/escapeRegExp';
import get from 'lodash/get';

import {
  getSubName,
  findSub,
  isProvisioning,
  hasProvisioningFailed,
} from 'Redux/thunks/subscriptions';
import { intercept401 } from 'Redux/utils';
import ApologyActions from 'Redux/thunks/apology';
import { sfetchInit, getPendingRequestCount, getCompletedRequestCount } from './sfetch';
import UserAPI from '../api/user';
import PaymentActions from 'Redux/thunks/stripe';
import SubActions, { makeRoleCheckerForCurrentSub } from 'Redux/thunks/subscriptions';
import UserProfileActions from 'Redux/thunks/user-profile';
import PipeActions from 'Redux/thunks/pipes';
import DatahubActions from 'Redux/thunks/datahub';
import DatasetActions from 'Redux/thunks/datasets';
import SystemActions from 'Redux/thunks/systems';
import { lastViewedTabUpdated, NavId } from 'Redux/thunks/navigation';
import { userLoggedIn } from 'Redux/thunks/auth';

import './error';
import { Store } from '@reduxjs/toolkit';
import { SomeObject } from '../types/types';
import { RedirectFunction, EnterHook, RouterState } from 'react-router';
import isUndefined from 'lodash/isUndefined';

let store: Store;

/**
 * Shortcut function
 * @return {object} The full Redux state
 */
function getState() {
  return store.getState();
}

/**
 * Utility function to do something on change of a store key
 * @param  {Function} valueGetter Retrives the value to watch from the store
 * @param  {Function} callback    Called when changes happen
 */
export function onKeyChange(valueGetter: Function, callback: Function) {
  let currentValue: any;

  function handleChange() {
    const previousValue = currentValue;
    currentValue = valueGetter();

    if (previousValue !== currentValue) {
      callback(currentValue, previousValue);
    }
  }

  store.subscribe(handleChange);
}

export function requireAuth(
  nextState: RouterState,
  replace: RedirectFunction,
  callback: Function | undefined
) {
  if (!getState().user.loggedIn) {
    // Not logged in! Please wait; checking with HQ…
    UserAPI.get(getState().globals.portalUrl)
      .then(() => {
        // looks like we already had a working session
        store.dispatch(userLoggedIn());
        if (callback) callback();
      })
      .catch((error) => {
        if (!intercept401(error, getState, store.dispatch)) {
          replace('/server-error');
          if (callback) callback();
        }
      });
  } else {
    // Logged in; please proceed, madam.
    if (callback) callback();
  }
}

export function setLastViewedTab(navId: NavId, value: string, callback: Function | undefined) {
  store.dispatch(lastViewedTabUpdated({ navId, value }));
  if (callback) callback();
}

export function redirectFromIndexRoute(
  navId: string,
  defaultRoute: string,
  baseUrl: string,
  replace: RedirectFunction,
  callback: Function | undefined
) {
  const state = store.getState();
  const lastViewed = get(state, `navigation.lastVisitedTab.${navId}`);

  let to;
  if (lastViewed) {
    to = `${baseUrl}/${lastViewed}`;
  } else {
    to = `${baseUrl}/${defaultRoute}`;
  }
  replace(to);
  if (callback) callback();
}

export function requirePortalAdmin(
  redirectRoute: string,
  replace: RedirectFunction,
  callback: Function | undefined
) {
  const state = getState();
  if (state.user.isPortalAdmin) {
    if (callback) callback();
  } else {
    replace(redirectRoute);
    if (callback) callback();
  }
}

export function requireGroupMembership(
  groupName: string,
  redirectRoute: string,
  replace: RedirectFunction,
  callback: Function | undefined
) {
  const state = getState();
  const roleChecker = makeRoleCheckerForCurrentSub(state);

  if (roleChecker(groupName)) {
    if (callback) callback();
  } else {
    replace(redirectRoute);
    if (callback) callback();
  }
}

function wireupUserLogin() {
  // Load user profile when user is logged in
  let versionCheck: ReturnType<typeof setTimeout>;
  onKeyChange(
    () => getState().user.loggedIn,
    (loggedIn: boolean) => {
      if (loggedIn === true) {
        store.dispatch(UserProfileActions.load());
        store.dispatch(SubActions.loadAll());
        // Checks for new version and alert user
        versionCheck = setInterval(() => {
          store.dispatch(ApologyActions.checkVersion());
        }, 5 * 60000);
      } else {
        clearTimeout(versionCheck);
      }
    }
  );
}

function wireupConnected() {
  // Load node data when subscription is connected
  // TODO: should be able clean up BareSubscriptionPage.componentDidMount
  onKeyChange(
    () => getState().subscription.connected,
    (connected: boolean) => {
      if (connected === true) {
        store.dispatch(DatasetActions.loadAll());
        store.dispatch(PipeActions.loadAll());
        store.dispatch(SystemActions.loadAll());
        store.dispatch(DatahubActions.getInfo());
      }
    }
  );
}

function wireupProvisioningPolling() {
  const pollingPending: SomeObject = {};

  function poll(subId: string) {
    store.dispatch(SubActions.reload(subId));
    assignPolling(subId); // eslint-disable-line no-use-before-define
  }

  function assignPolling(subId: string) {
    pollingPending[subId] = setTimeout(() => poll(subId), 30000);
  }

  onKeyChange(
    () => getState().subscriptions,
    (subs: SomeObject[]) => {
      // Figure out which subs are being provisioned
      const pendingSubs = subs
        .filter((sub) => isProvisioning(sub) || hasProvisioningFailed(sub))
        .map((sub) => sub.id);

      // Remove polling for subs that are no longer being provisioned
      Object.keys(pollingPending).forEach(
        (pollingPendingSubId) =>
          !pendingSubs.some((pendingSubId) => pendingSubId === pollingPendingSubId) &&
          clearTimeout(pollingPending[pollingPendingSubId])
      );

      // Add polling for subs being provisioned
      pendingSubs.forEach((subId) => !pollingPending[subId] && assignPolling(subId));
    }
  );
}

function wireupWindowTitles() {
  const subFromPath = (path: string) => {
    const matches = path.match(/^subscription\/(.+)/);
    const subName = getSubName(findSub(getState().subscriptions)(matches[1].split('/')[0]));

    if (subName.length < 1) return 'subscription';
    else return subName;
  };

  // pattern is /sub/<sub-id>/types/type/<id>/
  const configIdFromPath = (path: string) => decodeURIComponent(path.split('/')[4]);

  function getTitle(path: string) {
    switch (true) {
      case /^dashboard/.test(path):
        return 'Dashboard';
      case /^user\/settings/.test(path):
        return 'Your settings';
      case /^create-subscription/.test(path):
        return 'Create subscription';
      case /^create-trial/.test(path):
        return 'Create trial';
      case /^request-trial/.test(path):
        return 'Request trial';
      case /^alerts/.test(path):
        return 'Alerts';
      case /^user\/profile/.test(path):
        return 'User profile';
      case /^user\/change-password/.test(path):
        return 'Change password';
      case /^auth\/login/.test(path):
        return 'Login';
      case /^auth\/logout/.test(path):
        return 'Logout?';
      case /^auth\/signup/.test(path):
        return 'Sign up';
      case /^auth\/forgot-password/.test(path):
        return 'Forgot your password?';
      case /^subscription\/.+?\/settings/.test(path):
        return `Settings for ${subFromPath(path)}`;
      case /^subscription\/.+?\/overview/.test(path):
        return `Overview of ${subFromPath(path)}`;
      case /^subscription\/.+?\/browse\/search/.test(path):
        return `Searching in ${subFromPath(path)}`;
      case /^subscription\/.+?\/browse\/models\//.test(path):
        return `Model of ${subFromPath(path)}`;
      case /^subscription\/.+?\/browse\/entity-types\//.test(path):
        return `Entity type of ${subFromPath(path)}`;
      case /^subscription\/.+?\/browse\/entity-types/.test(path):
        return `Entity types of ${subFromPath(path)}`;
      case /^subscription\/.+?\/browse\/models/.test(path):
        return `Models of ${subFromPath(path)}`;
      case /^subscription\/.+?\/browse/.test(path):
        return `Browsing ${subFromPath(path)}`;
      case /^subscription\/.+?\/datasets\/new/.test(path):
        return `New dataset in ${subFromPath(path)}`;
      case /^subscription\/.+?\/datasets\/dataset/.test(path):
        return `${configIdFromPath(path)} (Dataset) in ${subFromPath(path)}`;
      case /^subscription\/.+?\/datasets/.test(path):
        return `Datasets in ${subFromPath(path)}`;
      case /^subscription\/.+?\/pipes\/new/.test(path):
        return `New pipe in ${subFromPath(path)}`;
      case /^subscription\/.+?\/pipes\/pipe/.test(path):
        return `${configIdFromPath(path)} (Pipe) in ${subFromPath(path)}`;
      case /^subscription\/.+?\/pipes/.test(path):
        return `Pipes in ${subFromPath(path)}`;
      case /^subscription\/.+?\/systems\/new/.test(path):
        return `New system in ${subFromPath(path)}`;
      case /^subscription\/.+?\/systems\/system/.test(path):
        return `${configIdFromPath(path)} (System) in ${subFromPath(path)}`;
      case /^subscription\/.+?\/systems/.test(path):
        return `Systems in ${subFromPath(path)}`;
      case /^subscription\/.+?\/flows\/flow/.test(path):
        return `Flow in ${subFromPath(path)}`;
      case /^subscription\/.+?\/flows/.test(path):
        return `Flows in ${subFromPath(path)}`;
      case /^subscription\/.+?$/.test(path):
        return `Subscription ${subFromPath(path)}`;
      default:
        return null;
    }
  }
  // Sets window title when store changes
  onKeyChange(
    () => getState().routing.locationBeforeTransitions.pathname,
    (pathname: string) => {
      const trimmedBasename = getState().routing.locationBeforeTransitions.basename.substring(1);
      const pathRegEx = new RegExp(`^(${escapeRegExp(trimmedBasename)})?(.*)`);
      const fixedUpPath = pathname.match(pathRegEx)[2];
      const title = getTitle(fixedUpPath);

      if (!title) {
        console.warn('Missing title rule match for path: ', pathname);
        document.title = 'Sesam';
      } else {
        document.title = `${title} - Sesam`;
      }
    }
  );
}

function wireupStatuspage() {
  onKeyChange(
    () => {
      const status = getState().globals.status;
      if (status && !isUndefined(status.is_itest_mode)) {
        return status.is_itest_mode;
      }
    },
    (isItestMode: boolean | undefined) => {
      if (isItestMode === false) {
        const statusPageScript = document.createElement('script');
        statusPageScript.setAttribute('src', 'https://lbct3259brkc.statuspage.io/embed/script.js');
        document.body.appendChild(statusPageScript);
      }
    }
  );
}

export default function (injectStore) {
  store = injectStore;

  wireupUserLogin();
  wireupConnected();
  wireupWindowTitles();
  wireupProvisioningPolling();
  sfetchInit(store);
  wireupStatuspage();

  if (typeof window !== 'undefined') {
    window.SESAM = { getPendingRequestCount, getCompletedRequestCount };
  }

  store.dispatch(ApologyActions.checkStatus());
}
