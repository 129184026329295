import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import sortBy from 'lodash/sortBy';

import SubActions, { getCurrentSub, valuesFromOperations } from 'Redux/thunks/subscriptions';
import { propTypesFromOperations, subscriptionMemberProps } from '../../../model-props';
import Card from '../../../components/card';
import PromiseButton from '../../../components/promise-button';
import UserBanner from '../../../components/user-banner';
import InviteForm from './invite-form';
import MemberRoles from './member-roles';

const operations = ['invite_user'];

class MembersSettings extends React.Component {
  constructor(props) {
    super(props);

    this.props.loadMembers(this.props.subId);
    this.props.loadAvailableRoles(this.props.subId);

    this.getInviteStatus = (m) => {
      if (m.roles.includes('group:DeclinedInvitation')) {
        return 'Invite declined';
      }
      if (m.roles.includes('group:Invited')) {
        return 'Pending invite';
      }
      return null;
    };

    this.getAdminStatus = (m) => {
      if (m.roles.includes('group:Admin')) {
        return 'Administrator';
      }
      return 'Regular user';
    };

    this.getSesamSupportStatus = (m) => {
      const sesamSupportInfo = m.sesam_support_info;
      if (sesamSupportInfo) {
        return `Sesam support (expires at ${moment(sesamSupportInfo.exp).format(
          'MMMM Do YYYY, h:mm:ss a'
        )})`;
      }
      return null;
    };

    this.getBlockedStatus = (m) => {
      if (m.roles.includes('group:Disabled')) {
        if (m.roles.includes('group:Admin')) {
          return 'Blocked (ignored because the user is admin';
        }
        return 'Blocked';
      }
      return null;
    };

    this.isCurrentUser = (m) => this.props.currentUserId === m.user.user_id;
    this.currentUserRoles = () => {
      const currentMember = this.props.members.find(this.isCurrentUser);
      return currentMember.roles;
    };

    this.isAllowedToRemove = (m) =>
      !this.isCurrentUser(m) &&
      this.props.members.length > 1 &&
      this.currentUserRoles().includes('group:Admin');

    this.isAllowedToBlock = (m) =>
      !this.isCurrentUser(m) &&
      !m.roles.includes('group:Admin') &&
      !m.roles.includes('group:Disabled') &&
      this.currentUserRoles().includes('group:Admin');

    this.isAllowedToUnBlock = (m) =>
      m.roles.includes('group:Disabled') && this.currentUserRoles().includes('group:Admin');

    this.isAllowedToReinvite = (m) => m.roles.includes('group:Invited');

    this.refresh = () => {
      this.props.loadMembers(this.props.subId);
      this.props.loadAvailableRoles(this.props.subId);
    };
  }

  componentDidMount() {
    this.props.registerRefresh(this.refresh);
  }

  componentWillUnmount() {
    this.props.unregisterRefresh(this.refresh);
  }

  render() {
    return (
      <main className="scrollArea">
        {this.props.can_invite_user.enabled && (
          <div className="row">
            <div className="col gr-equal">
              <h2 className="heading-section">Invite member</h2>
              <p>
                An invitation email will be sent if you invite another user to this subscription.
              </p>
              {this.props.availableRoles.length > 0 && (
                <InviteForm
                  subId={this.props.subId}
                  availableRoles={this.props.availableRoles}
                  onInvite={this.props.onInvite}
                />
              )}
            </div>
          </div>
        )}
        <h2 className="heading-section">Existing members</h2>
        <div className="card-list">
          <ul className="card-list__list">
            {sortBy(this.props.members, 'user.user_id').map((m) => {
              const adminStatus = this.getAdminStatus(m);
              const sesamSupportStatus = this.getSesamSupportStatus(m);
              const blockedStatus = this.getBlockedStatus(m);
              const inviteStatus = this.getInviteStatus(m);
              const badges = {};
              const actions = [];

              if (adminStatus) {
                badges.admin = adminStatus;
              }
              if (sesamSupportStatus) {
                badges.sesamSupport = sesamSupportStatus;
              }

              if (blockedStatus) {
                badges.block = blockedStatus;
              }

              if (inviteStatus) {
                badges.invite = inviteStatus;
              }

              if (this.isAllowedToRemove(m)) {
                actions.push(
                  <PromiseButton
                    key="remove"
                    onClick={() => this.props.onRemove(this.props.subId, m)}
                    pending="Removing…"
                    theme="danger"
                  >
                    Remove
                  </PromiseButton>
                );
              }

              if (this.isAllowedToBlock(m)) {
                actions.push(
                  <PromiseButton
                    key="block"
                    onClick={() => this.props.onBlock(this.props.subId, m)}
                    pending="Blocking…"
                  >
                    Block
                  </PromiseButton>
                );
              }

              if (this.isAllowedToUnBlock(m)) {
                actions.push(
                  <PromiseButton
                    key="unblock"
                    onClick={() => this.props.onUnblock(this.props.subId, m)}
                    pending="Unblocking…"
                  >
                    Unblock
                  </PromiseButton>
                );
              }

              if (this.isAllowedToReinvite(m)) {
                actions.push(
                  <PromiseButton
                    key="re-invite"
                    onClick={() => this.props.onReinvite(this.props.subId, m)}
                    pending="Re-inviting…"
                  >
                    Re-invite
                  </PromiseButton>
                );
              }

              const memberRoles = (
                <MemberRoles
                  initialRoles={m.roles}
                  onSave={(roles) => this.props.onUpdateRoles(this.props.subId, m, roles)}
                />
              );

              return (
                <li key={m.user.user_id}>
                  <Card
                    actions={actions}
                    badges={badges}
                    header={<UserBanner name={m.user.name} email={m.user.email} />}
                    text={memberRoles}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </main>
    );
  }
}

MembersSettings.propTypes = {
  ...propTypesFromOperations(operations),
  currentUserId: PropTypes.string.isRequired,
  subId: PropTypes.string.isRequired,
  loadMembers: PropTypes.func.isRequired,
  loadAvailableRoles: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
  onUnblock: PropTypes.func.isRequired,
  onReinvite: PropTypes.func.isRequired,
  onUpdateRoles: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape(subscriptionMemberProps)),
};

function mapStateToProps(state) {
  return Object.assign(valuesFromOperations(getCurrentSub(state), operations), {
    availableRoles: state.subscription.availableRoles,
    currentUserId: state.user.user_id,
    members: state.subscription.members,
    subId: state.subscription.id,
  });
}

const mapDispatchToProps = (dispatch) => ({
  loadMembers: (subId) => dispatch(SubActions.loadMembers(subId)),
  loadAvailableRoles: (subId) => dispatch(SubActions.loadAvailableRoles(subId)),
  onRemove: (subId, member) => dispatch(SubActions.remove(subId, member)),
  onBlock: (subId, member) => dispatch(SubActions.block(subId, member)),
  onInvite: (subId, email, role) => dispatch(SubActions.invite(subId, email, role)),
  onUnblock: (subId, member) => dispatch(SubActions.unblock(subId, member)),
  onReinvite: (subId, member) => dispatch(SubActions.reinvite(subId, member)),
  onUpdateRoles: (subId, member, roles) => dispatch(SubActions.updateRoles(subId, member, roles)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MembersSettings);
