import PropTypes from 'prop-types';
import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';

import Button from '../Button/Button';
import ProgressBar from '../ProgressBar/ProgressBar';

import Divider from '@material-ui/core/Divider';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';

import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.light,
    color: theme.palette.text.primary,
  },
  title: {
    padding: '0.625rem',
    fontFamily: 'Barlow, sans-serif',
    fontSize: '1.25rem',
  },
  step: {
    padding: '0.625rem',
  },
  actions: {
    padding: '0.625rem',
    display: 'flex',
  },
}));

import './WizardStyle.css';

function Wizard(props) {
  function setValue(path, value) {
    const stateCopy = { ...state };
    set(stateCopy, path, value);

    setState(stateCopy);
  }

  function setValueTouched(key, isTouched) {
    const touchedCopy = { ...touched };
    touchedCopy[key] = isTouched;

    setTouched(touchedCopy);
  }

  function runValidations() {
    let err = {};

    function setError(key, msg) {
      const errorsCopy = { ...errors };
      errorsCopy[key] = msg;
      err = errorsCopy;
    }

    function validateWithSchema() {
      try {
        props.validationSchema.validateSync(state, {
          abortEarly: false,
        });
      } catch (e) {
        err = e.inner.reduce((acc, curr) => {
          acc[curr.params.path] = curr.message;
          return acc;
        }, {});
        return;
      }
      err = {};
    }

    if (!isEmpty(props.validationSchema)) {
      validateWithSchema();
    }

    if (!isEmpty(props.customValidations)) {
      Object.keys(props.customValidations).forEach((key) => {
        props.customValidations[key](props.bag, state, setError);
      });
    }

    return err;
  }

  const [step, setStep] = useState(0);
  const [state, setState] = useState(props.initialValues);
  const [touched, setTouched] = useState({});

  const classes = useStyle();

  const progress = ((step + 1) / props.steps.length) * 100;

  const Step = props.steps[step];

  const errors = runValidations();

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{props.title}</h1>
      <Divider />
      <div className={classes.step} style={props.customContainerStyle}>
        <Step
          bag={props.bag}
          errors={errors}
          state={state}
          setValue={setValue}
          setValueTouched={setValueTouched}
          touched={touched}
        />
      </div>
      <Divider />
      <div className={classes.actions}>
        <div className="wizard__step-selector">
          <Button disabled={step < 1} onClick={() => setStep(step - 1)}>
            Previous
          </Button>
          <div className="wizard__progress-bar">
            <ProgressBar progress={progress} />
          </div>
          <div
            className={`wizard__error-counter wizard__error-counter--${
              Object.keys(errors).length === 0 ? 'valid' : 'invalid'
            }`}
          >{`${Object.keys(errors).length} ${Object.keys(errors).length === 0 ? '✔' : '✖'}`}</div>
          <Button disabled={step + 1 === props.steps.length} onClick={() => setStep(step + 1)}>
            Next
          </Button>
        </div>
        <Button
          disabled={Object.keys(errors).length > 0}
          onClick={() => props.onFinish(state)}
          theme="primary"
        >
          Finish
        </Button>
      </div>
    </div>
  );
}

Wizard.propTypes = {
  bag: PropTypes.shape({}),
  customContainerStyle: PropTypes.shape({}),
  customValidations: PropTypes.arrayOf(PropTypes.func),
  initialValues: PropTypes.shape({}),
  onFinish: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.func).isRequired,
  title: PropTypes.string.isRequired,
  validationSchema: PropTypes.shape({ validateSync: PropTypes.func }),
};

Wizard.defaultProps = {
  customContainerStyle: {},
  customValidations: [],
  initialValues: {},
  validationSchema: {},
};

export default Wizard;
