import React from 'react';
import PropTypes from 'prop-types';

import isUndefined from 'lodash/isUndefined';

import './style.css';

const MockInput = ({ backgroundColor, label, value, ...restProps }) => {
  restProps.className = [
    isUndefined(restProps.className) ? '' : restProps.className,
    's-mock-input__input',
  ]
    .join(' ')
    .trim();

  const inputHtml = <div {...restProps}>{value}</div>;

  const labelHtml = !isUndefined(label) && (
    <label
      className="s-mock-input__label"
      htmlFor={restProps.id}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      {label}
    </label>
  );

  return (
    <div className="s-mock-input">
      {inputHtml}
      {labelHtml}
    </div>
  );
};

MockInput.propTypes = {
  backgroundColor: (props, propName, component) => {
    if (!isUndefined(props['label']) && isUndefined(props[propName])) {
      return new Error(
        `You must supply the ${propName} prop to the ${component} component, when supplying the label prop`
      );
    }
  },
  label: PropTypes.string,
};

export default MockInput;
