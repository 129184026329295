import React from 'react';
import PropTypes from 'prop-types';
import SesamLink from 'Common/Links/SesamLink';
import ExternalLink from 'Common/Links/ExternalLink';

function Help(props) {
  return (
    <div>
      <h2 className="heading-page">{props.title}</h2>
      {props.children}
    </div>
  );
}

Help.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};

function SimpleHelp(props) {
  return (
    <Help title={props.title}>
      <p>{props.text}</p>
    </Help>
  );
}

SimpleHelp.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

function ConnectHelp(props) {
  switch (props.failed) {
    case 'no_default_connection':
      return (
        <SimpleHelp
          title="No default connection defined"
          text="Unable to connect to subscription because no default urls has been defined."
        />
      );
    case 'provisioning':
      return (
        <SimpleHelp
          title="Provisioning"
          text="Instance is being provisioned. This could take a few minutes."
        />
      );
    case 'not_found':
    case 'not_json':
    case 'not_a_node':
      return (
        <Help title="Not a node?">
          <p>
            The browser was able to connect to {props.lastUrl}, but the response {"didn't"} look
            like a Sesam instance.
          </p>
          <p>Make sure that the url</p>
          <ul>
            <li>
              Points to the <b>api endpoint</b> of the node.
            </li>
            <li>{"Doesn't return 404"}</li>
            <li>
              Returns a JSON document that looks like
              <pre>
                {`{
  "type": "sesam:node"
  [..]
}`}
              </pre>
            </li>
          </ul>
          <p>Example url would be https://foobar/api</p>
        </Help>
      );
    case 'network_problem':
      return (
        <Help title="Connection failed">
          <p>Failed to connect to {props.lastUrl}.</p>
          <p>Make sure that</p>
          <ul>
            <li>
              Your browser can <b>access the url</b> you have specified.
            </li>
            <li>
              You are accessing the node over <b>https</b>
            </li>
            <li>
              If you are using Chrome and is accessing the node over <b>http</b> you can allow
              mixing http and https with a button in the left corner of the url bar. Click on it and
              then click the <b>Load unsafe script</b> button. You will need to do this for every
              browser session.
            </li>
            <li>
              If you are using Firefox and is accessing the node over <b>http</b> you can allow
              mixing http and https by clicking on the green padlock and then click "Disable
              protection".
            </li>
            <li>
              Your browser <b>trusts the certificate</b> of your subscription
            </li>
            <li>There is no proxy in between that strips away Cross Origin headers</li>
          </ul>
          <p>
            Verify the connection by accessing the url directly and add an exception if your browser
            does not trust the certificate for your subscription.
          </p>
          <p>
            <ExternalLink href={props.lastUrl} rel="noopener noreferrer" target="_blank">
              {props.lastUrl}
            </ExternalLink>
          </p>
          <p>You may also want to check the browser console to check if it gives any more clues.</p>
        </Help>
      );
    case 'unlicensed':
      return (
        <Help title="Not licensed yet">
          <p>The subscription at {props.lastUrl} does not have a license yet.</p>
          <p>
            Goto{' '}
            <SesamLink to={`/subscription/${props.subId}/settings-subscription/license`}>
              license settings
            </SesamLink>{' '}
            to upload license.
          </p>
        </Help>
      );
    case 'id_mismatch':
      return (
        <Help title="Url belongs to another subscription">
          <p>The subscription at {props.lastUrl} belongs to another subscription.</p>
          <p>
            If you have access to this subscription, you can transfer the license for this
            subscription to this url.
          </p>
          <p>
            Make sure you {"don't"} breach the license agreement by using the same license on
            multiple instances.
          </p>
          <p>
            Goto{' '}
            <SesamLink to={`/subscription/${props.subId}/settings-subscription/license`}>
              license settings
            </SesamLink>{' '}
            to fix license.
          </p>
        </Help>
      );
    case 'access_denied':
      return (
        <Help title="Access denied">
          <p>{"Doesn't"} seem like you have the neccessary roles to access this subscription</p>
          <p>
            Ask a user who has more rights on this subscription to grant you the neccessary
            permissions.
          </p>
        </Help>
      );
    default:
      return (
        <SimpleHelp
          title="Oops, unable to connect"
          text={`Failed to connect for unknown an unknown reason: ${props.failed}`}
        />
      );
  }
}

export default ConnectHelp;
