import React, { useEffect, useReducer, useCallback } from 'react';

const DEBUG = false;

function log(...msg: string[]) {
  if (DEBUG) {
    console.log(`[useAsync] ${msg}`);
  }
}

type State = {
  args: any[];
  loading: boolean;
  result: any;
  error: boolean;
};

type Action = {
  payload: any;
  type: 'execute' | 'setResult' | 'setError';
};

const nullState: State = {
  args: [],
  loading: false,
  result: null,
  error: false,
};

function reducer(state = nullState, action: Action): State {
  log('Handling action', action.type);
  switch (action.type) {
    case 'execute': {
      return {
        args: action.payload,
        loading: true,
        result: state.result,
        error: false,
      };
    }
    case 'setResult': {
      return { ...nullState, result: action.payload };
    }
    case 'setError': {
      return { ...nullState, error: action.payload };
    }
    default: {
      return nullState;
    }
  }
}

/**
 * This hook enables us to simply call asynchronous functions in functional components
 * without having to mess around with useEffect and additional state manually
 * It returns a function to be executed. The returned function to be called
 * dispatches an action and the hook stores the arguments.
 * It also returns a value that the function returns if we need it, as well
 * as boolean for the loading state of the function, and the error state
 * @param {function} fn async function to be called (_must_ be memoized)
 * @param {any} defaultValue the default value of what this function returns
 */
function useAsync(fn: Function, defaultValue: any = null, executeImmediately = true) {
  const initialState = {
    ...nullState,
    result: defaultValue,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let isCancelled = false;
    async function call() {
      try {
        log('Calling function');
        const val = await fn(...state.args);
        if (!isCancelled) {
          log('Dispatching result');
          dispatch({ type: 'setResult', payload: val });
        }
      } catch (err) {
        if (!isCancelled) {
          log('Error  calling function', err);
          dispatch({ type: 'setError', payload: err });
        }
      }
    }
    if (state.loading) {
      call();
    }
    return () => {
      isCancelled = true;
    };
  }, [state.loading, state.args, fn]);

  useEffect(() => {
    if (executeImmediately) {
      dispatch({ type: 'execute', payload: defaultValue });
    }
  }, []);

  return [
    function (...args: any[]) {
      dispatch({ type: 'execute', payload: args });
    }, // execute
    state.result, // value
    state.loading,
    state.error,
  ];
}

export default useAsync;
