import { PipeNotificationRule } from '../../types/notifications';
import { createReducer } from '@reduxjs/toolkit';
import {
  ruleAdded,
  ruleUpdated,
  ruleRemoved,
  rulesLoaded,
  allRulesLoaded,
} from '../thunks/pipe-notifications';

type PipeNotificationsState = {
  [pipeId: string]: PipeNotificationRule[];
};

const initialState: PipeNotificationsState = {};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(ruleAdded, (state, action) => {
      if (state[action.payload.pipeId]) {
        state[action.payload.pipeId].push(action.payload.rule);
      } else {
        state[action.payload.pipeId] = [action.payload.rule];
      }
    })
    .addCase(ruleUpdated, (state, action) => {
      if (state[action.payload.pipeId]) {
        const index = state[action.payload.pipeId].findIndex(
          (r: PipeNotificationRule) => r.id === action.payload.rule.id
        );
        if (index !== -1) {
          state[action.payload.pipeId][index] = action.payload.rule;
        }
      }
    })
    .addCase(ruleRemoved, (state, action) => {
      if (state[action.payload.pipeId]) {
        const index = state[action.payload.pipeId].findIndex(
          (r: PipeNotificationRule) => r.id === action.payload.ruleId
        );

        if (index !== -1) {
          state[action.payload.pipeId].splice(index, 1);
        }

        if (state[action.payload.pipeId].length === 0) {
          delete state[action.payload.pipeId];
        }
      }
    })
    .addCase(rulesLoaded, (state, action) => {
      state[action.payload.pipeId] = action.payload.rules;
    })
    .addCase(allRulesLoaded, (state, action) => {
      const newState: { [key: string]: PipeNotificationRule[] } = {};
      action.payload.rules.forEach((rule: PipeNotificationRule) => {
        if (rule.pipe_id) {
          if (newState[rule.pipe_id]) {
            newState[rule.pipe_id].push(rule);
          } else {
            newState[rule.pipe_id] = [rule];
          }
        }
      });
      return newState;
    })
);
