import { apologise } from './apology';
import { getApiConf } from './subscriptions';
import UtilsAPI from '../../api/utils';
import { createAction } from '@reduxjs/toolkit';
import { AppThunk } from '../..';
import { SomeObject } from '../../types/types';
import { Json } from '../../types/json';
import { layoutChanged } from './global';

export type Panel = 'options' | 'schema' | 'templates';

export const panelToggled = createAction<Panel>('editor/panelToggled');
export const panelShown = createAction<Panel>('editor/panelShown');
export const panelHidden = createAction<Panel>('editor/panelHidden');
export const dirtyStateUpdated = createAction<boolean>('editor/dirtyStateUpdated');
export const reformatConfigLoaded = createAction<any>('editor/reformatConfigLoaded');
export default {
  togglePanel(panel: Panel): AppThunk {
    return (dispatch) => {
      dispatch(panelToggled(panel));
      dispatch(layoutChanged());
    };
  },

  showPanel(panel: Panel): AppThunk {
    return (dispatch) => {
      dispatch(panelShown(panel));
      dispatch(layoutChanged());
    };
  },

  hidePanel(panel: Panel): AppThunk {
    return (dispatch) => {
      dispatch(panelHidden(panel));
      dispatch(layoutChanged());
    };
  },

  getReformatConfig(): AppThunk {
    return function (dispatch, getState) {
      return UtilsAPI.getReformatConfig(getApiConf(getState))
        .then((config) => dispatch(reformatConfigLoaded(config)))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  // TODO: IS-6787 This is not really a Redux action; it's dispatched as one so it can
  // access `getState()`. Should rethink this.
  // Edit: this is now just an API call with the apologise error handler
  // need to solve error handling better, and then this doesn't have to be here
  reformatCode(code: string, styleOptions: SomeObject): AppThunk {
    return function (dispatch, getState) {
      return UtilsAPI.reformatCode(getApiConf(getState), code, styleOptions).catch((error) =>
        dispatch(apologise(error))
      );
    };
  },

  // TODO: IS-6787 This is not really a Redux action; it's dispatched as one so it can
  // access `getState()`. Should rethink this.
  // Edit: this is now just an API call with the apologise error handler
  // need to solve error handling better, and then this doesn't have to be here
  validateConfig(config: Json): AppThunk {
    return function (dispatch, getState) {
      return UtilsAPI.validateConfig(getApiConf(getState), config).catch((error) =>
        dispatch(apologise(error))
      );
    };
  },

  dirtyStateUpdated,
};
