import debounceAction from 'debounce-action';
import { createSelector, createAction } from '@reduxjs/toolkit';

import { handlePortalError } from '../utils';
import { getApiConf } from './subscriptions';
import { hasFeature } from './datahub';
import {
  getSinkDatasetId,
  getSinkSystemId,
  getSourceDatasetIds,
  getSourceSystemId,
} from 'Internals/pipes';

import PipesAPI from '../../api/pipes';
import {
  State,
  PipeResponse,
  Pipe,
  SomeObject,
  Optional,
  PipeRuntime,
  pipeFields,
} from '../../types/types';
import { AppThunk } from '../..';
import { apologise } from './apology';
import { Json } from '../../types/json';

export const pipesSelector = createSelector(
  (state: State) => state.pipes,
  (pipes) => Object.values(pipes)
);
// -----------------------------------------------------------------------------

export const pipeAdded = createAction<PipeResponse>('pipe/added');
export const pipeUpdated = createAction<{ pipe: PipeResponse; oldPipe: Pipe }>('pipe/updated');
export const pipeRuntimeUpdated = createAction<{
  id: string;
  runtime: PipeRuntime;
}>('pipe/runtimeUpdated');
export const pipeRemoved = createAction<{
  id: string;
  sinkDatasetId: Optional<string>;
  sourceDatasetIds: string[];
  sinkSystemId: Optional<string>;
  sourceSystemId: Optional<string>;
}>('pipe/removed');
export const pipeLoaded = createAction<{ id: string; pipe: PipeResponse }>('pipe/loaded');
export const pipePartiallyLoaded = createAction<{ id: string; pipe: PipeResponse }>(
  'pipe/partiallyLoaded'
);
export const pipeLoading = createAction('pipe/loading');
export const pipeLoadingFailed = createAction('pipe/loadingFailed');
export const pipesLoadAllStarted = createAction('pipe/loadAllStarted');
export const pipesLoadAllFinished = createAction<PipeResponse[]>('pipe/loadAllFinished');
export const pipesLoadAllFailed = createAction('pipe/loadAllFailed');
export const actionStatusSet = createAction<{ id: string; state: string }>('pipe/actionStatusSet');

export default {
  add(newPipe: Pipe): AppThunk {
    return function (dispatch, getState) {
      return PipesAPI.post(getApiConf(getState), newPipe).then((newPipes) =>
        dispatch(pipeAdded(newPipes[0]))
      );
    };
  },

  delete(id: string): AppThunk {
    return function (dispatch, getState) {
      dispatch(actionStatusSet({ id, state: 'deleting' }));
      const pipe = getState().pipes[id];
      const sinkDatasetId = getSinkDatasetId(pipe);
      const sourceDatasetIds = getSourceDatasetIds(pipe);
      const sourceSystemId = getSourceSystemId(pipe);
      const sinkSystemId = getSinkSystemId(pipe);
      return PipesAPI.remove(getApiConf(getState), id)
        .then(() =>
          dispatch(
            pipeRemoved({
              id,
              sinkDatasetId,
              sourceDatasetIds,
              sinkSystemId,
              sourceSystemId,
            })
          )
        )
        .catch((error) => dispatch(apologise(error)));
    };
  },

  runPumpOperation(id: string, operation: string, params: SomeObject): AppThunk {
    return function (dispatch, getState) {
      dispatch(
        actionStatusSet({
          id,
          state: operation,
        })
      );
      return PipesAPI.runPumpOperation(getApiConf(getState), id, operation, params)
        .then((serverUpdate) =>
          dispatch(
            pipeRuntimeUpdated({
              id,
              runtime: serverUpdate.runtime,
            })
          )
        )
        .catch((error) => dispatch(apologise(error)));
    };
  },

  runPumpOperationWithoutErrorHandler(id: string, operation: string, params: SomeObject): AppThunk {
    return function (dispatch, getState) {
      dispatch(
        actionStatusSet({
          id,
          state: operation,
        })
      );
      return PipesAPI.runPumpOperation(getApiConf(getState), id, operation, params).then(
        (serverUpdate) =>
          dispatch(
            pipeRuntimeUpdated({
              id,
              runtime: serverUpdate.runtime,
            })
          )
      );
    };
  },

  update(id: string, updatedPipe: Pipe): AppThunk {
    return function (dispatch, getState) {
      const oldPipe = getState().pipes[id];
      return PipesAPI.put(getApiConf(getState), id, updatedPipe)
        .then((pipe) => dispatch(pipeUpdated({ pipe, oldPipe })))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  load: debounceAction(
    (id: string): AppThunk => {
      return function (dispatch, getState) {
        dispatch(pipeLoading());
        return PipesAPI.get(getApiConf(getState), id)
          .then((pipe) => dispatch(pipeLoaded({ id, pipe })))
          .catch((error) => {
            dispatch(apologise(error));
            dispatch(pipeLoadingFailed());
          });
      };
    },
    1000,
    { leading: true, trailing: false }
  ),

  partialLoad: debounceAction(
    (id: string, fields: pipeFields): AppThunk => {
      return function (dispatch, getState) {
        return PipesAPI.get(getApiConf(getState), id, fields)
          .then((pipe) => dispatch(pipePartiallyLoaded({ id, pipe })))
          .catch((error) => {
            dispatch(apologise(error));
            dispatch(pipeLoadingFailed());
          });
      };
    },
    1000,
    { leading: true, trailing: false }
  ),

  loadForced: (id: string): AppThunk => {
    return function (dispatch, getState) {
      return PipesAPI.get(getApiConf(getState), id)
        .then((pipe) => dispatch(pipeLoaded({ id, pipe })))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  loadAll: debounceAction(
    (loadInternal = false): AppThunk => {
      return function (dispatch, getState) {
        dispatch(pipesLoadAllStarted());
        return PipesAPI.getAll(getApiConf(getState), loadInternal)
          .then((pipes) => dispatch(pipesLoadAllFinished(pipes)))
          .catch((error) => {
            dispatch(pipesLoadAllFailed());
            dispatch(apologise(error));
          });
      };
    },
    1000,
    { leading: true, trailing: false }
  ),

  loadAllForced: (loadInternal = false): AppThunk => {
    return function (dispatch, getState) {
      dispatch(pipesLoadAllStarted());
      return PipesAPI.getAll(getApiConf(getState), loadInternal)
        .then((pipes) => dispatch(pipesLoadAllFinished(pipes)))
        .catch((error) => {
          dispatch(pipesLoadAllFailed());
          dispatch(apologise(error));
        });
    };
  },

  analysePipe(pipe: Pipe): AppThunk {
    return function (dispatch, getState) {
      return PipesAPI.analyse(getApiConf(getState), pipe, hasFeature(getState)(1));
    };
  },

  previewPipe(pipe: Pipe, customSourceEntity: Json, useTrace = false): AppThunk {
    return function (dispatch, getState) {
      return PipesAPI.preview(
        getApiConf(getState),
        pipe,
        customSourceEntity,
        useTrace,
        hasFeature(getState)(1)
      );
    };
  },

  getPipeSettings(pipeId: string): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      const subId = getState().subscription.id;
      return PipesAPI.getSettings(getApiConf(getState), portalUrl, subId, pipeId).catch((error) =>
        dispatch(handlePortalError(error))
      );
    };
  },

  updatePipeSettings(pipeId: string, pipe: Pipe): AppThunk {
    return function (dispatch, getState) {
      const portalUrl = getState().globals.portalUrl;
      const subId = getState().subscription.id;
      return PipesAPI.updateSettings(getApiConf(getState), portalUrl, subId, pipeId, pipe).catch(
        (error) => dispatch(handlePortalError(error))
      );
    };
  },
};
