import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SesamLink from 'Common/Links/SesamLink';
import Button from 'Common/Button/Button';
import Page from '../../../components/page';
import PageHeader, { PageHeaderGroup, PageHeaderTitle } from '../../../components/page-header';
import TabbedNav from '../../../components/tabbed-nav';

function SystemsParent(props) {
  const nav = [
    {
      label: 'List all',
      to: `/subscription/${props.subId}/systems/all`,
    },
    {
      label: 'Permissions',
      to: `/subscription/${props.subId}/systems/permissions`,
    },
    {
      label: 'Prototype permissions',
      to: `/subscription/${props.subId}/systems/prototype-permissions`,
    },
  ];

  return (
    <Page dataSelenium="subscription-systems-pageview">
      <PageHeader>
        <PageHeaderTitle>Systems</PageHeaderTitle>
        <PageHeaderGroup>
          <Button theme="primary" onClick={props.onRefresh}>
            Refresh
          </Button>
          <SesamLink to={`/subscription/${props.subId}/systems/new`} buttonLink={true}>
            <Button theme="primary">New system</Button>
          </SesamLink>
        </PageHeaderGroup>
      </PageHeader>
      <TabbedNav nav={nav}>{props.children}</TabbedNav>
    </Page>
  );
}

SystemsParent.propTypes = {
  children: PropTypes.node,
  subId: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    subId: state.subscription.id,
    subscriptionInfo: state.subscription.info,
  };
}

export default connect(mapStateToProps)(SystemsParent);
