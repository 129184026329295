import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SubActions from 'Redux/thunks/subscriptions';
import { CustomConnectionsThunk } from 'Redux/thunks/customConnectionURL.thunks';

export const useTryConnectViaURL = (subscriptionId: string, customConnectionUrl?: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const attemptConnectPersistedURL = async (url: string) => {
      return dispatch(SubActions.attemptConnectV2(subscriptionId, url));
    };

    if (customConnectionUrl) {
      attemptConnectPersistedURL(customConnectionUrl).then((results) => {
        dispatch(CustomConnectionsThunk.setCustomConnection(subscriptionId, customConnectionUrl));
      });

      // TODO: attempt connect
      //   attemptConnect(customConnectionUrl);
      // if the attempt fails, connect to the fallback
      // if the attempt success, store URL in the local storage
    }
  }, [customConnectionUrl]);
};
