import first from 'lodash/first';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import { push } from 'react-router-redux';

import Typography from 'Common/SesamTypography';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import PaymentMethodActions from 'Redux/thunks/payment-methods';
import SubThunks from 'Redux/thunks/subscriptions';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import ExternalLink from 'Common/Links/ExternalLink';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import SesamModal from 'Common/SesamModal/SesamModal';
import SesamRadioGroupField from 'Common/SesamRadioGroupField/SesamRadioGroupField';
import PriceCalculator from '../../components/price-calculator';
import ProductsEditor from '../../components/products-editor';
import SelectPayment from '../../components/products-editor/select-payment';
import Layout from '../../layout/full-sidebar';
import PageHeader, { PageHeaderTitle } from '../../components/page-header';
import Page from '../../components/page';
import { Links } from 'Constants/links';
import { TestID } from '../../testID';

const defaultProducts = {
  datahub: {
    size: 'developer',
  },
};

class CreateSubscription extends React.Component {
  constructor(props) {
    super(props);

    this.onCreate = () => {
      const hasName = this.state.name;
      const hasPaymentMethod = this.state.paymentMethodId;
      this.setState({
        hasName,
        hasPaymentMethod,
      });
      if (hasName && hasPaymentMethod) {
        this.setState({ pleaseWait: true });
        this.props
          .createSubscription(this.state)
          .then((id) => this.props.redirectToSubId(id))
          .catch(() => this.setState({ pleaseWait: false }));
      }
    };

    this.declineAgreement = () => {
      this.setState({ showAgreement: false });
    };

    this.handleFieldChange = (ev) => {
      const stateKey = ev.target.type === 'radio' ? ev.target.name : ev.target.id;
      this.setState({ [stateKey]: ev.target.value });
    };

    this.handleServiceTypeChange = (ev) => {
      const updatedServiceType = ev.target.value;
      this.setState((state) => {
        const newProducts = cloneDeep(state.products);
        if (
          updatedServiceType === 'on-premise' &&
          (newProducts.datahub.size === 'developer' || newProducts.datahub.size === 'developer-pro')
        ) {
          newProducts.datahub.size = 'single';
        }
        if (updatedServiceType === 'on-premise') {
          delete newProducts.vpn;
        }
        return { service: updatedServiceType, products: newProducts };
      }, this.calculatePrice);
    };

    this.productsChanged = (products) => {
      let newExpectedDataSize = this.state.expectedDataSize;
      const type = get(products, 'datahub.size');
      if (type == 'developer' || type == 'developer-pro') {
        // Clears out the products and expectedDataSize if subscription is developer or developer-pro
        newExpectedDataSize = 0;
        const newProducts = { datahub: { size: type } };
        if (get(products, 'search') === true) {
          newProducts.search = true;
        }
        if (get(products, 'monitoring') === true) {
          newProducts.monitoring = true;
        }
        products = newProducts;
      }
      this.setState({ products, expectedDataSize: newExpectedDataSize }, () =>
        this.calculatePrice()
      );
    };

    this.paymentMethodChanged = (paymentMethodId) => {
      this.setState({ paymentMethodId });
    };

    this.calculatePrice = () => {
      this.props
        .calcPrice(this.state.products, this.state.service, 'paid', this.state.expectedDataSize)
        .then((price) => {
          this.setState({ price });
        });
    };
    this.debouncedCalculatePrice = debounce(this.calculatePrice, 400);

    this.handleExpectedDataSizeChanged = (ev) =>
      this.setState({ expectedDataSize: Number(ev.target.value) }, () =>
        this.debouncedCalculatePrice()
      );

    this.state = {
      description: '',
      hasName: true,
      hasPaymentMethod: true,
      name: 'My subscription',
      paymentMethodId: first(this.props.paymentMethods.length),
      products: defaultProducts,
      service: 'in-cloud',
      showAgreement: false,
      type: 'paid',
      url: '',
      expectedDataSize: 0,
    };
  }

  componentDidMount() {
    this.calculatePrice();
    this.props.loadPaymentMethods();
  }

  render() {
    if (!this.props.paymentMethods.length > 0) {
      return (
        <Layout>
          <Page>
            <PageHeader>
              <PageHeaderTitle>
                <SesamLink to={'/'}>Dashboard</SesamLink>
              </PageHeaderTitle>
              <PageHeaderTitle>New subscription</PageHeaderTitle>
            </PageHeader>
            <main className="scrollArea">
              <div className="row">
                <div className="col">
                  <h2 className="heading-section" style={{ marginTop: '2em' }}>
                    You have no verified payment methods.{' '}
                    <SesamLink
                      to="/user/payment-methods"
                      data-testid={TestID.CreateNewPaymentMethodLink}
                    >
                      Go to your Profile to create one
                    </SesamLink>
                    .
                  </h2>
                </div>
              </div>
            </main>
          </Page>
        </Layout>
      );
    }

    return (
      <Layout>
        <Page>
          <PageHeader>
            <PageHeaderTitle>
              <SesamLink to={'/'}>Dashboard</SesamLink>
            </PageHeaderTitle>
            <PageHeaderTitle>New subscription</PageHeaderTitle>
          </PageHeader>
          <main className="scrollArea">
            <div className="row">
              <div className="col gr-primary">
                <h2 className="heading-section">Setup</h2>
                <SesamTextField
                  margin="normal"
                  label="Name"
                  id="name"
                  value={this.state.name}
                  onChange={this.handleFieldChange}
                  inputProps={{ ['data-selenium']: 'subscription-name' }}
                />
                <Typography variant="subtitle2" style={{ marginTop: '15px' }}>
                  Run the Sesam instance…
                </Typography>
                <Form component="div" horizontal>
                  <SesamRadioGroupField
                    row
                    value={this.state.service}
                    onChange={this.handleServiceTypeChange}
                    name="service"
                    margin="normal"
                    radios={[
                      {
                        label: 'In the cloud',
                        value: 'in-cloud',
                        helperText: 'We set it up and host it',
                      },
                      {
                        label: 'On-premise',
                        value: 'on-premise',
                        helperText: 'It runs on your hardware',
                        seleniumText: 'service-on-premise',
                      },
                    ]}
                  />
                </Form>
                <ProductsEditor
                  products={this.state.products}
                  isNewSubscription={true}
                  onChange={this.productsChanged}
                  serviceType={this.state.service}
                  startCollapsed
                />
                {!this.state.hasName && (
                  <div className="error-panel">Subscription must have a name</div>
                )}
                {!this.state.hasPaymentMethod && (
                  <div className="error-panel">Please select a verified payment method</div>
                )}
                {this.props.createSubFailed && (
                  <div className="error-panel">Subscription limit reached</div>
                )}
              </div>
              <div className="col gr-secondary">
                <h3 className="heading-section">Price</h3>
                <p style={{ fontSize: '0.8rem' }}>
                  Please notice that prices are estimates only and are not intended as actual price
                  quotes. For more detailed information please visit{' '}
                  <ExternalLink target="_blank" rel="noopener noreferrer" href={Links.SesamPricing}>
                    Subscription Fee and payment terms
                  </ExternalLink>
                  {'.'}
                </p>
                <p>You will be billed monthly:</p>
                {this.state.price && (
                  <PriceCalculator
                    value={this.state.price}
                    expectedDataSize={this.state.expectedDataSize}
                    onExpectedDataSizeChange={this.handleExpectedDataSizeChanged}
                  />
                )}
                <SelectPayment
                  onChange={this.paymentMethodChanged}
                  paymentMethods={this.props.paymentMethods}
                  value={this.state.paymentMethodId}
                />
                <FormActions>
                  <Button
                    data-selenium="create-subscription"
                    disabled={!this.state.paymentMethodId}
                    onClick={() => this.setState({ showAgreement: true })}
                    type="submit"
                  >
                    Create…
                  </Button>
                </FormActions>
              </div>
            </div>
          </main>
          <SesamModal
            className="simple-dialog"
            isOpen={this.state.showAgreement}
            onRequestClose={this.declineAgreement}
            contentLabel="Agreement"
          >
            <h2 className="heading-page">Agreement</h2>
            <p>
              Please read and accept the agreement below to proceed. If you have trouble viewing the
              document, you can{' '}
              <ExternalLink href={Links.TermsOfServicePDF} download>
                download it manually
              </ExternalLink>
              .
            </p>
            <iframe src={Links.TermsOfServicePDF} className="external-document" />
            <div className="toolbar toolbar--right">
              <Button
                disabled={this.state.pleaseWait}
                onClick={this.declineAgreement}
                style={{ marginRight: '0.5rem' }}
              >
                Decline
              </Button>
              <Button
                data-selenium="accept-agreement"
                disabled={this.state.pleaseWait}
                onClick={this.onCreate}
                type="submit"
                theme="primary"
              >
                {this.state.pleaseWait ? 'Creating…' : 'Accept and create subscription'}
              </Button>
            </div>
          </SesamModal>
        </Page>
      </Layout>
    );
  }
}

CreateSubscription.propTypes = {
  createSubFailed: PropTypes.bool,
  createSubscription: PropTypes.func.isRequired,
  redirectToSubId: PropTypes.func.isRequired,
  calcPrice: PropTypes.func.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  loadPaymentMethods: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    createSubFailed: state.user.createSubFailed,
    paymentMethods: state.paymentMethods.filter((pm) => pm.is_verified),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createSubscription: (data) => dispatch(SubThunks.createSubscription(data)),
    calcPrice: (products, service, type, expectedDataSize) =>
      dispatch(SubThunks.calcNewPrice(products, service, type, expectedDataSize)),
    redirectToSubId: (subId) => dispatch(push(`/subscription/${subId}`)),
    loadPaymentMethods: () => dispatch(PaymentMethodActions.loadAll()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscription);
