import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useCombobox } from 'downshift';

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

import Clear from '@material-ui/icons/Clear';
import Error from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Search from '@material-ui/icons/Search';

const useStyle = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    input: {
      '& input': {
        marginLeft: '32px',
      },
      '&.MuiOutlinedInput-adornedStart': {
        paddingLeft: '17px',
      },
    },
    selectedInput: {
      '& fieldset': {
        borderColor: theme.palette.grey['300'],
      },
    },
    filterInput: {
      borderLeft: `1px solid ${theme.palette.grey['300']}`,
      borderBottom: 0,
      borderRight: `1px solid ${theme.palette.grey['300']}`,
      borderTop: `1px solid ${theme.palette.grey['300']}`,
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      padding: '10px 0px',
      '& input': {
        marginLeft: '32px',
      },
      '&.MuiInputBase-adornedStart': {
        paddingLeft: '17px',
      },
      '&:before': {
        borderBottom: `1px solid ${theme.palette.grey['300']}`,
      },
    },
    inputDone: {
      '&:after': {
        border: 0,
      },
      '&:before': { border: '0 !important' },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.success.main,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.success.main} !important`,
      },
    },
    inputNotDone: {
      '&:after': {
        border: 0,
      },
      '&:before': { border: '0 !important' },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.error.main} !important`,
      },
    },
    iconDone: {
      color: theme.palette.success.main,
    },
    iconNotDone: {
      color: theme.palette.error.main,
    },
    combobox: {
      marginTop: '15px',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',
    },
    items: {
      flex: 1,
      overflow: 'scroll',
      marginTop: '-1px',
    },
    paper: {
      marginBottom: '10px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    listItemIcon: {
      width: '27px',
      height: '27px',
      color: 'black',
    },
    loadingProgress: {
      marginLeft: '2px',
    },
  };
});

const HopsWizardFilteredList = (props) => {
  const classes = useStyle();

  const {
    editable,
    inputProps,
    isLoading,
    items,
    Icon,
    onSelectItem,
    onChange,
    placeholderEmptySearch,
    placeholderEmptyValue,
    placeholderSearch,
    value,
  } = props;

  const [filteredItems, setFilteredItems] = useState(items);

  const inputRef = useRef(<input />);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  /**
   * Let's us control the internal state changes of downshift
   */
  const stateReducer = (state, action) => {
    if (action.type === useCombobox.stateChangeTypes.ItemClick) {
      /**
       * We want the filter input field to clear out when an item is clicked
       */
      const changesCopy = { ...action.changes };
      changesCopy.inputValue = '';
      return changesCopy;
    }

    return action.changes;
  };

  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    reset,
    selectItem,
  } = useCombobox({
    items: filteredItems,
    defaultIsOpen: true,
    stateReducer,
    onInputValueChange: ({ inputValue }) => {
      setFilteredItems(
        items.filter((id) => id.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
      );
    },
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem !== null) {
        onSelectItem(changes.selectedItem);
      }
      /**
       * Resets downshift to sensible defaults
       */
      reset();
    },
  });

  return (
    <div className={classes.container}>
      <OutlinedInput
        value={value}
        className={clsx([
          classes.input,
          classes.selectedInput,
          value && classes.inputDone,
          !value && classes.inputNotDone,
        ])}
        endAdornment={
          <InputAdornment>
            <IconButton
              size="small"
              onClick={() => {
                onSelectItem('');
                inputRef.current.focus();
              }}
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        }
        startAdornment={
          <InputAdornment>
            {value ? (
              <CheckCircle className={classes.iconDone} />
            ) : (
              <Error className={classes.iconNotDone} />
            )}
          </InputAdornment>
        }
        readOnly={!editable}
        inputProps={{
          placeholder: placeholderEmptyValue,
        }}
        onChange={(ev) => onChange(ev.target.value)}
        fullWidth={true}
      />
      <div {...getComboboxProps({ className: classes.combobox })}>
        <Input
          className={clsx([classes.filterInput])}
          fullWidth={true}
          startAdornment={
            <InputAdornment>
              <Search />
            </InputAdornment>
          }
          inputProps={{
            ...getInputProps({
              ...inputProps,
              placeholder: placeholderSearch,
              ref: inputRef,
            }),
          }}
        />
        {
          <div className={classes.items}>
            <Paper variant="outlined" className={classes.paper}>
              <List disablePadding {...getMenuProps()}>
                {isLoading ? (
                  <ListItem>
                    <ListItemText>Loading...</ListItemText>
                  </ListItem>
                ) : !isEmpty(filteredItems) ? (
                  filteredItems.map((item, index) => {
                    return (
                      <ListItem
                        button
                        key={`${item}${index}`}
                        selected={index === highlightedIndex}
                        component="li"
                        onMouseDown={(e) => selectItem(item)}
                        {...getItemProps({
                          item,
                        })}
                      >
                        {Icon && (
                          <ListItemIcon>
                            <Icon className={classes.listItemIcon} />
                          </ListItemIcon>
                        )}
                        <ListItemText>{item}</ListItemText>
                      </ListItem>
                    );
                  })
                ) : (
                  <ListItem>
                    <ListItemText>{placeholderEmptySearch}</ListItemText>
                  </ListItem>
                )}
              </List>
            </Paper>
          </div>
        }
      </div>
    </div>
  );
};

HopsWizardFilteredList.propTypes = {
  editable: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  Icon: PropTypes.func,
  onSelectItem: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  placeholderEmptySearch: PropTypes.string,
  placeholderEmptyValue: PropTypes.string,
  placeholderSearch: PropTypes.string,
  value: PropTypes.string.isRequired,
};

HopsWizardFilteredList.defaultProps = {
  editable: true,
  isLoading: false,
  placholderEmptySearch: 'No items found',
  placeholderEmptyValue: 'No value selected',
  placeholderSearch: 'Search items',
};

export default HopsWizardFilteredList;
