import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { findSub, isProvisioning } from 'Redux/thunks/subscriptions';
import SesamLink from 'Common/Links/SesamLink';
import { Form, FormActions } from 'Common/forms';
import { LoadingPanel } from 'Common/LoadingPanel';

import FocusPanel from '../../../components/focus-panel';
import ConnectHelp from '../connect-help';

const mapStateToProps = function mapStateToProps(state) {
  return {
    provisioning: isProvisioning(findSub(state.subscriptions)(state.subscription.id)),
    connecting: state.subscription.connecting,
    connected: state.subscription.connected,
    failed: state.subscription.lastConnectFailed,
    subId: state.subscription.id,
    lastUrl: state.subscription.lastConnectUrl,
    hibernated: findSub(state.subscriptions)(state.subscription.id)?.was_hibernated_due_to_idleness,
  };
};

export default function (doneComponent) {
  function ConnectedGuard(props) {
    if (!props.connected && props.provisioning) {
      return <LoadingPanel loadingMessage="Provisioning…" />;
    }
    if (props.failed) {
      return (
        <div className="layout__content scrollArea">
          <FocusPanel>
            <ConnectHelp failed={props.failed} lastUrl={props.lastUrl} subId={props.subId} />
            <Form component="div">
              <FormActions>
                <SesamLink to={`/subscription/${props.subId}/settings-subscription/network`}>
                  Go to network settings
                </SesamLink>
              </FormActions>
            </Form>
          </FocusPanel>
        </div>
      );
    }
    if (!props.connected && props.connecting) {
      return <LoadingPanel loadingMessage="Connecting..." />;
    }
    if (props.hibernated) {
      return (
        <FocusPanel>
          <div>
            <h2 className="heading-page">Subscription Hibernated!</h2>
            <p>Please wait 10 mins for reactivation.</p>
          </div>
          <Form component="div">
            <FormActions>
              <SesamLink to={`/subscription/${props.subId}/settings-subscription/network`}>
                Go to network settings
              </SesamLink>
            </FormActions>
          </Form>
        </FocusPanel>
      );
    }
    if (!props.connecting && !props.connected) {
      return (
        <FocusPanel>
          <div>
            <h2 className="heading-page">Not connected</h2>
            <p>Go to network settings to connect</p>
          </div>
          <Form component="div">
            <FormActions>
              <SesamLink to={`/subscription/${props.subId}/settings-subscription/network`}>
                Go to network settings
              </SesamLink>
            </FormActions>
          </Form>
        </FocusPanel>
      );
    }
    const doneProps = Object.assign({}, props);
    delete doneProps.provisioning;
    delete doneProps.connected;
    delete doneProps.subId;
    delete doneProps.lastUrl;
    delete doneProps.failed;
    return React.createElement(doneComponent, doneProps);
  }

  ConnectedGuard.propTypes = {
    provisioning: PropTypes.bool.isRequired,
    connected: PropTypes.bool.isRequired,
    connecting: PropTypes.bool,
    subId: PropTypes.string.isRequired,
    lastUrl: PropTypes.string,
    failed: PropTypes.string.isRequired,
    hibernated: PropTypes.bool,
  };

  return connect(mapStateToProps)(ConnectedGuard);
}
