import React from 'react';
import DangerText from 'Common/Text/DangerText';
import { TestID } from '../../testID';

export const PostEntityModalError: React.FC<{ errorMessages: string[] }> = ({ errorMessages }) => (
  <div className="error-message-container">
    {errorMessages.map((message, index) => (
      <DangerText
        variant="body1"
        key={message.slice(-2) + '-' + index}
        className="error-message"
        data-testid={TestID.ErrorMessageContainerOnDeadLetterPostModal}
      >
        {message}
      </DangerText>
    ))}
  </div>
);
