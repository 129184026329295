import React from 'react';
import PumpFailedForm from './PumpFailedForm';
import PumpFinishedOverdueForm from './PumpFinishedOverdueForm';
import PumpOffsetSetForm from './PumpOffsetSetForm';
import PumpRestoredFromBackupForm from './PumpRestoredFromBackupForm';
import PumpStartedOverdueForm from './PumpStartedOverdueForm';
import ValueTooHighOverTimeForm from './ValueTooHighOverTimeForm';
import ValueTooLowOverTimeForm from './ValueTooLowOverTimeForm';
import ValueTooHighForm from './ValueTooHighForm';
import ValueTooLowForm from './ValueTooLowForm';
import PatternMatchForm from './PatternMatchForm';

export default function renderRuleForm(type, props) {
  switch (type) {
    case 'pump_failed':
      return <PumpFailedForm {...props} />;
    case 'pump_started_overdue':
      return <PumpStartedOverdueForm {...props} />;
    case 'pump_finished_overdue':
      return <PumpFinishedOverdueForm {...props} />;
    case 'pump_offset_set':
      return <PumpOffsetSetForm {...props} />;
    case 'restore_completed':
      return <PumpRestoredFromBackupForm {...props} />;
    case 'pump_completed_value_too_high_over_time':
      return <ValueTooHighOverTimeForm {...props} />;
    case 'pump_completed_value_too_low_over_time':
      return <ValueTooLowOverTimeForm {...props} />;
    case 'pump_completed_value_too_high':
      return <ValueTooHighForm {...props} />;
    case 'pump_completed_value_too_low':
      return <ValueTooLowForm {...props} />;
    case 'pattern_match':
      return <PatternMatchForm {...props} />;
  }
}
