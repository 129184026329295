import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'Common/Button/Button';
import ButtonGroup from 'Common/ButtonGroup/ButtonGroup';
import EditorActions from 'Redux/thunks/editor';

import '../../style/global.css';
import './Editor.css';

/*
 * Editor parts with replacements on one side and config on other side
 */
class _Editor extends React.Component {
  constructor(props) {
    super(props);
    this.toggleButtonClasses = (propKey) => {
      return () => `btn btn--default ${this.props[propKey] ? 'btn--active' : ''}`;
    };

    this.state = {
      toolbarControls: [],
    };
  }

  componentDidMount() {
    if (!this.props.ready) {
      this.props.showPanel('templates');
    } else {
      this.props.hidePanel('templates');
    }
  }

  render() {
    return (
      <section className="editor">
        <div className="editor__header">
          <div className="toolbar toolbar--small">
            <span>Panels:</span>
            <ButtonGroup className="input-group">
              <Button
                active={this.props['showTemplates']}
                onClick={this.props.onTogglePanel('templates')}
              >
                Templates
              </Button>
              <Button
                active={this.props['showSchema']}
                onClick={this.props.onTogglePanel('schema')}
              >
                Schema
              </Button>
              {this.props.extraToolbarPanelButtons}
            </ButtonGroup>
            {this.props.extraToolbarButtons}
            <Button
              className="editor__options-btn"
              onClick={this.props.onTogglePanel('options')}
              style={{ marginLeft: '0.5rem' }}
            >
              Editor options
            </Button>
          </div>
        </div>
        <div className="editor__work-area">{this.props.children}</div>
      </section>
    );
  }
}

_Editor.propTypes = {
  children: PropTypes.node,
  disableSave: PropTypes.bool,
  message: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  shortMessage: PropTypes.string,
  status: PropTypes.string,
  showSchema: PropTypes.bool.isRequired,
  showTemplates: PropTypes.bool.isRequired,
  onTogglePanel: PropTypes.func.isRequired,
  showPanel: PropTypes.func.isRequired,
  hidePanel: PropTypes.func.isRequired,
  extraToolbarButtons: PropTypes.node,
  extraToolbarPanelButtons: PropTypes.node,
  ready: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    showSchema: state.editor.visiblePanels.schema,
    showTemplates: state.editor.visiblePanels.templates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onTogglePanel: (panel) => () => dispatch(EditorActions.togglePanel(panel)),
    showPanel: (panel) => dispatch(EditorActions.showPanel(panel)),
    hidePanel: (panel) => dispatch(EditorActions.hidePanel(panel)),
  };
}
export const Editor = connect(mapStateToProps, mapDispatchToProps)(_Editor);

export { default as Configuration } from './configuration';
export { default as Replacements, Replacement } from './replacements';
