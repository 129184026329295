import { sfetchJson } from 'Internals/sfetch';
import { ApiConf, Pipe, SomeObject, PipeResponse, pipeFields } from '../types/types';
import { Json } from '../types/json';

async function get(apiConf: ApiConf, id?: string, fields?: pipeFields): Promise<PipeResponse> {
  if (!id) return Promise.reject(new Error('ID for pipe not supplied'));
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  let url = `${subUrl}/pipes/${idUrl}`;
  if (fields) {
    url += `?fields=${fields.toString()}`;
  }
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  return await sfetchJson(url, options);
}

async function getAll(apiConf: ApiConf, includeInternal = false): Promise<PipeResponse[]> {
  const { subUrl, token } = apiConf;
  let url = `${subUrl}/pipes`;

  if (includeInternal) {
    url = url + '?include-internal-pipes=true';
  }

  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  return await sfetchJson(url, options);
}

async function post(apiConf: ApiConf, newPipe: Pipe, force = true): Promise<PipeResponse[]> {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    body: JSON.stringify([newPipe]),
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  };
  return await sfetchJson(`${subUrl}/pipes?force=${force}`, options);
}

async function remove(apiConf: ApiConf, id: string): Promise<Json> {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'DELETE',
  };
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/pipes/${idUrl}`;
  return await sfetchJson(url, options);
}

async function put(
  apiConf: ApiConf,
  id: string,
  updatedPipe: Pipe,
  force = true
): Promise<PipeResponse> {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const options: RequestInit = {
    body: JSON.stringify(updatedPipe),
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  };
  return await sfetchJson(`${subUrl}/pipes/${idUrl}/config?force=${force}`, options);
}

async function runPumpOperation(
  apiConf: ApiConf,
  id: string,
  operation: string,
  params: SomeObject
): Promise<SomeObject> {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/pipes/${idUrl}/pump`;
  const formData = new FormData();
  const options: RequestInit = {
    body: formData,
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'POST',
  };
  formData.append('operation', operation);
  Object.keys(params).forEach((key) => formData.append(key, params[key]));
  return await sfetchJson(url, options);
}

async function analyse(apiConf: ApiConf, pipe: Pipe, useNewerApi: boolean): Promise<Json> {
  const { subUrl, token } = apiConf;
  const formData = new URLSearchParams();
  formData.append('operation', 'analyze-pipe');
  formData.append('pipe-config', JSON.stringify(pipe));
  const options: RequestInit = {
    body: formData,
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'POST',
  };
  if (useNewerApi) {
    return sfetchJson(`${subUrl}/pipes/${pipe._id}/analyze`, options);
  } else {
    // fallback for old nodes, can be removed at a later stage
    return sfetchJson(`${subUrl}/`, options);
  }
}

async function preview(
  apiConf: ApiConf,
  pipe: Pipe,
  customSourceEntity: Json,
  useTrace: boolean,
  useNewerApi: boolean
): Promise<Json> {
  const { subUrl, token } = apiConf;
  const pipeCopy = { ...pipe };

  if (pipeCopy._id === null) {
    pipeCopy._id = 'foo'; // To be able to preview an unnamed pipe
  }

  const formData = new URLSearchParams();
  formData.append('operation', 'preview-pipe');
  formData.append('pipe-config', JSON.stringify(pipeCopy));
  if (useTrace) formData.append('trace', 'true');
  if (customSourceEntity) {
    formData.append('source', JSON.stringify(customSourceEntity));
  }
  const options: RequestInit = {
    body: formData,
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'POST',
  };

  if (useNewerApi) {
    return sfetchJson(`${subUrl}/pipes/${pipeCopy._id}/preview`, options);
  } else {
    // fallback for old nodes, can be removed at a later stage
    return sfetchJson(`${subUrl}/`, options);
  }
}

async function getSettings(
  apiConf: ApiConf,
  portalUrl: string,
  subId: string,
  pipeId: string
): Promise<Json> {
  const { token } = apiConf;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };
  return await sfetchJson(`${portalUrl}/subscriptions/${subId}/pipe-settings/${pipeId}`, options);
}

async function updateSettings(
  apiConf: ApiConf,
  portalUrl: string,
  subId: string,
  pipeId: string,
  settings: SomeObject
): Promise<Json> {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(settings),
  };
  return await sfetchJson(`${portalUrl}/subscriptions/${subId}/pipe-settings/${pipeId}`, options);
}

async function explainMerge(apiConf: ApiConf, entityId: string, pipeId: string) {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  return await sfetchJson(`${subUrl}/pipes/${pipeId}/explain-merge?entity_id=${entityId}`, options);
}

export default {
  analyse,
  explainMerge,
  get,
  getAll,
  getSettings,
  post,
  preview,
  put,
  remove,
  runPumpOperation,
  updateSettings,
};
