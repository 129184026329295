import React, { useState } from 'react';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';

import Box from 'Common/Box/Box';
import Button from 'Common/Button/Button';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import SlideLeftIcon from '../../images/icons/slide-left.svg';

interface SearchBoxProps {
  onSearch: () => void;
  onQueryChanged: (query: string) => void;
  query: string;
}

const useStyle = makeStyles({
  box: {
    marginBottom: '25px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    marginLeft: '10px',
  },
  collapsible: {
    width: '50px',
    flexGrow: 0,
    display: 'flex',
    flexShrink: 0,
    background: '#F7F8F8',
    height: '2.5em',
    marginRight: '-8px',
    '&:hover': {
      cursor: 'pointer',
    },
    '& svg': {
      display: 'block',
      height: '2em',
      margin: 'auto',
      padding: '0.25em 0',
      width: '2em',
      color: '#58585B',
    },
  },
});

const SearchBox: React.FC<SearchBoxProps> = ({ onSearch, onQueryChanged, query, onClick }) => {
  const classes = useStyle();

  return (
    <Box className={classes.box}>
      <Box.Header>
        <Box.Title>Search</Box.Title>
        <div className={classes.collapsible} onClick={onClick}>
          <SlideLeftIcon />
        </div>
      </Box.Header>
      <Box.Content white padding>
        <form
          className={classes.container}
          onSubmit={(ev) => {
            ev.preventDefault();
            onSearch();
          }}
        >
          <SesamTextField
            autoFocus
            value={query}
            onChange={(ev: React.ChangeEvent) => {
              const target = ev.target as HTMLInputElement;
              onQueryChanged(target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => {
                      onQueryChanged('');
                    }}
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button type="submit" className={classes.action}>
            Search
          </Button>
        </form>
      </Box.Content>
    </Box>
  );
};

export default SearchBox;
