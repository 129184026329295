import { ApiConf } from '../../../types';
import { connect } from 'react-redux';
import { getApiConf } from 'Redux/thunks/subscriptions';
import cloneDeep from 'lodash/cloneDeep';
import defaults from 'lodash/defaults';
import PropTypes from 'prop-types';
import React from 'react';

import { ActionBarStatus } from '../../../components/action-bar';
import {
  Editor,
  Replacements,
  Replacement,
  Configuration,
} from '../../../components/editor/Editor';
import { LoadingPanel } from 'Common/LoadingPanel';
import ActionBar from '../../../components/action-bar';
import Button from 'Common/Button/Button';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import MetadataAPI from '../../../api/metadata';
import DatahubSettings from '../settings-datahub';

type SettingsDatahubMetadataProps = {
  apiConf: ApiConf;
};

class SettingsDatahubMetadata extends React.Component<SettingsDatahubMetadataProps> {
  constructor(props: SettingsDatahubMetadataProps) {
    super(props);

    this.onSave = () => {
      this.setState({
        message: '',
        shortMessage: 'Saving…',
        status: ActionBarStatus.waiting,
      });

      MetadataAPI.post(this.props.apiConf, this.state.value)
        .then(() =>
          this.setState({
            status: ActionBarStatus.default,
            shortMessage: 'Metadata saved',
          })
        )
        .catch((error) =>
          this.setState({
            status: ActionBarStatus.error,
            shortMessage: 'Could not save metadata',
            message: error.response.statusText, // TODO: Better error message; parse JSON
          })
        );
    };

    this.onAddConfigTemplate = (ev) => {
      ev.preventDefault();
      const value = cloneDeep(this.state.value);
      let template = this.state.templates.find((tpl) => tpl.name === this.state.selectedTemplate);

      if (template) {
        template = cloneDeep(template);
        delete template.name;
        const templateId = template.id;
        delete template.id;
        if (templateId === 'rdf') {
          // Add a new entry to the "rdf"-entry (or create a new one)
          value.rdf = value.rdf || {};
          value.rdf[`selector${Date.now()}`] = template;
        } else {
          // merge into any existing value (keep existing attibutes)
          value[templateId] = value[templateId] || {};
          defaults(value[templateId], template);
        }

        this.setState({
          editorKey: this.state.editorKey + 1,
          value,
        });
      }
    };

    this.onConfigChanged = (config) => {
      this.setState({
        message: '',
        shortMessage: '',
        status: ActionBarStatus.default,
        value: config,
      });
    };

    this.state = {
      configError: null,
      templates: [
        {
          name: 'RDF',
          id: 'rdf',
          prefix_includes: ['<prefix>'],
          prefix_rules: {
            id: null,
            properties: [null, null],
          },
          prefixes: {},
          quote_safe_characters: '<quote_safe_characters>',
        },
        // Note: if you change the default threshold values here, you should change them in the
        // "DependencyTrackingSettings" class in the file "lake/python/lake/lake/node/metadata.py", too.
        {
          name: 'Dependency tracking',
          id: 'dependency_tracking',
          dependency_warning_threshold: 10000,
          dependency_error_threshold: 50000,
          dependency_warning_threshold_total_bytes: 33554432,
          dependency_error_threshold_total_bytes: 134217728,
        },
        // Note: if you change the default threshold values here, you should change them in the
        // "PipeLimit" class in the file "lake/python/lake/lake/node/metadata.py", too.
        {
          name: 'Pipe limits',
          id: 'pipe_limits',
          minimum_schedule_interval_for_internal_pipes: 1,
          minimum_schedule_interval_for_external_pipes: 1,
        },
        {
          name: 'TaskManager settings',
          id: 'task_manager',
          disable_pump_scheduler: false,
        },
      ],
      selectedTemplate: null,
      value: null,
      editorKey: 1,
    };
  }

  componentDidMount() {
    MetadataAPI.get(this.props.apiConf)
      .then((jsonData) => {
        if (jsonData.config.original.$audit) {
          delete jsonData.config.original.$audit;
        }
        this.setState({ value: jsonData.config.original });
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          this.setState({ value: { _id: 'node', type: 'metadata' } });
        } else {
          // TODO display global apologize
          console.log('request failed', error);
        }
      });
  }

  render() {
    if (!this.state.value) {
      return <LoadingPanel />;
    }

    const templates = this.state.templates.map((template) => template.name);
    return (
      <React.Fragment>
        <DatahubSettings>
          <Editor
            disableSave={!!this.state.configError}
            onSave={this.onSave}
            message={this.state.message}
            shortMessage={this.state.shortMessage}
            status={this.state.status}
          >
            <Replacements>
              <Replacement
                title="Configuration templates"
                buttonLabel="Add"
                onReplaceTemplate={this.onAddConfigTemplate}
              >
                <SesamTextField
                  id="configTemplates"
                  label="Insert configuration"
                  margin="normal"
                  onChange={(ev) => this.setState({ selectedTemplate: ev.target.value })}
                  value={this.state.selectedTemplate || ''}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (value) => value || '-',
                  }}
                  select
                >
                  {templates.map((name) => (
                    <SesamMenuItem key={name} value={name}>
                      {name}
                    </SesamMenuItem>
                  ))}
                </SesamTextField>
              </Replacement>
            </Replacements>
            <Configuration
              value={this.state.value}
              key={this.state.editorKey}
              onChange={this.onConfigChanged}
              configError={this.state.configError}
              onSave={this.onSave}
              onValidateJson={(isValid) => this.setState({ configError: !isValid })}
            />
          </Editor>
          <ActionBar
            message={this.state.message}
            shortMessage={this.state.shortMessage}
            status={this.state.status}
          >
            <Button onClick={this.onSave} disabled={this.state.configError}>
              Save
            </Button>
          </ActionBar>
        </DatahubSettings>
      </React.Fragment>
    );
  }

  static contextTypes = {
    router: PropTypes.object,
  };
}

function mapStateToProps(state) {
  return {
    apiConf: getApiConf(() => state),
  };
}

export default connect(mapStateToProps)(SettingsDatahubMetadata);
