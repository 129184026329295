import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { RootState } from '../index';
import { SomeObject } from '../types/types';
import { getCurrentSub } from './thunks/subscriptions';

export const datahubSelector = (state: RootState) => state.subscription;

export const datahubInfoSelector = createSelector(datahubSelector, (datahub) => datahub.info);

export const pipesSelector = createSelector(
  (state: RootState) => state.pipes,
  (pipes) => Object.values(pipes)
);

export const leafPipesSelector = createSelector(
  (state: RootState) => Object.keys(state.datasets),
  (state) => state.upstreams,
  (state) => state.downstreams,
  (state) => state.lookups,
  (datasets, upstreams, downstreams, lookups) => {
    const leafPipes = [];
    for (const datasetId of datasets) {
      if (!downstreams[datasetId]) {
        if (!lookups[datasetId]) {
          leafPipes.push(upstreams[datasetId]);
        }
      }
    }

    return leafPipes;
  }
);

export const searchIsActiveSelector = createSelector(
  (state: RootState) => state.subscriptions,
  (state: RootState) => state.subscription.id,
  (subscriptions, id) => {
    const sub = subscriptions.find((s) => s.id === id);
    return sub && sub.products && sub.products.search === true;
  }
);

export const systemsSelector = createSelector(
  (state: RootState) => state.systems,
  (systems) => Object.values(systems)
);

export const DEFAULT_CONFIG_GROUP_ID = '$Default';
export const DEFAULT_CONFIG_GROUP_LABEL = '* Default *';
export const configGroupsSelector = createSelector(
  pipesSelector,
  systemsSelector,
  (pipes, systems) => {
    const pipesAndSystems = [...pipes, ...systems];
    const configGroups: { [configGroup: string]: true } = {};
    for (const p of Object.values(pipesAndSystems)) {
      const configGroup = get(p, 'config.effective.metadata[$config-group]');
      if (configGroup) {
        configGroups[configGroup] = true;
      }
    }
    // Add the "default" config group
    // it has a special $ marker to show that this is the
    // default config group, i.e. that the pipe shouldn't have
    // the metadata config-group property at all
    // the $ marker is not rendered in the list however
    configGroups[DEFAULT_CONFIG_GROUP_ID] = true;

    // Doing it this way to simply handle duplicates
    return Object.keys(configGroups);
  }
);

export const apiConfSelector = createSelector(
  (state: RootState) => state.subscription.url,
  (state: RootState) => state.subscription.token,
  (subUrl, token) => {
    return { subUrl, token };
  }
);

export const subIdSelector = (state: RootState) => state.subscription.id;
export const portalUrlSelector = (state: RootState) => state.globals.portalUrl;

export const recipientDisplayNameSelector = createSelector(
  (state: RootState) => state.subscription.members,
  (state: RootState) => state.subscription.availableRoles,
  (members, roles) => {
    let recipientDisplayNames: SomeObject = {};
    recipientDisplayNames['role'] = {};
    for (const role of roles) {
      recipientDisplayNames['role'][role.id] = role.name;
    }

    recipientDisplayNames['user_id'] = {};
    for (const member of members) {
      let memberDisplayName = member.user.name;
      if (member.user.email !== memberDisplayName) {
        memberDisplayName = `${memberDisplayName} (${member.user.email})`;
      }
      recipientDisplayNames['user_id'][member.user.user_id] = memberDisplayName;
    }

    return recipientDisplayNames;
  }
);

export const canEditVPN = createSelector(getCurrentSub, (sub) => {
  return sub.products.vpn && sub.provisioner_version === '2' ? true : false;
});

export const isProvisionerVersion2 = createSelector(getCurrentSub, (sub) => {
  const version = get(sub, 'provisioner_version');
  return version === '2';
});
