import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import PropTypes from 'prop-types';
import produce from 'immer';
import React from 'react';

import ErrorPanel from 'Common/ErrorPanel';
import { LoadingPanel } from 'Common/LoadingPanel';

import { getModelName, getModelType } from '../models-list/ModelsList';
import useResource from '../../hooks/useResource';
import KeyValue from '../key-value';

import './ModelOverviewStyle.css';

const ModelOverview = (props) => {
  const { subId, subUrl, token } = props;
  const modelId = props.params.modelId;
  // eslint-disable-next-line no-unused-vars
  const [model, loading, error, _refresh] = useResource(
    `${subUrl}/models/${modelId}?effective=true`,
    {
      credentials: 'include',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
    true,
    null
  );

  let cleanModel;
  if (model) {
    cleanModel = produce(model, (draft) => {
      Object.keys(draft).forEach((k) => {
        if (typeof draft[k] === 'object') {
          delete draft[k];
        }
      });
      draft.name = getModelName(draft._id);
      draft.type = getModelType(draft._id);
      draft['Entity types'] = (
        <SesamLink to={`/subscription/${subId}/browse/entity-types?Model.selected=${draft.name}`}>
          View
        </SesamLink>
      );
    });
  } else {
    cleanModel = null;
  }

  if (!modelId) {
    return <div>No model specified.</div>;
  }
  if (loading) {
    return <LoadingPanel loadingMessage={'Loading model.'} />;
  }
  if (error) {
    return <ErrorPanel title={'Error loading model.'} />;
  }
  return (
    <div className="model-overview">
      <KeyValue list={cleanModel} />
    </div>
  );
};

ModelOverview.propTypes = {
  params: PropTypes.shape({ modelId: PropTypes.string.isRequired }).isRequired,
  subUrl: PropTypes.string.isRequired,
  subId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    subUrl: state.subscription.url,
    token: state.subscription.token,
    subId: state.subscription.id,
  };
}

export default connect(mapStateToProps)(ModelOverview);
