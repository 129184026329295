import { makeStyles } from '@material-ui/core/styles';
import { getIndexWithoutDeleted, getCompleteLog, getIndexWithDeleted } from 'Internals/datasets';
import { withSeparators } from 'Internals/utils';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState, useMemo, useRef } from 'react';

import Button from 'Common/Button/Button';
import Clear from '@material-ui/icons/Clear';
import DatasetInspectorFilterJump from '../dataset-inspector/dataset-inspector-filter/DatasetInspectorFilterJump';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Popover from '@material-ui/core/Popover';
import Search from '@material-ui/icons/Search';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import SesamRadioGroupField from 'Common/SesamRadioGroupField/SesamRadioGroupField';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from 'Common/SesamTypography';
import Warning from '@material-ui/icons/Warning';
import { DatasetInspectorFilters } from 'Types/enums';

const useStyle = makeStyles((theme) => {
  return {
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& *': {
        marginLeft: '5px',
        marginRight: '5px',
        width: '50px',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '400px',
      padding: '15px',
      backgroundColor: theme.palette.background.light,
    },
    errorMessage: { color: theme.palette.error.main, marginRight: '10px' },
    input: {
      display: 'flex',
      flexDirection: 'column',
    },
    marginBottom: {
      marginBottom: '15px',
    },
    mockTextField: {
      background: 'transparent',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.divider}`,
      flex: 1,
      textAlign: 'left',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '12px',
      paddingTop: '3px',
      '&:focus': {
        outline: 0,
      },
    },
    mockTextFieldError: {
      borderColor: theme.palette.error.main,
    },
    searchField: {
      height: '40px',
    },
    searchFieldInfo: {
      color: theme.palette.info.main,
    },
    searchIcon: {
      marginTop: '5px',
      color: theme.palette.text.primary,
    },
    searchValue: {
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      marginRight: '5px',
      color: theme.palette.text.primary,
    },
  };
});

function DatasetNavigatorFilter(props) {
  const classes = useStyle();

  const {
    dataset,
    datasetIndexes,
    entitiesLength,
    filter,
    isFilterDirty,
    jumpSequenceValue,
    jumpType,
    jumpUpdatedValue,
    loading,
    populating,
    resetJump,
    setJumpSequenceValue,
    setJumpUpdatedValue,
    reset,
    resetSearch,
    search,
    searchType,
    searchValue,
    setFilter,
    setJumpType,
    setSearchType,
    setSearchValue,
    subset,
    ...restProps
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const mockInputRef = useRef(null);

  const browseOptions = useMemo(
    () => [
      {
        label: `Latest ${!subset ? `(${withSeparators(getIndexWithoutDeleted(dataset))})` : ''}`,
        value: DatasetInspectorFilters.LatestWithoutDeleted,
      },
      {
        label: `Latest w/ deleted ${
          !subset
            ? `(
        ${withSeparators(getIndexWithDeleted(dataset))})`
            : ''
        }`,
        value: DatasetInspectorFilters.LatestWithDeleted,
      },
      {
        label: `All ${!subset ? `(${withSeparators(getCompleteLog(dataset))})` : ''}`,
        value: DatasetInspectorFilters.CompleteLog,
      },
    ],
    [dataset]
  );

  const idsAreIndexed = useMemo(() => {
    for (const index of datasetIndexes) {
      if (index.expression === '"_S.$ids"') {
        return true;
      }
    }
  }, [datasetIndexes]);

  const showSubset = subset ? (
    <div className={clsx([classes.browse, classes.marginBottom])}>
      <Typography variant="subtitle2">Subset</Typography>
      <SesamTextField value={JSON.stringify(subset)} readOnly />
    </div>
  ) : null;

  let errorMsg;
  if (!loading && !populating && !isFilterDirty && entitiesLength === 0) {
    errorMsg = 'No entities found';
  }

  return (
    <div {...restProps}>
      <button
        ref={mockInputRef}
        className={clsx([classes.mockTextField, errorMsg && classes.mockTextFieldError])}
        onClick={() => setIsExpanded(true)}
      >
        {errorMsg && (
          <Tooltip title={errorMsg}>
            <Warning className={classes.errorMessage} />
          </Tooltip>
        )}
        <div className={classes.searchValue}>{searchValue}</div>
        <div className={classes.searchIcon}>
          <Search />
        </div>
      </button>
      <Popover
        open={isExpanded}
        anchorEl={mockInputRef.current}
        onClose={() => {
          setIsExpanded(false);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.container}>
          <form
            onSubmit={(ev) => {
              ev.preventDefault();
              search();
              setIsExpanded(false);
            }}
          >
            <div className={clsx([classes.search, classes.marginBottom])}>
              <Typography variant="subtitle2">Search</Typography>
              <div className={classes.input}>
                <SesamRadioGroupField
                  value={searchType}
                  onChange={(ev) => setSearchType(ev.target.value)}
                  radios={[
                    {
                      label: 'Text',
                      value: 'freetext',
                    },
                    {
                      label: 'Id',
                      value: 'id',
                    },
                  ]}
                  row
                />
                <OutlinedInput
                  autoFocus
                  className={classes.searchField}
                  onChange={(ev) => setSearchValue(ev.target.value)}
                  value={searchValue}
                  placeholder={
                    searchType === 'id'
                      ? 'Returns exact match on ID'
                      : 'Search for text inside an entity'
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" size="small" onClick={resetSearch}>
                        <Clear fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
                  startAdornment={
                    searchType === 'id' && !idsAreIndexed ? (
                      <InputAdornment position="start">
                        <Tooltip title="Only the _id property will be searched since this dataset does not index the $ids property.">
                          <InfoOutlined className={classes.searchFieldInfo} />
                        </Tooltip>
                      </InputAdornment>
                    ) : null
                  }
                />
              </div>
            </div>
            <div className={clsx([classes.search, classes.marginBottom])}>
              <Typography variant="subtitle2">Jump</Typography>
              <div className={classes.input}>
                <SesamRadioGroupField
                  value={jumpType}
                  onChange={(ev) => setJumpType(ev.target.value)}
                  radios={[
                    {
                      label: 'Sequence',
                      value: 'sequence',
                    },
                    {
                      label: 'Updated',
                      value: 'updated',
                    },
                  ]}
                  row
                />
                <DatasetInspectorFilterJump
                  jumpSequenceValue={jumpSequenceValue}
                  jumpType={jumpType}
                  jumpUpdatedValue={jumpUpdatedValue}
                  onJumpReset={resetJump}
                  onJumpSequenceValueChanged={setJumpSequenceValue}
                  onJumpUpdatedValueChanged={setJumpUpdatedValue}
                  disabled={
                    filter === DatasetInspectorFilters.CompleteLog &&
                    searchType === 'id' &&
                    searchValue.length > 0
                  }
                />
              </div>
            </div>
            <div className={clsx([classes.browse, classes.marginBottom])}>
              <Typography variant="subtitle2">Browse</Typography>
              <SesamTextField select value={filter} onChange={(ev) => setFilter(ev.target.value)}>
                {browseOptions.map((bo) => (
                  <SesamMenuItem key={bo.label} value={bo.value}>
                    {bo.label}
                  </SesamMenuItem>
                ))}
              </SesamTextField>
            </div>
            {showSubset}
            <div className={classes.actions}>
              <Button onClick={reset}>Reset</Button>
              <Button type="submit" theme="primary">
                Search
              </Button>
            </div>
          </form>
        </div>
      </Popover>
    </div>
  );
}

DatasetNavigatorFilter.propTypes = {
  dataset: PropTypes.shape({}),
  datasetIndexes: PropTypes.arrayOf(PropTypes.shape({})),
  entitiesLength: PropTypes.number.isRequired,
  filter: PropTypes.string.isRequired,
  isFilterDirty: PropTypes.bool.isRequired,
  jumpSequenceValue: PropTypes.number,
  jumpType: PropTypes.string.isRequired,
  jumpUpdatedValue: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  populating: PropTypes.bool.isRequired,
  resetJump: PropTypes.func.isRequired,
  setJumpSequenceValue: PropTypes.func,
  setJumpUpdatedValue: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  searchType: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  setJumpType: PropTypes.func.isRequired,
  setSearchType: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  subset: PropTypes.shape([]),
};

export default DatasetNavigatorFilter;
