import React from 'react';

import CenteredLayout from '../layout/centered';
import NotFound from '../components/not-found';

function NotFoundPage() {
  return (
    <CenteredLayout>
      <NotFound />
    </CenteredLayout>
  );
}

export default NotFoundPage;
