import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import moment from 'moment';
import set from 'lodash/set';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../alert';
import AlertActions from 'Redux/thunks/alerts';
import Button from 'Common/Button/Button';
import ExpansionPanel from '../expansion-panel';
import Feedback from '../feedback/';
import InProgress from '../in-progress';
import { LinkButton } from 'Common/LinkButton/LinkButton';
import PipeNotificationActions from 'Redux/thunks/pipe-notifications';
import renderRuleForm from './forms/renderRuleForm';
import validationSchema from './forms/validationSchema';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import { Form, FormActions } from 'Common/forms';
import { LoadingPanel } from 'Common/LoadingPanel';
import { PipeNotificationRule } from '../../types/notifications';
import { RootState } from '../..';
import { toastAdded } from 'Redux/thunks/global';

interface EditPipeNotificationRuleFormProps {
  pipeId: string;
  rule: PipeNotificationRule;
  subId: string;
}

const EditPipeNotificationRuleForm: React.FC<EditPipeNotificationRuleFormProps> = ({
  rule,
  pipeId,
  subId,
}) => {
  const [alertsDialoOpen, setAlertsDialogOpen] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [loadingAlerts, setLoadingAlerts] = useState<boolean>(false);

  const alerts = useSelector((state: RootState) =>
    state.alerts.filter((alert) => alert.notification_rule_id === rule.id)
  );
  const alertsLoaded = useSelector((state: RootState) => state.simpleSettings.alertsLoaded);
  const alertsShownSince = useSelector((state: RootState) => state.simpleSettings.alertsShownSince);

  const dispatch = useDispatch();
  const addToast = (message: string) => dispatch(toastAdded({ type: 'success', message }));
  const deleteRule = (ruleId: string) =>
    dispatch(PipeNotificationActions.delete(subId, pipeId, ruleId));
  const loadAlerts = () => dispatch(AlertActions.load());
  const loadOlderAlerts = () => dispatch(AlertActions.loadOlder());
  const updateNotificationRule = (rule: PipeNotificationRule) =>
    dispatch(PipeNotificationActions.edit(subId, pipeId, rule.id, rule));

  function loadOlder() {
    setLoadingAlerts(true);
    loadOlderAlerts().then(() => {
      setLoadingAlerts(false);
    });
  }

  useEffect(() => {
    loadAlerts();
  }, []);

  return (
    <ExpansionPanel title={rule.name || rule.id} initiallyExpanded={false}>
      <SesamTextField label="Rule type" disabled value={rule.type} margin="normal" />
      <Formik
        initialValues={rule}
        onSubmit={(rule) =>
          updateNotificationRule(rule).then(() => addToast('Notification rule updated!'))
        }
        enableReinitialize
        validationSchema={validationSchema}
        validate={(values) => {
          /**
           * Becayse Yup's .when() won't work with Formik, we have to specify
           * conditional validation here.
           */
          const errors = {};
          if (
            values.type === 'pump_completed_value_too_high_over_time' ||
            values.type === 'pump_completed_value_too_low_over_time' ||
            values.type === 'pump_completed_value_too_high' ||
            values.type === 'pump_completed_value_too_low'
          ) {
            if (!values.extra_rule_info.parameter) {
              set(errors, ['extra_rule_info', 'parameter'], 'This field is required');
            }
            if (!values.extra_rule_info.limit) {
              set(errors, ['extra_rule_info', 'limit'], 'This field is required');
            }
          }
          if (
            values.type === 'pump_completed_value_too_high_over_time' ||
            values.type === 'pump_completed_value_too_low_over_time'
          ) {
            if (!values.extra_rule_info.interval) {
              set(errors, ['extra_rule_info', 'interval'], 'This field is required');
            }
          }
          if (values.type === 'pattern_match') {
            if (!values.extra_rule_info.event_type) {
              set(errors, ['extra_rule_info', 'event_type'], 'This field is required');
            }
            if (!values.extra_rule_info.parameter) {
              set(errors, ['extra_rule_info', 'parameter'], 'This field is required');
            }
            if (!values.extra_rule_info.pattern) {
              set(errors, ['extra_rule_info', 'pattern'], 'This field is required');
            }
          }

          return errors;
        }}
      >
        {(props) => {
          return (
            <div>
              <Form margin={false} useFormik>
                {props.values.type && renderRuleForm(props.values.type, props)}
                <FormActions>
                  <LinkButton
                    type="button"
                    onClick={() => {
                      setAlertsDialogOpen(true);
                    }}
                  >
                    Show generated alerts
                  </LinkButton>
                  <Button
                    theme="danger"
                    type="button"
                    onClick={() => {
                      setDeleting(true);
                      deleteRule(rule.id);
                    }}
                    loading={deleting}
                    loadingText="Deleting"
                    disabled={deleting}
                  >
                    Delete
                  </Button>
                  <Button
                    type="submit"
                    loading={props.isSubmitting}
                    loadingText="Saving"
                    disabled={!props.dirty || !props.isValid || props.isSubmitting}
                  >
                    Save
                  </Button>
                </FormActions>
              </Form>
            </div>
          );
        }}
      </Formik>
      <Dialog open={alertsDialoOpen}>
        <DialogContent>
          <h3 className="heading-section">
            Alerts generated by this rule{' '}
            <small>
              (since {moment(alertsShownSince).format('YYYY-MM-DD')}) —{' '}
              <LinkButton disabled={loadingAlerts} onClick={loadOlder}>
                load older
              </LinkButton>
              {loadingAlerts && <InProgress size="small" />}
            </small>
          </h3>
          {alertsLoaded && alerts.length > 0 && (
            <ul className="expansion-panel-list">
              {alerts.map((alert) => (
                <li key={alert.notification_id}>
                  <Alert {...alert} />
                </li>
              ))}
            </ul>
          )}
          {alertsLoaded && alerts.length === 0 && (
            <Feedback>
              This rule hasn&apos;t generated any alerts since (at least){' '}
              {moment(alertsShownSince).format('YYYY-MM-DD')}{' '}
              <small>
                {' '}
                —{' '}
                <LinkButton disabled={loadingAlerts} onClick={loadOlder}>
                  load older
                </LinkButton>
              </small>
            </Feedback>
          )}
          {!alertsLoaded && <LoadingPanel size="medium" />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAlertsDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ExpansionPanel>
  );
};

export default EditPipeNotificationRuleForm;
