import get from 'lodash/get';
import { isValidConfig, isRunError } from 'Internals/pipes';
import { Pipe, System } from 'Types/types';

import './OverviewStyle.css';

export const isNotInternal = (system: System) => !system.isInternal;

const endpointTypes = ['http_endpoint', 'xml_endpoint', 'csv_endpoint', 'excel_endpoint'];

interface EndpointNode {
  pipesIn: number;
  pipesOut: number;
  direction: 'inbound' | 'outbound' | 'both';
  isInboundError: boolean;
  isOutboundError: boolean;
  pipes: string[];
}

type Accumulator = {
  [key: string]: EndpointNode;
};

export const coalescePipesIntoEndpointNodes = (acc: Accumulator, p: Pipe) => {
  const sourceType = get(p, 'config.effective.source.type');
  const isConfigError = !isValidConfig(p);
  const isRunErr = isRunError(p);
  if (endpointTypes.includes(sourceType)) {
    if (acc[sourceType]) {
      if (acc[sourceType]?.pipesOut) {
        acc[sourceType].pipesOut = acc[sourceType].pipesOut + 1;
      } else {
        acc[sourceType] = {
          ...acc[sourceType],
          pipesOut: 1,
        };
      }
      if (acc[sourceType]?.pipes) {
        acc[sourceType].pipes.push(p['_id']);
      } else {
        acc[sourceType] = {
          ...acc[sourceType],
          pipes: [p['_id']],
        };
      }
      if (acc[sourceType]?.direction === 'outbound') {
        acc[sourceType].direction = 'both';
      }
      acc[sourceType] = {
        ...acc[sourceType],
        isInboundError: acc[sourceType].isInboundError || isConfigError || isRunErr,
      };
    } else {
      acc[sourceType] = {
        pipesIn: 0,
        pipesOut: 1,
        direction: 'inbound',
        isInboundError: isConfigError || isRunErr,
        isOutboundError: false,
        pipes: [p['_id']],
      };
    }
  }
  const sinkType = get(p, 'config.effective.sink.type');
  if (endpointTypes.includes(sinkType)) {
    if (acc[sinkType]) {
      if (acc[sinkType].pipesIn) acc[sinkType].pipesIn = acc[sinkType].pipesIn + 1;
      else acc[sinkType].pipesIn = 1;
      if (acc[sinkType].pipes) acc[sinkType].pipes.push(p['_id']);
      else acc[sinkType].pipes = [p['_id']];
      if (acc[sinkType].direction === 'inbound') acc[sinkType].direction = 'both';
      acc[sinkType].isOutboundError = acc[sinkType].isOutboundError || isConfigError || isRunErr;
    } else {
      acc[sinkType] = {
        pipesOut: 0,
        pipesIn: 1,
        direction: 'outbound',
        isOutboundError: isConfigError || isRunErr,
        isInboundError: false,
        pipes: [p['_id']],
      };
    }
  }
  return acc;
};

export type Group = {
  id: string;
  systems: System[];
};

export const groupSystems = (groups: { [key: string]: Group }, system: System) => {
  const groupName = system.systemGroup;
  const existingGroup = groups[groupName];

  if (existingGroup) {
    existingGroup.systems.push(system);
  } else {
    groups[groupName] = {
      id: groupName,
      systems: [system],
    };
  }
  return groups;
};
