import { makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { Link } from 'react-router';

const useStyle = makeStyles((theme: any) => {
  return {
    link: {
      color: theme.palette.text.brand,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.text.brandActive,
      },
    },
    buttonLink: {
      color: theme.palette.text.brand,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.text.brandActive,
        textDecoration: 'none',
      },
    },
  };
});

interface SesamLinkProps {
  className?: string;
  to: string;
  buttonLink?: boolean;
  children: ReactNode;
}

function SesamLink({ className, to, buttonLink, ...props }: SesamLinkProps) {
  const classes = useStyle();
  let fullClassName = buttonLink ? classes.buttonLink : classes.link;
  if (className) {
    fullClassName = fullClassName + ' ' + className;
  }
  return <Link className={fullClassName} to={to} {...props} />;
}

export default SesamLink;
