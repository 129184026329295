import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import produce from 'immer';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import ModelActions from 'Redux/thunks/models';
import { LoadingPanel } from 'Common/LoadingPanel';

import KeyValue from '../key-value';
import useResource from '../../hooks/useResource';
import EntityTypePropertiesList from '../entity-type-properties-list/EntityTypePropertiesList';
import './PipeInferredSchemaStyle.css';

function getDefinitionIdFromRef(refVal) {
  let definitionId;
  if (typeof refVal === 'string') {
    const refValParts = refVal.split('/');
    definitionId = refValParts[refValParts.length - 1];
  }
  return definitionId;
}

function parseJsonSchemaDefinition(val) {
  if (typeof val === 'object') {
    if (val.subtype) {
      return val.subtype;
    }
    if (val.anyOf && Array.isArray(val.anyOf)) {
      const result = val.anyOf.map((x) => {
        if (x.subtype) return x.subtype;
        if (x.items) {
          // It's an array, get the type of items
          let type = x.items.type;
          if (x.items.subtype) {
            type = x.items.subtype;
          }
          return `array<${type}>`;
        }
        if (x.type) return x.type;
      });

      if (result.length !== 0) return [...new Set(result)].join(' | ');
    }
    if (val.type) {
      return val.type;
    }
  }
  return 'Unknown type';
}

const PipeInferredSchema = (props) => {
  const { subUrl, token, pipe } = props;
  const entityTypeId = get(pipe, '_id');

  const allEntityTypeIds = {};
  for (const m of props.models) {
    const modelEntityTypes = m['entity_types'];
    if (modelEntityTypes && isObject(modelEntityTypes)) {
      for (const entityTypeId of Object.keys(modelEntityTypes)) {
        allEntityTypeIds[entityTypeId] = true;
      }
    }
  }
  const hasPipeSinkEntityType = allEntityTypeIds[props.pipe._id];

  if (!hasPipeSinkEntityType) {
    props.router.push(`/subscription/${props.subId}/pipes/pipe/${props.pipe._id}/dashboard`);
    return null;
  }

  const modelId = useMemo(() => {
    const pipeId = get(props.pipe, '_id');
    if (pipeId && !isEmpty(props.models)) {
      const model = props.models.find(
        (curr) =>
          curr['entity_types'] && Object.keys(curr['entity_types']).some((_id) => _id === pipeId)
      );
      if (model) return model['_id'];
    }
  }, [props.models, props.pipe]);

  // eslint-disable-next-line no-unused-vars
  const [entityType, loading, error, _refresh] = useResource(
    `${subUrl}/models/${modelId}/${entityTypeId}?effective=true`,
    {
      credentials: 'include',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
    true,
    null
  );

  let cleanEntityType = {};
  let properties = [];
  if (entityType) {
    cleanEntityType = produce(entityType, (draft) => {
      Object.keys(draft).forEach((k) => {
        if (typeof draft[k] === 'object') {
          delete draft[k];
        }
      });
      delete draft.additionalProperties;
      delete draft.type;
    });
    properties = Object.keys(entityType.properties).reduce((acc, propertyName) => {
      const obj = {};
      obj['name'] = propertyName;
      const propertyInfo = entityType.properties[propertyName];
      let propertyType = {};
      if (propertyInfo['$ref']) {
        const definitionName = getDefinitionIdFromRef(propertyInfo['$ref']);
        propertyType = entityType.definitions[definitionName];
      } else if (propertyInfo.type) {
        propertyType = propertyInfo;
      }

      obj['type'] = parseJsonSchemaDefinition(propertyType);
      acc.push(obj);
      return acc;
    }, []);
  }

  useEffect(() => {
    const refresh = () => {
      props.loadAllModels();
    };
    props.registerRefresh(refresh);

    return () => {
      props.unregisterRefresh(refresh);
    };
  });

  if (!entityTypeId) {
    return <div>No entity type specified.</div>;
  }
  if (loading) {
    return <LoadingPanel loadingMessage={'Loading entity type.'} />;
  }
  if (error) {
    return <div>Error loading entity type.</div>;
  }

  return (
    <div className="entity-type-overview">
      <div className="entity-type-overview__key-value">
        <KeyValue list={cleanEntityType} />
      </div>
      <b className="entity-type-overview__properties-label">Properties:</b>
      <div className="entity-type-overview__properties-list">
        <EntityTypePropertiesList properties={properties} />
      </div>
    </div>
  );
};

PipeInferredSchema.propTypes = {
  subId: PropTypes.string.isRequired,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  params: PropTypes.shape({
    pipeID: PropTypes.string.isRequired,
  }).isRequired,
  pipe: PropTypes.object.isRequired,
  models: PropTypes.array,
  registerRefresh: PropTypes.func.isRequired,
  unregisterRefresh: PropTypes.func.isRequired,
  loadAllModels: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  let pipe;
  if (ownProps.pipe) {
    pipe = ownProps.pipe;
  } else if (get(ownProps, 'params.pipeID')) {
    pipe = state.pipes[ownProps.params.pipeID];
  }

  return {
    pipe,
    subId: state.subscription.id,
    subUrl: state.subscription.url,
    token: state.subscription.token,
    models: state.models,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAllModels: () => dispatch(ModelActions.loadAll()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(PipeInferredSchema);
