import React, { useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SystemActions from 'Redux/thunks/systems';
import { PermissionsEditor } from 'Common/PermissionsEditor';
import useAsync from '../../../hooks/useAsync';
import SystemsParent from './SystemsParent';

function SystemsPrototypePermissions(props) {
  const registeredRefreshes = useRef([]);

  const _refresh = useCallback(() => {
    props.loadAll();
    registeredRefreshes.current.forEach((cb) => cb());
  }, [props]);

  const [refresh] = useAsync(_refresh);

  const handleRefresh = () => {
    refresh();
    console.log('refresh clicked!');
  };

  const registerRefresh = useCallback((func) => {
    registeredRefreshes.current.push(func);
  }, []);

  const unregisterRefresh = useCallback((func) => {
    registeredRefreshes.current = registeredRefreshes.current.filter((c) => c !== func);
  }, []);

  return (
    <SystemsParent onRefresh={handleRefresh}>
      <div className="scrollArea">
        <p>These settings will be inherited as default settings by all systems.</p>
        <PermissionsEditor
          permission="system-prototype"
          registerRefresh={registerRefresh}
          unregisterRefresh={unregisterRefresh}
        />
      </div>
    </SystemsParent>
  );
}

SystemsPrototypePermissions.propTypes = {
  loadAll: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    loadAll: () => dispatch(SystemActions.loadAll()),
  };
}

export default connect(null, mapDispatchToProps)(SystemsPrototypePermissions);
