import { EnvVarsDict } from '../../api/vars';
import { createReducer } from '@reduxjs/toolkit';
import { envVarLoadedAll, envVarReplacedAll, envVarReplaced, envVarRemoved } from '../thunks/vars';
import { disconnected } from '../thunks/subscriptions';

type EnvVarState = EnvVarsDict;
const initialState: EnvVarState = {};

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
export default createReducer(initialState, (builder) =>
  //@ts-ignore not sure why this error happens
  builder
    .addCase(envVarLoadedAll, (state, action) => {
      return action.payload;
    })
    .addCase(envVarReplacedAll, (state, action) => {
      return action.payload;
    })
    .addCase(envVarReplaced, (state, { payload }) => {
      state[payload.name] = payload.value;
    })

    .addCase(envVarRemoved, (state, action) => {
      delete state[action.payload];
    })
    .addCase(disconnected, () => initialState)
);
