import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import get from 'lodash/get';
import Delete from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import SesamCheckboxCompact from 'Common/SesamCheckboxCompact/SesamCheckboxCompact';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import { recipientDisplayNameSelector } from 'Redux/selectors';
import { RoleInfo, SomeObject, SubscriptionMemberInfo, Theme } from '../../types/types';

// TODO: Remove old recipients editor

export type Recipient = {
  id: string;
  type: string;
  methods: string[];
};

type DecoratedRecipient = {
  value: Recipient;
  text: string;
  tooltip: string | null;
  group: string;
};

interface RecipientsEditorProps {
  availableRoles: RoleInfo[];
  members: SubscriptionMemberInfo[];
  recipients: Recipient[];
  onChange: (recipients: Recipient[]) => void;
  TextFieldProps: SomeObject;
}

const useStyle = makeStyles((theme: Theme) => {
  return {
    centerColumn: {
      textAlign: 'center',
    },
    deleteIcon: {
      color: theme.palette.error.dark,
    },
    noRecipientsMsg: {
      border: `1px solid ${theme.palette.divider}`,
      padding: '11px',
    },
    table: {
      width: '100%',
      border: `1px solid ${theme.palette.divider}`,
    },
  };
});

const RecipientsEditor: React.FC<RecipientsEditorProps> = (props) => {
  const { recipients, onChange, TextFieldProps, ...restProps } = props;
  const classes = useStyle();

  const [inputValue, setInputValue] = useState('');

  const recipientDisplayNames = useSelector(recipientDisplayNameSelector);
  const availableRoles = useSelector((state) => state.subscription.availableRoles);
  const members = useSelector((state) => state.subscription.members);

  let availableRecipients: DecoratedRecipient[] = [];

  const recipientIds = recipients.map((recipient: Recipient) => recipient.id);

  members.forEach((member: SubscriptionMemberInfo) => {
    if (member.user) {
      if (member.roles && !member.roles.includes('group:Invited')) {
        const userId = member.user.user_id;
        let name = member.user.name;
        if (!recipientIds.includes(userId)) {
          if (member.user.email !== name) {
            name = `${name} (${member.user.email})`;
          }
          availableRecipients.push({
            value: {
              id: userId,
              type: 'user_id',
              methods: ['portal', 'email'],
            },
            text: name,
            tooltip: null,
            group: 'Users',
          });
        }
      }
    }
  });

  availableRoles.forEach((role: RoleInfo) => {
    const roleId = role.id;
    if (!role['is-internal-role'] && !recipientIds.includes(roleId)) {
      availableRecipients.push({
        value: {
          id: roleId,
          type: 'role',
          methods: ['portal', 'email'],
        },
        text: role.name,
        tooltip: role.description,
        group: 'Roles',
      });
    }
  });

  function getRecipientDisplayName(recipient: Recipient) {
    let displayName = get(recipientDisplayNames, recipient.type, {})[recipient.id];
    if (!displayName) {
      displayName = recipient.id;
    }
    return displayName;
  }

  function getRecipientTooltip(recipient: Recipient) {
    const displayName = getRecipientDisplayName(recipient);
    let toolTip = null;
    if (recipient.type === 'role') {
      toolTip = `This is the "${displayName}" role. All members with this role will receive a notification when this rule triggers.`;
    }
    if (recipient.type === 'user_id') {
      toolTip = `This is "${displayName}" user.`;
    }
    return toolTip;
  }

  function toggleRecipientMethod(recipientId: string, method: string) {
    const updatedRecipients = recipients.map((recipient: Recipient) => {
      const updatedRecipient = { ...recipient };
      const updatedRecipientMethods = [...recipient.methods];

      if (recipient.id === recipientId) {
        const methodIdx = recipient.methods.indexOf(method);
        if (methodIdx === -1) {
          updatedRecipientMethods.push(method);
        } else {
          updatedRecipientMethods.splice(methodIdx, 1);
        }
        updatedRecipient.methods = updatedRecipientMethods;
      }

      return updatedRecipient;
    });

    onChange(updatedRecipients);
  }

  function deleteRecipient(recipientId: string) {
    const updatedRecipients = recipients.filter((recipient: Recipient) => {
      if (recipient.id !== recipientId) {
        return recipient;
      }
    });

    onChange(updatedRecipients);
  }

  return (
    <div {...restProps}>
      {recipients.length === 0 && (
        <div className={classes.noRecipientsMsg}>No recipients selected</div>
      )}
      {recipients.length > 0 && (
        <table className={classes.table}>
          <thead className="table__header">
            <tr>
              <th className="table__header-col">Recipient</th>
              <th className={clsx([classes.centerColumn, 'table__header-col'])}>Portal</th>
              <th className={clsx([classes.centerColumn, 'table__header-col'])}>Email</th>
              <th className={clsx([classes.centerColumn, 'table__header-col'])} />
            </tr>
          </thead>
          <tbody>
            {recipients.map((recipient: Recipient, idx: number) => {
              return (
                <tr key={recipient.id} className="table__row">
                  <td className="table__data" style={{ whiteSpace: 'normal' }}>
                    {getRecipientDisplayName(recipient)}
                  </td>
                  <td
                    className={clsx([classes.centerColumn, 'table__data'])}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <SesamCheckboxCompact
                      size="small"
                      checked={recipient.methods.indexOf('portal') !== -1}
                      onChange={() => {
                        toggleRecipientMethod(recipient.id, 'portal');
                      }}
                    />
                  </td>
                  <td
                    className={clsx([classes.centerColumn, 'table__data'])}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <SesamCheckboxCompact
                      size="small"
                      checked={recipient.methods.indexOf('email') !== -1}
                      onChange={() => {
                        toggleRecipientMethod(recipient.id, 'email');
                      }}
                    />
                  </td>
                  <td
                    className={clsx([classes.centerColumn, 'table__data'])}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <Delete
                      className={classes.deleteIcon}
                      onClick={() => {
                        deleteRecipient(recipient.id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <Autocomplete
        options={availableRecipients}
        inputValue={inputValue}
        value={null}
        onInputChange={(ev, val) => setInputValue(val)}
        groupBy={(recipient: DecoratedRecipient) => recipient.group}
        getOptionLabel={(recipient: DecoratedRecipient) => recipient.text}
        renderInput={(params) => (
          <SesamTextField
            {...TextFieldProps}
            {...params}
            placeholder="Start typing, or click the arrow to the left"
          />
        )}
        onChange={(ev: React.ChangeEvent, recipient: DecoratedRecipient) => {
          if (recipient !== null) {
            onChange([...recipients, recipient.value]);
            setInputValue('');
          }
        }}
      />
    </div>
  );
};

export default RecipientsEditor;
