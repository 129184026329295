import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ActionBar from '../../../components/action-bar';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import { sfetchDownload } from 'Internals/sfetch';
import { getGdprMsUrl } from '../gdpr/util';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

class GdprChangeRequest extends React.Component {
  constructor(props) {
    super();

    this.state = {
      message: '',
      email: props.location.query.email || props.userEmail,
    };

    this.download = (ev) => {
      ev.preventDefault();

      if (!this.state.email) {
        this.setState({
          message: 'Please enter the email of the contact person',
        });
        return;
      }

      const requestOptions = {
        credentials: 'include',
        headers: { Authorization: `bearer ${this.props.token}` },
      };

      const url = `${
        this.props.gdprMicroserviceURL
      }/change-requests?contact=${this.state.email.trim()}`;

      sfetchDownload(url, requestOptions)
        .then(() => {
          this.setState({ message: '' });
        })
        .catch((e) => {
          this.setState({ message: 'Something went wrong. Please try again.' });
        });
    };

    this.downloadAll = (ev) => {
      ev.preventDefault();

      const requestOptions = {
        credentials: 'include',
        headers: { Authorization: `bearer ${this.props.token}` },
      };

      const url = `${this.props.gdprMicroserviceURL}/change-requests?contact=*`;

      sfetchDownload(url, requestOptions)
        .then(() => {
          this.setState({ message: '' });
        })
        .catch((e) => {
          this.setState({ message: 'Something went wrong. Please try again.' });
        });
    };

    this.handleEmailChange = (ev) => {
      this.setState({
        email: ev.target.value,
      });
    };
  }

  render() {
    return (
      <main className="scrollArea">
        <h1 className="heading-section">Handle Change Requests</h1>
        <div className="row">
          <div className="col col--elastic gr-primary">
            <Form onSubmit={this.download} standout>
              <SesamTextField
                label="Enter contactperson email"
                id="contact-person-change-request-email"
                onChange={this.handleEmailChange}
                value={this.state.email}
                margin="normal"
              />
              <FormActions>
                <ActionBar shortMessage={this.state.message}>
                  <Button type="submit">Download change requests</Button>
                  <Button onClick={this.downloadAll}>Download all change requests</Button>
                </ActionBar>
              </FormActions>
            </Form>
          </div>
        </div>
      </main>
    );
  }
}

GdprChangeRequest.propTypes = {
  token: PropTypes.string.isRequired,
  gdprMicroserviceURL: PropTypes.string.isRequired,
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    userEmail: state.user.email,
    token: state.subscription.token,
    gdprMicroserviceURL: getGdprMsUrl(state),
  };
}

export default withRouter(connect(mapStateToProps)(GdprChangeRequest));
