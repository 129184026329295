import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import React, { useEffect, useState } from 'react';

import SesamLink from 'Common/Links/SesamLink';
import Recaptcha from 'react-google-recaptcha';
import { Form } from 'Common/forms';
import Actions from 'Redux/thunks/auth';
import Button from 'Common/Button/Button';
import CenteredLayout from '../layout/centered';
import Feedback from '../components/feedback';
import FocusPanel from '../components/focus-panel';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import PortalAPI from '../api/portal';
import useInputValue from '../hooks/useInputValue';
import ThirdPartyLogin from './components/third-party-login/ThirdPartyLogin';
import { TestID } from '../testID';

import './LoginStyle.css';

const Login = (props) => {
  const [authenticationProviders, setAuthenticationProviders] = useState([]);
  const [captcha, setCaptcha] = useState(false);
  const email = useInputValue('');
  const [isFormValid, setNotValid] = useState(false);
  const password = useInputValue('');
  const [pleaseWait, setPleaseWait] = useState(false);

  function captchaDone(response) {
    setCaptcha(response);
  }

  function getAuthenticationProviders() {
    PortalAPI.getStatus(props.portalUrl)
      .then((jsonData) => {
        if (jsonData.authentication_providers) {
          setAuthenticationProviders(jsonData.authentication_providers);
        }
      })
      .catch((error) => {
        console.log('request failed', error);
      });
  }

  function handleLoginSuccess() {
    const location = props.location;
    const redirect = location.query.redirect;
    if (redirect && !redirect.startsWith('/auth/login')) {
      props.redirect({
        pathname: redirect,
        query: (location.state && location.state.query) || null,
      });
    } else {
      props.welcome();
    }
  }

  function handlePossibleSSORedirect(url) {
    if (url) {
      window.location.href = url;
      // cancel promise
      throw new Error('Redirecting');
    } else {
      // continue with normal login
      return;
    }
  }

  function login(event) {
    event.preventDefault();
    const isCredentialMissing = !email.value || !password.value;

    setNotValid(isCredentialMissing);

    if (!isCredentialMissing) {
      setPleaseWait(true);
      props
        .attemptLogin(email.value, password.value, captcha)
        .then(handlePossibleSSORedirect)
        .then(handleLoginSuccess)
        .catch(() => {
          setCaptcha(null);
          setPleaseWait(false);
        });
    }
  }

  useEffect(() => {
    getAuthenticationProviders();
  }, []);

  const loc = props.location;
  const invite = loc.state && loc.state.query && loc.state.query.invite;

  return (
    <CenteredLayout>
      <FocusPanel>
        <div className="login" data-testid={TestID.LoginForm}>
          <div className="login__header">Please log in using</div>
          {invite && (
            <div className="login__invite">
              <p className="text-remark">
                <strong>You have been invited</strong> to join the subscription{' '}
                <span className="force-wrap">
                  &quot;
                  {invite}
                  &quot;
                </span>
              </p>
            </div>
          )}
          <div className="login__methods">
            {authenticationProviders.length > 0 && (
              <React.Fragment>
                <div className="login__third-party">
                  <ThirdPartyLogin providers={authenticationProviders} location={props.location} />{' '}
                </div>
                <div className="login__or">or</div>
              </React.Fragment>
            )}
            <div className="login__username-password">
              <Form onSubmit={login}>
                <SesamTextField
                  inputProps={{ autoFocus: true }}
                  label="Email"
                  {...email}
                  data-testid={TestID.LoginUserNameInput}
                />
                <SesamTextField
                  label="Password"
                  type="password"
                  margin="normal"
                  {...password}
                  data-testid={TestID.LoginPasswordInput}
                />
                {props.captchaRequired && (
                  <Recaptcha
                    id="captcha"
                    onChange={captchaDone}
                    sitekey="6Lcsr8IZAAAAAJQoK5c_BTn0VTf10d4ur6LLuD7g"
                    data-testid={TestID.LoginRecaptcha}
                  />
                )}
                <div className="login__action-bar">
                  <SesamLink className="login__forgot-password" to="/auth/forgot-password">
                    Forgot password?
                  </SesamLink>
                  <Button
                    loading={pleaseWait}
                    onClick={login}
                    type="submit"
                    disabled={props.captchaRequired && !captcha}
                    theme="primary"
                    data-testid={TestID.LoginButton}
                  >
                    Log in
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="login__warning">
            {isFormValid && (
              <Feedback type="warning" data-testid={TestID.LoginEmailAndPasswordRequired}>
                Email and password are required
              </Feedback>
            )}
            {props.captchaRequired && !captcha && (
              <Feedback type="warning" data-testid={TestID.LoginErrorMessageCaptchaRequired}>
                Captcha is required
              </Feedback>
            )}
            {props.loginFailed && (
              <Feedback type="warning" data-testid={TestID.LoginWrongCredential}>
                Invalid username or password
              </Feedback>
            )}
          </div>
          <div className="login__sign-up">
            Need an account? <SesamLink to="/auth/signup">Sign up here!</SesamLink>
          </div>
        </div>
      </FocusPanel>
    </CenteredLayout>
  );
};

Login.propTypes = {
  attemptLogin: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  welcome: PropTypes.func.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      redirect: PropTypes.string,
    }).isRequired,
  }).isRequired,
  loginFailed: PropTypes.bool.isRequired,
  captchaRequired: PropTypes.bool.isRequired,
  portalUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  loginFailed: state.user.loginFailed,
  captchaRequired: state.user.captchaRequired,
  portalUrl: state.globals.portalUrl,
});
const mapDispatchToProps = (dispatch) => ({
  attemptLogin: (username, password, captcha) =>
    dispatch(Actions.attemptLogin(username, password, captcha)),
  redirect: (location) => dispatch(push(location)),
  welcome: () => dispatch(push('/')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
