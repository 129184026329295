import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import './style.css';

class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnChange = (ev) => {
      const val = ev.target.checked;
      if (typeof this.props.onToggle === 'function') this.props.onToggle(val);
      if (val) {
        if (typeof this.props.onSwitchOn === 'function') this.props.onSwitchOn();
      } else {
        if (typeof this.props.onSwitchOff === 'function') this.props.onSwitchOff();
      }
      this.setState({ isOn: val });
    };

    this.state = {
      isOn: this.props.isOn,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOn !== this.props.isOn) {
      this.setState({ isOn: this.props.isOn });
    }
  }

  render() {
    const classNames = ['toggle-switch', `toggle-switch--is-${this.state.isOn ? 'on' : 'off'}`];

    if (this.props.inline) {
      classNames.push('toggle-switch--inline');
    }

    return (
      <label>
        <span className={classNames.join(' ')}>
          <span
            className="toggle-switch__off"
            style={{ color: this.props.theme.palette.text.primary }}
          >
            {this.props.labelOff}
          </span>
          <input
            checked={this.state.isOn}
            className="toggle-switch__checkbox"
            id={this.props.id}
            onChange={this.handleOnChange}
            type="checkbox"
          />
          <span
            className="toggle-switch__on"
            style={{ color: this.props.theme.palette.text.primary }}
          >
            {this.props.labelOn}
          </span>
        </span>
      </label>
    );
  }
}

ToggleSwitch.propTypes = {
  id: PropTypes.string,
  isOn: PropTypes.bool,
  inline: PropTypes.bool,
  labelOff: PropTypes.string,
  labelOn: PropTypes.string,
  onSwitchOff: PropTypes.func,
  onSwitchOn: PropTypes.func,
  onToggle: PropTypes.func,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      text: PropTypes.shape({ primary: PropTypes.string }),
    }),
  }),
};

ToggleSwitch.defaultProps = {
  inline: false,
  labelOff: 'Off',
  labelOn: 'On',
  onSwitchOff: null,
  onSwitchOn: null,
  onToggle: null,
};

export default withTheme(ToggleSwitch);
