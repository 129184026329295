import sfetch, { sfetchJson, sfetchText } from 'Internals/sfetch';
import { ApiConf } from '../types/types';
import { Json } from '../types/json';

async function getReformatConfig(apiConf: ApiConf) {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };

  return await sfetchJson(`${subUrl}/utils/reformat-config`, options);
}

type StyleOptions = {
  [option: string]: any;
};

async function reformatCode(apiConf: ApiConf, code: string, styleOptions: StyleOptions) {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    body: code,
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'text/plain',
    },
    method: 'POST',
  };

  const search: string[] = [];
  Object.keys(styleOptions).forEach((key) =>
    search.push(`${encodeURIComponent(key)}=${encodeURIComponent(styleOptions[key])}`)
  );

  return await sfetchText(`${subUrl}/utils/reformat-config?${search.join('&')}`, options);
}

async function validateConfig(apiConf: ApiConf, config: Json) {
  const { subUrl, token } = apiConf;
  const options: RequestInit = {
    body: JSON.stringify(config),
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  };

  return await sfetchJson(`${subUrl}/utils/validate-config`, options);
}

async function upload(
  token: string,
  url: string,
  mimeType: string,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE',
  file?: File
) {
  const options: RequestInit = {
    body: file,
    credentials: 'include',
    headers: {
      'Content-Type': mimeType,
      Authorization: `bearer ${token}`,
    },
    method: method,
  };

  return await sfetch(url, options);
}

async function download(token: string, url: string, mimeType: string) {
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Accept: mimeType,
      Authorization: `bearer ${token}`,
    },
    method: 'GET',
  };

  return await sfetch(url, options);
}

export default {
  getReformatConfig,
  reformatCode,
  validateConfig,
  upload,
  download,
};
