import React from 'react';
import { default as MUIFormGroup } from '@material-ui/core/FormGroup';
import { FormGroupProps as MUIFormGroupProps } from '@material-ui/core/FormGroup';

export interface FormGroupProps extends MUIFormGroupProps {}

const FormGroup: React.FC<FormGroupProps> = (props) => {
  return <MUIFormGroup {...props} />;
};

export default FormGroup;
