import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

function CodeViewer({ code, forceWrap, scroll }) {
  return (
    <pre
      className={`code-viewer ${forceWrap ? 'code-viewer--wrap' : ''} ${
        scroll ? 'code-viewer--scroll' : ''
      }`}
    >
      {code}
    </pre>
  );
}

CodeViewer.propTypes = {
  code: PropTypes.string.isRequired,
  forceWrap: PropTypes.bool,
  scroll: PropTypes.bool,
};

export default CodeViewer;
