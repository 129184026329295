import { jwtAllLoaded, jwtAdded, jwtRemoved } from '../thunks/jwt';
import { SubscriptionJWT } from '../../types/types';
import { createReducer } from '@reduxjs/toolkit';
import { mutableDelete } from 'Internals/utils';

type SubJWTState = SubscriptionJWT[];

const initialState: SubJWTState = [];

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
export default createReducer(initialState, (builder) =>
  builder
    .addCase(jwtAllLoaded, (state, action) => {
      return action.payload;
    })
    .addCase(jwtAdded, (state, action) => {
      state.push(action.payload);
    })
    .addCase(jwtRemoved, (state, action) => {
      mutableDelete(state, (jwt) => jwt.id === action.payload);
    })
);
