import { Tooltip } from '@material-ui/core';
import SesamLink from 'Common/Links/SesamLink';
import moment from 'moment';
import React from 'react';

export const PipeCompletenessStatus: React.FC<{
  completeness: number;
  pipeId: string;
  subId: string;
}> = ({ completeness, pipeId, subId }) => {
  const completenessRelative = moment(completeness / 1000).fromNow();
  const completenessTotal = moment(completeness / 1000).format('do MMM YYYY - HH:mm:ss');
  return (
    <span title={completenessTotal}>
      <SesamLink
        to={`/subscription/${subId}/pipes/pipe/${pipeId}/output?jtype=updated&jval=${
          completeness / 1000
        }`}
      >
        {completenessRelative}
      </SesamLink>
    </span>
  );
};
