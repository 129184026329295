import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PortalAPI from '../../../api/portal';

import Button from 'Common/Button/Button';
import { LoadingPanel } from 'Common/LoadingPanel';
import { compose } from 'redux';
import { withTheme } from '@material-ui/core';
import DatahubSettings from '../../../subscription/pages/settings-datahub';

class StacktracePage extends React.Component {
  constructor() {
    super();

    this.refresh = () => {
      this.getStacktrace();
    };

    this.getStacktrace = async () => {
      const stacktrace = await PortalAPI.getStacktrace(this.props.portalUrl, this.props.token);
      this.setState({
        stacktrace,
      });
    };

    this.state = {
      stacktrace: null,
    };
  }

  componentDidMount() {
    this.getStacktrace();
  }

  render() {
    if (!this.state.stacktrace) return <LoadingPanel />;

    return (
      <DatahubSettings>
        <div
          style={{
            overflow: 'scroll',
          }}
        >
          <Button text="Refresh" onClick={this.refresh} />
          <div
            dangerouslySetInnerHTML={{ __html: this.state.stacktrace }}
            style={{
              backgroundColor: '#f7f8f8',
              color: 'black',
              paddingLeft: '0.5em',
            }}
            // the stacktrace has it's own styles that we can't change but the
            // main text in it is not styled - that's where our styling takes over
            // with this, we simply set the text to black so that it's always visible
          />
        </div>
      </DatahubSettings>
    );
  }
}

StacktracePage.propTypes = {
  portalUrl: PropTypes.string,
  token: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  portalUrl: state.subscription.url,
  token: state.subscription.token,
});

export default compose(withTheme, connect(mapStateToProps))(StacktracePage);
