import React from 'react';
import State from 'Common/renderers/State/State';
import { StatusClassName } from './PipeStatus';

export const PipeDisabledStatus: React.FC<{ isDisabled: boolean }> = ({ isDisabled }) => {
  if (!isDisabled) {
    return null;
  }

  return <State title="Pipe is disabled" className={StatusClassName.RED} text="No" />;
};
