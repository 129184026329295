import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uniq from 'lodash/uniq';

import SesamAutocomplete from 'Common/SesamAutocomplete/SesamAutocomplete';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

import { filterNonHiddenRoles } from 'Redux/reducers/datahub';

const noop = () => null;
let iid = 0;

export const RoleProps = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const MultiroleSelector = (props) => {
  const { availableRoles, initialRoles, onChange, showHiddenRoles, validate, ...restProps } = props;

  const [id, setId] = useState((iid += 1));
  const [roles, setRoles] = useState(initialRoles);
  const [validationMessage, setValidationMessage] = useState(null);

  useEffect(() => {
    setRoles(initialRoles);
  }, [initialRoles]);

  const handleChange = (selected) => {
    const selectedRoleIds = selected.map((name) => {
      let foundRole = availableRoles.find((ar) => ar.name === name);
      return foundRole.id;
    });
    const rolesToKeep = roles.filter((r) => !isVisibleRole(r));
    const newRoles = uniq(rolesToKeep.concat(selectedRoleIds));

    onChange(newRoles);

    setRoles(newRoles);
    setValidationMessage(validate(newRoles));
  };

  const getAvailableRoles = () =>
    showHiddenRoles ? availableRoles : availableRoles.filter(filterNonHiddenRoles);

  const getSelectedRoles = () => (showHiddenRoles ? roles : roles.filter(isVisibleRole));

  const isVisibleRole = (role) => {
    return showHiddenRoles
      ? true
      : role !== 'group:Disabled' && role !== 'group:Invited' && role !== 'group:Declined';
  };

  const valid = !validationMessage;

  const roleNames = getSelectedRoles().map((roleId) => {
    let foundRole = availableRoles.find((ar) => ar.id === roleId);
    if (foundRole) {
      return foundRole.name;
    } else {
      return roleId;
    }
  });

  return (
    <React.Fragment>
      <SesamAutocomplete
        disableCloseOnSelect
        filterSelectedOptions
        multiple
        renderInput={(params) => (
          <SesamTextField
            {...restProps}
            {...params}
            margin="none"
            label="Roles"
            error={!valid}
            helperText={validationMessage}
          />
        )}
        options={getAvailableRoles().map((ar) => ar['name'])}
        value={roleNames}
        size="small"
        onChange={(obj, val) => {
          handleChange(val);
        }}
      />
    </React.Fragment>
  );
};

MultiroleSelector.defaultProps = {
  initialRoles: [],
  label: 'Roles',
  onChange: noop,
  showHiddenRoles: false,
  validate: noop,
};

export default MultiroleSelector;

function mapStateToProps(state) {
  return {
    availableRoles: state.subscription.availableRoles,
  };
}

export const MultiroleSelectorCurrentSub = connect(mapStateToProps)(MultiroleSelector);
