import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../../layout/regular';
import SignupForm from '../../components/signup-form-new';
import Page from '../../../components/page';

const SignupNew = function ({ location }) {
  return (
    <Layout text="Create account" color="#00AEEF">
      <Helmet
        meta={[
          {
            name: 'viewport',
            content:
              'width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
          },
        ]}
      />
      <Page>
        <p className="text-remark">
          You need to create an account in order to purchase the GDPR Platform in the next step.
          This will only take seconds.
        </p>
        <SignupForm location={location} />
      </Page>
    </Layout>
  );
};

SignupNew.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      redirect: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default SignupNew;
