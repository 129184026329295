import React from 'react';
import PropTypes from 'prop-types';

import { ActionPanel } from '../action/ActionPanel';
import { DeleteAction } from '../action/DeleteAction';
import PipeOperations from '../pipe-operations';

const PipesListActions = (props) => (
  <div className="list-actions">
    <ActionPanel>
      <PipeOperations pipes={props.selectedPipes} />
      <DeleteAction title="pipe" selection={props.selectedPipes} onDelete={props.onDelete} />
    </ActionPanel>
  </div>
);

PipesListActions.propTypes = {
  selectedPipes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PipesListActions;
