import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormActions } from 'Common/forms';
import { MultiroleSelectorCurrentSub } from '../../../components/multirole-selector';
import PromiseButton from '../../../components/promise-button';

function isHiddenRole(r) {
  return r === 'group:Disabled' || r === 'group:Invited' || r === 'group:Declined';
}

class MemberRoles extends React.Component {
  constructor(props) {
    super(props);

    this.validate = (selectedRoles) => {
      if (!this.hasAnyRealRoles(selectedRoles)) {
        return 'Member requires at least one role';
      }
      return null;
    };

    this.hasAnyRealRoles = (roles) => {
      return roles.filter((r) => !isHiddenRole(r)).length > 0;
    };

    this.canSave = () => {
      return (
        !isEqual(this.props.initialRoles, this.state.roles) &&
        this.hasAnyRealRoles(this.state.roles)
      );
    };

    this.state = {
      roles: this.props.initialRoles,
    };
  }

  render() {
    return (
      <Form component="div" horizontal>
        <MultiroleSelectorCurrentSub
          initialRoles={this.state.roles}
          backgroundColor="white"
          onChange={(roles) => this.setState({ roles })}
          validate={this.validate}
        />
        <FormActions>
          <PromiseButton
            disabled={!this.canSave()}
            onClick={() => this.props.onSave(this.state.roles)}
            pending="Saving…"
          >
            Save
          </PromiseButton>
        </FormActions>
      </Form>
    );
  }
}

MemberRoles.propTypes = {
  initialRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default MemberRoles;
