import React from 'react';
import BrowsePage from '../browse-page/BrowsePage';
import EntityTypesList from './EntityTypesList';

const EntityTypesListPage = (props) => {
  return (
    <BrowsePage>
      <EntityTypesList {...props} />
    </BrowsePage>
  );
};

export default EntityTypesListPage;
