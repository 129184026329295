import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, FormGroup, FormHelperText } from '../forms';
import SesamCheckboxCompact from '../SesamCheckboxCompact/SesamCheckboxCompact';

const useStyle = makeStyles(() => {
  return {
    checkboxRoot: {
      marginRight: '0.625rem',
    },
    helperTextRoot: {
      marginTop: 0,
      marginLeft: '1.9375rem',
    },
    FormControlLabelRoot: {
      margin: 0,
    },
  };
});

const CodeStyleOption = (props) => {
  const { checked, helperText, label, name, onChange } = props;
  const classes = useStyle();

  return (
    <FormControl margin="normal">
      <FormGroup>
        <FormControlLabel
          className={classes.FormControlLabelRoot}
          control={
            <SesamCheckboxCompact
              className={classes.checkboxRoot}
              name={name}
              onChange={onChange}
              checked={checked}
              size="small"
            />
          }
          label={label}
        />
        <FormHelperText className={classes.helperTextRoot}>{helperText}</FormHelperText>
      </FormGroup>
    </FormControl>
  );
};

CodeStyleOption.propTypes = {
  helperText: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export default CodeStyleOption;
