import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import get from 'lodash/get';

import { clone } from 'Redux/utils';
import SystemActions from 'Redux/thunks/systems';
import { DEFAULT_CONFIG_GROUP_ID } from 'Redux/selectors';
import { LoadingPanel } from 'Common/LoadingPanel';
import { getFromLocalStorage } from 'Internals/local-storage';

import { ActionBarStatus } from '../../../components/action-bar';
import Page from '../../../components/page';
import PageHeader, { PageHeaderTitle } from '../../../components/page-header';
import SystemEditor from '../../../components/system-editor';
import TabbedNav from '../../../components/tabbed-nav';

const CONFIG_EDITOR_LS_KEY = 'sesam--config-editor';

class NewSystem extends React.Component {
  constructor(props) {
    super(props);

    this.loadConfigGroupFromLS = () => {
      const configGroupFromLS = getFromLocalStorage(
        CONFIG_EDITOR_LS_KEY,
        ['configGroup'],
        DEFAULT_CONFIG_GROUP_ID
      );
      return configGroupFromLS;
    };

    this.save = (system) => {
      this.setState({
        status: ActionBarStatus.waiting,
        shortMessage: 'Saving…',
        message: '',
      });
      return this.props.save(system).catch((err) => {
        this.setState({
          status: ActionBarStatus.error,
          shortMessage: 'Could not save system',
          message: err,
        });

        throw new Error(err);
      });
    };

    this.afterSave = (system) => {
      const link = encodeURIComponent(system._id);
      this.context.router.push(`/subscription/${this.props.subId}/systems/system/${link}/edit`);
    };

    this.state = {
      loadingSourceSystem: false,
      message: '',
      shortMessage: '',
      status: ActionBarStatus.default,
    };
  }

  componentDidMount() {
    if (this.props.params.systemID) {
      this.setState({ loadingSourceSystem: true });
      this.props
        .load(this.props.params.systemID)
        .then(() => this.setState({ loadingSourceSystem: false }));
    }
  }

  render() {
    let defaultSystem;
    if (this.props.params.systemID) {
      // Duplicating a system
      if (this.state.loadingSourceSystem) {
        return <LoadingPanel />;
      }
      defaultSystem = clone(this.props.sourceSystem.config.original);
      defaultSystem._id = `${this.props.params.systemID}-copy`;
    } else {
      defaultSystem = {
        _id: null,
        type: 'system:<type>',
      };
      const newId = get(this, 'props.location.query.id');
      if (newId) defaultSystem._id = newId;
    }

    const savedConfigGroup = this.loadConfigGroupFromLS();

    if (savedConfigGroup !== DEFAULT_CONFIG_GROUP_ID) {
      defaultSystem.metadata = {
        '$config-group': savedConfigGroup,
      };
    }

    return (
      <Page>
        <PageHeader>
          <PageHeaderTitle>
            <SesamLink to={`/subscription/${this.props.subId}/systems`}>Systems</SesamLink>
          </PageHeaderTitle>
          <PageHeaderTitle>New system</PageHeaderTitle>
        </PageHeader>
        <TabbedNav
          nav={[
            {
              label: 'Config',
              to: this.props.location.pathname,
            },
          ]}
        >
          <SystemEditor
            initialDirty={false}
            initialSaved={false}
            initialValue={defaultSystem}
            isDuplicatedSystem={Boolean(this.props.params.systemID)}
            message={this.state.message}
            onSave={this.save}
            onAfterSave={this.afterSave}
            shortMessage={this.state.shortMessage}
            status={this.state.status}
          />
        </TabbedNav>
      </Page>
    );
  }
}

NewSystem.propTypes = {
  load: PropTypes.func.isRequired,
  params: PropTypes.shape({
    systemID: PropTypes.string,
  }).isRequired,
  save: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
  sourceSystem: PropTypes.object,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};

NewSystem.contextTypes = {
  // https://github.com/reactjs/react-router/issues/975
  router: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    sourceSystem: state.systems[ownProps.params.systemID],
    subId: state.subscription.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    load: (id) => dispatch(SystemActions.load(id)),
    save: (system) => dispatch(SystemActions.add(system)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSystem);
