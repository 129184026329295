import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import produce from 'immer';

import { filterNonHiddenRoles } from 'Redux/reducers/datahub';
import SubActions, { getCurrentSub, valuesFromOperations } from 'Redux/thunks/subscriptions';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import PromiseButton from '../../../components/promise-button';
import RoleCard from '../../../components/role-card';

const operations = ['create_custom_role'];

/* TODO: This should use the CardList component; not the plain markup and
   CSS classnames */

class SettingsSubscriptionRoles extends React.Component {
  constructor(props) {
    super(props);

    this.isAllowedToRemove = (role) => {
      return role['is-custom-role'];
    };

    this.refresh = () => {
      this.props.loadAvailableRoles(this.props.subId);
    };

    this.state = {
      editMode: false,
      name: '',
      description: '',
      editingId: '',
    };

    this.handleCustomFieldChange = (e) => {
      const { name, value } = e.target;
      switch (name) {
        case 'name':
          this.setState({ name: value });
          break;
        case 'description':
          this.setState({ description: value });
          break;
      }
    };
  }

  componentDidMount() {
    this.props.loadAvailableRoles(this.props.subId);
    this.props.registerRefresh(this.refresh);
  }

  componentWillUnmount() {
    this.props.unregisterRefresh(this.refresh);
  }

  // TODO promise-button doesn't work with form and button type="submit", which would be nice here
  render() {
    return (
      <main className="scrollArea">
        {this.props.can_create_custom_role.enabled && (
          <div className="row">
            <div className="col gr-equal">
              <h2 className="heading-section">Add new role</h2>
              <p>A role can be assigned to one or more members of the subscription.</p>
              <Form component="div">
                <SesamTextField
                  margin="normal"
                  label="Role name"
                  id="name"
                  inputRef={(e) => {
                    this.name = e;
                  }}
                />
                <SesamTextField
                  margin="normal"
                  label="Role description"
                  id="description"
                  inputRef={(e) => {
                    this.description = e;
                  }}
                />
                <FormActions>
                  <PromiseButton
                    pending="Adding..."
                    theme="primary"
                    onClick={() =>
                      this.props.onAdd(this.props.subId, {
                        name: this.name.value,
                        description: this.description.value,
                      })
                    }
                  >
                    Add
                  </PromiseButton>
                </FormActions>
              </Form>
            </div>
          </div>
        )}
        <h2 className="heading-section">Existing roles</h2>
        <div className="card-list">
          <ul className="card-list__list">
            {sortBy(this.props.availableRoles, 'name').map((r) => (
              <li key={r.name}>
                <RoleCard
                  role={r}
                  editMode={
                    this.isAllowedToRemove(r) &&
                    this.state.editingId === r.id &&
                    this.state.editMode
                  }
                  handleFieldChange={this.handleCustomFieldChange}
                  actions={
                    this.isAllowedToRemove(r) && (
                      <React.Fragment>
                        <PromiseButton
                          pending="Removing…"
                          onClick={() => this.props.onRemove(this.props.subId, r)}
                          theme="danger"
                        >
                          Remove
                        </PromiseButton>
                        <Button
                          onClick={() => {
                            this.setState({ name: r.name, description: r.description });
                            if (this.state.editingId === r.id)
                              this.setState({ editMode: !this.state.editMode, editingId: r.id });
                            else this.setState({ editMode: true, editingId: r.id });
                          }}
                          theme="default"
                        >
                          {this.state.editMode && this.state.editingId === r.id ? 'Cancel' : 'Edit'}
                        </Button>
                        {this.state.editMode && this.state.editingId === r.id && (
                          <PromiseButton
                            pending="Updating..."
                            onClick={() => {
                              const editMode = this.state.editMode;
                              this.setState({ editMode: !editMode });
                              const role = produce(r, (draft) => {
                                draft.name = this.state.name;
                                draft.description = this.state.description;
                              });
                              return this.props.onUpdate(this.props.subId, role);
                            }}
                            theme="primary"
                          >
                            Update
                          </PromiseButton>
                        )}
                      </React.Fragment>
                    )
                  }
                />
              </li>
            ))}
          </ul>
        </div>
      </main>
    );
  }
}

SettingsSubscriptionRoles.propTypes = {
  can_create_custom_role: PropTypes.shape({
    enabled: PropTypes.bool,
  }),
  subId: PropTypes.string.isRequired,
  loadAvailableRoles: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  availableRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
};

function mapStateToProps(state) {
  return Object.assign(valuesFromOperations(getCurrentSub(state), operations), {
    availableRoles: state.subscription.availableRoles.filter(filterNonHiddenRoles),
    subId: state.subscription.id,
  });
}

function mapDispatchToProps(dispatch) {
  return {
    loadAvailableRoles: (subId) => dispatch(SubActions.loadAvailableRoles(subId)),
    onRemove: (subId, role) => dispatch(SubActions.removeRole(subId, role)),
    onAdd: (subId, role) => dispatch(SubActions.addRole(subId, role)),
    onUpdate: (subId, role) => dispatch(SubActions.updateRole(subId, role)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsSubscriptionRoles);
