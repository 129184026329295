import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import StaticGraph from '../../subscription/components/graph/StaticGraph';
import { LoadingPanel } from 'Common/LoadingPanel';
import { getComponentType, getIcon, getNodeLabel, getEdgeColor } from 'Internals/graph';
import { getPipeQueueSize } from 'Internals/pipes';
import { withSeparators } from 'Internals/utils';

// step in pixels for nodes in a row/col
const STEP_X = 250;

const Flow = ({ flow, onGotoNode, autoSelectMainNode, mainNodeId, onHoverNode }) => {
  if (isEmpty(flow)) {
    return <LoadingPanel />;
  }

  const nodes = [];
  const edges = [];
  const graph = {};
  const visited = [];

  flow.slice().forEach((flowNode, index) => {
    const obj = flowNode.data;

    let id;
    if (visited.includes(obj._id)) {
      id = `${obj._id}::loop${visited.filter((v) => v !== obj._id).length}`;
    } else {
      id = obj._id;
    }

    visited.push(obj._id);

    nodes.push({
      id,
      label: getNodeLabel(obj),
      meta: {
        type: getComponentType(obj),
        datasetId: obj.sinkDatasetId,
        ownId: id,
      },
      color: {
        color: getEdgeColor(obj),
        highlight: {
          border: getEdgeColor(obj),
          background: 'white',
        },
        hover: {
          border: getEdgeColor(obj),
          background: 'white',
        },
      },
      image: getIcon(obj),
      y: 0,
      x: -((flow.length - 1) * STEP_X) / 2 + STEP_X * index,
      fixed: { x: true },
    });
    let previousObj = get(flow[index - 1]);
    const queueSize = getPipeQueueSize(obj, previousObj); // 0 if system
    if (index !== 0) {
      edges.push({
        from: get(flow[index - 1], 'data._id'),
        to: id,
        dashes: flowNode.isHop,
        label: queueSize > 0 ? withSeparators(queueSize) : '',
        title: `${queueSize > 0 ? `Queue size: ${withSeparators(queueSize)}` : ''}`,
        color: {
          color: getEdgeColor(obj),
          highlight: getEdgeColor(obj),
          hover: getEdgeColor(obj),
        },
        id: `dataset:${obj._id}`,
        width: 2,
        smooth: {
          forceDirection: 'horizontal',
        },
        arrows: {
          to: { enabled: true },
          from: { enabled: false },
        },
        meta: {
          datasetId: obj.sinkDatasetId,
          ownId: id,
        },
      });
    }
  });

  graph.nodes = nodes;
  graph.edges = edges;

  return (
    <StaticGraph
      autoSelectMainNode={autoSelectMainNode}
      graph={graph}
      onGotoNode={onGotoNode}
      onHoverNode={onHoverNode}
      mainNodeId={mainNodeId}
      options={{
        height: '9rem',
        autoResize: true,
        interaction: {
          dragNodes: false,
          dragView: false,
          zoomView: false,
        },
      }}
      fit
    />
  );
};

Flow.propTypes = {
  autoSelectMainNode: PropTypes.bool,
  flow: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    })
  ),
  mainNodeId: PropTypes.string,
  onHoverNode: PropTypes.func.isRequired,
  onGotoNode: PropTypes.func.isRequired,
};

export default Flow;
