import React from 'react';
import moment from 'moment';

export const PipeNextRunStatus: React.FC<{ type: string; nextRun: string }> = ({
  type,
  nextRun,
}) => {
  if (type === 'noop' || !nextRun) {
    return <span>&mdash;</span>;
  }

  const value = `${moment(nextRun).format('YYYY-MM-DD')} ${moment(nextRun).format('HH:mm:ss')}`;

  return <span title={value}>{value}</span>;
};
