import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import get from 'lodash/get';

import { DatasetIndexes } from '../../subscription/pages/datasets/DatasetIndexes';

class PipeIndexes extends React.Component {
  render() {
    if (!this.props.pipe) return <div />;

    const datasetID = get(this.props.pipe, 'config.effective.sink.dataset');

    return (
      <DatasetIndexes
        params={{ datasetID }}
        registerRefresh={this.props.registerRefresh}
        unregisterRefresh={this.props.unregisterRefresh}
      />
    );
  }
}

PipeIndexes.propTypes = {
  pipe: PropTypes.shape({}),
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  pipe: state.pipes[ownProps.params.pipeID],
});

export default connect(mapStateToProps)(PipeIndexes);
