import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import produce from 'immer';
import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import PipeActions from 'Redux/thunks/pipes';
import ModelActions from 'Redux/thunks/models';

import BrowsePage from '../browse-page/BrowsePage';
import FilterTable from '../filter-table/FilterTable';
import { SubscriptionContext } from '../../pages/Subscription.page';
import { useAppDispatch } from '../..';

const filterLabelMapping = {
  ['name']: 'Name',
  ['type']: 'Type',
};

const emptyFilter = Object.freeze({
  // TODO: Do we need to send both empty and default filter?
  ['name']: {
    selected: [],
    search: [],
  },
  ['type']: {
    selected: [],
    search: [],
  },
});

const defaultFilter = produce(emptyFilter, (draft) => {});

const defaultVisibleCols = ['Name', 'Type', 'Data types'];

const defaultVisibleFacets = ['name', 'type'];

const defaultSortBy = [
  {
    id: 'name',
    desc: false,
  },
];

export function getModelName(value) {
  switch (value) {
    case 'input':
      return 'Input';
    case 'global':
      return 'Global';
    case 'undefined':
      return 'Undefined';
    default:
      return value;
  }
}

export function getModelType(value) {
  switch (value) {
    case 'input':
      return 'Implicit';
    case 'global':
      return 'Implicit';
    case 'undefined':
      return 'Implicit';
    default:
      return 'Explicit';
  }
}

const columns = [
  {
    Header: 'Name',
    id: 'name',
    accessor: 'name',
    fixed: true,
    width: 300,
    Cell: ({ cell: { value }, row }) => {
      const subId = useContext(SubscriptionContext);
      return (
        <SesamLink to={`subscription/${subId}/browse/models/${row.original._id}`}>
          {value}
        </SesamLink>
      );
    },
  },
  {
    Header: 'Type',
    id: 'type',
    accessor: 'type',
    Cell: ({ cell: { value } }) => {
      return <span>{value}</span>;
    },
  },
  {
    Header: 'Entity types',
    id: 'entityTypes',
    accessor: 'name',
    Cell: ({ cell: { value } }) => {
      const subId = useContext(SubscriptionContext);
      return (
        <SesamLink to={`subscription/${subId}/browse/entity-types?Model.selected=${value}`}>
          View entity types
        </SesamLink>
      );
    },
  },
];

/**
 * Renders a list of data models
 * @param {object} props
 */
const ModelsList = (props) => {
  const { loadAllPipes, loadAllModels, pipes, models } = props;
  const refresh = () => loadAllModels();

  useEffect(() => {
    loadAllPipes();
  }, []);

  useEffect(() => {
    if (!isEmpty(pipes)) {
      loadAllModels();
    }
  }, [pipes, loadAllModels]);

  useEffect(() => {
    props.registerRefresh(refresh);
    return () => {
      props.unregisterRefresh(refresh);
    };
  });

  return (
    <FilterTable
      defaults={{
        filter: defaultFilter,
        sortBy: defaultSortBy,
        visibleCols: defaultVisibleCols,
        visibleFacets: defaultVisibleFacets,
      }}
      emptyFilter={emptyFilter}
      filterLabelMapping={filterLabelMapping}
      columns={columns}
      itemsToFilter={models}
      tableKey="data-models-3"
    />
  );
};

ModelsList.propTypes = {
  pipes: PropTypes.shape({}),
  models: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadAllPipes: PropTypes.func.isRequired,
  loadAllModels: PropTypes.func.isRequired,
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
};

ModelsList.defaultProps = {
  models: null,
};

const modelsSelector = createSelector(
  (state) => state.models,
  (models) => {
    return models.map((_model) => {
      const model = { ..._model };
      model.name = getModelName(_model._id);
      model.type = getModelType(_model._id);
      return model;
    });
  }
);

function mapStateToProps(state) {
  return {
    pipes: state.pipes,
    models: modelsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAllPipes: () => dispatch(PipeActions.loadAll()),
    loadAllModels: () => dispatch(ModelActions.loadAll()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelsList);
