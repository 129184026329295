import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import SesamModal from 'Common/SesamModal/SesamModal';
import SesamLink from 'Common/Links/SesamLink';
import { registerKey, unregisterKey } from 'Internals/global-shortcuts';
import { dataAttrsFromProps } from 'Internals/react-utils';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import SesamCheckboxFieldCompact from 'Common/SesamCheckboxFieldCompact/SesamCheckboxFieldCompact';
import './style.css';

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.makeFocusChanger = (reverse) => () => {
      const walker = document.createTreeWalker(
        this.menuEl,
        NodeFilter.SHOW_ELEMENT,
        (node) => {
          if (node.classList.contains('menu__action') && !node.disabled) {
            return NodeFilter.FILTER_ACCEPT;
          }
          return NodeFilter.FILTER_SKIP;
        },
        false
      );

      walker.currentNode =
        document.activeElement.labels && document.activeElement.labels.length
          ? document.activeElement.labels[0]
          : document.activeElement;
      const next = walker[reverse ? 'previousNode' : 'nextNode']();

      if (next) {
        next.focus();
      }
    };

    this.handleFocus = () => this.registerArrowHandlers();

    this.handleBlur = (ev) => {
      if (!this.menuEl.contains(ev.relatedTarget)) {
        this.unregisterArrowHandlers();
      }
    };
  }

  componentWillUnmount() {
    this.unregisterArrowHandlers();
  }

  registerArrowHandlers() {
    if (!this.focused) {
      this.focused = true;
      registerKey('ArrowDown', this.makeFocusChanger());
      registerKey('ArrowUp', this.makeFocusChanger(true));
    }
  }

  unregisterArrowHandlers() {
    if (this.focused) {
      this.focused = false;
      unregisterKey('ArrowDown');
      unregisterKey('ArrowUp');
    }
  }

  render() {
    return (
      <ul
        className="menu"
        onBlur={this.handleBlur}
        style={{
          backgroundColor: this.props.theme.palette.background.light,
          color: this.props.theme.palette.text.primary,
          borderColor: this.props.theme.palette.background.darker,
        }}
        onFocus={this.handleFocus}
        ref={(el) => {
          this.menuEl = el;
        }}
      >
        {this.props.children}
      </ul>
    );
  }
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(Menu);

// -----------------------------------------------------------------------------

function _MenuItem({ children, theme }) {
  return (
    <li className="menu__item" style={{ color: theme.palette.text.primary }}>
      {children}
    </li>
  );
}

_MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export const MenuItem = withTheme(_MenuItem);

// -----------------------------------------------------------------------------

export function MenuControl({ children }) {
  return (
    <MenuItem>
      <div className="menu__control">{children}</div>
    </MenuItem>
  );
}

MenuControl.propTypes = {
  children: PropTypes.node.isRequired,
};

// -----------------------------------------------------------------------------

function _MenuAction({ children, disabled, label, onClick, theme, ...rest }) {
  return (
    <MenuItem>
      <button
        className="menu__action"
        disabled={disabled}
        style={{
          color: disabled ? theme.palette.action.disabled : theme.palette.text.primary,
        }}
        onClick={onClick}
        {...dataAttrsFromProps(rest)}
      >
        {label}
        {children}
      </button>
    </MenuItem>
  );
}

_MenuAction.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

_MenuAction.defaultProps = {
  disabled: false,
};

export const MenuAction = withTheme(_MenuAction);

// -----------------------------------------------------------------------------

function _MenuActionLink({ children, disabled, label, to, theme }) {
  return (
    <MenuItem>
      {disabled ? (
        <span className="menu__action" style={{ color: theme.palette.action.disabled }}>
          {label}
          {children}
        </span>
      ) : (
        <SesamLink className="menu__action" style={{ color: theme.palette.text.primary }} to={to}>
          {label}
          {children}
        </SesamLink>
      )}
    </MenuItem>
  );
}

_MenuActionLink.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

_MenuActionLink.defaultProps = {
  disabled: false,
  children: null,
};

export const MenuActionLink = withTheme(_MenuActionLink);

// -----------------------------------------------------------------------------

class _MenuActionPopup extends React.Component {
  constructor(props) {
    super(props);

    this.handleMenuActionClick = () => {
      this.openModal(this.props);
    };

    this.handleCancel = (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      this.closeModal();
    };

    this.handleSubmit = (ev) => {
      ev.preventDefault();
      this.props.onClick();
      this.closeModal();
    };

    this.closeModal = () => {
      this.props.onClose();
      ReactDOM.unmountComponentAtNode(this.modalRootNode);
      this.modalRootNode.parentNode.removeChild(this.modalRootNode);
      this.modalRootNode = null;
    };
  }

  openModal(props) {
    let modalParent;
    this.modalRootNode = document.createElement('noscript');

    if (this.context.getMenuToggler) {
      modalParent = this.context.getMenuToggler();
      modalParent.uiMountPoint.appendChild(this.modalRootNode);
    } else {
      modalParent = this;
      document.body.appendChild(this.modalRootNode);
    }

    const popup = (
      <SesamModal
        darkModeActive={props.darkModeActive}
        className="simple-dialog menu-action-popup"
        isOpen
        onRequestClose={this.closeModal}
        contentLabel={props.label}
      >
        <Form onSubmit={this.handleSubmit}>
          <h3 className="heading-component">{props.label}</h3>
          {props.children}
          <FormActions>
            <Button type="submit">OK</Button>
            <Button onClick={this.handleCancel}>Cancel</Button>
          </FormActions>
        </Form>
      </SesamModal>
    );

    ReactDOM.unstable_renderSubtreeIntoContainer(modalParent, popup, this.modalRootNode);
  }

  render() {
    return (
      <MenuAction
        disabled={this.props.disabled}
        label={`${this.props.label}…`}
        onClick={this.handleMenuActionClick}
      />
    );
  }
}

_MenuActionPopup.propTypes = Object.assign({}, MenuAction.propTypes, {
  onClose: PropTypes.func,
});

_MenuActionPopup.defaultProps = MenuAction.defaultProps;

_MenuActionPopup.contextTypes = {
  getMenuToggler: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    darkModeActive: state.theme.dark,
  };
}

export const MenuActionPopup = connect(mapStateToProps)(withTheme(_MenuActionPopup));

// -----------------------------------------------------------------------------

class _MenuActionPopupWithoutForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleMenuActionClick = () => {
      this.openModal(this.props);
    };

    this.closeModal = () => {
      this.props.onClose();
      ReactDOM.unmountComponentAtNode(this.modalRootNode);
      this.modalRootNode.parentNode.removeChild(this.modalRootNode);
      this.modalRootNode = null;
    };
  }

  openModal(props) {
    let modalParent;
    this.modalRootNode = document.createElement('noscript');

    if (this.context.getMenuToggler) {
      modalParent = this.context.getMenuToggler();
      modalParent.uiMountPoint.appendChild(this.modalRootNode);
    } else {
      modalParent = this;
      document.body.appendChild(this.modalRootNode);
    }

    const popup = (
      <SesamModal
        darkModeActive={props.darkModeActive}
        className="simple-dialog menu-action-popup"
        isOpen
        onRequestClose={this.closeModal}
        contentLabel={props.label}
      >
        {props.children}
      </SesamModal>
    );

    ReactDOM.unstable_renderSubtreeIntoContainer(modalParent, popup, this.modalRootNode);
  }

  render() {
    return (
      <MenuAction
        disabled={this.props.disabled}
        label={`${this.props.label}…`}
        onClick={this.handleMenuActionClick}
      />
    );
  }
}

_MenuActionPopupWithoutForm.propTypes = Object.assign({}, MenuAction.propTypes, {
  onClose: PropTypes.func,
});

_MenuActionPopupWithoutForm.defaultProps = MenuAction.defaultProps;

_MenuActionPopupWithoutForm.contextTypes = {
  getMenuToggler: PropTypes.func,
};

export const MenuActionPopupWithoutForm = connect(mapStateToProps)(
  withTheme(_MenuActionPopupWithoutForm)
);

// -----------------------------------------------------------------------------

const useMenuCheckboxStyle = makeStyles(() => ({
  root: {
    marginLeft: '14px',
  },
}));

const _MenuCheckbox = function ({ checked, readOnly, style, label, onChange, ...rest }) {
  const classes = useMenuCheckboxStyle();
  return (
    <MenuItem>
      <SesamCheckboxFieldCompact
        className={classes.root}
        label={label}
        style={style}
        CheckboxProps={{
          checked: checked,
          disabled: readOnly,
          onChange: onChange,
          disableRipple: true,
          ...dataAttrsFromProps(rest),
        }}
      />
    </MenuItem>
  );
};

_MenuCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

_MenuCheckbox.defaultProps = {
  readOnly: false,
  children: null,
};

export const MenuCheckbox = withTheme(_MenuCheckbox);

// -----------------------------------------------------------------------------

export const MenuSeparator = function () {
  return (
    <li className="menu__separator">
      <hr />
    </li>
  );
};
