import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import SubscriptionsActions, {
  makePermissionCheckerForCurrentSub,
  makeRoleCheckerForCurrentSub,
} from 'Redux/thunks/subscriptions';
import DatahubActions from 'Redux/thunks/datahub';
import { canEditVPN } from 'Redux/selectors';
import Button from 'Common/Button/Button';
import PortalFallbackMessage from 'Common/PortalFallbackMessage/PortalFallbackMessage';
import Page from '../../../components/page';
import PageHeader, { PageHeaderTitle } from '../../../components/page-header';
import TabbedNav from '../../../components/tabbed-nav';
import useAsync from '../../../hooks/useAsync';

const SubscriptionSettings = (props) => {
  let registeredRefreshes = useRef([]);

  const _refresh = useCallback(() => {
    props.refreshSubscription(props.subId);
    if (props.connected) props.refreshDatahubInfo();

    registeredRefreshes.current.forEach((cb) => cb());
  }, [props]);

  const [refresh] = useAsync(_refresh);

  const handleRefresh = () => {
    refresh();
  };

  const registerRefresh = useCallback((func) => {
    registeredRefreshes.current.push(func);
  }, []);

  const unregisterRefresh = useCallback((func) => {
    registeredRefreshes.current = registeredRefreshes.current.filter((c) => c !== func);
  }, []);

  const isPaymentMethodOwner = () => {
    const paymentMethodInfo = props.subData.paymentmethod_info;
    const paymentOwnerUserId = get(paymentMethodInfo, 'owner.user_id');
    const userId = get(props.user, 'user_id');
    if (paymentOwnerUserId) {
      if (paymentOwnerUserId === userId) return true;
    }
    return false;
  };

  let nav = [
    {
      label: 'Basics',
      to: `/subscription/${props.subId}/settings-subscription/basics`,
    },
  ];

  if (props.permissionChecker('modify_subscription')) {
    nav.push({
      label: 'License',
      to: `/subscription/${props.subId}/settings-subscription/license`,
    });
  }

  nav.push({
    label: 'Network',
    to: `/subscription/${props.subId}/settings-subscription/network`,
  });

  if (
    (props.permissionChecker('modify_subscription') && isPaymentMethodOwner()) ||
    props.isPortalAdmin
  ) {
    nav.push({
      label: 'Products',
      to: `/subscription/${props.subId}/settings-subscription/products`,
    });
  }

  if (props.canEditVPN) {
    nav.push({
      label: 'VPN',
      to: `/subscription/${props.subId}/settings-subscription/vpn`,
    });
  }

  if (props.permissionChecker('view_api_jwt_tokens')) {
    nav.push({
      label: 'JWT',
      to: `/subscription/${props.subId}/settings-subscription/jwt`,
    });
  }

  nav = nav.concat([
    {
      label: 'Roles',
      to: `/subscription/${props.subId}/settings-subscription/roles`,
    },
    {
      label: 'Members',
      to: `/subscription/${props.subId}/settings-subscription/members`,
    },
    {
      label: 'Notifications',
      to: `/subscription/${props.subId}/settings-subscription/notifications`,
    },
  ]);

  return (
    <Page dataSelenium="subscription-settings-pageview">
      <PageHeader style={{ alignItems: 'baseline' }}>
        <PageHeaderTitle>Subscription settings</PageHeaderTitle>
        {props.isPortalInFallbackMode && <PortalFallbackMessage />}
        <Button theme="primary" style={{ marginLeft: 'auto' }} onClick={handleRefresh}>
          Refresh
        </Button>
      </PageHeader>
      <TabbedNav nav={nav}>
        {React.cloneElement(props.children, {
          registerRefresh: registerRefresh,
          unregisterRefresh: unregisterRefresh,
        })}
      </TabbedNav>
    </Page>
  );
};

SubscriptionSettings.propTypes = {
  children: PropTypes.node,
  connected: PropTypes.bool.isRequired,
  subId: PropTypes.string.isRequired,
  permissionChecker: PropTypes.func.isRequired,
  isPortalInFallbackMode: PropTypes.bool.isRequired,
  canEditVPN: PropTypes.bool.isRequired,
  refreshSubscription: PropTypes.func.isRequired,
  refreshDatahubInfo: PropTypes.func.isRequired,
  subData: PropTypes.object,
  user: PropTypes.object,
  isPortalAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  connected: state.subscription.connected,
  subId: state.subscription.id,
  subData: state.subscriptions.find((s) => s.id === state.subscription.id),
  user: state.user,
  permissionChecker: makePermissionCheckerForCurrentSub(state),
  canEditVPN: canEditVPN(state),
  roleChecker: makeRoleCheckerForCurrentSub(state),
  isPortalInFallbackMode: state.globals.status.is_fallback_mode,
  isPortalAdmin: state.user.isPortalAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  refreshSubscription: (subId) => dispatch(SubscriptionsActions.refresh(subId)),
  refreshDatahubInfo: () => dispatch(DatahubActions.getInfo()),
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(SubscriptionSettings);

export { Connected as SubscriptionSettings };
