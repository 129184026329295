import { SomeObject } from '../../types/types';
import { Json } from '../../types/json';
import { createReducer } from '@reduxjs/toolkit';
import {
  subSwitched,
  connectAttemptStarted,
  connectAttemptFinished,
  subCreated,
  disconnected,
  connectAttemptFailed,
  connectionLogUpdated,
  availableRolesLoaded,
  membersLoaded,
  subRoleAdded,
  subRoleUpdated,
  subRoleRemoved,
  memberRemoved,
  memberReinvited,
  memberUpdated,
  memberInvited,
} from '../thunks/subscriptions';
import { portalJwtUpdated } from '../thunks/global';
import { datahubUpdated, datahubLicenseLoaded } from '../thunks/datahub';
import { mutableUpdate, mutableUpsert, mutableDelete } from 'Internals/utils';

type DatahubState = {
  availableRoles: SomeObject[];
  connected: boolean;
  connecting: boolean;
  connectLog: { event: string; timestamp: Date }[];
  id: string;
  info: SomeObject;
  lastConnectFailed: string;
  lastConnectUrl: string;
  defaultConnectionUrl: string;
  license: Json | undefined;
  members: SomeObject[];
  token: string;
  url: string;
};

const initialState: DatahubState = {
  availableRoles: [],
  connected: false,
  connecting: false,
  connectLog: [],
  id: '',
  info: {},
  lastConnectFailed: '',
  lastConnectUrl: '',
  defaultConnectionUrl: '',
  license: undefined,
  members: [],
  token: '',
  url: '',
};

function mutUpdateMember(members: SomeObject[], newMember: SomeObject) {
  mutableUpdate(members, newMember, (m) => m.user.user_id === newMember.user.user_id);
}

function mutUpsertMember(members: SomeObject[], newMember: SomeObject) {
  mutableUpsert(members, newMember, (m) => m.user.user_id === newMember.user.user_id);
}

function mutDeleteMember(members: SomeObject[], memberToDelete: SomeObject) {
  mutableDelete(members, (m) => m.user.user_id === memberToDelete.user.user_id);
}

function mutUpdateRole(roles: SomeObject[], newRole: SomeObject) {
  mutableUpdate(roles, newRole, (r) => r.id === newRole.id);
}

function mutDeleteRole(roles: SomeObject[], roleIdToDelete: string) {
  mutableDelete(roles, (r) => r.id === roleIdToDelete);
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(subSwitched, (state, action) => {
      state.id = action.payload.subscriptionId;
      state.defaultConnectionUrl = action.payload.defaultConnectionUrl;
    })
    .addCase(connectAttemptStarted, (state) => {
      state.connecting = true;
    })
    .addCase(connectAttemptFinished, (state, action) => {
      state.url = action.payload.url;
      state.token = action.payload.token;
      state.connecting = false;
      state.connected = true;
      state.lastConnectFailed = '';
    })
    .addCase(subCreated, (state, action) => {
      state.url = 'https://localhost:9443/api';
      state.id = action.payload.id;
    })
    .addCase(disconnected, (state, action) => {
      if (action.payload) {
        state.id = '';
      }
      state.url = '';
      state.token = '';
      state.connecting = false;
      state.connected = false;
      state.lastConnectFailed = '';
      state.members = [];
      state.info = {};
    })
    .addCase(connectAttemptFailed, (state, action) => {
      state.lastConnectFailed = action.payload.status;
      state.lastConnectUrl = action.payload.url;
      state.connecting = false;
    })
    .addCase(connectionLogUpdated, (state, action) => {
      state.connectLog.push({
        event: action.payload.event,
        timestamp: action.payload.timestamp,
      });
    })

    .addCase(availableRolesLoaded, (state, action) => {
      state.availableRoles = action.payload;
    })
    .addCase(subRoleAdded, (state, action) => {
      state.availableRoles.push(action.payload);
    })
    .addCase(subRoleUpdated, (state, action) => {
      mutUpdateRole(state.availableRoles, action.payload);
    })
    .addCase(subRoleRemoved, (state, action) => {
      mutDeleteRole(state.availableRoles, action.payload.id);
    })
    .addCase(membersLoaded, (state, action) => {
      state.members = action.payload;
    })
    .addCase(memberRemoved, (state, action) => {
      mutDeleteMember(state.members, action.payload.member);
    })
    .addCase(memberReinvited, (state, action) => {
      mutUpdateMember(state.members, action.payload);
    })
    .addCase(memberUpdated, (state, action) => {
      mutUpdateMember(state.members, action.payload.member);
    })
    .addCase(memberInvited, (state, action) => {
      mutUpsertMember(state.members, action.payload);
    })
    .addCase(portalJwtUpdated, (state, action) => {
      state.token = action.payload;
    })
    .addCase(datahubUpdated, (state, action) => {
      state.info = action.payload;
    })
    .addCase(datahubLicenseLoaded, (state, action) => {
      state.license = action.payload;
    })
);

export const filterNonHiddenRoles = (r) => !r['is-internal-role'];
