import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import ApologyActions from 'Redux/thunks/apology';
import AppHeader from '../../components/app-header';
import NavBar from '../../components/navbar/NavBar';
import ExternalLink from 'Common/Links/ExternalLink';

import '../../style/global.css';
import StatusBar from '../../components/status-bar/StatusBar';
import { Links } from 'Constants/links';
import { TestID } from '../../testID';

const useStyle = makeStyles((theme) => {
  return {
    layout: {
      backgroundColor: theme.palette.background.semilight,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      '& .app-header': {
        flex: '0 0 var(--size-app-header-height)',
        zIndex: '2000',
      },
    },
    layoutContent: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      color: theme.palette.text.primary,
    },
    content: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
    },
  };
});

/*
 * Layout
 */
function Layout(props) {
  const classes = useStyle();

  const footerCollapsed = [
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
      }}
    >
      <p>&copy; {new Date().getFullYear()} Sesam.io AS</p>
    </div>,
  ];
  const footerExpanded = (
    <div>
      <div>
        <ul>
          <li>
            <ExternalLink
              href={Links.SesamDocumentations}
              rel="noopener noreferrer"
              target="_blank"
              data-testid={`${TestID.AppBarMenuLink}-Documentation`}
            >
              Documentation
            </ExternalLink>
          </li>
          <li>
            <ExternalLink
              href={Links.TermsOfService}
              rel="noopener noreferrer"
              target="_blank"
              data-testid={`${TestID.AppBarMenuLink}-Terms`}
            >
              Terms
            </ExternalLink>
          </li>
          <li>
            <ExternalLink
              href={Links.PrivacyPolicy}
              rel="noopener noreferrer"
              target="_blank"
              data-testid={`${TestID.AppBarMenuLink}-Privacy`}
            >
              Privacy
            </ExternalLink>
          </li>
        </ul>
        <p>&copy; {new Date().getFullYear()} Sesam.io AS</p>
      </div>
      <StatusBar collapsed={false} />
    </div>
  );

  return (
    <div className={classes.layout}>
      <AppHeader />
      <div className={classes.layoutContent}>
        {(props.navLinks || props.topElement) && (
          <NavBar
            location={props.location}
            footerExpanded={footerExpanded}
            footerCollapsed={footerCollapsed}
            primaryItem={props.topElement}
            navLinks={props.navLinks}
            statusBarCollapsed={<StatusBar collapsed={true} />}
          />
        )}
        <section className={classes.content} id="app-content">
          {props.children}
        </section>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  navLinks: PropTypes.array,
  topElement: PropTypes.node,
  serverUpgraded: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    subId: state.subscription.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    serverUpgraded: () => dispatch(ApologyActions.serverUpgraded()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
