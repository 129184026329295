import sfetch, { sfetchJson } from 'Internals/sfetch';

async function login(portalUrl: string, email: string, password: string, captcha?: string) {
  const url = `${portalUrl}/login`;
  const data = new FormData();
  data.append('email', email);
  data.append('password', password);
  if (captcha) {
    data.append('g-recaptcha-response', captcha);
  }
  const requestOptions: RequestInit = {
    credentials: 'include',
    method: 'POST',
    body: data,
  };
  return sfetchJson(url, requestOptions);
}

export type SignupData = {
  email: string;
  password: string;
  name: string;
  'g-recaptcha-response'?: string;
};

async function signup(portalUrl: string, signupData: SignupData) {
  const data = new FormData();
  data.append('email', signupData.email);
  data.append('password', signupData.password);
  data.append('name', signupData.name);
  if (signupData['g-recaptcha-response']) {
    data.append('g-recaptcha-response', signupData['g-recaptcha-response']);
  }
  const requestOptions: RequestInit = {
    credentials: 'include',
    method: 'POST',
    body: data,
  };
  return await sfetchJson(`${portalUrl}/signup`, requestOptions);
}

async function forgotPassword(portalUrl: string, email: string, captchaResponse: string) {
  const data = new FormData();
  data.append('email', email);
  data.append('g-recaptcha-response', captchaResponse);
  const requestOptions: RequestInit = {
    credentials: 'include',
    method: 'POST',
    body: data,
  };
  return await sfetch(`${portalUrl}/forgot-password`, requestOptions);
}

async function resetPassword(portalUrl: string, token: string, password: string) {
  const data = new FormData();
  data.append('token', token);
  data.append('password', password);
  const requestOptions: RequestInit = {
    credentials: 'include',
    method: 'POST',
    body: data,
  };

  return await sfetch(`${portalUrl}/reset-password`, requestOptions);
}

async function verifyEmail(portalUrl: string, token: string) {
  const data = new FormData();
  data.append('token', token);
  const requestOptions: RequestInit = {
    credentials: 'include',
    method: 'POST',
    body: data,
  };

  return await sfetch(`${portalUrl}/verify-emailaddress`, requestOptions);
}

export default {
  login,
  signup,
  forgotPassword,
  resetPassword,
  verifyEmail,
};
