import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import ClipboardJS from 'clipboard';
import { compose } from 'redux';
import { connect } from 'react-redux';

import get from 'lodash/get';

import Button from 'Common/Button/Button';
import { Form } from 'Common/forms';

import SesamTextField from 'Common/SesamTextField/SesamTextField';

import withDownload from 'Common/HOC/with-download/withDownload';

import './DownloadEntitiesStyle.css';
import { toastAdded } from 'Redux/thunks/global';

class DownloadEntities extends React.Component {
  constructor(props) {
    super(props);

    this.clipboard = new ClipboardJS('.copyToClipboard');
    this.clipboard.on('success', () => {
      this.props.addToast('Copied to clipboard!');
    });

    this.handleSubmit = (ev) => {
      ev.preventDefault();

      const urlWithParams = this.getUrlWithParams(this.props.url, this.state.limit);

      this.props.onDownload(urlWithParams, this.props.token, this.props.type, this.props.fileName);
    };

    this.handleChange = (ev) => {
      const name = get(ev, 'target.name');
      const value = get(ev, 'target.value');

      this.setState({
        [name]: value,
      });
    };

    this.getCurl = (url, limit, token) =>
      `curl -H "Authorization: bearer ${token}" ${this.getUrlWithParams(url, limit)}`;

    this.getUrl = (host, subId, pipeName, limit) =>
      `${host}/subscription/${subId}/pipes/pipe/${pipeName}/output?download=${limit}`;

    this.getUrlWithParams = (url, limit) => `${url}?limit=${limit}`;

    this.state = {
      limit: 10,
    };
  }

  componentDidMount() {
    // Look for ?download=xx and download if found
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const download = get(query, 'download');
    if (download) {
      const urlWithParams = this.getUrlWithParams(this.props.url, download);
      this.props.onDownload(urlWithParams, this.props.token, this.props.type, this.props.fileName);
    }
  }

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  render() {
    const curl = this.getCurl(this.props.url, this.state.limit, this.props.token);

    const url = this.getUrl(
      window.location.origin,
      this.props.subId,
      this.props.pipeName,
      this.state.limit
    );

    return (
      <div className="download-entities">
        <p>
          Download entities by <b>clicking download</b>, by running the <b>cURL command</b> in the
          terminal, or by <b>pasting the URL</b> in the address bar of your browser.
        </p>
        <Form className="download-entities__actions" onSubmit={this.handleSubmit}>
          <div className="download-entities__action">
            <SesamTextField
              value={this.state.limit}
              type="number"
              onChange={this.handleChange}
              name="limit"
              label="Limit"
              id="limit"
            />
          </div>
          <div className="download-entities__action">
            <Button text="Download" type="submit" />
          </div>
        </Form>
        <div className="download-entities__curl-copy copyToClipboard" data-clipboard-text={curl}>
          Copy cURL to clipboard
        </div>
        <div className="download-entities__url-copy copyToClipboard" data-clipboard-text={url}>
          Copy URL to clipboard
        </div>
      </div>
    );
  }
}

DownloadEntities.propTypes = {
  addToast: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  pipeName: PropTypes.string.isRequired,
  subId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  type: PropTypes.string,
  fileName: PropTypes.string,
};

DownloadEntities.defaultProps = {
  type: 'json',
};

const mapDispatchToProps = (dispatch) => ({
  addToast: (message) => {
    dispatch(toastAdded({ message, type: 'success' }));
  },
});

export default compose(connect(null, mapDispatchToProps), withDownload())(DownloadEntities);
