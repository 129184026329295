import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'lodash/isEqual';

import SubActions, {
  findDefaultUrl,
  isProvisioning,
  hasProvisioningFailed,
} from 'Redux/thunks/subscriptions';
import ExternalLink from 'Common/Links/ExternalLink';

import SubscriptionButtons from '../../components/subscription-buttons';
import { SubscriptionAvatar } from '../subscription-avatar/SubscriptionAvatar';
import Card from '../card';

const isInvite = (sub) => get(sub, 'supported-operations.accept_invitation.enabled', false);
const isUsable = (sub) => !isProvisioning(sub) && !isInvite(sub);

class SubscriptionCard extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = () => {
      this.props.router.push(`/subscription/${this.props.sub.id}`);
    };

    this.state = {
      isInvite: isInvite(this.props.sub),
      isUsable: isUsable(this.props.sub),
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props))
      this.setState({
        isInvite: isInvite(this.props.sub),
        isUsable: isUsable(this.props.sub),
      });
  }

  render() {
    const sub = this.props.sub;
    const connectionUrl = findDefaultUrl(sub);
    const actions = <SubscriptionButtons sub={this.props.sub} />;
    const asideActions = [];

    let badges;
    const header = (
      <SubscriptionAvatar
        connectionUrl={connectionUrl}
        customConnectionURL={this.props.customConnectionURL}
        name={sub.name}
        subId={sub.id}
        withLink={true}
      />
    );

    if (this.state.isInvite) {
      asideActions.push('You are invited to join');
    }

    if (!this.state.isInvite && sub.url) {
      asideActions.push(
        <ExternalLink href={sub.url} key="documentation" rel="noopener noreferrer" target="_blank">
          Documentation
        </ExternalLink>
      );
    }

    if (isProvisioning(sub)) {
      badges = { 'in-progress': 'Provisioning' };
    } else if (hasProvisioningFailed(sub)) {
      badges = { 'provisioning-failed': 'Provisioning failed' };
    }

    return (
      <Card
        actions={actions}
        asideActions={asideActions}
        badges={badges}
        header={header}
        onClick={this.onClick || null}
        text={sub.description}
        type={this.state.isInvite ? 'success' : 'default'}
        testid={this.props.testid}
      />
    );
  }
}

SubscriptionCard.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  sub: PropTypes.object.isRequired,
  customConnectionURL: PropTypes.string,
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onAccept: () => dispatch(SubActions.accept(ownProps.sub.id)),
    onDecline: () => dispatch(SubActions.decline(ownProps.sub.id)),
  };
}

const Connected = connect(null, mapDispatchToProps)(withRouter(SubscriptionCard));

export { Connected as SubscriptionCard };
