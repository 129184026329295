import React from 'react';
import PropTypes from 'prop-types';
import SesamLink from 'Common/Links/SesamLink';

import './SesamSystem.css';
import PipeIcon from '../../../images/icons/pipe.svg';
import SystemIcon from '../../../images/icons/system.svg';
import XmlIcon from '../../../images/icons/xml_white.svg';
import HttpIcon from '../../../images/icons/http_white.svg';
import CsvIcon from '../../../images/icons/csv_white.svg';
import ExcelIcon from '../../../images/icons/xls_white.svg';
import WarningIcon from '../../../images/icons/warning.svg';
import { TestID } from 'src/testID';

const DIRECTION_IN = Symbol('Inbound pipe');
const DIRECTION_OUT = Symbol('Outbound pipe');

function getIcon(type) {
  switch (type) {
    case 'http_endpoint': {
      return <HttpIcon width="32" height="32" />;
    }
    case 'xml_endpoint': {
      return <XmlIcon width="32" height="32" />;
    }
    case 'csv_endpoint': {
      return <CsvIcon width="32" height="32" />;
    }
    case 'excel_endpoint': {
      return <ExcelIcon width="32" height="32" />;
    }
    default: {
      return <SystemIcon width="32" height="32" />;
    }
  }
}

function getLabel(type) {
  switch (type) {
    case 'http_endpoint': {
      return 'HTTP Endpoint';
    }
    case 'xml_endpoint': {
      return 'XML Endpoint';
    }
    case 'csv_endpoint': {
      return 'CSV Endpoint';
    }
    case 'excel_endpoint': {
      return 'Excel Endpoint';
    }
    default: {
      return 'System';
    }
  }
}

const SystemPipes = function ({ count, direction, link, isError }) {
  const pipeLabel = count === 1 ? 'pipe' : 'pipes';
  const directionLabel = direction === DIRECTION_IN ? 'inbound' : 'outbound';
  const label = `${count} ${directionLabel} ${pipeLabel}`;

  if (count === 0) {
    return <span title={label}>0</span>;
  }

  if (isError) {
    return (
      <span>
        <WarningIcon className="system__link--error" />
        <SesamLink title={label} to={link}>
          {count}
        </SesamLink>
      </span>
    );
  }

  return (
    <SesamLink title={label} to={link}>
      {count}
    </SesamLink>
  );
};

SystemPipes.propTypes = {
  count: PropTypes.number.isRequired,
  direction: PropTypes.symbol.isRequired,
  link: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
};

/**
 * Draws a clickable system with pipe counts, etc.
 */
export const SesamSystem = function ({
  id,
  name,
  pipesFrom,
  pipesTo,
  pipesFromLink,
  pipesToLink,
  link,
  isInboundError,
  isOutboundError,
}) {
  return (
    <div
      className={`system${isInboundError || isOutboundError ? ' system--error' : ''}`}
      data-id={id}
    >
      <div className="body">
        <h3 className="title" title={name} data-testid={`${TestID.SesamHubPieChartSystem}-${name}`}>
          {name}
        </h3>
        <div className="info info-pipe">
          <PipeIcon className="info-pipe__icon" width="24" height="24" />
          <SystemPipes
            count={pipesTo}
            direction={DIRECTION_OUT}
            link={pipesToLink}
            isError={isInboundError}
          />
          &nbsp;/&nbsp;
          <SystemPipes
            count={pipesFrom}
            direction={DIRECTION_IN}
            link={pipesFromLink}
            isError={isOutboundError}
          />
        </div>
      </div>
      <SesamLink to={link} className="wrapper" title={getLabel(name)}>
        <button className="action action-system">{getIcon(name)}</button>
      </SesamLink>
    </div>
  );
};

SesamSystem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pipesFrom: PropTypes.number.isRequired,
  pipesTo: PropTypes.number.isRequired,
  pipesFromLink: PropTypes.string.isRequired,
  pipesToLink: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isInboundError: PropTypes.bool.isRequired,
  isOutboundError: PropTypes.bool.isRequired,
};

SesamSystem.defaultProps = {
  tailLength: 5,
};
