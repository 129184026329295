import { withTheme } from '@material-ui/core';
import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { theme, darkTheme } from '../../../themes';

Modal.setAppElement('#app');

function SesamModal(props) {
  const style = {
    content: {
      ...props.style.content,
      backgroundColor: props.darkModeActive
        ? darkTheme.palette.background.light
        : theme.palette.background.light,
      color: props.darkModeActive ? darkTheme.palette.text.primary : theme.palette.text.primary,
      border: `1px solid ${
        props.darkModeActive ? darkTheme.palette.divider : theme.palette.divider
      }`,
    },
    overlay: {
      ...props.style.overlay,
      backgroundColor: props.darkModeActive ? darkTheme.palette.overlay : theme.palette.overlay,
    },
  };

  return <Modal {...props} style={style} data-testid={props.testid} />;
}

SesamModal.defaultProps = {
  style: {
    content: {},
    overlay: {},
  },
  darkModeActive: false,
};

SesamModal.propTypes = {
  style: PropTypes.object,
  darkModeActive: PropTypes.bool,
};

export default withTheme(SesamModal);
