import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

const useStyle = makeStyles(() => ({
  container: {
    width: 254,
    height: 70,
    padding: '14px 8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dateAndOffset: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  skeleton: {
    backgroundColor: '#e0e0e0ad',
  },
}));

const EntitySkeleton = () => {
  const classes = useStyle();

  return (
    <div className={classes.container}>
      <div>
        <Skeleton className={classes.skeleton} animation="wave" width={213} variant="text" />
      </div>
      <div className={classes.dateAndOffset}>
        <Skeleton className={classes.skeleton} animation="wave" width={142} variant="text" />
        <Skeleton className={classes.skeleton} animation="wave" width={65} variant="text" />
      </div>
    </div>
  );
};

export default EntitySkeleton;
