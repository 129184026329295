import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import UtilsAPI from '../api/utils';
import { apologise } from 'Redux/thunks/apology';
import { RootState } from '../';

interface DownloadProps {
  url: string;
  mimeType: string;
  fileName: string;
}

function b64EncodeUnicode(str: string) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode('0x' + p1);
    })
  );
}

function getType(mimeType: string) {
  switch (mimeType) {
    case 'application/json':
      return 'json';
    case 'application/zip':
      return 'zip';
    default:
      return 'txt';
  }
}

const useDownload = ({ url, mimeType, fileName }: DownloadProps) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.subscription.token);

  const [downloading, setDownloading] = useState(false);

  const download = useCallback(() => {
    setDownloading(true);
  }, []);

  useEffect(() => {
    async function downloadFromServer() {
      let response;
      try {
        response = await UtilsAPI.download(token, url, mimeType);
      } catch (e) {
        dispatch(apologise(e));
        setDownloading(false);
        return;
      }

      let data;
      let file;
      if (response) {
        if (getType(mimeType) === 'zip') {
          data = await response.blob();
          file = URL.createObjectURL(data);
        } else {
          const resContentType =
            response.headers.get('content-type') || 'text/plain; charset=utf-8';
          const encoding = resContentType.match(/charset=([\s\S]+)$/);

          try {
            if (encoding && encoding[1] && encoding[1] !== 'None') {
              data = await response.arrayBuffer().then((buffer) => {
                const decoder = new TextDecoder(encoding[1]);
                return decoder.decode(buffer);
              });
            } else {
              data = await response.text();
            }
          } catch (e) {}
          file = `data:${mimeType};base64,${b64EncodeUnicode(data)}`;
        }

        if (file) {
          const link = document.createElement('a');
          link.setAttribute('href', file);
          link.setAttribute('download', `${fileName}.${getType(mimeType)}`);
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        setDownloading(false);
      }
    }

    if (downloading) {
      downloadFromServer();
    }
  }, [downloading, token, url, mimeType]);

  return download;
};

export default useDownload;
