import React from 'react';
import PropTypes from 'prop-types';
import SesamLink from 'Common/Links/SesamLink';
import { connect } from 'react-redux';
import { PermissionsEditor } from 'Common/PermissionsEditor';

function PipePermissions(props) {
  const pipeId = props.params.pipeID;
  return (
    <div className="scrollArea">
      <p>
        These settings inherit their default values from the{' '}
        <SesamLink to={`/subscription/${props.subId}/pipes/prototype-permissions`}>
          pipe prototype settings
        </SesamLink>
        .
      </p>
      <PermissionsEditor permissionPath={['pipes', pipeId]} />
    </div>
  );
}

PipePermissions.propTypes = {
  params: PropTypes.shape({
    pipeID: PropTypes.string.isRequired,
  }),
  subId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    subId: state.subscription.id,
  };
}

export default connect(mapStateToProps)(PipePermissions);
