import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import cloneDeep from 'lodash/cloneDeep';
import isNull from 'lodash/isNull';
import mapValues from 'lodash/mapValues';

import Button from 'Common/Button/Button';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import FilterTable, { getSortValues } from '../../../components/filter-table/FilterTable';
import GdprPurposeForm from '../../../components/gdpr-purpose-form/GdprPurposeForm';
import { LoadingPanel } from 'Common/LoadingPanel';

import GdprActions from 'Redux/thunks/gdpr';
import { uuid } from 'Internals/utils';
import { sortWithNullOrUndefinedValues } from 'Internals/sort';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import './GdprPurposePageStyle.css';
import SesamLink from 'Common/Links/SesamLink';

const defaultVisibleCols = [
  'Title',
  'Description',
  'Business process',
  'Language',
  'Legal type',
  'Legal days',
  'Data source',
  'Data target',
  'Edit',
];

const defaultVisibleFacets = ['title', 'business-process', 'lang'];

/**
 * Filter
 */
const filterLabelMapping = {
  title: 'Title',
  ['business-process']: 'Business process',
  lang: 'Language',
};

const emptyFilter = Object.freeze({
  title: {
    selected: [],
    search: [],
  },
  ['business-process']: {
    selected: [],
    search: [],
  },
  lang: {
    selected: [],
    search: [],
  },
});

const defaultFilter = cloneDeep(emptyFilter);

/**
 * Sorting
 */
const defaultSortBy = [
  {
    id: 'title',
    desc: false,
  },
];

/**
 * Removes null values from an object, and replaces them with 'undefined'
 * @param {obj} o
 */
function removeNullValuesFromObject(o) {
  return mapValues(o, (c) => (isNull(c) ? undefined : c));
}

const defaultPurposeTypes = [
  {
    display: 'Consent',
    value: 'consent',
  },
  {
    display: 'Contract',
    value: 'contract',
  },
  {
    display: 'Legal obligation',
    value: 'legal-obligation',
  },
  {
    display: 'Vital interest',
    value: 'vital-interest',
  },
  {
    display: 'Public interest',
    value: 'public-interest',
  },
  {
    display: 'Official authority',
    value: 'official-authority',
  },
  {
    display: 'Legitimate interest',
    value: 'legitimate-interest',
  },
];

const GdprPurposePage = (props) => {
  const {
    addPurpose,
    deletePurpose,
    loadPolicies,
    loadPurposes,
    policies,
    purposes,
    removePurposeFromDatatypes,
    updatePurpose,
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [editPurpose, setEditPurpose] = useState(null);

  useEffect(() => {
    loadPurposes();
    loadPolicies();
  }, []);

  useEffect(() => {
    if (editPurpose) {
      setShowDialog(true);
    }
  }, [editPurpose]);

  const columns = [
    {
      id: 'title',
      Header: 'Title',
      fixed: true,
      accessor: 'title',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: 'description',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'businessProcess',
      Header: 'Business process',
      accessor: 'business-process',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'purposeType',
      Header: 'Legal type',
      accessor: 'purpose-type',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'legalDays',
      Header: 'Legal days',
      accessor: 'legal-days',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'validFrom',
      Header: 'Valid from',
      accessor: 'valid-from',
      Cell: ({ row }) => (
        <div>{row.value ? moment(row.value).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
      ),
      sortType: getSortValues((a, b) => {
        return sortWithNullOrUndefinedValues(new Date(a), new Date(b));
      }),
    },
    {
      id: 'validTo',
      Header: 'Valid to',
      accessor: 'valid-to',
      Cell: ({ row }) => (
        <div>{row.value ? moment(row.value).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
      ),
      sortType: getSortValues((a, b) => {
        return sortWithNullOrUndefinedValues(new Date(a), new Date(b));
      }),
    },
    {
      id: 'dataSource',
      Header: 'Data source',
      accessor: 'data-source',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'dataTarget',
      Header: 'Data target',
      accessor: 'data-target',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'lang',
      Header: 'Language',
      accessor: 'lang',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Edit',
      id: 'edit',
      fixed: true,
      width: 65,
      Cell: ({ row }) => <SesamLink onClick={() => handleEditClick(row.original)}>Edit</SesamLink>,
      align: 'center',
    },
  ];

  const handleCancel = () => {
    setShowDialog(false);
  };

  const handleDeletePurpose = (purpose) => {
    removePurposeFromDatatypes(purpose['_id']).then(() => {
      deletePurpose(purpose);
      setShowDialog(false);
    });
  };

  const handleEditClick = (purpose) => {
    setEditPurpose(purpose);
  };

  const handleNewPurpose = (data) => {
    const purpose = { ...data, ['purpose-id']: uuid() };
    purpose['valid-from'] = purpose['valid-from']
      ? new Date(purpose['valid-from']).toISOString()
      : null;
    purpose['valid-to'] = purpose['valid-to'] ? new Date(purpose['valid-to']).toISOString() : null;

    addPurpose(purpose);
    setShowDialog(false);
  };

  const handleUpdatePurpose = (purpose) => {
    purpose['valid-from'] = purpose['valid-from']
      ? new Date(purpose['valid-from']).toISOString()
      : null;
    purpose['valid-to'] = purpose['valid-to'] ? new Date(purpose['valid-to']).toISOString() : null;

    updatePurpose(purpose);
    setShowDialog(false);
  };

  if (!purposes) {
    return <LoadingPanel />;
  }

  const policyOptions = policies.reduce(
    (acc, policy) => {
      acc.push({
        display: policy['title'],
        value: policy['policy-id'],
      });
      return acc;
    },
    [{ display: 'None', value: 'none' }]
  );

  const defaultValues = {
    ['purpose-id']: '',
    ['title']: '',
    ['business-process']: '',
    ['description']: '',
    ['detail']: '',
    ['legal-days']: '',
    ['purpose-type-id']: defaultPurposeTypes[0].value,
    ['data-source']: '',
    ['data-target']: '',
    ['valid-from']: null,
    ['valid-to']: null,
    ['version']: 0,
    ['lang']: '',
    ['policy-id']: 'none',
  };

  return (
    <ErrorBoundary>
      <main className="scrollArea">
        <div className="gdpr-purpose-page">
          <div className="gdpr-purpose-page__table">
            <FilterTable
              defaults={{
                filter: defaultFilter,
                sortBy: defaultSortBy,
                visibleCols: defaultVisibleCols,
                visibleFacets: defaultVisibleFacets,
              }}
              emptyFilter={emptyFilter}
              filterLabelMapping={filterLabelMapping}
              columns={columns}
              itemsToFilter={purposes}
              tableKey="purposes-2"
            />
          </div>
          <div className="gdpr-purpose-page__new-purpose">
            <Button
              text="Create new purpose"
              onClick={() => {
                setShowDialog(true);
              }}
            />
          </div>
        </div>
        <Dialog
          open={showDialog}
          maxWidth="lg"
          onClose={() => {
            setShowDialog(false);
            setEditPurpose(null);
          }}
        >
          <DialogContent>
            {editPurpose ? (
              <GdprPurposeForm
                defaultValues={defaultValues}
                initialValues={removeNullValuesFromObject(editPurpose)}
                purposeTypes={defaultPurposeTypes}
                inModal
                onCancel={() => {
                  setEditPurpose(null);
                  handleCancel();
                }}
                onUpdate={(purpose) => {
                  handleUpdatePurpose(purpose);
                  setEditPurpose(null);
                }}
                onDelete={(purpose) => {
                  handleDeletePurpose(purpose);
                  setEditPurpose(null);
                }}
                policyOptions={policyOptions}
              />
            ) : (
              <GdprPurposeForm
                defaultValues={defaultValues}
                purposeTypes={defaultPurposeTypes}
                inModal
                newPurpose
                onCancel={handleCancel}
                onSubmit={handleNewPurpose}
                onUpdate={() => {}}
                policyOptions={policyOptions}
              />
            )}
          </DialogContent>
        </Dialog>
      </main>
    </ErrorBoundary>
  );
};

GdprPurposePage.propTypes = {
  addPurpose: PropTypes.func.isRequired,
  datatypes: PropTypes.arrayOf(PropTypes.shape({})),
  deletePurpose: PropTypes.func.isRequired,
  loadPolicies: PropTypes.func.isRequired,
  loadPurposes: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  policies: PropTypes.arrayOf(PropTypes.shape({})),
  portalUrl: PropTypes.string.isRequired,
  purposes: PropTypes.arrayOf(PropTypes.shape({})),
  removePurposeFromDatatypes: PropTypes.func.isRequired,
  router: PropTypes.shape({}).isRequired,
  updatePurpose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  datatypes: state.gdpr.datatypes.data,
  policies: state.gdpr.policies.data,
  portalUrl: state.subscription.url,
  purposes: state.gdpr.purposes.data,
  subId: state.subscription.id,
  token: state.subscription.token,
});

const mapDispatchToProps = (dispatch) => ({
  addPurpose: (purpose) => dispatch(GdprActions.addPurpose(purpose)),
  deletePurpose: (purpose) => dispatch(GdprActions.deletePurpose(purpose)),
  loadDatatypes: () => dispatch(GdprActions.loadDatatypes()),
  loadPolicies: () => dispatch(GdprActions.loadPolicies()),
  loadPurposes: () => dispatch(GdprActions.loadPurposes()),
  removePurposeFromDatatypes: (purpose) =>
    dispatch(GdprActions.removePurposeFromDatatypes(purpose)),
  updatePurpose: (purpose) => dispatch(GdprActions.updatePurpose(purpose)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(GdprPurposePage);
