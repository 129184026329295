import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { getIndexWithoutDeleted, getCompleteLog, getIndexWithDeleted } from 'Internals/datasets';
import { withSeparators } from 'Internals/utils';

import Box from 'Common/Box/Box';
import Button from 'Common/Button/Button';

import SesamCheckboxField from 'Common/SesamCheckboxField/SesamCheckboxField';
import SesamRadioGroupField from 'Common/SesamRadioGroupField/SesamRadioGroupField';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

import { DatasetInspectorContext } from '../DatasetInspectorContext';
import DatasetInspectorFilterSearch from './DatasetInspectorFilterSearch';
import DatasetInspectorFilterJump from './DatasetInspectorFilterJump';
import DisplayModeButton from 'Common/DisplayModeButton';
import './DatasetInspectorFilterStyle.css';
import { DatasetInspectorFilters } from 'Types/enums';
import { TestID } from '../../../testID';

const DatasetInspectorFilter = (props) => {
  const { dataset, filter, subset } = props;
  const [state, actions, showTableView] = useContext(DatasetInspectorContext);

  const showUncommitted =
    dataset.runtime['has-circuit-breaker'] || dataset.runtime['circuit-breaker-tripped'] ? (
      <SesamCheckboxField
        label="Show uncommitted"
        CheckboxProps={{
          onChange: (ev) => actions.setUncommitted(ev.target.checked),
          checked: state.uncommitted,
          disabled: !state.history,
        }}
      />
    ) : null;

  const showSubset = subset ? (
    <div className="dataset-inspectpr-filter__subset">
      <div className="dataset-inspector-filter__control-label">Subset: </div>
      <SesamTextField value={JSON.stringify(subset)} readOnly />
    </div>
  ) : null;

  return (
    <Box className="dataset-inspector-filter">
      <Box.Content padding className="dataset-inspector-filter__content">
        <div className="dataset-inspector-filter__filter">
          <div
            className="dataset-inspector-filter__browse"
            data-testid={TestID.DatasetInspectorFilter}
          >
            <div className="dataset-inspector-filter__control-label">Browse: </div>
            <SesamRadioGroupField
              row
              value={filter}
              onChange={(ev) => actions.setFilter(ev.target.value)}
              radios={[
                {
                  label: `Latest ${
                    !subset ? `(${withSeparators(getIndexWithoutDeleted(dataset))})` : ''
                  }`,
                  value: DatasetInspectorFilters.LatestWithoutDeleted,
                  testid: TestID.DatasetInspectorFilterLatest,
                },
                {
                  label: `Latest w/ deleted ${
                    !subset ? `(${withSeparators(getIndexWithDeleted(dataset))})` : ''
                  }`,
                  value: DatasetInspectorFilters.LatestWithDeleted,
                  testid: TestID.DatasetInspectorFilterDeleted,
                },
                {
                  label: `All ${!subset ? `(${withSeparators(getCompleteLog(dataset))})` : ''}`,
                  value: DatasetInspectorFilters.CompleteLog,
                  testid: TestID.DatasetInspectorFilterAll,
                },
              ]}
            />
            {showUncommitted}
            {showSubset}
            <div className="dataset-inspector-filter__toggle">
              <DisplayModeButton
                buttonValue={showTableView ? 'table' : 'list'}
                value1="list"
                value2="table"
                buttonToggled={actions.toggleTableView}
              />
            </div>
          </div>
          <form
            className="dataset-inspector-filter__search"
            onSubmit={(ev) => {
              ev.preventDefault();
              actions.search();
            }}
          >
            <div className="dataset-inspector-filter__find">
              <div className="dataset-inspector-filter__control">
                <div className="dataset-inspector-filter__control-label">Find: </div>
                <SesamRadioGroupField
                  value={state.searchType}
                  onChange={(ev) => actions.setSearchType(ev.target.value)}
                  radios={[
                    {
                      label: 'Text',
                      value: 'freetext',
                    },
                    {
                      label: 'Id',
                      value: 'id',
                    },
                  ]}
                  row
                />
              </div>
              <DatasetInspectorFilterSearch
                datasetIndexes={state.datasetIndexes}
                onSearchReset={actions.resetSearch}
                onSearchValueChanged={(ev) => actions.setSearchValue(ev.target.value)}
                searchType={state.searchType}
                searchValue={state.searchValue}
              />
            </div>
            <div className="dataset-inspector-filter__jump">
              <div className="dataset-inspector-filter__control">
                <div className="dataset-inspector-filter__control-label">From: </div>
                <SesamRadioGroupField
                  className="dataset-inspector-filter__radio-button-group"
                  onChange={(ev) => actions.setJumpType(ev.target.value)}
                  radios={[
                    {
                      label: 'Sequence',
                      value: 'sequence',
                    },
                    {
                      label: 'Updated',
                      value: 'updated',
                    },
                  ]}
                  row
                  value={state.jumpType}
                />
              </div>
              <DatasetInspectorFilterJump
                jumpSequenceValue={state.jumpSequenceValue}
                jumpType={state.jumpType}
                jumpUpdatedValue={state.jumpUpdatedValue}
                onJumpReset={actions.resetJump}
                onJumpSequenceValueChanged={actions.setJumpSequenceValue}
                onJumpUpdatedValueChanged={actions.setJumpUpdatedValue}
              />
            </div>
            <Button
              type="submit"
              color="primary"
              className="dataset-inspector-filter__action"
              style={{
                marginRight: '0.625rem',
                alignSelf: 'flex-end',
                marginBottom: '0.5rem',
              }}
            >
              Go
            </Button>
            <Button
              onClick={actions.reset}
              className="dataset-inspector-filter__action "
              style={{ alignSelf: 'flex-end', marginBottom: '0.5rem' }}
            >
              Reset
            </Button>
          </form>
        </div>
      </Box.Content>
    </Box>
  );
};

DatasetInspectorFilter.propTypes = {
  dataset: PropTypes.shape(),
  filter: PropTypes.string.isRequired,
  subset: PropTypes.shape([]),
};

export { DatasetInspectorFilter };
