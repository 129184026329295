import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import { buildClassName, dataAttrsFromProps } from 'Internals/react-utils';
import Menu from '../menu';
import Popover, { AnchorPointPropShape } from '../popover';

import './style.css';

const viewport = window;
const KEY_ESCAPE = 27;
const INTERACTIVE_ELEMENTS = ['A', 'AREA', 'BUTTON', 'INPUT'];

const isOrContains = (containerEl, childEl) =>
  containerEl === childEl || containerEl.contains(childEl);

const isInteractiveElement = (el) => INTERACTIVE_ELEMENTS.includes(el.nodeName);

class MenuToggler extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: props.startOpen };
    this.assessListeners();

    this.handleToggle = (ev) => {
      ev.preventDefault();
      this.setState({ isOpen: !this.state.isOpen }, () => {
        if (this.state.isOpen) {
          const firstItem = this.popover.querySelector(
            INTERACTIVE_ELEMENTS.map((tag) => `${tag.toLowerCase()}:not(:disabled)`).join(',')
          );
          if (firstItem) {
            firstItem.focus();
          }
        }
      });
    };

    this.handleOnClick = (ev) => {
      const target = ev.target;

      if (target !== this.anchor) {
        if (this.props.closeOnClickOutside && !isOrContains(this.popover, target)) {
          this.setState({ isOpen: false });
        } else if (this.props.closeOnInteraction && isInteractiveElement(target)) {
          this.setState({ isOpen: false });
        }
      }
    };

    this.handleOnEsc = (ev) => {
      if (this.props.closeOnEsc && ev.keyCode === KEY_ESCAPE) {
        this.setState({ isOpen: false });
      }
    };
  }

  getChildContext() {
    return {
      // We pass along a parent getMenuToggler context, if one exists
      getMenuToggler: this.context.getMenuToggler || (() => this),
    };
  }

  componentDidUpdate() {
    this.assessListeners();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  assessListeners() {
    if (this.state.isOpen && !this.handlersRegistered) {
      this.addListeners();
    } else if (!this.state.isOpen) {
      this.removeListeners();
    }
  }

  addListeners() {
    viewport.addEventListener('click', this.handleOnClick);
    viewport.addEventListener('keydown', this.handleOnEsc);
    this.handlersRegistered = true;
  }

  removeListeners() {
    viewport.removeEventListener('click', this.handleOnClick);
    viewport.removeEventListener('keydown', this.handleOnEsc);
    this.handlersRegistered = false;
  }

  render() {
    const classNames = ['menu-toggler'];

    if (this.state.isOpen) {
      classNames.push('menu-toggler--open');
    }

    // The "uiMountPoint" element serves as a placeholder to anchor components
    // spawned by the menu's children, e.g. modals or other UI that can remain
    // active after the menu closes (closing the meny will close the Popover
    // and any MenuItems within). The uiMountPoint can be retrieved via context
    // in child elements: context.getMenuToggler().uiMountPoint

    return (
      <div
        className={classNames.join(' ')}
        ref={(el) => {
          this.uiMountPoint = el;
        }}
      >
        <button
          className={buildClassName('menu-toggler__toggler', this.props.labelClassName)}
          onClick={this.handleToggle}
          ref={(anchor) => {
            this.anchor = anchor;
          }}
          style={
            !this.props.isDashboardIcon
              ? {
                  color: this.props.active
                    ? this.state.isOpen
                      ? this.props.theme.palette.text.primary
                      : 'white'
                    : this.props.theme.palette.text.primary,
                  backgroundColor: this.state.isOpen
                    ? this.props.theme.palette.background.light
                    : this.props.active
                    ? this.props.theme.palette.primary.main
                    : null,
                }
              : {}
          }
          {...dataAttrsFromProps(this.props)}
        >
          <div className="menu-toggler__label">{this.props.label}</div>
        </button>
        <Popover
          anchor={this.anchor}
          anchorPoint={this.props.anchorPoint}
          className="menu-toggler-popover"
          isOpen={this.state.isOpen}
          refPopover={(popover) => {
            this.popover = popover;
          }}
        >
          <Menu>{this.props.children}</Menu>
        </Popover>
      </div>
    );
  }
}

MenuToggler.propTypes = {
  anchorPoint: AnchorPointPropShape,
  children: PropTypes.node.isRequired,
  closeOnClickOutside: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
  closeOnInteraction: PropTypes.bool,
  label: PropTypes.node.isRequired,
  labelClassName: PropTypes.string,
  startOpen: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
  active: PropTypes.bool,
  isDashboardIcon: PropTypes.bool,
};

MenuToggler.defaultProps = {
  closeOnClickOutside: true,
  closeOnEsc: true,
  closeOnInteraction: true,
  startOpen: false,
  labelClassName: 'menu-toggler__toggler-default',
  active: false,
  isDashboardIcon: false,
};

MenuToggler.childContextTypes = {
  getMenuToggler: PropTypes.func,
};

MenuToggler.contextTypes = {
  getMenuToggler: PropTypes.func,
};

export default withTheme(MenuToggler);
