import yup from 'yup';

export default yup.object().shape({
  type: yup.string().required(),
  name: yup.string(),
  description: yup.string(),
  extra_rule_info: yup.object().shape({
    extra_grace_period: yup
      .number()
      .typeError('The value of this field must be a number!')
      .integer('The value of this field must be a whole number!')
      .positive('The value of this field cannot be negative!')
      .nullable(true),
    limit: yup
      .number()
      .typeError('The value of this field must be a number!')
      .integer('The value of this field must be a whole number!')
      .positive('The value of this field cannot be negative!')
      .nullable(true),
    parameter: yup.string().nullable(),
    interval: yup.number().nullable(),
    event_type: yup.string().nullable(),
  }),
  recipients: yup.array(
    yup.object().shape({
      id: yup.string(),
      type: yup.string(),
      methods: yup
        .array(yup.mixed().oneOf(['email', 'portal']))
        .required('A recipient must have either the email or the portal option selected!'),
    })
  ),
});
