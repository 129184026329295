import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyle = makeStyles((theme) => {
  return {
    notification: {
      color: theme.palette.text.danger,
    },
  };
});

function DangerText({ className, ...props }) {
  const classes = useStyle();
  let fullClassName = classes.notification;
  if (className) {
    fullClassName = fullClassName + ' ' + className;
  }
  return <Typography className={fullClassName} {...props} />;
}

DangerText.propTypes = {
  className: PropTypes.string,
};

export default DangerText;
