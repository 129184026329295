import { sfetchJson } from 'Internals/sfetch';

const baseUrl = '/api/notifications';

export type Alert = any;

async function getAll(since: Date): Promise<Alert[]> {
  const url = `${baseUrl}?since=${since.toISOString()}`;
  const options: RequestInit = {
    credentials: 'include',
  };
  return await sfetchJson(url, options);
}

export default {
  getAll,
};
