import produce from 'immer';
import get from 'lodash/get';

import { createReducer } from '@reduxjs/toolkit';
import { getGroup, isTenant } from 'Internals/systems';
import { SystemMap } from '../../types/types';
import { disconnected } from '../thunks/subscriptions';
import {
  systemAdded,
  systemUpdated,
  systemLoaded,
  systemLoadAllFinished,
  systemRemoved,
  systemSecretsLoaded,
  systemSecretsAdded,
  systemSecretsRemoved,
} from '../thunks/systems';

// -----------------------------------------------------------------------------

const isInternalSystemRegex = /^(system:)/;

type SystemsState = SystemMap;

const initialState: SystemsState = {};

export const decorateSystem = (rawSystem: any) =>
  produce(rawSystem, (system) => {
    const id = system._id;

    system.link = encodeURIComponent(id);
    system.origin = system.runtime.origin;
    if (system.origin == null) {
      // fallback until all nodes expose this
      system.origin = isInternalSystemRegex.test(id) ? 'system' : 'user';
    }
    system.isInternal = system.origin !== 'user';
    // TODO baard: workaround for IS-2857
    if (system.config.effective) {
      system.name = system.config.effective.name || system._id;
      system.type = system.config.effective.type;
    } else {
      system.name = system._id;
      system.type = system.config.type;
    }
    system.tags = [];
    if (rawSystem.storage) {
      if (typeof rawSystem.storage === 'object') {
        system.bytesUsed = rawSystem.storage.total;
      } else {
        system.bytesUsed = rawSystem.storage;
      }
    }
    const userTags = get(rawSystem, 'config.original.metadata.tags');
    if (userTags) {
      if (Array.isArray(userTags)) {
        system.tags = system.tags.concat(userTags);
      } else {
        system.tags.push(userTags);
      }
    }
    if (
      rawSystem.config.effective.metadata &&
      rawSystem.config.effective.metadata['$config-group']
    ) {
      system.configGroup = rawSystem.config.effective.metadata['$config-group'];
    } else {
      system.configGroup = 'Default';
    }
    system.systemGroup = getGroup(rawSystem);
    system.tenant = isTenant(rawSystem) ? isTenant(rawSystem) : 'Not Tenant';
  });

// -----------------------------------------------------------------------------

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(disconnected, () => initialState)
    .addCase(systemAdded, (state, action) => {
      state[action.payload._id] = decorateSystem(action.payload);
    })

    .addCase(systemUpdated, (state, action) => {
      state[action.payload._id] = decorateSystem(action.payload);
    })
    .addCase(systemLoaded, (state, action) => {
      state[action.payload.id] = decorateSystem(action.payload.system);
    })

    .addCase(systemLoadAllFinished, (state, action) => {
      let newState: SystemsState = {};
      for (let i = 0; i < action.payload.length; i++) {
        newState[action.payload[i]._id] = decorateSystem(action.payload[i]);
      }
      return newState;
    })

    .addCase(systemRemoved, (state, action) => {
      delete state[action.payload];
    })

    .addCase(systemSecretsLoaded, (state, action) => {
      if (state[action.payload.id]) {
        state[action.payload.id].secrets = action.payload.secrets;
      }
    })

    .addCase(systemSecretsAdded, (state, action) => {
      let system = state[action.payload.id];
      if (system) {
        if (Array.isArray(system.secrets)) {
          for (const secretName of action.payload.secrets) {
            if (!system.secrets.includes(secretName)) {
              system.secrets.push(secretName);
            }
          }
        } else {
          system.secrets = action.payload.secrets;
        }
      }
    })

    .addCase(systemSecretsRemoved, (state, action) => {
      let system = state[action.payload.id];
      if (system) {
        if (Array.isArray(system.secrets)) {
          const index = system.secrets.findIndex((s: string) => s === action.payload.secretName);
          if (index !== -1) {
            system.secrets.splice(index, 1);
          }
        }
      }
    })
);

export default reducer;
