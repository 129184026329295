import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { layoutChanged } from 'Redux/thunks/global';
import SesamLink from 'Common/Links/SesamLink';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getFromLocalStorage, setIntoLocalStorage } from 'Internals/local-storage';

import SlideLeftIcon from '../../images/icons/slide-left.svg';
import SlideRightIcon from '../../images/icons/slide-right.svg';
import { TestID } from '../../testID';

import './NavBar.css';

const useStyle = makeStyles((theme) => {
  return {
    navbar: {
      backgroundColor: theme.palette.background.light,
      borderLeftWidth: 0,
      borderRight: `1px solid ${theme.palette.divider}`,
      borderTopWidth: 0,
      position: 'relative',
      width: '16rem',
    },
    navbarCollapsed: {
      width: '3.75rem',
      '& $navbarMenuLabel': {
        fontSize: '0.75rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      },
      '& .navbar__footer': {
        fontSize: 'smaller',
        transform: 'rotate(-90deg) translateX(-16rem)',
        marginLeft: '-13rem',
        paddingTop: '14.5rem',
      },
    },
    navbarToggler: {
      display: 'block',
      backgroundColor: theme.palette.background.dark,
      borderWidth: '0',
      color: theme.palette.grey[50],
      padding: '0 0.75rem',
      textAlign: 'right',
      width: '100%',
      '&:focus': {
        outlineWidth: 0,
        color: theme.palette.grey[100],
      },
      '&:hover': {
        color: theme.palette.grey[100],
      },
      '& svg': {
        display: 'block',
        height: '2em',
        marginLeft: 'auto',
        padding: '0.25em 0',
        width: '2em',
      },
    },
    navbarMenu: {
      paddingLeft: 0,
      margin: 0,
    },
    navbarMenuPrimaryItem: {
      backgroundColor: theme.palette.background.middle,
      color: theme.palette.text.primary,
      display: 'block',
      padding: '0.5em 0.625em',
    },
    navbarMenuGroup: {
      display: 'block',
      '& ul': {
        padding: 0,
      },
    },
    navbarMenuLabel: {
      backgroundColor: theme.palette.background.semilight,
      color: theme.palette.text.primary,
      height: '2.125rem',
      lineHeight: '1.125rem',
      padding: '0.5rem 0.75rem',
    },
    navbarMenuItem: {
      display: 'block',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:hover $navbarMenuLink': {
        backgroundColor: 'transparent',
        color: theme.palette.common.white,
      },
    },
    navbarMenuLink: {
      alignItems: 'center',
      display: 'flex',
      color: theme.palette.text.primary,
      lineHeight: 2,
      overflow: 'hidden',
      padding: '0.25rem 0.75rem',
      position: 'relative',
      textDecoration: 'none',
      '& svg': {
        flex: '0 0 2rem',
        marginRight: '1.25em',
        maxHeight: '2rem',
        maxWidth: '2rem',
        width: '2rem',
        zIndex: '200',
      },
    },
    navbarMenuLinkActive: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    navbarMenuLinkDisabled: {
      opacity: '0.33',
    },
  };
});

const LOCAL_STORAGE_KEY = 'sesam--navbar';

function NavBar(props) {
  const { footerCollapsed, footerExpanded, navLinks, primaryItem, statusBarCollapsed, subId } =
    props;

  const classes = useStyle();

  const initialCollapsed = get(props, ['location', 'query', 'collapsed'], false);
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  useEffect(() => {
    if (subId !== undefined) {
      const collapsedValue = getFromLocalStorage(LOCAL_STORAGE_KEY, subId, initialCollapsed);

      setCollapsed(collapsedValue);
    }
  }, [initialCollapsed, subId]);

  const handleToggle = () => {
    setIntoLocalStorage(LOCAL_STORAGE_KEY, subId, !collapsed);
    setCollapsed(!collapsed);
  };

  const renderMenuItem = (menuItem) => {
    if (Array.isArray(menuItem)) {
      return (
        <li className={classes.navbarMenuGroup} key={menuItem.label}>
          <h2 className={classes.navbarMenuLabel}>{menuItem.label}</h2>
          <ul>{menuItem.map(renderMenuItem)}</ul>
        </li>
      );
    }

    return (
      <li className={classes.navbarMenuItem} key={menuItem.to}>
        <SesamLink
          activeClassName={classes.navbarMenuLinkActive}
          className={clsx([
            classes.navbarMenuLink,
            menuItem.disabled && classes.navbarMenuLinkDisabled,
          ])}
          title={menuItem.title}
          to={menuItem.to}
          data-selenium="navbarMenuLink"
          buttonLink={true}
        >
          {menuItem.icon}
          <span data-testid={`${TestID.AppBarMenuItem}-${menuItem.title}`}>{menuItem.title}</span>
        </SesamLink>
      </li>
    );
  };

  const footer = collapsed ? footerCollapsed : footerExpanded;
  return (
    <div className={clsx([classes.navbar, collapsed && classes.navbarCollapsed])}>
      <PerfectScrollbar>
        <button className={classes.navbarToggler} onClick={handleToggle} title="Toggle menu">
          {collapsed ? <SlideRightIcon /> : <SlideLeftIcon />}
        </button>
        <ul className={classes.navbarMenu}>
          {primaryItem && <li className={classes.navbarMenuPrimaryItem}>{primaryItem}</li>}
          {navLinks.map(renderMenuItem)}
        </ul>
        <div className="navbar__footer">{footer}</div>
        {collapsed && statusBarCollapsed}
      </PerfectScrollbar>
    </div>
  );
}

NavBar.propTypes = {
  footerCollapsed: PropTypes.node,
  footerExpanded: PropTypes.node,
  statusBarCollapsed: PropTypes.node,
  layoutChange: PropTypes.func.isRequired,
  navLinks: PropTypes.array,
  primaryItem: PropTypes.node,
  subId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({}),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    subId: state.subscription.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    layoutChange: () => dispatch(layoutChanged()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
