import PropTypes from 'prop-types';
import React from 'react';

import './style.css';
import '../../style/material-typography.css';
import '../../style/material-shadow.css';

class MaterialDialog extends React.Component {
  render() {
    let className = 'material-dialog';
    if (this.props.className) {
      className += ' ' + this.props.className;
    }

    return (
      <div className={className}>
        <div className="material-dialog__dialog material-shadow-5">
          <div className="material-dialog__dialog-header material-headline">
            {this.props.header}
          </div>
          <div className="material-dialog__dialog-content">{this.props.children}</div>
        </div>
        <div className="material-dialog__overlay" onClick={this.props.onOverlayClick} />
      </div>
    );
  }
}

MaterialDialog.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  onOverlayClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default MaterialDialog;
