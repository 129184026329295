import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SesamCheckbox from '../SesamCheckbox/SesamCheckbox';
import { CheckboxProps } from '@material-ui/core/Checkbox';

const useStyle = makeStyles(() => {
  return {
    root: {
      padding: '0',
    },
  };
});

const SesamCheckboxCompact: React.FC<CheckboxProps> = (props) => {
  const classes = useStyle();

  return <SesamCheckbox {...props} classes={classes} />;
};

export default SesamCheckboxCompact;
