import React from 'react';
import { FormikProps } from 'formik';

import RecipientsEditor from '../../recipients-editor/RecipientsEditor';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import { Recipient } from '../../recipients-editor/RecipientsEditor';
import { RoleInfo, SubscriptionMemberInfo } from '../../../types/types';

interface FormValues {
  description: string;
  name: string;
  recipients: Recipient[];
}

interface ValueTooHighOverTimeFormProps {
  availableRoles: RoleInfo[];
  members: SubscriptionMemberInfo[];
}

const parameterData = [
  { name: 'Additional low-level errormessage', id: 'original_error_message' },
  { name: 'Additional low-level traceback', id: 'original_traceback' },
  { name: 'Traceback', id: 'traceback' },
  { name: 'Reason why stopped', id: 'reason_why_stopped' },
];

const parameterDescriptions = {
  original_error_message:
    'If something went wrong, this can in some cases contain an additional low-level description of what went wrong. Note: If no additional information is available, this property will be an empty string.',
  original_traceback:
    'If something went wrong, this can in some cases contain an additional low-level stacktrace of the exception. Note: If no additional information is available, this property will be an empty string.',
  reason_why_stopped: 'If something went wrong, this contains a description of what went wrong. ',
  traceback:
    "If something went wrong, this contains the stacktrace of the exception. (Tip: It is usually better to use the 'Reason why stopped' parameter instead, since that usually contains the relevant information and is less verbose.)",
};

const eventTypeData = [
  { id: 'pump-failed', name: 'Pump failed' },
  { id: 'read-error', name: 'Read error' },
  { id: 'write-error', name: 'Write error' },
  { id: 'batch-write-error', name: 'Batch write error' },
  { id: 'pump-offset-set', name: 'Pump offset set' },
  { id: 'restore-completed', name: 'Restore completed' },
];

const eventTypeDescriptions = {
  ['batch-write-error']:
    'An event of this type is emitted when the pipe fails to write a batch of entities to the sink.',
  ['pump-failed']:
    'An event of this type is emitted when a pipe-run fails to complete successfully.',
  ['pump-offset-set']:
    'An event of this type is emitted when the pump offset is set. This can happen if an upstream dataset has been restored from backup, or if a user has manually set the pump offset (for instance by resetting the pipe).',
  ['read-error']:
    'An event of this type is emitted when the pipe fails to read entities from the sink.',
  ['restore-completed']:
    'An event of this type is emitted if the pipe and its associated data and state has been restored from backup.',
  ['write-error']:
    'An event of this type is emitted when the pipe fails to write an entity to the sink.',
};

const ValueTooHighOverTimeForm: React.FC<ValueTooHighOverTimeFormProps & FormikProps<FormValues>> =
  ({ availableRoles, handleChange, handleBlur, errors, members, setValues, values }) => {
    return (
      <React.Fragment>
        <SesamTextField
          autoFocus
          error={errors.extra_rule_info && errors.extra_rule_info.event_type}
          helperText={
            (errors.extra_rule_info && errors.extra_rule_info.event_type) ||
            (values.extra_rule_info &&
              values.extra_rule_info.event_type &&
              eventTypeDescriptions[values.extra_rule_info.event_type]) ||
            'The name of the event type to check.'
          }
          label="Event type"
          margin="normal"
          name="extra_rule_info.event_type"
          onBlur={handleBlur}
          onChange={handleChange}
          select
          value={values.extra_rule_info.event_type || ''}
        >
          {eventTypeData.map((data) => {
            return (
              <SesamMenuItem key={data.id} value={data.id}>
                {data.name}
              </SesamMenuItem>
            );
          })}
        </SesamTextField>
        <SesamTextField
          error={errors.extra_rule_info && errors.extra_rule_info.parameter}
          helperText={
            (errors.extra_rule_info && errors.extra_rule_info.parameter) ||
            (values.extra_rule_info &&
              values.extra_rule_info.parameter &&
              parameterDescriptions[values.extra_rule_info.parameter]) ||
            'The name of the parameter to check.'
          }
          label="Parameter"
          margin="normal"
          name="extra_rule_info.parameter"
          onBlur={handleBlur}
          onChange={handleChange}
          select
          value={values.extra_rule_info.parameter || ''}
        >
          {parameterData.map((data) => {
            return (
              <SesamMenuItem key={data.id} value={data.id}>
                {data.name}
              </SesamMenuItem>
            );
          })}
        </SesamTextField>
        <SesamTextField
          error={errors.extra_rule_info && errors.extra_rule_info.pattern}
          helperText={
            (errors.extra_rule_info && errors.extra_rule_info.pattern) ||
            'The maximum value of the parameter.'
          }
          label="Pattern"
          margin="normal"
          name="extra_rule_info.pattern"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.extra_rule_info.pattern || ''}
        />

        <SesamTextField
          helperText="A human readable name for this rule"
          label="Name"
          margin="normal"
          name="name"
          onChange={handleChange}
          value={values.name}
        />
        <SesamTextField
          helperText="A description of the business logic behind this rule"
          label="Description"
          margin="normal"
          multiline
          name="description"
          onChange={handleChange}
          rows={4}
          value={values.description}
        />
        <RecipientsEditor
          availableRoles={availableRoles}
          members={members}
          onChange={(recipients) => {
            setValues({ ...values, recipients });
          }}
          recipients={values.recipients}
          style={{ marginTop: '30px' }}
          TextFieldProps={{
            helperText:
              (errors.recipients && errors.recipients[0] && errors.recipients[0].methods) || ' ',
            margin: 'normal',
            error: Boolean(
              errors.recipients && errors.recipients[0] && errors.recipients[0].methods
            ),
          }}
        />
      </React.Fragment>
    );
  };

export default ValueTooHighOverTimeForm;
