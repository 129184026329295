import { DatasetInspectorContext } from '../DatasetInspectorContext';
import {
  generateTableColumnsFromEntities,
  generateTableColumnsFromEntityType,
} from 'Internals/table';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useContext } from 'react';
import usePipeEntityTypes from '../../../hooks/usePipeEntityTypes';

import Box from 'Common/Box/Box';
import DatasetTable from './dataset-table/DatasetTable';
import DatasetInspectorMessage from '../dataset-inspector-message/DatasetInspectorMessage';

import './DatasetInspectorTableStyle.css';

const DatasetInspectorTable = (props) => {
  const { onScroll, onSetTableContainerRef, upstreamPipeId } = props;

  const [showSystemAttr, setShowSystemAttr] = useState(false);
  const [state, actions] = useContext(DatasetInspectorContext);
  const upstreamPipeEntityTypes = usePipeEntityTypes(upstreamPipeId);

  const customMessageActions = [
    <div key="1" className="dataset-inspector-table__checkbox-group">
      <label htmlFor="showSystemAttr" className="dataset-inspector-table__checkbox-group-label">
        Show system attributes
      </label>
      <input
        id="showSystemAttr"
        type="checkbox"
        checked={showSystemAttr}
        onChange={() => setShowSystemAttr((c) => !c)}
      />
    </div>,
  ];

  const schema = useMemo(() => {
    if (!upstreamPipeEntityTypes || Object.keys(upstreamPipeEntityTypes.properties).length === 0) {
      return generateTableColumnsFromEntities(state.entities, showSystemAttr, true);
    } else {
      return generateTableColumnsFromEntityType(upstreamPipeEntityTypes, showSystemAttr, true);
    }
  }, [state.entities, showSystemAttr, upstreamPipeEntityTypes]);

  const selectedEntityOffset = state.selectedEntity ? state.selectedEntity['_updated'] : undefined;

  return (
    <div className="dataset-inspector-table__table">
      <Box>
        <Box.Header>
          <DatasetInspectorMessage
            customActions={customMessageActions}
            filter={props.filter}
            dataset={props.dataset}
          />
        </Box.Header>
        <Box.Content white>
          <DatasetTable
            data={state.entities}
            columns={schema}
            onScroll={onScroll}
            onSelectEntity={actions.selectEntity}
            onSetTableContainerRef={onSetTableContainerRef}
            selectedEntityOffset={selectedEntityOffset}
            hasJumpValue={
              state.jumpSequenceValue !== undefined || state.jumpUpdatedValue !== undefined
            }
            populating={state.populating}
            loading={state.loading}
            loadingMore={state.loadingMore}
            loadingMoreTop={state.loadingMoreTop}
          />
        </Box.Content>
      </Box>
    </div>
  );
};

DatasetInspectorTable.propTypes = {
  onScroll: PropTypes.func.isRequired,
  onSetTableContainerRef: PropTypes.func.isRequired,
  upstreamPipeId: PropTypes.string,
  filter: PropTypes.shape({}).isRequired,
  dataset: PropTypes.shape({}).isRequired,
};

export default DatasetInspectorTable;
