import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import EditorPanelDivider from './EditorPanelDivider';

function Replacements(props) {
  if (!props.showTemplates) {
    return null;
  }
  return (
    <div className="stretchy-panel editor__panel editor__panel--replacements">
      <h3 className="head">Templates</h3>
      <div className="body">{props.children}</div>
    </div>
  );
}

Replacements.propTypes = {
  children: PropTypes.node,
  showTemplates: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return { showTemplates: state.editor.visiblePanels.templates };
}

export default connect(mapStateToProps)(Replacements);

export function Replacement(props) {
  const buttonLabel = props.buttonLabel || 'Replace';
  return (
    <div>
      <EditorPanelDivider>
        <EditorPanelDivider.Title>{props.title}</EditorPanelDivider.Title>
      </EditorPanelDivider>
      <div className="editor__panel-content">
        <Form component="div">
          {props.children}
          <FormActions>
            {props.actions}
            <Button disabled={props.disabled} onClick={props.onReplaceTemplate}>
              {buttonLabel}
            </Button>
          </FormActions>
        </Form>
      </div>
    </div>
  );
}

Replacement.propTypes = {
  actions: PropTypes.node,
  buttonLabel: PropTypes.string,
  children: PropTypes.node,
  onReplaceTemplate: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
