import sfetch, { sfetchJson } from 'Internals/sfetch';
import { ApiConf, Dataset, NodeComponent } from '../types/types';
import { Json } from '../types/json';

async function get(apiConf: ApiConf, id?: string, verbose = true): Promise<Dataset> {
  if (!id) return Promise.reject(new Error('ID for dataset not supplied'));
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  let url = `${subUrl}/datasets/${idUrl}${verbose ? '?verbose=true' : ''}`;

  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  return await sfetchJson(url, options);
}

async function getAll(apiConf: ApiConf, includeInternal = true): Promise<NodeComponent[]> {
  const { subUrl, token } = apiConf;
  let url = `${subUrl}/datasets`;

  // unlike with pipes and systems
  // the datasets get all by default
  if (!includeInternal) {
    url = url + '?include-internal-datasets=false';
  }

  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  return await sfetchJson(url, options);
}

async function rollback(apiConf: ApiConf, id: string): Promise<Response> {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/datasets/${idUrl}`;

  const data = new URLSearchParams();
  data.append('operation', 'rollback-circuit-breaker');

  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: data,
  };
  return await sfetch(url, options);
}

async function commit(apiConf: ApiConf, id: string): Promise<Response> {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/datasets/${idUrl}`;

  const data = new URLSearchParams();
  data.append('operation', 'commit-circuit-breaker');

  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: data,
  };
  return await sfetch(url, options);
}

async function getIndexes(apiConf: ApiConf, id: string): Promise<Json> {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/datasets/${idUrl}/indexes`;

  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
  };
  return await sfetchJson(url, options);
}

async function removeIndexes(apiConf: ApiConf, datasetId: string, id: string): Promise<Json> {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/datasets/${encodeURIComponent(datasetId)}/indexes/${id}`;
  const options: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `bearer ${token}`,
    },
    method: 'DELETE',
  };
  return await sfetchJson(url, options);
}

async function remove(apiConf: ApiConf, id: string): Promise<Response> {
  const { subUrl, token } = apiConf;
  const idUrl = encodeURIComponent(id);
  const url = `${subUrl}/datasets/${idUrl}`;

  const options: RequestInit = {
    credentials: 'include',
    headers: { Authorization: `bearer ${token}` },
    method: 'DELETE',
  };
  return await sfetch(url, options);
}

export default {
  get,
  getAll,
  rollback,
  commit,
  getIndexes,
  removeIndexes,
  remove,
};
