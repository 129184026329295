import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Flows from 'Internals/flows';
import SesamHub from '../../components/hub/SesamHub';
import DatasetActions, { datasetsSelector } from 'Redux/thunks/datasets';
import OverviewInstructions from '../../../components/instructions-splash/instructions-overview';
import Page from '../../../components/page';
import PipeActions from 'Redux/thunks/pipes';
import SystemActions from 'Redux/thunks/systems';
import { pipesSelector, systemsSelector } from 'Redux/selectors';
import { LoadingPanel } from 'Common/LoadingPanel';
import { groupSystems, isNotInternal } from './overviewUtils';
import { SubscriptionOverview } from './SubscriptionOverview.view';

import './OverviewStyle.css';
import { Flow } from 'Internals/overview-utils';
import { AppDispatch, RootState } from 'src';
import {
  Dataset,
  DownstreamMap,
  InboundMap,
  LookupMap, 
  OutboundMap,
  PipeMap,
  System,
  TransformMap,
} from 'Types/types';
import { useSystemNodes } from 'Hooks/useSystemNodes';
import { useOverviewDatasets } from 'Hooks/useOverviewDatasets';

export const NODE_SYSTEM = 'system:sesam-node:sesam:node';

type OverviewProps = {
  datasets: Dataset[];
  pipes: PipeMap;
  downstreams: DownstreamMap;
  lookups: LookupMap;
  inbounds: InboundMap;
  outbounds: OutboundMap;
  subId: string;
  systems: System[];
  everythingLoaded: boolean;
  hasUserDatasets: boolean;
  hasUserPipes: boolean;
  hasUserSystems: boolean;
  loadEverything: () => void;
  darkModeActive: boolean;
  transforms: TransformMap;
  context: {
    router: any;
  };
};

const Overview: React.FC<OverviewProps> = (props) => {
  const {
    context,
    datasets,
    pipes,
    downstreams,
    lookups,
    inbounds,
    outbounds,
    subId,
    systems,
    everythingLoaded,
    hasUserDatasets,
    hasUserPipes,
    hasUserSystems,
    loadEverything,
    darkModeActive,
    transforms,
  } = props;

  const onDatasetClick = () => {
    context.router.push(`/subscription/${subId}/pipes`);
  };

  const onInternalPipesClick = () => {
    context.router.push(
      `/subscription/${subId}/pipes?config.effective.source.system=${NODE_SYSTEM}&config.effective.sink.system=${NODE_SYSTEM}`
    );
  };

  const onSystemClick = () => {
    context.router.push(`/subscription/${subId}/systems`);
  };

  const [flows, setFlows] = useState<Flow[]>();

  useEffect(() => {
    loadEverything();
  }, [loadEverything]);
  
  useEffect(() => {
    Flows.get(pipes, downstreams, lookups).then((flows) => {
      setFlows(flows);
    });
  }, [pipes, downstreams, lookups]);

  const filteredSystemNodes = systems.filter(isNotInternal).reduce(groupSystems, {});
  const filteredSystemNodesArray = Object.values(filteredSystemNodes);
  const systemNodes = useSystemNodes({
    systemNodes: filteredSystemNodesArray,
    pipes,
    inbounds,
    outbounds,
    transforms,
    darkModeActive,
    flows,
    subId,
  });

  const { sumLatest, sumDeleted, sumHistory } = useOverviewDatasets(datasets);

  if (!everythingLoaded) {
    return <LoadingPanel />;
  }

  if (!hasUserDatasets || !hasUserPipes) {
    return (
      <Page dataSelenium="subscription-overview-pageview">
        <OverviewInstructions
          subId={subId}
          hasUserDatasets={hasUserDatasets}
          hasUserPipes={hasUserPipes}
          hasUserSystems={hasUserSystems}
        />
      </Page>
    );
  }

  return (
    <SubscriptionOverview>
      <SesamHub
        sumLatest={sumLatest}
        sumDeleted={sumDeleted}
        sumHistory={sumHistory}
        onDatasetClick={onDatasetClick}
        onPipeClick={onInternalPipesClick}
        onSystemClick={onSystemClick}
        subscriptionId={subId}
        systems={systemNodes}
        darkModeActive={darkModeActive}
      />
    </SubscriptionOverview>
  );
};

function mapStateToProps(state: RootState) {
  return {
    datasets: datasetsSelector(state),
    everythingLoaded:
      state.loadStatus.datasetsLoaded &&
      state.loadStatus.pipesLoaded &&
      state.loadStatus.systemsLoaded,
    hasUserDatasets:
      state.loadStatus.datasetsLoaded &&
      datasetsSelector(state).some((dataset) => !dataset.isInternal),
    hasUserPipes:
      state.loadStatus.pipesLoaded && pipesSelector(state).some((pipe) => !pipe.isInternal),
    hasUserSystems:
      state.loadStatus.systemsLoaded && systemsSelector(state).some((system) => !system.isInternal),
    pipes: state.pipes,
    downstreams: state.downstreams,
    lookups: state.lookups,
    inbounds: state.inbounds,
    outbounds: state.outbounds,
    subId: state.subscription.id,
    systems: systemsSelector(state),
    darkModeActive: state.theme.dark,
    transforms: state.transforms,
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    loadEverything: () =>
      Promise.all([
        dispatch(DatasetActions.loadAll()),
        dispatch(PipeActions.loadAll()),
        dispatch(SystemActions.loadAll()),
      ]),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
