import AlertsAPI, { Alert } from '../../api/alerts';
import debounceAction from 'debounce-action';
import { apologise } from './apology';
import { simpleSettingSet } from '../thunks/simple-settings';
import { createAction } from '@reduxjs/toolkit';
import { AppThunk } from '../..';

export const loadedAll = createAction<Alert[]>('alerts/loadedAll');

const load = debounceAction(
  function (): AppThunk {
    return function (dispatch, getState) {
      const state = getState();
      const since = state.simpleSettings.alertsShownSince;
      return AlertsAPI.getAll(since)
        .then((alerts) => {
          dispatch(loadedAll(alerts));
          dispatch(simpleSettingSet({ key: 'alertsLoaded', value: true }));
        })
        .catch((error) => dispatch(apologise(error)));
    };
  },
  1000,
  { leading: true, trailing: false }
);

const loadOlder = debounceAction(
  function (days = 7): AppThunk {
    return function (dispatch, getState) {
      const since = new Date(getState().simpleSettings.alertsShownSince);
      since.setDate(since.getDate() - days);
      dispatch(simpleSettingSet({ key: 'alertsShownSince', value: since }));
      return dispatch(load());
    };
  },
  1000,
  { leading: true, trailing: false }
);

export default {
  load,
  loadOlder,
};
