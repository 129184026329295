import React, { useRef, useLayoutEffect, useCallback, useEffect, useState, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { toastAdded } from 'Redux/thunks/global';
import Box from 'Common/Box/Box';
import { useBlockLayout, useResizeColumns, useRowSelect, useSortBy, useTable } from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import TableListHeader from '../table-list-header/table-list-header';
import useLocalStorage from '../../hooks/useLocalStorage';
import FilterTableRow from './FilterTableRow';
import FilterTableHeader from './FilterTableHeader';
import useFilter from './useFilter';

import './FilterTableStyle.css';

/**
 * This function takes in a comparator function that takes in two values and returns -1 or 1 depending on which sorting
 * direction we want to employ. It returns a function which takes in 2 entire row objects and the id of the column
 * and then applies the comparator function on just the specific cell values.
 * This is necessary because the new react-table sortType method expects a sorting function of the signature that this
 * function returns. This can be useful when we want the entire row object for the comparison. However, it's annoying
 * otherwise, because we then always have to drill down to the actual value of the cell to use in the compare function.
 *
 * I talked about this with the creator of the library and he also argued for this approach
 * (https://spectrum.chat/react-table/general/sorttype-function-should-get-the-value-row-directly~52f129cb-50be-4e54-b824-e2f45e40cbda)
 *
 * Use this in the sortType parameters where you define your columns
 *
 * @param comparator function
 * @returns {function(react-table row, react-table row, id of cell): }
 */
export function getSortValues(comparator) {
  return function (row1, row2, id) {
    return comparator(row1.values[id], row2.values[id]);
  };
}

function FilterTable(props) {
  /**
   * Constants
   */
  // FIXME: Constants should be centralized
  const LOCAL_STORAGE_KEY = 'sesam--data-table-3'; // <-- This one is used multiple places
  const FACETS_KEY = 'sesam--filter-facets';

  /**
   * Props
   */
  const {
    actionsRenderer,
    addToast,
    defaults,
    emptyFilter,
    filterLabelMapping,
    columns,
    itemsToFilter,
    location,
    router,
    subId,
    tableKey,
    onVisibleColsChanged,
    onFilterChanged,
    curl,
  } = props;

  // TODO: Remove commented stuff

  /**
   * State
   */
  const [selection, setSelection] = useState([]);
  const [listHeight, setListHeight] = useState(0);

  const defaultHiddenCols = columns
    .map(({ Header, id }) => {
      if (!defaults.visibleCols.includes(Header)) {
        return id;
      }
    })
    .filter(Boolean);

  /**
   * Local storage
   */
  const [hiddenColsFromLocalStorage, setHiddenColsToLocalStorage] = useLocalStorage(
    LOCAL_STORAGE_KEY,
    [tableKey, 'hidden_cols'],
    defaultHiddenCols
  );

  const [sortByFromLocalStorage, setSortByToLocalStorage] = useLocalStorage(
    LOCAL_STORAGE_KEY,
    [tableKey, 'sortBy'],
    defaults.sortBy
  );

  const {
    handleUpdateFilterSearch,
    handleRemoveFilterSearch,
    handleClearFilterSearch,
    handleResetFilter,
    handleClearFilterSelected,
    handleUpdateFilterSelected,
    handleSaveFilter,
    handleSavedFilterClick,
    handleSavedFilterDeleteClick,
    handleToggleFacetVisibility,
    filteredItems,
    filter,
    savedFilters,
    visibleFacets,
  } = useFilter({
    defaults: {
      filter: defaults.filter,
      visibleFacets: defaults.visibleFacets,
    },
    keys: {
      base: LOCAL_STORAGE_KEY,
      table: tableKey,
      facets: FACETS_KEY,
    },
    router,
    emptyFilter,
    filterLabelMapping,
    location,
    setSelection,
    subId,
    addToast,
    itemsToFilter,
  });

  /**
   * Render
   */
  const cols = useMemo(() => columns, [columns]);

  const handleSelectChanged = useCallback((select) => setSelection(select), [setSelection]);

  const listRef = useRef(null);
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    state,
    allColumns,
    prepareRow,
    getToggleAllRowsSelectedProps,
    setHiddenColumns,
    toggleAllRowsSelected,
    totalColumnsWidth,
    selectedFlatRows,
  } = useTable(
    {
      columns: cols,
      data: filteredItems,
      autoResetSortBy: false,
      autoResetSelectedRows: false,
      initialState: {
        sortBy: sortByFromLocalStorage,
        hiddenColumns: hiddenColsFromLocalStorage,
      },
    },
    useBlockLayout,
    useSortBy,
    useResizeColumns,
    useRowSelect
  );

  const resetHiddenColumns = useCallback(() => {
    setHiddenColumns(defaultHiddenCols);
    setHiddenColsToLocalStorage(defaultHiddenCols);
  }, [defaultHiddenCols, setHiddenColsToLocalStorage, setHiddenColumns]);

  /**
   * Inform parent about changed visible columns
   */
  useEffect(() => {
    if (onVisibleColsChanged) {
      const newVisibleColumns = columns
        .map(({ Header, id }) => {
          if (!state.hiddenColumns.includes(id)) {
            return Header;
          }
        })
        .filter(Boolean);
      onVisibleColsChanged(newVisibleColumns);
    }
    setHiddenColsToLocalStorage(state.hiddenColumns);
  }, [state.hiddenColumns, onVisibleColsChanged]);

  /**
   * Inform parent about changed filter
   */
  useEffect(() => {
    if (onFilterChanged) {
      onFilterChanged(filter);
    }
  }, [filter, onFilterChanged]);

  /**
   * Store the sortBy into LS
   */
  useEffect(() => {
    setSortByToLocalStorage(state.sortBy);
  }, [setSortByToLocalStorage, state.sortBy]);

  /**
   * Inform parent about selected rows
   */
  useEffect(() => {
    const pipeIds = selectedFlatRows.map((row) => row.original._id);
    handleSelectChanged(pipeIds);
  }, [selectedFlatRows, handleSelectChanged]);

  /**
   * Add the right margin in the header if there is a scrollbar present
   */
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    let _scrollbarWidth;
    if (listRef.current) {
      _scrollbarWidth = listRef.current.offsetWidth - listRef.current.clientWidth;
    }
    if (isNaN(_scrollbarWidth)) _scrollbarWidth = 0;
    setScrollbarWidth(_scrollbarWidth);
  }, [listHeight, filteredItems]);

  return (
    <Box className={'filter-table__box'}>
      <Box.Header className={'filter-table__box__header'}>
        <TableListHeader
          cols={allColumns}
          resetHiddenColumns={resetHiddenColumns}
          filter={filter}
          filterLabels={filterLabelMapping}
          itemsToFilter={itemsToFilter}
          onFacetSelectedChange={handleUpdateFilterSelected}
          onFacetSearchChange={handleUpdateFilterSearch}
          onClearSelectedFilters={handleClearFilterSelected}
          onClearSearchFilters={handleClearFilterSearch}
          onRemoveSearch={handleRemoveFilterSearch}
          onResetFilters={handleResetFilter}
          onToggleFacetVisibility={handleToggleFacetVisibility}
          onSavedFilterClick={handleSavedFilterClick}
          onSavedFilterDeleteClick={handleSavedFilterDeleteClick}
          onSaveFilter={handleSaveFilter}
          savedFilters={savedFilters}
          visibleFacets={visibleFacets}
          curl={curl}
        />
      </Box.Header>
      <Box.Content white style={{ overflow: 'initial' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <AutoSizer
            onResize={({ height }) => {
              setListHeight(height);
            }}
          >
            {({ width, height }) => {
              // The - 1 here is because we'd be sometimes getting fractional
              // widths which then triggered a horizontal scrollbar when unnecessary
              const rowWidth =
                totalColumnsWidth + 30 > width - scrollbarWidth - 1
                  ? totalColumnsWidth + 30
                  : width - scrollbarWidth - 1;
              return (
                <div
                  {...getTableProps({
                    style: {
                      display: 'flex',
                      flexDirection: 'column',
                      width: width,
                    },
                  })}
                >
                  <div {...getTableBodyProps({ style: { flex: '1 1 auto' } })}>
                    <FixedSizeList
                      itemCount={rows.length}
                      overscanCount={3}
                      itemData={{ rows, prepareRow }}
                      itemSize={30}
                      outerRef={listRef}
                      width={width}
                      height={height}
                      innerElementType={({ children, ...rest }) => {
                        return (
                          <div {...rest}>
                            <FilterTableHeader
                              headers={headers}
                              getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps}
                              rowWidth={rowWidth}
                            />
                            {children}
                          </div>
                        );
                      }}
                    >
                      {(restProps) => {
                        return <FilterTableRow rowWidth={rowWidth} {...restProps} />;
                      }}
                    </FixedSizeList>
                  </div>
                </div>
              );
            }}
          </AutoSizer>
        </div>
      </Box.Content>
      <Box.Actions className="table__action-bar">
        <div>{`${selection.length} of ${filteredItems.length} rows selected`}</div>
        {typeof actionsRenderer === 'function'
          ? actionsRenderer(selection, () => toggleAllRowsSelected(false))
          : ''}
      </Box.Actions>
    </Box>
  );
}

FilterTable.propTypes = {
  actionsRenderer: PropTypes.func,
  addToast: PropTypes.func.isRequired,
  defaults: PropTypes.shape({
    filter: PropTypes.shape({}).isRequired,
    sortBy: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    visibleCols: PropTypes.arrayOf(PropTypes.string).isRequired,
    visibleFacets: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  emptyFilter: PropTypes.shape({}).isRequired,
  filterLabelMapping: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemsToFilter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  location: PropTypes.shape({}).isRequired,
  noDataText: PropTypes.string,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  subId: PropTypes.string.isRequired,
  tableKey: PropTypes.string.isRequired,
  useVirtualList: PropTypes.bool,
  onVisibleColsChanged: PropTypes.func,
  onFilterChanged: PropTypes.func,
  curl: PropTypes.string,
};

FilterTable.defaultProps = {
  actions: null,
  noDataText: 'Nothing to show',
  useVirtualList: true,
  onVisibleColsChanged: null,
  curl: '',
};

const mapStateToProps = (state) => ({
  subId: state.subscription.id,
});

const mapDispatchToProps = (dispatch) => ({
  addToast: (message) => dispatch(toastAdded({ message, type: 'success' })),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(FilterTable);
