/* eslint-env node */

const commonColors = require('./commonColors');

module.exports = {
  brand: '#009fdc',
  brandActive: '#006f9a',
  light: {
    danger: '#B00020',
    warning: commonColors.flame.flameDark,
    text: {
      brand: '#0381c1',
      brandActive: '#0381c1',
    },
    background: {
      light: '#fff',
      semilight: '#f7f8f8',
      middle: '#ecebeb',
      darker: '#dbdcde',
      dark: '#58585b',
      blueish: commonColors.cyan.cyan10,
    },
    overlay: 'rgba(255, 255, 255, 0.30)',
    divider: 'rgba(0, 0, 0, 0.12)',
    inverted: '#f8f8f8',
  },
  dark: {
    danger: '#c93c37',
    warning: commonColors.flame.flameDark,
    text: {
      brand: commonColors.cyan.cyan40,
      brandActive: '#eaebec',
    },
    background: {
      light: '#2d333b',
      semilight: '#22272e',
      middle: '#636e7b',
      darker: '#151515',
      dark: '#101010',
      blueish: '#0a2c38',
    },
    overlay: 'rgba(0, 0, 0, 0.30)',
    divider: 'rgba(255, 255, 255, 0.12)',
    inverted: '#f7f8f8',
  },
};
