import { createAction, createReducer } from '@reduxjs/toolkit';

import { persistURLService } from 'Internals/connectionString.utils';
import type { CustomConnectionURL } from 'Internals/connectionString.utils';
import { AppThunk } from 'src';

export const setAllCustomConnectionURL = createAction(
  'customConnectionURL/setAll',
  (customConnectionURL: CustomConnectionURL) => {
    return {
      payload: customConnectionURL,
    };
  }
);
export const setCustomConnectionURL = createAction(
  'customConnectionURL/set',
  (subscriptionId: string, url: string) => {
    return {
      payload: {
        subscriptionId,
        url,
      },
    };
  }
);
export const removeCustomConnectionURL = createAction<string>('customConnectionURL/remove');

export const CustomConnectionsThunk = {
  setAllCustomConnections(): AppThunk {
    return (dispatch) => {
      const persistedURLs = persistURLService().getAllPersistedURLs();

      if (persistedURLs) {
        dispatch(setAllCustomConnectionURL(persistedURLs));
      }
    };
  },
  setCustomConnection(subscriptionId: string, customURL: string): AppThunk {
    return (dispatch) => {
      if (subscriptionId && customURL) {
        dispatch(setCustomConnectionURL(subscriptionId, customURL));
        persistURLService().setPersistedURL(subscriptionId, customURL);
      }
    };
  },
  removeCustomConnection(subscriptionId: string): AppThunk {
    return (dispatch) => {
      if (subscriptionId) {
        dispatch(removeCustomConnectionURL(subscriptionId));
        persistURLService().removePersistedURL(subscriptionId);
      }
    };
  },
};

const initialState: CustomConnectionURL = {};

export const customConnectionURLs = createReducer(initialState, (builder) =>
  builder
    .addCase(setAllCustomConnectionURL, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(setCustomConnectionURL, (state, action) => {
      return {
        ...state,
        [action.payload.subscriptionId]: action.payload.url,
      };
    })
    .addCase(removeCustomConnectionURL, (state, action) => {
      delete state[action.payload];

      return state;
    })
);
