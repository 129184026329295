export const dtlCompletions = [
  {
    name: 'hops',
    caption: 'Hop to another dataset',
    snippet:
      '["hops",{"datasets": ["${1:DATASET} ${2:ALIAS}"],"where": [["eq", "_S._id", "${2:$ALIAS}.ref"]]}]',
  },
  {
    name: 'if',
    caption: 'If/else statement',
    snippet: '["if", ${1:CONDITION}, ${2:THEN}, ${3:ELSE}]',
  },
  {
    name: 'comment',
    caption: 'Creates a comment',
    snippet: '["comment", "${1:VALUE}"]',
  },
  {
    name: 'filter',
    caption:
      'Continue applying transformations only if the evaluation of the FILTER expression returns true.',
    snippet: '["filter", ${1:boolean-expression}]',
  },
  {
    name: 'add',
    caption:
      'Adds the PROPERTY field(s) to the target entity with the values returned by evaluating the VALUES expression.',
    snippet: '["add", "${1:PROPERTY}", ${2:VALUES}]',
  },
  {
    name: 'default',
    caption: 'Adds PROPERTY to with VALUE/S unless already exists.',
    snippet: '["default", "${1:PROPERTY}", ${2:VALUES}]',
  },
  {
    name: 'make-ni',
    caption: 'Make namespace.',
    snippet: '["make-ni", "${1:NAMESPACE}","${2:FROMPROPERTY}","${3:TOPROPERTY}",]',
  },
  {
    name: 'remove',
    caption: 'Remove property.',
    snippet: '["remove", "${1:property}"]',
  },
  // {
  //   name: 'all',
  //   caption: 'all: Returns true if all arguments evaluate to true.',
  //   snippet: ['all', '${1:FUNCTION}', '${2:VALUES}'],
  // },
  // {
  //   name: 'any',
  //   caption: 'any: Returns true if at least one argument evaluates to true.',
  //   snippet: ['any', '${1:FUNCTION}', '${2:VALUES}'],
  // },
  // {
  //   name: 'and',
  //   caption: 'and: Returns true only if all arguments evaluate to true.',
  //   snippet: ['and', '${1:boolean-expression}'],
  // },
  // {
  //   name: 'or',
  //   caption: 'or: Returns true if any of the arguments evaluate to true.',
  //   snippet: ['or', '${1:boolean-expression}'],
  // },
  // {
  //   name: 'not',
  //   caption: 'not: Returns the inverse boolean value.',
  //   snippet: ['not', '${1:boolean-expression}'],
  // },
  // {
  //   name: 'eq',
  //   caption: 'eq: Returns true if the two arguments given are equal.',
  //   snippet: ['eq', '${1:value-expression1}', '${2:value-expression2}'],
  // },
  // {
  //   name: 'neq',
  //   caption: 'neq: Returns false if the two arguments given are equal.',
  //   snippet: ['neq', '${1:value-expression1}', '${2:value-expression2}'],
  // },
  // {
  //   name: 'gt',
  //   caption:
  //     'gt: Returns true if the first argument is greater than the second argument.',
  //   snippet: ['gt', '${1:value-expression1}', '${2:value-expression2}'],
  // },
  // {
  //   name: 'gte',
  //   caption:
  //     'gte: Returns true if the first argument is greater than or equal the second argument.',
  //   snippet: ['gte', '${1:value-expression1}', '${2:value-expression2}'],
  // },
  // {
  //   name: 'lt',
  //   caption:
  //     'lt: Returns true if the first argument is less than the second argument.',
  //   snippet: ['lt', '${1:value-expression1}', '${2:value-expression2}'],
  // },
  // {
  //   name: 'lte',
  //   caption:
  //     'lte: Returns true if the first argument is less than or equal the second argument.',
  //   snippet: ['lte', '${1:value-expression1}', '${2:value-expression2}'],
  // },
  // {
  //   name: 'is-empty',
  //   caption:
  //     'is-empty: Returns true if the number of elements in the first argument is 0.',
  //   snippet: ['is-empty', '${1:value-expression1}'],
  // },
  // {
  //   name: 'is-not-empty',
  //   caption:
  //     'is-not-empty: Returns true if the number of elements in the first argument is greater than 0.',
  //   snippet: ['is-not-empty', '${1:value-expression1}'],
  // },
  // {
  //   name: 'is-changed',
  //   caption: 'is-changed: Compares previous and current versions of entities.',
  // },
  // {
  //   name: 'boolean',
  //   caption: 'boolean: Translates all input values to booleans.',
  // },
  // {
  //   name: 'is-boolean',
  //   caption: 'is-boolean: Is value a boolean literal.',
  // },
  // {
  //   name: 'is-null',
  //   caption:
  //     'is-null: Boolean function that returns true if value is either null, an empty list, or a list where the first element in the list is null.',
  // },
  // {
  //   name: 'is-not-null',
  //   caption:
  //     'is-not-null: Returns true if the number of elements in the first argument is greater than 0.',
  // },
  // {
  //   name: 'if',
  //   caption:
  //     'if: If CONDITION evaluates to true then apply the transforms in THEN, otherwise apply the transforms in ELSE. ',
  // },
  // {
  //   name: 'is-not-empty',
  //   caption:
  //     'is-not-empty: Returns true if the number of elements in the first argument is greater than 0.',
  // },
  // {
  //   name: 'is-not-empty',
  //   caption:
  //     'is-not-empty: Returns true if the number of elements in the first argument is greater than 0.',
  // },
  // {
  //   name: 'is-not-empty',
  //   caption:
  //     'is-not-empty: Returns true if the number of elements in the first argument is greater than 0.',
  // },
  // {
  //   name: 'is-not-empty',
  //   caption:
  //     'is-not-empty: Returns true if the number of elements in the first argument is greater than 0.',
  // },
  // {
  //   name: 'is-not-empty',
  //   caption:
  //     'is-not-empty: Returns true if the number of elements in the first argument is greater than 0.',
  // },
  // {
  //   name: 'is-not-empty',
  //   caption:
  //     'is-not-empty: Returns true if the number of elements in the first argument is greater than 0.',
  // },
  // {
  //   name: 'is-not-empty',
  //   caption:
  //     'is-not-empty: Returns true if the number of elements in the first argument is greater than 0.',
  // },
  // {
  //   name: 'is-not-empty',
  //   caption:
  //     'is-not-empty: Returns true if the number of elements in the first argument is greater than 0.',
  // },
];

/*
all keywords
"if": ,
"case-eq": ,
"case": ,
"coalesce": ,
"apply": ,
"apply-hops": ,
"dict": ,
"key-values": ,
"items": ,
"keys": ,
"values": ,
"path": ,
"hops": ,
"reference": ,
"lookup": ,
"uri-expand": ,
"encrypt": ,
"decrypt": ,
"encrypt-pki": ,
"decrypt-pki": ,
"encrypt-pgp": ,
"decrypt-pgp": ,
"base64-encode": ,
"base64-decode": ,
"upper": ,
"lower": ,
"length": ,
"concat": ,
"join": ,
"split": ,
"strip": ,
"lstrip": ,
"rstrip": ,
"ljust": ,
"rjust": ,
"replace": ,
"substring": ,
"matches": ,
"plus": ,
"+": ,
"minus": ,
"-": ,
"abs": ,
"sqrt": ,
"sin": ,
"cos": ,
"tan": ,
"divide": ,
"/": ,
"multiply": ,
"*": ,
"mod": ,
"%": ,
"pow": ,
"^": ,
"round": ,
"ceil": ,
"floor": ,
"hash128": ,
"json": ,
"json-transit": ,
"json-parse": ,
"json-transit-parse": ,
"string": ,
"bytes": ,
"integer": ,
"is-integer": ,
"is-datetime": ,
"is-decimal": ,
"is-float": ,
"is-string": ,
"is-list": ,
"is-dict": ,
"is-ni": ,
"is-uri": ,
"is-null": ,
"is-not-null": ,
"if-null": ,
"now": ,
"uuid": ,
"datetime": ,
"datetime-parse": ,
"datetime-format": ,
"datetime-plus": ,
"datetime-diff": ,
"datetime-shift": ,
"decimal": ,
"float": ,
"uri": ,
"curie": ,
"url-quote": ,
"url-unquote": ,
"ni": ,
"ni-ns": ,
"ni-id": ,
"list": ,
"tuple": D
"tuples": ,
"first": ,
"last": ,
"nth": ,
"slice": ,
"insert": ,
"combine": ,
"in": ,
"flatten": ,
"filter": ,
"min": ,
"max": ,
"sum": ,
"count": ,
"distinct": ,
"sorted": ,
"sorted-descending": ,
"reversed": ,
"range": ,
"enumerate": ,
"map": ,
"map-values": ,
"map-dict": ,
"group-by": ,
"union": ,
"intersection": ,
"intersects": ,
"difference": ,
}
*/
