import { ApiConf } from '../types/types';
import sfetch, { sfetchJson } from 'Internals/sfetch';

async function get(apiConf: ApiConf) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/metadata`;
  const options: RequestInit = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return await sfetchJson(url, options);
}

async function post(apiConf: ApiConf, metadata: { [key: string]: any }) {
  const { subUrl, token } = apiConf;
  const url = `${subUrl}/metadata`;
  const options: RequestInit = {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(metadata),
  };
  return await sfetch(url, options);
}

export default {
  get,
  post,
};
