import React from 'react';
import { FormikProps } from 'formik';

import RecipientsEditor from '../../recipients-editor/RecipientsEditor';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import { Recipient } from '../../recipients-editor/RecipientsEditor';
import { RoleInfo, SubscriptionMemberInfo } from '../../../types/types';

interface FormValues {
  description: string;
  name: string;
  recipients: Recipient[];
}

interface PumpRestoredFromBackupFormProps {
  availableRoles: RoleInfo[];
  members: SubscriptionMemberInfo[];
}

const PumpRestoredFromBackupForm: React.FC<
  PumpRestoredFromBackupFormProps & FormikProps<FormValues>
> = ({ availableRoles, handleChange, handleBlur, errors, members, setValues, values }) => {
  return (
    <React.Fragment>
      <SesamTextField
        autoFocus
        helperText="A human readable name for this rule"
        label="Name"
        margin="normal"
        name="name"
        onChange={handleChange}
        value={values.name}
      />
      <SesamTextField
        helperText="A description of the business logic behind this rule"
        label="Description"
        margin="normal"
        multiline
        name="description"
        onChange={handleChange}
        rows={4}
        value={values.description}
      />
      <RecipientsEditor
        availableRoles={availableRoles}
        members={members}
        onChange={(recipients) => {
          setValues({ ...values, recipients });
        }}
        recipients={values.recipients}
        style={{ marginTop: '30px' }}
        TextFieldProps={{
          helperText:
            (errors.recipients && errors.recipients[0] && errors.recipients[0].methods) || ' ',
          margin: 'normal',
          error: Boolean(errors.recipients && errors.recipients[0] && errors.recipients[0].methods),
        }}
      />
    </React.Fragment>
  );
};

export default PumpRestoredFromBackupForm;
