import { createReducer } from '@reduxjs/toolkit';
import { collectTransforms } from 'Internals/pipes';
import { TransformMap, Pipe, PipeResponse } from '../../types/types';
import { disconnected } from '../thunks/subscriptions';
import {
  pipeAdded,
  pipeUpdated,
  pipeLoaded,
  pipesLoadAllFinished,
  pipeRemoved,
} from '../thunks/pipes';
import { systemRemoved } from '../thunks/systems';

type TransformsState = TransformMap;

function setTransform(pipe: Pipe | PipeResponse, state: TransformsState) {
  const transforms = collectTransforms(pipe);
  if (transforms.length < 1) {
    return;
  }
  for (const id of transforms) {
    if (state[id]) {
      state[id][pipe._id] = true;
    } else {
      state[id] = { [pipe._id]: true };
    }
  }
}

const initialState: TransformsState = {};

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(disconnected, () => initialState)
    .addCase(pipeAdded, (state, action) => {
      setTransform(action.payload, state);
    })
    .addCase(pipeUpdated, (state, action) => {
      setTransform(action.payload.pipe, state);
    })
    .addCase(pipeLoaded, (state, action) => {
      setTransform(action.payload.pipe, state);
    })
    .addCase(pipesLoadAllFinished, (state, action) => {
      const newState = {};
      for (let i = 0; i < action.payload.length; i++) {
        setTransform(action.payload[i], newState);
      }
      return newState;
    })
    .addCase(pipeRemoved, (state, action) => {
      if (action.payload.sourceSystemId && state[action.payload.sourceSystemId]) {
        delete state[action.payload.sourceSystemId][action.payload.id];
      }
    })
    .addCase(systemRemoved, (state, action) => {
      delete state[action.payload];
    })
);

export default reducer;
