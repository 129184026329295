import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import CollapsePanel from 'Common/CollapsePanel/CollapsePanel';

class PipeDebug extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.pipe !== prevProps.pipe) {
      if (
        this.props.pipe &&
        !get(this.props.pipe, 'debug_info.stacktrace') &&
        !get(this.props.pipe, 'debug_info.debug_info_list')
      ) {
        this.context.router.push(
          `/subscription/${this.props.subId}/pipes/pipe/${encodeURIComponent(
            this.props.pipe._id
          )}/dashboard`
        );
      }
    }
  }

  render() {
    const pipe = this.props.pipe;
    if (!pipe) {
      return null;
    }

    let pipeRunnerStacktraceElement = null;
    const pipeRunnerStacktrace = get(pipe, 'debug_info.stacktrace');
    if (pipeRunnerStacktrace) {
      pipeRunnerStacktraceElement = (
        <CollapsePanel title="Thread dump" startCollapsed={false}>
          <pre>{pipeRunnerStacktrace}</pre>
        </CollapsePanel>
      );
    }

    const debugInfoList = get(pipe, 'debug_info.debug_info_list', []);
    const debugInfoListElements = [];
    let debugInfoKey = 0;
    for (const debugInfo of debugInfoList) {
      debugInfoKey++;
      debugInfoListElements.push(
        <CollapsePanel key={debugInfoKey} title={debugInfo.header} startCollapsed={true}>
          <pre>{debugInfo.body}</pre>
        </CollapsePanel>
      );
    }

    return (
      <div title="This page contains various low-level runtime information about the pipe. (This is only displayed to sesam employees, and is used for debugging problems.)">
        {pipeRunnerStacktraceElement}
        {debugInfoListElements}
      </div>
    );
  }
}

PipeDebug.propTypes = {
  params: PropTypes.shape({
    pipeID: PropTypes.string.isRequired,
  }),
  pipe: PropTypes.shape({
    debug_info: PropTypes.shape({
      stacktrace: PropTypes.string.isRequired,
      debug_info_list: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string.isRequired,
          body: PropTypes.string.isRequired,
        })
      ),
    }),
    _id: PropTypes.string.isRequired,
  }),
  subId: PropTypes.string.isRequired,
};

PipeDebug.contextTypes = {
  router: PropTypes.object,
};

PipeDebug.defaultProps = {
  params: { pipeID: 'pipe-id' },
  pipe: { debug_info: { stacktrace: 'Stack trace' } },
};

const mapStateToProps = (state, ownProps) => ({
  pipe: state.pipes[ownProps.params.pipeID],
  subId: state.subscription.id,
});

export default connect(mapStateToProps)(PipeDebug);
