import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { withTheme } from '@material-ui/core/styles';

import Ellipsis from 'Common/Ellipsis';
import EntitySkeleton from './EntitySkeleton';
import { DatasetInspectorContext } from '../DatasetInspectorContext';
import { EntityBrowserListActionsFooter } from './EntityBrowserListActionsFooter.view';
import { EntitySkeletons } from './EntitySkeletons.view';
import { filterResolvedEntities } from 'Internals/entities.utils';
import { TestID } from '../../../testID';
import { DatasetInspectorFilter } from '../dataset-inspector-filter/DatasetInspectorFilter';
import { DatasetInspectorFilters } from 'Types/enums';

function EntityStatus({ entity }) {
  if (entity._deleted) {
    return <span className="dataset-inspector-browser__list-entity-status">Deleted</span>;
  } else if (entity._previous !== null) {
    return <span className="dataset-inspector-browser__list-entity-status">Updated</span>;
  }
  return null;
}

function entityClass(selected, previous, entity) {
  if (selected && selected._updated === entity._updated) {
    return 'selected';
  } else if (previous && previous._updated === entity._updated) {
    return 'previous';
  }
}

const EntityBrowserList = (props) => {
  const [state, actions] = useContext(DatasetInspectorContext);

  const hasJumpValue =
    state.jumpSequenceValue !== undefined || state.jumpUpdatedValue !== undefined;

  const entitiesToDisplay = [
    DatasetInspectorFilters.LatestWithoutDeleted,
    DatasetInspectorFilters.LatestWithDeleted,
  ].includes(props.filter)
    ? filterResolvedEntities(state.entities)
    : state.entities;

  return (
    <>
      <div
        className="dataset-inspector-browser__list"
        style={{ overflowX: 'hidden', flex: 1 }}
        onScroll={props.onScroll}
        ref={(el) => props.onSetRef(el)}
      >
        {!state.datasetEmpty && (
          <ul
            className="dataset-inspector-browser__list-entities"
            style={{
              backgroundColor: props.theme.palette.background.light,
            }}
          >
            {((state.populating && hasJumpValue) || state.loadingMoreTop) && (
              <li style={{ backgroundColor: props.theme.palette.background.light }}>
                <EntitySkeleton />
              </li>
            )}
            {entitiesToDisplay.map((entity, idx) => {
              const listEntitiesDarkModeStyle = {
                borderBottom: `1px solid ${props.theme.palette.divider}`,
              };

              if (state.selectedEntity && state.selectedEntity._id !== entity._id) {
                listEntitiesDarkModeStyle['backgroundColor'] = props.theme.palette.background.light;
              }
              return (
                <li
                  key={`${entity._id}-${idx}`}
                  onClick={(ev) => {
                    actions.selectEntity(entity);
                  }}
                  className={entityClass(state.selectedEntity, state.previousEntity, entity)}
                  ref={(el) => props.onEntityRef(el, entity, idx)}
                  style={listEntitiesDarkModeStyle}
                  data-testid={`${TestID.BrowserListEntity}-${entity._id?.replace(':', '-')}`}
                >
                  <div className="dataset-inspector-browser__list-entity-summary">
                    <h3
                      className="heading-component"
                      style={{ color: props.theme.palette.text.secondary }}
                      data-testid={TestID.DatasetInspectorBrowserListEntityTitle}
                    >
                      <Ellipsis>{entity._id}</Ellipsis>
                    </h3>
                    <div
                      className="dataset-inspector-browser__list-entity-summary-details"
                      style={{
                        color: props.theme.palette.text.primary,
                      }}
                    >
                      <div>
                        {moment(entity._ts / 1000).format('DD.MM.YYYY HH:mm:ss')}{' '}
                        <EntityStatus entity={entity} />
                      </div>
                      <div>#{entity._updated}</div>
                    </div>
                  </div>
                </li>
              );
            })}
            <EntitySkeletons
              theme={props.theme}
              isLoading={state.populating || state.loading}
              isLoadingMore={state.loadingMore}
            />
          </ul>
        )}
      </div>
      {props.displayRepostAll && entitiesToDisplay.length > 0 && (
        <EntityBrowserListActionsFooter
          theme={props.theme}
          repostDeadEntities={props.repostDeadEntities}
          disabled={state.populating || state.loading}
        />
      )}
    </>
  );
};

EntityBrowserList.propTypes = {
  repostDeadEntities: PropTypes.func,
  displayRepostAll: PropTypes.bool,
  onScroll: PropTypes.func.isRequired,
  onSetRef: PropTypes.func.isRequired,
  onEntityRef: PropTypes.func.isRequired,
  showingSearchResults: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      background: PropTypes.shape({ light: PropTypes.string }),
    }),
  }).isRequired,
  filter: PropTypes.string,
};

const Themed = withTheme(EntityBrowserList);

export { Themed as EntityBrowserList };
