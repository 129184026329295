import React from 'react';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';

const PipeStateRenderer = (props) => <span>{upperFirst(props.state)}</span>;

PipeStateRenderer.propTypes = {
  state: PropTypes.string,
};

PipeStateRenderer.defaultProps = {
  state: '',
};

export default PipeStateRenderer;
