import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PipeActions from 'Redux/thunks/pipes';
import DatasetActions from 'Redux/thunks/datasets';
import SystemActions from 'Redux/thunks/systems';
import SesamLink from 'Common/Links/SesamLink';
import Button from 'Common/Button/Button';
import Page from '../page';
import PageHeader, { PageHeaderGroup, PageHeaderTitle } from '../page-header';
import TabbedNav from '../tabbed-nav';

function PipesListPage(props) {
  return (
    <Page dataSelenium="subscription-pipes-pageview">
      <PageHeader>
        <PageHeaderTitle>Pipes</PageHeaderTitle>
        <PageHeaderGroup>
          <Button
            theme="primary"
            onClick={() => {
              props.loadAll(props.includeInternal);
              if (props.shouldLoadDatasets) props.loadDatasets();
            }}
          >
            Refresh
          </Button>
          <SesamLink to={`/subscription/${props.subId}/pipes/new`} buttonLink={true}>
            <Button theme="primary">New pipe</Button>
          </SesamLink>
        </PageHeaderGroup>
      </PageHeader>
      <TabbedNav
        nav={[
          {
            label: 'List all',
            to: `/subscription/${props.subId}/pipes/all`,
          },
          {
            label: 'Permissions',
            to: `/subscription/${props.subId}/pipes/permissions`,
          },
          {
            label: 'Prototype permissions',
            to: `/subscription/${props.subId}/pipes/prototype-permissions`,
          },
        ]}
      >
        {props.children}
      </TabbedNav>
    </Page>
  );
}

PipesListPage.propTypes = {
  children: PropTypes.node,
  loadAll: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
  includeInternal: PropTypes.bool.isRequired,
  loadDatasets: PropTypes.func.isRequired,
  shouldLoadDatasets: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    subId: state.subscription.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    delete: (id) => dispatch(PipeActions.delete(id)),
    loadAll: (includeInternal) => {
      dispatch(PipeActions.loadAll(includeInternal));
      dispatch(SystemActions.loadAll());
    },
    loadDatasets: (loadInternal) => {
      dispatch(DatasetActions.loadAll(loadInternal));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PipesListPage);
