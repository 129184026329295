import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';

import Button from 'Common/Button/Button';
import Page from '../../../components/page';
import PageHeader, { PageHeaderGroup, PageHeaderTitle } from '../../../components/page-header';
import PipeActions from 'Redux/thunks/pipes';
import SystemActions from 'Redux/thunks/systems';
import TabbedNav from '../../../components/tabbed-nav';

function PipesParent(props) {
  return (
    <Page dataSelenium="subscription-pipes-pageview">
      <PageHeader>
        <PageHeaderTitle>Pipes</PageHeaderTitle>
        <PageHeaderGroup>
          <Button theme="primary" onClick={() => props.loadAll()}>
            Refresh
          </Button>
          <SesamLink to={`/subscription/${props.subId}/pipes/new`} buttonLink={true}>
            <Button type="button">New pipe</Button>
          </SesamLink>
        </PageHeaderGroup>
      </PageHeader>
      <TabbedNav
        nav={[
          {
            label: 'List all',
            to: `/subscription/${props.subId}/pipes/all`,
          },
          {
            label: 'Permissions',
            to: `/subscription/${props.subId}/pipes/permissions`,
          },
          {
            label: 'Prototype permissions',
            to: `/subscription/${props.subId}/pipes/prototype-permissions`,
          },
        ]}
      >
        {props.children}
      </TabbedNav>
    </Page>
  );
}

PipesParent.propTypes = {
  children: PropTypes.node,
  loadAll: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    subId: state.subscription.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    delete: (id) => dispatch(PipeActions.delete(id)),
    loadAll: () => {
      dispatch(PipeActions.loadAll());
      dispatch(SystemActions.loadAll());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PipesParent);
