import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    fontFamily: 'Barlow, sans-serif',
  },
});

const SesamTypography = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} {...rest}>
      {children}
    </Typography>
  );
};

SesamTypography.propTypes = {
  children: PropTypes.node,
};

export default SesamTypography;
