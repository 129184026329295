import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import SesamLink from 'Common/Links/SesamLink';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'Common/Button/Button';
import SesamModal from 'Common/SesamModal/SesamModal';
import { confirmBefore } from 'Common/Confirmation';
import { LoadingPanel } from 'Common/LoadingPanel';
import DatasetActions from 'Redux/thunks/datasets';
import DatasetActionsMenu from '../../../components/dataset-actions-menu/dataset-actions-menu';
import InProgress from '../../../components/in-progress';
import NotFound from '../../../components/not-found';
import OutgoingFlowWizard from '../../../components/outgoing-flow-wizard/OutgoingFlowWizard';
import Page from '../../../components/page';
import PageHeader, { PageHeaderGroup, PageHeaderTitle } from '../../../components/page-header';
import PipeActions from 'Redux/thunks/pipes';
import { upstreamPipeIsGlobal } from 'Internals/datasets';

import TabbedNav from '../../../components/tabbed-nav';
import { getUpstreamPipeFromDatasetId, isGlobal } from 'Internals/pipes';

import './DatasetStyle.css';

class Dataset extends React.Component {
  constructor(props) {
    super(props);

    this.onDelete = () => {
      this.props.delete(this.props.params.datasetID).then(() => this.context.router.goBack());
    };

    this.registeredRefreshes = [];

    this.refresh = () => {
      this.props
        .load(this.props.params.datasetID)
        .catch(() => this.setState({ cannotFindObject: true }));
      this.registeredRefreshes.forEach((callback) => callback());
    };

    this.registerRefresh = (callback) => {
      this.registeredRefreshes.push(callback);
    };

    this.unregisterRefresh = (func) => {
      this.registeredRefreshes = this.registeredRefreshes.filter((c) => c !== func);
    };

    this.rollbackDataset = () => {
      this.props.rollback(this.props.params.datasetID);
    };

    this.commitDataset = () => {
      this.props
        .commit(this.props.params.datasetID)
        .then(() => this.refresh(this.props.params.datasetID));
    };

    this.openWizard = () => {
      this.setState({ showWizard: true });
    };

    this.closeWizard = () => {
      this.setState({ showWizard: false });
    };

    this.addCustomActions = (actions) => {
      this.setState({
        customActions: actions,
      });
    };

    this.removeCustomActions = () => {
      this.setState({
        customActions: [],
      });
    };

    this.state = {
      cannotFindObject: false,
      showWizard: false,
      customActions: [],
    };
  }

  render() {
    const dataset = this.props.dataset;
    if (!dataset) {
      return <LoadingPanel />;
    }
    const nav = [];
    const upstreamPipe = getUpstreamPipeFromDatasetId(
      this.props.dataset._id,
      this.props.pipes,
      this.props.upstreams
    );
    const upstreamPipeID = get(upstreamPipe, '_id', '');

    const isExecutionDataset =
      this.props.params.datasetID.startsWith('system:pump:') &&
      !this.props.params.datasetID.startsWith('system:pump:system');
    if (dataset) {
      nav.push({
        label: 'Entities',
        to: `/subscription/${this.props.subId}/settings-datahub/dataset/${dataset.link}/view`,
      });
      nav.push({
        label: 'Permissions',
        to: `/subscription/${this.props.subId}/settings-datahub/dataset/${dataset.link}/permissions`,
      });
      nav.push({
        label: 'Indexes',
        to: `/subscription/${this.props.subId}/settings-datahub/dataset/${dataset.link}/indexes`,
      });    
    }

    const circuitBreakerHasTripped = dataset && dataset.runtime['circuit-breaker-tripped'];

    return (
      <Page>
        <PageHeader>
          <PageHeaderTitle>
            <SesamLink to={`/subscription/${this.props.subId}/settings-datahub`}>Datahub</SesamLink>
          </PageHeaderTitle>
          <PageHeaderTitle>{this.props.params.datasetID}</PageHeaderTitle>
          <PageHeaderGroup>
            <DatasetActionsMenu
              upstreamPipeID={upstreamPipeID}
              datasetID={this.props.params.datasetID}
              subId={this.props.subId}
            />
          </PageHeaderGroup>
          <PageHeaderGroup>
            {this.state.customActions.length > 0 && this.state.customActions.map((c) => c)}
            {dataset && dataset.runtime.indexing && (
              <SesamLink
                to={`/subscription/${this.props.subId}/settings-datahub/dataset/${dataset.link}/indexes`}
              >
                <InProgress />
                Indexing
              </SesamLink>
            )}
            {circuitBreakerHasTripped && (
              <Button
                onClick={() =>
                  confirmBefore('Are you sure you want to roll back this dataset?', () =>
                    this.rollbackDataset()
                  )
                }
              >
                Roll back dataset
              </Button>
            )}
            {circuitBreakerHasTripped && (
              <Button
                onClick={() =>
                  confirmBefore('Are you sure you want to commit this dataset?', () =>
                    this.commitDataset()
                  )
                }
              >
                Commit dataset
              </Button>
            )}
            {upstreamPipeID && (
              <SesamLink
                to={`/subscription/${this.props.subId}/pipes/pipe/${upstreamPipeID}`}
                buttonLink={true}
              >
                <Button theme="primary">Go to pipe</Button>
              </SesamLink>
            )}
            {isExecutionDataset && dataset && (
              <SesamLink
                to={`/subscription/${this.props.subId}/pipes/pipe/${
                  /^system:pump:(.+)$/g.exec(this.props.params.datasetID)[1]
                }`}
                buttonLink={true}
              >
                <Button theme="primary">Go to pipe</Button>
              </SesamLink>
            )}
            <Button
              disabled={!dataset}
              onClick={() =>
                confirmBefore('Are you sure you want to delete this dataset?', () =>
                  this.onDelete()
                )
              }
              theme="danger"
            >
              Delete…
            </Button>
            <Button onClick={() => this.refresh(this.props.params.datasetID)}>Refresh</Button>
          </PageHeaderGroup>
        </PageHeader>
        <TabbedNav nav={nav}>
          {this.state.cannotFindObject && (
            <NotFound
              link={`/subscription/${this.props.subId}/settings-datahub`}
              linkText="View datasets"
              message="Maybe it hasn't been created yet, or you misspelled an ID?"
              title="Dataset not found"
            />
          )}
          {!this.state.cannotFindObject && !dataset && <LoadingPanel />}
          {!this.state.cannotFindObject &&
            dataset &&
            React.cloneElement(this.props.children, {
              // Inject props to children to allow them to register/unregister
              // a callback that will be invoked when the page is refreshed,
              // allowing them to do their own refresh-a-roo
              registerRefresh: this.registerRefresh,
              unregisterRefresh: this.unregisterRefresh,
              addCustomActions: this.addCustomActions,
              removeCustomActions: this.removeCustomActions,
            })}
        </TabbedNav>
        <SesamModal
          className="simple-dialog dataset__wizard-modal"
          isOpen={this.state.showWizard}
          onRequestClose={this.closeWizard}
        >
          <OutgoingFlowWizard dataset={this.props.dataset} />
        </SesamModal>
      </Page>
    );
  }
}

Dataset.propTypes = {
  children: PropTypes.any,
  dataset: PropTypes.object,
  delete: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,
  loadPipes: PropTypes.func.isRequired,
  pipes: PropTypes.shape({}).isRequired,
  upstreams: PropTypes.shape({}).isRequired,
  commit: PropTypes.func.isRequired,
  rollback: PropTypes.func.isRequired,
  params: PropTypes.shape({
    datasetID: PropTypes.string.isRequired,
  }),
  subId: PropTypes.string.isRequired,
};

Dataset.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  subId: state.subscription.id,
  dataset: state.datasets[ownProps.params.datasetID],
  pipes: state.pipes,
  upstreams: state.upstreams,
});

const mapDispatchToProps = (dispatch) => ({
  delete: (id) => dispatch(DatasetActions.delete(id)),
  load: (id) => dispatch(DatasetActions.load(id)),
  rollback: (id) => dispatch(DatasetActions.rollback(id)),
  commit: (id) => dispatch(DatasetActions.commit(id)),
  loadPipes: () => dispatch(PipeActions.loadAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dataset);
