import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { isProvisionerVersion2 } from 'Redux/selectors';
import CollapsePanel from 'Common/CollapsePanel/CollapsePanel';
import { Form } from 'Common/forms';
import SesamRadioGroupField from 'Common/SesamRadioGroupField/SesamRadioGroupField';
import Typography from 'Common/SesamTypography';
import SesamCheckboxField from 'Common/SesamCheckboxField/SesamCheckboxField';
import { getEnvironment, getProductionType } from 'Internals/utils';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import PanelWithSwitch from '../common/panel-with-switch/PanelWithSwitch';
import { showZendeskWindow } from '../../redux-store/thunks/global';

// custom css class styles for some used components below
const useStyles = makeStyles((theme) => ({
  input: {
    bottom: 14,
    left: 0,
    maxWidth: '100px',
  },
  slider: {
    width: 350,
  },
  sliderRoot: {
    marginTop: 20,
  },
  label: {
    position: 'relative',
    left: 10,
    bottom: 7,
  },
  radioGroup: {
    width: '63%',
  },
  checkbox: {
    position: 'relative',
    left: 20,
  },
  linkColor: {
    color: theme.palette.primary.main,
    '&:hover, &:active': {
      cursor: 'pointer',
    },
  },
}));

function getIsFixedPrice(products) {
  if ('fixed_price_gb' in products.datahub) {
    return true;
  }
  return false;
}

function getThreshold(products) {
  if ('fixed_price_gb' in products.datahub) {
    return products.datahub.fixed_price_gb;
  } else {
    return 0;
  }
}

function ProductsEditor(props) {
  const styles = useStyles();

  // This handler then maps back the changes from the radio buttons/checkboxes
  // to changes in the products object, which it then gives to the parent
  function handleChange(e) {
    const { name, value } = e.target;
    let updatedProducts = JSON.parse(JSON.stringify(props.products));

    switch (name) {
      case 'serviceLevel':
        if (value === 'basic') {
          delete updatedProducts.sla;
        } else {
          if (!('sla' in updatedProducts)) {
            updatedProducts.sla = {};
          }
          updatedProducts.sla.level = value;
        }
        break;
      case 'environment':
        if (value === 'production') {
          updatedProducts.datahub.size = 'single';
        } else if (value === 'developer') {
          updatedProducts.datahub.size = 'developer';
        } else if (value === 'developer-pro') {
          updatedProducts.datahub.size = 'developer-pro';
        }
        break;
      case 'productionType':
        updatedProducts.datahub.size = value;
        break;
      case 'thresholdText':
        if (value === '') {
          updatedProducts.datahub.fixed_price_gb = 0;
        } else {
          updatedProducts.datahub.fixed_price_gb = Number(value);
        }
        break;
      case 'vpn':
        // Currently, we don'ẗ support having the user configure the VPN, so for now this is an
        // empty object.
        // In the future, this object will get some actual properties ("local-subnet", "dns", etc).
        // But for now, an empty object means that vpn is enabled. If vpn isn't enabled,
        // we don't include the "vpn" property.
        if ('vpn' in updatedProducts) {
          delete updatedProducts.vpn;
        } else {
          updatedProducts.vpn = {};
        }
        break;
      case 'search':
        if ('search' in updatedProducts) {
          delete updatedProducts.search;
        } else {
          updatedProducts.search = true;
        }
        break;
      case 'isFixedPrice':
        if ('fixed_price_gb' in updatedProducts.datahub) {
          delete updatedProducts.datahub.fixed_price_gb;
        } else {
          updatedProducts.datahub.fixed_price_gb = 0;
        }
        break;
      case 'monitoring':
        if ('monitoring' in updatedProducts) {
          delete updatedProducts.monitoring;
        } else {
          updatedProducts.monitoring = true;
        }
        break;
    }

    // to remove legacy sla properties when the subscription has been downgraded manually by support
    if (
      updatedProducts.datahub.size === 'developer' ||
      updatedProducts.datahub.size === 'developer-pro'
    )
      delete updatedProducts.sla;

    props.onChange(updatedProducts);
  }

  const environment = getEnvironment(props.products);
  const productionType = getProductionType(props.products);
  const isFixedPrice = getIsFixedPrice(props.products);
  const threshold = getThreshold(props.products);
  return (
    <div className="products-editor">
      <CollapsePanel title="Choose environment" startCollapsed={false}>
        <Form component="div" horizontal>
          <SesamRadioGroupField
            row
            name="environment"
            onChange={handleChange}
            value={environment}
            margin="normal"
            radios={[
              {
                value: 'developer',
                label: 'Developer',
                disabled: !props.isNewSubscription || props.serviceType === 'on-premise',
              },
              {
                value: 'developer-pro',
                label: 'Developer Pro',
                disabled: !props.isNewSubscription || props.serviceType === 'on-premise',
              },
              {
                value: 'production',
                label: 'Production/Test/CI',
                disabled: !props.isNewSubscription,
              },
            ]}
          />
        </Form>

        {environment === 'developer' && (
          <div>
            <Typography variant="body1" gutterBottom>
              A developer environment has a fixed price and serves a single developer. These do not
              have support for backup, VPN, or SLA and can not host test, staging or production
              environments. The development environment is cloud-only and restricted.
            </Typography>
            <Typography variant="body2" gutterBottom>
              {'Fixed price - 1 Engine < 20 GB Data'}
            </Typography>
          </div>
        )}
        {environment === 'developer-pro' && (
          <div>
            <Typography variant="body1" gutterBottom>
              A developer-pro environment has a fixed price and serves a single developer. These do
              not have support for backup, VPN, or SLA and can not host test, staging or production
              environments. The development environment is cloud-only and restricted.
            </Typography>
            <Typography variant="body2" gutterBottom>
              {'Fixed price - 2 Engines < 20 GB Data'}
            </Typography>
          </div>
        )}
        {environment === 'production' && (
          <div>
            <Typography variant="body1" gutterBottom>
              Runtime environments have support for VPN and SLA. Geo-replicated backup is included
              in cloud environments.
            </Typography>
          </div>
        )}
        {!props.isNewSubscription && (
          <Typography variant="body1" gutterBottom style={{ marginTop: '15px' }}>
            If you need to change environment or compute level
            <a
              className={styles.linkColor}
              onClick={() => {
                props.showContactSupport();
              }}
            >
              {' '}
              contact support
            </a>
            .
          </Typography>
        )}
      </CollapsePanel>
      {environment === 'production' && (
        <CollapsePanel title="Compute level" startCollapsed={false}>
          <Form component="div" horizontal>
            <SesamRadioGroupField
              row
              name="productionType"
              onChange={handleChange}
              value={productionType}
              fullWidth={true}
              margin="normal"
              className={styles.radioGroup}
              radios={[
                {
                  value: 'single',
                  label: 'Single Compute',
                  helperText: <span>{'4 Engines < 350 GB Data'}</span>,
                  disabled: !props.isNewSubscription,
                },
                {
                  value: 'multi',
                  label: 'Multi Compute',
                  helperText: <span>{'16 Engines < 1 TB Data'}</span>,
                  disabled: !props.isNewSubscription,
                },
              ]}
            />
            {!props.isNewSubscription && (
              <SesamCheckboxField
                label="Fixed price"
                className={styles.checkbox}
                CheckboxProps={{
                  name: 'isFixedPrice',
                  id: 'isFixedPrice',
                  checked: isFixedPrice,
                  onChange: handleChange,
                }}
                margin="normal"
              />
            )}
          </Form>
        </CollapsePanel>
      )}
      {environment === 'production' && isFixedPrice && !props.isNewSubscription && (
        <CollapsePanel title="Fixed price threshold" startCollapsed={false}>
          <Typography variant="body1">Pay half price per GB for a fixed size.</Typography>
          <Form component="div" horizontal className={styles.sliderRoot}>
            <Input
              id="thresholdText"
              name="thresholdText"
              className={styles.input}
              value={threshold}
              margin="dense"
              onChange={handleChange}
              inputProps={{
                step: 1,
                min: 0,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
            <label htmlFor="thresholdText" className={styles.label}>
              GB
            </label>
          </Form>
        </CollapsePanel>
      )}
      {environment === 'production' && (
        <CollapsePanel title="Service level agreement" startCollapsed={false}>
          <Typography variant="body1">
            This specifies how long we can take to respond to errors that might affect your
            subscription.
          </Typography>
          <Typography style={{ fontSize: '0.8rem', marginTop: '8px' }}>
            SLA is billed for minimum 50GB data and a maximum of 300GB
          </Typography>
          <Form component="div" horizontal>
            <SesamRadioGroupField
              row
              name="serviceLevel"
              onChange={handleChange}
              value={props.products.sla ? props.products.sla.level : 'basic'}
              fullWidth={true}
              margin="normal"
              radios={[
                {
                  value: 'basic',
                  label: 'None',
                  helperText: 'No support',
                },
                {
                  value: 'standard',
                  label: 'Standard',
                  helperText: (
                    <span>
                      Critical errors: 8 hours
                      <br />
                      Serious errors: 2 days
                    </span>
                  ),
                },
                {
                  value: 'enterprise',
                  label: 'Enterprise',
                  helperText: (
                    <span>
                      Critical errors: 1 hour
                      <br />
                      Serious errors: 8 hours
                    </span>
                  ),
                },
              ]}
            />
          </Form>
        </CollapsePanel>
      )}
      {props.serviceType === 'in-cloud' &&
        environment !== 'developer' &&
        environment !== 'developer-pro' && (
          <PanelWithSwitch
            name="vpn"
            title="VPN"
            startCollapsed={false}
            handleChange={handleChange}
            isOn={props.products.vpn ? true : false}
          >
            <Typography variant="body1">
              Extend Sesam into your own network using IPSec Virtual Private Network.
            </Typography>
          </PanelWithSwitch>
        )}
      {props.serviceType === 'in-cloud' &&
        (props.isProvisionerVersion2 || props.isNewSubscription) &&
        !props.isNewSubscription && (
          <PanelWithSwitch
            name="search"
            title="Integrated search and property lineage"
            startCollapsed={false}
            handleChange={handleChange}
            isOn={props.products.search ? true : false}
          >
            <Typography variant="body1">
              Integrated search gives you more insight into your data and relationships within.
              Integrated search is free for Developer and Developer Pro subscriptions. With property
              lineage enabled, you can discover relationships between properties, see where they are
              produced, and get an overview of how they flow through your subscription.
            </Typography>
          </PanelWithSwitch>
        )}
      {(props.isProvisionerVersion2 || props.isNewSubscription) && !props.isNewSubscription && (
        <PanelWithSwitch
          name="monitoring"
          title="Metrics and monitoring"
          startCollapsed={false}
          handleChange={handleChange}
          isOn={props.products.monitoring ? true : false}
        >
          <Typography variant="body1">
            Metrics and monitoring gives you more insight about the status of pipes and lowers the
            cost of custom notifications in case you need this feature on a large number of pipes.
          </Typography>
        </PanelWithSwitch>
      )}
    </div>
  );
}

ProductsEditor.propTypes = {
  products: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  serviceType: PropTypes.string.isRequired,
  startCollapsed: PropTypes.bool,
  isPortalAdmin: PropTypes.bool,
  isItestMode: PropTypes.bool,
  isNewSubscription: PropTypes.bool,
  isProvisionerVersion2: PropTypes.bool,
  showContactSupport: PropTypes.func,
};

ProductsEditor.defaultProps = {
  startCollapsed: false,
  isNewSubscription: false,
};

function mapStateToProps(state) {
  return {
    isPortalAdmin: state.user.isPortalAdmin,
    isItestMode: state.globals.status.is_itest_mode,
    portalUrl: state.globals.portalUrl,
    subId: state.subscription && state.subscription.id,
    isProvisionerVersion2: isProvisionerVersion2(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showContactSupport: () => dispatch(showZendeskWindow()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsEditor);
