import { createReducer } from '@reduxjs/toolkit';
import { toastAdded, toastDismissed, Toast } from '../thunks/global';

type ToastsState = Toast[];

const initialState: ToastsState = [];

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
export default createReducer(initialState, (builder) =>
  builder
    .addCase(toastAdded, (state, action) => {
      state.push(action.payload);
    })
    .addCase(toastDismissed, (state, action) => {
      state.shift();
    })
);
