/*
 * Function to ask user to confirm
 */
export const confirmBefore = (text, onOkay) => {
  const result = window.confirm(text);

  if (result) {
    onOkay();
  }
};
