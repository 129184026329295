import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { buildClassName, ClassNamePropType } from 'Internals/react-utils';
import ColumnsIcon from '../../images/icons/columns.svg';
import { MenuAction, MenuCheckbox, MenuSeparator } from '../menu';
import MenuToggler from '../menu-toggler';
import { withTheme } from '@material-ui/core';

/**
 * Provide the contents for a cell in the table
 * @param  {object} row The row of data
 * @param  {object} col The column object
 * @return {Node}       A string or React element to output
 */
function renderCell(row, col) {
  if (typeof col.data === 'function') {
    const rendered = col.data(row, col);
    return typeof rendered === 'undefined' ? '' : rendered;
  }
  return get(row, col.data, '');
}

function defaultColSorter(col, row1, row2) {
  const row1Data = renderCell(row1, col);
  const row2Data = renderCell(row2, col);

  if (typeof row1Data === 'number' && typeof row2Data === 'number') {
    return row1Data < row2Data ? -1 : 1;
  }

  return row1Data.toString().trim().toLowerCase() < row2Data.toString().trim().toLowerCase()
    ? -1
    : 1;
}

/**
 * PropType structure for a column definition
 * @typedef DataTable~ColPropType
 * @prop {object} [attrs] A key-value object to assign as extra DOM arguments on the
 *       column's `<td>`s. Use for settings `data-*` attributes, for instance.
 * @prop {Node|DataTable~dataRetrieverCallbacktitle} data
 *       Key in data object, or callback for retrieving data for the column. If a
 *       string key is provided, it can be any format acceptable to lodash's `get`
 * @prop {boolean} [fixed = false] If `true` the column cannot be hidden by the user
 * @prop {string} header The header for the column, output within a `<th>`
 * @prop {boolean} [hideHeader = false] Whether to hide the header text in the `<th>`
 * @prop {boolean} [defaultHidden = false] If `true` the column is hidden by default
 * @prop {("default"|"icon"|"date"|"number")} [type = "default"] The rendering type
 *       for the column. This affects the column width and alignment.
 */
export const ColPropType = PropTypes.shape({
  attrs: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  defaultHidden: PropTypes.bool,
  fixed: PropTypes.bool,
  header: PropTypes.string.isRequired,
  hideHeader: PropTypes.bool,
  sorter: PropTypes.func,
  type: PropTypes.oneOf([
    'actions',
    'date',
    'default',
    'icon',
    'long-text',
    'number',
    'short-text',
    'medium-text',
  ]),
});

export const DefaultColProps = {
  attrs: {},
  defaultHidden: false,
  fixed: false,
  hideHeader: false,
  sorter: defaultColSorter,
  type: 'default',
};

export const normaliseCol = (col) => Object.assign({}, DefaultColProps, col);

// -----------------------------------------------------------------------------

function _TableHeaderRow({
  areAllSelected,
  colsToShow,
  onToggleSelectAll,
  selectableRows,
  setSortBy,
  sortable,
  sortedBy,
  theme,
}) {
  return (
    <thead>
      <tr>
        {selectableRows && (
          <th scope="col" title="Select" className="data-table__col data-table__col--icon">
            <div className="data-table__cell-wrap">
              <input
                checked={areAllSelected()}
                onChange={onToggleSelectAll}
                title="Select all rows"
                type="checkbox"
              />
            </div>
          </th>
        )}
        {colsToShow.map((col) => (
          <th
            className={buildClassName(
              'data-table__col',
              `data-table__col--${col.type}`,
              sortable && sortedBy === col.header && 'data-table__col--sorted'
            )}
            style={{
              backgroundColor:
                sortable && sortedBy === col.header ? theme.palette.background.blueish : null,
            }}
            key={col.header}
            scope="col"
            title={col.header}
          >
            {!col.hideHeader && (
              <div className="data-table__cell-wrap">
                {sortable && col.sorter && (
                  <button className="data-table__sort" onClick={() => setSortBy(col)}>
                    {col.header}
                  </button>
                )}
                {(!sortable || !col.sorter) && col.header}
              </div>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
}

_TableHeaderRow.propTypes = {
  areAllSelected: PropTypes.func.isRequired,
  colsToShow: PropTypes.arrayOf(ColPropType).isRequired,
  onToggleSelectAll: PropTypes.func.isRequired,
  selectableRows: PropTypes.bool.isRequired,
  setSortBy: PropTypes.func.isRequired,
  sortable: PropTypes.bool.isRequired,
  sortedBy: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
};

_TableHeaderRow.defaultProps = {
  sortedBy: undefined,
};

export const TableHeaderRow = withTheme(_TableHeaderRow);

// -----------------------------------------------------------------------------

function _TableRow({
  className,
  data,
  selectable,
  selected,
  clicked,
  onSelectToggle,
  visibleCols,
  onRowClick,
  pinned,
  theme,
}) {
  const clickClass = clicked ? 'clicked' : '';
  const classNames = [buildClassName(className), clickClass].filter(Boolean).join(' ');
  return (
    <tr
      className={classNames}
      onClick={() => onRowClick(data)}
      style={{
        backgroundColor: pinned ? theme.palette.background.darker : '',
        borderBottom: `1px solid ${theme.palette.background.semilight}`,
      }}
    >
      {selectable && (
        <td className="data-table__col data-table__col--icon">
          <div className="data-table__cell-wrap">
            <input
              checked={selected}
              onChange={() => onSelectToggle(data)}
              title="Select row"
              type="checkbox"
            />
          </div>
        </td>
      )}
      {visibleCols.map((col) => (
        <td
          className={`data-table__col data-table__col--${col.type}`}
          key={col.header}
          {...col.attrs}
        >
          <div className="data-table__cell-wrap">{renderCell(data, col)}</div>
        </td>
      ))}
    </tr>
  );
}

_TableRow.propTypes = {
  className: ClassNamePropType,
  data: PropTypes.shape().isRequired,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onSelectToggle: PropTypes.func,
  visibleCols: PropTypes.arrayOf(ColPropType).isRequired,
  onRowClick: PropTypes.func,
  clicked: PropTypes.bool,
  pinned: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
};

_TableRow.defaultProps = {
  className: undefined,
  onSelectToggle: () => {},
  selectable: false,
  selected: false,
  onRowClick: () => {},
  clicked: false,
  pinned: false,
};

export const TableRow = withTheme(_TableRow);

// -----------------------------------------------------------------------------

function _TableSpanRow({ content, span, theme }) {
  const cellContent =
    typeof content === 'string' ? (
      <span
        className="text-remark"
        style={{
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.light,
          padding: '5px 10px',
        }}
      >
        {content}
      </span>
    ) : (
      content
    );

  return (
    <tr>
      <td className="data-table__col data-table__col--span-row" span={span}>
        <div className="data-table__cell-wrap">{cellContent}</div>
      </td>
    </tr>
  );
}

_TableSpanRow.propTypes = {
  content: PropTypes.node.isRequired,
  span: PropTypes.number.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export const TableSpanRow = withTheme(_TableSpanRow);

// -----------------------------------------------------------------------------

export function TableColSelector({ cols, visibleCols, onChange, onReset }) {
  const anchorPoint = {
    anchor: { hPos: 'right', vPos: 'bottom' },
    popover: { hPos: 'right', vPos: 'top' },
  };
  return (
    <div className="data-table__col-selector">
      <MenuToggler
        anchorPoint={anchorPoint}
        closeOnInteraction={false}
        label={<ColumnsIcon />}
        labelClassName="btn btn--dropdown btn--small btn--icon"
      >
        {cols.map((col) => (
          <MenuCheckbox
            checked={visibleCols.includes(col)}
            key={col.header}
            label={col.header}
            onChange={(ev) => onChange(col, ev.target.checked)}
            readOnly={col.fixed}
          />
        ))}
        <MenuSeparator />
        <MenuAction label="Reset to default" onClick={onReset} />
      </MenuToggler>
    </div>
  );
}

TableColSelector.propTypes = {
  cols: PropTypes.arrayOf(ColPropType).isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  visibleCols: PropTypes.arrayOf(ColPropType).isRequired,
};

/**
 * @callback DataTable~dataRetrieverCallback
 * @param {object} data The data object for the current row
 * @param {DataTable~ColPropType} col The column being rendered
 */
