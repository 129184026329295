import React, { FC } from 'react';
import InProgress from '../in-progress';
import './LoadingPanel.css';

interface LoadingPanelProps {
  size?: 'small' | 'medium' | 'large';
  loadingMessage?: string;
}

/**
 * Panel that can be displayed while loading
 */
export const LoadingPanel: FC<LoadingPanelProps> = ({ size, loadingMessage }) => (
  <div className="loading-panel">
    <div className="loading-panel__image">
      <InProgress size={size} />
    </div>
    <p className="loading-panel__message">{loadingMessage}</p>
  </div>
);

LoadingPanel.defaultProps = {
  size: 'large',
  loadingMessage: 'Loading…',
};
