import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import SesamLink from 'Common/Links/SesamLink';
import AlertIcon from '@material-ui/icons/NotificationsNoneOutlined';

import { getSubName, findSub } from 'Redux/thunks/subscriptions';
import { LoadingPanel } from 'Common/LoadingPanel';
import { LinkButton } from 'Common/LinkButton/LinkButton';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import { simpleSettingSet } from 'Redux/thunks/simple-settings';
import AlertActions from 'Redux/thunks/alerts';

import Alert from '../../components/alert';
import { alertProps } from '../../model-props';
import FocusPanel from '../../components/focus-panel';
import Layout from '../../layout/full-sidebar';
import Media from '../../components/media';
import Page from '../../components/page';
import PageHeader, { PageHeaderGroup, PageHeaderTitle } from '../../components/page-header';

class AlertsPage extends React.Component {
  constructor(props) {
    super(props);

    this.getGroupName = (groupBy) => {
      switch (this.props.groupBy) {
        case 'subscription_id':
          return groupBy === undefined
            ? 'Alerts not from subscriptions'
            : this.props.getSubName(groupBy);

        case 'pipe_id':
          return groupBy === undefined ? 'Alerts not from pipes' : groupBy;

        default:
          return 'All alerts';
      }
    };

    this.getGroups = () => {
      const groups = {};
      this.props.alerts.forEach((alert) => {
        const groupBy = alert[this.props.groupBy];
        if (groups[groupBy]) {
          groups[groupBy].alerts.push(alert);
        } else {
          groups[groupBy] = {
            alerts: [alert],
            name: this.getGroupName(groupBy),
          };
        }
      });
      return groups;
    };

    this.loadOlder = () => {
      this.props.loadOlderAlerts();
    };
  }

  componentDidMount() {
    if (!this.props.alertsLoaded) {
      this.props.loadAlerts();
    }
  }

  render() {
    const groups = this.getGroups();
    const groupCount = Object.keys(groups).length;
    const sinceDate = moment(this.props.since).format('YYYY-MM-DD');

    return (
      <Layout>
        <Page>
          <PageHeader>
            <PageHeaderTitle>
              <SesamLink to={'/'}>Dashboard</SesamLink>
            </PageHeaderTitle>
            <PageHeaderTitle>Alerts</PageHeaderTitle>
            <PageHeaderGroup>
              <SesamTextField
                label="Group by"
                id="alert-group-by"
                onChange={(ev) => this.props.setGroupBy(ev.target.value)}
                value={this.props.groupBy}
                select
              >
                <SesamMenuItem value="nothing">Do not group</SesamMenuItem>
                <SesamMenuItem value="subscription_id">Subscription</SesamMenuItem>
                <SesamMenuItem value="pipe_id">Pipe</SesamMenuItem>
              </SesamTextField>
            </PageHeaderGroup>
          </PageHeader>
          {!this.props.alertsLoaded && <LoadingPanel />}
          {this.props.alertsLoaded && groupCount === 0 && (
            <FocusPanel>
              <Media img={<AlertIcon />} type="banner">
                <h3 className="heading-section">No alerts</h3>
                <p>
                  No alerts were triggered since {sinceDate}. You can try to{' '}
                  <LinkButton onClick={this.loadOlder}>load older</LinkButton> alerts.
                </p>
              </Media>
            </FocusPanel>
          )}
          {this.props.alertsLoaded && groupCount > 0 && (
            <div className="layout__content scrollArea">
              {Object.keys(groups).map((group) => (
                <section key={group}>
                  <h3 className="heading-section">{groups[group].name}</h3>
                  {
                    <ul className="expansion-panel-list">
                      {groups[group].alerts.map((alert) => (
                        <li key={alert.notification_id}>
                          <Alert {...alert} />
                        </li>
                      ))}
                    </ul>
                  }
                </section>
              ))}
              <p>
                Showing alerts since {sinceDate}. You can try to{' '}
                <LinkButton onClick={this.loadOlder}>load older</LinkButton> alerts.
              </p>
            </div>
          )}
        </Page>
      </Layout>
    );
  }
}

AlertsPage.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape(alertProps)).isRequired,
  alertsLoaded: PropTypes.bool.isRequired,
  getSubName: PropTypes.func.isRequired,
  groupBy: PropTypes.string.isRequired,
  loadAlerts: PropTypes.func.isRequired,
  loadOlderAlerts: PropTypes.func.isRequired,
  setViewAlertsSince: PropTypes.func.isRequired,
  setGroupBy: PropTypes.func.isRequired,
  since: PropTypes.instanceOf(Date).isRequired,
};

function mapStateToProps(state) {
  return {
    alerts: state.alerts,
    alertsLoaded: state.simpleSettings.alertsLoaded,
    groupBy: state.simpleSettings.alertsGroupBy,
    since: state.simpleSettings.alertsShownSince,
    getSubName: (id) => getSubName(findSub(state.subscriptions)(id)),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAlerts: () => dispatch(AlertActions.load()),
    loadOlderAlerts: () => dispatch(AlertActions.loadOlder()),
    setGroupBy: (groupBy) => dispatch(simpleSettingSet({ key: 'alertsGroupBy', value: groupBy })),
    setViewAlertsSince: (since) =>
      dispatch(simpleSettingSet({ key: 'alertsShownSince', value: since })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsPage);
