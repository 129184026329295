import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ActionBar from '../../../components/action-bar';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import sfetch, { sfetchDownload } from 'Internals/sfetch';
import { getGdprMsUrl } from '../gdpr/util';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

class GdprAccessRequest extends React.Component {
  constructor(props) {
    super();

    this.state = {
      requestMessage: '',
      accessMessage: '',
      email: props.location.query.email || props.userEmail,
      id: props.location.query.id,
    };

    this.download = (e) => {
      e.preventDefault();
      const id = this.state.id;
      const email = this.state.email;

      if (!id) {
        this.setState({ accessMessage: 'Please enter an ID' });
        return;
      }

      if (!email) {
        this.setState({
          accessMessage: 'Please enter the email of the contact person',
        });
        return;
      }

      const requestOptions = {
        credentials: 'include',
        headers: { Authorization: `bearer ${this.props.token}` },
      };

      const url = `${
        this.props.gdprMicroserviceURL
      }/generate-request-template?request_id=access-request:${id.trim()}:${email.trim()}`;

      sfetchDownload(url, requestOptions)
        .then(() => {
          this.setState({ accessMessage: '' });
        })
        .catch((e) => {
          this.setState({
            accessMessage: 'Something went wrong. Please try again.',
          });
        });
    };

    this.upload = (e) => {
      e.preventDefault();
      const file = this.state.file;

      if (!file) {
        this.setState({ requestMessage: 'You must select a file' });
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      const options = {
        body: formData,
        credentials: 'include',
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
        method: 'PUT',
      };

      sfetch(`${this.props.gdprMicroserviceURL}/upload-request-result`, options)
        .then(() => {
          this.setState({ requestMessage: 'File uploaded successfully.' });
        })
        .catch((e) => {
          this.setState({
            requestMessage: 'Something went wrong. Please try again.',
          });
        });
    };

    this.handleFileChange = (ev) => {
      this.setState({
        file: ev.target.files[0],
      });
    };

    this.handleEmailChange = (ev) => {
      this.setState({
        email: ev.target.value,
      });
    };

    this.handleAccessRequestIdChange = (ev) => {
      this.setState({
        id: ev.target.value,
      });
    };
  }

  render() {
    return (
      <main className="scrollArea">
        <h1 className="heading-section">Handle Access Requests</h1>
        <div className="row">
          <div className="col col--elastic gr-primary">
            <Form onSubmit={this.download} standout>
              <SesamTextField
                label="Access request ID"
                id="access-request-id"
                onChange={this.handleAccessRequestIdChange}
                value={this.state.id}
                margin="normal"
              />
              <SesamTextField
                label="Contact person email"
                id="contact-person-email"
                onChange={this.handleEmailChange}
                value={this.state.email}
                margin="normal"
              />
              <FormActions>
                <ActionBar shortMessage={this.state.accessMessage}>
                  <Button type="submit">Download template</Button>
                </ActionBar>
              </FormActions>
            </Form>
            <Form onSubmit={this.upload} standout>
              <SesamTextField
                label="Upload data request template"
                type="file"
                id="request-upload"
                onChange={this.handleFileChange}
                helperText={this.state.requestMessage}
              />
              <FormActions>
                <Button type="submit">Upload</Button>
              </FormActions>
            </Form>
          </div>
        </div>
      </main>
    );
  }
}

GdprAccessRequest.propTypes = {
  token: PropTypes.string.isRequired,
  gdprMicroserviceURL: PropTypes.string.isRequired,
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    userEmail: state.user.email,
    token: state.subscription.token,
    gdprMicroserviceURL: getGdprMsUrl(state),
  };
}

export default withRouter(connect(mapStateToProps)(GdprAccessRequest));
