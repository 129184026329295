import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import cloneDeep from 'lodash/cloneDeep';

import Button from 'Common/Button/Button';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import FilterTable, { getSortValues } from '../../../components/filter-table/FilterTable';
import GdprPolicyForm from '../../../components/gdpr-policy-form/GdprPolicyForm';
import { LoadingPanel } from 'Common/LoadingPanel';

import GdprActions from 'Redux/thunks/gdpr';
import { uuid } from 'Internals/utils';
import { sortWithNullOrUndefinedValues } from 'Internals/sort';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import './GdprPolicyPageStyle.css';
import ExternalLink from 'Common/Links/ExternalLink';
import SesamLink from 'Common/Links/SesamLink';

const defaultVisibleCols = [
  'Title',
  'Description',
  'Valid from',
  'Valid to',
  'Link',
  'Version',
  'Language',
  'Edit',
];

const defaultVisibleFacets = ['title'];

/**
 * Filter
 */
const filterLabelMapping = {
  title: 'Title',
};

const emptyFilter = Object.freeze({
  title: {
    selected: [],
    search: [],
  },
});

const defaultFilter = cloneDeep(emptyFilter);

/**
 * Sorting
 */
const defaultSortBy = [
  {
    id: 'systemId',
    desc: false,
  },
];

/**
 * Gdpr data type page
 * Table and form for adding, editing and deleting data types
 */
const GdprPolicyPage = ({ addPolicy, deletePolicy, loadPolicies, policies, updatePolicy }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [editPolicy, setEditPolicy] = useState(null);

  useEffect(() => {
    loadPolicies();
  }, []);

  useEffect(() => {
    if (editPolicy) {
      setShowDialog(true);
    }
  }, [editPolicy]);

  const columns = [
    {
      id: '_id',
      Header: 'Id',
      accessor: '_id',
      fixed: true,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'policy-id',
      Header: 'Policy ID',
      accessor: 'policy-id',
      fixed: true,
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'title',
      Header: 'Title',
      accessor: 'title',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: 'description',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'validFrom',
      Header: 'Valid from',
      accessor: 'valid-from',
      Cell: ({ row }) => (
        <div>{row.value ? moment(row.value).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
      ),
      sortType: getSortValues((a, b) => {
        return sortWithNullOrUndefinedValues(new Date(a), new Date(b));
      }),
    },
    {
      id: 'validTo',
      Header: 'Valid to',
      accessor: 'valid-to',
      Cell: ({ row }) => (
        <div>{row.value ? moment(row.value).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
      ),
      sortType: getSortValues((a, b) => {
        return sortWithNullOrUndefinedValues(new Date(a), new Date(b));
      }),
    },
    {
      id: 'link',
      Header: 'Link',
      accessor: 'link',
      Cell: ({ row }) => {
        if (row.value) {
          return (
            <ExternalLink href={row.value} target="_blank" rel="noopener noreferrer">
              {row.value}
            </ExternalLink>
          );
        }
        return '';
      },
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'version',
      Header: 'Version',
      accessor: 'version',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      id: 'lang',
      Header: 'Language',
      accessor: 'lang',
      sortType: getSortValues(sortWithNullOrUndefinedValues),
    },
    {
      Header: 'Edit',
      id: 'edit',
      fixed: true,
      width: 65,
      Cell: ({ row }) => <SesamLink onClick={() => handleEditClick(row.original)}>Edit</SesamLink>,
      align: 'center',
    },
  ];

  const handleCancel = () => {
    setShowDialog(false);
  };

  const handleDeletePolicy = (policy) => {
    deletePolicy(policy);
    setShowDialog(false);
  };

  const handleEditClick = (policy) => {
    setEditPolicy(policy);
  };

  const handleNewPolicy = (data) => {
    const policy = { ...data, ['policy-id']: uuid() };
    policy['valid-from'] = policy['valid-from']
      ? new Date(policy['valid-from']).toISOString()
      : null;
    policy['valid-to'] = policy['valid-to'] ? new Date(policy['valid-to']).toISOString() : null;

    addPolicy(policy);
    setShowDialog(false);
  };

  const handleUpdatePolicy = (policy) => {
    policy['valid-from'] = policy['valid-from']
      ? new Date(policy['valid-from']).toISOString()
      : null;
    policy['valid-to'] = policy['valid-to'] ? new Date(policy['valid-to']).toISOString() : null;

    updatePolicy(policy);
    setShowDialog(false);
  };

  if (!policies) {
    return <LoadingPanel />;
  }

  const defaultValues = {
    ['policy-id']: '',
    ['title']: '',
    ['description']: '',
    ['valid-from']: null,
    ['valid-to']: null,
    ['link']: '',
    ['markup']: '',
    ['version']: 0,
    ['lang']: '',
  };

  return (
    <ErrorBoundary>
      <main className="scrollArea">
        <div className="gdpr-policy-page">
          <div className="gdpr-policy-page__table">
            <FilterTable
              defaults={{
                filter: defaultFilter,
                sortBy: defaultSortBy,
                visibleCols: defaultVisibleCols,
                visibleFacets: defaultVisibleFacets,
              }}
              emptyFilter={emptyFilter}
              filterLabelMapping={filterLabelMapping}
              columns={columns}
              itemsToFilter={policies}
              tableKey="policies-2"
            />
          </div>
          <div className="gdpr-policy-page__new-policy">
            <Button
              text="Create new policy"
              onClick={() => {
                setShowDialog(true);
              }}
            />
          </div>
        </div>
        <Dialog
          open={showDialog}
          maxWidth="lg"
          onClose={() => {
            setShowDialog(false);
            setEditPolicy(null);
          }}
        >
          <DialogContent>
            {editPolicy ? (
              <GdprPolicyForm
                defaultValues={defaultValues}
                initialValues={editPolicy}
                inModal
                onCancel={() => {
                  setEditPolicy(null);
                  handleCancel();
                }}
                onUpdate={(policy) => {
                  handleUpdatePolicy(policy);
                  setEditPolicy(null);
                }}
                onDelete={(policy) => {
                  handleDeletePolicy(policy);
                  setEditPolicy(null);
                }}
              />
            ) : (
              <GdprPolicyForm
                defaultValues={defaultValues}
                inModal
                newPolicy
                onCancel={handleCancel}
                onDelete={handleDeletePolicy}
                onSubmit={handleNewPolicy}
                onUpdate={() => {}}
              />
            )}
          </DialogContent>
        </Dialog>
      </main>
    </ErrorBoundary>
  );
};

GdprPolicyPage.propTypes = {
  addPolicy: PropTypes.func.isRequired,
  deletePolicy: PropTypes.func.isRequired,
  loadPolicies: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  policies: PropTypes.arrayOf(PropTypes.shape({})),
  portalUrl: PropTypes.string.isRequired,
  router: PropTypes.shape({}).isRequired,
  updatePolicy: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  datatypes: state.gdpr.datatypes.data,
  portalUrl: state.subscription.url,
  policies: state.gdpr.policies.data,
  token: state.subscription.token,
});

const mapDispatchToProps = (dispatch) => ({
  addPolicy: (policy) => dispatch(GdprActions.addPolicy(policy)),
  deletePolicy: (policy) => dispatch(GdprActions.deletePolicy(policy)),
  loadPolicies: () => dispatch(GdprActions.loadPolicies()),
  updatePolicy: (policy) => dispatch(GdprActions.updatePolicy(policy)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(GdprPolicyPage);
