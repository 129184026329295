import React from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import round from 'lodash/round';
import moment from 'moment';

import { withSeparators } from 'Internals/utils';
import './pipe-progress.css';



const PipeRunProgressRenderer = ( props ) => {
  const pipeRunName = props.pipeRunName;
  const progress = props.progress;
  const runtime = props.runtime;
  const allProgressGroups = [];
  let total_estimate_by_group = progress.total_estimate_by_group;
  if (total_estimate_by_group) {
    for (const group in progress.total_estimate_by_group) {
      if (progress.total_estimate_by_group.hasOwnProperty(group)) {
        if (!allProgressGroups.includes(group)) {
          allProgressGroups.push(group);
        }
      }
    }
  } else {
    total_estimate_by_group = {};
  }
  let processed_by_group = progress.processed_by_group;
  if (processed_by_group) {
    for (const group in progress.processed_by_group) {
      if (progress.processed_by_group.hasOwnProperty(group)) {
        if (!allProgressGroups.includes(group)) {
          allProgressGroups.push(group);
        }
      }
    }
  } else {
    processed_by_group = {};
  }

  let pipeRunDisplayName = "";
  if (pipeRunName.length > 0) {
    pipeRunDisplayName = pipeRunName.charAt(0).toUpperCase() + pipeRunName.slice(1);
  } else {
    // This only happens if the node is running an old version that doesn't supply the "current-runs" property.
    pipeRunDisplayName = "";
  }

  let progressTooltip = 'Progress breakdown for the ' + pipeRunDisplayName + ' run:\n';
  for (const group of allProgressGroups) {
    let processedForGroup = 0;
    processedForGroup = processed_by_group[group];
    if (isUndefined(processedForGroup) || isNull(processedForGroup)) {
      processedForGroup = 0;
    }
    let estimateForGroup = null;
    estimateForGroup = total_estimate_by_group[group];
    if (isUndefined(estimateForGroup) || isNull(estimateForGroup)) {
      estimateForGroup = '?';
    }
    progressTooltip += `${group}: ${withSeparators(processedForGroup)}/${withSeparators(
      estimateForGroup
    )}\n`;
  }

  const processed = <span title={progressTooltip}>{withSeparators(progress.processed || 0)}</span>;
  const total = <span title={progressTooltip}>{withSeparators(progress['total-estimate'])}</span>;
  let pipeRunTypeTooltip = null;
  if (pipeRunName === "full") {
    pipeRunTypeTooltip = "This pipe-run is processing *all* the entities from the source."
  } else if (pipeRunName == "rescan") {
    pipeRunTypeTooltip = "This is a background rescan pipe-run that is reprocessing *all* the entities from the source."
  } else if (pipeRunName == "partial-rescan") {
    pipeRunTypeTooltip = "This is a partial rescan pipe-run that is reprocessing some of the newest entities from the source."
  } else if (pipeRunName == "incremental") {
    pipeRunTypeTooltip = "This is a normal incremental pipe-run that is only processing new entities from the source."
  }

  if (progress['total-estimate'] !== null) {
    let percentage = round((progress.processed / progress['total-estimate']) * 100, 0);
    percentage = !isNaN(percentage) ? percentage : 0;
    return (
      <span>
        <span title={pipeRunTypeTooltip}>{pipeRunDisplayName} </span>
        <span title={progressTooltip}>{percentage}% ({withSeparators(progress.processed || 0)} of {withSeparators(progress['total-estimate'])})</span>
      </span>
    );
  }
  return <span title={pipeRunTypeTooltip}>{pipeRunDisplayName} ({processed} so far)</span>;
}

PipeRunProgressRenderer.propTypes = {
  pipeRunName: PropTypes.string.isRequired,
  progress: PropTypes.shape().isRequired,
  runtime: PropTypes.shape().isRequired,
};


const PipeProgressRenderer = (props) => {
  const progress = props.progress;
  const runtime = props.runtime;

  if (!progress) {
    return <div>Not available</div>;
  }

  if (!runtime['is-running']) {
    if (props.progress['last-run'] !== null) {
      return (
        <span>
          Processed {withSeparators(props.progress['last-run'])} last run
          {props.lastRunWithDate && (
            <span title={runtime['last-run']}>
              {' '}
              ({moment.duration(moment().diff(runtime['last-run'])).humanize()} ago)
            </span>
          )}
        </span>
     );
    } else if (!runtime.success) {
      return props.dashIfEmpty ? <div>&mdash;</div> : null;
    }
    return <span>Never been run</span>;
  }

  let pipeRunProgress = {};
  if (progress.hasOwnProperty("current-runs")) {
    // this is a node running a new version of the code, where each pipe-run ("incremental", "rescan", etc) has its
    // own separate progress-info.
    pipeRunProgress = progress["current-runs"];
  } else {
    // this is a node running an old version of the code, so extract the pipe-run info from the progress object directly.
    const pipeRunName = "Processing";
    pipeRunProgress[pipeRunName] = {
      ...progress
    };
    delete pipeRunProgress[pipeRunName]["last-run"];
  }

  const pipeRunRenderers = Object.keys(pipeRunProgress).map((pipeRunName) => {
    const progressForPipeRun = pipeRunProgress[pipeRunName];
    return (<PipeRunProgressRenderer key={pipeRunName} pipeRunName={pipeRunName} progress={progressForPipeRun} runtime={props.runtime} />)
  });
  return <span className="pipe-progress">{pipeRunRenderers}</span>;
};

PipeProgressRenderer.propTypes = {
  dashIfEmpty: PropTypes.bool,
  lastRunWithDate: PropTypes.bool,
  progress: PropTypes.shape().isRequired,
  runtime: PropTypes.shape().isRequired,
};

PipeProgressRenderer.defaultProps = {
  dashIfEmpty: false,
  lastRunWithDate: false,
};

export default PipeProgressRenderer;
