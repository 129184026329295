import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import SubscriptionLink from './SubscriptionLink';
import { useSelector } from 'react-redux';

export function getDatasetLink(datasetId, datasets, upstreams) {
  // Get the mapping from datasets to pipes which produce them
  const dataset = datasets[datasetId];

  let isUserDataset = false;
  if (dataset) {
    isUserDataset = get(dataset, 'origin') === 'user';
  } else {
    isUserDataset = false;
  }

  // remove -dead suffix if present
  let cleanDatasetId = datasetId;
  if (datasetId.includes('-dead')) {
    cleanDatasetId = datasetId.replace('-dead', '');
  }

  // Get the upstream pipe id (might not exist)
  const upstreamPipeId = upstreams[cleanDatasetId];

  // default, if internal dataset
  let path = `settings-datahub/dataset/${datasetId}`;

  // if we can link to the pipe output page
  if (isUserDataset && upstreamPipeId) {
    path = `pipes/pipe/${upstreamPipeId}/output`;
  }

  // or redirect the *-dead dataset to the * pipe which created it
  if (datasetId.includes('-dead') && upstreamPipeId) {
    path = `pipes/pipe/${upstreamPipeId}/dead-letters`;
  }

  return path;
}

function DatasetLink({ datasetId, children, ...restProps }) {
  if (!children) {
    children = datasetId;
  }
  const datasets = useSelector((state) => state.datasets);
  const upstreams = useSelector((state) => state.upstreams);
  const path = getDatasetLink(datasetId, datasets, upstreams);

  return (
    <SubscriptionLink title={`Go to ${datasetId}`} to={path} {...restProps}>
      {children}
    </SubscriptionLink>
  );
}

DatasetLink.propTypes = {
  datasetId: PropTypes.string,
  children: PropTypes.node,
};

export default DatasetLink;
