import React from 'react';
import PropTypes from 'prop-types';

import KeyValue from '../key-value';
import get from 'lodash/get';
import { propertiesToSchema } from 'Internals/utils';
import InputLabel from '@material-ui/core/InputLabel';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import SesamAutocomplete from 'Common/SesamAutocomplete/SesamAutocomplete';

export function TargetSchemaPanel(props) {
  const { targetSchemaIds, selectedSchema, onSelectSchema } = props;
  const properties = propertiesToSchema(get(selectedSchema, 'properties', {}));
  return (
    <div className="stretchy-panel editor__panel editor__panel--extra">
      <h3 className="head">Target entity schema</h3>
      <div className="body" style={{ padding: '1rem' }}>
        <p style={{ marginTop: 0 }}>
          Here you can select one of the schemas defined in Sesam to serve as a guide for what you
          want you target entities to look like. Editor autocompletion will also take it into
          account.{' '}
        </p>
        <InputLabel id="target-entity-schema-dropdown">Target entity schema</InputLabel>
        <SesamAutocomplete
          renderInput={(params) => (
            <SesamTextField
              {...params}
              margin="none"
              placeholder={'Choose target sink type'}
              fullWidth={true}
            />
          )}
          getOptionLabel={(o) => o}
          value={get(selectedSchema, 'title', 'Unspecified')}
          onChange={(ev, val) => {
            if (val === 'Unspecified') {
              val = null;
            }
            onSelectSchema(val);
          }}
          options={['Unspecified', ...targetSchemaIds]}
        />
        <div>
          <h3 className="heading-section">Properties</h3>
          <KeyValue
            list={properties.reduce((acc, curr) => {
              acc[curr.name] = curr.type;
              return acc;
            }, {})}
          />
        </div>
      </div>
    </div>
  );
}

TargetSchemaPanel.propTypes = {
  targetSchemaIds: PropTypes.array.isRequired,
  onSelectSchema: PropTypes.func.isRequired,
  selectedSchema: PropTypes.shape({}),
};
