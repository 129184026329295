import React, { useEffect, useState } from 'react';
import flatten from 'lodash/flatten';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import union from 'lodash/union';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import Box from 'Common/Box/Box';
import JsonPanel from 'Common/JsonPanel';
import SesamLink from 'Common/Links/SesamLink';
import { apiConfSelector } from 'Redux/selectors';

import SearchAPI from '../../api/search';
import EntitiesAPI from '../../api/entities';
import { SearchResults, SearchResult, SomeObject } from '../../types/types';
import './styles.css';

interface NIInspectorProps {
  ni: string;
  subId: string;
  router: object;
}

const useStyle = makeStyles({
  relatedBox: {
    marginBottom: '25px',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const REFERENCED_BY_CUTOFF = 20;

const NIInspector: React.FC<NIInspectorProps> = ({ ni, router, subId }) => {
  const classes = useStyle();
  const encodedNI = encodeURIComponent(ni);
  const apiConf = useSelector(apiConfSelector);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [references, setReferences] = useState<string[]>([]);

  useEffect(() => {
    async function getSearchResults() {
      const searchResults: SearchResults = await SearchAPI.getResultsById(apiConf, ni);

      const references = searchResults.results
        .map((searchResult: SearchResult) => {
          return searchResult.refs;
        })
        .filter(Boolean);

      const referencesUnique = union(flatten(references));

      setSearchResults(searchResults.results);
      setReferences(referencesUnique);
    }

    if (ni !== undefined) {
      getSearchResults();
    }
  }, [apiConf, ni]);

  const [entities, setEntities] = useState<SomeObject>({});
  useEffect(() => {
    // TODO: Error handling
    async function getEntities() {
      const promises = searchResults.map(async (searchResult: SearchResult) => {
        const entity = await EntitiesAPI.lookupEntity(
          { ...apiConf, dataset: searchResult.dataset },
          searchResult.id
        );

        return { dataset: searchResult.dataset, entity: entity[0] };
      });

      const response = await Promise.all(promises);

      const entities = response.reduce((acc, res) => {
        if (acc[res.dataset]) {
          acc[res.dataset].push(res.entity);
        } else {
          acc[res.dataset] = [res.entity];
        }

        return acc;
      }, {});

      setEntities(entities);
    }

    if (ni !== undefined) {
      getEntities();
    }
  }, [apiConf, searchResults]);

  const [referencedByCount, setReferencedByCount] = useState<number>(0);
  const [referencedBy, setReferencedBy] = useState<string[]>([]);

  useEffect(() => {
    // TODO: Error handling
    async function getReferencedBy() {
      const searchResults: SearchResults = await SearchAPI.getResultsByReferencedBy(apiConf, ni);
      setReferencedByCount(searchResults.total_hits ? searchResults.total_hits : 0);

      const referencedBy = searchResults.results.map((searchResult: SearchResult) => {
        return searchResult.id;
      });

      setReferencedBy(union(referencedBy));
    }

    if (ni !== undefined) {
      getReferencedBy();
    }
  }, [ni, apiConf]);

  return (
    <main className="scrollArea">
      <div className="row">
        <div className="col ni-secondary">
          <div className={classes.relatedBox}>
            <Box>
              <Box.Header>
                <Box.Title>
                  Referenced by
                  {referencedByCount > 0 && ` (${referencedByCount})`}
                </Box.Title>
              </Box.Header>
              <Box.Content white>
                <List>
                  {referencedByCount === 0 && (
                    <ListItem className={classes.listItem}>
                      <ListItemText>
                        This identifier is not referenced in any other entities
                      </ListItemText>
                    </ListItem>
                  )}
                  {referencedByCount > 0 && referencedByCount <= REFERENCED_BY_CUTOFF && (
                    <>
                      {referencedBy.sort().map((identifier: string) => {
                        const encodedIdentifier = encodeURIComponent(identifier);
                        return (
                          <ListItem key={identifier} className={classes.listItem}>
                            <SesamLink to={`/subscription/${subId}/browse/ni/${encodedIdentifier}`}>
                              <ListItemText>{identifier}</ListItemText>
                            </SesamLink>
                          </ListItem>
                        );
                      })}
                      <ListItem>
                        <SesamLink to={`/subscription/${subId}/browse/search?ref=${encodedNI}`}>
                          <ListItemText>{`Show ${referencedByCount} ${
                            referencedByCount === 1 ? 'entity' : 'entities'
                          } that reference this identifier`}</ListItemText>
                        </SesamLink>
                      </ListItem>
                    </>
                  )}
                  {referencedByCount > REFERENCED_BY_CUTOFF && (
                    <ListItem className={classes.listItem}>
                      <SesamLink to={`/subscription/${subId}/browse/search?ref=${encodedNI}`}>
                        <ListItemText>{`This identifier is referenced by ${referencedByCount} entities`}</ListItemText>
                      </SesamLink>
                    </ListItem>
                  )}
                </List>
              </Box.Content>
            </Box>
          </div>
          <div className={classes.relatedBox}>
            <Box white>
              <Box.Header>
                <Box.Title>
                  References{references.length > 0 && ` (${references.length})`}
                </Box.Title>
              </Box.Header>
              <Box.Content white>
                <List>
                  {references.length === 0 && (
                    <ListItem className={classes.listItem}>
                      <ListItemText>
                        This identifier does not reference any other identifiers
                      </ListItemText>
                    </ListItem>
                  )}
                  {references.length > 0 &&
                    references.sort().map((id: string) => {
                      const encodedId = encodeURIComponent(id);
                      return (
                        <SesamLink to={`/subscription/${subId}/browse/ni/${encodedId}`}>
                          <ListItem className={classes.listItem}>
                            <ListItemText>{id}</ListItemText>
                          </ListItem>
                        </SesamLink>
                      );
                    })}
                </List>
              </Box.Content>
            </Box>
          </div>
        </div>
        <div className="col ni-primary">
          <Box>
            <Box.Header>
              <Box.Title>Entities</Box.Title>
            </Box.Header>
            <Box.Content>
              {Object.keys(entities).length === 0 && (
                <ListItem className={classes.listItem}>
                  <ListItemText>This identifier is not used in any entities</ListItemText>
                </ListItem>
              )}
              {Object.keys(entities).length !== 0 && <JsonPanel rawJson={entities} fitToContent />}
            </Box.Content>
          </Box>
        </div>
      </div>
    </main>
  );
};

export default withRouter(NIInspector);
