import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import { connect } from 'react-redux';

import Button from 'Common/Button/Button';

import './style.css';
import zendesk from 'Internals/zendesk';
import { createAction } from '@reduxjs/toolkit';
import { hideZendeskWindow, showZendeskWindow } from '../../redux-store/thunks/global';

export const zendeskConnected = createAction('zendesk/connected');

function UserFeedback({
  user,
  subId,
  portalUrl,
  setZendeskAvailable,
  subData,
  isVisible,
  hide,
  show,
}) {
  function toggle() {
    if (isVisible) hide();
    else show();
  }

  React.useEffect(() => {
    zendesk.init(portalUrl, () => {
      setZendeskAvailable();
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          zIndex: 1000,
        },
      });
      window.zE('webWidget', 'hide');
    });
  }, [portalUrl, setZendeskAvailable]);

  React.useEffect(() => {
    if (window.zE) {
      if (isVisible) {
        window.zE('webWidget', 'show');
        window.zE('webWidget', 'open');
      } else {
        window.zE('webWidget', 'hide');
        window.zE('webWidget', 'close');
      }
    }
  }, [isVisible]);

  React.useEffect(() => {
    if (user.email) {
      if (window.zE) {
        // Prefill ticket form with data
        window.zE(() =>
          window.zE('webWidget', 'prefill', {
            name: {
              value: user.name,
              readOnly: true,
            },
            email: {
              value: user.email,
              readOnly: true,
            },
          })
        );
        // If the user hides the widget with it's controls
        // the widget launcher button appears (which we normally want hidden)
        window.zE('webWidget:on', 'close', () => {
          window.zE('webWidget', 'hide');
          hide();
        });
      }
    }
  }, [user]);

  React.useEffect(() => {
    function getSLA() {
      const sla = get(subData, 'products.sla.level', 'none');
      switch (sla) {
        case 'enterprise':
          return 'enterprise-sla';
        case 'standard':
          return 'standard-sla';
        case 'basic':
        case 'none':
          return 'basic-sla';
      }
    }

    if (window.zE) {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            fields: [
              { id: 360004138159, prefill: { '*': subId ? subId : '' } },
              { id: 360004185060, prefill: { '*': getSLA() } },
            ],
          },
        },
      });
    }
  }, [subId, subData, user]);

  return user.zendeskAvailable ? (
    <div className="user-feedback">
      <div className="user-feedback__text-area__buttons">
        <Button onClick={toggle} theme="primary">
          Help
        </Button>
      </div>
    </div>
  ) : null;
}

UserFeedback.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    zendeskAvailable: PropTypes.bool.isRequired,
  }).isRequired,
  subId: PropTypes.string.isRequired,
  portalUrl: PropTypes.string.isRequired,
  setZendeskAvailable: PropTypes.func.isRequired,
  subData: PropTypes.object,
  isVisible: PropTypes.bool,
  hide: PropTypes.func,
  show: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  subId: state.subscription.id,
  portalUrl: state.globals.portalUrl,
  subData: state.subscriptions.find((s) => s.id === state.subscription.id),
  isVisible: state.zendesk.show,
});

const mapDispatchToProps = (dispatch) => ({
  setZendeskAvailable: () => dispatch(zendeskConnected()),
  hide: () => dispatch(hideZendeskWindow()),
  show: () => dispatch(showZendeskWindow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFeedback);
