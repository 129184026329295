import React from 'react';
import PropTypes from 'prop-types';

import DefaultAvatar from '../../images/avatar.svg';

import { gravatar } from 'Internals/user';
import { buildClassName, ClassNamePropType } from 'Internals/react-utils';

function UserAvatar({ className, email, handleError, showDefault }) {
  if (!email) {
    return null;
  }
  return showDefault ? (
    <DefaultAvatar alt="Avatar" className={buildClassName('user-avatar', className)} />
  ) : (
    <img
      alt="Avatar"
      className={buildClassName('user-avatar', className)}
      onError={handleError}
      src={gravatar(email)}
    />
  );
}

UserAvatar.propTypes = {
  className: ClassNamePropType,
  email: PropTypes.string,
  handleError: PropTypes.func.isRequired,
  title: PropTypes.string,
  showDefault: PropTypes.bool.isRequired,
};

export default UserAvatar;
