import sendAnalyticsInfo from '../api/analytics';

export const sendHibernationAnalyticsTrigger = (portalUrl: string) => {
  const pathname = window.location.pathname;
  const subId = pathname.split('/')[2];

  if (pathname.includes('subscription/')) {
    sendAnalyticsInfo(portalUrl, subId, 'page_view');
  }
};
