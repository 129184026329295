import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import SystemActions from 'Redux/thunks/systems';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

import TrashIcon from '../../images/icons/trash.svg';
import DataTable from '../data-table';

import './style.css';

const sortSecrets = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase());

class SystemSecrets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      secretsAddName: '',
      secretsAddValue: '',
      showAddForm: false,
      secrets: [],
    };

    this.refresh = () => {
      this.props.loadAllSecrets(this.props.systemID);
    };

    this.handleDelete = (row) => {
      // TODO: This confirmation box should be switched out with a custom ui component.
      const confirmation = confirm(`Delete secret "${row.secret}"?`);

      if (confirmation) {
        this.props.deleteSecret(this.props.systemID, row.secret);
      }
    };

    this.getSortedSecrets = () =>
      this.props.secrets
        .slice()
        .sort(sortSecrets)
        .map((secret) => ({ secret }));

    this.handleFieldChange = (ev) => {
      this.setState({ [ev.target.id]: ev.target.value });
    };

    this.getDeleteButton = (row) => (
      <button
        className="secrets__delete btn btn--invert btn--danger btn--icon btn--small"
        onClick={() => this.handleDelete(row)}
        title="Delete"
      >
        <TrashIcon />
      </button>
    );

    this.handleSubmit = (ev) => {
      ev.preventDefault();

      // TODO: Validation

      const obj = {};
      obj[this.state.secretsAddName] = this.state.secretsAddValue;

      this.props.addSecret(this.props.systemID, obj).then(() =>
        this.setState({
          secretsAddName: '',
          secretsAddValue: '',
          showAddForm: false,
        })
      );
    };
  }

  componentDidMount() {
    if (this.props.registerRefresh) {
      this.props.registerRefresh(this.refresh);
    }
    this.refresh();
  }

  componentWillUnmount() {
    if (this.props.unregisterRefresh) {
      this.props.unregisterRefresh(this.refresh);
    }
  }

  render() {
    let tableFooter;

    if (this.state.showAddForm) {
      tableFooter = (
        <Form onSubmit={this.handleSubmit} standout>
          <h3 className="heading-component">Add secret</h3>
          <SesamTextField
            label="Name"
            autoFocus
            id="secretsAddName"
            onChange={this.handleFieldChange}
            value={this.state.secretsAddName}
            margin="normal"
          />
          <SesamTextField
            multiline
            label="Value"
            rows="5"
            id="secretsAddValue"
            value={this.state.secretsAddValue}
            autoComplete="off"
            onChange={this.handleFieldChange}
            margin="normal"
          />
          <FormActions>
            <Button
              onClick={(ev) => {
                ev.preventDefault();
                this.setState({ showAddForm: false });
              }}
              type="button"
            >
              Cancel
            </Button>
            <Button type="submit">Add</Button>
          </FormActions>
        </Form>
      );
    } else {
      tableFooter = (
        <div className="toolbar toolbar--right">
          <Button onClick={() => this.setState({ showAddForm: true })}>Add secret…</Button>
        </div>
      );
    }

    return (
      <DataTable
        className="secrets"
        cols={[
          { header: 'Name', data: 'secret', hideHeader: true },
          {
            header: 'Actions',
            type: 'icon',
            data: this.getDeleteButton,
            hideHeader: true,
          },
        ]}
        data={this.getSortedSecrets()}
        dataKey="secret"
        emptyMessage="No secrets have been stored"
        footer={tableFooter}
        id="datahub-secrets"
      />
    );
  }
}

SystemSecrets.propTypes = {
  systemID: PropTypes.string.isRequired,
  loadAllSecrets: PropTypes.func,
  addSecret: PropTypes.func,
  deleteSecret: PropTypes.func,
  secrets: PropTypes.array,
  registerRefresh: PropTypes.func,
  unregisterRefresh: PropTypes.func,
};

SystemSecrets.defaultProps = {
  secrets: [],
};

const mapStateToProps = (state, ownProps) => {
  const system = state.systems[ownProps.systemID];
  return {
    secrets: get(system, 'secrets', []),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadAllSecrets: (id) => dispatch(SystemActions.systemSecretsLoadAll(id)),
  addSecret: (id, obj) => dispatch(SystemActions.systemSecretsAdd(id, obj)),
  deleteSecret: (id, secret) => dispatch(SystemActions.systemSecretDelete(id, secret)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemSecrets);
