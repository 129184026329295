import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import Add from '@material-ui/icons/Add';
import Attachment from '@material-ui/icons/Attachment';
import Avatar from '@material-ui/core/Avatar';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Clear from '@material-ui/icons/Clear';
import Error from '@material-ui/icons/Error';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import upperFirst from 'lodash/upperFirst';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Button from 'Common/Button/Button';
import ConfigGroupSelector from '../../../components/config-group-selector/ConfigGroupSelector';
import SesamCheckboxField from 'Common/SesamCheckboxField/SesamCheckboxField';
import useUpload from '../../../hooks/useUpload';
import { Form, FormActions } from 'Common/forms';
import {
  DEFAULT_CONFIG_GROUP_ID,
  DEFAULT_CONFIG_GROUP_LABEL,
  configGroupsSelector,
} from 'Redux/selectors';

import { RootState } from '../../../';

function setConfigGroupName(configGroup: string) {
  return configGroup.trim().toLowerCase() === DEFAULT_CONFIG_GROUP_LABEL.toLowerCase()
    ? DEFAULT_CONFIG_GROUP_ID
    : configGroup;
}

const useStyle = makeStyles((theme) => {
  return {
    input: {
      display: 'none',
    },
    icon: {
      justifyContent: 'center',
    },
    configUpload: {
      marginTop: '16px',
      marginBottom: '8px',
    },
    dropZone: {
      border: '1px solid #c4c4c4',
      borderRadius: '4px',
      backgroundColor: theme.palette.background.light,
    },
    avatarSuccess: {
      backgroundColor: theme.palette.success.main,
      '& svg': { color: 'white' },
    },
    avatarError: {
      backgroundColor: theme.palette.error.main,
      '& svg': { color: 'white' },
    },
  };
});

const UploadConfig = () => {
  const classes = useStyle();

  const subUrl = useSelector((state: RootState) => state.subscription.url);
  const configGroups = useSelector(configGroupsSelector);

  const [configGroup, setConfigGroup] = useState(setConfigGroupName(DEFAULT_CONFIG_GROUP_ID));
  const [force, setForce] = useState(false);

  const url = useMemo(() => {
    let u = `${subUrl}/config`;
    if (configGroup !== DEFAULT_CONFIG_GROUP_ID) {
      u = u + `/${encodeURIComponent(configGroup)}`;
    }
    if (force) {
      u = u + '?force=true';
    }

    return u;
  }, [subUrl, configGroup, force]);

  const { triggerRef, dropzoneRef, fileList, removeFile, upload } = useUpload({
    multiple: false,
    url: url,
    method: 'PUT',
  });

  const getIcon = (status: string) => {
    switch (status) {
      case 'uploading':
        return <CircularProgress size={24} thickness={4.5} />;
      case 'uploaded':
        return <CheckCircle className={classes.icon} />;
      case 'failed':
        return <Error className={classes.icon} />;
      default:
        return <Attachment size={24} className={classes.icon} />;
    }
  };

  return (
    <Form component="div" margin={false}>
      <div>
        <div className={classes.configUpload}>
          <div className={classes.dropZone} ref={dropzoneRef}>
            <List dense>
              {!fileList.length && (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ListItemIcon className={classes.icon}>
                        <Clear />
                      </ListItemIcon>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={'No file attached'}
                    secondary={'Drag a file here, or click "Add file"'}
                  />
                </ListItem>
              )}
              {fileList.length > 0 &&
                fileList.map((file) => (
                  <ListItem key={file.uuid} dense>
                    <ListItemAvatar>
                      <Avatar
                        className={clsx([
                          file.status === 'uploaded' && classes.avatarSuccess,
                          file.status === 'failed' && classes.avatarError,
                        ])}
                      >
                        <ListItemIcon className={classes.icon}>{getIcon(file.status)}</ListItemIcon>
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={file.name}
                      secondary={upperFirst(file.status)}
                      primaryTypographyProps={{ noWrap: true }}
                    />
                  </ListItem>
                ))}
            </List>
          </div>
          <FormActions>
            <Button onClick={() => triggerRef.current?.click()} type="button">
              {!fileList.length ? 'Add file…' : 'Change file…'}
            </Button>
          </FormActions>
        </div>
        <input
          ref={triggerRef}
          accept="application/json"
          className={classes.input}
          id="icon-button-file"
          type="file"
        />
        <ConfigGroupSelector
          configGroups={configGroups}
          onChangeConfigGroup={(cg: string) => setConfigGroup(setConfigGroupName(cg))}
          onSelectConfigGroup={(cg: string) => setConfigGroup(setConfigGroupName(cg))}
          selectedConfigGroup={configGroup}
          TextFieldProps={{ label: 'Config group', margin: 'normal' }}
        />
      </div>
      <FormActions>
        <SesamCheckboxField
          margin="none"
          label="Force (ignore validation errors)"
          FormControlLabelProps={{ style: { marginRight: 0 } }}
          CheckboxProps={{
            size: 'small',
            checked: force,
            onChange: (ev: React.ChangeEvent<HTMLInputElement>) => {
              setForce(ev.target.checked);
            },
          }}
        />
        <Button disabled={!fileList.length || !configGroup} onClick={() => upload()}>
          Import
        </Button>
      </FormActions>
    </Form>
  );
};

export default UploadConfig;
