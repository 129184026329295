import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import get from 'lodash/get';

import Box from 'Common/Box/Box';
import Button from 'Common/Button/Button';
import Upload from 'Common/Upload/Upload';
import withUpload from 'Common/HOC/with-upload/WithUpload';
import { LoadingPanel } from 'Common/LoadingPanel';
import { DatasetInspectorContainer } from '../dataset-inspector/DatasetInspectorContainer';
import EntityViewer from '../entity-viewer/EntityViewer';

import './PipeInputStyle.css';

const EntityUpload = withUpload()(Upload);

function PipeInput(props) {
  const [fetchRemoteEntities, setFetchRemoteEntities] = useState(false);

  if (!props.pipe) return <div />;

  const sourceType = get(props.pipe, 'config.effective.source.type');
  const subset = get(props.pipe, 'config.effective.source.subset');

  let description;
  let content;

  const renderEntityViewer = () => {
    description = (
      <div className="pipe-input__description">
        <h1 className="heading-page">Source entities</h1>
        <p>This is how the entities look as they come out of the source.</p>
      </div>
    );

    content = (
      <div className="pipe-input__content">
        <EntityViewer pipe={props.pipe} stage="source" />
      </div>
    );
  };

  if (sourceType === 'dataset' || sourceType === 'binary') {
    let datasetId;

    if (sourceType === 'dataset') datasetId = get(props.pipe, 'config.effective.source.dataset');
    if (sourceType === 'binary') {
      const url = get(props.pipe, 'config.effective.source.url');
      const splitUrl = url.split('/');

      if (splitUrl.includes('datasets')) {
        datasetId = splitUrl[splitUrl.indexOf('datasets') + 1];
      } else {
        datasetId = splitUrl[0];
      }
    }

    const dataset = props.datasets[datasetId];

    if (!dataset) {
      if (props.datasetsLoaded) {
        renderEntityViewer();
      } else {
        content = <LoadingPanel />;
      }
    } else {
      content = (
        <DatasetInspectorContainer
          addCustomActions={props.addCustomActions}
          datasetID={datasetId}
          registerRefresh={props.registerRefresh}
          removeCustomActions={props.removeCustomActions}
          unregisterRefresh={props.unregisterRefresh}
          subset={subset}
          tab="input"
        />
      );
    }
  } else if (sourceType === 'http_endpoint') {
    description = (
      <div className="pipe-input__description">
        <h1 className="heading-page">Upload entities</h1>
        <p className="output-">
          {`This pipe gets its entities from the `}
          <code>{`/receivers/${props.pipe['_id']}/entities`}</code>
          {` endpoint.
        You can use the upload form below to push entities to the pipe.`}
        </p>
      </div>
    );

    content = (
      <div className="pipe-input__content">
        <div className="pipe-input__content-section">
          <Box>
            <Box.Header>
              <Box.Title>Upload</Box.Title>
            </Box.Header>
            <Box.Content padding>
              <EntityUpload
                url={`${props.subUrl}/receivers/${encodeURIComponent(props.pipe._id)}/entities`}
                token={props.token}
                allowed={['json']}
                editor
                curl
              />
            </Box.Content>
          </Box>
        </div>
      </div>
    );
  } else if (
    ['merge', 'merge_datasets', 'union_datasets', 'diff_datasets', 'embedded'].includes(sourceType)
  ) {
    renderEntityViewer();
  } else {
    // In this case, we want to add a button that will explicitly load the entities instead of automatically loading them
    // This is because these other sources talk over the network and requests might count towards rate limits

    if (fetchRemoteEntities) {
      description = (
        <div className="pipe-input__description">
          <h1 className="heading-page">Input entities</h1>
          <p className="output-">Input entities from the source</p>
        </div>
      );
      content = (
        <div className="pipe-input__content">
          <EntityViewer pipe={props.pipe} stage="source" />
        </div>
      );
    } else {
      description = (
        <div className="pipe-input__description">
          <h1 className="heading-page">Input entities</h1>
          <p className="output-">
            This pipe has an external source and loading the input entities will trigger a network
            request to the source system
          </p>
        </div>
      );
      content = (
        <div className="pipe-input__content">
          <div className="pipe-input__content-section">
            <Button theme="primary" onClick={() => setFetchRemoteEntities(true)}>
              Fetch entities
            </Button>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="pipe-input">
      {description}
      {content}
    </div>
  );
}

PipeInput.propTypes = {
  addCustomActions: PropTypes.func.isRequired,
  datasets: PropTypes.shape({}),
  pipe: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
  registerRefresh: PropTypes.func,
  removeCustomActions: PropTypes.func,
  subUrl: PropTypes.string,
  token: PropTypes.string,
  unregisterRefresh: PropTypes.func,
  datasetsLoaded: PropTypes.bool.isRequired,
};

PipeInput.defaultProps = {
  pipe: null,
  addCustomActions: () => {
    //empty
  },
  removeCustomActions: () => {
    //empty
  },
  registerRefresh: () => {
    //empty
  },
  unregisterRefresh: () => {
    //empty
  },
};

const mapStateToProps = (state, ownProps) => {
  let pipe;
  if (ownProps.pipe) {
    pipe = ownProps.pipe;
  } else if (get(ownProps, 'params.pipeID')) {
    pipe = state.pipes[ownProps.params.pipeID];
  }
  return {
    datasets: state.datasets,
    pipe,
    subUrl: state.subscription.url,
    token: state.subscription.token,
    datasetsLoaded: state.loadStatus.datasetsLoaded,
  };
};

export default connect(mapStateToProps)(PipeInput);
