import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

function Feedback(props) {
  return (
    <span className={`feedback feedback--${props.type}`} {...props}>
      {props.children}
    </span>
  );
}

Feedback.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['error', 'notification', 'success', 'warning']),
};

Feedback.defaultProps = {
  type: 'notification',
};

export default Feedback;
