import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import ConfigList from '../../../components/config-list/ConfigList';
import Flow from '../../../components/flow/Flow';
import { LoadingPanel } from 'Common/LoadingPanel';

import './FlowDashboardPageStyle.css';

function FlowDashboardPage({ flow, entities, subId, router }) {
  const handleNodeClick = (type, id) => {
    router.push(`/subscription/${subId}/${type}s/${type}/${id}/overview`);
  };

  const handleNodeHover = (id) => {
    let skip = 0;
    let name;
    if (id.indexOf('::loop') !== -1) {
      [name, skip] = id.split('::loop');
    } else {
      name = id;
    }

    let idx = flow.findIndex((c) => {
      if (c.data._id === name && skip === 0) {
        return true;
      } else if (c.data._id === name && skip > 0) {
        skip--;
      }
      return false;
    });
    setSelectedIdx(idx);
  };

  const [selectedIdx, setSelectedIdx] = useState(0);

  const objectList = flow.map((c) => c.data);

  return !isEmpty(flow) ? (
    <div className="flow-dashboard">
      <div className="flow-dashboard__flow">
        <Flow
          autoSelectMainNode
          flow={flow}
          onGotoNode={handleNodeClick}
          onHoverNode={handleNodeHover}
          mainNodeId={flow[selectedIdx].data._id}
        />
      </div>
      <div className="flow-dashboard__config">
        <ConfigList
          objectList={objectList}
          subId={subId}
          entities={entities}
          selectedIdx={selectedIdx}
          scrollToSelected
        />
      </div>
    </div>
  ) : (
    <LoadingPanel />
  );
}

FlowDashboardPage.propTypes = {
  flow: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    })
  ),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
  subId: PropTypes.string,
  entities: PropTypes.array,
};

export default withRouter(FlowDashboardPage);
