import React from 'react';
import PropTypes from 'prop-types';

import { withSeparators } from 'Internals/utils';

const getTitleFromChildren = (children) => {
  let label = '';

  React.Children.map(children, (child) => {
    if (typeof child === 'string' || typeof child === 'number') {
      label += child;
    }
  });

  return label;
};

// TODO add functionality from amountToSize here
const Numeric = (props) => (
  <span
    className={props.className}
    style={props.style}
    title={props.title ? props.title : getTitleFromChildren(props.children)}
  >
    {props.withSeparators ? withSeparators(props.children) : props.children}
  </span>
);

Numeric.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
  children: PropTypes.any.isRequired,
  withSeparators: PropTypes.bool,
  onToggleRepresentation: PropTypes.func,
  title: PropTypes.string,
};
Numeric.defaultProps = {
  className: '',
  style: {},
  children: 0,
  withSeparators: true,
};

export default Numeric;
