import React, { lazy } from 'react';
import PropTypes from 'prop-types';

const RC2 = lazy(() => import(/* webpackChunkName: "chartjs" */ 'react-chartjs2'));

import isEqual from 'lodash/isEqual';

import { LoadingPanel } from 'Common/LoadingPanel';
import EmptyState from 'Common/EmptyState/EmptyState';

class DiskUsageGraph extends React.Component {
  constructor(props) {
    super(props);

    this.chartOptions = {
      tooltips: {
        mode: 'point',
        callbacks: {
          label: (tooltipItem, data) =>
            `${data.datasets[tooltipItem.datasetIndex].label}: ${this.getHumanizedDiskUsage(
              tooltipItem.yLabel
            )}`,
        },
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                day: 'MMM DD',
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              suggestedMax: 5,
              suggestedMin: 0,
              callback: (value) => this.getHumanizedDiskUsage(value),
            },
          },
        ],
      },
    };

    this.chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(231,233,237)',
    };

    this.getHumanizedDiskUsage = (value) => {
      const valueAsNumber = Number(value);
      const gigaBytes = (valueAsNumber / 1e9).toFixed(1);
      return `${gigaBytes} GB`;
    };

    this.getDiskData = () => {
      const c3ToGraphJs = (columns) => ({
        labels: columns.find((c) => c[0] === 'x').slice(1),
        datasets: columns
          .filter((c) => c[0] !== 'x')
          .map((c) => ({
            label: c[0],
            data: c.slice(1),
          })),
      });

      return c3ToGraphJs(this.props.data.disk.columns);
    };
  }

  shouldComponentUpdate(nextProps) {
    if (isEqual(nextProps, this.props)) {
      return false;
    }
    return true;
  }

  render() {
    if (!this.props.data) {
      return <EmptyState>No data found</EmptyState>;
    }
    const diskData = this.getDiskData();
    const cubicInterpolationMode = 'monotonic';

    diskData.datasets[0] = {
      ...diskData.datasets[0],
      ...{
        label: 'Datahub disk usage',
        backgroundColor: this.chartColors.green,
        borderColor: this.chartColors.green,
        fill: false,
        cubicInterpolationMode,
      },
    };

    if (diskData.datasets.length > 1) {
      if (this.props.datahubSize === 'multi') {
        diskData.datasets.pop();
      } else {
        diskData.datasets[1] = {
          ...diskData.datasets[1],
          ...{
            label: 'Datahub disk usage limit',
            backgroundColor: this.chartColors.red,
            borderColor: this.chartColors.red,
            fill: false,
            cubicInterpolationMode,
          },
        };
      }
    }

    return (
      <React.Suspense fallback={<LoadingPanel />}>
        <RC2 data={diskData} options={this.chartOptions} type="line" />
      </React.Suspense>
    );
  }
}

DiskUsageGraph.propTypes = {
  connected: PropTypes.bool.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default DiskUsageGraph;
