import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Actions from 'Redux/thunks/subscriptions';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import Page from '../../components/page';
import PageHeader, { PageHeaderTitle } from '../../components/page-header';
import Layout from '../../layout/full-sidebar';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import SesamLink from 'Common/Links/SesamLink';
import { compose } from 'redux';

class RequestTrial extends React.Component {
  constructor(props) {
    super(props);

    this.onRequestTrial = (ev) => {
      ev.preventDefault();
      this.setState({ pleaseWait: true });
      this.props
        .requestTrial(this.state)
        .then(() => this.setState({ pleaseWait: false }))
        .then(() => this.props.router.push('/'))
        .catch(() => this.setState({ pleaseWait: false }));
    };

    this.handleFieldChange = (ev) => {
      this.setState({ [ev.target.id]: ev.target.value });
    };

    this.state = {
      phoneNumber: '',
      company: '',
    };
  }

  render() {
    const createTrialRequestInfo = [];
    if (this.props.lastCreateTrialRequest) {
      const userSubmittedInformation = this.props.lastCreateTrialRequest.user_submitted_information;
      for (const property in userSubmittedInformation) {
        if (userSubmittedInformation.hasOwnProperty(property)) {
          const value = userSubmittedInformation[property];
          createTrialRequestInfo.push(
            <dt className="key-value__key" key={`key_${property}`}>
              {property}:
            </dt>
          );
          createTrialRequestInfo.push(
            <dd className="key-value__value" key={`value_${property}`}>
              {value}
            </dd>
          );
        }
      }
    }
    return (
      <Layout>
        <Page>
          <PageHeader>
            <PageHeaderTitle>
              <SesamLink to={'/'}>Dashboard</SesamLink>
            </PageHeaderTitle>
            <PageHeaderTitle>Request private trial</PageHeaderTitle>
          </PageHeader>
          {this.props.lastCreateTrialRequest && (
            <main className="scrollArea">
              <div className="row">
                <div className="col gr-primary">
                  <p>
                    You made a trial subscription request at{' '}
                    {moment(this.props.lastCreateTrialRequest.created_time).format(
                      'MMMM Do YYYY, h:mm:ss a'
                    )}
                    .
                  </p>
                  <div>
                    The request contained this information:
                    <dl className="key-value">{createTrialRequestInfo}</dl>
                  </div>
                </div>
              </div>
            </main>
          )}
          {!this.props.lastCreateTrialRequest && (
            <main className="scrollArea">
              <div className="row">
                <div className="col gr-primary">
                  <p>
                    Here you can request that a private trial subscription is created for you. Once
                    you send the application a sesam employee will review the application and may
                    contact you for additional information. Once the application has been approved,
                    a new subscription will appear on your dashboard.
                  </p>

                  <Form onSubmit={this.onRequestTrial}>
                    <SesamTextField
                      label="Phone number"
                      id="phoneNumber"
                      value={this.state.phoneNumber}
                      onChange={this.handleFieldChange}
                      margin="normal"
                    />
                    <SesamTextField
                      label="Company"
                      id="company"
                      value={this.state.company}
                      onChange={this.handleFieldChange}
                      margin="normal"
                    />
                    <FormActions>
                      <Button
                        data-selenium="request-trial"
                        disabled={this.state.pleaseWait}
                        type="submit"
                      >
                        {this.state.pleaseWait ? 'Sending request…' : 'Request trial'}
                      </Button>
                    </FormActions>
                  </Form>
                </div>
              </div>
            </main>
          )}
        </Page>
      </Layout>
    );
  }
}

RequestTrial.propTypes = {
  requestTrial: PropTypes.func.isRequired,
  lastCreateTrialRequest: PropTypes.shape({
    user_submitted_information: PropTypes.object,
    status: PropTypes.string.required,
    created_time: PropTypes.string.required,
    responded_time: PropTypes.string,
    response: PropTypes.string,
    dismissed_from_gui: PropTypes.bool,
  }),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    lastCreateTrialRequest: state.user.lastCreateTrialRequest,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    requestTrial: (data) => dispatch(Actions.requestTrial(data)),
  };
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RequestTrial);
