import { createReducer } from '@reduxjs/toolkit';
import { lastViewedTabUpdated, NavId } from '../thunks/navigation';

type NavigationState = {
  lastVisitedTab: {
    [id in NavId]: string;
  };
};

const initialState: NavigationState = {
  lastVisitedTab: {
    dataset: '',
    datasets: '',
    gdpr: '',
    pipe: '',
    pipes: '',
    'settings-databrowser': '',
    'settings-datahub': '',
    'settings-subscription': '',
    system: '',
    systems: '',
  },
};

/*
Note: We are using the builder callback for creating a typesafe reducer.
See https://redux-toolkit.js.org/usage/usage-with-typescript#building-type-safe-reducer-argument-objects
*/
export default createReducer(initialState, (builder) =>
  builder.addCase(lastViewedTabUpdated, (state, action) => {
    state.lastVisitedTab[action.payload.navId] = action.payload.value;
  })
);
