import JwtAPI from '../../api/jwt';
import { apologise } from './apology';
import { AppThunk } from '../..';
import { createAction } from '@reduxjs/toolkit';
import { SubscriptionJWT } from '../../types/types';

export const jwtAdded = createAction<SubscriptionJWT>('jwt/added');
export const jwtRemoved = createAction<string>('jwt/deleted');
export const jwtAllLoaded = createAction<SubscriptionJWT[]>('jwt/loadedAll');

export default {
  add(subId: string, jwt: string): AppThunk {
    return function (dispatch) {
      return JwtAPI.post(subId, jwt)
        .then((newJwt) => {
          const jwtString = newJwt.jwt_string;
          const clientSecret = newJwt.client_secret;
          delete newJwt.jwt_string;
          delete newJwt.client_secret;
          dispatch(jwtAdded(newJwt));
          return { jwtString, clientSecret };
        })
        .catch((error) => dispatch(apologise(error)));
    };
  },

  delete(subId: string, id: string): AppThunk {
    return function (dispatch) {
      return JwtAPI.remove(subId, id)
        .then(() => dispatch(jwtRemoved(id)))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  loadAll(subId: string): AppThunk {
    return function (dispatch) {
      return JwtAPI.getAll(subId)
        .then((allJwts) => dispatch(jwtAllLoaded(allJwts)))
        .catch((error) => dispatch(apologise(error)));
    };
  },
};
