import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import CollapsePanel from 'Common/CollapsePanel/CollapsePanel';
import { getCurrentSub } from 'Redux/thunks/subscriptions';
import { LoadingPanel } from 'Common/LoadingPanel';
import { getDatabrowserURL, getDatabrowserURLFromCustomFQDN } from '../settings-databrowser/index';

import './style.css';

function SettingsDatabrowserNetwork(props) {
  let databrowserProvisioningResult = {};
  if (props.sub) {
    if (props.sub.provisioning_result) {
      const databrowserListFromProvisioningResult = props.sub.provisioning_result.databrowser;
      if (databrowserListFromProvisioningResult) {
        for (let i = 0; i < databrowserListFromProvisioningResult.length; i += 1) {
          const databrowser = databrowserListFromProvisioningResult[i];
          if (databrowser.id === databrowserId) {
            databrowserProvisioningResult = databrowser;
            break;
          }
        }
      }
    }
  }

  const databrowserId = props.params.databrowserId;

  let databrowserURL = getDatabrowserURL(props.sub, databrowserId);

  if (!databrowserURL) {
    return <LoadingPanel loadingMessage="Provisioning…" />;
  }

  if (!databrowserURL) {
    databrowserURL = '*unknown*';
  }

  return (
    <main className="scrollArea">
      <div className="row">
        <div className="col col--elastic gr-primary">
          <h2 className="heading-section">Data Access Portal backend</h2>
          <p>The URL of the Data Access Portal backend is: {databrowserURL}</p>

          {props.isPortalAdmin && (
            <div>
              <h2 className="heading-section">Debug info</h2>
              <p>
                This section contains low-level information that is mostly useful for depevelopers
                during debugging of problems.
              </p>
              <CollapsePanel title="Raw provisioner information" startCollapsed={true}>
                <pre>{JSON.stringify(databrowserProvisioningResult, null, 4)}</pre>
              </CollapsePanel>
            </div>
          )}
        </div>
        <div className="col gr-secondary">
          <p>This page displays various Data Access Portal network settings.</p>
        </div>
      </div>
    </main>
  );
}

SettingsDatabrowserNetwork.propTypes = {
  sub: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  isItestMode: PropTypes.bool.isRequired,
};

SettingsDatabrowserNetwork.contextTypes = {
  // https://github.com/reactjs/react-router/issues/975
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    sub: getCurrentSub(state),
    isItestMode: state.globals.status.is_itest_mode,
    subId: state.subscription.id,
    isPortalAdmin: state.user.isPortalAdmin,
  };
}

export default connect(mapStateToProps)(SettingsDatabrowserNetwork);
