import React from 'react';
import { CellProps } from 'react-table';
import get from 'lodash/get';
import find from 'lodash/find';
import Tooltip from '@material-ui/core/Tooltip';
import isObject from 'lodash/isObject';
import { EntityType } from '../types/types';
import { Entity } from '../types/entities.types';

function getColumnAndNamespace(entityKey: string, type = undefined) {
  let col;
  let key: string | undefined = entityKey;
  let ns;

  const keySplit = entityKey.split(':');
  if (keySplit.length > 1) {
    key = keySplit.pop();
    ns = keySplit.join(':');
  }

  col = {
    Header: key,
    id: entityKey,
    show: true,
    type,
    accessor: entityKey,
    sortable: false,
    width: 300,
    Cell: ({ cell: { value } }: CellProps<any>) => {
      if (value === null || value === undefined) {
        return '';
      } else if (Array.isArray(value)) {
        return value.length === 0 ? (
          '[ ]'
        ) : (
          <Tooltip title={JSON.stringify(value, null, 2)}>
            <span>{`[...]`}</span>
          </Tooltip>
        );
      } else if (isObject(value)) {
        return (
          <Tooltip title={JSON.stringify(value, null, 2)}>
            <span>{`{...}`}</span>
          </Tooltip>
        );
      }
      return String(value);
    },
  };

  return [col, ns];
}

/**
 * Generates table columns from a list of entities
 * @param {arrray} entities // Array of entities
 * @param {bool} addSystemColumns // Show attributes starting with "_"
 * @param {bool} addSequenceColumn // Add column showing the sequence number of the entity
 */
export const generateTableColumns = (
  generateFrom: 'entityType' | 'entities',
  entities: Entity[],
  entityType: EntityType,
  addSystemColumns: boolean,
  addSequenceColumn: boolean
  // addPinColumn
) => {
  const initialAcc = [];

  if (addSequenceColumn) {
    initialAcc.push({
      Header: '#',
      id: 'sequence',
      show: true,
      accessor: '_updated',
      sortable: false,
      width: 50,
    });
  }

  if (generateFrom === 'entityType') {
    return Object.keys(entityType.properties).reduce((acc, prop) => {
      if (prop[0] !== '_' || prop === '_id' || addSystemColumns) {
        let colHeader;
        const [col, ns] = getColumnAndNamespace(
          prop,
          get(entityType, ['properties', prop, 'type'])
        );

        if (ns) {
          colHeader = find(acc, (c) => c['Header'] === ns);

          if (!colHeader) {
            colHeader = { Header: ns, columns: [col] };
            acc.push(colHeader);
          } else {
            colHeader.columns.push(col);
          }
        } else {
          acc.push(col);
        }
      }

      return acc;
    }, initialAcc);
  }

  if (generateFrom === 'entities') {
    return entities.reduce((acc, entity) => {
      const entityKeys = Object.keys(entity);
      entityKeys.forEach((entityKey) => {
        if (entityKey[0] !== '_' || entityKey === '_id' || addSystemColumns) {
          let colHeader;
          const [col, ns] = getColumnAndNamespace(entityKey);

          if (ns) {
            colHeader = find(acc, (c) => c['Header'] === ns);

            if (!colHeader) {
              colHeader = { Header: ns, columns: [col] };
              acc.push(colHeader);
            } else {
              let existingColumn;
              existingColumn = find(colHeader.columns, (c) => c['Header'] === col['Header']);
              if (!existingColumn) colHeader.columns.push(col);
            }
          } else {
            let existingColumn;
            existingColumn = find(acc, (c) => c['Header'] === col['Header']);
            if (!existingColumn) acc.push(col);
          }
        }
      });

      return acc;
    }, initialAcc);
  }
};

export function generateTableColumnsFromEntities(
  entities: Entity[],
  addSystemColumns = false,
  addSequenceColumn = true
) {
  return generateTableColumns('entities', entities, null, addSystemColumns, addSequenceColumn);
}

export function generateTableColumnsFromEntityType(
  entityType: EntityType,
  addSystemColumns = false,
  addSequenceColumn = true
) {
  return generateTableColumns('entityType', [], entityType, addSystemColumns, addSequenceColumn);
}
