import PropTypes from 'prop-types';
import React from 'react';
import SesamLink from 'Common/Links/SesamLink';

import DatasetIcon from '../../images/icons/dataset.svg';
import OkIcon from '../../images/icons/ok.svg';
import PipeIcon from '../../images/icons/pipe.svg';
import SystemIcon from '../../images/icons/system.svg';
import SlideRightIcon from '../../images/icons/slide-right.svg';

import './style.css';

function OverviewInstructions(props) {
  const systemDrawing = props.hasUserSystems ? (
    <OkIcon className="instructions-splash__ok" />
  ) : (
    <SystemIcon />
  );
  const pipeDrawing = props.hasUserPipes ? (
    <OkIcon className="instructions-splash__ok" />
  ) : (
    <PipeIcon />
  );
  const datasetDrawing = props.hasUserDatasets ? (
    <OkIcon className="instructions-splash__ok" />
  ) : (
    <DatasetIcon />
  );

  return (
    <div className="instructions-splash">
      <div className="instructions-splash__title">Overview</div>
      <div className="instructions-splash__content">
        Your subscription is currently empty, or unfinished. <br />
        Setting it up can be done in three steps:
      </div>
      <div className="instructions-splash__model">
        <div className="instructions-splash__component">
          {systemDrawing}
          <div className="instructions-splash__description">
            Create a <SesamLink to={`/subscription/${props.subId}/systems`}>system</SesamLink>
          </div>
        </div>
        <div className="instructions-splash__component">
          <div className="instructions-splash__arrow">
            <SlideRightIcon />
          </div>
        </div>
        <div className="instructions-splash__component">
          {pipeDrawing}
          <div className="instructions-splash__description">
            Create a <SesamLink to={`/subscription/${props.subId}/pipes`}>pipe</SesamLink>
          </div>
        </div>
        <div className="instructions-splash__component">
          <div className="instructions-splash__arrow">
            <SlideRightIcon />
          </div>
        </div>
        <div className="instructions-splash__component">
          {datasetDrawing}
          <div className="instructions-splash__description">Populate a dataset</div>
        </div>
      </div>
    </div>
  );
}

OverviewInstructions.propTypes = {
  hasUserDatasets: PropTypes.bool.isRequired,
  hasUserPipes: PropTypes.bool.isRequired,
  hasUserSystems: PropTypes.bool.isRequired,
  subId: PropTypes.string.isRequired,
};

export default OverviewInstructions;
