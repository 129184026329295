import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

function NotAvailable(props) {
  return (
    <div className="not-available">
      <h2 className="heading-section">{props.title}</h2>
      <p>{props.message}</p>
    </div>
  );
}

NotAvailable.propTypes = {
  message: PropTypes.node,
  title: PropTypes.string,
};

NotAvailable.defaultProps = {
  message: 'This content is not available right now',
  title: 'Sorry',
};

export default NotAvailable;
