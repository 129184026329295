import { PERSIST_CONNECTION_STRING_URL_LOCAL_STORAGE_KEY } from 'Constants/commonConstants';
import { getFromLocalStorage, removeFromLocalStorage, setIntoLocalStorage } from './local-storage';
import { isEmpty } from 'lodash';

export type CanConnectUtilArgs = {
  pleaseWait?: boolean;
  previousURL?: string;
  nextURL?: string;
  persistedURL?: string;
};

export type CustomConnectionURL = {
  [key: string]: string;
};

export const canConnect = (canConnectArgs: CanConnectUtilArgs) => {
  if (canConnectArgs.pleaseWait || !canConnectArgs.previousURL) {
    return false;
  }

  return canConnectArgs.persistedURL
    ? canConnectArgs.previousURL !== canConnectArgs.persistedURL
    : canConnectArgs.previousURL !== canConnectArgs.nextURL;
};

type PersistURLServiceArgs = {
  setURL: typeof setIntoLocalStorage;
  getURL: typeof getFromLocalStorage;
  removeURL: typeof removeFromLocalStorage;
  localStorageKey?: string;
};

type PersistURLServiceAPI = {
  setPersistedURL: (subscriptionId: string, value: string) => void;
  getPersistedURL: (subscriptionId: string) => string | undefined;
  getAllPersistedURLs: () => CustomConnectionURL | undefined;
  removePersistedURL: (subscriptionId: string) => void;
};

export const initPersistURLService = (args: PersistURLServiceArgs) => (): PersistURLServiceAPI => {
  const {
    setURL,
    getURL,
    removeURL,
    localStorageKey = PERSIST_CONNECTION_STRING_URL_LOCAL_STORAGE_KEY,
  } = args;

  const setPersistedURL = (subscriptionId: string, value: string) => {
    setURL(localStorageKey, [subscriptionId], value);
  };

  const getPersistedURL = (subscriptionId: string): string | undefined => {
    const persistedURL = getURL(localStorageKey, subscriptionId);

    if (persistedURL && !isEmpty(persistedURL)) {
      return persistedURL;
    }
  };

  const getAllPersistedURLs = (): CustomConnectionURL | undefined => {
    const persistedConnectionStrings = getURL(localStorageKey);

    if (persistedConnectionStrings) {
      return persistedConnectionStrings;
    }
  };

  const removePersistedURL = (subscriptionId: string) => {
    removeURL(localStorageKey, subscriptionId);
  };

  return {
    setPersistedURL,
    getPersistedURL,
    getAllPersistedURLs,
    removePersistedURL,
  };
};

export const persistURLService = initPersistURLService({
  setURL: setIntoLocalStorage,
  getURL: getFromLocalStorage,
  removeURL: removeFromLocalStorage,
});
