import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import produce from 'immer';
import Actions from 'Redux/thunks/user-profile';

import Button from 'Common/Button/Button';
import ToggleSwitch from 'Common/ToggleSwitch';

const actions = {
  TOGGLE_ALL: 'TOGGLE_ALL',
  TOGGLE: 'TOGGLE',
  SET: 'SET',
};

const internalProviders = ['sesam-portal-internal', 'sesam-portal-auth0'];

const capitalize = (str) => {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return str;
  }
};

const providerSorter = (a, b) => {
  if (internalProviders.includes(a)) return 1;
  if (internalProviders.includes(b)) return -1;
  else return a.localeCompare(b);
};

const makeWhitelistMap = (whitelist, providerIds) => {
  if (!Array.isArray(whitelist) || whitelist.length < 1) whitelist = providerIds;
  return providerIds.reduce((whitelistMap, id) => {
    whitelistMap[id] = whitelist.includes(id);
    return whitelistMap;
  }, {});
};

const makeWhiteListArray = (whitelistMap) =>
  Object.entries(whitelistMap).reduce((result, [k, v]) => {
    if (v) return [...result, k];
    else return result;
  }, []);

const AuthProviderItem = ({ id, value, onToggle }) => {
  const label = internalProviders.includes(id) ? 'Username/password' : capitalize(id);
  return (
    <li key={id} style={{ marginBottom: '0.5rem' }}>
      <ToggleSwitch
        isOn={value}
        labelOn=""
        labelOff=""
        inline={true}
        onToggle={() => onToggle(id)}
      />
      <span>{label}</span>
    </li>
  );
};

AuthProviderItem.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

const createInitialState = (whitelist, ids) => ({
  enabled: whitelist && whitelist.length > 0,
  whitelistMap: makeWhitelistMap(whitelist, ids),
});

const reducer = (state, action) => {
  switch (action.type) {
    case actions.TOGGLE: {
      return produce(state, (draft) => {
        draft.whitelistMap[action.id] = !draft.whitelistMap[action.id];
      });
    }
    case actions.TOGGLE_ALL: {
      return produce(state, (draft) => {
        draft.enabled = !draft.enabled;
      });
    }
    case actions.SET: {
      const whitelistMap = makeWhitelistMap(action.whitelist, action.providerIds);
      const enabled = action.whitelist && action.whitelist.length > 0;
      return { enabled, whitelistMap };
    }
  }
};

const getToggleValue = (state, id) => {
  if (state.enabled) {
    return state.whitelistMap[id];
  } else {
    return true;
  }
};
const UserAuthProviders = ({ user, onUpdate }) => {
  // empty whitelist means all providers are allowed
  // a whitelist cannot have all the providers
  // a whitelist must have unique entries
  // for easier manipulation, whitelist is an object whitelistMap in the state
  const [state, dispatch] = React.useReducer(
    reducer,
    createInitialState(user.providerWhitelist, user.providerIds)
  );
  const [shouldUpdate, setShouldUpdate] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  React.useEffect(() => {
    dispatch({
      type: actions.SET,
      whitelist: user.providerWhitelist,
      providerIds: user.providerIds,
    });
    setUpdating(false);
  }, [user.providerWhitelist, user.providerIds]);

  React.useEffect(() => {
    if (shouldUpdate) {
      let updatedUser = {};
      if (state.enabled) {
        const whitelistArray = makeWhiteListArray(state.whitelistMap);
        updatedUser = { ...user, providerWhitelist: whitelistArray };
      } else {
        updatedUser = { ...user, providerWhitelist: [] };
      }
      onUpdate(updatedUser);
      setShouldUpdate(false);
      setUpdating(true);
    }
  }, [shouldUpdate]);
  const isNotAllowedState = state.enabled && Object.values(state.whitelistMap).every((x) => !x);
  if (!user.providerIds) return null;
  return (
    <div style={{ marginTop: '30px' }}>
      <h2 className="heading-section">Authentication providers</h2>
      <span>
        These are the authentication providers you have used to login to Management Studio. Once you
        enable restricting providers, you can pick which providers are allowed to be logged in with.
      </span>
      <p>
        <ToggleSwitch
          isOn={state.enabled}
          labelOn=""
          labelOff=""
          inline={true}
          onToggle={() => dispatch({ type: actions.TOGGLE_ALL })}
        />
        <span>Restrict providers</span>
      </p>
      <hr style={{ marginBottom: '1rem' }} />
      <div
        style={{
          opacity: state.enabled ? 1 : 0.2,
          pointerEvents: state.enabled ? 'auto' : 'none',
        }}
      >
        <h2 className="text-remark">
          {state.enabled ? 'Restricting providers is enabled' : 'Restricting providers is disabled'}
        </h2>

        <ul
          style={{
            listStyleType: 'none',
            padding: 0,
          }}
        >
          {user.providerIds
            .slice()
            .sort(providerSorter)
            .map((id) => {
              const value = getToggleValue(state, id);
              const handleToggle = (id) => dispatch({ type: actions.TOGGLE, id });
              return <AuthProviderItem key={id} id={id} value={value} onToggle={handleToggle} />;
            })}
        </ul>
      </div>

      {isNotAllowedState && <p>You must have at least one authentication provider enabled.</p>}
      <Button
        disabled={isNotAllowedState || updating}
        loading={updating}
        loadingText="Updating"
        onClick={() => setShouldUpdate(true)}
        text="Save"
      />
    </div>
  );
};

UserAuthProviders.propTypes = {
  user: PropTypes.shape({}).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdate: (data) => dispatch(Actions.changeProfile(null, data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserAuthProviders);
