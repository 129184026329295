import zendesk from 'Internals/zendesk';
import { handlePortalError } from '../utils';
import AuthAPI, { SignupData } from '../../api/auth';
import { AppThunk } from '../..';
import { createAction } from '@reduxjs/toolkit';

export const userLoggedIn = createAction('auth/loggedIn');
export const userLoginFailed = createAction<boolean>('auth/loginFailed');
export const signupUserFailed = createAction('auth/signupFailed');
export const forgotPasswordSent = createAction<string>('auth/forgotPasswordSent');
export const passwordReset = createAction('auth/passwordReset');
export const passwordResetInvalidToken = createAction('auth/passwordResetInvalidToken');

export default {
  attemptLogin(email: string, password: string, captcha?: string): AppThunk {
    return function (dispatch, getState) {
      return AuthAPI.login(getState().globals.portalUrl, email, password, captcha)
        .then((response) => {
          if (response.use_sso) {
            return response.url;
          } else {
            dispatch(userLoggedIn());
            return null;
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            dispatch(userLoginFailed(!!error.responseBody.verification_needed));
          } else {
            dispatch(handlePortalError(error));
          }
          // need to throw, otherwise the promise gets fulfilled
          throw error;
        });
    };
  },

  logout(): AppThunk {
    return function (dispatch, getState) {
      const logoutURL = `${getState().globals.portalUrl}/logout2`;
      zendesk.logout();
      window.location.href = logoutURL;
    };
  },

  attemptSignup(signupForm: SignupData): AppThunk {
    return function (dispatch, getState) {
      return AuthAPI.signup(getState().globals.portalUrl, signupForm)
        .then(() => dispatch(userLoggedIn()))
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            dispatch(signupUserFailed());
          } else {
            dispatch(handlePortalError(error));
          }
          // need to throw, otherwise the promise gets fulfilled
          throw error;
        });
    };
  },

  forgotPassword(email: string, captchaResponse: string): AppThunk {
    return function (dispatch, getState) {
      return AuthAPI.forgotPassword(getState().globals.portalUrl, email, captchaResponse)
        .then(() => dispatch(forgotPasswordSent(email)))
        .catch((error) => {
          dispatch(handlePortalError(error));
          // need to throw, otherwise the promise gets fulfilled
          throw error;
        });
    };
  },

  resetPassword(token: string, password: string): AppThunk {
    return function (dispatch, getState) {
      return AuthAPI.resetPassword(getState().globals.portalUrl, token, password)
        .then(() => dispatch(passwordReset()))
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            dispatch(passwordResetInvalidToken());
          } else {
            dispatch(handlePortalError(error));
          }
          // need to throw, otherwise the promise gets fulfilled
          throw error;
        });
    };
  },

  // not much of a thunk either
  verifyEmail(token: string): AppThunk {
    return function (dispatch, getState) {
      return AuthAPI.verifyEmail(getState().globals.portalUrl, token);
    };
  },
};
