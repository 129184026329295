import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './CollapsePanel.css';
import { withTheme } from '@material-ui/core';

/*
 * Panel with title that can be collapsed
 */
function CollapsePanel(props) {
  const [collapsed, setCollapsed] = useState(props.startCollapsed);
  const className = collapsed ? 'collapsepanel' : 'collapsepanel show';
  return (
    <div
      className={className}
      style={{
        backgroundColor: props.theme.palette.background.light,
        border: `1px solid ${props.theme.palette.divider}`,
      }}
    >
      <h2 className="header" onClick={() => setCollapsed((collapsed) => !collapsed)}>
        {props.title}
      </h2>
      <div className="panel" style={{ backgroundColor: props.theme.palette.background.semilight }}>
        {props.children}
      </div>
    </div>
  );
}

CollapsePanel.defaultProps = {
  startCollapsed: true,
  title: 'Title of collapsible panel',
};

CollapsePanel.propTypes = {
  title: PropTypes.string.isRequired,
  startCollapsed: PropTypes.bool.isRequired,
  children: PropTypes.node,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(CollapsePanel);
