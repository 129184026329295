import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import './BoxStyle.css';

const useStyle = makeStyles((theme) => {
  return {
    box: {
      backgroundColor: theme.palette.background.semilight,
      border: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      maxWidth: '100%',
      overflow: 'auto',
      height: '100%',
    },
    boxNoScroll: {
      height: 'auto',
    },
    boxHeader: {
      padding: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.divider}`,
      flexShrink: '0',
      height: '2.5rem',
    },
    boxHeaderTitle: {
      display: 'flex',
      color: theme.palette.text.primary,
      '& path': {
        fill: theme.palette.text.primary,
      },
      overflow: 'hidden',
    },
    boxHeaderActions: {
      display: 'flex',
      color: theme.palette.text.primary,
    },
    boxContent: {
      color: theme.palette.text.primary,
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      overflow: 'auto',
      backgroundColor: theme.palette.background.semilight,
      height: '100%',
      '& .graph': {
        width: '100%',
      },
    },
    boxContentPadded: {
      padding: '0.9375rem',
    },
    boxContentPaddedSmall: {
      padding: '0.5rem',
    },
    boxContentWhite: {
      backgroundColor: theme.palette.background.light,
    },
  };
});

const Header = ({ children, ...rest }) => {
  const classes = useStyle();
  return (
    <div className={classes.boxHeader} {...rest}>
      {children}
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

const Title = (props) => {
  const classes = useStyle();
  return <div className={classes.boxHeaderTitle}>{props.children}</div>;
};

Title.propTypes = {
  children: PropTypes.node,
};

const Actions = ({ className, children, ...rest }) => {
  const classes = useStyle();
  return (
    <div className={clsx([classes.boxHeaderActions, className && className])} {...rest}>
      {children}
    </div>
  );
};

Actions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const Content = React.forwardRef(
  ({ className, padding, compact, children, white, style, ...restProps }, ref) => {
    const classes = useStyle();

    return (
      <div
        className={clsx([
          classes.boxContent,
          padding && classes.boxContentPadded,
          compact && classes.boxContentPaddedSmall,
          white && classes.boxContentWhite,
          className && className,
        ])}
        style={style}
        {...restProps}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  padding: PropTypes.bool,
  compact: PropTypes.bool,
  white: PropTypes.bool,
};

Content.defaultProps = {
  padding: false,
  compact: false,
  white: false,
};

/**
 * A component that wraps its children in a box.
 */
const Box = ({ children, className, noScroll, style, ...restProps }) => {
  const classes = useStyle();

  return (
    <div
      className={clsx([classes.box, className && className, noScroll && classes.boxNoScroll])}
      style={style}
      {...restProps}
    >
      {children}
    </div>
  );
};

Box.Header = Header;
Box.Title = Title;
Box.Actions = Actions;
Box.Content = Content;

Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noScroll: PropTypes.bool,
};

export default Box;
export { Header, Title, Actions, Content };
