import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../types/types';

import Actions from 'Redux/thunks/user-profile';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import Feedback from '../../components/feedback';

const ProfileForm: React.FC = () => {
  const dispatch = useDispatch();

  const email = useSelector((state: State) => state.user.email);
  const name = useSelector((state: State) => state.user.name);

  const changeProfile = (data: {
    name: string;
    email: string;
    pleaseWait: Boolean;
    notValid: boolean;
    profileUpdated: boolean;
  }) => dispatch(Actions.changeProfile(data));

  const [emailValue, setEmailValue] = useState<string>(email);
  const [nameValue, setNameValue] = useState<string>(name);
  const [profileUpdated, setProfileUpdated] = useState<boolean>(false);
  const [notValid, setNotValid] = useState<boolean>(false);
  const [pleaseWait, setPleaseWait] = useState<boolean>(false);

  const updateProfile = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const notValid = !name;

    setProfileUpdated(false);
    setNotValid(notValid);

    if (!notValid) {
      setPleaseWait(true);

      changeProfile({
        name: nameValue,
        email: emailValue,
        pleaseWait,
        notValid,
        profileUpdated,
      })
        .then(() => {
          setProfileUpdated(true);
          setPleaseWait(false);
        })
        .catch(() => {
          setPleaseWait(false);
        });
    }
  };

  useEffect(() => {
    setNameValue(name);
  }, [name]);
  useEffect(() => {
    setEmailValue(email);
  }, [email]);

  return (
    <Form onSubmit={updateProfile}>
      <SesamTextField
        label="Name"
        id="name"
        value={nameValue}
        onChange={(ev: React.SyntheticEvent<Element, Event>) => setNameValue(ev.target.value)}
        margin="normal"
      />

      <SesamTextField
        label="Email"
        name="email"
        value={emailValue}
        type="email"
        disabled
        margin="normal"
      />

      <div style={{ display: 'inline-block', float: 'right', marginTop: '5px' }}>
        <FormActions>
          <Button
            type="submit"
            text="Update profile"
            loadingText="Updating profile"
            loading={pleaseWait}
            disabled={pleaseWait}
          />
        </FormActions>
      </div>
      {notValid && <Feedback type="warning">Name is required</Feedback>}
      {profileUpdated && <Feedback>Profile updated</Feedback>}
    </Form>
  );
};

export default ProfileForm;
