import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import Page from '../../../components/page';
import PageHeader, { PageHeaderTitle } from '../../../components/page-header';
import TabbedNav from '../../../components/tabbed-nav';
import FocusPanel from '../../../components/focus-panel';
import { makeRoleCheckerForCurrentSub } from 'Redux/thunks/subscriptions';
import Box from 'Common/Box/Box';

const GDPRPage = (props) => {
  if (!props.databrowserExists)
    return (
      <Page>
        <PageHeader>
          <PageHeaderTitle>GDPR</PageHeaderTitle>
        </PageHeader>
        <div style={{ flex: '0 0 auto' }}>
          <Box>
            <Box.Header>Error</Box.Header>
            <Box.Content padding>
              The subscription doesn't have a databrowser associated with it or there was a problem
              provisioning it.
            </Box.Content>
          </Box>
        </div>
      </Page>
    );
  if (!(props.roleChecker('group:Admin') || props.roleChecker('group:GDPR_operations'))) {
    // the user isn't allowed to access any of the tabs on this page.
    return (
      <FocusPanel>
        <div>
          <h2 className="heading-page">Access denied</h2>
          <p>You don&apos;t have the permissions required to access this page.</p>
        </div>
      </FocusPanel>
    );
  }

  const nav = [];

  if (props.roleChecker('group:Admin')) {
    nav.push({
      label: 'Setup',
      to: `/subscription/${props.subId}/gdpr/data-types`,
    });
  }

  if (props.roleChecker('group:Admin') || props.roleChecker('group:GDPR_operations')) {
    nav.push({
      label: 'Access Requests',
      to: `/subscription/${props.subId}/gdpr/access-requests`,
    });
    nav.push({
      label: 'Change Requests',
      to: `/subscription/${props.subId}/gdpr/change-requests`,
    });
    nav.push({
      label: 'Purposes',
      to: `/subscription/${props.subId}/gdpr/purposes`,
    });
    nav.push({
      label: 'Data types',
      to: `/subscription/${props.subId}/gdpr/datatypes`,
    });
    nav.push({
      label: 'Policies',
      to: `/subscription/${props.subId}/gdpr/policies`,
    });
  }

  if (props.roleChecker('group:Admin')) {
    nav.push({
      label: 'Translations',
      to: `/subscription/${props.subId}/gdpr/translations`,
    });
  }

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle>GDPR</PageHeaderTitle>
      </PageHeader>
      <TabbedNav nav={nav}>{props.children}</TabbedNav>
    </Page>
  );
};

GDPRPage.propTypes = {
  children: PropTypes.node,
  roleChecker: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
  databrowserExists: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const subData = state.subscriptions.find((s) => s.id === state.subscription.id);
  const databrowser = subData.connections.find((i) => i.type === 'databrowser');
  return {
    subId: state.subscription.id,
    roleChecker: makeRoleCheckerForCurrentSub(state),
    databrowserExists: !!databrowser,
  };
}

export default connect(mapStateToProps)(GDPRPage);
