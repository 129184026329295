import { compose } from 'redux';
import { connect } from 'react-redux';
import parse from 'url-parse';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import yup from 'yup';
import { withRouter } from 'react-router';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import PipeActions from 'Redux/thunks/pipes';
import SystemActions from 'Redux/thunks/systems';

import { LoadingPanel } from 'Common/LoadingPanel';
import { makeRoleCheckerForCurrentSub } from 'Redux/thunks/subscriptions';
import Wizard from 'Common/Wizard/Wizard';
import { getFromLocalStorage } from 'Internals/local-storage';
import { DEFAULT_CONFIG_GROUP_ID, DEFAULT_CONFIG_GROUP_LABEL } from 'Redux/selectors';

import SesamTextField from 'Common/SesamTextField/SesamTextField';

const LOCAL_STORAGE_KEY = 'sesam--config-editor';

const initialState = {
  url: '',
  name: '',
};

const validationSchema = yup.object().shape({
  url: yup.string().url('This field must be a valid URL').required('This field is required'),
  name: yup.string().required('This field is required').max(50, 'Name is too long'),
});

function Step1({ errors, state, setValue, setValueTouched }) {
  return (
    <React.Fragment>
      <div style={{ marginBottom: '20px' }}>
        Select the endpoint you want the outgoing flow to push its entities to. Must be a valid URL.
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SesamTextField
          margin="none"
          inputProps={{ autoFocus: true }}
          error={Boolean(errors.url)}
          helperText={errors.url}
          value={state.url}
          label="URL"
          onChange={(e) => setValue('url', get(e, 'target.value'))}
          onFocus={() => setValueTouched('url', true)}
        />
      </div>
    </React.Fragment>
  );
}

Step1.propTypes = {
  errors: PropTypes.shape({}),
  state: PropTypes.shape({}),
  setValue: PropTypes.func,
  setValueTouched: PropTypes.func,
};

function Step2({ bag, errors, state, setValue, setValueTouched, touched }) {
  const url = parse(state.url);

  if (!touched['name'] && !state.name && state.url) {
    setValue('name', url.hostname.replace(/\./g, '-'));
    setValueTouched('name', true);
  }

  return (
    <React.Fragment>
      <div style={{ marginBottom: '20px' }}>Select the name of the system.</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!bag.isAdmin && <div style={{ marginRight: '5px', marginBottom: '15px' }}>user - </div>}
        <SesamTextField
          margin="none"
          inputProps={{ autoFocus: true }}
          error={Boolean(errors.name)}
          helperText={errors.name}
          value={state.name}
          label="Name"
          onChange={(e) => setValue('name', get(e, 'target.value'))}
          onFocus={() => setValueTouched('name', true)}
        />
      </div>
    </React.Fragment>
  );
}

Step2.propTypes = {
  bag: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  state: PropTypes.shape({}),
  setValue: PropTypes.func,
  setValueTouched: PropTypes.func,
  touched: PropTypes.shape({}),
};

function checkIfSystemExists(bag, state, setError) {
  const systemName = bag.isAdmin ? `${state.name}` : `user-${state.name}`;
  const systemExists = bag.systems[systemName];

  if (systemExists) {
    setError('name', 'This system already exists');
  }
}

function OutgoingFlowWizard(props) {
  function addOutgoingFlow(state) {
    const isAdmin = props.roleChecker('group:Admin');
    const systemName = isAdmin ? `${state.name}` : `user-${state.name}`;
    const configGroupFromLS = getFromLocalStorage(
      LOCAL_STORAGE_KEY,
      ['configGroup'],
      DEFAULT_CONFIG_GROUP_ID
    );

    const systemConfig = {
      _id: systemName,
      type: 'system:url',
      url_pattern: state.url,
    };

    if (
      configGroupFromLS !== DEFAULT_CONFIG_GROUP_ID &&
      configGroupFromLS !== DEFAULT_CONFIG_GROUP_LABEL // this is likely unnecessary, but the label might be saved in some old state
    ) {
      systemConfig.metadata = { '$config-group': configGroupFromLS };
    }

    const firstPipeId = `${systemName}-${props.dataset._id}`;
    const firstPipeConfig = {
      _id: firstPipeId,
      type: 'pipe',
      source: {
        type: 'dataset',
        dataset: props.dataset._id,
      },
      transform: {
        type: 'dtl',
        rules: {
          default: [['copy', '*']],
        },
      },
    };

    if (
      configGroupFromLS !== DEFAULT_CONFIG_GROUP_ID &&
      configGroupFromLS !== DEFAULT_CONFIG_GROUP_LABEL // this is likely unnecessary, but the label might be saved in some old state
    ) {
      firstPipeConfig.metadata = { '$config-group': configGroupFromLS };
    }

    const secondPipeId = `${systemName}-${props.dataset._id}-endpoint`;
    const secondPipeConfig = {
      _id: secondPipeId,
      type: 'pipe',
      source: {
        type: 'dataset',
        dataset: firstPipeId,
      },
      sink: {
        type: 'json',
        system: systemName,
        url: '/',
      },
    };

    if (
      configGroupFromLS !== DEFAULT_CONFIG_GROUP_ID &&
      configGroupFromLS !== DEFAULT_CONFIG_GROUP_LABEL // this is likely unnecessary, but the label might be saved in some old state
    ) {
      secondPipeConfig.metadata = { '$config-group': configGroupFromLS };
    }

    if (!isAdmin) {
      if (!firstPipeConfig.metadata) {
        firstPipeConfig.metadata = { origin: 'Self service' };
      } else {
        firstPipeConfig.metadata['origin'] = 'Self service';
      }
      if (!secondPipeConfig.metadata) {
        secondPipeConfig.metadata = { origin: 'Self service' };
      } else {
        secondPipeConfig.metadata['origin'] = 'Self service';
      }
    }

    setLoading(true);
    Promise.all([
      props.addSystem(systemConfig),
      props.addPipe(firstPipeConfig),
      props.addPipe(secondPipeConfig),
    ])
      .then(() => {
        props.router.push(
          `/subscription/${props.subId}/flows/flow/config?path=${props.dataset._id}&path=${firstPipeId}&path=${secondPipeId}`
        );
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEmpty(props.systems)) {
      props.loadSystems();
    }
  }, [props]);

  return (
    <div>
      {loading && <LoadingPanel />}
      {!loading && (
        <Wizard
          customContainerStyle={{ height: '175px' }}
          customValidations={[checkIfSystemExists]}
          bag={{
            systems: props.systems,
            isAdmin: props.roleChecker('group:Admin'),
          }}
          initialValues={initialState}
          onFinish={addOutgoingFlow}
          steps={[Step1, Step2]}
          title="New outgoing flow"
          validationSchema={validationSchema}
        />
      )}
    </div>
  );
}

OutgoingFlowWizard.propTypes = {
  addPipe: PropTypes.func.isRequired,
  addSystem: PropTypes.func.isRequired,
  dataset: PropTypes.shape({ _id: PropTypes.string }),
  loadSystems: PropTypes.func.isRequired,
  roleChecker: PropTypes.func.isRequired,
  router: PropTypes.shape({ push: PropTypes.func }),
  subId: PropTypes.string.isRequired,
  systems: PropTypes.shape({}),
};

function mapStateToProps(state) {
  return {
    systems: state.systems,
    subId: state.subscription.id,
    roleChecker: makeRoleCheckerForCurrentSub(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPipe: (pipe) => dispatch(PipeActions.add(pipe)),
    addSystem: (system) => dispatch(SystemActions.add(system)),
    loadSystems: () => dispatch(SystemActions.loadAll()),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(OutgoingFlowWizard);
