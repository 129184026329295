import React, { useEffect, useCallback, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SesamLink from 'Common/Links/SesamLink';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

import Button from 'Common/Button/Button';
import {
  getAvailableActions,
  isGlobal,
  getSinkDataset,
  getUpstreamPipes,
  getDownstreamPipes,
} from 'Internals/pipes';
import Flows from 'Internals/flows';
import { LoadingPanel } from 'Common/LoadingPanel';
import PipeProgressRenderer from 'Common/table-list-renderers/pipe-progress';
import PipeActions from 'Redux/thunks/pipes';
import ModelActions from 'Redux/thunks/models';
import DatasetActions from 'Redux/thunks/datasets';
import InfoTooltip from 'Common/InfoTooltip/InfoTooltip';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import { makeUrlParamsFromPath } from 'Internals/graph';
import { toastAdded } from 'Redux/thunks/global';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import PipeActionsMenu from '../../../components/pipe-actions-menu';
import TabbedNav from '../../../components/tabbed-nav';
import NotFound from '../../../components/not-found';
import Page from '../../../components/page';
import PageHeader, { PageHeaderGroup, PageHeaderTitle } from '../../../components/page-header';
import useAsync from '../../../hooks/useAsync';
import PopupAction from '../../../components/action/PopupAction';
import SesamModal from '../../../components/common/SesamModal/SesamModal';
import OutgoingFlowWizard from '../../../components/outgoing-flow-wizard/OutgoingFlowWizard';

import './Pipe.css';

function Pipe(props, context) {
  let registeredRefreshes = useRef([]);
  const [pipesFlows, setPipesFlows] = useState([]);
  const [customActions, setCustomActions] = useState([]);
  const [cannotFindObject, setCannotFindObject] = useState(false);
  const [showWizard, setShowWizard] = useState(false);

  const _onDelete = useCallback((pipeId) => {
    props.delete(pipeId).then(() => context.router.push(`/subscription/${props.subId}/pipes`));
  }, []);
  const [onDelete] = useAsync(_onDelete);

  const _onDeleteSinkDataset = useCallback(
    (id) => {
      props.deleteSinkDataset(id).then(props.showDeleteSinkDatasetToast);
    },
    [props.deleteSinkDataset, props.showDeleteSinkDatasetToast]
  );

  const [onDeleteSink] = useAsync(_onDeleteSinkDataset);

  const _refresh = useCallback(() => {
    // Note: this returns immediately, so the loading state when used with
    // useAsync is not correct
    props.load(props.params.pipeID).catch(() => setCannotFindObject(true));
    registeredRefreshes.current.forEach((cb) => cb());
  }, [props.params.pipeID]);

  const [refresh] = useAsync(_refresh);

  const [currentDisableComment, setCurrentDisableComment] = useState(null);

  useEffect(() => {
    refresh();
  }, []); // only do on load

  useEffect(() => {
    props.loadAllModels();
  }, [props.loadAllModels]);

  const modelId = useMemo(() => {
    const pipeId = get(props.pipe, '_id');
    if (pipeId && !isEmpty(props.models)) {
      const model = props.models.find(
        (curr) =>
          curr['entity_types'] && Object.keys(curr['entity_types']).some((_id) => _id === pipeId)
      );
      if (model) return model['_id'];
    }
  }, [props.models, props.pipe]);

  const datasetID = get(props.pipe, 'config.effective.sink.dataset');
  /**
   * This is to make sure we are working with the latest version of the dataset.
   */
  useEffect(() => {
    if (props.datasetsLoaded && datasetID) {
      props.loadDataset(datasetID).catch((e) => {
        console.log(e);
      });
    }
  }, [props.datasetsLoaded, props.loadDataset, datasetID]);

  // to refresh the pipe[runtime] data every 2-seconds
  // This is for removing pipe config warnings automatically wihout needing for a custom refersh
  const pipeRuntimeState = get(props.pipe, 'runtime.state');
  useEffect(() => {
    if (pipeRuntimeState === 'Deploying') {
      const id = setInterval(() => {
        props.partialLoad(props.params.pipeID, ['runtime']);
      }, 2000);

      return () => {
        clearInterval(id);
      };
    }
  }, [pipeRuntimeState]);

  const registerRefresh = useCallback((func) => {
    registeredRefreshes.current.push(func);
  }, []);

  const unregisterRefresh = useCallback((func) => {
    registeredRefreshes.current = registeredRefreshes.current.filter((c) => c !== func);
  }, []);

  const getPipesFlows = useCallback((pipeId, pipes, downstreams, lookups) => {
    Flows.get(pipes, downstreams, lookups).then((flows) => {
      setPipesFlows(flows.filter((f) => f.path.includes(pipeId)));
    });
  }, []);

  function getPipeOperationButtons() {
    const btnFactory = (event) => {
      return (
        <Button key={event} onClick={() => props.runPumpOperation(props.pipe._id, event, {})}>
          {event.charAt(0).toUpperCase() + event.slice(1)}
        </Button>
      );
    };
    const availableActions = getAvailableActions(props.pipe);
    const buttons = [];

    if (availableActions.includes('start')) {
      buttons.push(btnFactory('start'));
    } else if (availableActions.includes('stop')) {
      buttons.push(btnFactory('stop'));
    }

    if (availableActions.includes('enable')) {
      buttons.push(btnFactory('enable'));
    } else if (availableActions.includes('disable')) {
      buttons.push(
        <PopupAction
          key="pop-up-action"
          name="Disable"
          useListItem={false}
          onActionClick={() => {
            const parameters = {};
            if (currentDisableComment !== null) {
              parameters['comment'] = currentDisableComment;
            }
            props.runPumpOperation(props.pipe._id, 'disable', parameters);
          }}
          onClosed={() => setCurrentDisableComment(null)}
        >
          <SesamTextField
            id="disable"
            value={currentDisableComment}
            onChange={(ev) => setCurrentDisableComment(ev.target.value)}
            helperText="You can add comment describing the reason for disabling these pipes. Leave blank for no comment."
          />
        </PopupAction>
      );
    }

    return buttons;
  }

  const addCustomActions = useCallback((actions) => {
    setCustomActions(actions);
  }, []);

  const removeCustomActions = useCallback(() => {
    setCustomActions([]);
  }, []);

  useEffect(() => {
    if (props.pipe) {
      getPipesFlows(props.pipe._id, props.pipes, props.upstreams, props.downstreams, props.lookups);
    }
  }, [props.pipe, props.pipes, props.upstreams, props.downstreams, props.lookups]);

  const pipe = props.pipe;
  const datasets = props.datasets;
  const upstreamPipes = useCallback(getUpstreamPipes(props.pipe, props.pipes, props.upstreams), [
    props.pipe,
    props.pipes,
    props.upstreams,
  ]);

  const downstreamPipes = useCallback(
    getDownstreamPipes(props.pipe, props.pipes, props.downstreams),
    [props.pipe, props.pipes, props.downstreams]
  );

  if (!pipe) {
    return null;
  }

  let sinkDatasetID = '';

  let sinkDataset;
  if (!isUndefined(datasets)) {
    sinkDataset = getSinkDataset(pipe, datasets);
    sinkDatasetID = !isUndefined(sinkDataset) ? sinkDataset._id : '';
  }

  const allEntityTypeIds = {};
  for (const m of props.models) {
    const modelEntityTypes = m['entity_types'];
    if (modelEntityTypes && isObject(modelEntityTypes)) {
      for (const entityTypeId of Object.keys(modelEntityTypes)) {
        allEntityTypeIds[entityTypeId] = true;
      }
    }
  }
  const hasPipeSinkEntityType = allEntityTypeIds[props.pipe._id];

  let nav = [];
  let flowButtonLink = '';
  let flowButtonText = '';
  if (pipe) {
    nav = [
      {
        label: 'Dashboard',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/dashboard`,
      },
      {
        label: 'Config',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/edit`,
      },
      {
        label: 'Input',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/input`,
      },
      {
        label: 'Output',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/output`,
      },
      {
        label: 'Execution log',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/execution`,
      },
      {
        label: 'Permissions',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/permissions`,
      },
      {
        label: 'Insights',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/insights`,
      },
      {
        label: 'Notifications',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/notifications`,
      },
      {
        label: 'Graph',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/graph`,
      },
    ];

    if (get(pipe, 'config.effective.pump.dead_letter_dataset')) {
      nav.splice(4, 0, {
        label: 'Dead letters',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/dead-letters`,
      });
    }

    if (sinkDatasetID) {
      nav.push({
        label: 'Indexes',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/indexes`,
      });
    }

    if (isGlobal(pipe)) {
      nav.push({
        label: 'Overview',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/overview`,
      });
    }

    if (pipe.debug_info && (pipe.debug_info.stacktrace || pipe.debug_info.debug_info_list)) {
      nav.push({
        label: 'Debug',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/debug`,
      });
    }

    if (hasPipeSinkEntityType) {
      nav.splice(4, 0, {
        label: 'Inferred schema',
        to: `/subscription/${props.subId}/pipes/pipe/${pipe.link}/inferred-schema`,
      });
    }

    if (pipesFlows.length > 1) {
      flowButtonLink = `/subscription/${props.subId}/flows/all/?Includes.selected=${pipe._id}`;
      flowButtonText = 'Show flows';
    } else if (pipesFlows.length === 1) {
      flowButtonLink = `/subscription/${props.subId}/flows/flow/dashboard?${makeUrlParamsFromPath(
        pipesFlows[0].path
      )}`;
      flowButtonText = 'Go to flow';
    }

    const description = get(pipe, ['config', 'original', 'description']);

    return (
      <ErrorBoundary>
        <Page className="page--pipe">
          <PageHeader>
            <PageHeaderTitle>
              <SesamLink to={`/subscription/${props.subId}/pipes`}>Pipes</SesamLink>
            </PageHeaderTitle>
            {pipe && (
              <React.Fragment>
                <PageHeaderTitle style={{ marginRight: '5px' }}>
                  {pipe.name || props.params.pipeID}
                </PageHeaderTitle>
                {description && (
                  <InfoTooltip info={description} size="medium" style={{ marginRight: '5px' }} />
                )}
              </React.Fragment>
            )}
            {pipe && (
              <PageHeaderGroup>
                <PipeActionsMenu
                  actionExecutor={props.runPumpOperation}
                  onDelete={onDelete}
                  onDeleteSinkDataset={onDeleteSink}
                  pipe={pipe}
                  subId={props.subId}
                  sinkDatasetID={sinkDatasetID}
                  token={props.token}
                  subUrl={props.subUrl}
                  flowsLabel={flowButtonText}
                  flowsLink={flowButtonLink}
                  onOpenWizard={() => setShowWizard(true)}
                  addToast={props.addToast}
                />
              </PageHeaderGroup>
            )}
            {pipe && (
              <PageHeaderGroup>
                <PipeProgressRenderer
                  progress={pipe.runtime.progress}
                  runtime={pipe.runtime}
                  lastRunWithDate
                />
                {customActions.length > 0 && customActions.map((c) => c)}
                {props.pipe && modelId && (
                  <SesamLink
                    to={`/subscription/${props.subId}/browse/entity-types/${modelId}/${props.pipe._id}`}
                    buttonLink={true}
                  >
                    <Button>Sink entity type</Button>
                  </SesamLink>
                )}
                {upstreamPipes.length === 1 && (
                  <SesamLink
                    to={`/subscription/${props.subId}/pipes/pipe/${upstreamPipes[0]._id}`}
                    buttonLink={true}
                  >
                    <Button>Go upstream</Button>
                  </SesamLink>
                )}
                {downstreamPipes.length === 1 && (
                  <SesamLink
                    to={`/subscription/${props.subId}/pipes/pipe/${downstreamPipes[0]._id}`}
                    buttonLink={true}
                  >
                    <Button>Go downstream</Button>
                  </SesamLink>
                )}

                {pipesFlows.length > 0 && (
                  <SesamLink to={flowButtonLink} buttonLink={true}>
                    <Button>{flowButtonText}</Button>
                  </SesamLink>
                )}
                {getPipeOperationButtons()}
                <Button theme="primary" onClick={() => refresh()}>
                  Refresh
                </Button>
              </PageHeaderGroup>
            )}
          </PageHeader>
          {!cannotFindObject && !pipe && <LoadingPanel />}
          {cannotFindObject && (
            <NotFound
              link={`/subscription/${props.subId}/pipes`}
              linkText="View pipes"
              message="Maybe you misspelled an ID?"
              title="Pipe not found"
            />
          )}
          {!cannotFindObject && pipe && (
            <TabbedNav nav={nav}>
              {!cannotFindObject &&
                React.cloneElement(props.children, {
                  registerRefresh: registerRefresh,
                  unregisterRefresh: unregisterRefresh,
                  addCustomActions: addCustomActions,
                  removeCustomActions: removeCustomActions,
                })}
            </TabbedNav>
          )}
          {sinkDataset && (
            <SesamModal
              className="simple-dialog dataset__wizard-modal"
              isOpen={showWizard}
              onRequestClose={() => setShowWizard(false)}
              darkModeActive={props.darkModeActive}
            >
              <OutgoingFlowWizard dataset={sinkDataset} />
            </SesamModal>
          )}
        </Page>
      </ErrorBoundary>
    );
  }
}

Pipe.propTypes = {
  children: PropTypes.node,
  delete: PropTypes.func.isRequired,
  deleteSinkDataset: PropTypes.func.isRequired,
  showDeleteSinkDatasetToast: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,
  params: PropTypes.shape({
    pipeID: PropTypes.string.isRequired,
  }),
  pipe: PropTypes.object,
  pipes: PropTypes.shape({}).isRequired,
  upstreams: PropTypes.shape({}).isRequired,
  downstreams: PropTypes.shape({}).isRequired,
  lookups: PropTypes.shape({}).isRequired,
  runPumpOperation: PropTypes.func.isRequired,
  subId: PropTypes.string.isRequired,
  datasets: PropTypes.shape({}),
  token: PropTypes.string,
  subUrl: PropTypes.string,
  darkModeActive: PropTypes.bool,
  partialLoad: PropTypes.func,
  loadAllModels: PropTypes.func,
  models: PropTypes.array,
  datasetsLoaded: PropTypes.bool,
  loadDataset: PropTypes.func,
  addToast: PropTypes.func,
};

Pipe.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  subId: state.subscription.id,
  pipe: state.pipes[ownProps.params.pipeID],
  datasets: state.datasets,
  pipes: state.pipes,
  upstreams: state.upstreams,
  downstreams: state.downstreams,
  lookups: state.lookups,
  token: state.subscription.token,
  subUrl: state.subscription.url,
  darkModeActive: state.theme.dark,
  models: state.models,
  datasetsLoaded: state.loadStatus.datasetsLoaded,
});

const mapDispatchToProps = (dispatch) => ({
  delete: (id) => dispatch(PipeActions.delete(id)),
  load: (id) => dispatch(PipeActions.load(id)),
  runPumpOperation: (id, operation, parameters) =>
    dispatch(PipeActions.runPumpOperation(id, operation, parameters)),
  deleteSinkDataset: (id) => dispatch(DatasetActions.delete(id)),
  showDeleteSinkDatasetToast: () =>
    dispatch(toastAdded({ message: 'Sink dataset deleted', type: 'success' })),
  partialLoad: (id, fields) => dispatch(PipeActions.partialLoad(id, fields)),
  loadAllModels: () => dispatch(ModelActions.loadAll()),
  loadDataset: (datasetID) => dispatch(DatasetActions.load(datasetID, true, true)),
  addToast: (message) => {
    dispatch(toastAdded({ message, type: 'success' }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Pipe);
