import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Actions from 'Redux/thunks/user-profile';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import PasswordMeterTextField from 'Common/PasswordMeterTextField/PasswordMeterTextField';
import SesamTextField from 'Common/SesamTextField/SesamTextField';
import SesamLink from 'Common/Links/SesamLink';
import Feedback from '../../components/feedback';

const minPasswordLength = 12;

const ChangePassword = (props) => {
  const [dirty, setDirty] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [notValid, setNotValid] = useState(false);
  const [passwordAccepted, setPasswordAccepted] = useState(false);
  const [pleaseWait, setPleaseWait] = useState(false);

  const updatePassword = (ev) => {
    ev.preventDefault();
    const notValidPass =
      !newPassword ||
      newPassword.length < minPasswordLength ||
      newPassword !== confirmPassword ||
      currentPassword.length < 1;

    setPasswordAccepted(false);
    setCurrentPasswordError(false);
    setNotValid(notValidPass);
    setDirty(false);

    if (!notValidPass) {
      setPleaseWait(true);
      props
        .changePassword(newPassword, currentPassword)
        .then(() => {
          setPasswordAccepted(true);
          setPleaseWait(false);
          setCurrentPassword('');
          setNewPassword('');
          setCurrentPasswordError(false);
          setConfirmPassword('');
        })
        .catch(() => {
          setCurrentPasswordError('Please provide the correct current password.');
          setPleaseWait(false);
        });
    }
  };

  return (
    <div>
      <h2 className="heading-section">Change password</h2>
      <Form onSubmit={updatePassword} style={{ width: '50%' }}>
        <SesamTextField
          id="current"
          label="Current password"
          type="password"
          value={currentPassword}
          onChange={(ev) => {
            setDirty(true);
            setCurrentPassword(ev.target.value);
          }}
          margin="normal"
        />

        <PasswordMeterTextField
          id="password"
          label="New password"
          type="password"
          value={newPassword}
          minLength={minPasswordLength}
          onChange={(ev) => {
            setDirty(true);
            setNewPassword(ev.target.value);
          }}
          margin="normal"
        />

        <SesamTextField
          id="confirmPassword"
          label="Confirm new password"
          type="password"
          value={confirmPassword}
          onChange={(ev) => {
            setDirty(true);
            setConfirmPassword(ev.target.value);
            newPassword !== ev.target.value
              ? setConfirmPasswordError(true)
              : setConfirmPasswordError(false);
          }}
          margin="normal"
          error={confirmPasswordError}
          helperText={confirmPasswordError ? 'The passwords you typed do not match.' : ''}
        />

        <FormActions>
          <Button type="submit" disabled={(pleaseWait || notValid) && !dirty}>
            {pleaseWait ? 'Changing password…' : 'Change password'}
          </Button>
        </FormActions>
        {notValid && (
          <Feedback type="warning">
            Password is required and it must be repeated correctly. The minimum length of the
            password must be at least 12 characters.
          </Feedback>
        )}
        {passwordAccepted && (
          <Feedback>
            Password was updated successfully!{' '}
            <SesamLink to="/dashboard">Go to Dashboard.</SesamLink>
          </Feedback>
        )}
        {currentPasswordError && <Feedback type="error">{currentPasswordError}</Feedback>}
      </Form>
    </div>
  );
};

ChangePassword.propTypes = {
  changePassword: PropTypes.func,
  children: PropTypes.node,
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (password, current) => dispatch(Actions.changePassword(password, current)),
  };
};

export default connect(null, mapDispatchToProps)(ChangePassword);
