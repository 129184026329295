import PropTypes from 'prop-types';
import React from 'react';

import Card from '../../components/card';
import Media from '../../components/media';

const CreateSubscriptionCard = function ({ name, tagline, description, actions, asideActions }) {
  const heading = [
    <h3 key="name" className="heading-section">
      {name}
    </h3>,
    <small key="tag">{tagline}</small>,
  ];

  return (
    <Card
      text={description}
      header={<Media>{heading}</Media>}
      actions={actions}
      asideActions={asideActions}
      type="notification"
    />
  );
};

CreateSubscriptionCard.propTypes = {
  actions: PropTypes.node,
  asideActions: PropTypes.node,
  description: PropTypes.node.isRequired,
  name: PropTypes.node.isRequired,
  tagline: PropTypes.node,
};

CreateSubscriptionCard.defaultProps = {
  actions: undefined,
  asideActions: undefined,
  tagline: undefined,
};

export default CreateSubscriptionCard;
