import React, { useState, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toastAdded } from 'Redux/thunks/global';
import Box from 'Common/Box/Box';
import { DatasetInspectorContext } from '../DatasetInspectorContext';
import DatasetInspectorViewerEntityActions from './DatasetInspectorViewerEntityActions';
import DatasetInspectorViewerEntityBody from './DatasetInspectorViewerEntityBody';
import DatasetInspectorViewerEntityHeader from './DatasetInspectorViewerEntityHeader';
import DatasetInspectorViewerEntityHeaderShare from './DatasetInspectorViewerEntityHeaderShare';
import DatasetInspectorViewerEntityHeaderCurl from './DatasetInspectorViewerEntityHeaderCurl';
import DatasetInspectorViewerEmptyEntity from './DatasetInspectorViewerEmptyEntity';
import './DatasetInspectorViewerStyle.css';

function DatasetInspectorViewer(props) {
  const [compare, setCompare] = useState(false);
  const [state, actions] = useContext(DatasetInspectorContext);

  function handleToggleCompare() {
    setCompare((_compare) => !_compare);
  }

  const backToLatest = useCallback(() => {
    setCompare(false);
    actions.resetPreviousEntity();
  }, [actions]);

  useEffect(() => {
    if (state.previousEntity) {
      actions.testPreviousEntity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions.testPreviousEntity, state.previousEntity]);

  if (compare && state.selectedEntity && state.previousEntity) {
    if (state.selectedEntity._updated === state.previousEntity._updated) {
      setCompare(false);
    }
  }

  return (
    <div className="dataset-inspector-viewer">
      {state.selectedEntity ? (
        <article className="dataset-inspector-viewer__entity">
          <Box>
            <Box.Header>
              <Box.Title>
                <DatasetInspectorViewerEntityHeader addToast={props.addToast} />
              </Box.Title>
              <div style={{ display: 'flex' }}>
                <Box.Title>
                  <DatasetInspectorViewerEntityHeaderShare
                    addToast={props.addToast}
                    filter={props.filter}
                    isPipeExecutionLog={props.isPipeExecutionLog}
                    tab={props.tab}
                  />
                </Box.Title>
                <Box.Title>
                  <DatasetInspectorViewerEntityHeaderCurl
                    addToast={props.addToast}
                    token={props.token}
                    subUrl={props.subUrl}
                  />
                </Box.Title>
              </div>
            </Box.Header>
            <Box.Content>
              <DatasetInspectorViewerEntityBody compare={compare} />
              <DatasetInspectorViewerEntityActions
                onBackToLatest={backToLatest}
                sourceEntityPath={props.sourceEntityPath}
                onToggleCompare={handleToggleCompare}
                compare={compare}
                isDeadLetterDataset={props.isDeadLetterDataset}
              />
            </Box.Content>
          </Box>
        </article>
      ) : (
        <DatasetInspectorViewerEmptyEntity />
      )}
    </div>
  );
}

DatasetInspectorViewer.propTypes = {
  sourceEntityPath: PropTypes.string,
  addToast: PropTypes.func.isRequired,
  isDeadLetterDataset: PropTypes.bool,
  filter: PropTypes.string,
  token: PropTypes.string,
  subUrl: PropTypes.string,
  isPipeExecutionLog: PropTypes.bool,
  tab: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    subUrl: state.subscription.url,
    token: state.subscription.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (message) => dispatch(toastAdded({ message, type: 'success' })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetInspectorViewer);
