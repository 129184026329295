import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import { withTheme } from '@material-ui/core';

import Button from './Button/Button';
import ButtonGroup from './ButtonGroup/ButtonGroup';

import SesamAutocomplete from './SesamAutocomplete/SesamAutocomplete';
import SesamCheckboxCompact from './SesamCheckboxCompact/SesamCheckboxCompact';
import SesamMenuItem from './SesamMenuItem/SesamMenuItem';
import SesamTextField from './SesamTextField/SesamTextField';

import '../../style/global.css';
import './ACLEditor.css';

class ACLEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      acl: [],
    };
  }

  componentDidMount() {
    this.setState({ acl: cloneDeep(this.props.acl) });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.acl, prevProps.acl)) {
      this.setState({ acl: cloneDeep(this.props.acl) });
    }
  }

  render() {
    const readOnly = isUndefined(this.props.modifyACLCallback);
    const thElements = [];
    if (!readOnly) {
      thElements.push(<th className="action-buttons" key="action-buttons" />);
    }
    thElements.push(
      <th className="ace-type" key="ace-type">
        Type
      </th>
    );
    thElements.push(
      <th className="principals" key="principals">
        Applies to
      </th>
    );

    for (const permissionName of Object.keys(this.props.permissionsInfo)) {
      const permissionInfo = this.props.permissionsInfo[permissionName];
      thElements.push(
        <th key={`action-${permissionName}`} className="action">
          <div>
            <span title={permissionInfo.description}>{permissionInfo.display_name}</span>
          </div>
        </th>
      );
    }

    thElements.push(
      <th className="filler" key="filler">
        &nbsp;
      </th>
    );

    const bodyTRElements = [];
    let aceReactKey = 0;
    const acl = this.state.acl;
    for (const ace of acl) {
      const aceType = ace[0];
      const principals = ace[1];
      const actions = ace[2];
      const tds = [];
      let aceTypeWidget;
      let principalsWidget;
      const acePos = acl.indexOf(ace);
      if (!readOnly) {
        tds.push(
          <td className="action-buttons" key="action-buttons">
            <ButtonGroup>
              <Button
                title="Move this row one place up"
                disabled={acePos <= 0}
                onClick={() => {
                  if (acePos > 0) {
                    acl.splice(acePos, 1);
                    acl.splice(acePos - 1, 0, ace);
                    this.props.modifyACLCallback(acl);
                  }
                }}
              >
                Up
              </Button>
              <Button
                title="Move this row one place down"
                disabled={acePos >= acl.length - 1}
                onClick={() => {
                  if (acePos < acl.length - 1) {
                    acl.splice(acePos, 1);
                    acl.splice(acePos + 1, 0, ace);
                    this.props.modifyACLCallback(acl);
                  }
                }}
              >
                Down
              </Button>
              <Button
                theme="danger"
                title="Delete this row"
                onClick={() => {
                  acl.splice(acl.indexOf(ace), 1);
                  this.props.modifyACLCallback(acl);
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </td>
        );
        aceTypeWidget = (
          <SesamTextField
            select
            margin="none"
            value={aceType}
            onChange={(event) => {
              ace[0] = event.target.value;
              this.props.modifyACLCallback(acl);
            }}
          >
            <SesamMenuItem value="allow">Allow</SesamMenuItem>
            <SesamMenuItem value="deny">Deny</SesamMenuItem>
            <SesamMenuItem value="allow_all">Allow all</SesamMenuItem>
            <SesamMenuItem value="deny_all">Deny all</SesamMenuItem>
          </SesamTextField>
        );

        principalsWidget = (
          <SesamAutocomplete
            value={principals}
            disableCloseOnSelect
            filterSelectedOptions
            multiple
            renderInput={(params) => <SesamTextField {...params} fullWidth={false} margin="none" />}
            options={this.props.principalsInfo.map((p) => p['id'])}
            size="small"
            onChange={(obj, val) => {
              ace[1] = val;
              this.props.modifyACLCallback(acl);
            }}
          />
        );
      } else {
        aceTypeWidget = aceType;
        principalsWidget = (
          <SesamAutocomplete
            value={principals}
            renderInput={(params) => <SesamTextField {...params} fullWidth={false} margin="none" />}
            size="small"
            multiple
            disabled
          />
        );
      }
      tds.push(
        <td className="ace-type" key="type">
          {aceTypeWidget}
        </td>
      );
      tds.push(
        <td className="principal" key="principal">
          {principalsWidget}
        </td>
      );

      for (const permissionName of Object.keys(this.props.permissionsInfo)) {
        const permissionInfo = this.props.permissionsInfo[permissionName];
        const permissionDisplayName = permissionInfo.display_name;
        let isChecked;
        let checkboxIsReadOnly = readOnly;
        if (aceType === 'deny_all' || aceType === 'allow_all') {
          isChecked = true;
          checkboxIsReadOnly = true;
        } else {
          isChecked = actions.indexOf(permissionName) !== -1;
        }
        let checkbox;
        if (!checkboxIsReadOnly) {
          checkbox = (
            <SesamCheckboxCompact
              size="small"
              checked={isChecked}
              inputProps={{
                title: `Click to toggle the setting for the '${permissionDisplayName}' permission.`,
              }}
              onChange={(event) => {
                const newIsChecked = event.target.checked;
                const actionList = ace[2];
                if (newIsChecked) {
                  actionList.push(permissionName);
                } else {
                  actionList.splice(actionList.indexOf(permissionName), 1);
                }
                this.props.modifyACLCallback(acl);
              }}
            />
          );
        } else {
          checkbox = (
            <SesamCheckboxCompact
              checked={isChecked}
              inputProps={{ readOnly: true }}
              disabled
              size="small"
            />
          );
        }
        tds.push(
          <td className="action" key={`action-${permissionName}`}>
            {checkbox}
          </td>
        );
      }
      tds.push(
        <td className="filler" key="filler">
          &nbsp;
        </td>
      );
      aceReactKey += 1;
      bodyTRElements.push(
        <tr key={`ace_${aceReactKey}`} className={`ace-type-${aceType}`}>
          {tds}
        </tr>
      );
    }

    let addRowControl = null;
    if (!readOnly) {
      addRowControl = (
        <div className="acl__action-bar">
          <Button
            style={{ marginLeft: '0.5rem' }}
            title="Add a new row"
            onClick={() => {
              const newACE = ['allow', [], []];
              const newACL = acl.concat([newACE]);
              this.props.modifyACLCallback(newACL);
            }}
          >
            Add
          </Button>
        </div>
      );
    }

    let aclClassName = 'acl';
    if (this.props.theme.palette.type === 'dark') {
      aclClassName += ' acl--dark';
    }
    return (
      <div
        className={readOnly ? 'readonly-acl' : 'acl-editor'}
        style={{
          backgroundColor: this.props.theme.palette.background.semilight,
        }}
      >
        <table className={aclClassName}>
          <thead>
            <tr>{thElements}</tr>
          </thead>
          <tbody>{bodyTRElements}</tbody>
        </table>
        {addRowControl}
      </div>
    );
  }
}

ACLEditor.propTypes = {
  permissionsInfo: PropTypes.object.isRequired,
  principalsInfo: PropTypes.array.isRequired,
  acl: PropTypes.array.isRequired,
  modifyACLCallback: PropTypes.func,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      type: PropTypes.string,
      background: PropTypes.shape({ semilight: PropTypes.string }),
    }),
  }).isRequired,
};

export default withTheme(ACLEditor);
