import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckBox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TestID } from '../../../testID';

const useStyle = makeStyles(() => ({
  label: {
    fontSize: '14px',
  },
  root: {
    height: '30px',
  },
}));

export const PersistConnectionStringURL: React.FC<{
  connectionString: string;
  canConnect: boolean;
  shouldPersistURL: boolean;
  persistedURLHandler: () => void;
}> = ({ canConnect, shouldPersistURL, persistedURLHandler }) => {
  const classes = useStyle();

  return (
    <FormControlLabel
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      control={
        <CheckBox
          onChange={persistedURLHandler}
          checked={shouldPersistURL}
          disabled={!canConnect}
          data-testid={TestID.PersistConnectionStringCheckbox}
        />
      }
      label="Persist custom URL"
    />
  );
};
