import React from 'react';
import SesamLink from 'Common/Links/SesamLink';

import SystemIcon from '../../../images/icons/system.svg';
import PipeIcon from '../../../images/icons/global-pipe.svg';
import XmlIcon from '../../../images/icons/xml_white.svg';
import HttpIcon from '../../../images/icons/http_white.svg';
import CsvIcon from '../../../images/icons/csv_white.svg';
import ExcelIcon from '../../../images/icons/xls_white.svg';
import MenuIcon from '../../../images/icons/menu.svg';

import './EndpointNode.css';
import { LocalOverviewNode } from 'Internals/overview-utils';

function getIcon(node: LocalOverviewNode) {
  if (node.kind === 'endpoint') {
    switch (node.name) {
      case 'http_endpoint': {
        return <HttpIcon width="32" height="32" />;
      }
      case 'xml_endpoint': {
        return <XmlIcon width="32" height="32" />;
      }
      case 'csv_endpoint': {
        return <CsvIcon width="32" height="32" />;
      }
      case 'excel_endpoint': {
        return <ExcelIcon width="32" height="32" />;
      }
      default: {
        return <HttpIcon width="32" height="32" />;
      }
    }
  } else if (node.kind === 'global') {
    return <PipeIcon width="32" height="32" />;
  } else {
    return <SystemIcon width="32" height="32" />;
  }
}

function getLabel(node: LocalOverviewNode) {
  if (node.kind === 'endpoint') {
    switch (node.name) {
      case 'http_endpoint': {
        return 'HTTP Endpoint';
      }
      case 'xml_endpoint': {
        return 'XML Endpoint';
      }
      case 'csv_endpoint': {
        return 'CSV Endpoint';
      }
      case 'excel_endpoint': {
        return 'Excel Endpoint';
      }
      default: {
        return 'Endpoint';
      }
    }
  } else if (node.kind === 'global') {
    return 'Global pipe';
  } else {
    return 'System';
  }
}
function EndpointNode(node: LocalOverviewNode) {
  return (
    <div className={`endpoint-node`} data-id={node.name}>
      <div className="body">
        <h3 className="title" title={node.name}>
          {node.name}
        </h3>
        <div>
          {node.flows.length > 1 ? (
            <EndpointCounts node={node} />
          ) : (
            <SesamLink to={node.flows[0].link} className="endpoint-node__link">
              <TruncatedName pipeName={node.flows[0].pipeName} systemId={node.name} />
            </SesamLink>
          )}
        </div>
      </div>
      {/* This is the blue button on the side of the node */}
      <SesamLink to={node.link} className="wrapper" buttonLink={true}>
        <button
          className={`action action-system ${node.doesNotExist ? 'action--disabled' : ''}`}
          disabled={node.doesNotExist}
          title={node.doesNotExist ? "This system doesn't exist" : getLabel(node)}
        >
          {getIcon(node)}
        </button>
      </SesamLink>
    </div>
  );
}

EndpointNode.defaultProps = {
  isGlobal: false,
  tailLength: 5,
};

type NodeCountProps = Pick<LocalOverviewNode, 'direction' | 'flows' | 'link'>;

function NodeCount(props: NodeCountProps) {
  const { direction, flows, link } = props;
  const count = flows.length;
  if (count < 1) return null;
  return (
    <>
      <span>{direction === 'incoming' ? 'In' : 'Out'}: </span>
      <SesamLink title={flows.map((f) => f.pipeName).join(', ')} to={link}>
        {count}
      </SesamLink>
    </>
  );
}

type NodeCountsProps = {
  node: LocalOverviewNode;
};

function EndpointCounts(props: NodeCountsProps) {
  const { flows, direction, pipesFromLink, pipesToLink } = props.node;
  if (direction === 'both') {
    return (
      <>
        <NodeCount
          direction="incoming"
          flows={flows.filter((f) => f.localDirection === 'inbound')}
          link={pipesToLink}
        />{' '}
        <NodeCount
          direction="outgoing"
          flows={flows.filter((f) => f.localDirection === 'outbound')}
          link={pipesFromLink}
        />
      </>
    );
  }
  return (
    <>
      <NodeCount
        direction={direction}
        flows={flows}
        link={direction === 'incoming' ? pipesToLink : pipesFromLink}
      />{' '}
    </>
  );
}

type TruncatedNameProps = {
  pipeName: string;
  systemId: string;
};

function TruncatedName(props: TruncatedNameProps) {
  const { pipeName, systemId } = props;
  let truncatedName = '';
  if (pipeName === systemId) {
    truncatedName = 'Go to flow';
  } else {
    truncatedName = pipeName.replace(new RegExp(`^${systemId}-?|-?${systemId}&`), '');
  }

  return (
    <div
      style={{
        maxWidth: '10rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <span title={pipeName}>{truncatedName}</span>
    </div>
  );
}

TruncatedName.defaultProps = {
  pipeName: '',
  systemId: '',
};

export default EndpointNode;
