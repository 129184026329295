import { push } from 'react-router-redux';

import { AppThunk, AppDispatch, RootState } from '..';
import { SomeObject } from '../types/types';
import { apologise } from './thunks/apology';

export function clone(state: SomeObject) {
  return Object.assign({}, state);
}

export function intercept401(error: Error, getState, dispatch) {
  if (error.response && error.response.status === 401) {
    const loc = getState().routing.locationBeforeTransitions;
    if (loc.pathname === 'auth/login') {
      // avoid double redirects
      return false;
    }
    const authLocation = {
      pathname: '/auth/login',
      query: { redirect: loc.pathname },
      state: { query: loc.query },
    };
    dispatch(push(authLocation));
    return true;
  }

  return false;
}

function handleErrorResponse(errorObject, error: Error, dispatch: AppDispatch) {
  if (errorObject.detail) {
    dispatch(apologise(errorObject.detail, 'portal'));
  } else {
    console.error('Error while handling server response; this should not happen!', error);
    dispatch(apologise(error.toString(), 'portal'));
  }
}

// TODO this whole function is strange
export function portalErrorHandler(dispatch: AppDispatch, getState: () => RootState) {
  // should avoid this happening on the auth/login page to avoid double redirect problem
  return (error: Error) => {
    if (intercept401(error, getState, dispatch)) {
      // the promise was not fulfilled
      throw error;
    }

    if (error.responseBody) {
      handleErrorResponse(error.responseBody, error, dispatch);
    } else if (error.response) {
      error.response
        .json()
        .then((errorObject) => handleErrorResponse(errorObject, error, dispatch))
        .catch(() => handleErrorResponse('Cannot parse error object', error, dispatch));
    } else {
      console.error('Unkown error; this should not happen!', error);
      dispatch(apologise(error.toString(), 'portal'));
    }

    // keep throwing, the promise was not fulfilled
    throw error;
  };
}

export function nodeErrorHandler(dispatch: AppDispatch) {
  return function (error: Error) {
    dispatch(apologise(error.toString() as string, 'node'));
    // keep throwing, the promise was not fulfilled
    throw error;
  };
}

// thunk friendly portal error handler
export function handlePortalError(error: Error): AppThunk {
  return function (dispatch, getState) {
    dispatch(portalErrorHandler(dispatch, getState)(error));
  };
}

// thunk friendly node error handler
export function handleNodeError(error: Error): AppThunk {
  return function (dispatch) {
    dispatch(nodeErrorHandler(dispatch)(error));
  };
}
