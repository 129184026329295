import React from 'react';
import SesamLink from 'Common/Links/SesamLink';

import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import NIInspector from '../ni-inspector/NIInspector';
import Page from '../page';
import PageHeader, { PageHeaderTitle } from '../page-header';
import TabbedNav from '../tabbed-nav';

interface NIInspectorPageProps {
  params: {
    ni: string;
    subId: string;
  };
}

const NIInspectorPage: React.FC<NIInspectorPageProps> = ({ params }) => {
  const { ni, subId } = params;
  const encodedNI = encodeURIComponent(ni);

  function getNamespace(ni: string) {
    const nsSplit = ni.split(':');
    nsSplit.pop();
    return encodeURIComponent(nsSplit.join(':'));
  }

  function getIdentifier(ni: string) {
    const nsSplit = ni.split(':');
    return nsSplit.pop();
  }

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle>
          <SesamLink to={`/subscription/${subId}/browse/search?ns=${getNamespace(ni)}`}>
            {getNamespace(ni)}
          </SesamLink>
        </PageHeaderTitle>
        <PageHeaderTitle>{getIdentifier(ni)}</PageHeaderTitle>
      </PageHeader>
      <TabbedNav
        nav={[{ label: 'Identifier', to: `/subscription/${subId}/browse/ni/${encodedNI}` }]}
      >
        <ErrorBoundary>
          <NIInspector ni={ni} subId={subId} />
        </ErrorBoundary>
      </TabbedNav>
    </Page>
  );
};

export default NIInspectorPage;
