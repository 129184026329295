import React from 'react';
import PropTypes from 'prop-types';

import { buildClassName } from 'Internals/react-utils';

import './style.css';

const InProgress = function ({ active, label, size, inverted }) {
  const className = buildClassName(
    'in-progress',
    active ? null : 'in-progress--inactive',
    size === 'small' ? 'in-progress--small' : null,
    size === 'large' ? 'in-progress--large' : null,
    inverted ? 'in-progress--white' : ''
  );
  return (
    <svg className={className} title={label} viewBox="0 0 100 100">
      <rect className="p p01" height="24" width="24" x="75" y="0" />
      <rect className="p p02" height="24" width="24" x="75" y="25" />
      <rect className="p p03" height="24" width="24" x="75" y="50" />
      <rect className="p p04" height="24" width="24" x="75" y="75" />

      <rect className="p p05" height="24" width="24" x="50" y="75" />
      <rect className="p p06" height="24" width="24" x="25" y="75" />

      <rect className="p p07" height="24" width="24" x="0" y="75" />
      <rect className="p p08" height="24" width="24" x="0" y="50" />
      <rect className="p p09" height="24" width="24" x="0" y="25" />
      <rect className="p p10" height="24" width="24" x="0" y="0" />

      <rect className="p p11" height="24" width="24" x="25" y="0" />
      <rect className="p p12" height="24" width="24" x="50" y="0" />
    </svg>
  );
};

InProgress.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  inverted: PropTypes.bool,
};

InProgress.defaultProps = {
  active: true,
  label: 'In progress…',
  size: 'medium',
  inverted: false,
};

export default InProgress;
