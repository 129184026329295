import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import useClipboard from '../../../hooks/useClipboard';
import { DatasetInspectorContext } from '../DatasetInspectorContext';

function DatasetInspectorViewerEntityHeaderShare(props) {
  const {
    addToast,
    params: { pipeID, subId, datasetID },
  } = props;
  const [state] = useContext(DatasetInspectorContext);
  const selectedEntityId = state.selectedEntity._id;
  const searchType = state.searchType;
  const jumpType = state.jumpType;
  const tab = props.tab;
  const uncommitted = state.uncommitted;

  const locationSplitted = window.location.href.split('/');
  let clipboardData = `${locationSplitted[0]}//${locationSplitted[2]}/subscription/${subId}`;
  if (pipeID) {
    clipboardData += `/pipes/pipe/${encodeURIComponent(pipeID)}/${tab}?`;
  } else if (datasetID) {
    clipboardData += `/settings-datahub/dataset/${encodeURIComponent(datasetID)}/view?`;
  }
  clipboardData += `filter=${props.filter}`;
  clipboardData += `&stype=${searchType}&sval=${encodeURIComponent(
    selectedEntityId
  )}&jtype=${jumpType}&uncommitted=${uncommitted}`;

  useClipboard('.dataset-inspector-viewer__entity-header-copy-button', () => {
    addToast('Copied to clipboard!');
  });
  return (
    <React.Fragment>
      <button
        className="btn btn--small dataset-inspector-viewer__entity-header-copy-button"
        title="Copy link of the Entity ID to clipboard"
        data-clipboard-text={clipboardData}
      >
        <ShareOutlinedIcon style={{ width: '20px', height: '20px', marginLeft: 'auto' }} />
      </button>
    </React.Fragment>
  );
}

DatasetInspectorViewerEntityHeaderShare.propTypes = {
  addToast: PropTypes.func.isRequired,
  params: PropTypes.shape({
    pipeID: PropTypes.string,
    subId: PropTypes.string,
    datasetID: PropTypes.string,
  }),
  filter: PropTypes.string,
  isPipeExecutionLog: PropTypes.bool,
  tab: PropTypes.string,
};

export default withRouter(DatasetInspectorViewerEntityHeaderShare);
