import { createAction } from '@reduxjs/toolkit';
export type CommandPaletteType = 'datahubObjects';

export const layoutChanged = createAction('global/layoutChanged');
export const commandPaletteToggled = createAction<CommandPaletteType | undefined>(
  'global/commandPaletteToggled'
);

export const portalJwtUpdated = createAction<string>('global/portalJwtUpdated');

export type ToastType = 'success' | 'notification' | 'warning';
export type Toast = {
  type: ToastType;
  message: string;
};
function prepareToast(config: Toast) {
  const { type, message } = config;
  return {
    payload: {
      message,
      type,
    },
  };
}

export const toastAdded = createAction<typeof prepareToast>('toast/added', prepareToast);
export const toastDismissed = createAction('toast/dismised');

export const darkModeActivated = createAction('theme/darkModeActivated');

export const darkModeDeactivated = createAction('theme/darkModeDeactivated');

export type ComponentType = 'pipes' | 'systems' | 'datasets';

export const showZendeskWindow = createAction('zendesk/showWindow');
export const hideZendeskWindow = createAction('zendesk/hideWindow');