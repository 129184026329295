import React from 'react';
import PropTypes from 'prop-types';

import Button from 'Common/Button/Button';
import { LoadingPanel } from 'Common/LoadingPanel';
import CodeViewer from '../code-viewer';

import './style.css';
import EditorPanelDivider from '../editor/EditorPanelDivider';
const SystemMicroserviceStatus = (props) => {
  if (!props.logs) {
    return <LoadingPanel size="medium" />;
  }

  return (
    <div className={props.className || ''}>
      <EditorPanelDivider>
        <EditorPanelDivider.Title>Logs</EditorPanelDivider.Title>
        <EditorPanelDivider.Tools>
          <div className="toolbar toolbar--right">
            <div className="tail">
              <label htmlFor="tail" className="tail__label">
                Tail
              </label>
              <input
                type="checkbox"
                id="tail"
                checked={props.tail}
                onChange={props.onTailToggle}
                disabled={!props.logs.trim()}
              />
            </div>
            <Button onClick={props.onFetchLogs} disabled={props.tail}>
              Refresh
            </Button>
          </div>
        </EditorPanelDivider.Tools>
      </EditorPanelDivider>
      <div className="editor__panel-content">
        <CodeViewer code={props.logs} scroll={true} />
      </div>
    </div>
  );
};

SystemMicroserviceStatus.propTypes = {
  className: PropTypes.string,
  system: PropTypes.object,
  logs: PropTypes.string,
  onFetchLogs: PropTypes.func,
  onTailToggle: PropTypes.func,
  tail: PropTypes.bool,
};

SystemMicroserviceStatus.defaultProps = {
  tail: false,
};

export default SystemMicroserviceStatus;
