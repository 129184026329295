import React from 'react';
import PropTypes from 'prop-types';

import {
  isValidConfig as isPipeValid,
  hasConfigWarnings as hasPipeWarnings,
} from 'Internals/pipes';
import {
  isValidConfig as isSystemValid,
  hasConfigErrors as hasSystemWarnings,
} from 'Internals/systems';
import State from '../renderers/State/State';
import './config-state.css';

const ConfigStateRenderer = ({ value, type }) => {
  let isValid, hasWarnings;
  if (type === 'pipe') {
    isValid = isPipeValid;
    hasWarnings = hasPipeWarnings;
  } else if (type === 'system') {
    isValid = isSystemValid;
    hasWarnings = hasSystemWarnings;
  } else {
    return null;
  }
  if (!isValid(value)) {
    return <State title="Config has errors" className="status-red" text="Errors" />;
  } else if (hasWarnings(value)) {
    return <State title="Config has warnings" className="status-yellow" text="Warnings" />;
  }
  return <State title="Config is OK" className="status-green" text="OK" />;
};

ConfigStateRenderer.propTypes = {
  value: PropTypes.shape().isRequired,
  type: PropTypes.string.isRequired,
};

export default ConfigStateRenderer;
