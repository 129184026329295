import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Headline = function ({ text, color }) {
  return (
    <div className="bold-header" style={{ backgroundColor: color }}>
      <div className="layout__content">
        <h1 className="bold-header__text">{text}</h1>
      </div>
    </div>
  );
};

Headline.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Headline;
