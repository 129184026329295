import { IconButtonProps } from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconTypeMap } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import useToggle from '../../../hooks/useToggle';

import Clear from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

const useStyle = makeStyles((theme: Theme) => ({
  popoverTopBar: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    paddingLeft: '10px',
    justifyContent: 'space-between',
  },
}));

type SesamIconButtonPopoverProps = {
  IconButtonProps: IconButtonProps;
  icon: SvgIconTypeMap;
  render: (toggleOpen: () => void) => void;
  title?: string;
};

const SesamIconButtonPopover: React.FC<SesamIconButtonPopoverProps> = (props) => {
  const classes = useStyle();

  const { icon, IconButtonProps, render, title, ...restProps } = props;

  const [open, toggleOpen] = useToggle(false);

  const iconButtonRef = useRef(null);

  return (
    <div {...restProps}>
      <IconButton {...IconButtonProps} ref={iconButtonRef} onClick={toggleOpen}>
        {icon}
      </IconButton>
      <Popover
        anchorEl={iconButtonRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={toggleOpen}
      >
        <div className={classes.popoverTopBar}>
          <IconButton size="small" onClick={toggleOpen}>
            <Clear />
          </IconButton>
          {title && title}
        </div>
        <Divider />
        {render(toggleOpen)}
      </Popover>
    </div>
  );
};

export default SesamIconButtonPopover;
