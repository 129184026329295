import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import './ErrorBoundary.css';

/**
 * Simple ErrorBoundary component that can be inserted anywhere into the tree
 * to catch errors in children
 * Renders a fallback component when an error is thrown
 * Can be supplied a fallback prop for custom fallback component
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.handleRetry = () => {
      this.setState({ hasError: false, waiting: true }, () => {
        this.timeout = setTimeout(() => {
          this.setState({ waiting: false });
        }, 500);
      });
    };
    this.state = { hasError: false, waiting: false };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true });
    // might not need to console.log but just to be sure
    console.log(error);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    let fallbackComponent;
    if (typeof this.props.fallback === 'string') {
      fallbackComponent = <h1 className="error-boundary__message">{this.props.fallback}</h1>;
    } else {
      fallbackComponent = this.props.fallback;
    }

    if (this.state.hasError) {
      return (
        <div className="error-boundary__container">
          <div>
            {fallbackComponent}
            <Button
              style={{ marginTop: '0.5rem' }}
              onClick={this.handleRetry}
              theme="primary"
              text={'Retry'}
              loading={this.state.waiting}
              disabled={this.state.waiting}
            />
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ErrorBoundary.defaultProps = {
  fallback: 'An error occured.',
};

export default ErrorBoundary;
