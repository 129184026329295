import alerts from './alerts';
import datasets from './datasets';
import editor from './editor';
import env from './env';
import gdpr from './gdpr';
import globals from './globals';
import jwt from './jwt';
import loadStatus from './load-status';
import navigation from './navigation';
import paymentMethods from './payment-methods';
import pipeNotifications from './pipe-notifications';
import pipes from './pipes';
import models from './models';
import secrets from './secrets';
import simpleSettings from './simple-settings';
import subscription from './datahub';
import subscriptions from './subscriptions';
import systems from './systems';
import toasts from './toasts';
import user from './user';
import upstreams from './upstreams';
import downstreams from './downstreams';
import inbounds from './inbounds';
import outbounds from './outbounds';
import lookups from './lookups';
import transforms from './transforms';
import theme from './theme';
import zendesk from './zendesk';
import { routerReducer } from 'react-router-redux';
import { customConnectionURLs } from 'Redux/thunks/customConnectionURL.thunks';
import { postEntitiesModal } from 'Redux/thunks/postEntitiesModal.thunks';

// TODO: Encapsulate code that uses simple Add/Modify/Delete reducers

const RootReducer = {
  alerts,
  customConnectionURLs,
  datasets,
  editor,
  env,
  globals,
  jwt,
  loadStatus,
  navigation,
  paymentMethods,
  pipeNotifications,
  pipes,
  gdpr,
  models,
  secrets,
  simpleSettings,
  subscription,
  subscriptions,
  systems,
  toasts,
  user,
  upstreams,
  downstreams,
  inbounds,
  outbounds,
  postEntitiesModal,
  lookups,
  transforms,
  routing: routerReducer,
  theme,
  zendesk,
};

export default RootReducer;
