window.onerror = <OnErrorEventHandler>handleError;

function handleError(
  event: Event | string,
  source?: string,
  lineno?: number,
  colno?: number,
  error?: any
) {
  console.log('Handling error: ', event);
}
