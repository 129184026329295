import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SesamLink from './SesamLink';
function SubscriptionLink({ to, children, subId, title, dispatch, ...restProps }) {
  if (to.startsWith('/')) to = to.slice(1);
  return (
    <SesamLink to={`/subscription/${subId}/${to}`} title={title} {...restProps}>
      {children}
    </SesamLink>
  );
}

SubscriptionLink.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  subId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const mapStateToProps = (state) => ({
  subId: state.subscription.id,
});

export default connect(mapStateToProps, null)(SubscriptionLink);
