import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Skeleton from '@material-ui/lab/Skeleton';

const SearchResultSkeleton: React.FC = () => {
  return (
    <ListItem divider style={{ height: '73px' }}>
      <Skeleton variant="rect" width={200} heigh={30} />
    </ListItem>
  );
};

export default SearchResultSkeleton;
