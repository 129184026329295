import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { FormContext, FormProps } from './Form';

export interface FormActionsProps {
  className?: string;
}

const useStyle = makeStyles({
  formActions: {
    margin: '1rem 0 0.5rem 0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
    '& > button, & > a, & > span': {
      marginLeft: '1em',
    },
    '& > a:first-child, & > button:first-child, & > span:first-child': {
      marginLeft: 0,
    },
    '& > a, & > span': {
      fontSize: '0.875em',
    },
  },
  formActionsHorizontal: {
    alignSelf: 'center',
    flex: '0 0 auto',
    margin: 0,
  },
});

const FormActions: React.FC<FormActionsProps> = ({ children, className, ...restProps }) => {
  const styleClasses = useStyle();

  const formContext = useContext<FormProps>(FormContext);

  return (
    <div
      className={clsx([
        styleClasses.formActions,
        className,
        formContext.horizontal && styleClasses.formActionsHorizontal,
      ])}
    >
      {children}
    </div>
  );
};

export default FormActions;
