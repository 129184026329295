import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SesamAutocomplete from 'Common/SesamAutocomplete/SesamAutocomplete';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

const noop = () => null;
function MultiSelector(props) {
  const {
    name,
    label,
    placeholder = 'Enter your values separated by space',
    onChange = noop,
    items = [],
    disabledOptions = false,
    error = false,
    required = false,
    disabled = false,
  } = props;
  const [options, setOptions] = useState(items);
  const [inputValue, setInputValue] = useState('');

  function handleChange(e, selected) {
    onChange({ target: { name: name, value: [...selected] } });
  }

  function handleInputChange(e, value) {
    setInputValue(value);
    let v = value.trim();
    if (value.includes(' ') && v.length !== 0) {
      if (!items.includes(v)) {
        onChange({ target: { name: name, value: [...items, v] } });
        if (!disabledOptions) setOptions([...items, v]);
      }
    }
  }

  function handleBlur() {
    let v = inputValue.trim();
    if (v.length !== 0) {
      if (!items.includes(v)) {
        onChange({ target: { name: name, value: [...items, v] } });
        if (!disabledOptions) setOptions([...items, v]);
      }
    }
  }

  return (
    <React.Fragment>
      <SesamAutocomplete
        multiple
        freeSolo
        options={disabledOptions ? [] : options}
        value={items}
        onChange={(e, selected) => handleChange(e, selected)}
        onBlur={handleBlur}
        inputValue={inputValue}
        onInputChange={(e, value) => handleInputChange(e, value)}
        size="small"
        autoComplete
        disableCloseOnSelect={false}
        disabled={disabled}
        renderInput={(params) => {
          return (
            <SesamTextField
              {...params}
              size="small"
              placeholder={items.length < 1 ? placeholder : ''}
              label={label}
              margin="normal"
              error={error}
              required={required}
            />
          );
        }}
      />
    </React.Fragment>
  );
}

MultiSelector.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  backgroundColor: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.string),
  disabledOptions: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MultiSelector;
