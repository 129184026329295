import PropTypes from 'prop-types';
import React from 'react';
import Button from 'Common/Button/Button';
import { Form, FormActions } from 'Common/forms';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

const UpdateSecretForm = (props) => (
  <Form onSubmit={props.onSubmit} className="update-secret-form">
    <h3 className="heading-component">Update secret</h3>
    <SesamTextField
      label="Name"
      margin="normal"
      autoFocus
      disabled
      id="secretsUpdateName"
      value={props.name}
    />
    <SesamTextField
      label="New value"
      margin="normal"
      multiline
      rows="5"
      id="secretsUpdateValue"
      value={props.value}
      onChange={props.onFieldChange}
      autoFocus="off"
    />
    <FormActions>
      <Button
        onClick={(ev) => {
          ev.preventDefault();
          props.onCancel();
        }}
        type="button"
      >
        Cancel
      </Button>
      <Button type="submit">Update secret</Button>
    </FormActions>
  </Form>
);
UpdateSecretForm.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default UpdateSecretForm;
