// @ts-ignore
import debounceAction from 'debounce-action';
import { apologise } from './apology';
import { getApiConf } from './subscriptions';
import { createSelector, createAction } from '@reduxjs/toolkit';
import DatasetAPI from '../../api/datasets';
import { RootState, AppThunk } from '../..';
import { Json } from '../../types/json';
import { Dataset, SomeObject, NodeComponent } from '../../types/types';

export const datasetsSelector = createSelector(
  (state: RootState) => state.datasets,
  (datasets) => Object.values(datasets)
);

export const datasetRemoved = createAction<string>('datasets/deleted');
export const datasetLoaded = createAction<{
  id: string;
  dataset: NodeComponent;
}>('datasets/loaded');
export const datasetsLoadAllStarted = createAction('datasets/loadAllStarted');
export const datasetsLoadAllFinished = createAction<NodeComponent[]>('datasets/loadAllFinished');
export const datasetsLoadAllFailed = createAction('datasets/loadAllFailed');
export const datasetRolledBack = createAction<string>('datasets/rolledBack');
export const datasetCommitted = createAction<string>('datasets/committed');

// -----------------------------------------------------------------------------

export default {
  delete(id: string): AppThunk {
    return function (dispatch, getState) {
      return DatasetAPI.remove(getApiConf(getState), id)
        .then(() => dispatch(datasetRemoved(id)))
        .catch((error) => dispatch(apologise(error)));
    };
  },

  load(id: string, verbose = true, passthroughError = false): AppThunk {
    return function (dispatch, getState) {
      return DatasetAPI.get(getApiConf(getState), id, verbose)
        .then((dataset) => {
          dispatch(datasetLoaded({ id, dataset }));
          return dataset;
        })
        .catch((error) => {
          if (!passthroughError) {
            // do nothing
          } else {
            throw error;
          }
        });
    };
  },

  loadAll: debounceAction(
    function (loadInternal = false): AppThunk {
      return function (dispatch, getState) {
        dispatch(datasetsLoadAllStarted());
        return DatasetAPI.getAll(getApiConf(getState), loadInternal)
          .then((datasets) => dispatch(datasetsLoadAllFinished(datasets)))
          .catch((error) => {
            dispatch(datasetsLoadAllFailed());
            dispatch(apologise(error));
          });
      };
    },
    1000,
    { leading: true, trailing: false }
  ),

  loadAllForced: function (loadInternal = false): AppThunk {
    return function (dispatch, getState) {
      dispatch(datasetsLoadAllStarted());
      return DatasetAPI.getAll(getApiConf(getState), loadInternal)
        .then((datasets) => dispatch(datasetsLoadAllFinished(datasets)))
        .catch((error) => {
          dispatch(datasetsLoadAllFailed());
          dispatch(apologise(error));
        });
    };
  },

  rollback: debounceAction(
    function (id: string): AppThunk {
      return function (dispatch, getState) {
        return DatasetAPI.rollback(getApiConf(getState), id)
          .then(() => dispatch(datasetRolledBack(id)))
          .catch((error) => dispatch(apologise(error)));
      };
    },
    1000,
    { leading: true, trailing: false }
  ),

  commit: debounceAction(
    function (id: string): AppThunk {
      return function (dispatch, getState) {
        return DatasetAPI.commit(getApiConf(getState), id)
          .then(() => dispatch(datasetCommitted(id)))
          .catch((error) => dispatch(apologise(error)));
      };
    },
    1000,
    { leading: true, trailing: false }
  ),

  // TODO: IS-6787 This is not really a Redux action; it's dispatched as one so it can
  // access `getState()`. Should rethink this.
  getDatasetIndexes(datasetId: string): AppThunk {
    return function (dispatch, getState) {
      return DatasetAPI.getIndexes(getApiConf(getState), datasetId).catch((error) => {
        dispatch(apologise(error));
        return [];
      });
    };
  },
};
