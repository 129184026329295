import PropTypes from 'prop-types';
import React, { useState } from 'react';
import yup from 'yup';
import { Formik, Form, Field } from 'formik';

import Button from 'Common/Button/Button';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from 'Common/SesamTypography';

import SesamAutocomplete from 'Common/SesamAutocomplete/SesamAutocomplete';
import SesamMenuItem from 'Common/SesamMenuItem/SesamMenuItem';
import SesamTextField from 'Common/SesamTextField/SesamTextField';

const fieldRequired = 'This field is required';
const stringRequired = 'Must be a string';
const validationSchema = yup.object().shape({
  ['description']: yup.string(stringRequired).required(fieldRequired),
  ['level']: yup
    .string(stringRequired)
    .oneOf(['sensitive', 'personal', 'related'])
    .required(fieldRequired),
  ['system-id']: yup.string(stringRequired),
});

const helpText = {
  ['description']:
    'A longer description of the type of data to make it easier for the data subject to understand what the data is',
  ['level']:
    'The "level" of the data - it can be either "Personal" or "Related", i.e. directly about the data subject or indirectly (for example data about the customer such as address or orders for the customer, respectively)',
  ['system-id']:
    'The id of the system where the data is residing (for example "CRM" or "ActiveDirectory").',
  ['purpose-id']: 'What purposes is this data type covered by',
  ['contact']:
    'A comma separated list of email-addresses for who should get notified when a GDPR data access request or change request is received by the GDPR platform.',
};

const GdprDataTypeForm = ({
  defaultValues,
  initialValues,
  newDataType,
  onDelete,
  onSubmit,
  onUpdate,
  onCancel,
  purposeIds,
}) => {
  function handleFocus(e) {
    const target = e.target;
    const name = target.name;

    setCurrentFocus(name);
  }

  const [currentFocus, setCurrentFocus] = useState('');
  const [newAdditionalField, setNewAdditionalField] = useState('');

  const levelOptions = [
    {
      value: 'selectOne',
      display: 'Select one',
    },
    {
      value: 'sensitive',
      display: 'Sensitive',
    },
    {
      value: 'personal',
      display: 'Personal',
    },
    {
      value: 'related',
      display: 'Related',
    },
  ];

  return (
    <Formik
      initialValues={initialValues || defaultValues}
      validationSchema={validationSchema}
      onSubmit={(data) => {
        return newDataType ? onSubmit(data) : onUpdate(data);
      }}
    >
      {({ values, touched, errors, isValid, setFieldValue }) => {
        function addField() {
          setFieldValue(newAdditionalField, '');
          setNewAdditionalField('');
        }

        function handleKeyDown(e) {
          if (e.key === 'Enter') {
            e.preventDefault();
            const value = e.target.value;
            if (newAdditionalField.length < 1) return;
            setNewAdditionalField('');
            if (Object.keys(values).includes(value)) {
              return;
            }
            addField();
          }
        }

        const isError = (name) => touched[name] && Boolean(errors[name]);
        const getHelperText = (name) => touched[name] && errors[name];

        return (
          <Box width="100%" height="100%">
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Form>
                  <Grid container>
                    <Grid items xs={12}>
                      <Typography variant="h5" gutterBottom>{`${
                        newDataType ? 'Create new' : 'Edit'
                      } data type`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="description"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            type="text"
                            label="Description *"
                            inputProps={{ autoFocus: true }}
                            error={isError('description')}
                            helperText={getHelperText('description')}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="level"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            select
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            label="Level *"
                            placeholder="Select"
                            error={isError('level')}
                            helperText={getHelperText('level')}
                          >
                            {levelOptions.map((c) => (
                              <SesamMenuItem key={c.value} value={c.value}>
                                {c.display}
                              </SesamMenuItem>
                            ))}
                          </SesamTextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                      <Field
                        name="system-id"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            type="text"
                            label="System id"
                            error={isError('system-id')}
                            helperText={getHelperText('system-id')}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="purpose-id"
                        multiple={true}
                        render={({ field: { value, ...field } }) => {
                          if (value === null) value = [];

                          return (
                            <SesamAutocomplete
                              {...field}
                              multiple
                              disableCloseOnSelect
                              filterSelectedOptions
                              options={purposeIds}
                              getOptionLabel={(o) => o.name}
                              value={value}
                              onChange={(ev, val) => setFieldValue('purpose-id', val)}
                              renderInput={(params) => (
                                <SesamTextField {...params} margin="normal" label="Purpose Id" />
                              )}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="contact"
                        render={({ field: { value, ...field } }) => (
                          <SesamTextField
                            {...field}
                            margin="normal"
                            value={value !== null ? value : ''}
                            onFocus={handleFocus}
                            type="text"
                            label="Contact"
                            error={isError('contact')}
                            helperText={getHelperText('contact')}
                          />
                        )}
                      />
                    </Grid>
                    {Object.keys(values).map((key) => {
                      const firstChar = key.charAt(0);
                      if (
                        firstChar !== '_' &&
                        firstChar !== '$' &&
                        !Object.keys(defaultValues).includes(key)
                      ) {
                        return (
                          <Grid item xs={12}>
                            <Field
                              name={key}
                              render={({ field: { value, ...field } }) => (
                                <SesamTextField
                                  {...field}
                                  margin="normal"
                                  value={value !== null ? value : ''}
                                  onFocus={handleFocus}
                                  type="text"
                                  label={key}
                                  error={isError(key)}
                                  helperText={getHelperText(key)}
                                />
                              )}
                            />
                          </Grid>
                        );
                      }
                    })}
                    <Grid item xs={12}>
                      <Box pt={1}>
                        <Typography variant="h6" gutterBottom>
                          Add more fields
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Box mr={1} flex="1">
                          <SesamTextField
                            label="Field id"
                            onChange={(e) => {
                              const value = e.target.value;
                              setNewAdditionalField(value);
                            }}
                            onKeyDownCapture={handleKeyDown}
                            value={newAdditionalField}
                          />
                        </Box>

                        <Button
                          type="button"
                          size="big"
                          onClick={() => addField()}
                          disabled={newAdditionalField.length < 1}
                        >
                          Add
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
              <Grid item xs>
                <Box height="100%" display="flex" alignItems="center">
                  <Divider orientation="vertical" />
                  <Box pl={3}>
                    <Typography variant="body1">
                      {currentFocus ? helpText[currentFocus] : ''}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Form>
                  <Box pt={2} display="flex" flexDirection="row-reverse">
                    <Box ml={1}>
                      <Button type="button" onClick={onCancel} size="big" text="Cancel" />
                    </Box>
                    {!newDataType && (
                      <Box ml={1}>
                        <Button
                          type="button"
                          onClick={() => onDelete(values)}
                          size="big"
                          text="Delete"
                          theme="danger"
                        />
                      </Box>
                    )}
                    {newDataType ? (
                      <Button
                        size="big"
                        type="submit"
                        text="Add new data type"
                        disabled={!isValid}
                      />
                    ) : (
                      <Button size="big" type="submit" text="Save changes" disabled={!isValid} />
                    )}
                  </Box>
                </Form>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Formik>
  );
};

GdprDataTypeForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  inModal: PropTypes.bool,
  newDataType: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  purposeIds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

GdprDataTypeForm.defaultProps = {
  inModal: false,
  newDataType: false,
  onCancel: () => {},
  onDelete: () => {},
  onSubmit: () => {},
  onUpdate: () => {},
};

export default GdprDataTypeForm;
