import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ReactTable = lazy(() => import(/* webpackChunkName: "react-table" */ 'react-table-6'));

import SecretsThunks from 'Redux/thunks/secrets';

import UpdateSecretForm from './UpdateSecretForm';
import AddSecretForm from './AddSecretForm';
import './styles.css';
import { LoadingPanel } from 'Common/LoadingPanel';

const sortSecrets = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase());

class Secrets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      secretsAddName: '',
      secretsAddValue: '',
      secretsUpdateName: '',
      secretsUpdateValue: '',
      expanded: {},
      showAddForm: false,
    };

    this.columns = [
      {
        Header: 'By name',
        headerClassName: 'column_header--align-left',
        accessor: 'secret',
      },
      {
        expander: true,
        width: 65,
        style: { textAlign: 'center' },
        Expander: (options) => (
          <div
            className="secrets__action"
            onClick={() =>
              options.isExpanded
                ? this.handleEditClose()
                : this.handleEdit(options.row.secret, options.viewIndex)
            }
          >
            <span>Edit</span>
          </div>
        ),
      },
      {
        width: 65,
        accessor: 'secret',
        style: { textAlign: 'center' },
        Cell: (row) => (
          <div
            className="secrets__action secrets__action--danger"
            onClick={() => this.handleDelete(row.value)}
          >
            <span>Delete…</span>
          </div>
        ),
      },
    ];

    this.getSortedSecrets = () => {
      return this.props.secrets
        .slice()
        .sort(sortSecrets)
        .map((secret) => ({ secret }));
    };

    this.handleFieldChange = (ev) => {
      this.setState({ [ev.target.id]: ev.target.value });
    };

    this.handleAddNewSecret = (ev) => {
      ev.preventDefault();
      const newName = this.state.secretsAddName;
      const newValue = this.state.secretsAddValue;
      this.props.secretAdd(newName, newValue).then(this.resetAddFields);
    };

    this.handleUpdateSecret = (ev) => {
      ev.preventDefault();
      const name = this.state.secretsUpdateName;
      const newValue = this.state.secretsUpdateValue;
      const confirmation = confirm(
        `This will overwrite the existing value of secret "${name}". Are you sure you want to update?`
      );
      if (confirmation) {
        this.props.secretUpdate(name, newValue).then(this.resetUpdateFields);
      }
    };

    this.handleEditClose = () => {
      this.resetUpdateFields();
    };

    this.handleEdit = (secret, index) => {
      this.setState({
        expanded: {
          [index]: true,
        },
        secretsUpdateName: secret,
      });
    };

    this.handleShowAddForm = () => {
      this.setState({ showAddForm: true });
    };

    this.handleDelete = (secret) => {
      // TODO: This confirmation box should be switched out with a custom ui component.
      const confirmation = confirm(`Delete secret "${secret}"?`);
      if (confirmation) {
        this.props.secretDelete(secret);
      }
    };

    this.resetUpdateFields = () => {
      this.setState({
        expanded: {},
        secretsUpdateName: '',
        secretsUpdateValue: '',
      });
    };

    this.resetAddFields = () => {
      this.setState({
        showAddForm: false,
        secretsAddName: '',
        secretsAddValue: '',
      });
    };
  }

  render() {
    const data = this.getSortedSecrets();
    return (
      <Suspense fallback={<LoadingPanel />}>
        <div className="secrets">
          {data.length > 0 ? (
            <ReactTable
              data={data}
              collapseOnPageChange={false}
              collapseOnDataChange={false}
              resizable={false}
              onSortedChange={this.resetUpdateFields}
              sortable={false}
              expanded={this.state.expanded}
              showPagination={false}
              pageSize={data.length}
              SubComponent={(props) => (
                <UpdateSecretForm
                  name={props.row.secret}
                  value={this.state.secretsUpdateValue}
                  onSubmit={this.handleUpdateSecret}
                  onFieldChange={this.handleFieldChange}
                  onCancel={this.resetUpdateFields}
                />
              )}
              columns={this.columns}
            />
          ) : (
            <span>No secrets</span>
          )}
          <div>
            <AddSecretForm
              show={this.state.showAddForm}
              onShow={this.handleShowAddForm}
              onHide={this.resetAddFields}
              name={this.state.secretsAddName}
              value={this.state.secretsAddValue}
              onSubmit={this.handleAddNewSecret}
              onFieldChange={this.handleFieldChange}
            />
          </div>
        </div>
      </Suspense>
    );
  }
}

Secrets.propTypes = {
  secrets: PropTypes.arrayOf(PropTypes.string).isRequired,
  secretDelete: PropTypes.func.isRequired,
  secretAdd: PropTypes.func.isRequired,
  secretUpdate: PropTypes.func.isRequired,
};

function mapSecretsStateToProps(state) {
  return {
    secrets: state.secrets,
  };
}

function mapSecretsDispatchToProps(dispatch) {
  return {
    secretAdd: (name, value) => dispatch(SecretsThunks.secretAdd(name, value)),
    secretDelete: (name) => dispatch(SecretsThunks.secretDelete(name)),
    secretUpdate: (name, value) => dispatch(SecretsThunks.secretUpdate(name, value)),
  };
}

export default connect(mapSecretsStateToProps, mapSecretsDispatchToProps)(Secrets);
