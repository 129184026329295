import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';

import { connect } from 'react-redux';
import { findSub, isProvisioning, hasProvisioningFailed } from 'Redux/thunks/subscriptions';

import './style.css';
import Box from 'Common/Box/Box';
import { makeStyles } from '@material-ui/core/styles';
import useDelayedEffect from 'Hooks/useDelayedEffect';
import { useFlows } from 'Hooks/useFlows';

const useStyle = makeStyles((theme) => {
  return {
    statusBar: {
      width: '100%',
      position: 'absolute',
      bottom: '1rem',
      left: (props) => (props.collapsed ? '1rem' : 0),
    },
    statusBarItem: {
      margin: '0.5rem',
      padding: '0.5rem',
      color: theme.palette.text.primary,
      background: theme.palette.background.semilight,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    successIcon: {
      colorPrimary: '#089c40',
    },
  };
});

function makeTask(value, caption) {
  return {
    value,
    caption,
  };
}

function StatusBarItem({ show, text }) {
  const [afterGlow, setAfterGlow] = useState(false);
  const classes = useStyle();
  useDelayedEffect(
    () => setAfterGlow(true),
    () => setAfterGlow(false),
    1000,
    show
  );
  if (show || afterGlow) {
    return (
      <Box className={classes.statusBarItem}>
        {text}
        {afterGlow ? (
          <DoneIcon color={'primary'} className={classes.successIcon} size={20} />
        ) : (
          <CircularProgress color={'secondary'} size={20} disableShrink={true} />
        )}
      </Box>
    );
  } else {
    return null;
  }
}

StatusBarItem.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
};

function StatusBar(props) {
  let { tasks, collapsed } = props;
  const { loading: flowsLoading } = useFlows();
  const classes = useStyle(props);
  tasks = [...tasks, makeTask(flowsLoading, 'Deriving flows')];

  const isAnyTaskRunning = tasks.some((t) => t.value);
  return (
    <div className={classes.statusBar}>
      {collapsed
        ? isAnyTaskRunning && (
            <CircularProgress color={'secondary'} size={25} disableShrink={true} />
          )
        : tasks.map((task, i) => <StatusBarItem show={task.value} text={task.caption} key={i} />)}
    </div>
  );
}

StatusBar.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})),
  collapsed: PropTypes.bool,
};

StatusBar.defaultProps = {
  collapsed: false,
};

function mapStateToProps(state) {
  const pipeLoadingStatus = makeTask(state.loadStatus.pipeLoading, 'Syncing pipe');
  const pipesLoadingStatus = makeTask(state.loadStatus.pipesLoading, 'Syncing pipes');
  const datasetsLoadingStatus = makeTask(state.loadStatus.datasetsLoading, 'Syncing datasets');
  const systemsLoadingStatus = makeTask(state.loadStatus.systemsLoading, 'Syncing systems');
  const provisioningStatus = makeTask(
    isProvisioning(findSub(state.subscriptions)(state.subscription.id)),
    'Provisioning'
  );
  const provisioningFailed = makeTask(
    hasProvisioningFailed(findSub(state.subscriptions)(state.subscription.id)),
    'Provisioning failed'
  );

  return {
    tasks: [
      pipeLoadingStatus,
      pipesLoadingStatus,
      datasetsLoadingStatus,
      systemsLoadingStatus,
      provisioningStatus,
      provisioningFailed,
    ],
  };
}

export default connect(mapStateToProps)(StatusBar);
