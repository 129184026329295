import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import SlideLeftIcon from '../../images/icons/slide-left.svg';
import SlideRightIcon from '../../images/icons/slide-right.svg';
import ApologyActions from 'Redux/thunks/apology';

import Button from 'Common/Button/Button';

import './style.css';

export class Apology extends React.Component {
  constructor(props) {
    super(props);

    this.getFriendlyMessage = () => {
      switch (this.props.type) {
        case 'portal':
          return <p>{`Oops, something unexpected happened. ${this.props.message}`}</p>;
        case 'stale_client':
          return <p>{'A new version of Management Studio is available'}</p>;
        case 'background_sync':
          return <p>{'Background sync failed. Retrying…'}</p>;
        case 'server_upgraded':
          return <p>{'Your instance has been upgraded'}</p>;
        default:
          return <p>{`Oops, something went wrong: ${this.props.message}`}</p>;
      }
    };

    this.getActions = () => {
      switch (this.props.type) {
        case 'stale_client':
          return <Button onClick={this.handleRefresh} text="Refresh" title="Refresh application" />;
        case 'background_sync':
          return (
            <Button
              onClick={this.handleGotoConnections}
              text="Go to network settings"
              title="Navigate to datahub network settings"
            />
          );
        default:
          return <Button onClick={this.handleDismiss} text="Dismiss" title="Dismiss message" />;
      }
    };

    this.handleGotoConnections = () => {
      this.props.onPush(`/subscription/${this.props.message.subId}/settings/network`);
    };

    this.handleDismiss = () => {
      this.props.onDismiss(this.props.id);
    };

    this.handleRefresh = () => {
      location.reload(true);
    };

    this.handleToggle = (ev) => {
      ev.preventDefault();
      this.setState({ hidden: !this.state.hidden });
    };

    this.state = {
      hidden: true,
      collapsed: true,
    };
  }

  componentDidMount() {
    /* Hackish, but ReactCSSTransitionGroup forces DOM structure (yuck) */
    setTimeout(() => this.setState({ hidden: false }), 100);
  }

  render() {
    const classNames = ['apology'];
    const apologyBodyClass = this.state.collapsed ? 'apology__body collapsed' : 'apology__body';

    if (this.state.hidden) {
      classNames.push('apology--hidden');
    }

    return (
      <div className={classNames.join(' ')}>
        <button className="apology__toggle" onClick={this.handleToggle} title="Hide temporarily">
          {this.state.hidden ? <SlideLeftIcon /> : <SlideRightIcon />}
        </button>
        <div
          className={apologyBodyClass}
          onClick={() => this.setState({ collapsed: !this.state.collapsed })}
        >
          {this.getFriendlyMessage()}
          {this.getActions()}
        </div>
      </div>
    );
  }
}

Apology.propTypes = {
  id: PropTypes.number.isRequired,
  message: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onPush: PropTypes.func.isRequired,
};

class Apologies extends React.Component {
  constructor(props) {
    super(props);

    this.handleDismissAll = () => {
      for (const { id } of this.props.apologies) {
        this.props.dismiss(id);
      }
    };
  }

  render() {
    if (this.props.apologies.length === 0) {
      return null;
    }

    return (
      <ul className="apologies">
        {this.props.apologies.length > 1 && (
          <Button
            text="✕ Dismiss all"
            className="dismiss-all-button"
            onClick={this.handleDismissAll}
          />
        )}
        {this.props.apologies.map((a) => (
          <li key={a.id}>
            <Apology
              id={a.id}
              message={a.message}
              type={a.type}
              onDismiss={this.props.dismiss}
              onPush={this.props.push}
            />
          </li>
        ))}
      </ul>
    );
  }
}

Apologies.propTypes = {
  apologies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      message: PropTypes.any.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  dismiss: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    apologies: state.globals.apologies,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dismiss: (id) => dispatch(ApologyActions.dismissApology(id)),
    push: (path) => dispatch(push(path)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Apologies);
