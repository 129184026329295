import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import usePipeEntities from '../../hooks/usePipeEntities';

import { LoadingPanel } from 'Common/LoadingPanel';
import Button from 'Common/Button/Button';
import JsonPanel from 'Common/JsonPanel';

import './EntityViewerStyle.css';

const LIMIT = 50;

/**
 * Displays the latest x number of entities for a pipe given a stage
 **/

const EntityViewer = (props) => {
  const { pipe, stage, subUrl, token } = props;

  const { entities, selectedEntity, selectedEntityIndex, selectNextEntity, selectPreviousEntity } =
    usePipeEntities({ limit: LIMIT, pipe, stage, subUrl, token });

  if (!entities) {
    return <LoadingPanel />;
  }

  if (isEmpty(entities)) {
    entities.push('No entities found...');
  }

  return (
    <div className="entity-viewer">
      <div className="entity-viewer__content">
        <JsonPanel rawJson={selectedEntity} />
      </div>
      <div className="entity-viewer__actions">
        <Button
          text="Previous"
          type="button"
          className="entity-viewer__action"
          onClick={selectPreviousEntity}
          disabled={selectedEntityIndex === 0}
        />
        <div className="entity-viewer__page">
          <div>{`${selectedEntityIndex + 1} / ${entities.length}`}</div>
        </div>
        <Button
          text="Next"
          type="button"
          className="entity-viewer__action"
          onClick={selectNextEntity}
          disabled={selectedEntityIndex === entities.length - 1}
        />
      </div>
    </div>
  );
};

EntityViewer.propTypes = {
  pipe: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  subUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  stage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  subUrl: state.subscription.url,
  token: state.subscription.token,
});

export default connect(mapStateToProps)(EntityViewer);
