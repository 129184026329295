import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';

const ConfigModifiedInfo = ({ primitive }) => {
  const last_modified_by = get(primitive, 'config.audit.last_modified_by');
  const last_modified_ts = get(primitive, 'config.audit.last_modified_ts');
  if (!last_modified_by) return null;
  const text = `Last modified by ${last_modified_by.email}${
    last_modified_ts ? ` ${moment(last_modified_ts.replace('~t', '')).fromNow()}` : ''
  }`;
  return (
    <span
      title={`Last modified by ${last_modified_by.email}${
        last_modified_ts ? ` ${last_modified_ts}` : ''
      }`}
      className="pipe-dasbhoard__last-modified-by"
    >
      {text}
    </span>
  );
};
ConfigModifiedInfo.propTypes = {
  primitive: PropTypes.object,
};

export default ConfigModifiedInfo;
