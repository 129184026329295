import React from 'react';
import BrowsePage from '../browse-page/BrowsePage';
import Search from './Search';

const SearchPage = (props) => {
  return (
    <BrowsePage>
      <Search {...props} />
    </BrowsePage>
  );
};

export default SearchPage;
