import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const SesamToggleSwitch = ({
  leftLabel,
  rightLabel,
  isOn,
  onToggle,
  lowlight,
  margin,
  decoration,
  style,
}) => {
  const checkboxClass = lowlight ? 'sesam-slider round lowlight' : 'sesam-slider round';
  const marginClass = margin ? 'sesam-switch-parent with-margin' : 'sesam-switch-parent';
  const decorationLeft =
    decoration && isOn ? 'sesam-switch-left-label decoration' : 'sesam-switch-left-label';
  const decorationRight =
    decoration && !isOn ? 'sesam-switch-right-label decoration' : 'sesam-switch-right-label';

  return (
    <label className={marginClass} style={style}>
      <label className={decorationLeft}>{leftLabel}</label>
      <label className="sesam-switch">
        <input type="checkbox" checked={isOn} onChange={onToggle} />
        <span className={checkboxClass}></span>
      </label>
      <label className={decorationRight}>{rightLabel}</label>
    </label>
  );
};

SesamToggleSwitch.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  isOn: PropTypes.bool,
  onToggle: PropTypes.func,
  lowlight: PropTypes.bool,
  margin: PropTypes.bool,
  decoration: PropTypes.bool,
  style: PropTypes.object,
};

export default SesamToggleSwitch;
