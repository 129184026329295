import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import DatasetActions from 'Redux/thunks/datasets';
import SystemActions from 'Redux/thunks/systems';
import { systemsSelector, pipesSelector } from 'Redux/selectors';
import { sorterById } from 'Internals/utils';
import { getComponentType, getIcon, getNodeLabel, getEdgeColor } from 'Internals/graph';
import PipeActions from 'Redux/thunks/pipes';
import {
  getHttpTransformSystems,
  getPipeQueueSize,
  isMirorrOrReplica,
  getSinkDatasetId,
  getUpstreamPipes,
  getDownstreamPipes,
  getIncomingLookupPipes,
  getOutgoingLookupPipes,
} from 'Internals/pipes';
import get from 'lodash/get';
import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import StaticGraph from '../../components/graph/StaticGraph';
import GraphToggler from '../../components/graph/GraphToggler';
import GraphEdgeInfoPanel from '../../../components/graph-edge-info-panel/GraphEdgeInfoPanel';

// step in pixels for nodes in a row/col
const STEP_X = 150;
const STEP_Y = 100;

const COMPLETENESS_THRESHOLD = 48;

function isCompletenessBelowThreshold(dataset, hours) {
  let completeness;
  const completenessThreshold = (Date.now() - 1000 * 60 * 60 * hours) * 1000;
  if (dataset !== undefined) {
    completeness = get(dataset, ['runtime', 'completeness']);
  }

  if (typeof completeness === 'number' && completeness < completenessThreshold) {
    return true;
  }

  return false;
}

function getNodeId(o) {
  return `${getComponentType(o)}:${o._id}`;
}

function transformComponentsToGraph(
  main,
  directUpstream,
  directDownstream,
  indirectUpstream,
  indirectDownstream,
  allDatasets,
  darkMode = false
) {
  const nodes = [];
  const edges = [];
  const mainNodeId = getNodeId(main);

  const mainSinkDatasetId = getSinkDatasetId(main);
  const mainSinkDataset = allDatasets[mainSinkDatasetId];

  // Add the center node
  nodes.push({
    id: mainNodeId,
    meta: {
      type: getComponentType(main),
      datasetId: getSinkDatasetId(main),
      ownId: main._id,
    },
    color: {
      color: getEdgeColor(main, false, darkMode),
      highlight: {
        border: getEdgeColor(main, false, darkMode),
        background: 'white',
      },
      hover: {
        border: getEdgeColor(main, false, darkMode),
        background: 'white',
      },
    },
    label: getNodeLabel(main),
    image: getIcon(main),
    size: 40,
    x: 0,
    y: 0,
    fixed: { x: true, y: true },
  });

  directUpstream
    .filter(getComponentType)
    .slice()
    .sort(sorterById)
    .forEach((obj, i) => {
      const id = getNodeId(obj) + ':' + 'DU:' + i;

      const sinkDatasetId = getSinkDatasetId(obj);
      const sinkDataset = allDatasets[sinkDatasetId];
      const populated = get(sinkDataset, 'runtime.populated');

      const excludeCompleteness = get(main, ['config', 'original', 'exclude_completeness'], []);

      let completenessBelowThreshold = false;
      if (excludeCompleteness.includes(sinkDatasetId) === false) {
        completenessBelowThreshold = isCompletenessBelowThreshold(
          sinkDataset,
          COMPLETENESS_THRESHOLD
        );
      }

      nodes.push({
        id,
        label: getNodeLabel(obj),
        meta: {
          type: getComponentType(obj),
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
        color: {
          color: getEdgeColor(obj, false, darkMode),
          highlight: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
          hover: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
        },
        image: getIcon(obj),
        x: -400,
        y: -((directUpstream.length - 1) * STEP_Y) / 2 + STEP_Y * i,
        fixed: { x: true },
      });

      let queueSize = getPipeQueueSize(main, obj); // 0 if system
      if (obj.replicaQueueSize) {
        queueSize = queueSize + obj.replicaQueueSize;
      }

      const warnings =
        completenessBelowThreshold === true ||
        (queueSize > 0 ? true : false) ||
        populated === false;

      edges.push({
        from: id,
        to: mainNodeId,
        color: {
          color: getEdgeColor(main, warnings, darkMode),
          highlight: getEdgeColor(main, warnings, darkMode),
          hover: getEdgeColor(main, warnings, darkMode),
        },
        font: {
          color: getEdgeColor(main, warnings, darkMode),
          size: 22,
        },
        label: warnings === true ? '⚠' : '',
        id: `dataset:${obj._id}` + ':' + 'DU:' + i,
        meta: {
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
      });
    });

  directDownstream
    .filter(getComponentType)
    .slice()
    .sort(sorterById)
    .forEach((obj, i) => {
      const id = getNodeId(obj) + ':' + 'DD:' + i;

      const sinkDatasetId = getSinkDatasetId(obj);
      const sinkDataset = allDatasets[sinkDatasetId];
      const populated = get(sinkDataset, 'runtime.populated');

      const excludeCompleteness = get(obj, ['config', 'original', 'exclude_completeness'], []);

      let completenessBelowThreshold = false;
      if (excludeCompleteness.includes(sinkDatasetId) === false) {
        completenessBelowThreshold = isCompletenessBelowThreshold(
          mainSinkDataset,
          COMPLETENESS_THRESHOLD
        );
      }

      nodes.push({
        id,
        label: getNodeLabel(obj),
        meta: {
          type: getComponentType(obj),
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
        color: {
          color: getEdgeColor(obj, false, darkMode),
          highlight: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
          hover: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
        },
        image: getIcon(obj),
        x: 400,
        y: -((directDownstream.length - 1) * STEP_Y) / 2 + STEP_Y * i,
        fixed: { x: true },
      });

      let queueSize = getPipeQueueSize(obj, main); // 0 if system
      if (obj.replicaQueueSize) {
        queueSize = queueSize + obj.replicaQueueSize;
      }

      const warnings =
        completenessBelowThreshold === true ||
        (queueSize > 0 ? true : false) ||
        populated === false;

      edges.push({
        from: mainNodeId,
        to: id,
        color: {
          color: getEdgeColor(obj, warnings, darkMode),
          highlight: getEdgeColor(obj, warnings, darkMode),
          hover: getEdgeColor(obj, warnings, darkMode),
        },
        font: {
          color: getEdgeColor(obj, warnings, darkMode),
          size: 22,
        },
        label: warnings === true ? '⚠' : '',
        id: `dataset:${obj._id}` + ':' + 'DD:' + i,
        meta: {
          datasetId: getSinkDatasetId(main),
          ownId: obj._id,
        },
      });
    });

  indirectUpstream
    .filter(getComponentType)
    .slice()
    .sort(sorterById)
    .forEach((obj, i) => {
      const id = getNodeId(obj) + ':' + 'IU:' + i;
      const isPipe = getComponentType(obj) === 'pipe';

      const sinkDatasetId = getSinkDatasetId(obj);
      const sinkDataset = allDatasets[sinkDatasetId];
      const populated = get(sinkDataset, 'runtime.populated');

      const excludeCompleteness = get(main, ['config', 'original', 'exclude_completeness'], []);

      let completenessBelowThreshold = false;
      if (excludeCompleteness.includes(sinkDatasetId) === false) {
        completenessBelowThreshold = isCompletenessBelowThreshold(
          sinkDataset,
          COMPLETENESS_THRESHOLD
        );
      }

      nodes.push({
        id,
        label: getNodeLabel(obj),
        meta: {
          type: getComponentType(obj),
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
        color: {
          color: getEdgeColor(obj, false, darkMode),
          highlight: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
          hover: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
        },
        image: getIcon(obj),
        x: -((indirectUpstream.length - 1) * STEP_X) / 2 + STEP_X * i,
        y: -200,
        fixed: { y: true },
      });

      let queueSize = getPipeQueueSize(main, obj, true); // 0 if system
      if (obj.replicaQueueSize) {
        queueSize = queueSize + obj.replicaQueueSize;
      }

      const warnings =
        completenessBelowThreshold === true ||
        (queueSize > 0 ? true : false) ||
        populated === false;

      edges.push({
        from: id,
        to: mainNodeId,
        dashes: true,
        color: {
          color: getEdgeColor(main, warnings, darkMode),
          highlight: getEdgeColor(main, warnings, darkMode),
          hover: getEdgeColor(main, warnings, darkMode),
        },
        id: `dataset:${obj._id}` + ':' + 'IU:' + i,
        font: {
          color: getEdgeColor(main, warnings, darkMode),
          size: 22,
        },
        label: warnings === true ? '⚠' : '',
        smooth: {
          forceDirection: 'vertical',
        },
        arrows: {
          to: { enabled: true },
          from: { enabled: !isPipe },
        },
        meta: {
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
      });
    });

  indirectDownstream
    .filter(getComponentType)
    .slice()
    .sort(sorterById)
    .forEach((obj, i) => {
      const id = getNodeId(obj) + ':' + 'ID:' + i;
      const sinkDatasetId = getSinkDatasetId(obj);
      const sinkDataset = allDatasets[sinkDatasetId];
      const populated = get(sinkDataset, 'runtime.populated');

      const excludeCompleteness = get(obj, ['config', 'original', 'exclude_completeness'], []);

      let completenessBelowThreshold = false;
      if (excludeCompleteness.includes(sinkDatasetId) === false) {
        completenessBelowThreshold = isCompletenessBelowThreshold(
          mainSinkDataset,
          COMPLETENESS_THRESHOLD
        );
      }

      nodes.push({
        id,
        label: getNodeLabel(obj),
        meta: {
          type: getComponentType(obj),
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
        color: {
          color: getEdgeColor(obj, false, darkMode),
          highlight: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
          hover: {
            border: getEdgeColor(obj, false, darkMode),
            background: 'white',
          },
        },
        image: getIcon(obj),
        x: -((indirectDownstream.length - 1) * STEP_X) / 2 + STEP_X * i,
        y: 200,
        fixed: { y: true },
      });

      let queueSize = getPipeQueueSize(obj, main, true); // 0 if system
      if (obj.replicaQueueSize) {
        queueSize = queueSize + obj.replicaQueueSize;
      }

      const warnings =
        completenessBelowThreshold === true ||
        (queueSize > 0 ? true : false) ||
        populated === false;

      edges.push({
        from: mainNodeId,
        to: id,
        dashes: true,
        color: {
          color: getEdgeColor(obj, warnings, darkMode),
          highlight: getEdgeColor(obj, warnings, darkMode),
          hover: getEdgeColor(obj, warnings, darkMode),
        },
        id: `dataset:${obj._id}` + ':' + 'ID:' + i,
        font: {
          color: getEdgeColor(obj, warnings, darkMode),
          size: 22,
        },
        label: warnings === true ? '⚠' : '',
        smooth: {
          forceDirection: 'vertical',
        },
        arrows: {
          to: {
            enabled: true,
          },
          from: {
            enabled: getComponentType(main) === 'system',
          },
        },
        meta: {
          datasetId: sinkDatasetId,
          ownId: obj._id,
        },
      });
    });

  const edgesWithIds = edges.map((edge) => {
    if (!edge.id) {
      edge.id = `${edge.from}-${edge.to}`;
    }
    return edge;
  });

  const graph = {
    nodes,
    edges: edgesWithIds,
  };

  return {
    graph,
    mainNodeId,
  };
}

function PipeGraph(props, context) {
  function onGotoNode(type, id) {
    const url = `/subscription/${props.subId}/${type}s/${type}/${encodeURIComponent(id)}/graph`;

    if (props.onGotoNode) {
      props.onGotoNode(url);
    } else {
      context.router.push(url);
    }
  }

  function onToggleReplicas() {
    setShowReplicas((showReplicas) => !showReplicas);
  }

  const [selectedNode, setSelectedNode] = useState('');
  const [selectedEdge, setSelectedEdge] = useState('');
  const [pinnedEdge, setPinnedEdge] = useState('');
  const [showReplicas, setShowReplicas] = useState(false);
  const [lastChange, setLastChange] = useState('');
  const [lastChangedItem, setLastChangedItem] = useState('');
  const [hidden, setHidden] = useState(false);

  const isEdge = () => {
    return selectedEdge.length > 0 && lastChange === 'edge';
  };
  const isNode = () => {
    return selectedNode !== '' && lastChange === 'node';
  };

  useEffect(() => {
    props.loadDatasets();
    props.loadSystems();
  }, []);

  const pipe = props.pipe;
  const allDatasets = props.datasets;
  const allSystems = props.systems;
  const allPipes = {};
  Object.keys(props.pipes).forEach((k) => {
    if (props.pipes[k].origin !== 'search') allPipes[k] = props.pipes[k];
  });
  const upstreams = props.upstreams;
  const downstreams = props.downstreams;
  const lookups = props.lookups;
  const router = props.router;

  // get input pipes
  const upstreamPipes = useMemo(
    () => getUpstreamPipes(pipe, allPipes, upstreams, showReplicas),
    [pipe, allPipes, upstreams]
  );

  // get output pipes
  const downstreamPipes = useMemo(
    () => getDownstreamPipes(pipe, allPipes, downstreams, showReplicas),
    [pipe, allPipes, downstreams]
  );

  const incomingLookupPipes = useMemo(
    () => getIncomingLookupPipes(pipe, allPipes, lookups),
    [pipe, allPipes, lookups]
  );

  const outgoingLookupPipes = useMemo(
    () => getOutgoingLookupPipes(pipe, allDatasets, allPipes, upstreams, showReplicas),
    [pipe, allDatasets, allPipes, upstreams]
  );

  let mainObject = pipe;

  // get input/output systems
  const validSystems = useMemo(
    () =>
      allSystems.filter((s) => {
        if (!s.runtime['is-valid-config']) return false;
        if (!showReplicas && get(s, 'config.effective.metadata.origin') === 'replica') {
          return false;
        }
        return true;
      }),
    [allSystems]
  );

  const inputSystems = useMemo(
    () =>
      validSystems.filter(
        (s) =>
          get(pipe, 'config.effective.source.system') &&
          !get(pipe, 'config.effective.source.system').startsWith('system:sesam-node') &&
          get(pipe, 'config.effective.source.system') === s._id
      ),
    [pipe, validSystems]
  );

  const outputSystems = useMemo(
    () =>
      validSystems.filter(
        (s) =>
          get(pipe, 'config.effective.sink.system') &&
          !get(pipe, 'config.effective.sink.system').startsWith('system:sesam-node') &&
          get(pipe, 'config.effective.sink.system') === s._id
      ),
    [pipe, validSystems]
  );

  const httpTransformSystems = useMemo(
    () => getHttpTransformSystems(pipe, validSystems),
    [pipe, validSystems]
  );

  const { graph, mainNodeId } = useMemo(
    () =>
      transformComponentsToGraph(
        mainObject,
        [...upstreamPipes, ...inputSystems],
        [...downstreamPipes, ...outputSystems],
        [...outgoingLookupPipes, ...httpTransformSystems],
        incomingLookupPipes,
        allDatasets,
        props.darkMode
      ),
    [
      allDatasets,
      downstreamPipes,
      httpTransformSystems,
      incomingLookupPipes,
      inputSystems,
      mainObject,
      outgoingLookupPipes,
      outputSystems,
      upstreamPipes,
    ]
  );

  const refresh = useCallback(() => {
    const pipeIdsToRefresh = [
      ...upstreamPipes,
      ...downstreamPipes,
      ...outgoingLookupPipes,
      ...incomingLookupPipes,
    ].map((p) => p._id);
    const systemIdsToRefresh = [...inputSystems, ...outputSystems, ...httpTransformSystems].map(
      (s) => s._id
    );
    const pipeRefreshPromises = pipeIdsToRefresh.map((id) => props.loadPipe(id));
    const systemRefreshPromises = systemIdsToRefresh.map((id) => props.loadSystem(id));
    Promise.all(pipeRefreshPromises);
    Promise.all(systemRefreshPromises);

    props.loadDatasets();
  }, [
    upstreamPipes,
    downstreamPipes,
    outgoingLookupPipes,
    incomingLookupPipes,
    inputSystems,
    outputSystems,
    httpTransformSystems,
    props.loadDatasets,
  ]);

  useEffect(() => {
    props.registerRefresh(refresh);
    return () => props.unregisterRefresh(refresh);
  });

  const isGraphPage = router.routes[router.routes.length - 1].path === 'graph';

  if (!pipe) {
    return null;
  }

  return (
    <ErrorBoundary fallback={<div>An error occured while rendering the flow</div>}>
      <StaticGraph
        graph={graph}
        onGotoNode={onGotoNode}
        mainNodeId={mainNodeId}
        autoSelectMainNode={false}
        onHoverEdge={(params) => {
          setSelectedEdge(params.edge);
          setLastChange('edge');
          const name = params.edge.split(':').slice(0, -1).join('');
          setLastChangedItem(name);
          setHidden(lastChangedItem !== name ? false : hidden);
        }}
        onHoverNode={(params) => {
          setSelectedNode(params.node);
          setLastChange('node');
          const name = params.node.split(':').slice(0, -1).join('');
          setLastChangedItem(name);
          setHidden(lastChangedItem !== name ? false : hidden);
        }}
        onBlurNode={() => {
          setSelectedNode('');
        }}
        onBlurEdge={() => {
          if (pinnedEdge === '') {
            setSelectedEdge('');
          } else {
            setSelectedEdge(pinnedEdge);
          }
        }}
        onSelectEdge={(params) => {
          // Only set selected edge when an edge is selected, not a node.
          if (Array.isArray(params.nodes) && params.nodes.length === 0) {
            const edge = params.edges.length > 0 ? params.edges[0] : '';
            setSelectedEdge(edge);
            setPinnedEdge(edge);
          }
          setTimeout(() => {
            setSelectedEdge('');
            setPinnedEdge('');
          }, 10000);
        }}
        onDeselectEdge={() => {
          setSelectedEdge('');
          setPinnedEdge('');
        }}
        options={props.options}
        fit={props.fit}
        onToggleReplicas={onToggleReplicas}
        overlay={
          <div>
            {props.subHasReplicas && (
              <GraphToggler
                label="Show replicas"
                onToggle={onToggleReplicas}
                value={showReplicas}
              />
            )}
          </div>
        }
      />
      {isGraphPage && (isEdge() || isNode()) && (
        <GraphEdgeInfoPanel
          edge={isEdge() ? selectedEdge : selectedNode}
          main={mainObject}
          nodeMode={isNode()}
          hidden={hidden}
          setHidden={setHidden}
        />
      )}
    </ErrorBoundary>
  );
}

PipeGraph.propTypes = {
  loadDatasets: PropTypes.func.isRequired,
  datasets: PropTypes.shape({}).isRequired,
  pipes: PropTypes.shape({}).isRequired,
  upstreams: PropTypes.shape({}).isRequired,
  downstreams: PropTypes.shape({}).isRequired,
  lookups: PropTypes.shape({}).isRequired,
  systems: PropTypes.array.isRequired,
  loadSystems: PropTypes.func.isRequired,
  onGotoNode: PropTypes.func,
  options: PropTypes.shape({}),
  subId: PropTypes.string.isRequired,
  params: PropTypes.shape({
    pipeID: PropTypes.string.isRequired,
  }),
  pipe: PropTypes.object,
  fit: PropTypes.bool,
  subHasReplicas: PropTypes.bool.isRequired,
  loadPipe: PropTypes.func.isRequired,
  loadSystem: PropTypes.func.isRequired,
  registerRefresh: PropTypes.func.isRequired,
  unregisterRefresh: PropTypes.func.isRequired,
  router: PropTypes.shape({
    routes: PropTypes.arrayOf(PropTypes.object),
  }),
  darkMode: PropTypes.bool,
};

PipeGraph.contextTypes = {
  // https://github.com/reactjs/react-router/issues/975
  router: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const pipe = state.pipes[ownProps.params.pipeID];
  return {
    pipe,
    datasets: state.datasets,
    subId: state.subscription.id,
    subHasReplicas: pipesSelector(state).some((_pipe) => isMirorrOrReplica(_pipe)), // TODO fix
    systems: systemsSelector(state),
    pipes: state.pipes,
    upstreams: state.upstreams,
    downstreams: state.downstreams,
    lookups: state.lookups,
    darkMode: state.theme.dark,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDatasets: () => dispatch(DatasetActions.loadAll()),
    loadSystems: () => dispatch(SystemActions.loadAll()),
    loadPipe: (id) => dispatch(PipeActions.loadForced(id)),
    loadSystem: (id) => dispatch(SystemActions.loadForced(id)),
  };
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PipeGraph);
