import { CheckboxProps } from '@material-ui/core/Checkbox';
import { FormControlProps } from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { FormControl, FormControlLabel, FormGroup, FormHelperText } from '../forms';
import SesamCheckboxCompact from '../SesamCheckboxCompact/SesamCheckboxCompact';

const useStyle = makeStyles(() => {
  return {
    checkboxRoot: {
      marginRight: '0.25rem',
    },
  };
});

type SesamCheckboxFieldCompactProps = {
  CheckboxProps: CheckboxProps;
  FormControlProps: FormControlProps;
  helperText: string;
  label: string;
};

const SesamCheckboxFieldCompact: React.FC<SesamCheckboxFieldCompactProps> = (props) => {
  const { CheckboxProps, FormControlProps, helperText, label, ...restProps } = props;

  const classes = useStyle();

  return (
    <FormControl {...FormControlProps} {...restProps}>
      <FormGroup>
        <FormControlLabel
          control={
            <SesamCheckboxCompact
              className={classes.checkboxRoot}
              size="small"
              {...CheckboxProps}
            />
          }
          label={label}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormGroup>
    </FormControl>
  );
};

export default SesamCheckboxFieldCompact;
